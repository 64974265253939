import * as React from 'react'
import * as PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import consoleLogger from "../../../../../../lib/consoleLogger";

// TODO: TProps ...

const ActionButtons: React.FC<any> = (props) => {
    return (
        <CardActions>
            <div style={{
                height: props.canEdit ? '36px' : 0,
                marginTop: props.canEdit ? '8px' : 0,
                marginBottom: props.canEdit ? '8px' : 0,
                textAlign: 'right',
                width: '99%',
                transition: 'all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)',
            }}>
                <Button
                    data-qa="user-profile-basic-info-cancel-button"
                    disabled={!props.canEdit}
                    variant="outlined"
                    style={{
                        display: props.canEdit ? 'inline-block' : 'none',
                        marginRight: '8px',
                    }}
                    onClick={() => {
                        props.onSetState({ mode: ':VIEW:' }, props.initialize)
                    }}
                >
                    Cancel
                </Button>
                <Button
                    data-qa="user-profile-basic-info-save-button"
                    disabled={!props.canEdit || !props.isValid || !props.state.data.first_name || !props.state.data.last_name}
                    variant="contained"
                    color="primary"
                    style={{ display: props.canEdit ? 'inline-block' : 'none' }}
                    onClick={async () => {
                        const data = {
                            first_name: props.state.data.first_name,
                            last_name: props.state.data.last_name,
                        }
                        const res = await props.usersCurrentUpdate(props.config, data)
                            .catch((reason) => consoleLogger.error('::: reason:', reason))
                        if (res && res.status < 300 && res.data) {
                            window.location.reload()
                        }
                    }}
                >
                    Save
                </Button>
            </div>
        </CardActions>
    )
}

ActionButtons.propTypes = {
    canEdit: PropTypes.bool,
    config: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    isValid: PropTypes.bool,
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired,
    usersCurrentUpdate: PropTypes.func.isRequired,
}

ActionButtons.defaultProps = {
    canEdit: true,
    isValid: true,
}

export default ActionButtons
