import type { State } from 'xstate'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import CheckIcon from '@material-ui/icons/Check'
import type { TContext } from '..'

type TProps = {
    state: State<TContext, any, any, any, any>
    onClose: () => void
    onSave: () => void
}

const CustomDialogActions = (props: TProps) => {
    return (
        <DialogActions
            style={{
                padding: '24px',
                paddingTop: 0,
                background: 'rgb(250, 250, 250)',
            }}
        >
            <Button
                disabled={!props.state.matches('basic_info')}
                color="primary"
                style={{ marginRight: '8px' }}
                onClick={props.onClose}
            >
                Cancel
            </Button>
            <Button
                data-qa="add-user-directory-button"
                disabled={!props.state.can('SAVE')}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
                style={{ marginRight: '8px' }}
                onClick={props.onSave}
            >
                Add User Directory
            </Button>
        </DialogActions>
    )
}

export default CustomDialogActions
