import * as React from "react";
import * as PropTypes from "prop-types";
import {Dialog, DialogContent, DialogActions} from "@material-ui/core";
import Actions from "./Actions";
import Content from "./Content";

export default class extends React.Component<any, any> {
    public static propTypes = {
        muiTheme: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired
    };

    public render() {
        return <Dialog open style={{paddingTop: "5px", zIndex: 1250}} onClose={this.props.onClose}>
            <DialogContent style={{width: "100%", background: "rgb(250, 250, 250)", padding: "0 0 24px 0"}}>
                <Content {...this.props} />
            </DialogContent>
            <DialogActions style={{padding: "0 24px 24px", background: "rgb(250, 250, 250)"}}>
                <Actions {...this.props} />
            </DialogActions>
        </Dialog>;
    }
}
