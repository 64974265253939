// import initialState from "../reducers/config/initial-state";

import * as types from "./types";

export function generalMessageReset() {
    return { type: types.GENERAL_MESSAGE_RESET };
}

export function generalMessageSet(payload: any) {
    return {
        type: types.GENERAL_MESSAGE_SET,
        payload,
    };
}
