import { useRef } from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";

import ReactJson from "react-json-view";

import getAppUsersCallUrl from "../../../../../../../lib/getAppUsersCallUrl";
import axiosWrapper from "../../../../../../../lib/axiosWrapper";

import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FilterIcon from "@material-ui/icons/Filter";

import CenteredCircularProgress from "../../../../../../Widgets/CenteredCircularProgress/";
import Header from "../../../../../../Widgets/Editor/Header/";

const CustomDialogContent = (props) => {
    const elRef = useRef(null);

    const appUserId = props.appUsers?.selected?.data?.uid || "";

    return <DialogContent style={{ minWidth: 552, padding: 0, background: "rgb(250, 250, 250)" }}>
        <Header {...props} leftIcon={<FilterIcon />} primaryText={<div><strong>UID: </strong>{appUserId}</div>} style={{ height: 56 }} />

        {["ready", "waiting"].includes(props.parentState.status)
            && ["ready", "waiting"].includes(props.fstatus)
            ? <>
                <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", margin: 32 }}>
                    <div>
                        <FormControl
                            disabled={props.parentState.status !== "ready" || props.fstatus !== "ready"}
                            style={{ minWidth: 180 }}
                        >
                            <InputLabel id="fhir-version-standard-label">
                                    Standard Version
                            </InputLabel>
                            <Select
                                labelId="fhir-version-standard-label"
                                id="fhir-version-standard"
                                value={props.fhirVersion}
                                onChange={async (event) => {
                                    props.setFStatus("waiting");
                                    props.setFhirUser(null);
                                    const newFhirVersion = event.target.value;
                                    props.setFhirVersion(newFhirVersion);
                                    const accountId = props.appUsers?.selected?.data?.accountId;
                                    const environmentId = props.appUsers?.selected?.data?.environmentId;
                                    const directoryId = props.appUsers?.selected?.data?.dirId;
                                    const appUserId = props.appUsers?.selected?.data?.uid;
                                    const url = getAppUsersCallUrl(accountId, environmentId, directoryId) + `/${appUserId}/fhir-rep/${newFhirVersion}`;
                                    const response = await axiosWrapper(props.config.envApi, url, "GET");
                                    if (response && response.status < 300 && response.data) {
                                        props.setFhirUser(response.data);
                                        props.setFStatus("ready");
                                    }
                                }}
                            >
                                <MenuItem value={"FHIR_DSTU2__1_0_2"}>
                                        FHIR DSTU2 v1.0.2
                                </MenuItem>
                                <MenuItem value={"FHIR_STU3__3_0_2"}>
                                        FHIR STU3 v3.0.2
                                </MenuItem>
                                <MenuItem value={"FHIR_R4__4_0_1"}>
                                        FHIR R4 v4.0.1
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <Button
                            variant="contained" color="primary" size="small"
                            disabled={props.parentState.status !== "ready" || props.fstatus !== "ready" || !props.fhirUser}
                            style={{ marginRight: 16 }}
                            onClick={async () => {
                                elRef.current.select();
                                document.execCommand("copy");
                                props.onSetParentState({
                                    snackbar: {
                                        open: true,
                                        message: "Copied to Clipboard",
                                        autoHideDuration: 4000,
                                    },
                                });
                            }}
                        >
                                Copy to Clipboard
                        </Button>
                        <Button
                            variant="contained" color="primary" size="small"
                            disabled={props.parentState.status !== "ready" || props.fstatus !== "ready" || !props.fhirUser}
                            onClick={() => {
                                const name = props.appUsers?.selected?.data?.name || "";
                                const url = window.URL.createObjectURL(new Blob([JSON.stringify(props.fhirUser)]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", `${name}_${props.fhirVersion}.json`);
                                document.body.appendChild(link);
                                link.click();
                                props.onSetParentState({
                                    snackbar: {
                                        open: true,
                                        message: "JSON Downloaded",
                                        autoHideDuration: 4000,
                                    },
                                });
                            }}
                        >
                                Export as JSON
                        </Button>
                    </div>
                </div>
                {props.fhirUser ? <>
                    <ReactJson
                        src={props.fhirUser}
                        name={null}
                        enableClipboard
                        displayObjectSize
                        displayDataTypes
                        sortKeys
                    />
                    <textarea
                        ref={elRef}
                        value={JSON.stringify(props.fhirUser, null, 2)}
                        style={{ width: 1, height: 1, margin: 0, padding: 0, border: "none", opacity: 0 }}
                        readOnly
                    />
                </> : null}
            </>
            : <CenteredCircularProgress size={63} style={{ minWidth: 552, marginTop: 32 }} />
        }
    </DialogContent>
}

CustomDialogContent.propTypes = {
    appUsers: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    fstatus: PropTypes.string.isRequired,
    fhirVersion: PropTypes.string.isRequired,
    parentState: PropTypes.object.isRequired,

    setFhirUser: PropTypes.func.isRequired,
    setFhirVersion: PropTypes.func.isRequired,
    setFStatus: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired,

    fhirUser: PropTypes.object,
};

CustomDialogContent.defaultProps = {
    fhirUser: null,
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    appUsers: state.appUsers,
    ...ownProps,
});
export default connect(mapStateToProps)(CustomDialogContent);
