import * as React from "react";
import * as PropTypes from "prop-types";
import ServiceUsage from "./ServiceUsage/";
import ServiceSetUsage from "./ServiceSetUsage/";

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired
    };

    public render() {
        return <div>
            <ServiceSetUsage/>
            <ServiceUsage/>
        </div>
    }
}
