import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import consoleLogger from "../../../../lib/consoleLogger";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import General from "./General/";
// import HealthSubscriptionsTable from "./HealthSubscriptionsTable"
import {isAccountOwner} from "../../../../lib/user-roles-helpers";
import {Card, CardContent} from "@material-ui/core";
import Header from "../../../Widgets/Editor/Header/";

const INIT_STATE = {
    status: "", // "" | "ready" | "init"
    data: {
        name: "",
        path: "",
        customer_contact_name: "",
        customer_contact_email: ""
    },
    dataValidation: {
        accountName: "",
        customerContactEmail: ""
    },
    mode: ":VIEW:" // ":VIEW:" | ":EDIT:"
};

class AccEdit extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        accountsCurrentRead: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.initialize();
    }

    public render() {
        return <div style={{width: "100%"}}>
            <div className="container">
                {!isAccountOwner(this.props.login, this.props.config)
                    ? <Card style={{marginTop: "48px"}}>
                        <Header {...this.props} style={{height: "32px", padding: "8px 16px", borderTop: "none"}}/>
                        <CardContent style={{paddingTop: 0}}>
                            <p style={{textAlign: "center"}}>
                                <big>
                                    <strong>
                                        This screen is only available to the account owners
                                    </strong>
                                </big>
                            </p>
                        </CardContent>
                    </Card>
                    :["ready"].indexOf(this.state.status) < 0
                        ? <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
                        : <div>
                            <General {...this.props} state={this.state} initialize={this.initialize} onSetState={this.onSetState}/>
                            {/* <div style={{margin: "48px 10px 24px", fontSize: "14px", color: this.props.muiTheme.palette.secondaryTextColor}}>
                                Active interopiO Health Cloud Subscriptions
                            </div>
                            <HealthSubscriptionsTable {...this.props} state={this.state} initialize={this.initialize} onSetState={this.onSetState}/> */}
                        </div>
                }
            </div>
            <br/>
        </div>;
    }

    // Event handlers ----------------------------------------------------------
    private onSetState = (state, cb) => this.setState(state, cb);

    // Initialize --------------------------------------------------------------
    private initialize = () => {
        this.setState(
            {status: "init"},
            () => {
                this.props.accountsCurrentRead(this.props.config)
                    .then(() => {
                        this.setState({
                            status: "ready",
                            data: {
                                ...this.state.data,
                                ...this.props.accounts.selected.data
                            }
                        });
                    })
                    .catch((reason) => consoleLogger.error(":::", reason));
            }
        );
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(AccEdit));
