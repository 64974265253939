import * as React from 'react'
import * as PropTypes from 'prop-types'
import * as validateData from '../../../../../../lib/validate-portal-app-data'
import {
    FormControl,
    InputLabel,
    MenuItem,
    MenuProps as MenuPropsType,
    Select,
    TextField,
} from '@material-ui/core'
import CustomContexts from '../CustomContext'

const INIT_STATE = {
    dataValidation: {},
}

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        gateways: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired,
    }

    public SelectMenuProps: Partial<MenuPropsType> = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    }

    public constructor(props) {
        super(props)
        this.state = { ...INIT_STATE }
    }

    public componentDidMount() {
        this.props.onSetState({ isCreateEnabled: false })
        this.validateData()
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.state.data) !== JSON.stringify(prevProps.state.data)) {
            this.validateData()
        }
    }

    public render() {
        const apps = this.props.state.appsPerGateway
        let userContext = ''
        let isUserContextError: boolean
        if (this.props.portals.selected.data.portalType === 'PATIENT') {
            userContext = 'PATIENT'
            isUserContextError = false
            if(this.props.state.data.userContext === undefined) {
                this.props.onSetState({ data: { ...this.props.state.data, userContext: 'PATIENT' }})
            }
        }
        else {
            userContext = this.props.state.data.userContext
            isUserContextError = !!this.state.dataValidation.userContext
        }

        return (
            <div>
                <FormControl error={isUserContextError} fullWidth>
                    <InputLabel id='new-portal-app-context'>
                        User Context *
                    </InputLabel>
                    <Select
                        MenuProps={this.SelectMenuProps}
                        data-qa='new-portal-app-launch-context'
                        disabled={this.props.portals.selected.data.portalType === 'PATIENT'}
                        fullWidth
                        labelId='new-portal-app-context'
                        value={userContext}
                        onChange={e => this.props.onSetState({ data: { ...this.props.state.data, userContext: e.target.value }})}
                    >
                        <MenuItem
                            data-qa='new-portal-app-launch-provider'
                            value='PROVIDER'
                        >
                            Provider
                        </MenuItem>
                        <MenuItem
                            data-qa='new-portal-app-launch-patient'
                            value='PATIENT'
                        >
                            Patient
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    error={this.state.dataValidation.appId}
                    style={{ marginTop: '16px' }}
                >
                    <InputLabel id='new-portal-app-activation'>
                        App Activation *
                    </InputLabel>
                    <Select
                        data-qa='new-portal-app-launch-activation'
                        fullWidth
                        labelId='new-portal-app-activation'
                        MenuProps={this.SelectMenuProps}
                        value={this.props.state.data.appId}
                        onChange={ (e) => this.props.onSetState({
                            data: {
                                ...this.props.state.data,
                                appId: e.target.value,
                                appName: (e.currentTarget as any).innerText,
                            }
                        })}
                    >
                        {apps.map(app => (
                            <MenuItem
                                key={app.appId}
                                data-qa={`new-portal-app-launch-${app.appId}`}
                                value={app.appId}
                            >
                                {app.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    data-qa='new-portal-app-launch-name'
                    error={ !!this.state.dataValidation.displayName }
                    fullWidth
                    helperText={ this.state.dataValidation.displayName }
                    label='Display Name *'
                    name='name'
                    onChange={ (e) => this.props.onSetState({ data: { ...this.props.state.data, displayName: e.target.value }})}
                    style={{ marginTop: '16px' }}
                    value={ this.props.state.data.displayName || '' }
                />
                <TextField
                    data-qa='new-portal-app-launch-smart-context'
                    error={ !!this.state.dataValidation.intent }
                    fullWidth
                    helperText={ this.state.dataValidation.intent }
                    label='SMART Intent Context'
                    name='smart-context'
                    onChange={ (e) => this.props.onSetState({ data: { ...this.props.state.data, intent: e.target.value }})}
                    style={{ marginTop: '16px' }}
                    value={ this.props.state.data.intent || '' }
                />
                <CustomContexts {...this.props} />
            </div>
        )
    }

    private validateData = () => {
        const dataValidation = validateData.onWStep1(this.props.state)
        this.setState(
            {dataValidation},
            () => {
                const isValid = Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true)
                isValid !== this.props.state.isCreateEnabled && this.props.onSetState({ isCreateEnabled: isValid })
            }
        )
    }
}
