import * as React from "react";
import * as PropTypes from "prop-types";
import getMessageFromResponse from "../../../../lib/getMessageFromResponse";
import * as validateData from "../../../../lib/validate-accounts-data";
import {Button, TextField, CircularProgress, CardContent, CardHeader, CardActions, Card} from "@material-ui/core";
import Dialogs from "./Dialogs";

const INIT_STATE = {
    status: "", // "" | "ready" | "wait"
    dataValidation: {
        email: "",
        firstName: "",
        lastName: ""
    },
    dialog: "" // "" | ":DISPLAY_MESSAGE:"
};

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        calcNextWStep: PropTypes.func.isRequired,
        onSetState: PropTypes.func.isRequired,
        accountsRequestConfirmationCode: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.state.data) !== JSON.stringify(prevProps.state.data)) {
            this.validateData();
        }
    }

    public render() {
        return <Card style={{width: '560px', height: '500px', marginTop: '100px'}}>
            {/* Main content ------------------------------------------- */}
            <CardContent style={{width: "480px", marginRight: "auto", marginLeft: "auto", padding: "28px", borderRadius: "8px"}}>
                <CardHeader
                    title={
                        <div>
                            <h2 style={{fontSize: "28px", textAlign: "center", margin: "4px 0 24px"}}>
                                Request Access
                            </h2>
                            <div style={{fontSize: "14px", textAlign: "center", lineHeight: "24px"}}>
                                <p style={{margin: "0"}}>
                                    Thank you for your interest in interopiO®!
                                </p>
                                <p style={{margin: "0"}}>
                                    We’re getting closer to general availability. We’d love to learn about your use case to see if it qualifies for early access. Please submit the form
                                    below and we’ll reach<br/> out to you shortly.
                                </p>
                            </div>
                        </div>}/>

                <CardContent>
                    <div style={{display: "flex"}}>
                        <TextField style={{marginRight: "8px"}} name="firstName" value={this.props.state.data.firstName || ""} label="First Name" fullWidth
                            error={!!this.state.dataValidation.firstName} helperText={this.state.dataValidation.firstName} disabled={this.state.status === "wait"}
                            onChange={e => {
                                this.props.onSetState({
                                    data: {
                                        ...this.props.state.data,
                                        firstName: e.target.value
                                    }
                                });
                            }}/>
                        <TextField style={{marginLeft: "8px"}} name="lastName" value={this.props.state.data.lastName || ""} label="Last Name" fullWidth
                            error={!!this.state.dataValidation.lastName} helperText={this.state.dataValidation.lastName} disabled={this.state.status === "wait"}
                            onChange={e => {
                                this.props.onSetState({
                                    data: {
                                        ...this.props.state.data,
                                        lastName: e.target.value
                                    }
                                });
                            }}/>
                    </div>
                    <TextField name="email" type="email" value={this.props.state.data.email || ""} label="Email" placeholder="your-email@example.com" fullWidth
                        error={!!this.state.dataValidation.email} helperText={this.state.dataValidation.email} disabled={this.state.status === "wait"}
                        onChange={e => {
                            this.props.onSetState({
                                data: {
                                    ...this.props.state.data,
                                    email: e.target.value
                                }
                            });
                        }}/>
                </CardContent>

                <CardActions style={{padding: "16px"}}>
                    <Button variant="contained" color="primary" fullWidth disabled={!this.props.state.data.email || this.state.status !== "ready" || !this.isDataValid()}
                        onClick={() => {
                            this.setState(
                                {status: "wait"},
                                async () => {
                                    let response = null;
                                    try {
                                        const data = {email: this.props.state.data.email};
                                        response = await this.props.accountsRequestAccess(data, {bypassError: true});
                                    } catch (reason) {
                                        return this.setState({
                                            status: "ready",
                                            dataValidation: {
                                                ...this.state.dataValidation,
                                                email: getMessageFromResponse(reason, true)
                                            },
                                            dialog: ":DISPLAY_MESSAGE:"
                                        });
                                    }

                                    if (response) {
                                        this.setState(
                                            {status: "ready"},
                                            () => {
                                                this.props.onSetState({wstep: this.props.calcNextWStep()});
                                            }
                                        );
                                    }
                                }
                            );
                        }}>
                        {this.state.status === "wait"
                            ? <CircularProgress size={24} style={{marginTop: "5px"}}/>
                            : "CONFIRM"}
                    </Button>
                </CardActions>
            </CardContent>
            {["ready"].indexOf(this.state.status) > -1 &&
            <Dialogs {...this.props} state={this.state}
                onClose={() => this.setState(
                    {...INIT_STATE},
                    () => this.props.onSetState({
                        data: {
                            ...this.props.state.data,
                            email: ""
                        }
                    })
                )}
            />}
            <br/>
        </Card>;
    }

    // Helpers -----------------------------------------------------------------
    private isDataValid: Function = () => Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true);

    private validateData = () => {
        const dataValidation = validateData.onRequestAccessWStep1(this.props.state)
        this.setState({
            status: "ready",
            dataValidation
        });
    };
}
