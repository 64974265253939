import * as PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import CenteredCircularProgress from "../../../../../../Widgets/CenteredCircularProgress/";
import AppUserItem from "../../../Shared/AppUserItem";

const CustomDialogContent = (props) => {
    const appUserName = props.parentState.selectedAppUser.name || "";

    return <DialogContent style={{ minWidth: 552, padding: 24, background: "rgb(250, 250, 250)" }}>
        {props.parentState.status === "ready"
            ? <>
                <span>Are you sure you want to remove</span>&nbsp;
                <strong>{appUserName}</strong>?
                <br />
                <span>This action <strong>CANNOT</strong> be undone!</span>
                <AppUserItem
                    appUser={props.parentState.selectedAppUser}
                    parentState={props.parentState}
                    clickable={false} showMenu={false}
                />
            </>
            : <CenteredCircularProgress size={63} />}
    </DialogContent>
}

CustomDialogContent.propTypes = {
    parentState: PropTypes.object.isRequired,
};

export default CustomDialogContent;
