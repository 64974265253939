import * as React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import withLegacyTheme from '../../../../../lib/hoc/with-legacy-theme'

import { Alert, AlertTitle } from '@material-ui/lab';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

type TProps = {
    config: any
    history: any
    location: any
    muiTheme: any
    ui: any
}

const SSOError: React.FC<TProps> = (props: TProps) => {
    return (<div className="container">
        <Card style={{width: 580, marginTop: 48, marginRight: "auto", marginLeft: "auto", padding: 10, borderRadius: 8}}>
            <CardContent>
                <Alert severity="error" variant="outlined" style={{fontSize: "16px", marginBottom: 35}}>
                    <AlertTitle style={{ fontSize: "18px"}}>There was a problem logging you in with SSO</AlertTitle>
                    Please try again and make sure your account SSO configuration is correct or contact support.
                </Alert>
                <Button data-qa="sso-back-to-home-button" variant="contained" color="primary" fullWidth
                    onClick={() => {
                        props.history.push("/home");
                    }}
                >
                    Back to home
                </Button>
            </CardContent>
            <CardContent style={{padding: 16, textAlign: "center", fontSize: 16}}>
                <strong>
                    <a href="https://support.interopio.com/hc/en-us/articles/360059665492-SAML-single-sign-on-configuration" target="_blank" style={{ fontSize: 16, textDecoration: "none"}}>
                        Support Center
                    </a>
                </strong>
            </CardContent>
        </Card>
    </div>)
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ui: state.ui,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(withLegacyTheme()(SSOError)))
