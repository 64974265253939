import { useState, useEffect } from "react";
import * as React from 'react'
import * as PropTypes from "prop-types";

import Divider from "@material-ui/core/Divider";
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import axiosWrapper from "../../../lib/axiosWrapper";

import "./style.less";

const CardMenuImage: React.FC<any> = (props: any) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [src, setSrc] = useState("")

    useEffect(() => {
        if (props.src && props.src.includes('/environment-api/')) {
            const getLogoSrc = async () => {
                const logoUrl = props.src.split('/environment-api/')[1]
                const res = await axiosWrapper(
                    props.config.envApi,
                    logoUrl,
                    undefined, undefined, undefined,'arraybuffer'
                )
                if (res.status === 200) {
                    const blob = new Blob([res.data], { type: res.headers['content-type'] })
                    const url = URL.createObjectURL(blob)
                    return url
                } else {
                    return props.src // fallback to original url
                }
            }
            getLogoSrc().then((url) => {
                setSrc(url)
            })
        } else {
            setSrc(props.src)
        }
    }, [props.src,
        props.config.envApi])

    return (
        <div
            className={`card-menu-image ${props.className}`}
            style={{
                backgroundImage: `url(${src})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                ...props.style
            }}
            onClick={props.onClick}
        >
            {props.menuItems.length ? (
                <div className="image-menu">
                    <IconButton
                        data-qa={props["data-qa"]}
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        size="medium"
                        style={{
                            margin: 12,
                            padding: 0,
                            color: "rgba(0, 0, 0, 0.2)",
                            backgroundColor: "rgba(0, 0, 0, 0.05)",
                            borderRadius: 24,
                        }}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>

                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        anchorOrigin={{ horizontal: "center", vertical: "center" }}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        className="card-image-menu-popover"
                        PaperProps={{ style: { minWidth: 168 } }}
                        onClose={(event: any) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setAnchorEl(null)
                        }}
                    >
                        {props.menuItems.map((item, index) => (
                            <div key={`menu-item-${index}`}>
                                {item.dividerTop ? <Divider /> : null}
                                <MenuItem
                                    data-qa={item["data-qa"]}
                                    style={{ minHeight: 26, fontSize: 12, lineHeight: "26px" }}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setAnchorEl(null);
                                        item.onClick && item.onClick();
                                    }}
                                >
                                    {item.leftIcon ? <ListItemIcon style={{ minWidth: 32 }}>{item.leftIcon}</ListItemIcon> : null}
                                    <Typography variant="inherit" noWrap>
                                        {item.text}
                                    </Typography>
                                </MenuItem>
                                {item.dividerBottom ? <Divider /> : null}
                            </div>
                        ))}
                    </Popover>
                </div>
            ) : null}
        </div>
    );
}

CardMenuImage.propTypes = {
    className: PropTypes.string,
    "data-qa": PropTypes.string,
    menuItems: PropTypes.array,
    src: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    config: PropTypes.object,
};

CardMenuImage.defaultProps = {
    className: "",
    "data-qa": "",
    menuItems: [],
    src: "",
    style: {},
    onClick: () => {},
    config: {}
};

export default CardMenuImage;
