import * as React from "react";
import * as PropTypes from "prop-types"
import axiosWrapper from "../../../lib/axiosWrapper";
import getHostnameInfo from "../../../lib/getHostnameInfo";
import {Card, CardContent} from "@material-ui/core";
import Header from "../../Widgets/Editor/Header/";
import {Table, FilterByDate, FilterBy, Search} from "../../Widgets/Table";
import APP_MOCK_LOGS from "./data/app-mock-logs"
import DA_MOCK_LOGS from "./data/da-mock-logs"
import GW_MOCK_LOGS from "./data/gw-mock-logs"
import TYPE_ITEMS from "./data/type-items";
import LOG_EVENT_ITEMS from "./data/log-event-items";
import CustomBody from "./CustomBody";
import {delay, getFullDateDisplay} from "../../../lib/utils";
import { isEnvironmentAdmin } from '../../../lib/user-environment-permissions-helpers'
import "./styles.less";

import moment from "moment";
import consoleLogger from "../../../lib/consoleLogger";
const __MOCK_DATA_ENABLED__ = false;
let timestamp = Date.now();

class LoggingTab extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        dataSources: PropTypes.object.isRequired,
        gateways: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
        appId: PropTypes.string
    };

    public static defaultProps = {
        appId: ""
    };

    public constructor(props) {
        super(props);
        this.state = {
            isRefreshing: false,
            data: {
                logs: [],
                logsPagination: {
                    number: 0,
                    size: 50,
                    totalElements: 0
                }
            },
            logsFilter: {
                startTime: moment().startOf("day").valueOf(),
                logsPageSize: 50
            },
            expandAll: false,
        };
    }

    public componentDidMount() {
        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config)
        if (actAsAdmin) {
            this.fetchLogs();
        }
    }

    public render() {
        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config)
        if (!actAsAdmin) {
            return <div className="container">
                <Card style={{marginTop: "48px"}}>
                    <Header {...this.props} style={{height: "32px", padding: "8px 16px", borderTop: "none"}}/>
                    <CardContent>
                        <p style={{textAlign: "center"}}>
                            <big>
                                <strong>
                                    This screen is only available to the account owners or environment admins
                                </strong>
                            </big>
                        </p>
                    </CardContent>
                </Card>
            </div>
        }

        let leftFilters = this.getLeftFilters();
        let rightFilters = this.getRightFilters();
        let columns = this.getColumns();
        let pagination = {
            goToPage: this.fetchLogs,
            loading: this.state.isRefreshing,
            size: this.state.logsFilter.logsPageSize,
            number: this.state.data.logsPagination.number + 1,
            totalElements: this.state.data.logsPagination.totalElements,
            setPageSize: size => this.setLogFilterItem("logsPageSize", size)
        };

        return <Table leftFilters={leftFilters} rightFilters={rightFilters} columns={columns} pagination={pagination}
            export={[
                {func: () => this.export(','), label: "Export CSV"},
                {func: () => this.export('\t'), label: "Export TSV"}]}
            expandAll={this.toggleExpandAll} allExpanded={this.state.expandAll}
            refresh={this.props.refresh ? this.fetchLogs : undefined}>
            <CustomBody {...this.props} logState={this.state}/>
        </Table>
    }

    private export = async (separator) => {
        let element = document.createElement("a");
        element.style.display = "none";

        let data = "";
        if (["data-adapter", "gateway"].includes(this.props.type)) {
            data = `ID${separator}Time Stamp${separator}Time${separator}` +
                   `Data adapter id${separator}Account${separator}Environment${separator}SessionId${separator}Code${separator}` +
                   `Message${separator}` +
                   `PHI${separator}IP${separator}Agent${separator}` +
                   `Metadata${separator}Labels${separator}Level\r\n`;
            this.state.data.logs.map(log => {
                data += (`${log.id}${separator}${log.timestamp}${separator}${getFullDateDisplay(log.timestamp, this.props.ui.timeZone)}${separator}` +
                         `${log.data_adapter_id}${separator}${log.account_id}${separator}${log.environment_id}${separator}${log.session_id}${separator}${log.http_error_code}${separator}` +
                         `${log.hasOwnProperty('body') ? log.body.replace("\r\n", " ").replace("\r", " ").replace("\n", " ").replace(/,/g, " ").replace(/\t/g, " ").replace(/"/g, "'") : ""}${separator}` +
                         `${log.contains_phi}${separator}${log.ip_address}${separator}${log.user_agent}${separator}` +
                         `${log.metadata}${separator}${log.labels}${separator}${log.log_level}`);
                data += "\r\n";
            });
        } else if (this.props.type === "app") {
            data = `ID${separator}Time Stamp${separator}Time${separator}` +
                   `App ID${separator}Account${separator}Environment${separator}SessionId${separator}Code${separator}` +
                   `Message${separator}` +
                   `PHI${separator}IP${separator}User Agent${separator}` +
                   `Metadata${separator}Labels${separator}Log level\r\n`;
            this.state.data.logs.map(log => {
                data += (`${log.id}${separator}${log.timestamp}${separator}${getFullDateDisplay(log.timestamp, this.props.ui.timeZone)}${separator}` +
                         `${log.app_id}${separator}${log.account_id}${separator}${log.environment_id}${separator}${log.session_id}${separator}${log.http_error_code}${separator}` +
                         `${ log.hasOwnProperty('body') ? log.body.replace("\r\n", " ").replace("\r", " ").replace("\n", " ").replace(/,/g, " ").replace(/\t/g, " ").replace(/"/g, "'") : ""}${separator}` +
                         `${log.contains_phi}${separator}${log.ip_address}${separator}${log.user_agent}${separator}` +
                         `${log.metadata}${separator}${log.labels}${separator}${log.log_level}`);
                data += "\r\n";
            });
        }

        const blob = new Blob([data], {type: "text/csv;charset=utf-8;"});
        const url = URL.createObjectURL(blob);
        element.setAttribute("href", url);

        const extension = (separator === ',' ? 'csv' : 'tsv')
        const filename = `${getFullDateDisplay(Date.now(), this.props.ui.timeZone)}.${extension}`
        element.setAttribute("download", filename);

        document.body.appendChild(element);
        await delay(1550);
        element.click();
        await delay(1550);
        document.body.removeChild(element)
        element = null;
    };

    private getColumns = () => {
        let columns = [
            {
                size: 2.2,
                name: "time",
                label: "Time"
            },
            {
                size: 1.3,
                name: "gateway",
                label: "Gateway Id"
            },
            {
                size: 1.3,
                name: "session",
                label: "Session Id"
            },
            {
                size: .9,
                name: "type",
                label: "Type"
            },
            {
                size: 1.3,
                name: "meta",
                label: "Metadata"
            },
            {
                size: 8,
                name: "message",
                label: "Message"
            }
        ];

        this.props.type === "app" && columns.splice(5, 0, {size: 2, name: "event", label: "System Log Event"});

        return columns;
    }

    private getRightFilters = () => {
        let filters = [];

        let allGatewayIDs = [];
        if (this.props.type === "data-adapter") {
            allGatewayIDs = this.props.gateways.all.data.map(({gatewayId}) => gatewayId).filter((gw) => gw);
            filters.push({
                type: "component",
                value: <FilterBy key={1} {...this.props} label="Gateway" allItems={allGatewayIDs} filterKey="gatewayID" setFilterItem={this.setLogFilterItem}/>
            })
        }

        let allActivations = [];
        if (this.props.type === "app") {
            allActivations = this.props.apps.selected.data.activations.map(({gatewayId}) => gatewayId).filter((gatewayId) => gatewayId);
            filters.push({
                type: "component",
                value: <FilterBy key={2} {...this.props} label="Activation" allItems={allActivations} filterKey="activation" setFilterItem={this.setLogFilterItem}/>
            })
        }

        return filters;
    }

    private getLeftFilters = () => {
        return [
            {
                type: "component",
                value: <FilterByDate {...this.props} key="data_filter" setFilterItem={this.setLogFilterItem}/>
            },
            {
                type: "component",
                value: <FilterBy {...this.props} key="log_level" label="Type" allItems={TYPE_ITEMS} filterKey="logLevel" setFilterItem={this.setLogFilterItem}/>
            },
            {
                type: "component",
                value: <FilterBy {...this.props} key="log_events" label="Log Events" allItems={LOG_EVENT_ITEMS} filterKey="systemLogEvent" setFilterItem={this.setLogFilterItem}/>
            },
            {
                type: "component",
                value: <Search key="search" setFilterItem={this.setLogFilterItem} hint="Search logs"/>
            }
        ];
    };

    private toggleExpandAll = () => this.setState({expandAll: !this.state.expandAll});

    private setLogFilterItem = (item, value) => {
        const logsFilter = {...this.state.logsFilter};
        logsFilter[item] = value;
        if (item === "startTime") {
            delete logsFilter.period;
        }
        this.setState({logsFilter}, () => {
            if (!!value || item === "logLevel" || item === "systemLogEvent" || item === "searchTerms" || item === "gatewayID" || item === "activation") {
                this.fetchLogs();
            }
        })
    }

    private fetchLogs = (page = 1) => {
        page--;
        const {accountId} = getHostnameInfo();

        const selected = this.props.type === "data-adapter"
            ? this.props.dataSources.selected
            : this.props.type === "gateway"
                ? this.props.gateways.selected
                : this.props.type === "app"
                    ? this.props.apps.selected
                    : null
        if (!selected) return

        const environmentId = selected.data.environmentId;

        const selectedId = this.props.type === "data-adapter"
            ? selected.data.dataSourceId
            : this.props.type === "gateway"
                ? selected.data.gatewayId
                : null

        const filter = this.state.logsFilter;

        let url = this.props.type === "data-adapter"
            ? `apps/${accountId}/${environmentId}/da/${selectedId}/logs?size=${filter.logsPageSize}&page=${page}`
            : this.props.type === "gateway"
                ? `apps/${accountId}/${environmentId}/gateway/${selectedId}/logs?size=${filter.logsPageSize}&page=${page}`
                : this.props.type === "app"
                    ? `apps/${accountId}/${environmentId}/${this.props.appId}/logs?size=${filter.logsPageSize}&page=${page}`
                    : null
        if (!url) return

        if (!filter.period) {
            url += `&startTime=${filter.startTime}`
        } else {
            url += `&startTime=${filter.period.startTime.valueOf()}&endTime=${filter.period.endTime.valueOf()}`
        }

        if (filter.logLevel) {
            const logLevels = filter.logLevel.join(" ");
            url += `&logLevel=${logLevels}`
        }

        if (filter.systemLogEvent) {
            const systemLogEvents = filter.systemLogEvent.join(" ");
            url += `&systemLogEvent=${systemLogEvents}`
        }

        if (filter.searchTerms) {
            let searchTerms = encodeURIComponent(filter.searchTerms.join(" "));
            url += `&searchTerm=${searchTerms}`
        }

        if (filter.gatewayID) {
            const gatewayIDs = filter.gatewayID.join(" ");
            url += `&gatewayId=${gatewayIDs}`
        }

        if (filter.activation) {
            const activations = filter.activation.join(" ");
            url += `&gatewayId=${activations}`
        }

        this.setState({isRefreshing: true}, async () => {
            const logs: any = await this.getLogs(url)
            if (!logs) {
                return this.setState({isRefreshing: false});
            } else if (logs._embedded && logs._embedded.appsLogsDtoes) {
                this.setState({
                    isRefreshing: false,
                    data: {
                        ...this.state.data,
                        logs: logs._embedded.appsLogsDtoes,
                        logsPagination: logs.page
                    }
                })
            } else {
                this.setState({
                    isRefreshing: false,
                    data: {
                        ...this.state.data,
                        logs: [],
                        logsPagination: {
                            number: 0,
                            size: 50,
                            totalElements: 0
                        }
                    }
                });
            }
        });
    };

    private getLogs = async (url) => {
        const _timestamp = Date.now();
        timestamp = _timestamp;
        let logs = {};
        try {
            if (__MOCK_DATA_ENABLED__) {
                logs = this.props.type === "data-adapter"
                    ? await new Promise((resolve) => setTimeout(() => resolve(DA_MOCK_LOGS), 1550))
                    : this.props.type === "gateway"
                        ? await new Promise((resolve) => setTimeout(() => resolve(GW_MOCK_LOGS), 1550))
                        : this.props.type === "app"
                            ? await new Promise((resolve) => setTimeout(() => resolve(APP_MOCK_LOGS), 1550))
                            : null
            } else {
                logs = (await axiosWrapper(this.props.config.loggingApi, url, "GET")).data;
            }
            if (_timestamp !== timestamp) {
                return null;
            }
        } catch (reason) {
            consoleLogger.error(":::", reason);
        }
        return logs;
    }
}

export default LoggingTab
