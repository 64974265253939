import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../redux/actions";
import getLocationInfo from "../../../../lib/getLocationInfo";
import { getPath } from "../../../../lib/utils/";
import { Link } from "react-router-dom";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import { NavbarL2, NavbarL3 } from "../../../CustomNavbar/";
import CdsServiceAuth from "./CdsServiceAuth";
import Details from "./Details/";
import GatewayEditDataMapping from "./GatewayEditDataMapping";
import Logging from "./Logging";
import Subscriptions from "./Subscriptions";
import Transactions from "./Transactions";
import Usage from "./Usage/";
import {Layers} from "@material-ui/icons";
import Selector from "../../../CustomNavbar/Selector";
import { TStateDataValidationOnEditDetails } from '../../../../lib/validate-gateways-data'
import type { TGateway } from '../../../../types'
import InitMachine from './InitMachine'

type TProps = any

type TUserDirSlim = {
    directoryId: string
    name: string
    description: string
    directoryType: 'INTEROPIO_USERS' | 'DELEGATED_EHR_USERS'
}

export type TState = {
    status: '' | 'ready' | 'init' | 'wait'
    data: TGateway
    dataValidation: TStateDataValidationOnEditDetails
    jwkSetString: string
    mode: ":VIEW:" | ':EDIT:'
    showAll: boolean
    transactions: boolean
    userDirsSlim: TUserDirSlim[]
}

export const TABS = [
    { title: "DETAILS" },
    { title: "DATA MAPPING" },
    { title: "CDS SERVICE AUTH" },
    { title: "USAGE" },
    { title: "TRANSACTIONS" },
    { title: "SUBSCRIPTIONS" },
    { title: "LOGGING" }
];
const TAB_SELECTED_BY_DEFAULT = 0
export const INIT_STATE: TState = {
    status: '',
    data: {},
    dataValidation: {
        name: '',
        hl7Url: '',
        oktaJwkUrl: '',
    },
    jwkSetString: '',
    mode: ':VIEW:',
    showAll: false,
    transactions: false,
    userDirsSlim: [],
};

// eslint-disable-next-line no-unused-vars
export type TOnSetState<T> = (state: T | ((prevState: T, props?: T) => T), cb?: () => void) => void

class GatewayEdit extends React.Component<TProps, TState> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        gateways: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,
        uiTabsSetSelected: PropTypes.func.isRequired,
    };

    public constructor(props) {
        super(props);
        this.state = { ...INIT_STATE };
    }

    public componentDidMount() {
        this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
        this.init()
    }

    public componentDidUpdate(prevProps) {
        const selectedTab = getPath(this.props, "ui.tabs.selected");
        const prevSelectedTab = getPath(prevProps, "ui.tabs.selected");

        if (selectedTab !== prevSelectedTab) {
            this.init()
        }
    }

    public render() {
        const { env, gatewayId: selectedGateway } = getLocationInfo(this.props.location, this.props.config);

        const userStrategy = getPath(this.props, "gateways.selected.data.userStrategy") || "";
        const isInteropioUserStrategy: boolean = userStrategy === "INTEROPIO";
        const fullContent: boolean = ((this.props.ui.tabs.selected === 3 && !isInteropioUserStrategy) || this.props.ui.tabs.selected === 4 || this.props.ui.tabs.selected === 5 || this.props.ui.tabs.selected === 6)
        const plusContent: boolean = this.props.ui.tabs.selected === 1

        return <div style={{ width: "100%" }}>
            <InitMachine
                parentState={this.state}
                onSetParentState={this.onSetState}
            />

            <NavbarL2 title={(<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ height: "56px", marginRight: "24px" }}>
                    <Link to={`/${env}/gateways`} style={{ color: "#fff", fontSize: "24px", textDecoration: "none" }}>
                        Gateways
                    </Link>
                </div>
                <Selector data-qa-label="navbar-gtw-selector-name" value={selectedGateway} icon={<Layers style={{color: "rgba(255, 255, 255, 0.87)", margin: "0 8px 0 0" }} />}
                    data-qa="navbar-gtw-selector-name" data={this.props.gateways.all ? this.props.gateways.all.data.map(a => ({ id: a.gatewayId, name: a.name })) : []}
                    onChange={item => {
                        this.props.history.push(`/${env}/gateways/${item.id}`);
                        this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
                        this.init()
                    }}
                />
            </div>
            )} />

            <NavbarL3 disableAll={this.state.status !== "ready"} />

            <div className={`container${fullContent ? " full" : plusContent ? " mid" : ""}`}>
                {["ready", "wait"].indexOf(this.state.status) < 0
                    ? <CenteredCircularProgress size={63} style={{ padding: "24px" }} />
                    : this.renderTabContent()}
            </div>
            <br />
        </div>;
    }

    private renderTabContent = () => {
        const contentProps = {
            ...this.props,
            state: this.state,
            initialize: this.init,
            onSetState: this.onSetState
        }

        if (this.props.ui.tabs.selected === 0) {
            return <Details
                parentState={this.state}
                getQueueName={this.getQueueName}
                initialize={this.init}
                onSetParentState={this.onSetState}
            />
        }
        if (this.props.ui.tabs.selected === 1) {
            return <GatewayEditDataMapping onSetParentState={this.onSetState} />
        }
        if (this.props.ui.tabs.selected === 2) {
            return <CdsServiceAuth {...contentProps} />;
        }
        if (this.props.ui.tabs.selected === 3) {
            return <Usage {...contentProps} />;
        }
        if (this.props.ui.tabs.selected === 4) {
            return <Transactions {...contentProps} />;
        }
        if (this.props.ui.tabs.selected === 5) {
            return <Subscriptions {...contentProps} />;
        }
        if (this.props.ui.tabs.selected === 6) {
            return <Logging {...{...contentProps, type: "gateway", refresh: true}} />;
        }

        return null;
    }

    private onSetState: TOnSetState<TState> = (state, cb = () => { }) => {
        this.setState(state, cb)
    }

    private getQueueName = () => {
        return this.state.data.hl7Properties?.queueName ?? ''
    }

    private init = () => {
        this.setState({ status: "init" })
    }
}

const mapStateToProps = (state, ownProps) => ({ ...state, ...ownProps });
const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(GatewayEdit))
