import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import "./style.less";

const Component = (props) => {
    if (props.to === "") {
        return <div>{props.children}</div>
    }
    return <Link to={props.to} className={`custom-link-component ${props.className}`} style={props.style}>
        {props.children}
    </Link>;
}

Component.propTypes = {
    children: PropTypes.any.isRequired,
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    fallbackText: PropTypes.string
};

Component.defaultProps = {
    fallbackText: "No link",
    className: "",
    style: {}
};

export default Component;
