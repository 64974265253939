import * as React from "react";
import * as PropTypes from "prop-types";
import {TextField} from "@material-ui/core";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    return <div className="user-environment-permissions-h1-content">
        <div className="content-left-side">
            <TextField name="environment" value={props.dialogState.filters.environment || ""} placeholder="Search environment"
                style={{marginTop: "8px", borderRadius: "2px"}} onChange={e => props.onSetDialogState({filters: {...props.state.filters, environment: e.target.value}})}/>
        </div>
        <div className="content-right-side"/>
    </div>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired,
    dialogState: PropTypes.object,
    onSetDialogState: PropTypes.func.isRequired
};

export default Component;
