import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogActions from "./CustomDialogActions";
import CustomDialogContent from "./CustomDialogContent";
import CustomDialogTitle from "./CustomDialogTitle";

interface IProps {
    parentStatus: string;
    selectedSessions: any[];
    setParentStatus: Function;
    setDialog: Function;
    setSelectedSessions: Function;
}

const DeleteSessions: React.FC<IProps> = (props: IProps) => {
    function onClose() {
        props.setSelectedSessions([]);
        props.setDialog("");
    }

    return <Dialog open disableBackdropClick disableEscapeKeyDown scroll="body">
        <CustomDialogTitle selectedSessions={props.selectedSessions} onClose={onClose} />
        <CustomDialogContent parentStatus={props.parentStatus} selectedSessions={props.selectedSessions} />
        <CustomDialogActions parentStatus={props.parentStatus} selectedSessions={props.selectedSessions} onClose={onClose} setParentStatus={props.setParentStatus} />
    </Dialog >
}

export default DeleteSessions;
