import * as React from "react";
import * as PropTypes from "prop-types";
import DisplayMessage from "./DisplayMessage";

export default class extends React.Component<any, any> {
    public static propTypes = {
        state: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired
    };

    public render() {
        const propSet = {...this.props, onClose: this.props.onClose};

        let content = null;
        switch (this.props.state.dialog) {
            case ":DISPLAY_MESSAGE:":
                content = <DisplayMessage {...propSet} />;
                break;
        }

        return content;
    }
}
