import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Header from '../../../../Widgets/Editor/Header';
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {FilterByDate} from '../../../../Widgets/Table';
import RefreshIcon from '@material-ui/icons/Refresh'
import SearchIcon from '@material-ui/icons/Search'
import HelpIcon from '@material-ui/icons/Help';
import PageviewIcon from '@material-ui/icons/Pageview';
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import axiosWrapper from '../../../../../lib/axiosWrapper'
import getEnvironmentName from '../../../../../lib//getEnvironmentName';
import withLegacyTheme from '../../../../../lib/hoc/with-legacy-theme'
import {
    delay,
    getFullDateDisplay,
    getFullDateTimeMsDisplay,
    tryParseJSONObject
} from '../../../../../lib/utils'
import Pagination from '../../../../Widgets/Table/Pagination';
import { isEnvironmentAdmin } from '../../../../../lib/user-environment-permissions-helpers';

import './style.less'

let moment = require('moment-timezone');

const CUSTOM_TABLE_CELL_STYLE: React.CSSProperties = {
    padding: '0 4px',
}
const CUSTOM_TABLE_HEADER_CELL_STYLE: React.CSSProperties = {
    ...CUSTOM_TABLE_CELL_STYLE,
    fontSize: '13px',
}
const CUSTOM_TABLE_BODY_CELL_STYLE: React.CSSProperties = {
    ...CUSTOM_TABLE_CELL_STYLE,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'inherit',
}

const CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE: React.CSSProperties = {
    ...CUSTOM_TABLE_CELL_STYLE,
    whiteSpace: 'normal',
    overflowWrap: 'anywhere',
}

const MENU_ICON_STYLE: React.CSSProperties ={
    padding: '5px 24px 5px 5px',
    opacity: 0.5,
}

type TProps = {
    accounts: any
    apps: any
    config: any
    environments: any
    gateways: any
    location: any
    login: any
    muiTheme: any
    parentState: any
    ui: any
    uiTabsSetSelected: Function
    onSetState: Function
}

export type TLaunch = {
    id: string
    gateway_id: string | null
    session_id: string | null
    maxTime: number | null
    minTime: number | null
    timeToLaunch: number | null
    timeToSmartLaunch: number | null
    timeToAppReady: number | null
    timeToAppComplete: number | null
    auth_principal_username: string | null
    fhir_user: string | null
    launch_context: string | null
    patient_in_context: string | null
}

type TLaunches = Partial<{
    status: ':READY:' | ':PENDING:'
    total: number | null
    currentPage: number | null
    data: Partial<TLaunch>[]
    requestedAt: number | null
    updatedAt: number | null
}> | null

let searchTermTimer = null

const runAnalyticsQuery = (props, dataType, query) => {
    return axiosWrapper(props.config.analyticsService, dataType, "POST", query)
};

const getStartDate = (endDate, timeframe, timeZone) => {
    let midnight = moment.tz(endDate - timeframe, timeZone).startOf("day");
    return new Date(midnight.format());
}

const AppLaunches: React.FC<TProps> = (props: TProps) => {
    const [dbSearchTerm, setDbSearchTerm] = React.useState('')
    const [expandedItems, setExpandedItems] = React.useState<string[]>([])
    const [launches, setLaunches] = React.useState<TLaunches>(null)
    const [menuAnchorElement, setMenuAnchorElement] = React.useState(null)
    const [searchTerm, setSearchTerm] = React.useState<string | null>(null)
    const [startDate, setStartDate] = React.useState<number | null>(getStartDate(new Date().getTime(), 0, props.ui.timeZone).getTime())
    const [endDate, setEndDate] = React.useState<number | null>(new Date().getTime())
    const [size, setSize] = React.useState<number | null>(50)
    const [pagination, setPagination] = React.useState<object | null>({
        goToPage: () => {},
        loading: true,
        size: size,
        number: 1,
        totalElements: 0,
        setPageSize: size => setSize(size)
    })

    const setTimeFilter = (props, type, value, timeframe) => {
        if (type === "period") {
            // setTimeframe(-1)
            setStartDate(value.startTime.valueOf())
            setEndDate(value.endTime.valueOf())
        } else {
            // setTimeframe(timeframe)
            setStartDate(getStartDate(new Date(), timeframe, props.ui.timeZone).getTime())
            setEndDate(new Date().getTime())
        }
    }

    const fetchData = React.useCallback(async (page = 1) => {
        page--
        const ts = Date.now()
        setLaunches({
            status: ':PENDING:',
            currentPage: 0,
            data: null,
            requestedAt: ts,
            updatedAt: ts,
        })
        const {
            accountId,
            environmentId,
            clientId,
        } = props.apps.selected.data


        let query = {
            startTime: startDate,
            endTime: endDate,
            clientId: clientId,
            accountId: accountId,
            environmentId: environmentId,
            size,
            page
        }
        let data = []
        await runAnalyticsQuery(props, "app-launches", query).then(async (res) => {
            const resourceBuckets = res.data.aggregations.session_id.buckets;

            for (let i = 0; i < resourceBuckets.length; i++) {
                let curBucket = resourceBuckets[i];
                let curData = {};

                // Calculate the 3 times
                let timeToSmartLaunch
                let timeToAppReady
                let timeToAppComplete
                const startTime = curBucket.minTime.value || 0
                const smartLaunchTime = curBucket.resource.hits.hits.find((r) => r._source.event_type == "Obtained Access Token (interopiO)")?._source.timestamp || 0
                const appReadyTime = curBucket.resource.hits.hits.find((r) => r._source.event_type == "App Ready")?._source.timestamp || 0
                const appCompleteTime = curBucket.resource.hits.hits.find((r) => r._source.event_type == "App Launch Complete")?._source.timestamp || 0
                const smartLaunchEvent = curBucket.resource.hits.hits.find((r) => r._source.event_type == "Smart Launch")
                if (smartLaunchEvent) {
                    timeToSmartLaunch = smartLaunchEvent._source.duration
                }
                if (smartLaunchTime && startTime) {
                    timeToSmartLaunch = smartLaunchTime - startTime
                }
                if (appReadyTime && startTime) {
                    timeToAppReady = appReadyTime - startTime
                }
                if (appCompleteTime && startTime) {
                    timeToAppComplete = appCompleteTime - startTime
                }

                curData["timeToSmartLaunch"] = timeToSmartLaunch
                curData["timeToAppReady"] = timeToAppReady
                curData["timeToAppComplete"] = timeToAppComplete
                curData["session_id"] = curBucket.key;
                curData["maxTime"] = curBucket.maxTime.value;
                curData["minTime"] = startTime;
                curData["timeToLaunch"] = curBucket.timeToLaunch.value;
                curData["id"] = curBucket.resource.hits.hits[0]._id;
                let userInfo = curBucket.resource.hits.hits.find((r) => r._source.auth_principal_username && r._source.event_type !== "App Ready" && r._source.event_type !== "App Launch Complete")?._source.auth_principal_username || '[]';
                userInfo = tryParseJSONObject(userInfo);
                curData["auth_principal_username"] = userInfo?.name || userInfo?.email || "";
                curData["fhir_user"] = userInfo?.fhirUser || "";
                curData["gateway_id"] = curBucket.resource.hits.hits[0]._source.gateway_id;
                let launchContext = curBucket.resource.hits.hits.find((r) => r._source.launch_context)?._source.launch_context || '[]';
                curData["launch_context"] = JSON.stringify(launchContext[0]?.parameters)
                curData['patient_in_context'] = launchContext[0]?.parameters?.patientName || launchContext[0]?.parameters?.patient || ""

                data.push(curData);
            }

            setLaunches((prevState) => {
                return {
                    ...(prevState || {}),
                    total: res.data.aggregations.unique_total.value,
                    currentPage: page,
                    data: data,
                    status: ':READY:',
                    updatedAt: Date.now(),
                }
            })
        });

        setExpandedItems([])
        setMenuAnchorElement(null)
    }, [
        // dbSearchTerm,
        startDate,
        endDate,
        props,
        size
    ])

    React.useEffect(() => {
        if (launches) {
            setPagination({
                goToPage: fetchData,
                loading: launches?.status !== ":READY:",
                size: size,
                number: launches?.currentPage + 1,
                totalElements: launches?.total || 0,
                setPageSize: size => setSize(size)
            })
        }
    }, [
        launches,
        size,
        fetchData
    ])

    async function exportData(separator) {
        setMenuAnchorElement(null)

        let element = document.createElement('a')
        element.style.display = 'none'

        const data = (launches?.data || [])
            .reduce(
                (acc, it) => {
                    return acc + `${it.id}${separator}`
                        + `${it.minTime}${separator}`
                        + `${getFullDateTimeMsDisplay(it.minTime, props.ui.timeZone)}${separator}`
                        + `${it.timeToSmartLaunch || "-"}${separator}`
                        + `${it.timeToAppReady || "-"}${separator}`
                        + `${it.timeToAppComplete || "-"}${separator}`
                        + `${it.gateway_id}${separator}`
                        + `${it.session_id}${separator}`
                        + `${it.auth_principal_username}${separator}`
                        + `${it.fhir_user}${separator}`
                        + `${it.patient_in_context}${separator}`
                        + `${it.launch_context}${separator}`
                        + `\r\n`
                },
                `ID${separator}`
                    + `Event Time Timestamp${separator}`
                    + `Event Time${separator}`
                    + `SMART Launch(ms)${separator}`
                    + `App Ready(ms)${separator}`
                    + `App Launch Compl.(ms)${separator}`
                    + `Gateway Id${separator}`
                    + `Session Id${separator}`
                    + `User${separator}`
                    + `FHIR User${separator}`
                    + `Patient in context${separator}`
                    + `Launch Context${separator}`
                    + '\r\n'
            )

        const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })
        const url = URL.createObjectURL(blob)
        element.setAttribute('href', url)

        const extension = (separator === ',' ? 'csv' : 'tsv')
        const accountName = props.accounts.selected.data.name;
        const environmentId = props.apps.selected.data.environmentId;
        const environmentName = getEnvironmentName(environmentId, props.environments);
        const clientName = props.apps.selected.data.name;
        const filename = `${accountName}_${environmentName}_${clientName}_Launches-${getFullDateDisplay(Date.now(), props.ui.timeZone)}.${extension}`
        element.setAttribute('download', filename)

        document.body.appendChild(element)
        await delay(1550)
        element.click()
        await delay(1550)
        document.body.removeChild(element)
        element = null
    }

    // dbSearchTerm
    React.useEffect(() => {
        if (searchTermTimer) {
            clearTimeout(searchTermTimer)
        }
        searchTermTimer = setTimeout(() => {
            setDbSearchTerm(searchTerm ?? '')
        }, 550)
    }, [searchTerm])

    // fetch data
    React.useEffect(() => {
        fetchData()
    }, [fetchData])

    // filtering launches
    const filteredLaunches = (launches?.data || [])
        .filter(({ session_id, gateway_id }) => session_id.includes(dbSearchTerm) || gateway_id.includes(dbSearchTerm))
        .sort(({ minTime: tsa }, { minTime: tsb }) => {
            if (tsa - tsb > 0) return -1
            if (tsa - tsb < 0) return 1
            return 0
        })

    const actAsAdmin = isEnvironmentAdmin(props.login, props.location, props.config);
    if (!actAsAdmin) {
        return <div className="container">
            <Card style={{marginTop: "48px"}}>
                <Header {...props} style={{height: "32px", padding: "8px 16px", borderTop: "none"}}/>
                <CardContent>
                    <p style={{textAlign: "center"}}>
                        <big>
                            <strong>
                                This screen is only available to the account owners or environment admins
                            </strong>
                        </big>
                    </p>
                </CardContent>
            </Card>
        </div>
    }

    return (
        <Card style={{ marginTop: '48px', maxWidth: "1500px" }}>
            <div
                style={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '48px',
                    margin: 0,
                    padding: '0 12px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    background: props.muiTheme.palette.accent2Color,
                }}
            >
                <FilterByDate {...props} key="data_filter" setFilterItem={(item, start, end) => setTimeFilter(props, item, start, end)}/>
                <SearchIcon style={{ marginRight: '12px' }} />
                <TextField
                    value={searchTerm ?? ''}
                    placeholder="Session Id or Gateway Id"
                    style={{
                        width: '90%',
                        marginRight: '12px',
                    }}
                    onChange={(event) => {
                        setSearchTerm(event.target.value)
                    }}
                />

                <div style={{ flex: 1 }} />

                {launches?.status === ':READY:' ? (
                    <IconButton
                        onClick={() => {
                            fetchData()
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>
                ) : <CircularProgress />}

                <IconButton
                    onClick={() => {
                        const url = "https://support.interopio.com/hc/en-us/articles/12458811312788";
                        window.open(url, "_blank");
                    }}
                >
                    <HelpIcon />
                </IconButton>

                <IconButton
                    disabled={launches?.status !== ':READY:' || !launches?.data?.length}
                    onClick={(event) => {
                        setMenuAnchorElement(event.currentTarget)
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    open={!!menuAnchorElement}
                    anchorEl={menuAnchorElement}
                    onClose={() => setMenuAnchorElement(null)}
                >
                    <MenuItem
                        onClick={() => {
                            exportData(',')
                        }}
                    >
                        <UnarchiveIcon style={MENU_ICON_STYLE} />
                        &nbsp;Export CSV
                    </MenuItem>
                    <MenuItem
                        disabled={launches?.status !== ':READY:' || !launches?.data?.length}
                        onClick={() => {
                            exportData('\t')
                        }}
                    >
                        <UnarchiveIcon style={MENU_ICON_STYLE} />
                        &nbsp;Export TSV
                    </MenuItem>
                </Menu>
            </div>

            {/* Header L2 */}
            <div
                style={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '32px',
                    margin: 0,
                    padding: '0 12px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    background: props.muiTheme.palette.accent2Color,
                }}
            >
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '250px',
                    }}
                >
                    Event Time
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '220px',
                    }}
                >
                    Gateway Id
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '250px',
                    }}
                >
                    User
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '250px',
                    }}
                >
                    FHIR User
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '280px',
                    }}
                >
                    Patient in context
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '130px',
                    }}
                >
                    SMART Launch
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '130px',
                    }}
                >
                    App Ready
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '130px',
                    }}
                >
                    App Launch Compl.
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '48px',
                    }}
                >
                    Session
                </div>
            </div>

            {/* Body */}
            <div
                style={{
                    margin: 0,
                    padding: 0,
                }}
            >
                {filteredLaunches.length ? (
                    filteredLaunches.map((it) => {
                        const isExpanded = expandedItems.includes(it.id)
                        return (
                            <div
                                key={it.id}
                                className="session-inspector-log-item-wrapper"
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                    backgroundColor: isExpanded ? 'rgb(245, 245, 245)' : undefined,
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    props.onSetState({
                                        selectedAppLaunch: it,
                                        dialog: ':APP_LAUNCH_INSPECT:'
                                    })
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: isExpanded ? 'start' : 'center',
                                        margin: 0,
                                        padding: isExpanded ? '12px' : '0 12px',
                                    }}
                                >
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '250px',
                                        }}
                                    >
                                        {getFullDateTimeMsDisplay(it.minTime, props.ui.timeZone)}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '220px',
                                        }}
                                    >
                                        {it.gateway_id}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '250px',
                                        }}
                                    >
                                        {it.auth_principal_username}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '250px',
                                        }}
                                    >
                                        {it.fhir_user}
                                    </div>
                                    <div
                                        className='log-item-clickable'
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '280px',
                                        }}
                                    >
                                        {it.patient_in_context}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '130px',
                                        }}
                                    >
                                        {it.timeToSmartLaunch ? it.timeToSmartLaunch/1000 + " sec." : "-"}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '130px',
                                        }}
                                    >
                                        {it.timeToAppReady ? it.timeToAppReady/1000 + " sec." : "-"}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '130px',
                                        }}
                                    >
                                        {it.timeToAppComplete ? it.timeToAppComplete/1000 + " sec." : "-"}
                                    </div>
                                    <IconButton
                                        disabled={launches.status !== ':READY:'}
                                        onClick={() => {
                                            props.onSetState({
                                                selectedSessionId: it.session_id,
                                                selectedMetadata: ''
                                            }, () => {
                                                props.uiTabsSetSelected(4)
                                            })
                                        }}
                                    >
                                        <PageviewIcon />
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <Typography
                        align="center"
                        style={{
                            margin: 0,
                            padding: '12px',
                        }}
                    >
                        No data matches your search criteria
                    </Typography>
                )}
                <Pagination {...pagination} />
            </div>
        </Card>
    )
}

const mapStateToProps = (state, ownProps) => ({
    accounts: state.accounts,
    apps: state.apps,
    config: state.config,
    environments: state.environments,
    gateways: state.gateways,
    login: state.login,
    ui: state.ui,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(withLegacyTheme()(AppLaunches)))
