export default function (
    accountId: string,
    environmentId: string,
    gatewayId: string,
    proxyApi: any
): string {
    const {
        host,
        protocol,
        path,
    } = proxyApi;
    let port = proxyApi.port;
    port = port === "80" || port == "443" ? "" : `:${port}`;

    return `${protocol}://${host}${port}/${path}/${accountId}/${environmentId}/${gatewayId}`;
}
