import * as PropTypes from "prop-types";

const GenericItem = (props) => {
    return (
        <div
            data-qa="generic-item"
            style={{ paddingLeft: 16, marginBottom: 6 }}
        >
            <span style={{
                fontWeight: "bold",
                width: 200,
                marginRight: 6,
                display: "inline-block",
            }}>
                {props.name}
            </span>
            <span>
                {props.value}
            </span>
        </div>
    );
}

GenericItem.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
}

export default GenericItem
