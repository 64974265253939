import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import getCDRsCallUrl from "../../../../lib/getCDRsCallUrl";
import getDataSourcesCallUrl from "../../../../lib/getDataSourcesCallUrl";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import consoleLogger from "../../../../lib/consoleLogger";
import getMessageFromResponse from "../../../../lib/getMessageFromResponse";
import {isEnvironmentAdmin} from "../../../../lib/user-environment-permissions-helpers";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import {NavbarL2} from "../../../CustomNavbar/";
import WidgetsDeleteConfirm from "../../../Widgets/DeleteConfirm";
import DeleteReject from "../DSDeleteReject";
import DSAddNewWizard from "../DSAddNewWizard/";
import "./style.less";
import DSCardsList from "./DSCardsList";
import DSTableList from "./DSTableList";

interface IState {
    status: "" | "ready" | "init";
    listItems: any[];
    dialog: "" | ":ADD_NEW_WIZARD:" | ":DELETE_CONFIRM:" | ":DELETE_REJECT:";
    delete: {
        rejectingMessage: string;
        dataSourcesId: string;
        name: string;
    };
    view: string;
}

export const INIT_STATE: IState = {
    status: "",
    listItems: [],
    dialog: "",
    delete: {
        rejectingMessage: "",
        dataSourcesId: "",
        name: ""
    },
    view: "CARD"
};

class DSList extends React.Component<any, any> {
    public static propTypes = {
        cdrs: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        dataSources: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        cdrsReadAll: PropTypes.func.isRequired,
        dataSourcesDelete: PropTypes.func.isRequired,
        dataSourcesReadAll: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        $(".stage").addClass("stage-bgnd-dark");
        this.initialize();
    }

    public componentWillUnmount() {
        $(".stage").removeClass("stage-bgnd-dark");
    }

    public render() {
        const {env} = getLocationInfo(this.props.location, this.props.config);
        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);

        const data = [...this.state.listItems].sort((a, b) => a.name.localeCompare(b.name));

        return <div className="data-sources">
            <NavbarL2 onViewSwitch={this.onViewSwitch} view={this.state.view} title={<Link to={`/${env}/datasources`} style={{color: "#fff", fontSize: 24, textDecoration: "none"}}>
                Data Adapters
            </Link>}/>

            <div className="container" style={{paddingTop: 48}}>
                <p style={{margin: "0 0 15px 0", color: this.props.muiTheme.palette.textColor, fontSize: 24, fontWeight: 400, lineHeight: "28px"}}>
                    Welcome to interopiO!
                </p>
                <p style={{width: 350, margin: "0 0 15px 0", color: this.props.muiTheme.palette.secondaryTextColor, fontSize: 16, fontWeight: 400, lineHeight: "20px"}}>
                    To get started, create a data adapter. Or explore the demo data adapter.
                </p>
                <p style={{margin: "0 0 60px 0"}}>
                    <a href="https://support.interopio.com/hc/en-us" target="_blank" style={{margin: "0 15px 0 0"}}>
                        Support
                    </a>
                </p>

                {this.state.status !== "ready"
                    ? <CenteredCircularProgress size={63}/>
                    : this.state.view !== "LIST"
                        ? <DSCardsList actAsAdmin={actAsAdmin} data={data} {...this.props} onSetState={this.onSetState}/>
                        : <DSTableList actAsAdmin={actAsAdmin} data={data} {...this.props} onSetState={this.onSetState}/>}
            </div>
            <br/>

            {this.renderDialogs()}
        </div>;
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private onViewSwitch = view => this.setState({view});

    private renderDialogs = () => {
        switch (this.state.dialog) {
            case ":ADD_NEW_WIZARD:":
                return <DSAddNewWizard
                    initialize={this.initialize}
                    onClose={() => this.setState({dialog: ""})}
                />;

            case ":DELETE_CONFIRM:":
                return <WidgetsDeleteConfirm {...this.props}
                    type="data adapter"
                    delete={this.state.delete}
                    onClose={() => this.setState({dialog: "", delete: {}})}
                    onDelete={async () => {
                        const {accountId} = getHostnameInfo();
                        const {env} = getLocationInfo(this.props.location, this.props.config);
                        const url = getDataSourcesCallUrl(accountId, env) + "/" + this.state.delete.dataSourceId;
                        try {
                            const response = await this.props.dataSourcesDelete(this.props.config, url);
                            if (response && response.status < 300) {
                                this.setState({
                                    dialog: "",
                                    delete: {}
                                }, this.initialize);
                            } else {
                                const rejectingMessage = getMessageFromResponse(response, true);
                                this.setState((prevState) => ({
                                    dialog: ":DELETE_REJECT:",
                                    delete: {
                                        ...prevState.delete,
                                        rejectingMessage
                                    }
                                }));
                            }
                        } catch (reason) {
                            consoleLogger.error("::: reason:", reason);
                        }
                    }}/>;

            case ":DELETE_REJECT:":
                return <DeleteReject
                    parentState={this.state}
                    onClose={() => this.setState({dialog: "", delete: {}}, this.initialize)}
                />;
            default:
                return null;
        }
    };

    private initialize = async () => {
        this.setState({status: "init"});
        const {accountId} = getHostnameInfo();
        const {env} = getLocationInfo(this.props.location, this.props.config);
        const dsUrl = getDataSourcesCallUrl(accountId, env);
        const cdrsUrl = getCDRsCallUrl(accountId, env);
        await Promise.all([
            this.props.dataSourcesReadAll(this.props.config, dsUrl),
            this.props.cdrsReadAll(this.props.config, cdrsUrl)
        ]);
        const listItems = [...this.props.dataSources.all.data, ...this.props.cdrs.all.data];
        this.setState({
            status: "ready",
            listItems
        });
    };
}

const mapStateToProps = (state, ownProps) => ({
    cdrs: state.cdrs,
    config: state.config,
    dataSources: state.dataSources,
    login: state.login,
    ...ownProps
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdrsReadAll: actions.cdrsReadAll,
    dataSourcesDelete: actions.dataSourcesDelete,
    dataSourcesReadAll: actions.dataSourcesReadAll
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(DSList));
