import * as React from "react";
import * as PropTypes from "prop-types";
import {TextField, Switch, Checkbox, FormControlLabel} from "@material-ui/core";

export default class extends React.Component<any, any> {
    public static propTypes = {
        state: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    };


    public render() {
        const canEdit: boolean = this.props.state.mode === ":EDIT:";

        return <div style={{padding: "0 24px"}}>
            {this.renderEnable(canEdit)}
            {this.renderEndpoint(canEdit)}
            {this.renderIssuer(canEdit)}
            {/* {this.renderSpIssuer(canEdit)} */}
            {this.renderCert(canEdit)}
            {this.renderConvert(canEdit)}
        </div>;
    }

    private renderEnable = canEdit => {
        return canEdit
            ? <div>
                <FormControlLabel label="Enable SSO"
                    control={<Switch defaultChecked={this.props.state.ssoData.enabled}
                        style={{width: "calc(100% - 50px)", marginTop: "14px", display: "inline-block"}}
                        onChange={e => this.props.onSetState({
                            ssoData: {
                                ...this.props.state.ssoData,
                                enabled: e.target.checked ? 1 : 0
                            }
                        })}
                    />}/>
            </div>
            : <div>
                <FormControlLabel label={`Enable SSO(${this.props.state.ssoData.enabled ? "Enabled" : "Disabled"})`}
                    control={<Switch defaultChecked={this.props.state.ssoData.enabled} style={{width: "calc(100% - 50px)", marginTop: "14px", display: "inline-block"}}
                        disabled/>}/>
            </div>;
    };
    private renderEndpoint = (canEdit) => {
        return canEdit
            ? <TextField name="name" value={this.props.state.ssoData.entryPoint || ""} placeholder="SAML 2.0 endpoint (HTTP)" label="SAML 2.0 Endpoint (HTTP)" fullWidth
                onChange={e => {
                    this.props.onSetState({
                        ssoData: {
                            ...this.props.state.ssoData,
                            entryPoint: e.target.value
                        }
                    });
                }}/>
            : <TextField name="name" value={this.props.state.ssoData.entryPoint || ""} label="SAML 2.0 Endpoint (HTTP)" fullWidth disabled
                classes={{root: "disabled-text-field"}} style={{cursor: "default", color: this.props.muiTheme.palette.textColor}}/>;
    };
    private renderIssuer = canEdit => {
        return canEdit
            ? <TextField name="name" value={this.props.state.ssoData.issuer || ""} placeholder="Identity provider issuer" label="Identity Provider Issuer" fullWidth
                onChange={e => {
                    this.props.onSetState({
                        ssoData: {
                            ...this.props.state.ssoData,
                            issuer: e.target.value
                        }
                    });
                }}/>
            : <TextField name="name" value={this.props.state.ssoData.issuer || ""} label="Identity Provider Issuer" fullWidth disabled classes={{root: "disabled-text-field"}}
                style={{cursor: "default", color: this.props.muiTheme.palette.textColor}}/>;
    };

    private renderCert = (canEdit) => {
        return canEdit
            ? <TextField name="name" value={this.props.state.ssoData.cert || ""} placeholder="Public certificate" label="Public Certificate " fullWidth
                onChange={e => {
                    this.props.onSetState({
                        ssoData: {
                            ...this.props.state.ssoData,
                            cert: e.target.value
                        }
                    });
                }}/>
            : <TextField name="name" value={"********"} label="Public Certificate " fullWidth disabled classes={{root: "disabled-text-field"}}
                style={{cursor: "default", color: this.props.muiTheme.palette.textColor}}/>;
    };

    private renderConvert = canEdit => {
        return canEdit
            ? <div>
                <FormControlLabel label="Convert all users to SSO users"
                    control={<Checkbox style={{paddingTop: "16px", fontSize: "14px"}} checked={this.props.state.ssoData.force}
                        onChange={e => {
                            this.props.onSetState({
                                ssoData: {
                                    ...this.props.state.ssoData,
                                    force: e.target.checked ? 1 : 0
                                }
                            });
                        }}/>}/>
                <TextField name="sso-conver-warning" disabled fullWidth error={!!this.props.state.ssoData.force} style={{height: 0, cursor: "default"}}
                    helperText={this.props.state.ssoData.force ? "This action will irreversibly convert all users to SSO users! Please test your SSO integration is working before enabling." : ""}
                    classes={{root: "disabled-text-field"}}/>
            </div>
            : <FormControlLabel label="Convert all users to SSO users" control={<Checkbox disabled checked={this.props.state.ssoData.force} style={{paddingTop: "16px", fontSize: "14px"}}
                onChange={e => {
                    this.props.onSetState({
                        ssoData: {
                            ...this.props.state.ssoData,
                            force: e.target.checked
                        }
                    });
                }}/>}/>
    };

}
