import * as React from "react";
import {Link} from "react-router-dom";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import { Delete } from "@material-ui/icons";

import {NavbarL2} from "../../../CustomNavbar/";
import PortalAddNewWizard from "../PortalAddNewWizard";
import {
    CardBodyAddNew,
    CardCustomContent,
    CardFooter,
    CardMenuBar,
    CardMenuImage,
    CardWrapper,
    CardsContainer,
} from "../../../Widgets/CustomCard";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import WidgetsDeleteConfirm from "../../../Widgets/DeleteConfirm/";
// import IconBed from "../../../Widgets/icons/Bed";
import IconPatient from "../../../Widgets/icons/Patient";
import IconPhysician from "../../../Widgets/icons/Physician";

import { isEnvironmentAdmin } from "../../../../lib/user-environment-permissions-helpers";
import { capitalizeFirstLetter } from "../../../../lib/utils";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import getPortalsCallUrl from "../../../../lib/getPortalsCallUrl";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import getPortalResourceUrl from "../../../../lib/getPortalResourceUrl";
import getPortalCallUrl from "../../../../lib/getPortalCallUrl";
import getPortalId from "../../../../lib/getPortalId";
import getPortalConfig from "../../../../lib/getPortalConfig";
import "./style.less";

export const INIT_STATE = {
    status: "",
    portals: [],
    delete: {
        gatewayId: "",
        name: ""
    },
    dialog: ""
};
// const IMG_STYLE = {width: "320px", height: "132px", backgroundSize: "320px 132px"}

class PortalList extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        $(".stage").addClass("stage-bgnd-dark");
        this.initialize();
    }

    public componentWillUnmount() {
        $(".stage").removeClass("stage-bgnd-dark");
    }

    public render() {
        const {env} = getLocationInfo(this.props.location, this.props.config);
        const {accountId} = getHostnameInfo();
        const actAsAdmin: boolean = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);

        let portals = this.state.portals.slice();
        portals.sort((a, b) => (a.name || "").localeCompare(b.name));

        return <div className="portals">
            <NavbarL2 title={<Link to={`/${env}/portals`} className="portals-page-title">
                Portals
            </Link>}/>

            <div className="container">
                <p className="title-line1" style={{color: this.props.muiTheme.palette.textColor}}>
                    interopiO® Portal Management Console
                </p>
                <p className="title-line2" style={{color: this.props.muiTheme.palette.secondaryTextColor}}>
                    Activate, configure, and manage Provider and Patient Portals on supported Gateways.
                </p>
                <p className="title-link">
                    <a href="https://support.interopio.com/hc/en-us" target="_blank">
                        Getting Started
                    </a>
                    <a href="https://support.interopio.com/hc/en-us" target="_blank">
                        Documentation
                    </a>
                    <a href="https://support.interopio.com/hc/en-us" target="_blank">
                        Help Center
                    </a>
                </p>

                {this.state.status !== "ready"
                    ? <CenteredCircularProgress size={63}/>
                    : <CardsContainer>
                        {actAsAdmin
                            ? <CardWrapper
                                className='portal-card-wrapper portal-add-new-card'
                                data-qa='create-new-portal-card'
                            >
                                <CardBodyAddNew
                                    text='ADD PORTAL'
                                    onClick={() => this.setState({dialog: ':ADD_NEW_PORTAL:'})}
                                />
                            </CardWrapper>
                            : null}
                        {portals.map((portal, key) => {
                            const portalId = getPortalId(portal)
                            let portalUrl = getPortalResourceUrl(
                                accountId,
                                env,
                                portal.gatewayId,
                                portalId,
                                getPortalConfig(portal),
                                portal.shortcut || '',
                            )
                            const isProviderPortal = portal.portalType === 'PROVIDER'
                            return (
                                <CardWrapper
                                    className='portal-card-wrapper'
                                    key={key}
                                    onClick={() => this.selectPortal(env, portalId)}
                                    data-qa={`portal-${portal.name}-card`}
                                >
                                    <div style={{ width: '100%' }}>
                                        <CardMenuImage
                                            data-qa={`portal-${portal.name}-menu`}
                                            config={this.props.config}
                                            menuItems={actAsAdmin ? [
                                                {
                                                    'data-qa': `portal-${portal.name}-delete-btn`,
                                                    leftIcon: <Delete style={{ height: "18px", width: "18px", margin: "4px" }} />,
                                                    text: "Delete",
                                                    onClick: () => this.setState({
                                                        dialog: ":DELETE_CONFIRM:",
                                                        delete: portal
                                                    })
                                                }
                                            ] : []}/>
                                        <CardMenuBar
                                            singleLineTitle
                                            title={portal.name || ''}

                                            style={{
                                                paddingBottom: '0px',
                                                paddingTop: '4px',
                                            }}
                                        />
                                        <CardCustomContent>
                                            <div>
                                                <Chip
                                                    label={(
                                                        <>
                                                            <Avatar
                                                                style={{
                                                                    display: 'inline-block',
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    left: '-11px',
                                                                    backgroundColor: `${isProviderPortal ? this.props.muiTheme.palette.primary1Color : this.props.muiTheme.palette.accent1Color}`,
                                                                }}
                                                            >
                                                                {isProviderPortal
                                                                    ? <IconPhysician
                                                                        fontSize='small'
                                                                        className='portal-pill-icon'
                                                                        style={{ left: '7px' }}
                                                                    />
                                                                    : <IconPatient
                                                                        fontSize='small'
                                                                        className='portal-pill-icon'
                                                                    />
                                                                }
                                                            </Avatar>
                                                            <span data-qa={`portal-${portal.name}-portal-type`}>{capitalizeFirstLetter((portal.portalType || '').toLowerCase())}</span>
                                                        </>
                                                    )}
                                                    classes={{ label: 'full-width-chip-label' }}
                                                />
                                            </div>
                                        </CardCustomContent>
                                    </div>

                                    <CardFooter
                                        // anchorPrefix={
                                        //     <Launch style={{ color: '#00567D', verticalAlign: 'middle' }}/>
                                        // }
                                        // anchorText={`LAUNCH PORTAL`}
                                        anchorHref={portalUrl}
                                        // linkTo={portalUrl}
                                        anchorTarget='_blank'
                                        anchorPreventPropagation
                                        isPortal
                                        style={{
                                            paddingRight: '8px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    />
                                </CardWrapper>
                            )
                        })}
                    </CardsContainer>}
            </div>

            {this.renderDialogs()}
        </div>;
    }

    private selectPortal = (env, portalId) => {
        this.props.history.push(`/${env}/portals/${portalId}`);
    };

    private renderDialogs = () => {
        switch (this.state.dialog) {
            case ":ADD_NEW_PORTAL:":
                return <PortalAddNewWizard {...this.props} onClose={() => this.setState({dialog: ""})}/>;

            case ":DELETE_CONFIRM:":
                return <WidgetsDeleteConfirm {...this.props} type="portal" delete={this.state.delete} onClose={() => this.setState({dialog: "", delete: {}})}
                    onDelete={async () => {
                        const {accountId} = getHostnameInfo();
                        const {env} = getLocationInfo(this.props.location, this.props.config);
                        const url = getPortalCallUrl(accountId, env, this.state.delete.gatewayId, this.state.delete.portalType) + "/" + getPortalId(this.state.delete);

                        const response = await this.props.portalsDelete(this.props.config, url);

                        if (response.status < 300) {
                            this.setState(
                                {
                                    dialog: "",
                                    delete: {}
                                },
                                () => this.initialize());
                        }
                    }}/>;

            default:
                return null;
        }
    };

    private initialize = () => {
        const {env} = getLocationInfo(this.props.location, this.props.config);
        this.setState(
            {status: "init"},
            async () => {
                const {accountId} = getHostnameInfo();
                const portalsUrl = getPortalsCallUrl(accountId, env);

                const responses = await Promise.all([this.props.portalsReadAll(this.props.config, portalsUrl)]);

                const [{data: portals = INIT_STATE.portals} = {}] = responses;

                this.setState({status: "ready", portals});
            }
        );
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(PortalList));
