import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { PDFDownloadLink } from '@react-pdf/renderer'
import Button from "@material-ui/core/Button";
import {isEnvironmentAdmin} from "../../../../../lib/user-environment-permissions-helpers";
import withLegacyTheme from '../../../../../lib/hoc/with-legacy-theme'
import { Search, Table } from "../../../../Widgets/Table";
import AppUserItem from "../Shared/AppUserItem";
import UsersPdf from "./UsersPdf"

const Users = (props) => {
    const actAsAdmin = isEnvironmentAdmin(props.login, props.location, props.config);
    const editableDirectoryType = props.parentState?.data?.directoryType === "INTEROPIO_USERS";

    const appUsers = props.parentState?.appUsers || [];

    const leftFilters = [
        {
            type: "component",
            value: <Search
                key={1}
                setFilterItem={(_, terms = []) => props.onSetParentState({ filters: { ...props.parentState.filters, appUser: terms.join("") } })}
                hint="Search by name, email or UID"
            />,
        },
    ];

    const rightFilters = actAsAdmin
    // && editableDirectoryType
        ? [
            {
                type: "component",
                value: (
                    editableDirectoryType && <Button data-qa="add-user-action"
                        key={1} variant="contained" color="primary"
                        style={{ top: 5, marginRight: 16 }}
                        onClick={() => props.onSetParentState({ dialog: ":ADD_APP_USER:" })}
                    >Add User</Button>
                )
            },
        ] : [];

    const columns = [
        {
            size: 2,
            name: "name",
            label: "User",
            centered: true,
            sort: () => props.onSetParentState({sortBy: {["name"]: !props.parentState.sortBy.name}}),
            order: props.parentState?.sortBy.name,
        },
        {
            size: 1,
            name: "createdAt",
            label: "Created",
            sort: () => props.onSetParentState({sortBy: {["createdAt"]: !props.parentState?.sortBy?.createdAt}}),
            order: props.parentState?.sortBy.createdAt,
        },
        {
            size: 1,
            name: "lastLogin",
            label: "Last Login",
            sort: () => props.onSetParentState({sortBy: {["lastLogin"]: !props.parentState?.sortBy?.lastLogin}}),
            order: props.parentState?.sortBy.lastLogin,
        },
        {
            size: 3,
            name: "uid",
            label: "UID",
            centered: true,
            sort: () => props.onSetParentState({sortBy: {["uid"]: !props.parentState?.sortBy?.uid}}),
            order: props.parentState?.sortBy.uid,
        }
    ];

    return <Table
        leftFilters={leftFilters}
        rightFilters={rightFilters}
        columns={columns}
        export={[
            {
                func: () => {},
                label: (
                    <PDFDownloadLink
                        document={(
                            <UsersPdf
                                appUsers={appUsers}
                                directoryType={props.parentState.data.directoryType}
                                ui={props.ui}
                            />)}
                        fileName={`${props.parentState.data.name}.pdf`}
                        style={{ 
                            textDecoration: 'none',
                            color: props.muiTheme.palette.textColor,
                        }}
                    >
                        {({ loading }) => (loading ? 'Loading document...' : 'Export PDF')}
                    </PDFDownloadLink>
                ),
            }
        ]}
    >
        {appUsers.map((appUser, index) => <AppUserItem
            data-qa={`app-user-item-${appUser.uid}`}
            key={`app-user-item-${appUser.uid}-${index}`}
            appUser={appUser}
            parentState={props.parentState}
            clickable showMenu
            initialize={props.initialize}
            onSetParentState={props.onSetParentState}
        />)}
    </Table>;
};

Users.propTypes = {
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,

    initialize: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    login: state.login,
    ui: state.ui,
    ...ownProps,
});
export default withRouter(connect(mapStateToProps)(withLegacyTheme()(Users)))
