import * as types from "../../actions/types";

import initialState from "./initial-state";

export default function (state = initialState, action: any) {
    switch (action.type) {
        case types.APPS_ALL_SET: {
            return {
                ...state,
                all: {
                    status: action.payload.status,
                    data: [...action.payload.data],
                },
            };
        }

        case types.APPS_SELECTED_SET: {
            let selected = {
                status: action.payload.status,
                data: {
                    ...action.payload.data,
                },
                error: { ...action.payload.error },
            };
            return {
                ...state,
                selected: selected,
            };
        }

        default:
            return state;
    }
}
