import * as React from 'react'
import * as PropTypes from 'prop-types'
import * as validateData from '../../../../lib/validate-portal-data'
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@material-ui/core'
import getPortalResourceUrl from '../../../../lib/getPortalResourceUrl'
import getHostnameInfo from '../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../lib/getLocationInfo'
import getPortalConfig from '../../../../lib/getPortalConfig'

const INIT_STATE = {
    dataValidation: {},
    portalType: '',
}

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        gateways: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    }

    public constructor(props) {
        super(props)
        this.state = { ...INIT_STATE }
    }

    public componentDidMount() {
        this.props.onSetState({ isCreateEnabled: false })
        this.validateData()
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.state.data) !== JSON.stringify(prevProps.state.data)) {
            this.validateData()
        }
    }

    public render() {
        const {accountId} = getHostnameInfo()
        const {env} = getLocationInfo(this.props.location, this.props.config)
        const portalResourceUrl = getPortalResourceUrl(
            accountId,
            env,
            this.props.state.data.gatewayId || '{gateway-id}',
            '{portal-id}',
            getPortalConfig(this.props.state.data),
            this.props.state.data.shortcut || ''
        )

        return <div>
            <FormControl error={!!this.state.dataValidation.portalType} >
                <FormLabel component='legend'>Portal Type *</FormLabel>
                <RadioGroup
                    aria-label='portal-type'
                    data-e2e='create-portal-type'
                    name='portal-type'
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                    onChange={(e) => this.props.onSetState({ data: { ...this.props.state.data, portalType: e.target.value }})}
                >
                    <FormControlLabel
                        data-e2e='create-portal-type-provider'
                        control={<Radio color='primary' />}
                        label='Provider'
                        value='PROVIDER'
                    />
                    <FormControlLabel
                        data-e2e='create-portal-type-patient'
                        control={<Radio color='primary' />}
                        label='Patient'
                        value='PATIENT'
                    />
                </RadioGroup>
                {this.state.dataValidation.portalType ? (
                    <FormHelperText>
                        {this.state.dataValidation.portalType ?? ''}
                    </FormHelperText>
                ) : null}
            </FormControl>
            <TextField
                name='name'
                value={this.props.state.data.name || ''}
                placeholder='Type a name for the portal'
                label='Portal Name *'
                data-qa='new-portal-name'
                fullWidth
                onChange={e => this.props.onSetState({data: {...this.props.state.data, name: e.target.value}})}
                error={!!this.state.dataValidation.name}
                helperText={this.state.dataValidation.name}
            />
            <div>
                <FormControl
                    disabled={!this.props.state.gateways.length}
                    error={!!this.state.dataValidation.gatewayId}
                    fullWidth
                    style={{ marginTop: 16 }}
                >
                    <InputLabel id='new-portal-select-label'>
                        Supporting Gateway *
                    </InputLabel>
                    <Select
                        // Here's a link to an article explaining why we need these ManuProps settings
                        // to get our menu items to appear BELOW the text field instead of covering it
                        // (and why the default <Select> behavior designed by Material UI seems odd)
                        // https://medium.com/cloud-native-the-gathering/material-ui-how-to-change-a-menus-menuitems-anchor-to-popover-below-instead-of-over-ab222e175cfd
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        data-qa='new-portal-gateway'
                        value={this.props.state.data.gatewayId}
                        labelId='new-portal-select-label'
                        fullWidth
                        onChange={e => this.props.onSetState({data: {...this.props.state.data, gatewayId: e.target.value}})}
                    >
                        {this.props.state.gateways.map(gw => <MenuItem key={gw.gatewayId} data-qa={`new-portal-gw-${gw.gatewayId}`} value={gw.gatewayId}>{gw.name}</MenuItem>)}
                    </Select>
                    {this.state.dataValidation.gatewayId ? (
                        <FormHelperText>
                            {this.state.dataValidation.gatewayId ?? ''}
                        </FormHelperText>
                    ) : null}
                </FormControl>
            </div>
            <TextField
                name='shortcut'
                value={this.props.state.data.shortcut || ''}
                placeholder='no spaces, 4 characters long'
                label='Shortcut'
                data-qa='new-portal-shortcut'
                error={!!this.state.dataValidation.shortcut}
                helperText={this.state.dataValidation.shortcut}
                fullWidth
                style={{ marginTop: 16 }}
                onChange={e => this.props.onSetState({data: {...this.props.state.data, shortcut: e.target.value}})}
            />
            <TextField
                name='url'
                value={this.props.state.data.url || portalResourceUrl}
                label='Portal URL'
                style={{ marginTop: 32 }}
                data-qa='new-portal-url'
                disabled
                fullWidth
            />
        </div>
    }

    private validateData = () => {
        const dataValidation = validateData.onWStep1(this.props.state, this.props.state.gateways)
        this.setState(
            {dataValidation},
            () => {
                const isGatewayValid = Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true)
                isGatewayValid !== this.props.state.isCreateEnabled && this.props.onSetState({ isCreateEnabled: isGatewayValid })
            }
        );
    }
}
