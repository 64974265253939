import * as PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../redux/actions/";

import AppBar from '@material-ui/core/AppBar';

import "./style.less";

const NavbarL3 = (props) => {
    return (
        <AppBar className="navbar-l3" position="relative">
            {props.ui.tabs.items.map((item, index) => {
                let dataQaAttr = item["data-qa"];
                switch (item.title) {
                    case "USAGE": dataQaAttr = "usage-tab"; break;
                    case "TRANSACTIONS": dataQaAttr = "transactions-tab"; break;
                }

                const isSelected = index === props.ui.tabs.selected;
                const isDisabled = props.disableAll;
                const IconError = item.iconError;

                return (
                    <div
                        data-qa={dataQaAttr}
                        key={`tabs-${index}`}
                        className={`tab ${isSelected ? "tab-selected" : ""} ${isDisabled ? "tab-disabled" : ""} ${item.error ? "tab-error" : ""}`}
                        onClick={() => !isDisabled && props.uiTabsSetSelected(index)}
                    >
                        {IconError && <IconError/>}
                        {item.title}
                    </div>
                );
            })}
        </AppBar>
    );
}

NavbarL3.propTypes = {
    ui: PropTypes.object.isRequired,
    uiTabsSetSelected: PropTypes.func.isRequired,
    disableAll: PropTypes.bool,
};

NavbarL3.defaultTypes = {
    disableAll: false,
};

const mapStateToProps = (state, ownProps) => ({ ...state, ...ownProps });
const mapDispatchToProps = (dispatch) => bindActionCreators({
    uiTabsSetSelected: actions.uiTabsSetSelected,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(NavbarL3);
