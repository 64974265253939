import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import * as features from "../../../../lib/feature-helpers";
import getGatewaysCallUrl from "../../../../lib/getGatewaysCallUrl";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import {isEnvironmentAdmin} from "../../../../lib/user-environment-permissions-helpers";
import {Link} from "react-router-dom";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import {NavbarL2} from "../../../CustomNavbar/";
import WidgetsDeleteConfirm from "../../../Widgets/DeleteConfirm/";
import GatewayCardsList from "./GatewayCardsList";
import "./style.less";
import GatewayTableList from "./GatewayTableList";
import GatewayAddNewWizard from './GatewayAddNewWizard'
import InitMachine from './InitMachine'

export const INIT_STATE = {
    status: "",
    shallowGateways: [],
    delete: {
        gatewayId: "",
        name: ""
    },
    dialog: "",
    view: 'CARD'
};

class GatewayList extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        gatewaysDelete: PropTypes.func.isRequired,
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        $(".stage").addClass("stage-bgnd-dark");
        this.init()
    }

    public componentWillUnmount() {
        $(".stage").removeClass("stage-bgnd-dark");
    }

    public render() {
        const {env} = getLocationInfo(this.props.location, this.props.config);
        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);

        const featureSmileCdrCustomization = features.check(":SMILE_CDR_CUSTOMIZATION:", this.props.features.data);

        const data = this.state.shallowGateways
        data.sort((a, b) => a.name.localeCompare(b.name));

        const gatewaysList = {
            data,
            env,
            actAsAdmin,
            featureSmileCdrCustomization,
            history: this.props.history,
            onSetState: state => this.setState(state),
        };

        return <div className="gateways">
            <InitMachine
                parentState={this.state as any}
                onSetParentState={this.onSetState}
            />

            <NavbarL2 onViewSwitch={this.onViewSwitch} view={this.state.view} title={
                <Link to={`/${env}/gateways`} style={{color: "#fff", fontSize: "24px", textDecoration: "none"}}>
                    Gateways
                </Link>
            }/>

            <div className="container" style={{paddingTop: "48px"}}>
                <p style={{margin: "0 0 15px 0", color: this.props.muiTheme.palette.textColor, fontSize: "24px", fontWeight: 400, lineHeight: "28px"}}>
                    Welcome to interopiO!
                </p>
                <p style={{width: "350px", margin: "0 0 15px 0", color: this.props.muiTheme.palette.secondaryTextColor, fontSize: "16px", fontWeight: 400, lineHeight: "20px"}}>
                    To get started, create a gateway. Or explore the demo gateway.
                </p>
                <p style={{margin: "0 0 60px 0"}}>
                    <a href="https://support.interopio.com/hc/en-us" target="_blank" style={{margin: "0 15px 0 0"}}>
                        Support
                    </a>
                </p>

                {this.state.status !== "ready"
                    ? <CenteredCircularProgress size={63}/>
                    : this.state.view === 'LIST'
                        ? <GatewayTableList {...gatewaysList} />
                        : <GatewayCardsList {...gatewaysList} />}
            </div>

            {this.renderDialogs()}
        </div>;
    }

    private onViewSwitch = view => this.setState({view});

    private renderDialogs = () => {
        switch (this.state.dialog) {
            case ":ADD_NEW_WIZARD:":
                return (
                    <GatewayAddNewWizard
                        onClose={() => this.setState({ dialog: '' })}
                    />
                )

            case ":DELETE_CONFIRM:":
                return <WidgetsDeleteConfirm {...this.props} type="gateway" delete={this.state.delete}
                    onClose={() => this.setState({
                        dialog: "",
                        delete: {}
                    })}
                    onDelete={async () => {
                        const {accountId} = getHostnameInfo();
                        const {env} = getLocationInfo(this.props.location, this.props.config);
                        const url = getGatewaysCallUrl(accountId, env) + "/" + this.state.delete.gatewayId;

                        const response = await this.props.gatewaysDelete(this.props.config, url);

                        if (response.status < 300) {
                            this.setState(
                                {
                                    dialog: "",
                                    delete: {}
                                },
                                () => this.init(),
                            )
                        }
                    }}/>;
            default:
                return null;
        }
    };

    private onSetState = (state, cb) => this.setState(state, cb)

    private init = () => {
        this.setState({ status: 'init' })
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(GatewayList));
