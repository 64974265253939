import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import * as actions from "../../../../../redux/actions";
import getCDRsCallUrl from "../../../../../lib/getCDRsCallUrl";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../lib/getLocationInfo";
import {delay} from "../../../../../lib/utils/";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress/";
import Operations from "./Operations/";

const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "recalc",
    data: {},
    cashedResources: [],
    resources: [],
    filters: {
        resources: ":ALL:", // ":ALL:" | ":SUPPORTED:" | ":UNSUPPORTED:"
        operations: {
            read: false,
            search: false,
            create: false,
            update: false,
            delete: false
        }
    },
    sortBy: {
        type: true
    },
};

class CDREditSupportedOperations extends React.Component<any, any> {
    public static propTypes = {
        cdrs: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        cdrsRead: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.initialize();
    }

    public componentDidUpdate(_, prevState) {
        const filters_string = JSON.stringify(this.state.filters);
        const prevFilters_string = JSON.stringify(prevState.filters);
        const sortBy_string = JSON.stringify(this.state.sortBy);
        const prevSortBy_string = JSON.stringify(prevState.sortBy);
        if ((filters_string !== prevFilters_string) || (sortBy_string !== prevSortBy_string)) {
            this.setState(
                {status: "recalc"},
                async () => {
                    await delay(250); // delay() gives a little bit more time for the circular progress to be displayed
                    this.setState(
                        {resources: this.getResources()},
                        () => this.setState({status: "ready"}),
                    );
                },
            );
        }
    }

    public render() {
        return <div>
            {["ready", "recalc"].includes(this.state.status)
                ? <Operations state={this.state} initialize={this.initialize} onSetParentState={this.onSetState}/>
                : <CenteredCircularProgress size={63} style={{padding: 24}}/>}
            <br/>
        </div>;
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private getAllResourcesArray = () => {
        switch (this.props.cdrs.selected?.data?.fhirVersion) {
            case "FHIR_DSTU2__1_0_2":
                return this.props.config.fhirDSTU2Resources;
            case "FHIR_STU3__3_0_2":
                return this.props.config.fhirSTU3Resources;
            case "FHIR_R4__4_0_1":
                return this.props.config.fhirR4Resources;
            default:
                return [];
        }
    };

    private getFilteredResources(resources) {
        let filtered = [...resources];

        // *** Resource filter
        if (this.state.filters.resources === ":SUPPORTED:") {
            filtered = filtered.filter(fr => (
                Object.keys(fr)
                    .filter(key => key !== "type")
                    .reduce(((acc, key) => acc || fr[key]), false)
            ));
        } else if (this.state.filters.resources === ":UNSUPPORTED:") {
            filtered = filtered.filter(fr => {
                const keys = Object.keys(fr).filter(key => key !== "type");
                return !keys.reduce((acc, key) => acc || fr[key], false);
            });
        }

        // *** Operation filter
        for (const key in this.state.filters.operations) {
            if (this.state.filters.operations.hasOwnProperty(key)) {
                if (this.state.filters.operations[key]) {
                    filtered = filtered.filter((fr) => fr[key]);
                }
            }
        }

        return filtered;
    }

    private getSortedResources(resources) {
        const sortBy = this.state.sortBy;
        let sorted = [...resources];

        // Sort by "type"
        if (sortBy.type !== undefined) {
            if (sortBy.type === true) {
                sorted.sort((a, b) => {
                    if (a.type < b.type) return -1;
                    else if (a.type > b.type) return 1;
                    else return 0;
                });
            } else if (sortBy.type === false) {
                sorted.sort((a, b) => {
                    if (a.type < b.type) return 1;
                    else if (a.type > b.type) return -1;
                    else return 0;
                });
            }
        }

        ["read", "search", "create", "update", "delete"].forEach((operation) => this.sortByOperation(sorted, operation));

        return sorted;
    }

    private sortByOperation(sorted, operation) {
        const sortBy = this.state.sortBy;
        if (sortBy[operation] !== undefined) {
            if (sortBy[operation] === true) {
                sorted.sort((a, b) => {
                    if (!!a[operation] < !!b[operation]) return -1;
                    else if (!!a[operation] > !!b[operation]) return 1;
                    else return 0;
                });
            } else if (sortBy[operation] === false) {
                sorted.sort((a, b) => {
                    if (!!a[operation] < !!b[operation]) return 1;
                    else if (!!a[operation] > !!b[operation]) return -1;
                    else return 0;
                });
            }
        }
    }

    private getResources = (cashedResources?) => {
        let resources = [];

        if (cashedResources) {
            // 1.A. Clone cashedResources
            resources = [...cashedResources];
        } else {
            // 1.B. Clone state.cashedResources
            resources = [...this.state.cashedResources];
        }

        // 2. Add all other (unsupported) resources
        const allResourcesArray = this.getAllResourcesArray();
        allResourcesArray.forEach((rs) => !resources.find((fr) => fr.type === rs) && resources.push({type: rs}));

        // 3. Filter
        resources = this.getFilteredResources(resources);

        // 4. Sort
        resources = this.getSortedResources(resources);

        return resources;
    }

    private initialize = () => {
        this.setState(
            {status: "init"},
            async () => {
                const {accountId} = getHostnameInfo();
                const {env, cdrId} = getLocationInfo(this.props.location, this.props.config);
                const metadata = "true";
                await this.props.cdrsRead(this.props.config, getCDRsCallUrl(accountId, env) + "/" + cdrId + "?metadata=" + metadata);
                const resources = this.props.cdrs.selected.data?.metadata?.rest?.[0]?.resource || [];
                const cashedResources = [];
                if (resources.length) {
                    resources.forEach((re) => {
                        const type = re.type;
                        let read = false;
                        let search = false;
                        let create = false;
                        let update = false;
                        let del = false;
                        (re.interaction || []).forEach((ia) => {
                            switch (ia.code) {
                                case "read": read = true; break;
                                case "search":
                                case "search-type":
                                case "search-system": search = true; break;
                                case "create": create = true; break;
                                case "update": update = true; break;
                                case "delete": del = true; break;
                            }
                        });
                        cashedResources.push({type, read, search, create, update, delete: del});
                    });
                }
                this.setState({
                    ...INIT_STATE,
                    status: "ready",
                    data: this.props.cdrs.selected.data,
                    cashedResources,
                    resources: this.getResources(cashedResources)
                });
            },
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    cdrs: state.cdrs,
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdrsRead: actions.cdrsRead,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(CDREditSupportedOperations)));
