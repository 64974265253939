import * as React from 'react'
import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import { Table } from '../../../Widgets/Table'
import EhrListingItem from './EhrListingItem'

type TAppListing = {
    ehrListingUrl: string
    ehrName: string
    created: string
    updated: string
}

export type TProps = {
    canEdit?: boolean | false
    // children?: React.ReactNode
    muiTheme: {
        palette: {
            secondaryTextColor: string
            textColor: string
        }
    }
    state: Partial<{
        data: Partial<{
            accountId: string
            environmentId: string
            appId: string
            appListings: Partial<TAppListing>[]
            newAppListing: Partial<TAppListing>
        }>
        dataValidation: Partial<{
            newEhrListingUrl: string
            newEhrName: string
        }>
    }>
    onSetState: Function
}

const EhrListingUrls: React.FC<TProps> = (props: TProps) => {
    const canEdit = props.canEdit || false

    const appListings = props.state.data.appListings || []
    const columns = [
        {
            size: 1,
            name: "ehrName",
            label: <span style={{ paddingLeft: 20 }}>EHR</span>,
            centered: false,
        },
        {
            size: 1,
            name: "ehrListingUrl",
            label: <span style={{ paddingLeft: 2 }}>Listing URL</span>,
            centered: false,
        }
    ]

    return (
        <div className="ehr-listings">
            <h4 style={{
                marginBottom: 0,
                color: appListings.length ? props.muiTheme.palette.textColor : props.muiTheme.palette.secondaryTextColor }}
            >
                App EHR Listings
            </h4>

            {canEdit ? (
                <div className="new-ehr-listing MuiPaper-rounded MuiPaper-outlined">
                    <div className="new-ehr-listing-header">
                        <h5>Add New EHR Listing</h5>
                        <Fab
                            size="small"
                            disabled={(!props.state.data?.newAppListing?.ehrName
                                || !props.state.data?.newAppListing?.ehrListingUrl
                                || !!props.state.dataValidation.newEhrName
                                || !!props.state.dataValidation.newEhrListingUrl
                            )}
                            onClick={() => {
                                const newEhrName = props.state.data?.newAppListing?.ehrName || ''
                                const newEhrListingUrl = props.state.data?.newAppListing?.ehrListingUrl || ''
                                if (newEhrName && newEhrListingUrl) {
                                    const newAppListingItem = {
                                        ehrName: newEhrName,
                                        ehrListingUrl: newEhrListingUrl,
                                        appId: props.state.data.appId || '',
                                        environmentId: props.state.data.environmentId || '',
                                        accountId: props.state.data.accountId || '',
                                    }
                                    const newAppListings = [...appListings, newAppListingItem]
                                    props.onSetState({
                                        data: {
                                            ...props.state.data,
                                            appListings: newAppListings,
                                            newAppListing: {},
                                        },
                                    })
                                }
                            }}>
                            <AddIcon />
                        </Fab>
                    </div>

                    <TextField name="addNewEhrName"
                        label="New EHR Name"
                        value={props.state.data?.newAppListing?.ehrName || ''}
                        error={!!props.state.dataValidation.newEhrName}
                        fullWidth
                        onChange={(event) => props.onSetState({
                            data: {
                                ...props.state.data,
                                newAppListing: {
                                    ...props.state.data.newAppListing,
                                    ehrName: event.target.value,
                                },
                            },
                        })}
                    />

                    <TextField name="addNewEhrListingURL"
                        label="New EHR Listing URL"
                        value={props.state.data?.newAppListing?.ehrListingUrl || ''}
                        error={!!props.state.dataValidation.newEhrListingUrl}
                        fullWidth
                        style={{ marginTop: 24 }}
                        onChange={(event) => props.onSetState({
                            data: {
                                ...props.state.data,
                                newAppListing: {
                                    ...props.state.data.newAppListing,
                                    ehrListingUrl: event.target.value,
                                },
                            },
                        })}
                    />
                </div>
            ) : null}

            <Table columns={columns}>
                {appListings.map((listing, index) => (
                    <EhrListingItem
                        key={`ehr-listing-item-${index}`}
                        ehrName={listing.ehrName}
                        ehrListingUrl={(
                            <a href={listing.ehrListingUrl} target="_blank">
                                {listing.ehrListingUrl}
                            </a>
                        )}
                        created={listing.created}
                        updated={listing.updated}
                        itemIndex={index}
                        data={props.state.data}
                        canEdit={canEdit}
                        onSetState={props.onSetState}
                    />))}
            </Table>
        </div>
    )
}

export default EhrListingUrls
