import {getFullDateDisplay} from "../../../../../../lib/utils";
import "./style.less";
import * as PropTypes from "prop-types";

const LoginItem = props => {
    return <div className="login-item">
        <div>{props.user}</div>
        <div>{getFullDateDisplay(props.timestamp, props.timeZone)}</div>
        <div>{props.client}</div>
    </div>
};

LoginItem.propTypes = {
    user: PropTypes.string,
    timestamp: PropTypes.number,
    timeZone: PropTypes.string,
    location: PropTypes.string,
    client: PropTypes.string
};

export default LoginItem;
