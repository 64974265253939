import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../redux/actions";
import { withRouter } from "react-router";
import getUserDirsCallUrl from "../../../../../lib/getUserDirsCallUrl";
import Button from "@material-ui/core/Button";

const ActionButtons = (props) => {
    const canEdit = props.parentState.mode === ":EDIT:";
    const isValid = Object.keys(props.parentState.dataValidation).reduce((acc, key) => !!acc && !props.parentState.dataValidation[key], true);

    return <div style={{
        height: canEdit ? "auto" : 0,
        padding: canEdit ? 24 : "0 24px 0 0",
        textAlign: "right",
        opacity: canEdit ? 1 : 0,
        transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)",
    }}>
        <Button disabled={props.parentState.status !== "ready" || !canEdit} style={{ display: canEdit ? "inline-block" : "none", marginRight: 8 }}
            onClick={() => props.onSetParentState({ mode: ":VIEW:" }, props.initialize)}
        >
            Cancel
        </Button>
        <Button variant="contained" color="primary"
            disabled={props.parentState.status !== "ready" || !canEdit || !isValid}
            style={{ display: canEdit ? "inline-block" : "none" }}
            onClick={async () => {
                props.onSetParentState({ status: "waiting" });
                const accountId = props.parentState.data.accountId;
                const environmentId = props.parentState.data.environmentId;
                const directoryId = props.parentState.data.directoryId;
                const userDirUrl = getUserDirsCallUrl(accountId, environmentId) + `/${directoryId}`;
                const data = {
                    accountId,
                    environmentId,
                    directoryId,
                    name: props.parentState.data.name,
                    description: props.parentState.data.description || "",
                    directoryType: props.parentState.data.directoryType
                }
                const response = await props.userDirsUpdate(props.config, userDirUrl, data)
                if (response && response.status < 300 && response.data) {
                    props.onSetParentState({ mode: ":VIEW:" }, props.initialize);
                }
            }}
        >
            Save
        </Button>
    </div>;
}

ActionButtons.propTypes = {
    config: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired,
    userDirsUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    userDirsUpdate: actions.userDirsUpdate,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActionButtons));
