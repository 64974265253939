import * as React from "react";
import * as PropTypes from "prop-types";
import getGatewaysCallUrl from "../../../../lib/getGatewaysCallUrl";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import getPortalCallUrl from "../../../../lib/getPortalCallUrl";
import getPortalId from "../../../../lib/getPortalId";
import {Dialog, IconButton, Button, DialogActions, DialogContent, DialogTitle, Tooltip} from "@material-ui/core";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import {Close} from "@material-ui/icons";
import WStep1 from "./WStep1";
import "./style.less";

const INIT_STATE = {
    status: "",
    data: {},
    gateways: [],
    isNextWStepEnabled: false
};

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.initialize();
    }

    public render() {
        let stepProps = {
            ...this.props,
            state: this.state,
            onSetState: (state, cb) => this.setState(state, cb)
        };

        return <Dialog
            open
            style={{ paddingTop: '5px', zIndex: 1250 }}
            fullWidth
            className="new-portal-dialog"
            onClose={this.props.onClose}
        >
            <DialogTitle style={{background: this.props.muiTheme.palette.primary1Color}}>
                {this.getTitle()}
            </DialogTitle>
            <DialogContent className="new-portal-dialog-body">
                {this.state.status !== "ready"
                    ? <CenteredCircularProgress size={63} className="portals-loader"/>
                    : <WStep1 {...stepProps}/>}
            </DialogContent>
            <DialogActions>
                {this.getActions()}
            </DialogActions>
        </Dialog>;
    }

    private getActions = () => {
        return <Button
            data-qa="add-new-portal-create-btn"
            color="primary"
            disabled={!this.state.isCreateEnabled}
            fullWidth
            className="create-portal-btn"
            variant="contained"
            onClick={() => {
                this.setState(
                    {status: "wait"},
                    () => {
                        const {accountId} = getHostnameInfo();
                        const {env} = getLocationInfo(this.props.location, this.props.config);
                        const portalUrl = getPortalCallUrl(accountId, env, this.state.data.gatewayId, this.state.data.portalType)
                        const data = {...this.state.data};

                        this.props.portalsCreate(this.props.config, portalUrl, data)
                            .then(res => {
                                if (res && res.status < 300 && res.data) {
                                    const url = `/${env}/portals/${getPortalId(res.data)}`;
                                    this.props.history.push(url);
                                }
                            });
                    }
                );
            }}>
            Create Portal
        </Button>
    }

    private getTitle = () => {
        return <div className="new-portal-dialog-title">
            <span data-qa="add-new-portal-modal-title">Create Portal</span>
            <Tooltip title="Close">
                <IconButton className="new-portal-close-button" onClick={this.props.onClose}>
                    <Close/>
                </IconButton>
            </Tooltip>
        </div>
    }

    private initialize = () => {
        const {accountId} = getHostnameInfo();
        const {env} = getLocationInfo(this.props.location, this.props.config);

        this.setState(
            {status: "init"},
            () => {
                const gwUrl = getGatewaysCallUrl(accountId, env);
                Promise.all([
                    this.props.gatewaysReadAll(this.props.config, `${gwUrl}?userStrategy=Interopio`)
                ])
                    .then(response => this.setState({
                        status: "ready",
                        gateways: response[0] && response[0].data ? response[0].data : [],
                        data: {
                            ...this.state.data,
                            accountId,
                            environmentId: env
                        }
                    }));
            }
        );
    };
}
