import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import convertToUrlFriendly from "../../../../../../../lib/convertToUrlFriendly";
import * as validateData from "../../../../../../../lib/validate-data-sources-data";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

class WStep1 extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        dataSources: PropTypes.object.isRequired,
        environments: PropTypes.object.isRequired,
        wstate: PropTypes.object.isRequired,
        onSetWState: PropTypes.func.isRequired,
    };

    public constructor(props) {
        super(props);
        this.state = {
            dataValidation: {},
        };
    }

    public componentDidMount() {
        this.props.onSetWState(
            { isNextWStepEnabled: false },
            this.validateData,
        );
    }

    public componentDidUpdate(prevProps) {
        const data_string = JSON.stringify(this.props.wstate.data);
        const prevData_string = JSON.stringify(prevProps.wstate.data);
        if (data_string !== prevData_string) {
            this.validateData();
        }
    }

    public render() {
        const environmentPhi = this.props.environments?.selected?.data?.phi;
        const availableDataAdapters = this.props.wstate?.availableDataAdapters || [];

        return <>
            <TextField
                name="name" value={this.props.wstate.data.name || ""}
                placeholder="Display name" label="Display Name" helperText={this.state.dataValidation.name}
                error={!!this.state.dataValidation.name}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetWState((prevWState) => ({
                    data: {
                        ...prevWState.data,
                        name: event.target.value,
                        dataSourceId: convertToUrlFriendly(event.target.value.substring(0, 18)),
                    },
                }))}
            />

            <TextField
                name="data-source-id" value={this.props.wstate.data.dataSourceId || ""}
                placeholder="Data adapter ID" label="Data Adapter ID" helperText={this.state.dataValidation.dataSourceId}
                error={!!this.state.dataValidation.dataSourceId}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetWState((prevWState) => ({
                    data: {
                        ...prevWState.data,
                        dataSourceId: convertToUrlFriendly(event.target.value.substring(0, 18))
                    }
                }))}
            />

            <TextField
                name="description" value={this.props.wstate.data.description || ""}
                placeholder="Description" label="Description"
                autoComplete="off" autoFocus={false} fullWidth multiline
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetWState((prevWState) => ({
                    data: {
                        ...prevWState.data,
                        description: event.target.value,
                    },
                }))}
            />

            <div>
                <p style={{ marginTop: 24, marginBottom: 0 }}>
                    <strong>Will this data adapter be used with PHI?</strong>
                </p>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div>
                        <FormControlLabel
                            label="Yes"
                            control={<Radio
                                name="phi-yes"
                                value={true}
                                checked={this.props.wstate.data.phi === true}
                                disabled={!environmentPhi}
                                color="primary"
                                onChange={() => this.props.onSetWState((prevWState) => ({
                                    data: {
                                        ...prevWState.data,
                                        phi: true,
                                    }
                                }))}
                            />}
                            style={{ marginRight: 48 }}
                        />
                        <FormControlLabel
                            label="No"
                            control={<Radio
                                name="phi-no"
                                value={false}
                                checked={this.props.wstate.data.phi === false}
                                disabled={!environmentPhi}
                                color="primary"
                                onChange={() => this.props.onSetWState((prevWState) => ({
                                    data: {
                                        ...prevWState.data,
                                        phi: false,
                                    }
                                }))}
                            />}
                            style={{ marginRight: 48 }}
                        />
                    </div>
                    {!environmentPhi ? <div>
                        <Tooltip title="The data adapter may not be configured to use PHI since the containing environment does not allow PHI">
                            <InfoIcon />
                        </Tooltip>
                    </div>: null}
                </div>
                <TextField
                    name="phi-error" value="" disabled
                    helperText={this.state.dataValidation.phi}
                    error={!!this.state.dataValidation.phi}
                    autoComplete="off" autoFocus={false} fullWidth multiline
                    InputProps={{ style: { display: "none" } }}
                    classes={{ root: "disabled-text-field" }}
                />
            </div>

            <div style={{ display: "flex", alignItems: "flex-end" }}>
                <div>
                    <FormControl
                        error={!!this.state.dataValidation.adapterStandard}
                        style={{ minWidth: 320, margin: "16px 16px 0 0" }}
                    >
                        <InputLabel id="data-adapter-standard-select-label">
                            Adapter Standard
                        </InputLabel>
                        <Select data-qa="data-adapter-standard-select"
                            labelId="data-adapter-standard-select-label"
                            id="data-adapter-standard-select"
                            value={this.props.wstate.data.__temp__fhirVersionSelected}
                            onChange = {(event) => this.props.onSetWState((prevWState) => ({
                                data: {
                                    ...prevWState.data,
                                    __temp__fhirVersionSelected: event.target.value,
                                    type: "",
                                }
                            }))}
                        >
                            <MenuItem data-qa="data-adapter-standard-2" value={"1.0.2"}>
                                FHIR DSTU2 v1.0.2
                            </MenuItem>
                            <MenuItem data-qa="data-adapter-standard-3" value={"3.0.2"}>
                                FHIR STU3 v3.0.2
                            </MenuItem>
                            <MenuItem data-qa="data-adapter-standard-4" value={"4.0.1"}>
                                FHIR R4 v4.0.1
                            </MenuItem>
                        </Select>
                        <FormHelperText>
                            {this.state.dataValidation.adapterStandard}
                        </FormHelperText>
                    </FormControl>
                </div>
                <div>
                    <Tooltip title="This is the standard that the data source will be adapted to">
                        <InfoIcon />
                    </Tooltip>
                </div>
            </div>

            <div style={{ display: "flex", alignItems: "flex-end" }}>
                <div>
                    <FormControl
                        error={!!this.state.dataValidation.type}
                        disabled={!this.props.wstate.data.__temp__fhirVersionSelected}
                        style={{ minWidth: 320, margin: "16px 16px 0 0" }}
                    >
                        <InputLabel id="data-source-type-select-label">
                            Data Source Type
                        </InputLabel>
                        <Select data-qa="data-adapter-data-source"
                            labelId="data-source-type-select"
                            id="data-source-type-select-label"
                            value={this.props.wstate.data.type || ""}
                            onChange={(event) => this.props.onSetWState((prevWState) => ({
                                data: {
                                    ...prevWState.data,
                                    type: event.target.value,
                                }
                            }))}
                        >
                            {availableDataAdapters.map((a) => {
                                const toSearchFor = "_" + this.props.wstate.data.__temp__fhirVersionSelected.replace(/\./gi, "_");
                                const isCompatible = a.compatibleFhirVersions.find((v) => v.includes(toSearchFor));
                                return isCompatible
                                    ? <MenuItem data-qa={`adapter-source-${a.type}`} key={a.type} value={a.type}>
                                        {a.displayName || ""}
                                    </MenuItem>
                                    : null;
                            })}
                        </Select>
                        <FormHelperText>
                            {this.state.dataValidation.type}
                        </FormHelperText>
                    </FormControl>
                </div>
                <div>
                    <Tooltip title="This list contains all data adapters available for the Adapter Standard selected above. The chosen data source will be mapped to this standard and exposed through a gateway of that same standard.">
                        <InfoIcon />
                    </Tooltip>
                </div>
            </div>
        </>;
    }

    private isDataValid = () => Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true);

    private isNextWStepEnabled = () => {
        const isValid = this.isDataValid();
        if (isValid !== this.props.wstate.isNextWStepEnabled) {
            this.props.onSetWState({ isNextWStepEnabled: isValid });
        }
    };

    private validateData = () => {
        const dataValidation = validateData.onWStep1(this.props.wstate, this.props.dataSources, this.props.config);
        this.setState(
            { dataValidation },
            this.isNextWStepEnabled,
        );
    };
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    dataSources: state.dataSources,
    environments: state.environments,
    ...ownProps,
});
export default connect(mapStateToProps)(WStep1);
