import * as React from 'react'
import * as PropTypes from "prop-types";
import EditCustomContext from "./EditCustomContext";
import withLegacyTheme from "../../../../../../lib/hoc/with-legacy-theme";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { IconButton, ListItem, Menu, MenuItem } from "@material-ui/core";
import { Delete, Edit, MoreVert } from "@material-ui/icons";
import "./style.less";

const CustomContextListItem = (props) => {
    const contextKey = props.contextKey || "";
    const contextValue = props.contextValue || "";
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editKey, setEditKey] = React.useState(false);
    const [showCustomContextItemMenu, setShowCustomContextItemMenu] = React.useState(false);

    const editCustomContext = (newKey, newVal, index) => {
        const customContexts = props.portals.selected.data.portalType === 'PROVIDER'
            ? [...props.state.data.providerPortalCustomContexts]
            : [...props.state.data.patientPortalCustomContexts]
        let editContext = customContexts[index]
        editContext.contextKey = newKey
        editContext.contextValue = newVal
        setEditKey(false)
        props.onSetState({
            data: {
                ...props.state.data,
                ...customContexts,
            }
        })
    }

    const cancelEdit = () => {
        setEditKey(false);
    }

    const editMenu = (
        <div className="col-remove">
            <IconButton
                data-e2e={`portal-app-more-button-${props.contextKey}`}
                onClick={e => {
                    setAnchorEl(e.currentTarget);
                    setShowCustomContextItemMenu(true);
                }}
            >
                <MoreVert/>
            </IconButton>
            <Menu
                style={{ position: 'absolute', right: '16px' }}
                open={showCustomContextItemMenu || false}
                onClose={() => setShowCustomContextItemMenu(false)}
                anchorEl={anchorEl}
            >
                <MenuItem
                    data-e2e={`custom-context-edit-button-${props.contextKey}`}
                    onClick={ () => {
                        setShowCustomContextItemMenu(false);
                        setEditKey(true)
                    }}
                >
                    <Edit style={{ opacity: .5, marginRight: '12px' }}/> Edit
                </MenuItem>
                <MenuItem
                    data-e2e={`custom-context-delete-button-${props.contextKey}`}
                    onClick={() => {
                        setShowCustomContextItemMenu(false);
                        let portalCustomContextsData = {}
                        if (props.portals.selected.data.portalType === 'PROVIDER') {
                            let { providerPortalCustomContexts } =  props.state.data
                            providerPortalCustomContexts.splice(props.itemIndex, 1)
                            portalCustomContextsData = { providerPortalCustomContexts }
                        }
                        else {
                            let { patientPortalCustomContexts } = props.state.data
                            patientPortalCustomContexts.splice(props.itemIndex, 1)
                            portalCustomContextsData = { patientPortalCustomContexts }
                        }
                        props.onSetState({
                            data: {
                                ...props.state.data,
                                ...portalCustomContextsData,
                            }
                        });
                    }}>
                    <Delete style={{ opacity: .5, marginRight: '12px' }} /> Remove
                </MenuItem>
            </Menu>
        </div>
    )
    let customContexts = props.portals.selected.data.portalType === 'PROVIDER'
        ? { providerPortalCustomContexts: props.state.data.providerPortalCustomContexts }
        : { patientPortalCustomContexts: props.state.data.patientPortalCustomContexts }

    return (
        <ListItem
            data-e2e={`custom-context-itm-${props.contextKey}`}
            className="custom-context-list-item"
        >
            <div className="content">
                { editKey ?
                    <EditCustomContext
                        className="edit-custom-context"
                        itemIndex={props.itemIndex}
                        contextKey={contextKey}
                        contextValue={contextValue}
                        {...customContexts}
                        editCustomContext={editCustomContext}
                        cancelEdit={cancelEdit}
                    />
                    : <>
                        <div
                            data-e2e={`custom-ctx-${contextKey}-${contextKey}`}
                            className="col-1"
                        >
                            {contextKey}
                        </div>
                        <div
                            data-e2e={`custom-ctx-${contextKey}-${contextValue}`}
                            className="col-2"
                        >
                            {contextValue}
                        </div>
                        {editMenu}
                    </>
                }
            </div>
        </ListItem>
    );
}

CustomContextListItem.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object,
    onSetState: PropTypes.func.isRequired,
    contextKey: PropTypes.string,
    contextValue: PropTypes.string,
    itemIndex: PropTypes.number,
    portals: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    portals: state.portals,
});

export default withRouter(connect(mapStateToProps)(withLegacyTheme()(CustomContextListItem)));
