import * as types from "../../actions/types";

import initialState from "./initial-state";

export default function (state = initialState, action: any) {
    switch (action.type) {
        case types.APP_USERS_ALL_SET: {
            return {
                ...state,
                all: {
                    status: action.payload.status,
                    data: [...action.payload.data],
                },
            };
        }

        case types.APP_USERS_SELECTED_SET: {
            return {
                ...state,
                selected: {
                    status: action.payload.status,
                    data: { ...action.payload.data },
                },
            };
        }

        default:
            return state;
    }
}
