import * as types from "../../actions/types";

import initialState from "./initial-state";

export default function (state = initialState, action: any) {
    switch (action.type) {
        case types.GENERAL_MESSAGE_RESET: {
            return { ...initialState };
        }

        case types.GENERAL_MESSAGE_SET: {
            return { ...action.payload };
        }

        default:
            return state;
    }
}
