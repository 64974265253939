import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../redux/actions";
import {isLoggedIn} from "../../../lib/users-helpers";
import withLegacyTheme from "../../../lib/hoc/with-legacy-theme";
import HomePrivate from "./HomePrivate/";
import HomePublic from "./HomePublic/";

class Home extends React.Component<any, any> {
    public static propTypes = {
        login: PropTypes.object.isRequired
    };

    public render() {
        const isLogged = isLoggedIn(this.props.login);

        return isLogged
            ? <HomePrivate {...this.props} />
            : <HomePublic/>;
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Home));
