import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../../../../../redux/actions'
import getAppsCallUrl from '../../../../../../../lib/getAppsCallUrl'
import getHostnameInfo from '../../../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../../../lib/getLocationInfo'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import CheckIcon from '@material-ui/icons/Check'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import type {
    TApp,
    TAppActivation,
    TAppActivationSmartLaunchProxyInfo2Api,
} from '../../../../../../../types'
import type {
    TStatus as TEStatus,
    TAuthMode,
} from '..'

type TProps = {
    appActivation: TAppActivation
    apps: {
        selected: {
            data?: TApp
        }
    }
    authMode: TAuthMode
    canEdit: boolean
    config: any
    estatus: TEStatus
    estep: number
    ESTEP_MAX: number
    ESTEP_MIN: number
    ehrAuthType: string,
    isAppActivationDataValid: boolean
    isAppLaunchingWithinIKMG2: boolean
    parentState: any
    proxyAuthType: string,
    location: any
    selectedAppActivation?: TAppActivation
    appsRead: Function
    appsUpdate: Function
    calcNextWStep: () => void
    calcPrevWStep: () => void
    onCancel: () => void
    onClose: () => void
    setEStatus: React.Dispatch<React.SetStateAction<TEStatus>>
}

const CustomDialogActions: React.FC<TProps> = (props: TProps) => {
    if (!props.canEdit) {
        return null
    }

    return (
        <DialogActions style={{ padding: '24px', paddingTop: 0, background: 'rgb(250, 250, 250)' }}>
            {props.estep > props.ESTEP_MIN ? (
                <Button
                    disabled={props.estatus !== ':READY:' || !props.isAppActivationDataValid}
                    color="primary"
                    startIcon={<KeyboardArrowLeftIcon />}
                    style={{ marginRight: '8px' }}
                    onClick={props.calcPrevWStep}
                >
                    Back
                </Button>
            ) : null}

            {props.estep === props.ESTEP_MIN ? (
                <Button
                    data-e2e="act_cancel_btn"
                    disabled={props.estatus !== ':READY:'}
                    color="primary"
                    style={{ marginRight: '8px' }}
                    onClick={props.onCancel}
                >
                    Cancel
                </Button>
            ) : null}

            {props.estep < props.ESTEP_MAX ? (
                <Button
                    disabled={props.estatus !== ':READY:' || !props.isAppActivationDataValid}
                    color="primary"
                    endIcon={<KeyboardArrowRightIcon />}
                    style={{ marginRight: '8px' }}
                    onClick={props.calcNextWStep}
                >
                    Next
                </Button>
            ) : null}

            {props.estep === props.ESTEP_MAX ? (
                <Button
                    data-e2e="act_save_btn"
                    disabled={props.estatus !== ':READY:' || !props.isAppActivationDataValid}
                    variant="contained"
                    color="secondary"
                    startIcon={<CheckIcon />}
                    onClick={async () => {
                        props.setEStatus(':SAVING:')

                        let appActivationData = props.appActivation

                        // check if isAppLaunchingWithinIKMG2 is true and set tennants to empty array
                        if (props.isAppLaunchingWithinIKMG2) {
                            appActivationData = {
                                ...appActivationData,
                                tenants: [],
                                allPractices: false,
                            }
                        } else if (appActivationData.allPractices) {
                            appActivationData = {
                                ...appActivationData,
                                tenants: [],
                            }
                        }

                        if (props.authMode === ':SMART_2:') {
                            const smartLaunchProxyInfo2Api: TAppActivationSmartLaunchProxyInfo2Api = {
                                ehrAuthenticationType: props.ehrAuthType.replace(/:/g, ''), // PROXY | SYSTEM
                                ehrAuthenticationMethod: props.proxyAuthType.replace(/:/g, ''), // PUBLIC | CONFIDENTIAL
                                clientId: props.appActivation.smartLaunchProxyInfo2Api?.clientId,
                                scopes: props.appActivation.smartLaunchProxyInfo2Api?.scopes,
                                launchUrl: props.appActivation.smartLaunchProxyInfo2Api?.launchUrl,
                                redirectUrl: props.appActivation.smartLaunchProxyInfo2Api?.redirectUrl,
                                smartLaunchProxyId: props.appActivation.smartLaunchProxyInfo2Api?.smartLaunchProxyId,
                            }
                            if (smartLaunchProxyInfo2Api.ehrAuthenticationMethod === 'CONFIDENTIAL') {
                                smartLaunchProxyInfo2Api.clientAuthenticationMethod = props.appActivation.smartLaunchProxyInfo2Api?.clientAuthenticationMethod // ASYMMETRIC | SYMMETRIC
                                if (props.appActivation.smartLaunchProxyInfo2Api?.clientAuthenticationMethod === 'ASYMMETRIC') {
                                    smartLaunchProxyInfo2Api.publicKey = props.appActivation.smartLaunchProxyInfo2Api?.publicKey
                                    smartLaunchProxyInfo2Api.tokenUrl = props.appActivation.smartLaunchProxyInfo2Api?.tokenUrl
                                    smartLaunchProxyInfo2Api.appName = props.appActivation.smartLaunchProxyInfo2Api?.appName
                                }
                                else if (props.appActivation.smartLaunchProxyInfo2Api?.clientAuthenticationMethod === 'SYMMETRIC') {
                                    smartLaunchProxyInfo2Api.clientSecret = props.appActivation.smartLaunchProxyInfo2Api?.clientSecret
                                }
                            }

                            appActivationData = {
                                appId: appActivationData.appId,
                                appClientId: appActivationData.appClientId,
                                gatewayId: appActivationData.gatewayId,
                                appScopes: appActivationData.appScopes,
                                name: appActivationData.name,
                                disabled: appActivationData.disabled,
                                launchUrl: null,
                                proxySessionType: appActivationData.proxySessionType,
                                smartLaunchProxyInfo: null,
                                allscriptsUnityLaunchProxyInfoApi: null,
                                interopioContext: appActivationData.interopioContext,
                                showInProviderPortal: false,
                                showInPatientPortal: false,
                                externalAuth: null,
                                smartLaunchProxyInfo2Api,
                            }
                        }

                        const prevAppData = {
                            ...props.apps.selected?.data || {},
                            activations: [],
                        }
                        const prevActivationsData = props.parentState.activations || []
                        let filteredPrevActivationsData = prevActivationsData
                        if (props.selectedAppActivation) {
                            filteredPrevActivationsData = prevActivationsData
                                .filter(({ name }) => name !== props.selectedAppActivation.name)
                        }
                        const newActivationsData = [
                            appActivationData,
                            ...filteredPrevActivationsData,
                        ]
                        const newAppData = {
                            ...prevAppData,
                            activations: newActivationsData,
                        }
                        const { accountId } = getHostnameInfo()
                        const { env } = getLocationInfo(props.location, props.config)
                        const appUrl = getAppsCallUrl(accountId, env) + `/${props.apps.selected.data.appId}`
                        const [appsUpdateResponse] = await Promise.all([
                            props.appsUpdate(props.config, appUrl, newAppData),
                            new Promise((resolve) => setTimeout(resolve, 2550)),
                        ])

                        if (appsUpdateResponse) {
                            props.onClose()
                        }
                        else {
                            await props.appsRead(props.config, appUrl)
                            props.setEStatus(':READY:')
                        }
                    }}
                >
                    Save
                </Button>
            ) : null}
        </DialogActions>
    )
}

const mapStateToProps = (state, ownProps) => ({
    apps: state.apps,
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appsRead: actions.appsRead,
    appsUpdate: actions.appsUpdate,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomDialogActions))
