import * as React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {Card, CardContent} from "@material-ui/core";
import { isEnvironmentAdmin } from "../../../../../lib/user-environment-permissions-helpers";
import Header from "../../../../Widgets/Editor/Header/";
import LoggingTab from "../../../../Widgets/LoggingTab";
import ReqResLogging from "./ReqResLogging";
import "./style.less";

interface IProps {
    config: any;
    login: any;
    location: any;
    state: any;
    initialize: Function;
    onSetState: Function;
}

const TABS = ["EVENT LOGGING"];

const Logging: React.FC<IProps> = (props: IProps) => {
    const [tab, setTab] = React.useState(0);
    const actAsAdmin = isEnvironmentAdmin(props.login, props.location, props.config);
    if (!actAsAdmin) {
        return <div className="container">
            <Card style={{marginTop: "48px"}}>
                <Header {...props} style={{height: "32px", padding: "8px 16px", borderTop: "none"}}/>
                <CardContent>
                    <p style={{textAlign: "center"}}>
                        <big>
                            <strong>
                                This screen is only available to the account owners or environment admins
                            </strong>
                        </big>
                    </p>
                </CardContent>
            </Card>
        </div>
    }

    const tabs = actAsAdmin ? [...TABS, "REQUEST LOGGING"] : TABS

    let content = null;
    switch (tab) {
        case 0: content = <LoggingTab {...props} />; break;
        case 1: content = actAsAdmin
            ? <ReqResLogging estate={props.state} initialize={props.initialize} onSetEState={props.onSetState} setSubTab={setTab} />
            : null;
            break;
    }

    return (
        <div className="gateway-logging-content">
            <div className="sub-tabs">
                {tabs.map((title, idx) => (
                    <div key={idx} className={`tab ${idx === tab ? "selected" : ""}`}
                        onClick={() => {
                            props.onSetState({ mode: ":VIEW:" });
                            setTab(idx);
                        }}
                    >
                        {title}
                    </div>
                ))}
            </div>
            {content}
        </div>
    )
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    login: state.login,
    ...ownProps,
});
export default withRouter(connect(mapStateToProps)(Logging));
