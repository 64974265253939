import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../../../redux/actions'
import { withRouter } from 'react-router'
import axiosWrapper from '../../../../../lib/axiosWrapper'
import getHostnameInfo from '../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../lib/getLocationInfo'
import withLegacyTheme from '../../../../../lib/hoc/with-legacy-theme'
import type { TDataSource, TGateway } from '../../../../../types'
import CenteredCircularProgress from '../../../../Widgets/CenteredCircularProgress/'
import DataMapping from './DataMapping/'
import Dialogs from './Dialogs/'


export type TStateSource = {
    id: string
    name: string
}

type TStateResourceOperations = {
    type: string
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
    search: boolean
    overriddenBy?: string
    gatewayResourceOperations?: object
}

type TStateSourcesResources = { [K: string]: TStateResourceOperations[] }

type TState = {
    status: 'ready' | 'recalc' | 'init' | ''
    data: TGateway
    dataMappingData: any
    dialog: '' | ':EDIT:'
    filters: {
        resources: ':ALL:' | ':SUPPORTED:' | ':UNSUPPORTED:' | ':OVERRIDDEN:' | ':USCDI:' | ':NON-USCDI:'
        operations: {
            read: boolean
            search: boolean
            create: boolean
            update: boolean
            delete: boolean
        }
    },
    sortBy: Partial<{
        source: boolean
        type: boolean
    }>
    selectedResources: { [K: string]: boolean }
    sources: TStateSource[]
    sourcesResources: TStateSourcesResources
    resources: TStateResourceOperations[]
    selectedDataSourceType: string
}

type TProps = {
    config: any
    features: any
    gateways: {
        selected: {
            data: TGateway
        }
    }
    dataSources: {
        all: {
            data: TDataSource[]
        }
    }
    location: any
    login: any
    theme: any
    // eslint-disable-next-line no-unused-vars
    onSetParentState: (state: Partial<TState>) => TState
}

const INIT_STATE: TState = {
    status: 'init',
    data: {},
    dataMappingData: {},
    dialog: '',
    filters: {
        resources: ':ALL:',
        operations: {
            read: false,
            search: false,
            create: false,
            update: false,
            delete: false
        }
    },
    sortBy: {
        type: true
    },
    selectedResources: {},
    sources: [],
    sourcesResources: {},
    resources: [],
    selectedDataSourceType: ''
}

class GatewayEditDataMapping extends React.Component<TProps, TState> {
    public constructor(props) {
        super(props)
        this.state = INIT_STATE
    }

    public componentDidMount() {
        this.setState(
            { status: 'recalc' },
            () => { this.init() },
        )
    }

    public async componentDidUpdate(_, prevState) {
        const filters_string = JSON.stringify(this.state.filters)
        const prevFilters_string = JSON.stringify(prevState.filters)
        const sortBy_string = JSON.stringify(this.state.sortBy)
        const prevSortBy_string = JSON.stringify(prevState.sortBy)
        if (
            (filters_string !== prevFilters_string)
            || (sortBy_string !== prevSortBy_string)
        ) {
            this.setState({ status: 'recalc' })
            const resources = await this.getResources(this.state.dataMappingData)
            this.setState({
                status: 'ready',
                resources,
            })
        }
    }

    public render() {
        return (
            <div>
                {!['ready', 'recalc'].includes(this.state.status) ? (
                    <CenteredCircularProgress
                        size={63}
                        style={{ padding: '24px' }}
                    />
                ) : (
                    <DataMapping
                        {...this.props}
                        state={this.state}
                        initialize={this.init}
                        onSetState={this.onSetState}
                    />
                )}
                <br/>
                <Dialogs
                    {...this.props}
                    state={this.state}
                    initialize={this.init}
                    onSetState={this.onSetState}
                />
            </div>
        )
    }

    private onSetState = (state, cb) => this.setState(state, cb)

    private getDataMappingData = async () => {
        const { accountId } = getHostnameInfo()
        const { env, gatewayId } = getLocationInfo(this.props.location, this.props.config)

        const url = `api/${accountId}/env/${env}/gtwy/${gatewayId}/data-mapping`
        const response = await axiosWrapper(this.props.config.envApi, url)
        const dataMappingData = response.data
        return dataMappingData
    }

    private getFilteredResources = (resources: TStateResourceOperations[]): TStateResourceOperations[] => {
        let filtered = resources
        if (this.state.filters.resources === ':SUPPORTED:') {
            filtered = filtered.filter((it) => Object.keys(it)
                .filter((key) => key !== 'type' && key !== 'overriddenBy' && key !== 'gatewayResourceOperations')
                .reduce((acc, key) => acc || it[key], false)
            )
        }
        else if (this.state.filters.resources === ':UNSUPPORTED:') {
            filtered = filtered.filter((it) => !Object.keys(it)
                .filter((key) => key !== 'type' && key !== 'overriddenBy' && key !== 'gatewayResourceOperations')
                .reduce((acc, key) => acc || it[key], false)
            )
        }
        else if (this.state.filters.resources === ':OVERRIDDEN:') {
            filtered = filtered.filter((it) => {
                if (it.gatewayResourceOperations) {
                    return Object.keys(it.gatewayResourceOperations)
                        .filter((key) => key !== 'type' && it[key].overriddenBy)
                }
                return it.overriddenBy
            })
        }
        else if (this.state.filters.resources === ':USCDI:') {
            filtered = filtered.filter((it) => this.props.config.fhirUscdiResources.includes(it.type))
        }
        else if (this.state.filters.resources === ':NON-USCDI:') {
            filtered = filtered.filter((it) => !this.props.config.fhirUscdiResources.includes(it.type))
        }
        for (const key in this.state.filters.operations) {
            if (this.state.filters.operations.hasOwnProperty(key)) {
                if (this.state.filters.operations[key]) {
                    filtered = filtered.filter((it) => it[key])
                }
            }
        }
        return filtered
    }

    private getSortedResources = (resources: TStateResourceOperations[]): TStateResourceOperations[] => {
        const sortBy = this.state.sortBy
        let sorted = [...resources]
        if (sortBy.type !== undefined) {
            if (sortBy.type === true) {
                sorted.sort((a, b) => {
                    if (a.type < b.type) return -1
                    else if (a.type > b.type) return 1
                    else return 0
                })
            }
            else if (sortBy.type === false) {
                sorted.sort((a, b) => {
                    if (a.type < b.type) return 1
                    else if (a.type > b.type) return -1
                    else return 0
                })
            }
        }
        if (sortBy.source !== undefined) {
            if (sortBy.source === true) {
                sorted.sort((a, b) => {
                    const nameA = this.state.sources.find((source) => source.id === a.overriddenBy)?.['name'] || '~'
                    const nameB = this.state.sources.find((source) => source.id === b.overriddenBy)?.['name'] || '~'
                    if (nameA < nameB) return -1
                    else if (nameA > nameB) return 1
                    else return 0
                })
            }
            else if (sortBy.source === false) {
                sorted.sort((a, b) => {
                    const nameA = this.state.sources.find((source) => source.id === a.overriddenBy)?.['name'] || '@'
                    const nameB = this.state.sources.find((source) => source.id === b.overriddenBy)?.['name'] || '@'
                    if (nameA < nameB) return 1
                    else if (nameA > nameB) return -1
                    else return 0
                })
            }
        }
        return sorted
    }

    private getResources = (dataMappingData): TStateResourceOperations[] => {
        let resources = dataMappingData.gatewayResources || []
        resources = this.getFilteredResources(resources)
        resources = this.getSortedResources(resources)
        return resources
    }

    private getDataMappingSources = (dataMappingData) => {
        return dataMappingData.sources || []
    }

    private getSources = (dataMappingData): TStateSource[] => {
        const dataMappingSources = this.getDataMappingSources(dataMappingData)
        return dataMappingSources
            .map((it) => ({ id: it.id, name: it.displayName }))
    }

    private getSourcesResources = (dataMappingData): TStateSourcesResources => {
        const dataMappingSources = this.getDataMappingSources(dataMappingData)
        return dataMappingSources
            .reduce((acc, it) => {
                return {
                    ...acc,
                    [it.id]: it.resources,
                }
            }, {})
    }

    private getAllSourcesAndResources = async (): Promise<{
        dataMappingData: any;
        resources: TStateResourceOperations[];
        sources: TStateSource[];
        sourcesResources: TStateSourcesResources;
    }> => {
        const dataMappingData = await this.getDataMappingData()
        const resources = this.getResources(dataMappingData)
        const sources = this.getSources(dataMappingData)
        const sourcesResources = this.getSourcesResources(dataMappingData)
        return { dataMappingData, resources, sources, sourcesResources }
    }

    private init = async () => {
        const { dataMappingData, resources, sources, sourcesResources } = await this.getAllSourcesAndResources()
        const allDataSources = this.props.dataSources.all.data || []
        const selectedDataSourceId = this.props.gateways.selected.data.configuration?.defaultDataSource || ''
        const selectedDataSource =  allDataSources
            .find((ds) => ds.dataSourceId === selectedDataSourceId) || {}
        const selectedDataSourceType = selectedDataSource.type || ''
        this.setState({
            status: 'ready',
            data: this.props.gateways.selected.data,
            dataMappingData,
            sources,
            sourcesResources,
            resources,
            selectedDataSourceType,
        })
    }
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    features: state.features,
    gateways: state.gateways,
    dataSources: state.dataSources,
    login: state.login,
    ...ownProps,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    gatewaysUpdate: actions.gatewaysUpdate,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(GatewayEditDataMapping)))
