/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import {CardActions, Button} from "@material-ui/core";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../lib/getLocationInfo";
import getPortalCallUrl from "../../../../../lib/getPortalCallUrl";
import getPortalId from "../../../../../lib/getPortalId";
import {getPath} from "../../../../../lib/utils";
import consoleLogger from "../../../../../lib/consoleLogger";

const Component: React.FunctionComponent<any> = props => {
    const canEdit = props.state.mode === ":EDIT:";
    const selected = getPath(props, "portals.selected.data") || {};
    const gateway = props.state.gateway || {};

    return <CardActions>
        <div style={{
            height: canEdit ? "36px" : 0,
            marginTop: canEdit ? "8px" : 0,
            marginBottom: canEdit ? "8px" : 0,
            textAlign: "right",
            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
        }}>
            <Button variant="outlined" disabled={!canEdit} style={{display: canEdit ? "inline-block" : "none", marginRight: "8px"}} data-qa="portal-cancel-edit-button"
                onClick={() => {
                    props.onSetState({mode: ":VIEW:"});
                    props.initialize();
                }}>
                Cancel
            </Button>
            <Button
                color="primary"
                data-qa="portal-details-edit-save-button"
                disabled={!props.parentState.isSaveEnabled}
                style={{display: canEdit ? "inline-block" : "none"}}
                variant="contained"
                onClick={() => {
                    props.onSetState(
                        {status: "wait"},
                        async () => {
                            const {accountId} = getHostnameInfo();
                            const {env} = getLocationInfo(props.location, props.config);
                            const portalUrl = getPortalCallUrl(accountId, env, gateway.gatewayId, props.state.data.portalType);
                            const data = {...selected, ...props.state.data};

                            props.portalsUpdate(props.config, `${portalUrl}/${getPortalId(selected)}`, data)
                                .then(() => {
                                    props.onSetState({mode: ":VIEW:"});
                                    props.initialize(true);
                                })
                                .catch(error => {
                                    consoleLogger.log(error);
                                });
                        }
                    );
                }}>
                Save
            </Button>
        </div>
    </CardActions>;
}

Component.propTypes = {
    appsUpdate: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    isValid: PropTypes.bool,
    parentState: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired,
};

Component.defaultProps = {
    isValid: true
};

export default Component;
