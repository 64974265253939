import * as React from "react";
import * as PropTypes from "prop-types";
import {ListItem} from "@material-ui/core";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    const transaction = props.transaction;
    const resourceType = transaction.resourceType;
    const operationType = transaction.operationType;
    const avgTime = transaction.avgTime;
    const medianTime = transaction.medianTime;
    const count = transaction.count;

    return <ListItem disabled className={`gw-transactions-tt-item`} style={{fontSize: "14px", lineHeight: "14px", padding: "12px 0", opacity: 1}}>
        <div className="content-aggregated">
            <div className="resourceType">
                {resourceType}
            </div>
            <div className="operationType">
                {operationType}
            </div>
            <div className="avgTime">
                {avgTime.toFixed(2) + " ms"}
            </div>
            <div className="medianTime">
                {medianTime.toFixed(2) + " ms"}
            </div>
            <div className="count">
                {count}
            </div>
        </div>
    </ListItem>;
};

Component.propTypes = {
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    transaction: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
