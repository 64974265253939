import * as React from "react";
import * as PropTypes from "prop-types";
import {Fab, IconButton, List, ListItem, TextField, Tooltip} from "@material-ui/core";
import {Add, Delete} from "@material-ui/icons";

const Component: React.FunctionComponent<any> = props => {
    return props.canEdit ? renderEditMode(props) : renderViewMode(props);
}

function renderEditMode(props) {
    const redirectUrls = props.state.data.redirectUrls || []

    return <div style={{marginTop: "16px"}}>
        <h4
            style={{
                // marginBottom: 0,
                color: redirectUrls.length ? props.muiTheme.palette.textColor : props.muiTheme.palette.secondaryTextColor,
            }}
        >
            App Redirect URIs
        </h4>
        <div style={{display: "flex", alignItems: "baseline"}}>
            <TextField name="addNewRedirectUrl" value={props.state.data.newRedirectUrl || ""} label="Add New Redirect URI" error={!!props.state.dataValidation.newRedirectUrl}
                helperText={props.state.dataValidation.newRedirectUrl} fullWidth
                onChange={e => props.onSetState({
                    data: {
                        ...props.state.data,
                        newRedirectUrl: e.target.value
                    }
                })}
            />
            <Fab name="addNewRedirectUrlButton" size="small" disabled={!props.state.data.newRedirectUrl || !!props.state.dataValidation.newRedirectUrl}
                onClick={() => {
                    const newRedirectUrl = props.state.data.newRedirectUrl;
                    if (newRedirectUrl) {
                        const newRedirectUrls = [...redirectUrls, newRedirectUrl];
                        props.onSetState({
                            data: {
                                ...props.state.data,
                                redirectUrls: newRedirectUrls,
                                newRedirectUrl: ""
                            }
                        });
                    }
                }}>
                <Add/>
            </Fab>
        </div>

        <List style={{marginTop: "16px", marginBottom: "16px"}}>
            {redirectUrls.map((item, index) => (
                <div key={index}>
                    <ListItem disabled style={{background: index % 2 ? "rgb(235, 235, 235)" : "rgb(245, 245, 245)", opacity: 1, display: "flex", justifyContent: "space-between"}}>
                        {item} <Tooltip placement="top" title="Remove">
                            <IconButton
                                onClick={() => {
                                    let newRedirectUrls = [...redirectUrls];
                                    newRedirectUrls.splice(index, 1);
                                    props.onSetState({
                                        data: {
                                            ...props.state.data,
                                            redirectUrls: newRedirectUrls
                                        }
                                    });
                                }}>
                                <Delete style={{color: props.muiTheme.palette.accent1Color}}/>
                            </IconButton>
                        </Tooltip>
                    </ListItem>
                </div>
            ))}
        </List>
    </div>;
}

function renderViewMode(props) {
    const redirectUrls = props.state.data.redirectUrls || []

    return (
        <div>
            <h4
                style={{
                    marginBottom: 0,
                    color: redirectUrls.length ? props.muiTheme.palette.textColor : props.muiTheme.palette.secondaryTextColor,
                }}
            >
                App Redirect URIs
            </h4>

            <List>
                {redirectUrls.map((item, index) => (
                    <div key={index}>
                        <ListItem
                            disabled
                            style={{
                                background: index % 2 ? "rgb(235, 235, 235)" : "rgb(245, 245, 245)",
                            }}
                        >
                            {item}
                        </ListItem>
                    </div>
                ))}
            </List>
        </div>
    )
}

Component.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,

    onSetState: PropTypes.func.isRequired,

    canEdit: PropTypes.bool
}

Component.defaultProps = {
    canEdit: false
};

export default Component;
