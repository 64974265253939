import * as React from "react";
import * as PropTypes from "prop-types";
import * as validateData from "../../../../../../../../../lib/validate-cds-service-sets-data";
import TextField from "@material-ui/core/TextField";
import CheckCdsServices from "../../../../../../Shared/CheckCdsServices";

class WStep2 extends React.Component<any, any> {
    public static propTypes = {
        parentState: PropTypes.object.isRequired,
        onSetParentState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.props.onSetParentState(
            {
                dataValidation: {},
                isNextWStepEnabled: false,
            },
            this.validateData,
        );
    }

    public componentDidUpdate(prevProps) {
        const data_string = JSON.stringify(this.props.parentState.data);
        const prevData_string = JSON.stringify(prevProps.parentState.data);
        if (data_string !== prevData_string) {
            this.validateData();
        }
    }

    public render() {
        return <>
            <TextField
                name="jku" value={this.props.parentState?.gatewayJwkSet?.jku || ""}
                placeholder="JKU endpoint" label="JKU Endpoint"
                disabled fullWidth
                classes={{ root: "disabled-text-field" }}
                style={{ marginTop: 16 }}
            />
            <CheckCdsServices
                canEdit
                parentState={this.props.parentState}
                onSetParentState={this.props.onSetParentState}
            />
        </>;
    }

    private isDataValid = () => Object.keys(this.props.parentState.dataValidation).reduce((acc, key) => !!acc && !this.props.parentState.dataValidation[key], true);

    private isNextWStepEnabled = () => {
        let isValid = !!this.props.parentState.data.cdsServices && this.isDataValid();
        isValid = isValid && this.props.parentState.data.cdsServices && Array.isArray(this.props.parentState.data.cdsServices) && this.props.parentState.data.cdsServices.length;
        if (isValid !== this.props.parentState.isNextWStepEnabled) {
            this.props.onSetParentState({isNextWStepEnabled: isValid});
        }
    }

    private validateData = () => {
        const dataValidation = validateData.onWStep2(this.props.parentState);
        this.props.onSetParentState(
            {dataValidation},
            this.isNextWStepEnabled,
        );
    };
}

export default WStep2;
