import * as PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogActions from "./CustomDialogActions";
import CustomDialogContent from "./CustomDialogContent";
import CustomDialogTitle from "./CustomDialogTitle";

const DSDeleteReject = (props) => {
    return <Dialog
        open disableBackdropClick disableEscapeKeyDown scroll="body"
        onClose={props.onClose}
    >
        <CustomDialogTitle parentState={props.parentState} onClose={props.onClose} />
        <CustomDialogContent parentState={props.parentState} />
        <CustomDialogActions parentState={props.parentState} onClose={props.onClose} />
    </Dialog >
}

DSDeleteReject.propTypes = {
    parentState: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default DSDeleteReject;
