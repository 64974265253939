import * as PropTypes from "prop-types";
import {AppBar, IconButton, Tooltip} from "@material-ui/core";
import {Help, List, GridOn} from "@material-ui/icons";
import "./style.less";

const NavbarL2 = props => {
    return <AppBar data-qa="navbar-l2" position="relative" className="navbar-l2">
        <div style={{lineHeight: "56px", verticalAlign: "middle"}}>{props.title}</div>
        <div>
            {props.onViewSwitch && props.view === "LIST" && <IconButton onClick={() => props.onViewSwitch("CARD")}>
                <Tooltip title="Switch to card view">
                    <GridOn style={{color: "rgba(255, 255, 255, 0.67)"}}/>
                </Tooltip>
            </IconButton>}
            {props.onViewSwitch && props.view === "CARD" && <IconButton onClick={() => props.onViewSwitch("LIST")}>
                <Tooltip title="Switch to list view">
                    <List style={{color: "rgba(255, 255, 255, 0.67)"}}/>
                </Tooltip>
            </IconButton>}
            {props.title
                ? <IconButton>
                    <Help style={{color: "rgba(255, 255, 255, 0.67)"}}/>
                </IconButton>
                : null}
        </div>
    </AppBar>;
}

NavbarL2.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    view: PropTypes.oneOf(["LIST", "CARD"]),
    onViewSwitch: PropTypes.func
};

NavbarL2.defaultTypes = {
    title: null
};

export default NavbarL2;
