import { useState, useEffect } from "react";
import * as React from 'react'
import axiosWrapper from "../../../lib/axiosWrapper";

type TProps = {
    className?: string,
    imgStyle?: object,
    src: string,
    style?: object,
    config: {
        envApi: object
    }
}

const ImageLoader: React.FC<TProps> = ({className = "", imgStyle = {}, src = "", style = {}, config = { envApi: {}}} : TProps) => {
    const [imgSrc, setImgSrc] = useState("")

    useEffect(() => {
        if (src && src.includes('/environment-api/')) {
            const getLogoSrc = async () => {
                const logoUrl = src.split('/environment-api/')[1]
                const res = await axiosWrapper(
                    config.envApi,
                    logoUrl,
                    undefined, undefined, undefined,'arraybuffer'
                )
                if (res.status === 200) {
                    const blob = new Blob([res.data], { type: res.headers['content-type'] })
                    const url = URL.createObjectURL(blob)
                    return url
                } else {
                    return src // fallback to original url
                }
            }
            getLogoSrc().then((url) => {
                setImgSrc(url)
            })
        } else {
            setImgSrc(src)
        }
    }, [src,
        config.envApi])

    return <div className={`image-loader ${className}`} style={style}>
        <img src={imgSrc} style={imgStyle}/>
    </div>;
}

export default ImageLoader;
