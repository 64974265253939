import * as React from "react";
import {Dialog, DialogContent, DialogTitle, CircularProgress, Typography, IconButton, withTheme} from "@material-ui/core";
import PropTypes from "prop-types";
import {useEffect} from "react";
import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../lib/getLocationInfo";
import getDataSourcesCallUrl from "../../../../../../lib/getDataSourcesCallUrl";
import {Close} from "@material-ui/icons";
import ReactJson from "react-json-view";

const Metadata = props => {
    useEffect(() => {
        const {accountId} = getHostnameInfo();
        const {env, dataSourceId} = getLocationInfo(props.location, props.config);
        const url = `${getDataSourcesCallUrl(accountId, env)}/${dataSourceId}?metadata=true`;
        props.conformanceRead(props.config, url);
    // TODO: useEffect has missing dependencies!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Dialog open onClose={props.onClose} maxWidth="md">
        <DialogTitle disableTypography style={{color: props.theme.palette.primary.contrastText, background: props.theme.palette.primary.main}}>
            <Typography variant="h6" style={{paddingRight: "64px"}}>
                Conformance statement for {props.selected.name}
            </Typography>
            <IconButton onClick={props.onClose} style={{position: "absolute", right: props.theme.spacing(1), top: props.theme.spacing(1), color: props.theme.palette.primary.contrastText}}>
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent style={{padding: "16px", textAlign: props.selectedStatus !== "ready" ? "center" : "left"}}>
            {props.selectedStatus !== "ready"
                ? <CircularProgress/>
                : <ReactJson src={props.selected.metadata} name="Conformance" indentWidth={4} collapsed={false} enableClipboard displayObjectSize displayDataTypes sortKeys
                    style={{paddingTop: "24px", overflowX: "auto"}}/>}
        </DialogContent>
    </Dialog>
}

Metadata.propTypes = {
    location: PropTypes.any,
    config: PropTypes.any,
    conformanceRead: PropTypes.func,
    onClose: PropTypes.func,
    theme: PropTypes.object,
    selected: PropTypes.object,
    selectedStatus: PropTypes.bool
};

export default withTheme(Metadata);
