/* ***
* The `GatewayConfigurationDefault` should only be used if posting back
* an empty configuration. This could happen if the gateway hasn't yet been configured.
* If the gateway has been configured it should use the `type` specific to that gateway type,
* in our case the only type that exists is `GatewayConfigurationFhirDstu2`,
* but that should change soon as we plan to add an STU3 version
* and an interopiO FHIR CDR version.
*
* The rule of thumb is that if you know the type of gateway,
* just use the type specific to that gateway.
* If you don't or if it has yet to be configured,
* you can use `GatewayConfigurationDefault`.
*** */

export default {
    all: {
        status: "",
        data: [],
    },
    selected: {
        status: "",
        data: {},
    },
};
