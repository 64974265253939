import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router";
import * as actions from "../../../../redux/actions";
import getEnvironmentsCallUrl from "../../../../lib/getEnvironmentsCallUrl";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import {isEnvironmentAdmin} from "../../../../lib/user-environment-permissions-helpers";
import {isAccountOwner} from "../../../../lib/user-roles-helpers";
import EnvironmentAddNewWizard from "../EnvironmentAddNewWizard";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import {CardsContainer, CardWrapper, CardBody, CardBodyAddNew, CardMenuBar, CardFooter, CardCustomContent} from "../../../Widgets/CustomCard/";
import {Avatar, Chip} from "@material-ui/core";
import {Lock} from "@material-ui/icons";
import WidgetsDeleteConfirm from "../../../Widgets/DeleteConfirm/";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./style.less";

export const INIT_STATE = {
    status: "",
    dialog: "",
    delete: {
        environmentId: "",
        name: ""
    },
}

class EnvironmentList extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        environments: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        environmentsDelete: PropTypes.func.isRequired,
        environmentsReadAll: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        $(".stage").addClass("stage-bgnd-dark");
        this.initialize();
    }

    public componentWillUnmount() {
        $(".stage").removeClass("stage-bgnd-dark");
    }

    public render() {
        const {accountId, domain, port, protocol, type} = getHostnameInfo();

        return <div className="environments">
            <div className="container" style={{paddingTop: 48}}>
                <p data-qa="welcome-text-dashboard" style={{margin: "0 0 15px 0", color: this.props.muiTheme.palette.textColor, fontSize: 24, fontWeight: 500, lineHeight: "28px"}}>
                    interopiO® Management Console
                </p>
                <p style={{width: 513, margin: "0 0 10px 0", color: this.props.muiTheme.palette.secondaryTextColor, fontSize: 16, fontWeight: 400, lineHeight: "20px"}}>
                    Welcome! interopiO® is an innovations platform for digital health. To get started, create an environment or visit the Support Center.
                </p>
                <p style={{margin: "0 0 72px 0"}}>
                    <a href="https://support.interopio.com/hc/en-us/categories/360001801431-Getting-Started" target="_blank" style={{margin: "0 15px 0 0"}}>
                        Getting Started
                    </a>
                    <a href="https://support.interopio.com/hc/en-us/categories/360001807451-Using-interopiO-" target="_blank" style={{margin: "0 15px 0 0"}}>
                        Documentation
                    </a>
                    <a href="https://support.interopio.com/hc/en-us" target="_blank" style={{margin: "0 15px 0 0"}}>
                        Support
                    </a>
                </p>

                {this.state.status !== "ready"
                    ? <CenteredCircularProgress className="__test__-circular-progress" size={63}/>
                    : <CardsContainer className="__test__-cards-container">
                        {isAccountOwner(this.props.login, this.props.config)
                            ? <CardWrapper key={"card-add-new"} style={{minHeight: 200}}>
                                <CardBodyAddNew data-qa="add-new-environment-action" text="Add Environment" style={{minHeight: 200, padding: 66}}
                                    onClick={() => this.setState({dialog: ":ADD_NEW:"})}/>
                            </CardWrapper>
                            : null}

                        {this.props.environments.all.data.map((item, index) => {
                            if (!item) return null
                            return <CardWrapper key={`card-${index}-${item.environmentId}`} style={{minHeight: 200}}>
                                <CardMenuBar
                                    data-qa={`env-card-${item.environmentId}`}
                                    title={item.name}
                                    style={{paddingBottom: 0}}
                                    onClick={() => this.props.history.push(`/${item.environmentId}`)}
                                    menuItems={isEnvironmentAdmin(this.props.login, item.environmentId, this.props.config)
                                        ? [
                                            {
                                                leftIcon: <VisibilityIcon/>,
                                                text: "View",
                                                onClick: () => this.props.history.push(`/environments/${item.environmentId}`)
                                            },
                                            {
                                                leftIcon: <DeleteIcon/>,
                                                text: "Delete",
                                                "data-qa": "delete-env-action",
                                                onClick: () => this.setState({
                                                    dialog: ":DELETE_CONFIRM:",
                                                    delete: {
                                                        environmentId: item.environmentId,
                                                        name: item.name
                                                    }
                                                })
                                            }]
                                        : []}/>
                                <CardBody text={item.description} style={{minHeight: 100, paddingTop: 0}} onClick={() => this.props.history.push(`/${item.environmentId}`)}/>
                                <CardCustomContent
                                    style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    }}
                                    onClick={() => this.props.history.push(`/${item.environmentId}`)}
                                >
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            marginRight: 2,
                                            marginBottom: 10,
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {item.phi ?
                                            <Chip
                                                label={(() => {
                                                    return (
                                                        <>
                                                            <Avatar
                                                                style={{
                                                                    display: 'inline-block',
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    left: '-11px',
                                                                }}
                                                            >
                                                                <Lock
                                                                    style={{
                                                                        position: 'relative',
                                                                        top: '3px',
                                                                        left: '4px',
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </Avatar>
                                                            <span>
                                                                PHI enabled
                                                            </span>
                                                        </>
                                                    )
                                                })()}
                                                classes={{ label: 'full-width-chip-label' }}
                                                style={{ width: '100%', cursor: 'pointer' }}
                                            />
                                            : null
                                        }
                                    </div>
                                </CardCustomContent>
                                <CardFooter style={{ cursor: 'pointer' }}
                                    linkTo={`/${item.environmentId}`}
                                    linkText={`${protocol}//${accountId}.${domain}.${type}${port ? ":" + port : ""}/console/${item.environmentId}`}/>
                            </CardWrapper>})}
                    </CardsContainer>}
            </div>
            <br />

            {this.renderDialogs()}
        </div>;
    }

    private renderDialogs = () => {
        if (this.state.dialog === ":ADD_NEW:") {
            return <EnvironmentAddNewWizard className="__test__-add-new" initialize={this.initialize} onClose={() => this.setState({ dialog: "" })} />;
        } else if (this.state.dialog === ":DELETE_CONFIRM:") {
            return <WidgetsDeleteConfirm {...this.props} type="environment" delete={this.state.delete} confirmName={true} className="__test__-delete-confirm"
                onClose={() => this.setState({dialog: "", delete: {}})}
                onDelete={async () => {
                    const {accountId} = getHostnameInfo();
                    const res = await this.props.environmentsDelete(this.props.config, getEnvironmentsCallUrl(accountId) + "/" + this.state.delete.environmentId);
                    if (res.status < 300) {
                        this.setState({dialog: "", delete: {}}, this.initialize);
                    }
                }}/>;
        }
        return null;
    };

    private initialize = () => {
        this.setState(
            {status: "init"},
            async () => {
                const {accountId} = getHostnameInfo();
                const url = getEnvironmentsCallUrl(accountId);
                await this.props.environmentsReadAll(this.props.config, url);
                this.setState({status: "ready"});
            },
        );
    }
}

export { EnvironmentList };

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    environments: state.environments,
    login: state.login,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    environmentsDelete: actions.environmentsDelete,
    environmentsReadAll: actions.environmentsReadAll,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(EnvironmentList)));
