import * as validateId from "./validate-id";

const isUrl = require("is-url");

export function onWStep1(
    state: any,
    cdsServiceSets: any,
    config: any,
) {
    const name = state.data.name ? "" : "Required field";

    const ids = cdsServiceSets.all.data.map((item) => item.cdsServiceSetId);
    let cdsServiceSetId = "";
    cdsServiceSetId = validateId.isUrlFriendly(state.data.cdsServiceSetId) ? cdsServiceSetId : "The ID can only contain: lower case letters of the English alphabet, numbers (0-9), hyphen/minus sign (-), it must start with a letter and end with a letter or number, not a hyphen";
    cdsServiceSetId = validateId.isNotTooLong(state.data.cdsServiceSetId) ? cdsServiceSetId : "This ID is too long";
    cdsServiceSetId = validateId.isNotTooShort(state.data.cdsServiceSetId) ? cdsServiceSetId : "This ID is too short";
    cdsServiceSetId = state.data.cdsServiceSetId ? cdsServiceSetId : "Required field";
    cdsServiceSetId = validateId.isUnique(state.data.cdsServiceSetId, ids) ? cdsServiceSetId : "This ID already exists";
    cdsServiceSetId = validateId.isNotRestricted(state.data.cdsServiceSetId, config.reservedIds) ? cdsServiceSetId : "This ID is reserved";

    return {
        name,
        cdsServiceSetId,
    };
}

export function onWStep2(state: any) {
    let sourceUrl = ""
    sourceUrl = isUrl(state.data.sourceUrl) ? sourceUrl : "This is not a valid URL";
    sourceUrl = state.data.sourceUrl ? sourceUrl : "Required field";

    return {
        sourceUrl,
    };
}

export function onEditDetails(state: any) {
    const name = state.data.name ? "" : "Required field";

    let sourceUrl = ""
    sourceUrl = isUrl(state.data.sourceUrl) ? sourceUrl : "This is not a valid URL";
    sourceUrl = state.data.sourceUrl ? sourceUrl : "Required field";

    return {
        name,
        sourceUrl,
    };
}
