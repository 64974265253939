import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../../../../redux/actions";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import CopyIcon from "@material-ui/icons/FileCopy";
import getGatewaysResourceUrl from "../../../../../../../../../lib/getGatewaysResourceUrl";
import CdsServiceGatewayActivations from "../../../../Shared/CdsServiceGatewayActivations";
class ETab1 extends React.Component<any, any> {
    public static propTypes = {
        cdsServiceSets: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        gateways: PropTypes.object.isRequired,
        estate: PropTypes.object.isRequired,
        onSetEState: PropTypes.func.isRequired,
    };

    private gwRef = null;
    private cdsRef = null;

    constructor(props) {
        super(props);
        this.gwRef = React.createRef();
        this.cdsRef = React.createRef();
    }

    public render() {
        const canEdit = this.props.estate.mode === ":EDIT:";

        return <div style={{ padding: "0 24px 24px 24px" }}>
            {this.renderName(canEdit)}
            {this.renderGateway(canEdit)}
            {this.renderGatewayUrl()}
            {this.renderCdsDiscoveryEndpoint()}
            {this.renderInvocationTimeout()}
            <CdsServiceGatewayActivations canEdit={canEdit} wstate={this.props.estate} onSetWState={this.props.onSetEState} />
        </div>;
    }

    private renderName = (canEdit) => {
        return <TextField
            name="name" value={this.props.estate.data.name || ""}
            placeholder="Display name" label="Display Name" helperText={this.props.estate.dataValidation.name}
            error={!!this.props.estate.dataValidation.name} disabled={!canEdit || this.props.estate.status !== "ready"}
            autoComplete="off" autoFocus={false} fullWidth
            classes={{ root: canEdit ? "" : "disabled-text-field" }}
            style={{ marginTop: 16 }}
            onChange={canEdit ? (event) => this.props.onSetEState((prevEState) => ({ data: { ...prevEState.data, name: event.target.value } })) : undefined}
        />
    };

    private renderGateway = (canEdit) => {
        const gateways = this.props.gateways?.all?.data || [];
        const activationsAll = this.props.cdsServiceSets?.selected?.data?.activations || [];
        const activationsAllGatewayIds = activationsAll.map((activation) => activation.gatewayId);
        const filteredGateways = gateways.filter((gateway) => !activationsAllGatewayIds.includes(gateway.gatewayId) || gateway.gatewayId === this.props.estate.data.gatewayId);

        return <FormControl
            disabled={!canEdit || this.props.estate.status !== "ready"}
            error={!!this.props.estate.dataValidation.gateway}
            style={{ minWidth: 280, marginTop: 32 }}
        >
            <InputLabel id="cds-gateway-label">
                Gateway
            </InputLabel>
            <Select
                labelId="cds-gateway-select"
                id="cds-gateway-select"
                value={this.props.estate.data.gatewayId || ""}
                onChange={(event) => this.props.onSetEState((prevWState) => ({
                    data: {
                        ...prevWState.data,
                        gatewayId: event.target.value,
                    },
                }))}
            >
                {filteredGateways.map(({ gatewayId, name }, index) => (
                    <MenuItem key={`gateway-item-${gatewayId}-${index}`} value={gatewayId}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>
                {this.props.estate.dataValidation.gateway}
            </FormHelperText>
        </FormControl>
    };

    private renderGatewayUrl = () => {
        const gatewayObject = this.props.gateways.all.data.find((gateway) => gateway.gatewayId === this.props.estate.data.gatewayId) || {};
        const gatewayResourceUrl = gatewayObject.gatewayType === "HL7_V2"
            ? "hl7://interopiondemo.smilecdr.com:7000"
            : getGatewaysResourceUrl(gatewayObject.accountId, gatewayObject.environmentId, gatewayObject.gatewayId, this.props.config.proxyApi);

        return <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                <TextField
                    name="gatewayUrl" value={gatewayResourceUrl}
                    label="Gateway URL" disabled
                    autoComplete="off" autoFocus={false} fullWidth
                    classes={{ root: "disabled-text-field" }}
                    style={{ marginTop: 16 }}
                />
                <IconButton style={{ height: 48, paddingTop: 16 }} onClick={() => this.handleCopy(":GATEWAY_URL:")}>
                    <Tooltip title="Copy"><CopyIcon /></Tooltip>
                </IconButton>
            </div>
            <textarea
                ref={this.gwRef}
                value={gatewayResourceUrl}
                style={{ width: 1, height: 1, margin: 0, padding: 0, border: "none", opacity: 0 }}
                readOnly
            />
        </>
    };

    private renderCdsDiscoveryEndpoint = () => {
        const gatewayObject = this.props.gateways.all.data.find((gateway) => gateway.gatewayId === this.props.estate.data.gatewayId) || {};
        const gatewayResourceUrl = gatewayObject.gatewayType === "HL7_V2"
            ? "hl7://interopiondemo.smilecdr.com:7000"
            : getGatewaysResourceUrl(gatewayObject.accountId, gatewayObject.environmentId, gatewayObject.gatewayId, this.props.config.proxyApi);
        const gatewayCdsDiscoveryEndpoint = gatewayResourceUrl + "/cds-services";

        return <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                <TextField
                    name="gatewayCdsDiscovery" value={gatewayCdsDiscoveryEndpoint}
                    label="CDS Discovery Endpoint" disabled
                    autoComplete="off" autoFocus={false} fullWidth
                    classes={{ root: "disabled-text-field" }}
                    style={{ marginTop: 16 }}
                />
                <IconButton style={{ paddingTop: 16 }} onClick={() => this.handleCopy("CDS_DISCOVERY_ENDPOINT")}>
                    <Tooltip title="Copy"><CopyIcon /></Tooltip>
                </IconButton>
            </div>
            <textarea
                ref={this.cdsRef}
                value={gatewayCdsDiscoveryEndpoint}
                style={{ width: 1, height: 1, margin: 0, padding: 0, border: "none", opacity: 0 }}
                readOnly
            />
        </>
    };

    private renderInvocationTimeout = () => {
        return <TextField
            name="invocation-time" value="20 sec"
            label="Service Invocation Timeout" disabled
            autoComplete="off" autoFocus={false} fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />
    };

    private handleCopy = (key) => {
        const ref = {
            ":GATEWAY_URL:": this.gwRef,
            "CDS_DISCOVERY_ENDPOINT": this.cdsRef,
        }[key];

        if (!ref) return;

        ref.current.select();
        document.execCommand("copy");

        this.props.generalMessageSet({
            type: ":INFO_SNACKBAR:",
            title: "Copied to Clipboard",
        });
    };
}

const mapStateToProps = (state, ownProps) => ({
    cdsServiceSets: state.cdsServiceSets,
    config: state.config,
    gateways: state.gateways,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    generalMessageSet: actions.generalMessageSet,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ETab1);
