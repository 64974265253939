import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

const CustomDialogActions  = (props) => {
    return <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
        <Button data-qa="delete-confirm-close"
            color="primary" style={{ marginRight: 8 }}
            onClick={props.onClose}
        >
            {props.error ? "Cancel" : "Close"}
        </Button>
        {!props.error ? <Button data-qa="delete-confirm"
            variant="contained" color="secondary" style={{ marginRight: 8 }}
            disabled={!props.canDelete}
            onClick={props.onDelete}
        >
            {`${props.action} ${props.type}`}
        </Button> : null}
    </DialogActions>;
}

CustomDialogActions.propTypes = {
    action: PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,

    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default CustomDialogActions;
