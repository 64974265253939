import * as PropTypes from "prop-types";
import * as React from 'react';
import * as validateData from "../../../../../../lib/validate-portal-app-custom-context-data";
import {useState} from "react";
import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import { Add, Clear } from '@material-ui/icons';
import "./style.less";

interface CustomContext {
    contextKey: string;
    contextValue: string;
    dataValidation: {
        newKey: string,
        newValue: string
    }
}

const EditCustomContext = (props) => {

    const [customContext, setCustomContext] = useState<CustomContext>({
        contextKey: props.contextKey || "",
        contextValue: props.contextValue ||  "",
        dataValidation: {
            newKey: "",
            newValue: ""
        }
    });

    const editCustomContext = () => {
        props.editCustomContext(customContext.contextKey, customContext.contextValue, props.itemIndex);
    }

    const cancelEdit = () => {
        props.cancelEdit();
    }

    return (
        <div className={`new-custom-context MuiPaper-rounded MuiPaper-outlined ${props.className}`}>
            <div className="new-custom-context-header">
                <h5>Edit Custom Context</h5>
                <div className="edit-custom-context-controls">
                    <Fab
                        data-e2e='custom-context-save-edit'
                        style={{marginRight: '10px'}}
                        size="small"
                        disabled={(!customContext?.contextKey
                            || !customContext?.contextValue
                            || !!customContext?.dataValidation?.newKey
                            || !!customContext?.dataValidation?.newValue
                        )}
                        onClick={() => {
                            editCustomContext();
                        }}
                    >
                        <Add />
                    </Fab>
                    <Fab
                        data-e2e='custom-context-cancel-edit'
                        size="small"
                        onClick={() => {
                            cancelEdit();
                        }}
                    >
                        <Clear />
                    </Fab>
                </div>
            </div>

            <TextField
                data-e2e={`update-key-${customContext?.contextKey}`}
                name="addNewCustomContextName"
                label="Key"
                value={customContext?.contextKey || ''}
                helperText={customContext?.dataValidation?.newKey}
                error={!!customContext?.dataValidation?.newKey}
                fullWidth
                inputProps={{ maxLength: 255 }}
                onChange={(event) => {
                    let newCustomContextKey = event.target.value;
                    const potalContexts = props.providerPortalCustomContexts
                        ? [...props.providerPortalCustomContexts]
                        : [...props.patientPortalCustomContexts]

                    let portalContextList = [...potalContexts]
                    // The data validation makes sure we don't have a duplicate key.  But we don't want
                    // it to find itself in the list and think it's a duplicate.
                    portalContextList.splice(props.itemIndex, 1);
                    let validKey = event.target.value ?
                        validateData.onChangeCustomContextKey(portalContextList, newCustomContextKey)
                        : "";
                    setCustomContext(prevState => ({
                        ...prevState,
                        contextKey: newCustomContextKey,
                        dataValidation: {
                            ...prevState.dataValidation,
                            newKey: validKey
                        }
                    }))
                }}
            />

            <TextField
                data-e2e={`update-value-${customContext?.contextKey}`}
                name="addNewCustomContextValue"
                label="Value"
                value={customContext?.contextValue || ''}
                helperText={customContext?.dataValidation?.newValue}
                error={!!customContext?.dataValidation?.newValue}
                fullWidth
                inputProps={{ maxLength: 255 }}
                onChange={(event) => {
                    let newCustomContextValue = event.target.value;
                    let validValue = event.target.value ?
                        validateData.onChangeCustomContextValue(newCustomContextValue)
                        : "";
                    setCustomContext(prevState => ({
                        ...prevState,
                        contextValue: newCustomContextValue,
                        dataValidation: {
                            ...prevState.dataValidation,
                            newValue: validValue
                        }
                    }))
                }}
            />
        </div>
    )
}

EditCustomContext.propTypes = {
    className: PropTypes.string,
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    addNewCustomContext: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired,
    contextKey: PropTypes.string,
    contextValue: PropTypes.string,
    itemIndex: PropTypes.number,
    patientPortalCustomContexts: PropTypes.array,
    portals: PropTypes.object,
    providerPortalCustomContexts: PropTypes.array,
    editCustomContext: PropTypes.func.isRequired,
    cancelEdit: PropTypes.func.isRequired
}

export default EditCustomContext
