import { TState as TStateEdit } from '../components/Pages/Gateways/GatewayEdit'
import * as validateId from "./validate-id";
import { getPath } from "./utils/"

const isUrl = require("is-url")

export type TStateDataValidationOnEditDetails = {
    name: string
    hl7Url: string
    oktaJwkUrl: string
}

export function onEditDetails(data: TStateEdit['data']): TStateDataValidationOnEditDetails {
    const name = data.name ? '' : 'Required field'
    let hl7Url = ''
    let oktaJwkUrl = ''
    if (data.hl7Enabled) {
        if (!data.hl7Properties?.url) {
            hl7Url = 'Required field'
        }
        else if (!isUrl(data.hl7Properties.url)) {
            hl7Url = 'Invalid URL'
        }
    }
    if (data.authenticationType === 'OKTA_JWK') {
        if (!data.oktaJwkUrl) {
            oktaJwkUrl = 'Required field'
        }
        else if (!isUrl(data.oktaJwkUrl)) {
            oktaJwkUrl = 'Invalid URL'
        }
    }

    return {
        name,
        hl7Url,
        oktaJwkUrl
    }
}

export function onEditCdsServiceAuth(state: any) {
    let jwkSetString: string = state.jwkSetString;
    let jwkSetMessage: string = "";

    if (jwkSetString) {
        try {
            JSON.parse(jwkSetString);
        } catch (reason) {
            jwkSetMessage = "Invalid JSON format"
        }
        if (jwkSetString === "null") {
            jwkSetMessage = "Invalid JSON format"
        }
    }

    return {
        jwkSet: jwkSetMessage,
    };
}

export function onEditPatientPortal(state: any, config: any) {
    const patientPortalConfiguration = getPath(state, "data.patientPortalConfiguration") || {};
    const shortcut: string = patientPortalConfiguration.shortcut || "";

    let shortcutMessage: string = "";
    if (shortcut) {
        shortcutMessage = validateId.isUrlFriendly(shortcut) ? shortcutMessage : "The shortcut can only contain: lower case letters of the English alphabet, numbers (0-9), hyphen/minus sign (-), it must start with a letter and end with a letter or number, not a hyphen";
        // shortcutMessage = validateId.isNotTooLong(shortcut) ? shortcutMessage : "This shortcut is too long";
        shortcutMessage = validateId.isNotTooShort(shortcut) ? shortcutMessage : "This shortcut is too short";
        shortcutMessage = validateId.isNotRestricted(shortcut, config.reservedIds) ? shortcutMessage : "This shortcut is reserved";
    }

    return {
        patientPortalShortcut: shortcutMessage,
    };
}

export function onEditProviderPortal(state: any, config: any) {
    const providerPortalConfiguration = getPath(state, "data.providerPortalConfiguration") || {};
    const shortcut: string = providerPortalConfiguration.shortcut || "";

    let shortcutMessage: string = "";
    if (shortcut) {
        shortcutMessage = validateId.isUrlFriendly(shortcut) ? shortcutMessage : "The shortcut can only contain: lower case letters of the English alphabet, numbers (0-9), hyphen/minus sign (-), it must start with a letter and end with a letter or number, not a hyphen";
        // shortcutMessage = validateId.isNotTooLong(shortcut) ? shortcutMessage : "This shortcut is too long";
        shortcutMessage = validateId.isNotTooShort(shortcut) ? shortcutMessage : "This shortcut is too short";
        shortcutMessage = validateId.isNotRestricted(shortcut, config.reservedIds) ? shortcutMessage : "This shortcut is reserved";
    }

    return {
        providerPortalShortcut: shortcutMessage,
    };
}
