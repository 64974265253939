import * as React from "react";
import * as PropTypes from "prop-types";
import {TextField} from "@material-ui/core";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    return <div className="acc-ul-h1-content">
        <div className="content-left-side">
            <TextField name="user" value={props.state.filters.user || ""} placeholder="Search user" style={{height: "46px", borderRadius: "2px"}}
                onChange={e => props.onSetState({filters: {...props.state.filters, user: e.target.value}})}/>
        </div>
        <div className="content-right-side"/>
    </div>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
