import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import getActiveEnvironment, {ActiveEnvironment} from "../lib/getActiveEnvironment";
import accounts from "./reducers/accounts/";
import apps from "./reducers/apps/";
import appUsers from "./reducers/app-users/";
import cdrs from "./reducers/cdrs/";
import cdsServiceSets from "./reducers/cds-service-sets";
import config from "./reducers/config/";
import dataSources from "./reducers/data-sources/";
import environments from "./reducers/environments/";
import features from "./reducers/features/";
import gateways from "./reducers/gateways/";
import generalMessage from "./reducers/general-message/";
import login from "./reducers/login/";
import ui from "./reducers/ui/";
import userDirs from "./reducers/user-dirs/";
import users from "./reducers/users/";
import portals from "./reducers/portals";

const activeEnv: ActiveEnvironment = getActiveEnvironment();

const reducers = combineReducers({
    accounts,
    apps,
    appUsers,
    cdrs,
    cdsServiceSets,
    config,
    dataSources,
    features,
    environments,
    gateways,
    generalMessage,
    login,
    ui,
    userDirs,
    users,
    portals
});

let composeEnhancers = compose;
if (
    (
        process.env.NODE_ENV === "development"
        || activeEnv === ":LOCAL:"
        || activeEnv === ":DEVELOPMENT:"
    )
    && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

const store = createStore(
    reducers,
    {},
    composeEnhancers(applyMiddleware(thunk))
);

export default store;
