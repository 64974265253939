import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import * as features from "../../../../lib/feature-helpers";
import getLocationInfo from "../../../../lib/getLocationInfo";
import { NavbarL2 } from "../../../CustomNavbar/";
import { CardsContainer, CardWrapper, CardImage, CardBody, CardFooter } from "../../../Widgets/CustomCard/";
import "./style.less";

class EnvironmentDashboard extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired
    };

    public render() {
        const {env} = getLocationInfo(this.props.location, this.props.config);

        const featureSmileCdrCustomization: boolean = features.check(":SMILE_CDR_CUSTOMIZATION:", this.props.features.data);

        const CARDS = [
            {
                imageSrc: "/console/img/card-media-apps.png",
                title: "Apps",
                text: "Register and activate your SMART™ on FHIR® apps across various health systems and EHR vendors. Monitor their health and analyze usage in realtime.",
                anchorText: "Learn more",
                anchorHref: this.props.config.knowledgeBaseLinks.apps || this.props.config.knowledgeBaseLinks.default,
                anchorTarget: "_blank",
                buttonLabel: "GET STARTED",
                onClick: () => this.props.history.push(`/${env}/apps`)
            },
            {
                imageSrc: "/console/img/card-media-cds.png",
                title: "CDS Services",
                text: "Register and activate your CDS Hooks based services. Alert more intelligently with interopiO®’s usage intelligence. Monitor their health and analyze usage in realtime.",
                anchorText: "Learn more",
                anchorHref: this.props.config.knowledgeBaseLinks.cdsServiceSets || this.props.config.knowledgeBaseLinks.default,
                anchorTarget: "_blank",
                buttonLabel: "GET STARTED",
                onClick: () => this.props.history.push(`/${env}/cds-service-sets`)
            },
            {
                imageSrc: "/console/img/card-media-gateways.png",
                title: "Gateways",
                text: "Enable healthcare focused API management using standards such as SMART on FHIR®, CDS Hooks, HL7 messaging, etc. backed by one or more Data Adapter or FHIR® CDR.",
                anchorText: "Learn more",
                anchorHref: this.props.config.knowledgeBaseLinks.gateways || this.props.config.knowledgeBaseLinks.default,
                anchorTarget: "_blank",
                buttonLabel: "GET STARTED",
                onClick: () => this.props.history.push(`/${env}/gateways`)
            },
            {
                imageSrc: "/console/img/card-media-data-sources.png",
                title: "Data Adapters",
                text: "Connect and transform any data source into a FHIR® complaint data source. Choose from our existing library of Data Adapters or create your own.",
                anchorText: "Learn more",
                anchorHref: this.props.config.knowledgeBaseLinks.dataSources || this.props.config.knowledgeBaseLinks.default,
                anchorTarget: "_blank",
                buttonLabel: "GET STARTED",
                onClick: () => this.props.history.push(`/${env}/datasources`)
            },
            {
                imageSrc: featureSmileCdrCustomization
                    ? "/console/img/card-media-smile-cdrs.png"
                    : "/console/img/card-media-cdrs.png",
                title: featureSmileCdrCustomization
                    ? "Smile CDR"
                    : "FHIR CDRs",
                text: "Create a secure and scalable, cloud-based, FHIR® complaint Clinical Data Repository. Route specific data elements through your Gateways to your own CDR.",
                anchorText: "Learn more",
                anchorHref: this.props.config.knowledgeBaseLinks.cdrs || this.props.config.knowledgeBaseLinks.default,
                anchorTarget: "_blank",
                buttonLabel: "GET STARTED",
                onClick: () => this.props.history.push(`/${env}/cdrs`)
            },
            {
                imageSrc: "/console/img/card-media-auth.png",
                title: "Authentication",
                text: "Manage User Directories either delegated from integrated EHRs, integrated to existing LDAP or Active Directory servers, or create and host your own User Directory.",
                anchorText: "Learn more",
                anchorHref: this.props.config.knowledgeBaseLinks.userDirs || this.props.config.knowledgeBaseLinks.default,
                anchorTarget: "_blank",
                buttonLabel: "GET STARTED",
                onClick: () => this.props.history.push(`/${env}/auth`)
            },
            {
                imageSrc: "/console/img/card-media-settings.png",
                title: "Settings",
                text: "Take control of your environment by adjusting its finer points.",
                anchorText: "Learn more",
                anchorHref: this.props.config.knowledgeBaseLinks.environments || this.props.config.knowledgeBaseLinks.default,
                anchorTarget: "_blank",
                buttonLabel: "GET STARTED",
                onClick: () => this.props.history.push(`environments/${env}`)
            }
        ];

        return <div className="environment-dashboard" data-qa="environment-dashboard">
            <NavbarL2/>

            <div className="container">
                <p className="titleSmall" data-qa="environment-dashboard-welcome">
                    Welcome to interopiO!
                </p>
                <p className="titleLarge">
                    Get started here.
                </p>
                <p className="sub">
                    Discover interopiO
                </p>

                <CardsContainer>
                    {CARDS.map((item: any, index) => (
                        <CardWrapper key={index} style={{width: "308px", height: "307px", margin: "0 21px 17px 0", borderRadius: "14px", paddingBottom: "24px"}}>
                            <CardImage src={item.imageSrc} onClick={item.onClick} style={{height: "126px"}} imgStyle={{height: "126px", borderRadius: "12px 12px 0 0"}}/>
                            <CardBody title={item.title} text={item.text} onClick={item.onClick} style={{padding: "16px 24px 0"}}
                                textStyles={{marginBottom: 0, height: "76px", lineHeight: "18px"}}/>
                            <CardFooter anchorText={item.anchorText} anchorHref={item.anchorHref} anchorTarget={item.anchorTarget}
                                buttonLabel={item.buttonLabel} onClickButton={item.onClick} style={{padding: "4px 24px 4px 24px"}}/>
                        </CardWrapper>
                    ))}
                </CardsContainer>
            </div>
        </div>;
    }
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    features: state.features,
    ...ownProps,
});
export default connect(mapStateToProps)(EnvironmentDashboard);
