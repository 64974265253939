import * as React from "react";
import * as PropTypes from "prop-types";
import Cell from "./Cell/";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    return <div className="gw-mapping-rl-h2-content">
        <div className="type">
            <Cell {...props} name="type" label="Resource"/>
        </div>

        <div className="operations">
            <Cell {...props} name="operations" label="Operations" sortable={false}/>
        </div>

        <div className="source">
            <Cell {...props} name="source" label="DataAdapter"/>
        </div>
    </div>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired
};

export default Component;
