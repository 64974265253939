import PropTypes from "prop-types";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../redux/actions/";

import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../lib/getLocationInfo";
import getMessageFromResponse from "../../../../../lib/getMessageFromResponse";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";

import ServicesList from "./ServicesList";

const CheckCdsServices = (props) => {
    return (
        <>
            <div style={{ display: "flex", alignItems: "baseline" }}>
                <TextField
                    name="sourceUrl" value={props.parentState.data.sourceUrl || ""}
                    placeholder="Discovery URL" label="Discovery URL" helperText={props.parentState.dataValidation.sourceUrl}
                    error={!!props.parentState.dataValidation.sourceUrl}
                    disabled={!props.canEdit || props.parentState.status !== "ready"}
                    autoComplete="off" autoFocus={false} fullWidth
                    classes={{ root: props.canEdit ? "" : "disabled-text-field" }}
                    style={{ marginTop: 16 }}
                    onChange={(event) => props.onSetParentState(prevParentState => ({
                        data: {
                            ...prevParentState.data,
                            cdsServices: null,
                            sourceUrl: event.target.value,
                        },
                        dataValidation: {
                            ...prevParentState.dataValidation,
                            cdsServices: "",
                        },
                    }))}
                />
                {props.parentState.status === "ready"
                    ? <IconButton
                        data-qa="check-cds-service-btn"
                        disabled={!props.parentState.data.sourceUrl || !!props.parentState.data.cdsServices || !!props.parentState.dataValidation.sourceUrl || !!props.parentState.dataValidation.cdsServices}
                        color="primary"
                        onClick={async () => {
                            props.onSetParentState({ status: "waiting" });
                            const { accountId } = getHostnameInfo();
                            const { env } = getLocationInfo(props.location, props.config);
                            let response: any = {};
                            try {
                                response = await props.cdsServiceSetsCheckDiscovery(props.parentState.data.sourceUrl, accountId, env, { bypassError: true });
                            }
                            catch (reason) {
                                return props.onSetParentState((prevParentState) => ({
                                    status: "ready",
                                    data: {
                                        ...prevParentState.data,
                                        cdsServices: null,
                                    },
                                    dataValidation: {
                                        ...prevParentState.dataValidation,
                                        cdsServices: getMessageFromResponse(reason, true),
                                    },
                                }));
                            }
                            const responseStatus = response?.status;
                            const cdsServices = response?.data?.cdsServices;
                            if (responseStatus < 300 && Array.isArray(cdsServices)) {
                                if (!cdsServices.length) {
                                    return props.onSetParentState((prevParentState) => ({
                                        status: "ready",
                                        data: {
                                            ...prevParentState.data,
                                            cdsServices: null,
                                        },
                                        dataValidation: {
                                            ...prevParentState.dataValidation,
                                            cdsServices: "Can not find any CDS Services",
                                        },
                                    }));
                                }
                                props.onSetParentState((prevParentState) => ({
                                    status: "ready",
                                    data: {
                                        ...prevParentState.data,
                                        cdsServices,
                                    },
                                    dataValidation: {
                                        ...prevParentState.dataValidation,
                                        cdsServices: "",
                                    }
                                }));
                            }
                        }}
                    ><SendIcon /></IconButton>
                    : <IconButton disabled><CircularProgress /></IconButton>
                }
            </div>

            <div style={{ padding: 8, transform: props.parentState.dataValidation.cdsServices ? "scaleY(1)" : "scaleY(0)", transformOrigin: "top", borderRadius: 2, background: props.ui.xtheme.palette.colorRedDark, color: "#fff", transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)" }}>
                {props.parentState.dataValidation.cdsServices}
            </div>

            <ServicesList parentState={props.parentState} />
        </>
    );
};

CheckCdsServices.propTypes = {
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,

    cdsServiceSetsCheckDiscovery: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired,

    canEdit: PropTypes.bool,
};

CheckCdsServices.defaultProps = {
    canEdit: false,
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ui: state.ui,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsCheckDiscovery: actions.cdsServiceSetsCheckDiscovery,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckCdsServices));
