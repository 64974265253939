import * as React from "react";
import * as PropTypes from "prop-types";
import {CardActions, CircularProgress, Button} from "@material-ui/core";
import getMessageFromResponse from "../../../../../lib/getMessageFromResponse";
import consoleLogger from "../../../../../lib/consoleLogger";

const Component: React.FunctionComponent<any> = props => {
    return <CardActions>
        <div style={{
            height: props.state.mode === ":EDIT:" ? "36px" : 0,
            marginTop: props.state.mode === ":EDIT:" ? "8px" : 0,
            marginBottom: props.state.mode === ":EDIT:" ? "8px" : 0,
            textAlign: "right",
            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
        }}>
            <Button variant="outlined" disabled={props.state.mode !== ":EDIT:"} style={{display: props.state.mode === ":EDIT:" ? "inline-block" : "none", marginRight: "8px"}}
                onClick={() => props.onSetState({mode: ":VIEW:"}, props.initialize)}>
                Cancel
            </Button>
            <Button variant="contained" color="primary" disabled={props.state.mode !== ":EDIT:" || !props.isValid || props.state.status !== "ready"}
                style={{display: props.state.mode === ":EDIT:" ? "inline-block" : "none"}}
                onClick={() => {
                    const accountId = props.state.data.accountId;
                    const environmentId = props.state.data.environmentId;
                    const gatewayId = props.state.data.gatewayId;

                    let jwkSetObject = null;
                    if (props.state.jwkSetString) {
                        try {
                            jwkSetObject = JSON.parse(props.state.jwkSetString);
                        } catch (reason) {
                            consoleLogger.error("::: reason", reason);
                            consoleLogger.error("::: reason", props.state.jwkSetString, "" + props.state.jwkSetString);
                        }
                    }

                    props.onSetState(
                        {status: "wait"},
                        async () => {
                            let res;
                            try {
                                res = await props.gatewayPutJwkSet(accountId, environmentId, gatewayId, jwkSetObject, {bypassError: true});
                            } catch (reason) {
                                consoleLogger.error("::: reason:", reason);
                                res = null;
                                props.onSetState({
                                    status: "ready",
                                    dataValidation: {
                                        ...props.state.dataValidation,
                                        jwkSet: getMessageFromResponse(reason, true)
                                    }
                                });
                            }
                            if (res && res.status < 300) {
                                props.onSetState(
                                    {mode: ":VIEW:"},
                                    props.initialize
                                );
                            }
                        }
                    );
                }}>
                {props.state.status === "wait" ? <CircularProgress size={24}/> : null} Save
            </Button>
        </div>
    </CardActions>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    gatewayPutJwkSet: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired,
    isValid: PropTypes.bool
};

Component.defaultProps = {
    isValid: true
};

export default Component;
