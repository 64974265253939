import * as types from "../../actions/types";

import initialState from "./initial-state";

export default function (state = initialState, action: any) {
    switch (action.type) {
        case types.CONFIG_KNOWLEDGE_BASE_LINKS_SET: {
            return {
                ...state,
                knowledgeBaseLinks: {
                    ...state.knowledgeBaseLinks,
                    ...action.payload,
                },
            };
        }

        case types.CONFIG_USER_ROLES_SET: {
            return {
                ...state,
                userRoles: action.payload,
            };
        }

        case types.CONFIG_USER_STATUSES_SET: {
            return {
                ...state,
                userStatuses: action.payload,
            };
        }

        default:
            return state;
    }
}
