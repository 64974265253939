import * as PropTypes from "prop-types";
import AppUserAddNewWizard from "./AppUserAddNewWizard/";
import AppUserDeleteConfirm from "./AppUserDeleteConfirm";
import AppUserEdit from "./AppUserEdit";
import AppUserAsFhir from "./AppUserAsFhir";

const Dialogs = (props) => {
    const propSet = {
        parentState: props.parentState,
        initialize: props.initialize,
        onClose: props.initialize,
        onSetParentState: props.onSetParentState,
    };

    switch (props.parentState.dialog) {
        case ":ADD_APP_USER:":
            return <AppUserAddNewWizard {...propSet} />;
        case ":DELETE_APP_USER_CONFIRM:":
            return <AppUserDeleteConfirm {...propSet} />;
        case ":EDIT_APP_USER:":
            return <AppUserEdit {...propSet} />;
        case ":APP_USER_AS_FHIR:":
            return <AppUserAsFhir {...propSet} />;
        default: return null;
    }
}

Dialogs.propTypes = {
    parentState: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired,
};

export default Dialogs;
