import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import getMessageFromResponse from "../../../../lib/getMessageFromResponse";
import {Card, CardContent, CardHeader, CircularProgress, TextField} from "@material-ui/core";

const INIT_STATE = {
    status: "ready", // "ready" | "wait"
    dataValidation: {
        code: ""
    }
};

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        calcNextWStep: PropTypes.func.isRequired,
        onSetState: PropTypes.func.isRequired,
        accountsVerifyConfirmationCode: PropTypes.func.isRequired,
    };

    private _isMounted: boolean;

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
        this._isMounted = false;
    }

    public componentDidMount() {
        this._isMounted = true;
    }

    public componentDidUpdate() {
        if (this.state.status === "ready" && this.isDataValid() && this.isCodeFormatValid()) {
            this.submitCode();
        }
    }

    public render() {
        return <Card style={{width: "480px", marginTop: "48px", marginRight: "auto", marginLeft: "auto", paddingBottom: "16px", borderRadius: "8px"}}>
            <CardHeader
                title={<div>
                    <h2 style={{margin: "32px 0 16px 0", textAlign: "center"}}>
                        Check your email
                    </h2>
                </div>}
                subheader={<p style={{margin: 0, color: this.props.muiTheme.palette.textColor, textAlign: "center", fontSize: "16px"}}>
                    We’ve sent a six-digit confirmation code to&nbsp;
                    <strong>{this.props.state.data.email}</strong>&nbsp;
                    It will expire shortly, so enter your code soon.
                </p>}/>

            <CardContent>
                <div style={{textAlign: "center"}}>
                    {[0, 1, 2, 3, 4, 5].map((i) => {
                        return [
                            <TextField key={`code-digit-${i}`} name={`code-digit-${i}`} type="number" value={this.props.state.data.code[i] || ""} disabled={this.state.status !== "ready"}
                                autoFocus={i === 0} style={{width: "40px", margin: "2px"}} inputProps={{ style: {fontSize: "30px", textAlign: "center"}}}
                                onPaste={event => {
                                    let pastedText = "";
                                    // For IE compatibility
                                    if ((window as any).clipboardData && (window as any).clipboardData.getData) {
                                        pastedText = (window as any).clipboardData.getData("Text");
                                    } else if (event.clipboardData && event.clipboardData.getData) {
                                        pastedText = event.clipboardData.getData("text/plain");
                                    }
                                    const code = pastedText.replace(/[^0-9]/g, "").split("");
                                    this.onCodeChange(code, i);
                                }}
                                onKeyDown={event => {
                                    if (event.keyCode === 37) {
                                        $(`[name=code-digit-${i - 1}]`).focus().select();
                                    } else if (event.keyCode === 39) {
                                        $(`[name=code-digit-${i + 1}]`).focus().select();
                                    } else if (event.keyCode === 8) {
                                        let code = [...this.props.state.data.code];
                                        code[i] = null;
                                        this.onCodeChange(code, i - 1);
                                    } else if (this.isCodeDigitValid(event.key)) {
                                        let code = [...this.props.state.data.code];
                                        code[i] = event.key;
                                        this.onCodeChange(code, i + 1);
                                    }
                                }}
                            />,
                            i === 2 ? <span style={{ lineHeight: "52px" }} key="code-dash"> — </span> : null
                        ]
                    })}
                </div>

                {/* Info message -------------------------------------- */}
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: this.state.status === "wait" || this.state.dataValidation.code ? "16px 0 0 0" : 0,
                    padding: this.state.status === "wait" || this.state.dataValidation.code ? "8px" : 0,
                    transform: this.state.status === "wait" || this.state.dataValidation.code ? "scaleY(1)" : "scaleY(0)",
                    transformOrigin: "top",
                    borderRadius: "2px",
                    background: this.state.status === "wait" ? this.props.muiTheme.palette.disabledColor : this.props.muiTheme.palette.accent1Color,
                    color: "#fff",
                    transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)",
                }}>
                    {this.state.status === "wait"
                        ? [
                            <CircularProgress key="spinner" size={24} style={{display: "block", marginRight: "16px"}}/>,
                            <div key="message">Checking your code...</div>
                        ]
                        : this.state.dataValidation.code
                    }
                </div>
            </CardContent>

            <CardContent style={{padding: "16px", textAlign: "center", fontSize: "16px"}}>
                <small style={{color: this.props.muiTheme.palette.disabledColor}}>
                    Keep this window open while checking for your code.
                    <br/>
                    Haven't received our email? Try your spam folder!
                </small>
            </CardContent>
        </Card>;
    }

    // Event handlers ----------------------------------------------------------
    private onCodeChange = (code, index) => {
        this.props.onSetState(
            {data: {...this.props.state.data, code}},
            (): void => this.setState(
                prevState => ({dataValidation: {...prevState.dataValidation, code: ""}}),
                () => $(`[name=code-digit-${index}]`).focus().select(),
            ),
        );
    };

    // Helpers -----------------------------------------------------------------
    private isCodeDigitValid = digit => {
        return "0123456789".includes("" + digit);
    };

    private isCodeFormatValid = () => {
        return this.props.state.data.code.every(char => !isNaN(char) && char !== null);
    };

    private isDataValid = () => Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true);

    private submitCode = () => {
        if (this._isMounted) {
            this.setState(
                {status: "wait"},
                async () => {
                    let response = null;
                    try {
                        const data = {
                            email: this.props.state.data.email,
                            code: this.props.state.data.code.join("")
                        };
                        response = await this.props.accountsVerifyConfirmationCode(data, {bypassError: true});
                    } catch (reason) {
                        return this.setState({
                            status: "ready",
                            dataValidation: {
                                ...this.state.dataValidation,
                                code: getMessageFromResponse(reason, true),
                            }
                        });
                    }
                    if (response && response.data) {
                        this._isMounted = false;
                        this.setState(
                            {status: "ready"},
                            () => {
                                localStorage.removeItem("iio_confirmationEmail");
                                this.props.onSetState({wstep: this.props.calcNextWStep()});
                            },
                        );
                    }
                },
            );
        }
    };
}
