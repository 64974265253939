import * as React from "react";
import * as PropTypes from "prop-types";
import getAppsCallUrl from "../../../../../../lib/getAppsCallUrl";
import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../lib/getLocationInfo";
import {Button} from "@material-ui/core";

const Component: React.FunctionComponent<any> = props => {
    const content = [
        <Button variant="outlined" key="action-btn-cancel" disabled={props.state.status !== "ready"} style={{marginRight: "8px"}} onClick={props.onCancel}>
            Cancel
        </Button>,
        <Button variant="contained" data-qa="delete-app-confirm-btn" key="action-btn-remove" color="secondary" disabled={props.state.status !== "ready"}
            onClick={() => {
                props.onSetState(
                    {status: "wait"},
                    () => {
                        const {accountId} = getHostnameInfo();
                        const {env} = getLocationInfo(props.location, props.config);
                        const url = getAppsCallUrl(accountId, env) + "/" + props.state.delete.appId;
                        props.appsDelete(props.config, url)
                            .then((res) => {
                                if (res.status < 300) {
                                    props.onClose();
                                }
                            });
                    }
                );
            }}>
            Remove Registration
        </Button>
    ];

    return <div>{content}</div>;
}

Component.propTypes = {
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    appsDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
