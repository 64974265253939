import * as React from "react";
import * as PropTypes from "prop-types";
import {Button} from "@material-ui/core";

const Component: React.FunctionComponent<any> = props => {
    return <div style={{textAlign: "center"}}>
        <Button key="action-btn-close" color="primary" style={{minWidth: "128px"}} onClick={props.onClose}>
            Close
        </Button>
    </div>;
}

Component.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default Component;
