import moment from "moment"
import React from 'react'
import {
    Document,
    Page,
    StyleSheet,
    Text,
    View,
} from '@react-pdf/renderer'
import {
    getDateDisplay,
    getFullDateDisplay,
} from '../../../../../../lib/utils'

type TProps = {
    appUsers: [
        {
            appUserType: string,
            created: Date,
            email: string,
            lastLogin: Date,
            name: string,
            uid: string,
        }
    ],
    directoryType: 'INTEROPIO_USERS' | 'DELEGATED_EHR_USERS',
    ui: {
        timeZone: string,
    },
}

const styles = StyleSheet.create({
    header: {
        fontSize: '14px',
        paddingBottom: '8px',
    },
    notFound: {
        fontSize: '14px',
        paddingBottom: '8px',
        color: '#bdbdbd',
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#369'
    },
    row: {
        fontSize: '11px',
        marginLeft: '10px',
        marginBottom: '2px',
    },
    patient: {
        fontSize: '14px',
        paddingBottom: '8px',
        color: '#b51435',
    },
    provider: {
        fontSize: '14px',
        paddingBottom: '8px',
        color: '#02567d',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        backgroundColor: '#efefef',
    },
})

const UsersPdf:React.FC<TProps> = (props: TProps) => (
    <Document>
        <Page size='A4' style={styles.page}>
            <View style={styles.section}>
                <Text style={styles.header}>
                    {props.directoryType === 'INTEROPIO_USERS' ? 'interopiO users' : 'Delegated EHR users'}
                </Text>
                {(props.appUsers || []).map((appUser, index) => {
                    const isPatient = appUser.appUserType === 'PATIENT'
                    const isProvider = appUser.appUserType === 'PROVIDER'
                    return <div
                        key={`key-${index}`}
                        style={{
                            margin: '6px',
                            padding: '12px',
                            backgroundColor: '#fff',
                            borderRadius: '5px',
                            border: `2px solid ${isPatient ? '#b51435' : isProvider ? '#02567d' : '#bdbdbd'}`
                        }}
                    >
                        <Text style={isPatient ? styles.patient : isProvider ? styles.provider : styles.notFound}>
                            User type: {isPatient ? 'Patient' : isProvider ? 'Provider' : 'Not found'}
                        </Text>
                        <Text style={styles.row}>
                            Name: {appUser.name || '--'}
                        </Text>
                        <Text style={styles.row}>
                            Email: {appUser.email || '--'}
                        </Text>
                        <Text style={styles.row}>
                            Created: {moment(appUser.created || null).isValid()
                                ? getDateDisplay(appUser.created, props.ui.timeZone)
                                : "--"}
                        </Text>
                        <Text style={styles.row}>
                            Last login: {moment(appUser.lastLogin || null).isValid()
                                ? getFullDateDisplay(appUser.lastLogin, props.ui.timeZone)
                                : "--"}
                        </Text>
                        <Text style={styles.row}>
                            UID: {appUser.uid || '--'}
                        </Text>
                    </div>
                })}
            </View>
        </Page>
    </Document>
)

export default UsersPdf
