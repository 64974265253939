declare const APP_NAME;
declare const APP_VERSION;
declare const process;

import * as ReactDOM from "react-dom";
import $ from "jquery";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import consoleLogger from './lib/consoleLogger';

import store from "./redux";

import { Provider } from "react-redux";

import getActiveEnvironment from "./lib/getActiveEnvironment";

import App from "./components/App/";

import Home from "./components/Pages/Home/";

import Legal from "./components/Legal/";

import SignIn from "./components/Pages/SignIn/";
import SSO from "./components/Pages/SignIn/SSO";
import SSOError from "./components/Pages/SignIn/SSO/Error";

import UserAccountListEnterEmail from "./components/Pages/Users/UserAccountList/EnterEmail";
import UserChangeEmailConfirm from "./components/Pages/Users/UserChangeEmail/Confirm";
import UserEdit from "./components/Pages/Users/UserEdit/";
import UserInvitationConfirm from "./components/Pages/Users/UserInvitation/Confirm/";
import UserResetPasswordConfirm from "./components/Pages/Users/UserResetPassword/Confirm";
import UserResetPasswordEnterEmail from "./components/Pages/Users/UserResetPassword/EnterEmail";

import AccAddNewWizard from "./components/Pages/Accounts/AccAddNewWizard/";
import AccRequestAccessWizard from "./components/Pages/Accounts/AccRequestAccessWizard/";

import EnvironmentDashboard from "./components/Pages/Environments/EnvironmentDashboard/";
import EnvironmentEdit from "./components/Pages/Environments/EnvironmentEdit/";

import AppList from "./components/Pages/Apps/AppList/";
import AppEdit from "./components/Pages/Apps/AppEdit";

import CdsServiceSetList from "./components/Pages/CdsServiceSets/CdsServiceSetList";
import CdsServiceSetEdit from "./components/Pages/CdsServiceSets/CdsServiceSetEdit";

import PortalList from "./components/Pages/Portals/PortalList";
import PortalEdit from "./components/Pages/Portals/PortalEdit";

import GatewayList from "./components/Pages/Gateways/GatewayList/";
import GatewayEdit from "./components/Pages/Gateways/GatewayEdit";

import DSList from "./components/Pages/DataSources/DSList/";
import DSEdit from "./components/Pages/DataSources/DSEdit/";

import CDRList from "./components/Pages/CDRs/CDRList/";
import CDREdit from "./components/Pages/CDRs/CDREdit/";

import UserDirList from "./components/Pages/UserDirs/UserDirList";
import UserDirEdit from "./components/Pages/UserDirs/UserDirEdit";

import Monitoring from "./components/Pages/Monitoring";

// import Analytics from "./components/Pages/Analytics/";

const element: any = document.querySelector(`#${APP_NAME}`);
const buildDate: string = element.dataset.buildDate;

$(".init-loader")
    .css("pointer-events", "none")
    .fadeTo(1550, 0, () => setTimeout(() => $(".init-loader").remove(), 5550));

consoleLogger.log("::: ::: :::");
consoleLogger.log("::: process.env.NODE_ENV:", process.env.NODE_ENV);
consoleLogger.log("::: process.env.HOST:", process.env.HOST);
consoleLogger.log("::: Active Environment:", getActiveEnvironment());
consoleLogger.log("::: APP_NAME:", APP_NAME);
consoleLogger.log("::: APP_VERSION:", APP_VERSION);
consoleLogger.log("::: Build Date:", buildDate);
consoleLogger.log("::: ::: :::");

ReactDOM.render(
    <BrowserRouter basename="/console">
        <Provider store={store}>
            <App>
                <Switch>
                    <Route exact path="/home" component={Home} />

                    <Route exact path="/legal" component={Legal} />
                    <Route exact path="/legal/archives" component={Legal} />
                    <Route path="/terms/customer-terms-of-service" component={Legal} />
                    <Route path="/terms/user-terms-of-service" component={Legal} />
                    <Route path="/terms/customer-specific-supplement" component={Legal} />
                    <Route path="/terms/service-level-agreement" component={Legal} />
                    <Redirect exact from="/policies" to={"/policies/privacy-notice"} />
                    <Redirect exact from="/policies/privacy-policy" to={"/policies/privacy-notice"} />
                    <Route path="/policies" component={Legal} />
                    <Route path="/policies/privacy-notice" component={Legal} />
                    <Route path="/policies/acceptable-use-policy" component={Legal} />
                    <Route path="/policies/data-request-policy" component={Legal} />
                    <Route path="/policies/cookie-policy" component={Legal} />
                    <Redirect exact from="/customer-terms-of-service" to={"/terms/customer-terms-of-service"} />
                    <Redirect exact from="/user-terms-of-service" to={"/terms/user-terms-of-service"} />
                    <Redirect exact from="/customer-specific-supplement" to={"/terms/customer-specific-supplement"} />
                    <Redirect exact from="/service-level-agreement" to={"/terms/service-level-agreement"} />
                    <Redirect exact from="/privacy-policy" to={"/policies/privacy-notice"} />
                    <Redirect exact from="/privacy-notice" to={"/policies/privacy-notice"} />
                    <Redirect exact from="/acceptable-use-policy" to={"/policies/acceptable-use-policy"} />
                    <Redirect exact from="/data-request-policy" to={"/policies/data-request-policy"} />
                    <Redirect exact from="/cookie-policy" to={"/policies/cookie-policy"} />

                    <Route exact path="/signin" component={SignIn} />
                    <Route exact path="/sso" component={SSO} />
                    <Route exact path="/sso/error" component={SSOError} />

                    <Route exact path="/users/current" component={UserEdit} />
                    <Route exact path="/users/account-list/enter-email" component={UserAccountListEnterEmail} />
                    <Route exact path="/users/change-email/confirm" component={UserChangeEmailConfirm} />
                    <Route exact path="/users/invitation/confirm" component={UserInvitationConfirm} />
                    <Route exact path="/users/reset-password/enter-email" component={UserResetPasswordEnterEmail} />
                    <Route exact path="/users/reset-password/confirm" component={UserResetPasswordConfirm} />

                    <Route exact path="/accounts/new" component={AccAddNewWizard} />
                    <Route exact path="/accounts/request" component={AccRequestAccessWizard} />

                    <Redirect exact from="/environments" to={"/home"} />
                    <Route exact path="/environments/:environmentId" component={EnvironmentEdit} />

                    <Route exact path="/:envId" component={EnvironmentDashboard} />

                    <Route exact path="/:envId/apps" component={AppList} />
                    <Route exact path="/:envId/apps/:appId" component={AppEdit} />

                    <Route exact path="/:envId/cds-service-sets" component={CdsServiceSetList} />
                    <Route exact path="/:envId/cds-service-sets/:cdsServiceSetId" component={CdsServiceSetEdit} />

                    <Route exact path="/:envId/portals" component={PortalList} />
                    <Route exact path="/:envId/portals/:portalId" component={PortalEdit} />

                    <Route exact path="/:envId/gateways" component={GatewayList} />
                    <Route exact path="/:envId/gateways/:gatewayId" component={GatewayEdit} />

                    <Route exact path="/:envId/datasources" component={DSList} />
                    <Route exact path="/:envId/datasources/:dataSourceId" component={DSEdit} />

                    <Route exact path="/:envId/cdrs" component={CDRList} />
                    <Route exact path="/:envId/cdrs/:cdrId" component={CDREdit} />

                    <Route exact path="/:envId/auth" component={UserDirList} />
                    <Route exact path="/:envId/auth/:directoryId" component={UserDirEdit} />

                    <Route exact path="/:envId/monitoring" component={Monitoring} />

                    {/* <Route exact path="/:envId/analytics" component={Analytics} /> */}

                    <Redirect exact from="/" to={"/home"} />
                </Switch>
            </App>
        </Provider>
    </BrowserRouter>,
    document.getElementById(APP_NAME),
);
