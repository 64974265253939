import { useState, useEffect } from "react";
import * as React from 'react'
import * as PropTypes from "prop-types";
import "./style.less";
import axiosWrapper from "../../../lib/axiosWrapper";

const CardImage: React.FC<any> = (props: any) => {
    const [src, setSrc] = useState("")

    useEffect(() => {
        if (props.src && props.src.includes('/environment-api/')) {
            const getLogoSrc = async () => {
                const logoUrl = props.src.split('/environment-api/')[1]
                const res = await axiosWrapper(
                    props.config.envApi,
                    logoUrl,
                    undefined, undefined, undefined,'arraybuffer'
                )
                if (res.status === 200) {
                    const blob = new Blob([res.data], { type: res.headers['content-type'] })
                    const url = URL.createObjectURL(blob)
                    return url
                } else {
                    return props.src // fallback to original url
                }
            }
            getLogoSrc().then((url) => {
                setSrc(url)
            })
        } else {
            setSrc(props.src)
        }
    }, [props.src,
        props.config.envApi])

    return <div className={`card-image ${props.className}`} style={props.style} onClick={props.onClick}>
        <img src={src} style={props.imgStyle}/>
    </div>;
}

CardImage.propTypes = {
    className: PropTypes.string,
    imgStyle: PropTypes.object,
    src: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    config: PropTypes.object
};

CardImage.defaultProps = {
    className: "",
    imgStyle: {},
    src: "",
    style: {},
    onClick: () => {},
    config: {}
};

export default CardImage;
