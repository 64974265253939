import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../../redux/actions";
import getCdsServiceSetsCallUrl from "../../../../../../../lib/getCdsServiceSetsCallUrl";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

const CustomDialogActions  = (props) => {
    return <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
        <Button
            color="primary" style={{ marginRight: 8 }}
            disabled={props.dstate.status !== "ready"}
            onClick={props.onClose}
        >
            Cancel
        </Button>
        <Button
            variant="contained" color="secondary" style={{ marginRight: 8 }}
            disabled={props.dstate.status !== "ready"}
            onClick={async () => {
                props.onSetDState({ status: "waiting" });
                const accountId = props.dstate.data.accountId;
                const environmentId = props.dstate.data.environmentId;
                const cdsServiceSetId = props.dstate.data.cdsServiceSetId;
                const activations = (props.dstate?.data?.activations || []).filter((activation) => {
                    return !(activation.cdsServiceSetId === props.parentState.selectedActivation.cdsServiceSetId
                        && activation.gatewayId === props.parentState.selectedActivation.gatewayId);
                });
                const data = {
                    ...props.dstate.data,
                    activations,
                };
                const cdsServiceSetUrl = getCdsServiceSetsCallUrl(accountId, environmentId) + `/${cdsServiceSetId}`;
                const response = await props.cdsServiceSetsUpdate(props.config, cdsServiceSetUrl, data);
                if (response && response.status < 300) {
                    props.initialize();
                }
            }}
        >
            Delete CDS Service Set
        </Button>
    </DialogActions>;
}

CustomDialogActions.propTypes = {
    config: PropTypes.object.isRequired,
    dstate: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,

    cdsServiceSetsUpdate: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSetDState: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsUpdate: actions.cdsServiceSetsUpdate,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CustomDialogActions);
