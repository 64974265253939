import React from 'react'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Table from '../../../../Widgets/Table'
import PortalAppItem from './PortalAppItem'
import getAppsCallUrl from '../../../../../lib/getAppsCallUrl'
import getHostnameInfo from '../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../lib/getLocationInfo'

type TStatus = '' | 'init' | 'ready' | 'error'

const Apps = (props: any) => {
    const [status, setStatus] = React.useState<TStatus>('init')
    React.useEffect(() => {
        const { accountId } = getHostnameInfo()
        const { env } = getLocationInfo(props.location, props.config)
        const onSetParentState = props.onSetState
        const appsReadAll = props.appsReadAll

        if(status === 'init') {
            const appUrl = getAppsCallUrl(accountId, env);
            Promise.all([
                appsReadAll(props.config, appUrl),
            ])
                .then(res => {
                    const data = res[0] && res[0].data ? res[0].data : []
                    const filteredApps = data.filter((app) => app.activations.find((act) => act.gatewayId === props.state.gateway.gatewayId))
                    onSetParentState({ appsPerGateway: filteredApps })
                })
                .catch(() => setStatus('error'))
            setStatus('ready')
        }
    }, [
        props.config,
        props.location,
        props.state.gateway.gatewayId,
        status,
        props.appsReadAll,
        props.onSetState,
        setStatus,
    ])
    const columns = [
        {
            size: 2,
            name: 'name',
            label: 'Display Name',
        },
        {
            size: 1.5,
            name: 'activation',
            label: 'Activation',
        },
        {
            size: 1,
            name: 'context',
            label: 'User Context',
        },
        {
            size: 1.75,
            name: 'intent',
            label: 'Intent',
        },
        {
            size: 2,
            name: 'customContext',
            label: 'Custom Context',
        }
    ]

    const rightFilters = [
        {
            type: 'component',
            value: (
                <Button
                    data-qa="add-portal-launch"
                    key={1}
                    disabled={!props.state.appsPerGateway.length}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        props.onSetState({ dialog: ':ADD_NEW_APP_LAUNCH:' })
                    }}
                >
                    Add app launch
                </Button>
            )
        }
    ]

    const list = props.portals.selected.data.portalType === 'PROVIDER'
        ? props.portals.selected.data.providerPortalContextApiList
        : props.portals.selected.data.patientPortalContextApiList || []

    return (
        <Table
            columns={columns}
            rightFilters={rightFilters}
        >
            <List style={{ padding: 0 }}>
                {list.map((activation, idx) => {
                    return (
                        <PortalAppItem
                            key={`${activation.displayName}-${idx}`}
                            state={props.state}
                            data={activation}
                            onSetState={props.onSetState}
                        />
                    )
                })}
            </List>
        </Table>
    )
}

export default Apps
