import * as React from "react";
import * as PropTypes from "prop-types";
import getGatewaysResourceUrl from "../../../../../lib/getGatewaysResourceUrl";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../lib/getLocationInfo";
import {isEnvironmentAdmin} from "../../../../../lib/user-environment-permissions-helpers";
import {getPath} from "../../../../../lib/utils";
import * as validateData from "../../../../../lib/validate-gateways-data";
import ReactJson from "react-json-view";
import {TextField, IconButton, Card, Tooltip} from "@material-ui/core";
import Header from "../../../../Widgets/Editor/Header/";
import ActionButtons from "./ActionButtons";
import {Link, Edit} from "@material-ui/icons";

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.validateData();
    }

    public componentDidUpdate(prevProps) {
        if (this.props.state.jwkSetString !== prevProps.state.jwkSetString) {
            this.validateData();
        }
    }

    public render() {
        const {accountId} = getHostnameInfo();
        const {env, gatewayId} = getLocationInfo(this.props.location, this.props.config);

        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);

        const canEdit = this.props.state.mode === ":EDIT:";
        const isValid = Object.keys(this.props.state.dataValidation).reduce((acc, key) => acc && !this.props.state.dataValidation[key], true);

        let jwkSetString = getPath(this.props, "state.jwkSetString") || "";
        let jwkSetObject;
        try {
            jwkSetObject = JSON.parse(jwkSetString);
        } catch (reason) {
            jwkSetObject = {};
        }
        if (!jwkSetObject) {
            jwkSetObject = {};
        }

        return <Card style={{marginTop: "48px"}}>
            <Header {...this.props} leftIcon={<Link style={{paddingTop: "4px", marginRight: "24px"}}/>} style={{height: "56px"}}
                primaryText={<div style={{paddingTop: "4px", opacity: .5}}>
                    {this.props.state.data.gatewayType === "HL7_V2"
                        ? "hl7://interopiondemo.smilecdr.com:7000"
                        : getGatewaysResourceUrl(accountId, env, gatewayId, this.props.config.proxyApi)}
                </div>}
                rightIcon={!canEdit && actAsAdmin
                    ? <Tooltip title="Edit">
                        <IconButton style={{paddingTop: "16px"}} onClick={() => this.props.onSetState({mode: ":EDIT:"})}>
                            {/*hoverColor={this.props.muiTheme.palette.textColor}*/}
                            <Edit style={{color: this.props.muiTheme.palette.secondaryTextColor}}/>
                        </IconButton>
                    </Tooltip>
                    : null}/>

            <div style={{padding: "24px 24px 0 24px"}}>
                {canEdit
                    ? <TextField name="jwk-set" value={jwkSetString} label="JWK Set" error={!!this.props.state.dataValidation.jwkSet}
                        helperText={this.props.state.dataValidation.jwkSet} fullWidth multiline onChange={e => this.props.onSetState({jwkSetString: e.target.value})}/>
                    : null}
                <ReactJson src={jwkSetObject} name="JWK Set Preview" indentWidth={4} collapsed={false} enableClipboard displayObjectSize displayDataTypes sortKeys
                    style={{paddingTop: "24px", overflowX: "auto"}}/>
            </div>
            <ActionButtons {...this.props} isValid={isValid}/>
        </Card>;
    }

    private validateData = () => {
        const dataValidation = validateData.onEditCdsServiceAuth(this.props.state);
        this.props.onSetState({dataValidation});
    }
}
