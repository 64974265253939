import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { createMachine, assign } from 'xstate'
import { useMachine } from '@xstate/react'
import axiosWrapper from '../../../../../lib/axiosWrapper'
import getGatewaysCallUrl from '../../../../../lib/getGatewaysCallUrl'
import getHostnameInfo from '../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../lib/getLocationInfo'
import * as actions from '../../../../../redux/actions'
import {
    INIT_STATE as initParentState,
    TABS,
} from '..'

type TProps = {
    config: any
    gateways: any
    location: any
    parentState: {
        status: string
    }
    gatewaysRead: Function
    gatewaysReadAll: Function
    uiTabsSetItems: Function
    onSetParentState: Function
}

const InitMachine = (props: TProps) => {
    const [state, send] = useMachine(() => createMachine({
        id: 'init',
        initial: 'start',
        context: {
            jwkSetString: '',
            userDirsSlim: [],
        },
        states: {
            start: {
                on: {
                    SEND_REQUESTS: 'sending_requests',
                },
            },
            sending_requests: {
                invoke: {
                    src: 'sendRequests',
                    onDone: {
                        target: 'processing_data',
                        actions: assign({
                            jwkSetString: (_, event) => {
                                const jwkSetObject =  event.data?.[0]?.data?.jwkSet ?? initParentState.jwkSetString
                                let jwkSetString = ''
                                if (jwkSetObject && typeof jwkSetObject === 'object') {
                                    try {
                                        jwkSetString = JSON.stringify(jwkSetObject)
                                    }
                                    catch (reason) {
                                        jwkSetString = String(jwkSetObject)
                                    }
                                }
                                return jwkSetString
                            },
                            userDirsSlim: (_, event) => event.data?.[1]?.data || initParentState.userDirsSlim,
                        }),
                    },
                    onError: {
                        target: 'wait_redux',
                    }
                },
            },
            wait_redux: {
                after: {
                    100: { target: 'processing_data' }
                },
            },
            processing_data: {
                on: {
                    DONE: 'idle',
                },
            },
            idle: {
                on: {
                    RESTART: 'start',
                },
            },
        },
    }), {
        services: {
            sendRequests: () => {
                const { accountId } = getHostnameInfo()
                const { env, gatewayId } = getLocationInfo(props.location, props.config)
                const gwUrl = `${getGatewaysCallUrl(accountId, env)}/${gatewayId}`

                const gatewaysSelectedStatus = props.gateways.selected.status
                if (![/* 'ready', */ 'pending'].includes(gatewaysSelectedStatus)) {
                    props.gatewaysRead(props.config, gwUrl)
                }

                const gatewaysAllStatus = props.gateways.all.status
                if (![/* 'ready', */ 'pending'].includes(gatewaysAllStatus)) {
                    const gwsUrl = getGatewaysCallUrl(accountId, env)
                    props.gatewaysReadAll(props.config, gwsUrl)
                }

                const udsSlimUrl = `api/${accountId}/env/${env}/user-directory-slim`

                return Promise.all([
                    axiosWrapper(props.config.envApi, `${gwUrl}/auth-cds`, 'GET'),
                    axiosWrapper(props.config.envApi, udsSlimUrl, 'GET'),
                ])
            }
        }
    })

    const {
        uiTabsSetItems,
        onSetParentState,
    } = props

    // start
    React.useEffect(() => {
        if (state.matches('start')) {
            if (props.parentState.status === 'init') {
                send('SEND_REQUESTS')
            }
        }
    }, [props.parentState.status, send, state])

    // processing_data
    React.useEffect(() => {
        if (state.matches('processing_data')) {
            const gatewaysSelectedStatus = props.gateways.selected.status
            // const gatewaysAllStatus = props.gateways.all.status
            if (
                gatewaysSelectedStatus === 'ready'
                // && gatewaysAllStatus === 'ready'
            ) {
                uiTabsSetItems(TABS)

                const jwkSetString = state.context.jwkSetString
                const userDirsSlim = state.context.userDirsSlim
                onSetParentState({
                    ...initParentState,
                    status: 'ready',
                    data: props.gateways.selected.data,
                    jwkSetString,
                    userDirsSlim,
                }, () => {
                    send('DONE')
                })
            }
        }
    }, [
        onSetParentState,
        // props.gateways.all.status,
        props.gateways.selected.data,
        props.gateways.selected.status,
        send,
        state,
        uiTabsSetItems,
    ])

    // idle
    React.useEffect(() => {
        if (state.matches('idle')) {
            if (props.parentState.status === 'init') {
                send('RESTART')
            }
        }
    }, [props.parentState.status, send, state])

    // console.log('::: current state:', state)

    return null
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    gateways: state.gateways,
    ...ownProps,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    gatewaysRead: actions.gatewaysRead,
    gatewaysReadAll: actions.gatewaysReadAll,
    uiTabsSetItems: actions.uiTabsSetItems,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InitMachine))
