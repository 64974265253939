import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

const CustomDialogActions  = (props) => {
    return <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
        <Button
            disabled={props.parentState.status !== "ready"}
            style={{ marginRight: 8 }}
            onClick={props.onClose}
        >
            Ok
        </Button>
    </DialogActions>;
}

CustomDialogActions.propTypes = {
    parentState: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CustomDialogActions;
