import * as React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import { capitalizeFirstLetter } from '../../../../../../../../lib/utils'
import EditIcon from '@material-ui/icons/Edit'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import ChipDropDown from '../../../../../../../Widgets/ChipDropDown/'
import './style.less'

type TProps = {
    muiTheme: any
    state: any
    onSetState: Function
}

const HeaderContentL1: React.FC<TProps> = (props: TProps) => {
    const onFilterOperationsSetAll = (set = true) => () => {
        const operations = { ...props.state.filters.operations }
        Object.keys(operations).forEach((key) => {
            operations[key] = set;
        })
        props.onSetState({
            filters: {
                ...props.state.filters,
                operations
            }
        })
    }

    const areFilterOperationsSelectedAll = Object.keys(props.state.filters.operations)
        .reduce((acc, key) => ((acc || acc === null) && props.state.filters.operations[key]), null)

    const filterOperationsSelected = Object.keys(props.state.filters.operations)
        .filter((key) => props.state.filters.operations[key])

    const isFilterResourcesActive = props.state.filters.resources !== ':ALL:'
    const isFilterOperationsActive = !!filterOperationsSelected.length
    const isAnyFilterActive = isFilterResourcesActive || isFilterOperationsActive

    const selectedResourcesCount = Object.keys(props.state.selectedResources)
        .reduce((acc, key) => {
            if (props.state.selectedResources[key]) return ++acc
            else return acc
        }, 0)

    let labelResource = ''
    switch (props.state.filters.resources) {
        case ':ALL:':
            labelResource = 'All resources'
            break
        case ':SUPPORTED:':
            labelResource = 'Supported resources'
            break
        case ':UNSUPPORTED:':
            labelResource = 'Unsupported resources'
            break
        case ':OVERRIDDEN:':
            labelResource = 'Overridden resources'
            break
        case ':USCDI:':
            labelResource = 'USCDI resources'
            break
        case ':NON-USCDI:':
            labelResource = 'non-USCDI resources'
            break
    }

    let labelOperations = 'Operations'
    if (filterOperationsSelected.length) {
        labelOperations = capitalizeFirstLetter(filterOperationsSelected[0])
        if (filterOperationsSelected.length > 1) {
            labelOperations += ` + ${filterOperationsSelected.length - 1}`
        }
    }

    const iconColor = { color: props.muiTheme.palette.primary1Color }

    return (
        <div className="gw-mapping-rl-h1-content">
            <div className="content-left-side">
                {props.state.resources.length}
                {isAnyFilterActive ? ' filtered results' : ' resources'}
                {selectedResourcesCount ? `, ${selectedResourcesCount} selected` : null}
                {selectedResourcesCount ? (
                    <Tooltip title="Edit" className="icon-button" style={{ marginLeft: '8px' }}>
                        <IconButton onClick={() => props.onSetState({dialog: ':EDIT:', sourse: "", selectedSources: {}})}>
                            <EditIcon style={{ color: props.muiTheme.palette.secondaryTextColor }} />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </div>

            <div className="content-right-side">
                <ChipDropDown
                    label={labelResource}
                    isActive={isFilterResourcesActive}
                    activeBackground={props.muiTheme.palette.primary1Color}
                    activeColor="#fff"
                    deleteIconStyle={{ fill: 'rgba(255, 255, 255, 0.67)' }}
                    inactiveBackground="#fff"
                    inactiveColor={props.muiTheme.palette.textColor}
                    style={{ marginTop: '-9px', marginRight: '16px' }}
                    onRequestDelete={() => props.onSetState({
                        filters: {
                            ...props.state.filters,
                            resources: ':ALL:',
                        },
                    })}
                    popover={{
                        title: "Resources",
                        content: (
                            <div style={{ margin: '8px 16px', display: 'flex', flexDirection: 'column' }}>
                                {[
                                    {
                                        label: 'All resources',
                                        resources: ':ALL:',
                                    },
                                    {
                                        label: 'Supported resources',
                                        resources: ':SUPPORTED:',
                                    },
                                    {
                                        label: 'Unsupported resources',
                                        resources: ':UNSUPPORTED:',
                                    },
                                    {
                                        label: 'Overridden resources',
                                        resources: ':OVERRIDDEN:',
                                    },
                                    ['FHIR_STU3__3_0_2', 'FHIR_R4__4_0_1'].includes(props.state.data.gatewayType) ? {
                                        label: 'USCDI resources',
                                        resources: ':USCDI:',
                                    } : null,
                                    ['FHIR_STU3__3_0_2', 'FHIR_R4__4_0_1'].includes(props.state.data.gatewayType) ? {
                                        label: 'non-USCDI resources',
                                        resources: ':NON-USCDI:',
                                    } : null,
                                ].map((it) => it ? (
                                    <FormControlLabel
                                        key={it.resources}
                                        label={it.label}
                                        control={(
                                            <Checkbox
                                                checked={props.state.filters.resources === it.resources}
                                                icon={<RadioButtonUncheckedIcon style={iconColor} />}
                                                checkedIcon={<RadioButtonCheckedIcon style={iconColor} />}
                                                style={{ padding: 3 }}
                                                onChange={() => props.onSetState({
                                                    filters: {
                                                        ...props.state.filters,
                                                        resources: it.resources,
                                                    },
                                                })}
                                            />
                                        )}
                                    />
                                ) : null)}
                            </div>
                        )
                    }}
                />

                <ChipDropDown
                    label={labelOperations}
                    isActive={isFilterOperationsActive}
                    deleteIconStyle={{fill: "rgba(255, 255, 255, 0.67)"}}
                    activeBackground={props.muiTheme.palette.primary1Color}
                    activeColor="#fff"
                    inactiveBackground="#fff"
                    inactiveColor={props.muiTheme.palette.textColor}
                    style={{ marginTop: '-9px' }}
                    onRequestDelete={onFilterOperationsSetAll(false)}
                    popover={{
                        title: "Operations",
                        content: (
                            <div style={{ margin: '8px 16px' }}>
                                <FormControlLabel
                                    label="Select All Operations"
                                    control={(
                                        <Switch
                                            checked={areFilterOperationsSelectedAll}
                                            style={{ marginBottom: '8px' }}
                                            onChange={onFilterOperationsSetAll(!areFilterOperationsSelectedAll)}
                                        />
                                    )}
                                />
                                <Divider style={{ margin: '8px 0px' }} />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {Object.keys(props.state.filters.operations).map((key, index) => (
                                        <FormControlLabel
                                            key={`operation-${key}-${index}`}
                                            label={capitalizeFirstLetter(key)}
                                            control={(
                                                <Checkbox
                                                    name={`operation-${key}-${index}`}
                                                    checked={props.state.filters.operations[key]}
                                                    color="primary"
                                                    style={{ padding: '2px' }}
                                                    onChange={() => props.onSetState({
                                                        filters: {
                                                            ...props.state.filters,
                                                            operations: {
                                                                ...props.state.filters.operations,
                                                                [key]: !props.state.filters.operations[key]
                                                            }
                                                        }
                                                    })}
                                                />
                                            )}
                                        />
                                    ))}
                                </div>
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    )
}

export default HeaderContentL1
