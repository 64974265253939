import axiosWrapper from "../../lib/axiosWrapper";
import { getPath } from "../../lib/utils";
import initialState from "../../redux/reducers/user-dirs/initial-state";

import * as types from "./types";

export function userDirsRead(
    config: any,
    url: string,
) {
    return function (dispatch: Function): Promise<any> {
        dispatch({
            type: types.USER_DIRS_SELECTED_SET,
            payload: {
                status: "pending",
                data: initialState.selected.data,
            },
        });
        return axiosWrapper(config.envApi, url, "get")
            .then((res: any) => {
                dispatch({
                    type: types.USER_DIRS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((): void => {
                dispatch({
                    type: types.USER_DIRS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: initialState.selected.data,
                    },
                });
                // console.error(":::", reason);
            });
    };
}

export function userDirsReadAll(
    config: any,
    url: string,
) {
    return function (dispatch: Function): Promise<any> {
        dispatch({
            type: types.USER_DIRS_ALL_SET,
            payload: {
                status: "pending",
                data: initialState.all.data,
            },
        });
        return axiosWrapper(config.envApi, url, "get")
            .then((res: any) => {
                dispatch({
                    type: types.USER_DIRS_ALL_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((): void => {
                dispatch({
                    type: types.USER_DIRS_ALL_SET,
                    payload: {
                        status: "error",
                        data: initialState.all.data,
                    },
                });
                // console.error(":::", reason);
            });
    };
}

export function userDirsUpdate(
    config: any,
    url: string,
    data = {},
) {
    return function (dispatch: Function): Promise<any> {
        dispatch({
            type: types.USER_DIRS_SELECTED_SET,
            payload: {
                status: "pending",
                data: initialState.selected.data,
            },
        });
        return axiosWrapper(config.envApi, url, "put", data)
            .then((res: any) => {
                dispatch({
                    type: types.USER_DIRS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((): void => {
                dispatch({
                    type: types.USER_DIRS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: initialState.selected.data,
                    },
                });
                // console.error(":::", reason);
            })
    };
}
