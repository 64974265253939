import * as React from "react";
import * as PropTypes from "prop-types";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import CenteredCircularProgress from "../../../../../Widgets/CenteredCircularProgress";
import Actions from "./Actions";
import Content from "./Content";
import Title from "./Title";

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        gateways: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        gatewaysReadAll: PropTypes.func.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.initialize();
    }

    public render() {
        return <Dialog open style={{paddingTop: "5px", zIndex: 1250}} maxWidth="lg" classes={{paper: "io-modal-wrapper"}}>
            <DialogTitle style={{height: "48px", background: this.props.muiTheme.palette.primary1Color, color: "#fff", lineHeight: "48px", fontSize: "24px", fontWeight: 600}}>
                <Title {...this.props} />
            </DialogTitle>
            <DialogContent style={{width: "100%", background: "rgb(250, 250, 250)", padding: "0 0 24px 0", boxSizing: "border-box"}}>
                {this.props.state.status !== "ready"
                    ? <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
                    : <Content {...this.props}/>}
            </DialogContent>
            <DialogActions style={{padding: "0 24px 24px", background: "rgb(250, 250, 250)"}}>
                <Actions {...this.props}/>
            </DialogActions>
        </Dialog>
    }

    private initialize = () => {
        this.props.onSetState(
            {status: "ready"}
        );
    }
}
