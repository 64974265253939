import * as PropTypes from "prop-types";
import ETab1 from "./ETab1";

const ETabs = (props) => {
    const propSet = {
        estate: props.estate,
        onSetEState: props.onSetEState,
    };
    switch (props.estate.etab) {
        case 1: return <ETab1 {...propSet} />;
        default: return null;
    }
}

ETabs.propTypes = {
    estate: PropTypes.object.isRequired,
    onSetEState: PropTypes.func.isRequired,
};

export default ETabs;
