import axios from "axios";

import getHostnameInfo from "./getHostnameInfo";
import getMessageFromResponse from './getMessageFromResponse'
// import { isLoggedIn } from "./users-helpers";

import store from "../redux/"
import { generalMessageSet } from "../redux/actions/general-message";
import { loginReset } from "../redux/actions/login";

import { getPath, delay } from "./utils/"

async function errorInterceptor(error = {} as any): Promise<any> {
    if (!error.response.config.errorHandling) {
        return Promise.reject(error);
    }
    const response = error.response || {};
    const responseStatus: number = +response.status;
    const responseMessage = getMessageFromResponse(error, false)

    const state = store.getState();
    // const isLogged: boolean = isLoggedIn(state.login);

    if (responseStatus === 401) {
        store.dispatch(loginReset());
    }
    else if (responseStatus === 403) {
        store.dispatch(loginReset());
        if (!state.generalMessage.type) {
            store.dispatch(generalMessageSet({
                type: ":INFO_SNACKBAR:",
                // title: `${responseStatus} ${responseMessage}`,
                title: "Your interopiO session has expired",
                message: "Your interopiO session has expired due to inactivity. Please re-login to renew your session.",
            }));
        }
    }
    else if (responseStatus >= 400 && responseStatus < 500) {
        await delay(550) // This additional delay improves the transition animation
        if (!state.generalMessage.type.startsWith(':CRITICAL')) {
            store.dispatch(generalMessageSet({
                type: ':CRITICAL_SNACKBAR:',
                title: 'Something went wrong',
                message: `Oops, we got an error on the backend. Error: ${responseStatus} ${responseMessage}`,
            }))
        }
    }
    else if (responseStatus >= 500) {
        let errMsg = '';
        if (typeof responseMessage === 'string' && responseMessage.includes('smart launch proxy scopes')) {
            errMsg = 'You may not remove a scope that has been included in an activation.';
        }
        else {
            errMsg = 'Oops, we got an error on the backend. Please try again later or contact support.';
        }
        await delay(550) // This additional delay improves the transition animation
        if (!state.generalMessage.type.startsWith(':CRITICAL')) {
            store.dispatch(generalMessageSet({
                type: ':CRITICAL_SNACKBAR:',
                title: 'Something went wrong',
                message: errMsg,
            }))
        }
    }

    return Promise.reject(error);
}

function responseInterceptor(response: any) {
    return response;
}

// Add a response interceptor
axios.interceptors.response.use(
    // Do something with response data
    responseInterceptor,
    // Do something with response error
    errorInterceptor,
);

export default async function (
    server: any,
    dataPath: string,
    method: string = "get",
    data = null,
    cfg = {} as any,
    responseType: string = "json",
    errorHandling: boolean = true
): Promise<any> {
    // Authorization token
    const token: string = sessionStorage.getItem("token");

    const config: any = {}

    // Server URL
    const {
        protocol,
        host,
        port,
        path,
    } = server;
    const { accountId } = getHostnameInfo();
    config.url = "";
    config.url += protocol ? `${protocol}://` : "";
    config.url += accountId ? `${accountId}.` : "";
    config.url += host ? host : "";
    config.url += port ? `:${port}` : "";
    config.url += path ? `/${path}` : "";
    config.url += dataPath ? `/${dataPath}` : "";

    // Request method
    config.method = method.toLowerCase();

    // Custom headers
    config.headers = {};
    if (process.env.NODE_ENV !== "production") {
        config.headers["X-library"] = "axios";
    }
    if (token) {
        config.headers["Authorization"] = token;
    }

    // Data to be sent as the request body
    // Only applicable for request methods 'PUT', 'POST', and 'PATCH'
    config.data = data;

    // Indicates whether or not cross-site Access-Control requests
    // should be made using credentials
    config.withCredentials = cfg.withCredentials !== undefined ? cfg.withCredentials : true;

    // Indicates the type of data that the server will respond with
    // Options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
    config.responseType = responseType;

    // Defines whether to resolve or reject the promise for a given HTTP response status code.
    // If `validateStatus` returns `true` (or is set to `null` or `undefined`),
    // the promise will be resolved;
    // otherwise, the promise will be rejected.
    config.validateStatus = (status) => status >= 200 && status < 300; // default

    // Added the option to skip errorHandling when the promise get rejected
    config.errorHandling = errorHandling

    const response = await axios(config);

    const currToken: string = sessionStorage.getItem(token);
    const authorization: string = getPath(response, "headers.authorization");
    if (authorization && currToken !== authorization) {
        sessionStorage.setItem("token", authorization);
    }

    return response;
}
