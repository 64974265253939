import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {delay} from "../../../../../lib/utils";
import axiosWrapper from "../../../../../lib/axiosWrapper";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import * as actions from "../../../../../redux/actions";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress";
import SubscriptionsTable from "./SubscriptionsTable";
import "./style.less";

let moment = require("moment-timezone");

export class Subscriptions extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {
            status: "init",
            allActivatedApps: [],
            allSubscriptions: {
                data: null,
                refreshing: false
            },
            subscriptions: {
                data: null,
                refreshing: false
            },
            filters: {
                operations: {
                    read: false,
                    vread: false,
                    search: false,
                    create: false,
                    update: false,
                    patch: false,
                    delete: false,
                    history: false,
                    capabilities: false,
                    batch_tx: false,
                    extended: false
                }
            },
            timeframe: 0,
            sortBy: {
                end: true
            },
            showAll: false,
            // startDate will be saved in local time, startDateInMs will be saved in timeZone at 00:00;
            startDate: null,
            startDateInMs: null,
            endDate: null,
            endDateInMs: null
        };
    }

    public componentDidMount() {
        this.setState({
            allActivatedApps: this.props.gateways.selected.data.activatedApps,
            status: "recalc",
            endDate: new Date(),
            endDateInMs: new Date().getTime(),
            startDate: this.getStartDate(new Date()),
            startDateInMs: this.getStartDate(new Date()).getTime()
        }, () => {
            this.subscriptions_init();
        });
    }

    public componentDidUpdate(_, prevState) {
        const filters_string = JSON.stringify(this.state.filters);
        const prevFilters_string = JSON.stringify(prevState.filters);
        const sortBy_string = JSON.stringify(this.state.sortBy);
        const prevSortBy_string = JSON.stringify(prevState.sortBy);

        if (this.state.timeframe !== prevState.timeframe) {
            if (this.state.timeframe >= 0) {
                this.setState(
                    {
                        status: "recalc",
                        endDate: new Date(),
                        endDateInMs: new Date().getTime(),
                        startDate: this.getStartDate(new Date()),
                        startDateInMs: this.getStartDate(new Date()).getTime()
                    },
                    () => {
                        this.subscriptions_init();
                    })
            }
        }

        if (this.state.timeframe === -1 && (prevState.endDate.getTime() !== this.state.endDate.getTime() || prevState.startDate.getTime() !== this.state.startDate.getTime())) {
            this.setState(
                {
                    status: "recalc",
                    endDateInMs: this.getDateInMs(this.state.endDate),
                    startDateInMs: this.getDateInMs(this.state.startDate)
                },
                () => {
                    this.subscriptions_init();
                })
        }

        if ((filters_string !== prevFilters_string) || (sortBy_string !== prevSortBy_string)) {
            this.setState(
                {status: "recalc"},
                () => delay(250).then(() => this.setState(
                    {
                        subscriptions: {
                            data: this.processSubscriptions(this.state.allSubscriptions.data),
                            refreshing: false
                        }
                    },
                    () => this.setState({status: "ready"})
                ))
            );
        }
    }

    public render() {
        if (this.state.subscriptions.data === null) {
            return <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
        } else {
            return <div data-qa-gtw-subscriptions-container>
                <div style={{margin: "48px 10px 24px", fontSize: "14px", color: this.props.muiTheme.palette.secondaryTextColor}}>Active Subscriptions</div>
                <SubscriptionsTable {...this.props} state={this.state} onSetState={this.onSetState}/>
            </div>
        }
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private getStartDate(endDate) {
        let midnight = moment.tz(endDate - this.state.timeframe, this.props.ui.timeZone).startOf("day");
        return new Date(midnight.format());
    }

    private getDateInMs(date) {
        let midnight = moment.tz(moment(date).format("YYYY-MM-DD"), this.props.ui.timezone);
        return new Date(midnight.format()).getTime();
    }

    private runSubscriptionQuery() {
        const gatewayId = this.props.gateways.selected.data.gatewayId;
        const accountId = this.props.gateways.selected.data.accountId;
        const environmentId = this.props.gateways.selected.data.environmentId;
        const path = `api/${accountId}/env/${environmentId}/gtwy/${gatewayId}/subscription`;
        return axiosWrapper(this.props.config.envApi, path, "GET")
    };

    private processSubscriptions(subscriptions) {
        let preparedData = this.sortSubscriptions(subscriptions);

        return preparedData;
    }

    private sortSubscriptions(subscriptions) {
        const sortBy = this.state.sortBy;
        let sorted = $.extend(true, [], subscriptions);

        if (sortBy.end !== undefined) {
            if (sortBy.end === true) {
                sorted.sort((a, b) => {
                    if (new Date(a.resource.end).getTime() < new Date(b.resource.end).getTime()) return 1;
                    else if (new Date(a.resource.end).getTime() > new Date(b.resource.end).getTime()) return -1;
                    else return 0;
                });
            } else if (sortBy.end === false) {
                sorted.sort((a, b) => {
                    if (new Date(a.resource.end).getTime() < new Date(b.resource.end).getTime()) return -1;
                    else if (new Date(a.resource.end).getTime() > new Date(b.resource.end).getTime()) return 1;
                    else return 0;
                });
            }
        }

        if (sortBy.status !== undefined) {
            if (sortBy.status === true) {
                sorted.sort((a, b) => {
                    return a.resource.status.localeCompare(b.resource.status)
                });
            } else if (sortBy.status === false) {
                sorted.sort((a, b) => {
                    return a.resource.status.localeCompare(b.resource.status)
                }).reverse();
            }
        }

        if (sortBy.criteria !== undefined) {
            if (sortBy.criteria === true) {
                sorted.sort((a, b) => {
                    return a.resource.criteria.localeCompare(b.resource.criteria)
                });
            } else if (sortBy.criteria === false) {
                sorted.sort((a, b) => {
                    return a.resource.criteria.localeCompare(b.resource.criteria)
                }).reverse();
            }
        }

        if (sortBy.channelType !== undefined) {
            if (sortBy.channelType === true) {
                sorted.sort((a, b) => {
                    return a.resource.channel.type.localeCompare(b.resource.channel.type)
                });
            } else if (sortBy.channelType === false) {
                sorted.sort((a, b) => {
                    return a.resource.channel.type.localeCompare(b.resource.channel.type)
                }).reverse();
            }
        }

        return sorted;
    }

    private subscriptions_init() {
        this.runSubscriptionQuery()
            .then(res => {
                const allSubscriptions = {
                    data: this.subscibtions_postProcess(res.data),
                    refreshing: false
                };
                this.setState({
                    subscriptions: {
                        data: this.processSubscriptions(allSubscriptions.data),
                        refreshing: false
                    },
                    allSubscriptions,
                    status: "ready"
                });
            });
    }

    private subscibtions_postProcess(rawData) {
        let preparedData = [];
        if (rawData.entry) {
            preparedData = rawData.entry
        }

        return preparedData;
    }

}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Subscriptions));
