import * as PropTypes from "prop-types";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Table from "../../../../../Widgets/Table";
import SearchIcon from "@material-ui/icons/Search";
import AddBoxIcon from "@material-ui/icons/AddBox";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {capitalizeFirstLetter, getPath} from "../../../../../../lib/utils";
import "./style.less";
import {IconButton, Tooltip} from "@material-ui/core";
import {PlaylistAddCheck} from "@material-ui/icons";
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import ChipDropDown from '../../../../../Widgets/ChipDropDown/'

const Operations = (props) => {
    const total = `${props.state.resources.length} resources`;
    const columns = [
        {
            name: "type",
            size: 2,
            label: "Resource",
            sort: () => props.onSetState({sortBy: {["type"]: !getPath(props, "state.sortBy.type")}}),
            order: getPath(props, "state.sortBy.type")
        },
        {
            name: "read",
            size: 1,
            label: "Read",
            sort: () => props.onSetState({sortBy: {["read"]: !getPath(props, "state.sortBy.read")}}),
            order: getPath(props, "state.sortBy.read")
        },
        {
            name: "search",
            size: 1,
            label: "Search",
            sort: () => props.onSetState({sortBy: {["search"]: !getPath(props, "state.sortBy.search")}}),
            order: getPath(props, "state.sortBy.search")
        },
        {
            name: "create",
            size: 1,
            label: "Create",
            sort: () => props.onSetState({sortBy: {["create"]: !getPath(props, "state.sortBy.create")}}),
            order: getPath(props, "state.sortBy.create")
        },
        {
            name: "update",
            size: 1,
            label: "Update",
            sort: () => props.onSetState({sortBy: {["update"]: !getPath(props, "state.sortBy.update")}}),
            order: getPath(props, "state.sortBy.update")
        },
        {
            name: "delete",
            size: 1,
            label: "Delete",
            sort: () => props.onSetState({sortBy: {["delete"]: !getPath(props, "state.sortBy.delete")}}),
            order: getPath(props, "state.sortBy.delete")
        }
    ];
    const checkAll = set => {
        const operations = {...props.state.filters.operations};
        Object.keys(operations).forEach((key) => operations[key] = set);
        props.onSetState({
            filters: {
                ...props.state.filters,
                operations
            }
        })
    }
    const filteredOperations = Object.keys(props.state.filters.operations).filter(key => props.state.filters.operations[key]);
    let labelOperations = "Operations";
    if (filteredOperations.length) {
        labelOperations = capitalizeFirstLetter(filteredOperations[0]);
        if (filteredOperations.length > 1) {
            labelOperations += ` + ${filteredOperations.length - 1}`;
        }
    }

    let labelResource = ''
    switch (props.state.filters.resources) {
        case ':ALL:':
            labelResource = 'All resources'
            break
        case ':SUPPORTED:':
            labelResource = 'Supported resources'
            break
        case ':UNSUPPORTED:':
            labelResource = 'Unsupported resources'
            break
        case ':OVERRIDDEN:':
            labelResource = 'Overridden resources'
            break
        case ':USCDI:':
            labelResource = 'USCDI resources'
            break
        case ':NON-USCDI:':
            labelResource = 'non-USCDI resources'
            break
    }
    const isFilterResourcesActive = props.state.filters.resources !== ':ALL:'
    const iconColor = { color: props.muiTheme.palette.primary1Color }

    const filters = [
        {
            type: "component",
            value: (
                <div>
                    <ChipDropDown
                        label={labelResource}
                        isActive={isFilterResourcesActive}
                        activeBackground={props.muiTheme.palette.primary1Color}
                        activeColor="#fff"
                        deleteIconStyle={{ fill: 'rgba(255, 255, 255, 0.67)' }}
                        inactiveBackground="#fff"
                        inactiveColor={props.muiTheme.palette.textColor}
                        onRequestDelete={() => props.onSetState({
                            filters: {
                                ...props.state.filters,
                                resources: ':ALL:',
                            },
                        })}
                        popover={{
                            title: "Resources",
                            content: (
                                <div style={{ margin: '8px 16px', display: 'flex', flexDirection: 'column' }}>
                                    {[
                                        {
                                            label: 'All resources',
                                            resources: ':ALL:',
                                        },
                                        {
                                            label: 'Supported resources',
                                            resources: ':SUPPORTED:',
                                        },
                                        {
                                            label: 'Unsupported resources',
                                            resources: ':UNSUPPORTED:',
                                        },
                                        [
                                            'FHIR_STU3__3_0_2',
                                            'FHIR_R4__4_0_0',
                                            'FHIR_R4__4_0_1',
                                        ].some((it) => props.state.data.supportedGatewayTypes.includes(it)) ? {
                                                label: 'USCDI resources',
                                                resources: ':USCDI:',
                                            } : null,
                                        [
                                            'FHIR_STU3__3_0_2',
                                            'FHIR_R4__4_0_0',
                                            'FHIR_R4__4_0_1',
                                        ].some((it) => props.state.data.supportedGatewayTypes.includes(it)) ? {
                                                label: 'non-USCDI resources',
                                                resources: ':NON-USCDI:',
                                            } : null,
                                    ].map((it) => it ? (
                                        <FormControlLabel
                                            key={it.resources}
                                            label={it.label}
                                            control={(
                                                <Checkbox
                                                    checked={props.state.filters.resources === it.resources}
                                                    icon={<RadioButtonUncheckedIcon style={iconColor} />}
                                                    checkedIcon={<RadioButtonCheckedIcon style={iconColor} />}
                                                    style={{ padding: 3 }}
                                                    onChange={() => props.onSetState({
                                                        filters: {
                                                            ...props.state.filters,
                                                            resources: it.resources,
                                                        },
                                                    })}
                                                />
                                            )}
                                        />
                                    ) : null)}
                                </div>
                            )
                        }}
                    />
                </div>
            )
        },
        {
            type: "multiple",
            name: "operations",
            title: "Operations",
            checkAllLabel: "Select All Operations",
            label: labelOperations,
            isActive: filteredOperations.length > 0,
            checked: props.state.filters.operations,
            values: Object.keys(props.state.filters.operations),
            allSelected: filteredOperations.length === Object.keys(props.state.filters.operations).length,
            checkAll: val => checkAll(val),
            onDelete: () => checkAll(false),
            onClick: key => props.onSetState({filters: {...props.state.filters, operations: {...props.state.filters.operations, [key]: !props.state.filters.operations[key]}}})
        },
        {
            type: "component",
            value: <div style={{opacity: .2, marginRight: 0, borderRight: "1px solid gray", height: "30px"}}/>
        },
        {
            type: "component",
            value: <IconButton color="inherit" onClick={() => props.onSetState({showMetadata: true})}>
                <Tooltip title="Get conformance statement">
                    <PlaylistAddCheck/>
                </Tooltip>
            </IconButton>
        }
    ];

    return <Table columns={columns} total={total} rightFilters={filters} state={props.state}>
        {props.state.resources.map((resource, index) => <div key={index} className="operation-table-row">
            <div>
                <strong>{resource.type}</strong>
            </div>
            <div>
                <InsertDriveFileIcon style={{color: resource.read ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}}/>
            </div>
            <div>
                <SearchIcon style={{color: resource.search ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}}/>
            </div>
            <div>
                <AddBoxIcon style={{color: resource.create ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}}/>
            </div>
            <div>
                <EditIcon style={{color: resource.update ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}}/>
            </div>
            <div>
                <DeleteIcon style={{color: resource.delete ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}}/>
            </div>
        </div>
        )}
    </Table>;
}

Operations.propTypes = {
    state: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Operations;
