import { getPath } from "./utils/";

const ROLE_CODES = {
    owner: 0x8000, // 0000 0000 1000 0000 0000 0000
    user:  0x0080, // 0000 0000 0000 0000 1000 0000
}

export function getRoleCodeByRoleId (
    config: any,
    roleId: number,
): number | undefined {
    return getPath(config.userRoles.find((role) => role.id === roleId), "id");
}

export function getRoleOwnerId (config: any): number {
    return config.userRoles.find((role) => role.code === ROLE_CODES.owner).id;
}

export function getRoleUserId (config: any): number {
    return config.userRoles.find((role) => role.code === ROLE_CODES.user).id;
}

export function isAccountOwner (
    user: any,
    config: any,
): boolean {
    const roleOwnerId = getRoleOwnerId(config);
    const userRoleIds = user.userroles || (user.data && user.data.userroles) || [];
    return !!userRoleIds.find((role) => getPath(role, "role_id") === roleOwnerId);
}

export function isAccountUser (
    user: any,
    config: any,
): boolean {
    const roleUserId = getRoleUserId(config);
    const userRoleIds = user.userroles || (user.data && user.data.userroles) || [];
    return !!userRoleIds.find((role) => getPath(role, "role_id") === roleUserId);
}
