import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
// import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
// import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
// import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import RefreshIcon from '@material-ui/icons/Refresh'
import SearchIcon from '@material-ui/icons/Search'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import axiosWrapper from '../../../../../lib/axiosWrapper'
import withLegacyTheme from '../../../../../lib/hoc/with-legacy-theme'
import {
    delay,
    getFullDateDisplay,
    getFullDateTimeMsDisplay,
} from '../../../../../lib/utils'
import './style.less'

const CUSTOM_TABLE_CELL_STYLE: React.CSSProperties = {
    padding: '0 4px',
}
const CUSTOM_TABLE_HEADER_CELL_STYLE: React.CSSProperties = {
    ...CUSTOM_TABLE_CELL_STYLE,
    fontSize: '13px',
}
const CUSTOM_TABLE_BODY_CELL_STYLE: React.CSSProperties = {
    ...CUSTOM_TABLE_CELL_STYLE,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'inherit',
}
const CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE: React.CSSProperties = {
    ...CUSTOM_TABLE_CELL_STYLE,
    whiteSpace: 'normal',
    overflowWrap: 'anywhere',
}

const MENU_ICON_STYLE: React.CSSProperties ={
    padding: '5px 24px 5px 5px',
    opacity: 0.5,
}

type TProps = {
    apps: any
    config: any
    gateways: any
    location: any
    muiTheme: any
    parentState: any
    ui: any
}

type TLogSource = ':GATEWAY:' | ':DATA_ADAPTER:' | ':APP:'
type TGeneratedId = `${TLogSource}-${string}`
type TLogs = Partial<{
    status: ':READY:' | ':PENDING:'
    data: Partial<{
        id: string
        account_id: string | null
        app_id: string | null
        body: string | null
        contains_phi: boolean | null
        data_adapter_id: string | null
        environment_id: string | null
        gateway_id: string | null
        http_error_code: string | null
        ip_address: string | null
        labels: string | null
        log_level: string | null
        metadata: string | null
        session_id: string | null
        system_log_event: string | null
        timestamp: number | null
        underlyingException: string | null
        user_agent: string | null
        __logSource: TLogSource
        __generatedId: TGeneratedId
    }>[]
    requestedAt: number | null
    updatedAt: number | null
}> | null

let searchTermTimer = null

type TLogSourceLabel = 'App' | 'Gateway' | 'Data Adapter'
function getLogSourceLabel(logSource: TLogSource): TLogSourceLabel {
    switch (logSource) {
        case ':APP:': return 'App'
        case ':GATEWAY:': return 'Gateway'
        case ':DATA_ADAPTER:': return 'Data Adapter'
    }
}

const SessionInspector: React.FC<TProps> = (props: TProps) => {
    const [dbSearchTerm, setDbSearchTerm] = React.useState('')
    const [expandedItems, setExpandedItems] = React.useState<TGeneratedId[]>([])
    const [logs, setLogs] = React.useState<TLogs>(null)
    const [menuAnchorElement, setMenuAnchorElement] = React.useState(null)
    const [searchTerm, setSearchTerm] = React.useState<string | null>(null)

    const fetchData = React.useCallback(async () => {
        const ts = Date.now()

        setExpandedItems([])
        setMenuAnchorElement(null)

        if (!dbSearchTerm || dbSearchTerm.length < 5) {
            setLogs({
                status: ':READY:',
                data: null,
                requestedAt: ts,
                updatedAt: ts,
            })
        }
        else {
            setLogs({
                status: ':PENDING:',
                data: null,
                requestedAt: ts,
                updatedAt: ts,
            })

            const pageSize = 50

            const {
                accountId,
                environmentId,
                appId,
                activations,
            } = props.apps.selected.data

            const gatewaysIds = (activations || [])
                .map(({ gatewayId }) => gatewayId)

            const dataAdaptersIdsArr: any[] = (props.gateways.all.data || [])
                .filter(({ gatewayId }) => gatewaysIds.includes(gatewayId))
                .map(({ gatewayId }) => gatewayId)
            const dataAdaptersIdsSet = new Set(dataAdaptersIdsArr)
            const dataAdaptersIds = Array.from(dataAdaptersIdsSet)

            const appsUrl = `apps/${accountId}/${environmentId}/${appId}/logs?size=${pageSize}&startTime=0&searchTerm=${dbSearchTerm}`
            await axiosWrapper(props.config.loggingApi, `${appsUrl}&page=0`, 'GET')
                .then(async (res) => {
                    const data = (res?.data?._embedded?.appsLogsDtoes || [])
                        .map((it) => ({
                            ...it,
                            __logSource: ':APP:',
                            __generatedId: `${':APP:'}-${it.id}`,
                        }))
                    setLogs((prevState) => {
                        if (prevState?.requestedAt !== ts) {
                            return prevState
                        }
                        return {
                            ...(prevState || {}),
                            data: [
                                ...(prevState?.data || []),
                                ...data,
                            ]
                        }
                    })

                    const totalPages = res?.data?.page?.totalPages ?? 0
                    if (totalPages > 1) {
                        for(let i = 1; i < totalPages; i++) {
                            await axiosWrapper(props.config.loggingApi, `${appsUrl}&page=${i}`, 'GET')
                                .then((res) => {
                                    const moreData = (res?.data?._embedded?.appsLogsDtoes || [])
                                        .map((it) => ({
                                            ...it,
                                            __logSource: ':APP:',
                                            __generatedId: `${':APP:'}-${it.id}`,
                                        }))
                                    setLogs((prevState) => {
                                        if (prevState?.requestedAt !== ts) {
                                            return prevState
                                        }
                                        return {
                                            ...(prevState || {}),
                                            data: [
                                                ...(prevState?.data || []),
                                                ...moreData,
                                            ]
                                        }
                                    })
                                })
                        }
                    }
                })
                .then(async () => {
                    for(let i = 0; i < gatewaysIds.length; i++) {
                        const gwsUrl = `apps/${accountId}/${environmentId}/gateway/${gatewaysIds[i]}/logs?size=${pageSize}&startTime=0&searchTerm=${dbSearchTerm}`
                        await axiosWrapper(props.config.loggingApi, `${gwsUrl}&page=0`, 'GET')
                            .then(async (res) => {
                                const data = (res?.data?._embedded?.appsLogsDtoes || [])
                                    .map((it) => ({
                                        ...it,
                                        __logSource: ':GATEWAY:',
                                        __generatedId: `${':GATEWAY:'}-${it.id}`,
                                    }))
                                setLogs((prevState) => {
                                    if (prevState?.requestedAt !== ts) {
                                        return prevState
                                    }
                                    return {
                                        ...(prevState || {}),
                                        data: [
                                            ...(prevState?.data || []),
                                            ...data,
                                        ]
                                    }
                                })

                                const totalPages = res?.data?.page?.totalPages ?? 0
                                if (totalPages > 1) {
                                    for(let i = 1; i < totalPages; i++) {
                                        await axiosWrapper(props.config.loggingApi, `${gwsUrl}&page=${i}`, 'GET')
                                            .then((res) => {
                                                const moreData = (res?.data?._embedded?.appsLogsDtoes || [])
                                                    .map((it) => ({
                                                        ...it,
                                                        __logSource: ':GATEWAY:',
                                                        __generatedId: `${':GATEWAY:'}-${it.id}`,
                                                    }))
                                                setLogs((prevState) => {
                                                    if (prevState?.requestedAt !== ts) {
                                                        return prevState
                                                    }
                                                    return {
                                                        ...(prevState || {}),
                                                        data: [
                                                            ...(prevState?.data || []),
                                                            ...moreData,
                                                        ]
                                                    }
                                                })
                                            })
                                    }
                                }
                            })
                    }
                })
                .then(async () => {
                    for(let i = 0; i < dataAdaptersIds.length; i++) {
                        const dasUrl = `apps/${accountId}/${environmentId}/da/${dataAdaptersIds[i]}/logs?size=${pageSize}&startTime=0&searchTerm=${dbSearchTerm}`
                        await axiosWrapper(props.config.loggingApi, `${dasUrl}&page=0`, 'GET')
                            .then(async (res) => {
                                const data = (res?.data?._embedded?.appsLogsDtoes || [])
                                    .map((it) => ({
                                        ...it,
                                        __logSource: ':DATA_ADAPTER:',
                                        __generatedId: `${':DATA_ADAPTER:'}-${it.id}`,
                                    }))
                                setLogs((prevState) => {
                                    if (prevState?.requestedAt !== ts) {
                                        return prevState
                                    }
                                    return {
                                        ...(prevState || {}),
                                        data: [
                                            ...(prevState?.data || []),
                                            ...data,
                                        ]
                                    }
                                })

                                const totalPages = res?.data?.page?.totalPages ?? 0
                                if (totalPages > 1) {
                                    for(let i = 1; i < totalPages; i++) {
                                        await axiosWrapper(props.config.loggingApi, `${dasUrl}&page=${i}`, 'GET')
                                            .then((res) => {
                                                const moreData = (res?.data?._embedded?.appsLogsDtoes || [])
                                                    .map((it) => ({
                                                        ...it,
                                                        __logSource: ':DATA_ADAPTER:',
                                                        __generatedId: `${':DATA_ADAPTER:'}-${it.id}`,
                                                    }))
                                                setLogs((prevState) => {
                                                    if (prevState?.requestedAt !== ts) {
                                                        return prevState
                                                    }
                                                    return {
                                                        ...(prevState || {}),
                                                        data: [
                                                            ...(prevState?.data || []),
                                                            ...moreData,
                                                        ]
                                                    }
                                                })
                                            })
                                    }
                                }
                            })
                    }
                })

            setLogs((prevState) => {
                if (prevState?.requestedAt !== ts) {
                    return prevState
                }
                return {
                    ...(prevState || {}),
                    status: ':READY:',
                    updatedAt: Date.now(),
                }
            })
        }
    }, [
        dbSearchTerm,
        props.apps.selected.data,
        props.config.loggingApi,
        props.gateways.all.data,
    ])

    async function exportData(separator) {
        setMenuAnchorElement(null)

        let element = document.createElement('a')
        element.style.display = 'none'

        const data = (logs?.data || [])
            .reduce(
                (acc, it) => {
                    return acc + `${it.id}${separator}`
                        + `${it.timestamp}${separator}`
                        + `${getFullDateTimeMsDisplay(it.timestamp, props.ui.timeZone)}${separator}`
                        + `${getLogSourceLabel(it.__logSource)}${separator}`
                        + `${it.gateway_id}${separator}`
                        + `${it.app_id}${separator}`
                        + `${it.session_id}${separator}`
                        + `${it.log_level}${separator}`
                        + `${it.metadata}${separator}`
                        + `${it.system_log_event}${separator}`
                        + `${it.body}`
                        + `\r\n`
                },
                `ID${separator}`
                    + `Time Stamp${separator}`
                    + `Time${separator}`
                    + `Source${separator}`
                    + `Gateway Id${separator}`
                    + `App Id${separator}`
                    + `Session Id${separator}`
                    + `Type${separator}`
                    + `Metadata${separator}`
                    + `System Log Event${separator}`
                    + 'Message'
                    + '\r\n'
            )

        const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })
        const url = URL.createObjectURL(blob)
        element.setAttribute('href', url)

        const extension = (separator === ',' ? 'csv' : 'tsv')
        const filename = `${getFullDateDisplay(Date.now(), props.ui.timeZone)}.${extension}`
        element.setAttribute('download', filename)

        document.body.appendChild(element)
        await delay(1550)
        element.click()
        await delay(1550)
        document.body.removeChild(element)
        element = null
    }

    // init searchTerm
    React.useEffect(() => {
        if (searchTerm === null) {
            if (props.parentState.selectedSessionId) {
                setSearchTerm(props.parentState.selectedSessionId)
            }
            else if (props.parentState.selectedMetadata) {
                setSearchTerm(props.parentState.selectedMetadata)
            }
        }
    }, [props.parentState.selectedMetadata, props.parentState.selectedSessionId, searchTerm])

    // dbSearchTerm
    React.useEffect(() => {
        if (searchTermTimer) {
            clearTimeout(searchTermTimer)
        }
        searchTermTimer = setTimeout(() => {
            setDbSearchTerm(searchTerm ?? '')
        }, 550)
    }, [searchTerm])

    // fetch data
    React.useEffect(() => {
        fetchData()
    }, [fetchData])

    // filtering logs
    const filteredLogs = (logs?.data || [])
        .filter(({ session_id, metadata }) => metadata?.includes(dbSearchTerm) || session_id?.includes(dbSearchTerm))
        .sort(({ timestamp: tsa }, { timestamp: tsb }) => {
            if (tsa - tsb > 0) return -1
            if (tsa - tsb < 0) return 1
            return 0
        })

    return (
        <Card style={{ marginTop: '48px' }}>
            {/* Header L1 */}
            <div
                style={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '48px',
                    margin: 0,
                    padding: '0 12px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    background: props.muiTheme.palette.accent2Color,
                }}
            >
                <SearchIcon style={{ marginRight: '12px' }} />
                <TextField
                    value={searchTerm ?? ''}
                    placeholder="Session Id or Metadata"
                    style={{
                        width: '90%',
                        marginRight: '12px',
                    }}
                    onChange={(event) => {
                        setSearchTerm(event.target.value)
                    }}
                />

                <div style={{ flex: 1 }} />

                {logs?.status === ':READY:' ? (
                    <IconButton
                        onClick={() => {
                            fetchData()
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>
                ) : <CircularProgress />}

                <IconButton
                    disabled={logs?.status !== ':READY:' || !logs?.data?.length}
                    onClick={(event) => {
                        setMenuAnchorElement(event.currentTarget)
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    open={!!menuAnchorElement}
                    anchorEl={menuAnchorElement}
                    onClose={() => setMenuAnchorElement(null)}
                >
                    <MenuItem
                        onClick={() => {
                            exportData(',')
                        }}
                    >
                        <UnarchiveIcon style={MENU_ICON_STYLE} />
                        &nbsp;Export CSV
                    </MenuItem>
                    <MenuItem
                        disabled={logs?.status !== ':READY:' || !logs?.data?.length}
                        onClick={() => {
                            exportData('\t')
                        }}
                    >
                        <UnarchiveIcon style={MENU_ICON_STYLE} />
                        &nbsp;Export TSV
                    </MenuItem>
                </Menu>
            </div>

            {/* Header L2 */}
            <div
                style={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '32px',
                    margin: 0,
                    padding: '0 12px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    background: props.muiTheme.palette.accent2Color,
                }}
            >
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '220px',
                    }}
                >
                    Time
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '110px',
                    }}
                >
                    Source
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '110px',
                    }}
                >
                    Gateway Id
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '150px',
                    }}
                >
                    Session Id
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '80px',
                    }}
                >
                    Type
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '150px',
                    }}
                >
                    Metadata
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '110px',
                    }}
                >
                    System Log Event
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        flex: 1,
                    }}
                >
                    Message
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '48px',
                    }}
                />
            </div>

            {/* Body */}
            <div
                style={{
                    margin: 0,
                    padding: 0,
                }}
            >
                {filteredLogs.length ? (
                    filteredLogs.map((it) => {
                        const isExpanded = expandedItems.includes(it.__generatedId)
                        return (
                            <div
                                key={it.__generatedId}
                                className="session-inspector-log-item-wrapper"
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                    backgroundColor: isExpanded ? 'rgb(245, 245, 245)' : undefined
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: isExpanded ? 'start' : 'center',
                                        margin: 0,
                                        padding: isExpanded ? '12px' : '0 12px',
                                    }}
                                >
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '220px',
                                        }}
                                    >
                                        {getFullDateTimeMsDisplay(it.timestamp, props.ui.timeZone)}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            width: '110px',
                                        }}
                                    >
                                        <Chip
                                            size="small"
                                            label={getLogSourceLabel(it.__logSource)}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '110px',
                                        }}
                                    >
                                        {it.gateway_id}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '150px',
                                        }}
                                    >
                                        {it.session_id}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '80px',
                                        }}
                                    >
                                        {it.log_level}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '150px',
                                        }}
                                    >
                                        {it.metadata}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            width: '110px',
                                        }}
                                    >
                                        {it.system_log_event}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            ...isExpanded ? CUSTOM_TABLE_BODY_EXPANDED_CELL_STYLE : undefined,
                                            flex: 1,
                                        }}
                                    >
                                        {it.body}
                                    </div>
                                    <IconButton
                                        disabled={logs.status !== ':READY:'}
                                        // disableRipple
                                        // style={{ background: 'none' }}
                                        onClick={() => {
                                            if (isExpanded) {
                                                setExpandedItems((prevState) => prevState.filter((lit) => lit !== it.__generatedId))
                                            }
                                            else {
                                                setExpandedItems((prevState) => [...prevState, it.__generatedId])
                                            }
                                        }}
                                    >
                                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <Typography
                        align="center"
                        style={{
                            margin: 0,
                            padding: '12px',
                        }}
                    >
                        No data matches your search criteria
                    </Typography>
                )}
            </div>

            {/* Footer */}
            {/* <div
                style={{
                    margin: 0,
                    padding: '0 12px',
                }}
            >
                <IconButton
                    disabled={...}
                    onClick={() => {...}
                >
                    <NavigateBeforeIcon />
                </IconButton>
                <Button
                    variant="contained"
                    style={{
                        background: 'none',
                        boxShadow: 'none',
                    }}
                    onClick={...}
                >
                    ...
                </Button>
                <IconButton
                    disabled={...}
                    onClick={() => {...}
                >
                    <NavigateNextIcon/>
                </IconButton>
            </div> */}
        </Card>
    )
}

const mapStateToProps = (state, ownProps) => ({
    apps: state.apps,
    config: state.config,
    gateways: state.gateways,
    ui: state.ui,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(withLegacyTheme()(SessionInspector)))
