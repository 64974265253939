import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import type { TAppActivation } from '../../../../../../types'
import type { TParentState } from '../../Activations'
import CustomDialogActions from './CustomDialogActions'
import CustomDialogContent from './CustomDialogContent'
import CustomDialogTitle from './CustomDialogTitle'

export type TStatus = ':READY:' | ':DELETING:'

type TProps = {
    parentState: TParentState
    selectedAppActivation?: TAppActivation
    onClose: () => void
}

const ActivationDeleteConfirm: React.FC<TProps> = (props: TProps) => {
    const [status, setStatus] = React.useState<TStatus>(':READY:')

    return (
        <Dialog
            open
            disableBackdropClick
            disableEscapeKeyDown
            scroll="body"
            onClose={props.onClose}
        >
            <CustomDialogTitle onClose={props.onClose} />
            <CustomDialogContent selectedAppActivation={props.selectedAppActivation} />
            <CustomDialogActions
                dstatus={status}
                parentState={props.parentState}
                selectedAppActivation={props.selectedAppActivation}
                onCancel={props.onClose}
                onClose={props.onClose}
                setDStatus={setStatus}
            />
        </Dialog>
    )
}

export default ActivationDeleteConfirm
