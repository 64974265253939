import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import {getPath} from "../../../../lib/utils/";
import consoleLogger from "../../../../lib/consoleLogger";
import {Link} from "react-router-dom";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import {NavbarL2} from "../../../CustomNavbar/";
import Details from "./Details/";
import Dialogs from "./Dialogs";

const TABS = [
    {title: "DETAILS"}
];

const INIT_STATE = {
    status: "",
    data: {
        first_name: "",
        last_name: "",
        oldPassword: "",
        newPassword: "",
        email: "",
        type: ""
    },
    dataValidation: {},
    passwordError: false,
    changeEmailStatus: "",
    mode: ":VIEW:",
    dialog: ""
};

class Component extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,
        users: PropTypes.object.isRequired,
        usersCurrentRead: PropTypes.func.isRequired,
        uiTabsSetItems: PropTypes.func.isRequired,
        uiTabsSetSelected: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.props.uiTabsSetItems(TABS);
        this.props.uiTabsSetSelected(0);

        this.initialize();
    }

    public componentDidUpdate(prevProps) {
        const selectedTab = getPath(this.props, "ui.tabs.selected");
        const prevSelectedTab = getPath(prevProps, "ui.tabs.selected");

        if (selectedTab !== prevSelectedTab) {
            this.setState({...INIT_STATE}, () => this.initialize());
        }
    }

    public render() {
        return <div style={{width: "100%"}}>
            <NavbarL2 title={<div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <div style={{height: "56px", marginRight: "24px"}}>
                    <Link to={`/users/current`} style={{color: "#fff", fontSize: "24px", textDecoration: "none"}}>
                        User Profile
                    </Link>
                </div>
            </div>
            }/>
            <div className="container">
                {["ready", "wait"].indexOf(this.state.status) < 0
                    ? <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
                    : this.renderTabContent()}
            </div>
            <br/>
            {["ready", "wait"].indexOf(this.state.status) > -1 && <Dialogs {...this.props} state={this.state} initialize={this.initialize} onSetState={this.onSetState}/>}
        </div>;
    }

    private renderTabContent = () => {
        switch (this.props.ui.tabs.selected) {
            case 0:
                return <Details {...this.props} state={this.state} initialize={this.initialize} onSetState={this.onSetState}/>;
            default:
                return null;
        }
    }

    private onSetState = (state, cb) => {
        this.setState(state, cb);
    }

    private initialize = () => {
        this.setState(
            {status: "init"},
            () => {
                this.props.usersCurrentRead(this.props.config)
                    .then(() => {
                        this.setState({
                            ...INIT_STATE,
                            status: "ready",
                            data: {
                                ...INIT_STATE.data,
                                ...this.props.users.selected.data
                            }
                        });
                    })
                    .catch((reason) => consoleLogger.error(":::", reason));
            }
        );
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Component));
