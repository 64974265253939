import { useState } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../redux/actions";
import getCdsServiceSetsCallUrl from "../../../../../../lib/getCdsServiceSetsCallUrl";
import getGatewaysResourceUrl from "../../../../../../lib/getGatewaysResourceUrl";
import getLocationInfo from "../../../../../../lib/getLocationInfo";
import {isEnvironmentAdmin} from "../../../../../../lib/user-environment-permissions-helpers";
import withLegacyTheme from "../../../../../../lib/hoc/with-legacy-theme";
import IconButton from '@material-ui/core/IconButton';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CustomLink from "../../../../../Widgets/CustomLink";
import "./style.less";

const ActivationItem = (props) => {
    const actAsAdmin = isEnvironmentAdmin(props.login, props.location, props.config);
    const isActivationDisabled = props.activation.disabled;
    const { env } = getLocationInfo(props.location, props.config);

    // *** Gateway name and URL
    const gatewayObject = props.gateways.all.data.find((gateway) => gateway.gatewayId === props.activation.gatewayId) || {};
    const gatewayName = gatewayObject.name || "";
    const gatewayResourceUrl = gatewayObject.gatewayType === "HL7_V2"
        ? "hl7://interopiondemo.smilecdr.com:7000"
        : getGatewaysResourceUrl(gatewayObject.accountId, gatewayObject.environmentId, gatewayObject.gatewayId, props.config.proxyApi);

    // *** Right icon
    const [anchorEl, setAnchorEl] = useState(null);
    const rightIcon = (props.showMenu && actAsAdmin)
        ? <div className="col-3">
            <IconButton onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
            }}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                PaperProps={{ style: { minWidth: 168 } }}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setAnchorEl(null);
                    props.onSetParentState({
                        selectedActivation: props.activation,
                        dialog: ":EDIT_ACTIVATION:",
                    });
                }}>
                    <ListItemIcon><VisibilityIcon /></ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        View activation
                    </Typography>
                </MenuItem>
                <MenuItem onClick={async (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setAnchorEl(null);
                    props.onSetParentState({ status: "waiting" });

                    const accountId = props.parentState.data.accountId;
                    const environmentId = props.parentState.data.environmentId;
                    const cdsServiceSetId = props.parentState.data.cdsServiceSetId;
                    const activations = (props.parentState.data?.activations || [])
                        .map((activation) => {
                            if (activation.cdsServiceSetId === props.activation.cdsServiceSetId && activation.gatewayId === props.activation.gatewayId) {
                                return { ...activation, disabled: !props.activation.disabled };
                            }
                            return activation;
                        });
                    const data = { ...props.parentState.data, activations };
                    const cdsServiceSetUrl = getCdsServiceSetsCallUrl(accountId, environmentId) + `/${cdsServiceSetId}`;
                    const response = await props.cdsServiceSetsUpdate(props.config, cdsServiceSetUrl, data);
                    if (response && response.status < 300) {
                        props.initialize();
                    }
                }}>
                    <ListItemIcon>
                        {isActivationDisabled ? <CheckCircleIcon /> : <RemoveCircleIcon />}
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        {isActivationDisabled ? "Enable activation" : "Disable activation"}
                    </Typography>
                </MenuItem>
                <MenuItem onClick = {(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setAnchorEl(null);
                    props.onSetParentState({
                        selectedActivation: props.activation,
                        dialog: ":DELETE_ACTIVATION_CONFIRM:",
                    });
                }}>
                    <ListItemIcon><DeleteIcon /></ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        Delete activation
                    </Typography>
                </MenuItem>
            </Menu>
        </div> : null

    return <ListItem
        disabled
        className="cds-service-set-activation-ul-item"
        style={{opacity: props.parentState.status !== "ready" ? 0.33 : 1}}
    >
        <div className="content">
            <div className="col-1" style={{cursor: props.clickable ? "pointer" : "default"}}
                onClick={() => props.clickable && props.onSetParentState({
                    selectedActivation: props.activation,
                    dialog: ":EDIT_ACTIVATION:",
                })}>
                <span style={{color: isActivationDisabled ? props.muiTheme.palette.disabledColor : props.muiTheme.palette.textColor}}>
                    {props.activation.name}
                </span>
            </div>
            <div className="col-2">
                <span style={{color: isActivationDisabled ? props.muiTheme.palette.disabledColor : props.muiTheme.palette.textColor}}>
                    <CustomLink to={`/${env}/gateways/${props.activation.gatewayId}`}>
                        {gatewayName}
                    </CustomLink>
                    <br/>
                    <small style={{color: isActivationDisabled ? props.muiTheme.palette.disabledColor : props.muiTheme.palette.secondaryTextColor}}>
                        {gatewayResourceUrl}
                    </small>
                </span>
            </div>
            {rightIcon}
        </div>
    </ListItem>;
}

ActivationItem.propTypes = {
    activation: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    gateways: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    cdsServiceSetsUpdate: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired,
    clickable: PropTypes.bool,
    showMenu: PropTypes.bool,
};

ActivationItem.defaultProps = {
    clickable: true,
    showMenu: true
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    gateways: state.gateways,
    login: state.login,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsUpdate: actions.cdsServiceSetsUpdate,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(ActivationItem)));
