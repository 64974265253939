import * as PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogActions from "./CustomDialogActions";
import CustomDialogContent from "./CustomDialogContent";
import CustomDialogTitle from "./CustomDialogTitle";

const CdsServiceSetDeleteConfirmation = (props) => {
    return <Dialog
        open disableBackdropClick disableEscapeKeyDown scroll="body"
        onClose={props.onClose}
    >
        <CustomDialogTitle onClose={props.onClose} />
        <CustomDialogContent parentState={props.parentState} />
        <CustomDialogActions
            parentState={props.parentState}
            onClose={props.onClose}
            onSetParentState={props.onSetParentState}
        />
    </Dialog >
}

CdsServiceSetDeleteConfirmation.propTypes = {
    parentState: PropTypes.object.isRequired,

    onClose: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired,
};

export default CdsServiceSetDeleteConfirmation;
