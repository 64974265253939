import $ from "jquery";
import * as types from "./types";
import initialState from "../reducers/portals/initial-state";
import axiosWrapper from "../../lib/axiosWrapper";
import consoleLogger from "../../lib/consoleLogger";
import {getPath} from "../../lib/utils";
import getPortalsCallUrl from "../../lib/getPortalsCallUrl";
import getPortalId from "../../lib/getPortalId";
import {generalMessageSet} from "./general-message";

export function portalsReadAll (config: any, url: string) {
    return function (dispatch) {
        dispatch({
            type: types.PORTALS_ALL_SET,
            payload: {
                status: "pending",
                data: $.extend(true, [], initialState.all.data),
            },
        });
        return axiosWrapper(config.envApi, url, "get")
            .then((res) => {
                dispatch({
                    type: types.PORTALS_ALL_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data")
                    },
                });
                return res;
            })
            .catch((/* reason */) => {
                dispatch({
                    type: types.PORTALS_ALL_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, [], initialState.all.data),
                    },
                });
                // console.error(reason);
            });
    };
}

export function portalsCreate (config: any, url: string, data = {}) {
    return function (dispatch) {
        dispatch({
            type: types.PORTALS_SELECTED_SET,
            payload: {
                status: "pending",
                data: $.extend(true, {}, initialState.selected.data)
            }
        });
        return axiosWrapper(config.envApi, url, "post", data)
            .then((res) => {
                dispatch({
                    type: types.PORTALS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data")
                    }
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: types.PORTALS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, {}, initialState.selected.data)
                    }
                });
                if (error.response.status === 400) dispatch(generalMessageSet({
                    type: ":CRITICAL_SNACKBAR:",
                    title: "There was an error creating the portal!",
                    message: error.response.data.message,
                }));
                // console.error(error);
            });
    };
}

export function portalsUpdate (config: any, url: string, data = {}) {
    return function (dispatch) {
        dispatch({
            type: types.PORTALS_SELECTED_SET,
            payload: {
                status: "pending",
                data: $.extend(true, {}, initialState.selected.data)
            }
        });
        return axiosWrapper(config.envApi, url, "put", data)
            .then((res) => {
                dispatch({
                    type: types.PORTALS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data")
                    }
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: types.PORTALS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, {}, initialState.selected.data)
                    }
                });
                if (error.response.status === 400) dispatch(generalMessageSet({
                    type: ":CRITICAL_SNACKBAR:",
                    title: "There was an error updating the portal!",
                    message: error.response.data.message,
                }));
                // console.error(error);
                // throw new Error("");
            });
    };
}

export function portalsDelete (config: any, url: string) {
    return function () {
        return axiosWrapper(config.envApi, url, "delete")
            .then((res) => {
                return res;
            })
            .catch((error) => {
                consoleLogger.error(error);
            });
    };
}

export function portalSelect (config: any, accountId: any, env: any, data: any) {
    return function (dispatch) {
        dispatch({
            type: types.PORTALS_SELECTED_SET,
            payload: {
                status: "loading",
                data: data
            }
        });

        return axiosWrapper(config.envApi, `${getPortalsCallUrl(accountId, env, data.gatewayId)}/${data.portalType.toLowerCase()}/${getPortalId(data)}`, "get")
            .then((res) => {
                dispatch({
                    type: types.PORTALS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data")
                    }
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: types.PORTALS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, {}, initialState.selected.data)
                    }
                });
                consoleLogger.error(error);
            });
    };
}
