import * as React from "react";
import * as PropTypes from "prop-types";
import {TextField, IconButton, Switch, FormControlLabel, Tooltip} from "@material-ui/core";
import AppRedirectUrls from "../../../../AppRedirectUrls/";
import AppSecurity from "../../../../AppSecurity/";
import EhrListingUrls from "../../../../EhrListingUrls";
import {Info} from "@material-ui/icons";
import type { TProps as TEhrListingUrls } from '../../../../EhrListingUrls'

export default class extends React.Component<any, any> {
    public static propTypes = {
        state: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired
    };

    public render() {
        return <div style={{padding: "0 24px"}}>
            <TextField name="name" value={this.props.state.data.name || ""} label="Display Name" fullWidth disabled classes={{root: "disabled-text-field"}} style={{cursor: "default"}}/>

            <TextField name="appId" value={this.props.state.data.appId || ""} disabled label="App ID" fullWidth classes={{root: "disabled-text-field"}}
                style={{cursor: "default", marginTop: "16px"}}/>

            <TextField name="description" value={this.props.state.data.description || ""} label="Description" fullWidth multiline disabled classes={{root: "disabled-text-field"}}
                style={{cursor: "default", marginTop: "16px"}}/>

            <FormControlLabel label={`Enable Application Logging(${this.props.state.data.logViewer ? "Enabled" : "Disabled"})`} style={{marginTop: "16px"}}
                control={<Switch color="primary" checked={this.props.state.data.logViewer || false} disabled/>}/>

            {this.props.state.data.clientId
                ? <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "16px"}}>
                    <TextField name="clientId" value={this.props.state.data.clientId} label="Client ID" fullWidth disabled classes={{root: "disabled-text-field"}}
                        style={{cursor: "default"}}/>
                    <Tooltip title={<span style={{display: "block", padding: "8px 0", lineHeight: "normal", whiteSpace: "normal"}}>
                                            This client ID is issued by interopIO.
                                            Use this client ID in the Smart App.
                    </span>}>
                        <IconButton>
                            <Info/>
                        </IconButton>
                    </Tooltip>
                </div>
                : null}

            <TextField name="launchUrl" value={this.props.state.data.launchUrl || ""} label="App Launch URI" fullWidth disabled classes={{root: "disabled-text-field"}}
                style={{cursor: "default", marginTop: "16px"}}/>

            <TextField name="healthUrl" value={this.props.state.data.healthUrl || ""} label="App Health URI" fullWidth disabled classes={{root: "disabled-text-field"}}
                style={{cursor: "default", marginTop: "16px"}}/>

            <AppRedirectUrls {...this.props} canEdit={false}/>
            <EhrListingUrls {...(this.props as TEhrListingUrls)} />

            <div style={{marginTop: "16px"}}>
                <div style={{marginBottom: "8px", fontSize: "12px", color: this.props.muiTheme.palette.disabledColor}}>
                    Scopes
                </div>
                <div style={{color: this.props.muiTheme.palette.textColor}}>
                    {this.props.state.data.scopes}
                </div>
            </div>

            <AppSecurity {...this.props} canEdit={false}/>
        </div>;
    }
}
