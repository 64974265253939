import * as validateId from "./validate-id";

export function onChangeCustomContextKey(providerPortalCustomContexts: any, newCustomContextKey: string) {
    const keys = providerPortalCustomContexts ? providerPortalCustomContexts.map((item) => item.contextKey) : [];

    let newKey = "";
    newKey = validateId.isNotTooShort(newCustomContextKey, 1) ? newKey : "This key is too short";
    newKey = validateId.isContextKeyFriendly(newCustomContextKey) ? newKey : "The key can only contain: letters of the English alphabet, numbers (0-9), hyphen/minus sign (-), it must start with a letter and end with a letter or number, not a hyphen";
    newKey = validateId.isUnique(newCustomContextKey, keys) ? newKey : "The key must be unique";

    return newKey;
}

export function onChangeCustomContextValue(newCustomContextValue: string) {
    let newValue = "";
    return validateId.isNotTooShort(newCustomContextValue, 1) ? newValue : "This value is too short";
}
