import * as validateId from "./validate-id";
import { getPath } from "./utils/";

export function onWStep1(
    state: any,
    config: any,
) {
    const name: string = state.data.name ? "" : "Required field";

    const gateway: string = state.data.gatewayId ? "" : "Required field";

    const cdsServiceGatewayActivations: Array<any> = getPath(state, "data.cdsServiceGatewayActivations") || [];
    const overrideIds = {};
    cdsServiceGatewayActivations.forEach((item: any): void => {
        let message = "";
        if (item.overrideId) {
            message = validateId.isUrlFriendly(item.overrideId) ? message : "The ID can only contain: lower case letters of the English alphabet, numbers (0-9), hyphen/minus sign (-), it must start with a letter and end with a letter or number, not a hyphen";
            // message = validateId.isNotTooLong(item.overrideId) ? message : "This ID is too long";
            message = validateId.isNotTooShort(item.overrideId) ? message : "This ID is too short";
            // message = item.overrideId ? message : "Required field";
            // message = validateId.isUnique(item.overrideId, ids) ? message : "This ID already exists"; // TODO:
            message = validateId.isNotRestricted(item.overrideId, config.reservedIds) ? message : "This ID is reserved";
        }
        overrideIds[item.id] = message;
    });

    return {
        name,
        gateway,
        overrideIds,
    };
}

export const onEdit: Function = onWStep1;
