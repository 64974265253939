import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {isEnvironmentAdmin} from "../../../../../lib/user-environment-permissions-helpers";
import * as validateData from "../../../../../lib/validate-user-dirs-data";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Header from "../../../../Widgets/Editor/Header/";
import ActionButtons from "./ActionButtons";

class Details extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        initialize: PropTypes.func.isRequired,
        parentState: PropTypes.object.isRequired,
        onSetParentState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.validateData();
    }

    public componentDidUpdate(prevProps) {
        const data_string = JSON.stringify(this.props.parentState.data);
        const prevData_string = JSON.stringify(prevProps.parentState.data);
        if (data_string !== prevData_string) {
            this.validateData();
        }
    }

    public render() {
        const canEdit = this.props.parentState.mode === ":EDIT:";
        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);
        const editableDirectoryType = this.props?.parentState?.data?.directoryType === "INTEROPIO_USERS";

        return <Card style={{marginTop: 48}}>
            {this.renderHeader(canEdit, actAsAdmin, editableDirectoryType)}

            <div style={{padding: "0 24px"}}>
                {this.renderName(canEdit)}
                {this.renderDirectoryId()}
                {this.renderDescription(canEdit)}
            </div>

            <ActionButtons parentState={this.props.parentState} initialize={this.props.initialize} onSetParentState={this.props.onSetParentState} />
        </Card>;
    }

    private renderHeader = (canEdit, actAsAdmin, editableDirectoryType) => {
        return <Header {...this.props} style={{ height: 56 }}
            rightIcon={!canEdit && actAsAdmin && editableDirectoryType
                ? <IconButton style={{ paddingTop: 16 }} onClick={() => this.props.onSetParentState({ mode: ":EDIT:" })}>
                    <Tooltip title="Edit"><EditIcon /></Tooltip>
                </IconButton>
                : null}
        />
    };

    private renderName = (canEdit) => {
        return <TextField
            name="name" value={this.props.parentState.data.name || ""}
            placeholder="Display name" label="Display Name" helperText={this.props.parentState.dataValidation.name}
            error={!!this.props.parentState.dataValidation.name} disabled={!canEdit}
            autoComplete="off" autoFocus={false} fullWidth
            classes={{ root: canEdit ? "" : "disabled-text-field" }}
            style={{ marginTop: 16 }}
            onChange={canEdit ? (event) => this.props.onSetParentState((prevParentState) => ({ data: { ...prevParentState.data, name: event.target.value } })) : undefined}
        />
    };

    private renderDirectoryId = () => {
        return <TextField
            data-qa={`user-dir-id-${this.props.parentState.data.directoryId}`}
            name="directoryId" value={this.props.parentState.data.directoryId || ""}
            placeholder={"User directory ID"} label={"User Directory ID"}
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />
    };

    private renderDescription = (canEdit) => {
        return <TextField
            name="description" value={this.props.parentState.data.description || ""}
            placeholder="Description" label="Description" disabled={!canEdit}
            autoComplete="off" autoFocus={false} fullWidth multiline
            classes={{ root: canEdit ? "" : "disabled-text-field" }}
            onChange={canEdit ? (event) => this.props.onSetParentState((prevParentState) => ({ data: { ...prevParentState.data, description: event.target.value } })) : undefined}
            style={{
                marginTop: !this.props.parentState.data.description ? 0 : 16,
                marginBottom: 16
            }}
        />
    };

    private validateData = () => {
        const dataValidation = validateData.onEditDetails(this.props.parentState);
        this.props.onSetParentState({dataValidation});
    }
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    login: state.login,
    ...ownProps,
});
export default withRouter(connect(mapStateToProps)(Details));
