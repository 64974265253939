import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import getEpicPatientStagingDataEndpointUrl from "../../../../../../../lib/getEpicPatientStagingDataEndpointUrl"
import * as validateData from "../../../../../../../lib/validate-data-sources-data";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import JsonConfig from "../../../../Shared/JsonConfig";
import { withRouter } from 'react-router'
import getHostnameInfo from '../../../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../../../lib/getLocationInfo'
import type {
    TDataSourceType,
    TExternalAuth,
    TExternalAuthType,
} from '../../../../../../../types'
import ExternalAuth from '../../../../Shared/ExternalAuth'

class WStep2 extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,
        wstate: PropTypes.object.isRequired,

        onSetWState: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {
            dataValidation: {},
        };
    }

    public componentDidMount() {
        this.props.onSetWState(
            { isNextWStepEnabled: false },
            () => this.validateData(true),
        );
    }

    public componentDidUpdate(prevProps) {
        const endpoint = this.props.wstate.data.__temp__endpoint;
        const prevDataEndpoint = prevProps.wstate.data.__temp__endpoint;
        const sandboxId = this.props.wstate.data.configuration.sandboxId;
        const prevDataConfigurationSandboxId = prevProps.wstate.data.configuration.sandboxId;
        let conf = this.props.wstate.data.configuration || {};
        let prevConf = prevProps.wstate.data.configuration || {};
        const extAuth = this.props.wstate.data.externalAuth
        const prevExtAuth = prevProps.wstate.data.externalAuth
        if (endpoint !== prevDataEndpoint || sandboxId !== prevDataConfigurationSandboxId || extAuth !== prevExtAuth) {
            this.validateData(true);
        }
        else if (conf.patientStagingDataEnabled !== prevConf.patientStagingDataEnabled || conf.patientStagingDataRestUrl !== prevConf.patientStagingDataRestUrl) {
            this.validateData(false);
        }
        else if (conf.fileClinicalNoteEnabled !== prevConf.fileClinicalNoteEnabled || conf.fileClinicalNoteRestUrl !== prevConf.fileClinicalNoteRestUrl) {
            this.validateData(false);
        }
        else if (conf.json !== prevConf.json) {
            this.validateData(false);
        }
    }

    public render() {
        let fields = [];

        this.props.wstate.data.type === "DataSourceEpicDstu2" && fields.push(
            <p key={1} style={{ marginBottom: 0 }}>
                <strong>FHIR Config</strong>
            </p>);

        [
            "DataSourceFhirDstu2Proxy",
            "DataSourceAllscriptsDstu2",
            "DataSourceCernerDstu2",
            "DataSourceEpicDstu2",
            "DataSourceFlatironOncoEmrDstu2",
            "DataSourceDstu2ToR4",
            "DataSourceFhirStu3Proxy",
            "DataSourceFhirR4Proxy",
            "DataSourceCernerR4"
        ].includes(this.props.wstate.data.type) && fields.push(
            <TextField key={2}
                name="endpoint" value={this.props.wstate.data.__temp__endpoint || ""}
                placeholder="Endpoint" label="Endpoint" helperText={this.state.dataValidation.endpoint}
                error={!!this.state.dataValidation.endpoint}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetWState((prevWState) => ({
                    isNextWStepEnabled: false,
                    data: {
                        ...prevWState.data,
                        __temp__endpoint: event.target.value,
                        __temp__fhirVersionDetected: "",
                        configuration: {
                            ...prevWState.data.configuration,
                            patientStagingDataRestUrl: getEpicPatientStagingDataEndpointUrl(event.target.value)
                        },
                    },
                }))}
            />);

        [
            "DataSourceFhirDstu2Proxy",
            "DataSourceAllscriptsDstu2",
            "DataSourceCernerDstu2",
            "DataSourceEpicDstu2",
            "DataSourceFhirStu3Proxy",
            "DataSourceFhirR4Proxy",
            "DataSourceCernerR4",
            "DataSourceDstu2ToR4",
        ].includes(this.props.wstate.data.type) && fields.push(
            <TextField key={3}
                name="fhirVersion" value={this.props.wstate.data.__temp__fhirVersionDetected || ""}
                placeholder="FHIR version" label="FHIR Version" helperText={this.state.dataValidation.fhirVersion}
                error={!!this.state.dataValidation.fhirVersion} disabled
                autoComplete="off" autoFocus={false} fullWidth
                classes={{ root: "disabled-text-field" }}
            />);

        [
            "DataSourceFhirDstu2Proxy",
            "DataSourceFhirStu3Proxy",
            "DataSourceFhirR4Proxy",
            "DataSourceDstu2ToR4",
        ].includes(this.props.wstate.data.type) && fields.push(

            <FormControl key={4} style={{ minWidth: 320, margin: "16px 16px 0 0" }}>
                <InputLabel id="data-adapter-auth-type-select-label">
                    Authentication Type
                </InputLabel>
                <Select data-qa="data-adapter-auth-type"
                    labelId="data-adapter-auth-type-select-label"
                    id="data-adapter-auth-type-select"
                    value={this.props.wstate.data.configuration.authenticationType}
                    onChange={(event) => this.props.onSetWState((prevWState) => ({
                        data: {
                            ...prevWState.data,
                            configuration: {
                                ...prevWState.data.configuration,
                                authenticationType: event.target.value,
                            }
                        }
                    }))}
                >
                    <MenuItem data-qa="data-adapter-auth-type-none" value={"NONE"}>
                        None
                    </MenuItem>
                    <MenuItem data-qa="data-adapter-auth-type-smart" value={"SMART_ON_FHIR"}>
                        SMART on FHIR
                    </MenuItem>
                    <MenuItem data-qa="data-adapter-auth-type-oauth" value={"OAUTH2_CLIENT_CREDENTIALS"}>
                        OAuth2 Client Credentials
                    </MenuItem>
                </Select>
            </FormControl>);

        this.props.wstate.data.type === "DataSourceHspc" && fields.push(
            <TextField key={5}
                name = "sandbox-id" value = { this.props.wstate.data.configuration.sandboxId || "" }
                placeholder = "Sandbox ID" label = "Sandbox ID" helperText = {this.state.dataValidation.sandboxId}
                error = {!!this.state.dataValidation.sandboxId}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange = {(event) => this.props.onSetWState((prevWState) => ({
                    isNextWStepEnabled: false,
                    data: {
                        ...prevWState.data,
                        configuration: {
                            ...prevWState.data.configuration,
                            sandboxId: event.target.value,
                        },
                    },
                }))}
            />);

        this.props.wstate.data.type === "DataSourceHspc" && fields.push(
            <FormControlLabel key={6}
                control={<Checkbox
                    checked={!!this.props.wstate.data.configuration.secured}
                    color="primary"
                    onChange={(event) => this.props.onSetWState((prevWState) => ({
                        data: {
                            ...prevWState.data,
                            configuration: {
                                ...prevWState.data.configuration,
                                secured: event.target.checked,
                            },
                        },
                    }))}
                />}
                label="Secured"
            />);

        const message = this.renderMessage({ key: 7 });
        message && fields.push(message);

        this.props.wstate.data.type === "DataSourceEpicDstu2" && fields.push(
            <p key={8} style={{ marginTop: 32 }}>
                <strong>Epic Private API</strong>
            </p>);

        this.props.wstate.data.type === "DataSourceEpicDstu2" && fields.push(
            <FormControl key={9}
                error={!!this.state.dataValidation.patientStagingDataEnabled}
                style={{ display: "block" }}
            >
                <FormControlLabel
                    control={<Switch
                        checked={!!this.props.wstate.data.configuration.patientStagingDataEnabled}
                        color="primary" style={{ marginBottom: "8px" }}
                        onChange={(event) => this.props.onSetWState((prevWState) => ({
                            isNextWStepEnabled: false,
                            data: {
                                ...prevWState.data,
                                configuration: {
                                    ...prevWState.data.configuration,
                                    patientStagingDataEnabled: event.target.checked,
                                },
                            },
                        }))}
                    />}
                    label={`Patient Staging Data (${this.props.wstate.data.configuration.patientStagingDataEnabled ? "Enabled" : "Disabled"})`}
                />
                <FormHelperText>
                    {this.state.dataValidation.patientStagingDataEnabled}
                </FormHelperText>
            </FormControl>);

        this.props.wstate.data.type === "DataSourceEpicDstu2" && this.props.wstate.data.configuration.patientStagingDataEnabled
            && fields.push(
                <TextField key={10}
                    name="epic-patient-staging-data-endpoint" value={this.props.wstate.data.configuration.patientStagingDataRestUrl || ""}
                    placeholder="Patient staging data endpoint" label="Patient Staging Data Endpoint"
                    helperText={this.state.dataValidation.patientStagingDataRestUrl}
                    error={!!this.state.dataValidation.patientStagingDataRestUrl}
                    autoComplete="off" autoFocus={false} fullWidth
                    onChange={(event) => this.props.onSetWState((prevWState) => ({
                        isNextWStepEnabled: false,
                        data: {
                            ...prevWState.data,
                            configuration: {
                                ...prevWState.data.configuration,
                                patientStagingDataRestUrl: event.target.value,
                            }
                        }
                    }))}
                />);

        this.props.wstate.data.type === "DataSourceEpicDstu2" && fields.push(
            <FormControl key={11} style={{ display: "block", marginTop: 16 }}>
                <FormControlLabel
                    control={<Switch
                        checked={!!this.props.wstate.data.configuration.fileClinicalNoteEnabled}
                        color="primary" style={{ marginBottom: "8px" }}
                        onChange={(event) => this.props.onSetWState((prevWState) => ({
                            isNextWStepEnabled: false,
                            data: {
                                ...prevWState.data,
                                configuration: {
                                    ...prevWState.data.configuration,
                                    fileClinicalNoteEnabled: event.target.checked,
                                }
                            },
                        }))}
                    />}
                    label={`File Clinical Note (${this.props.wstate.data.configuration.fileClinicalNoteEnabled ? "Enabled" : "Disabled"})`}
                />
            </FormControl>);

        this.props.wstate.data.type === "DataSourceEpicDstu2" && this.props.wstate.data.configuration.fileClinicalNoteEnabled
            && fields.push(
                <TextField key={12}
                    name="epic-file-clinical-note-endpoint" value={this.props.wstate.data.configuration.fileClinicalNoteRestUrl || ""}
                    placeholder="File clinical note endpoint" label="File clinical Note Endpoint"
                    helperText={this.state.dataValidation.fileClinicalNoteRestUrl}
                    error={!!this.state.dataValidation.fileClinicalNoteRestUrl}
                    autoComplete="off" autoFocus={false} fullWidth
                    onChange={(event) => this.props.onSetWState((prevWState) => ({
                        isNextWStepEnabled: false,
                        data: {
                            ...prevWState.data,
                            configuration: {
                                ...prevWState.data.configuration,
                                fileClinicalNoteRestUrl: event.target.value,
                            },
                        },
                    }))}
                />);

        if (!fields.length) {
            const availableDataAdapters = this.props.wstate?.availableDataAdapters || [];
            const adapter = availableDataAdapters.find((a) => a.type === this.props.wstate.data.type);
            let configProps = adapter?.configurationSchema?.properties || {};

            if (this.props.wstate.data.type === "DataSourceG2R4External") {
                try {
                    const configJSON = JSON.parse(this.props.wstate?.data?.configuration?.json || "{}");
                    if (!configJSON.useG2WebService) {
                        let {apiEndpoint, ...restProps} = adapter?.configurationSchema?.properties || {};
                        configProps = restProps;
                    }
                } catch (e) {
                    // Do nothing
                }
            }

            fields.push(
                <JsonConfig
                    key={13}
                    configProps={configProps}
                    directParentState={this.state}
                    jsonStr={this.props.wstate.data.configuration.json || ""}
                    forceUpdate={() => this.validateData(false)}
                    onSetState={this.props.onSetWState}
                />
            )
        }

        if (this.props.wstate.data.type === "DataSourceFhirR4Proxy") {
            fields.push(
                <JsonConfig
                    key={13}
                    configProps={{
                        systemAuthEnabled: {
                            "type": "boolean"
                        }
                    }}
                    directParentState={this.state}
                    jsonStr={this.props.wstate.data.configuration.json || ""}
                    forceUpdate={() => this.validateData(false)}
                    onSetState={this.props.onSetWState}
                />
            )
        }

        return (
            <>
                {fields}
                <br />
                <ExternalAuth
                    availableDataAdapters={this.props.wstate.availableDataAdapters}
                    canEdit
                    configJsonStr={this.props.wstate.data.configuration?.json || ''}
                    dataSourceId={this.props.wstate.data.dataSourceId}
                    dataSourceType={this.props.wstate.data.type}
                    authTypeInitValue={this.props.wstate.data.type === "DataSourceFhirR4Proxy" ? "BACKEND" : this.props.wstate.data.externalAuth?.authType || ''}
                    clientApiEndpointInitValue={this.props.wstate.data.externalAuth?.clientApiEndpoint || ''}
                    clientIdInitValue={this.props.wstate.data.externalAuth?.clientId || ''}
                    clientSecretInitValue={this.props.wstate.data.externalAuth?.clientSecret || ''}
                    credentialsPasswordInitValue={this.props.wstate.data.externalAuth?.credentials?.[0]?.password || ''}
                    credentialsUserInitValue={this.props.wstate.data.externalAuth?.credentials?.[0]?.user || ''}
                    epicAppNameInitValue={this.props.wstate.data.externalAuth?.epicAppName || ''}
                    epicPublicKeyInitValue={this.props.wstate.data.externalAuth?.epicPublicKey || ''}
                    externalAuthApiIdInitValue={this.props.wstate.data.externalAuth?.externalAuthApiId || ''}
                    passwordInitValue={this.props.wstate.data.externalAuth?.password || ''}
                    usernameInitValue={this.props.wstate.data.externalAuth?.username || ''}
                    tokenEndpointInitValue={this.props.wstate.data.externalAuth?.tokenEndpoint || ''}
                    callback={this.externalAuthCallback}
                />
            </>
        )
    }

    protected externalAuthCallback = (data: Partial<{
        authType: TExternalAuthType
        availableExternalDataAdapters: TDataSourceType[]
        systemAuthEnabled: boolean
        clientApiEndpoint: string
        clientId: string
        clientSecret: string
        credentialsPassword: string
        credentialsUser: string
        dataSourceType: TDataSourceType
        epicAppName: string
        epicPublicKey: string
        externalAuthApiId: string,
        isAllscriptsUnityExt: boolean
        isEpicExt: boolean
        isIKnowMedExt: boolean
        isG2Ext: boolean
        isExternal: boolean
        isGenericExt: boolean
        isVarianR4Ext: boolean
        isR4ProxyExt: boolean
        password: string
        username: string
        tokenEndpoint: string
    }>) => {
        this.props.onSetWState((prevState) => {
            let externalAuth: TExternalAuth = null
            const { accountId } = getHostnameInfo()
            const { env } = getLocationInfo(this.props.location, this.props.config)
            const gatewayId = ''
            const dataSourceId = prevState.data.dataSourceId
            const appId = ''
            if ((data.isEpicExt || data.isR4ProxyExt) && data.systemAuthEnabled && data.authType === 'BACKEND') {
                externalAuth = {
                    accountId,
                    environmentId: env,
                    gatewayId,
                    dataSourceId,
                    appId,
                    authType: data.authType,
                    clientId: data.clientId,
                    epicAppName: data.epicAppName,
                    epicPublicKey: data.epicPublicKey,
                    externalAuthApiId: data.externalAuthApiId,
                    tokenEndpoint: data.tokenEndpoint,
                }
            }
            else if (data.isEpicExt && data.systemAuthEnabled && data.authType === 'EXTERNAL') {
                externalAuth = {
                    accountId,
                    environmentId: env,
                    gatewayId,
                    dataSourceId,
                    appId,
                    authType: data.authType,
                    clientId: data.clientId,
                    clientSecret: data.clientSecret,
                    tokenEndpoint: data.tokenEndpoint,
                    clientApiEndpoint: data.clientApiEndpoint,
                }
            }
            else if ((data.isGenericExt || data.isIKnowMedExt || data.isAllscriptsUnityExt || data.isVarianR4Ext) && data.authType === 'EXTERNAL') {
                externalAuth = {
                    accountId,
                    environmentId: env,
                    gatewayId: '',
                    dataSourceId,
                    appId: '',
                    authType: data.authType,
                    clientId: data.clientId,
                    clientSecret: data.clientSecret,
                    username: data.username,
                    password: data.password,
                    tokenEndpoint: data.tokenEndpoint,
                }
            }
            else if (data.isG2Ext && data.authType === 'EXTERNAL') {
                externalAuth = {
                    accountId,
                    environmentId: env,
                    gatewayId: '',
                    dataSourceId,
                    appId: '',
                    authType: data.authType,
                    clientId: data.clientId,
                    clientSecret: data.clientSecret,
                }
            }
            return {
                data: {
                    ...prevState.data,
                    externalAuth,
                },
            }
        })
    }

    private renderMessage = ({ key }) => {
        const { message = "", type = "" } = this.state.dataValidation?.messageBanner || {};
        const bgndColor = {
            [":RED:"]: this.props.ui.xtheme.palette.colorRedDark,
            [":YELLOW:"]: this.props.ui.xtheme.palette.colorOrangeDark,
        }[type] || "rgb(250, 250, 250)"

        return key && message.length ? <div key={key} style={{
            marginTop: 16,
            padding: 8,
            transform: type ? "scaleY(1)" : "scaleY(0)",
            transformOrigin: "top",
            borderRadius: 2,
            background: bgndColor,
            color: "#fff",
            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)",
        }}>{message}</div> : null;
    }

    private isValid = () => {
        const data = this.state.dataValidation ? Object.keys(this.state.dataValidation) : [];
        return data.length && data.reduce((acc, key) => !!acc && !this.state.dataValidation[key], true);
    }

    private validateData = (asyncCheck) => this.props.onSetWState(
        { isNextWStepEnabled: false },
        () => this.setState(
            {
                dataValidation: {
                    endpoint: "Loading...",
                    fhirVersion: "Loading...",
                    sandboxId: "Loading...",
                    json: "",
                    messageBanner: null,
                }
            },
            async () => {
                const dataValidation = await validateData.onWStep2Async(
                    asyncCheck,
                    this.props.wstate,
                    this.props.config,
                    (fhirVersionDetected = "", authenticationType = "NONE") => asyncCheck &&
                        this.props.onSetWState((prevWState) => ({
                            data: {
                                ...prevWState.data,
                                __temp__fhirVersionDetected: fhirVersionDetected,
                                configuration: {
                                    ...prevWState.data.configuration,
                                    authenticationType,
                                },
                            },
                        })),
                );
                this.setState(
                    { dataValidation },
                    () => {
                        const isValid = this.isValid();
                        if (isValid !== this.props.wstate.isNextWStepEnabled) {
                            this.props.onSetWState({ isNextWStepEnabled: isValid });
                        }
                    },
                );
            },
        ),
    );
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ui: state.ui,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(WStep2))
