import * as React from "react";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../../redux/actions";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import getMessageFromResponse from "../../../../../lib/getMessageFromResponse";
import {getPath} from "../../../../../lib/utils/";
import * as validateData from "../../../../../lib/validate-users-data";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import {Button, TextField, CircularProgress, Card, CardContent, CardHeader, CardActions} from "@material-ui/core";
import {ArrowForward} from "@material-ui/icons";

const jwtDecode = require("jwt-decode");

const INIT_STATE = {
    status: "",
    accountData: {},
    data: {
        firstName: "",
        lastName: "",
        password: "",
        email: "",
        agreeToMail: false,
        latestTosVersion: ""
    },
    dataValidation: {
        firstName: "",
        lastName: "",
        password: "",
        token: ""
    }
};

class Component extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        accountsGetPublicData: PropTypes.func.isRequired,
        loginSet: PropTypes.func.isRequired,
        usersCompleteInvitation: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        const {accountId} = getHostnameInfo();
        if (!accountId) {
            this.props.history.push("/signin");
        }
        this.initialize();
    }

    public componentDidUpdate(_, prevState) {
        if (JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.validateData();
        }
    }

    public render() {
        if (["ready"].indexOf(this.state.status) < 0) {
            return <div className="container" style={{textAlign: "center"}}>
                <CircularProgress size={63} style={{padding: "24px"}}/>
            </div>;
        }

        const {accountId, domain, type} = getHostnameInfo();

        return <div className="container">
            <Card style={{width: "480px", marginTop: "48px", marginRight: "auto", marginLeft: "auto", padding: "28px", borderRadius: "8px"}}>
                <div>
                    <CardHeader
                        title={<h2 style={{textAlign: "center", margin: "4px 0 24px", fontSize: "28px"}}>
                            <span>Join the interopiO® account </span>
                            <span>{this.state.accountData.name}</span>
                        </h2>}
                        subheader={<p style={{color: this.props.muiTheme.palette.textColor, textAlign: "center", fontSize: "16px", fontWeight: 800}}>
                            {`${accountId}.${domain}.${type}`}
                        </p>}
                        style={{paddingBottom: 0}}/>

                    <CardContent style={{paddingTop: 0}}>
                        <div style={{display: "flex"}}>
                            <TextField style={{marginRight: "8px"}} name="firstName" value={this.state.data.firstName || ""} label="First Name" fullWidth
                                error={!!this.state.dataValidation.firstName} helperText={this.state.dataValidation.firstName} disabled={this.state.status === "wait"}
                                onChange={e => {
                                    this.setState({
                                        data: {
                                            ...this.state.data,
                                            firstName: e.target.value
                                        }
                                    });
                                }}/>
                            <TextField style={{marginLeft: "8px"}} name="lastName" value={this.state.data.lastName || ""} label="Last Name" fullWidth
                                error={!!this.state.dataValidation.lastName} helperText={this.state.dataValidation.lastName} disabled={this.state.status === "wait"}
                                onChange={e => {
                                    this.setState({
                                        data: {
                                            ...this.state.data,
                                            lastName: e.target.value
                                        }
                                    });
                                }}/>
                        </div>

                        <TextField name="email" type="email" value={this.state.data.email || ""} label="Email" disabled fullWidth classes={{root: "disabled-text-field"}}
                            style={{cursor: "default"}}/>

                        <TextField name="password" type="password" value={this.state.data.password || ""} label="Password" fullWidth
                            error={!!this.state.dataValidation.password} helperText={this.state.dataValidation.password}
                            disabled={this.state.status !== "ready" || !!this.state.dataValidation.token}
                            onChange={e => {
                                this.setState({
                                    data: {
                                        ...this.state.data,
                                        password: e.target.value
                                    }
                                });
                            }}/>

                        <div style={{
                            marginTop: "16px",
                            padding: this.state.dataValidation.token ? "8px" : 0,
                            transform: this.state.dataValidation.token ? "scaleY(1)" : "scaleY(0)",
                            transformOrigin: "top",
                            borderRadius: "2px",
                            background: this.props.muiTheme.palette.accent1Color,
                            color: "#fff",
                            textAlign: "center",
                            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
                        }}>
                            {this.state.dataValidation.token}
                        </div>
                    </CardContent>

                    <CardActions style={{padding: "16px"}}>
                        <Button variant="contained" color="primary" fullWidth
                            disabled={this.state.status !== "ready" || !this.state.data.firstName || !this.state.data.lastName || !this.state.data.password || !this.isDataValid()}
                            onClick={() => {
                                const token = this.getToken();
                                this.setState(
                                    {status: "wait"},
                                    async () => {
                                        let response = null;
                                        try {
                                            const data = {
                                                first_name: this.state.data.firstName,
                                                last_name: this.state.data.lastName,
                                                password: this.state.data.password,
                                                token,
                                                TOSVersion: this.state.data.latestTosVersion
                                            }
                                            response = await this.props.usersCompleteInvitation(data, {bypassError: true});
                                        } catch (reason) {
                                            return this.setState({
                                                status: "ready",
                                                dataValidation: {
                                                    ...this.state.dataValidation,
                                                    token: getMessageFromResponse(reason, true)
                                                }
                                            });
                                        }
                                        if (response && response.data) {
                                            this.setState(
                                                {status: "ready"},
                                                () => {
                                                    this.props.history.push("/home");
                                                    this.props.loginSet({
                                                        status: "logged-in",
                                                        data: response.data
                                                    });
                                                }
                                            );
                                        }
                                    }
                                );
                            }}>
                            Next <ArrowForward/>
                        </Button>
                    </CardActions>
                    <div style={{fontSize: "12px", padding: "16px", lineHeight: "24px"}}>
                        By continuing, you’re agreeing to our <Link to="/terms/user-terms-of-service" target="_blank" style={{fontSize: "12px", fontWeight: 700, textDecoration: "none"}}>User
                        Terms of Service</Link>, <Link to="/policies/privacy-notice" target="_blank" style={{fontSize: "12px", fontWeight: 700, textDecoration: "none"}}>Privacy
                        Notice</Link>, and <Link to="/policies/cookie-policy" target="_blank" style={{fontSize: "12px", fontWeight: 700, textDecoration: "none"}}>Cookie Policy</Link>.
                    </div>
                </div>
            </Card>
            <br/>
        </div>;
    }

    private getToken = () => (getPath(this.props, "location.search") || "").replace("?token=", "");

    private isDataValid = () => Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true);

    private validateData = () => {
        const dataValidation = validateData.onInvitationConfirm(this.state)
        this.setState({dataValidation});
    }

    private initialize = () => {
        const token = this.getToken();

        this.setState(
            {status: "init"},
            async () => {
                let accountData = null;
                try {
                    [accountData] = await Promise.all([
                        this.props.accountsGetPublicData(),
                        this.props.usersCompleteInvitation({token}, {bypassError: true})
                    ]);
                } catch (reason) {
                    return this.setState({
                        status: "ready",
                        dataValidation: {
                            ...this.state.dataValidation,
                            token: getMessageFromResponse(reason, true)
                        }
                    });
                }
                let latestTosVersion = null;
                this.props.tosGetLatest()
                    .then(terms => {
                        latestTosVersion = terms.data.version;
                        if (accountData && accountData.data) {
                            const email = getPath(jwtDecode(token), "invitedUser.email") || "";
                            this.setState(prevState => ({
                                status: "ready",
                                data: {
                                    ...prevState.data,
                                    email,
                                    latestTosVersion
                                },
                                accountData: accountData.data
                            }));
                        }
                    })
            }
        );
    };
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Component));
