import * as PropTypes from "prop-types";
import { connect } from "react-redux";

import withLegacyTheme from "../../../../../../../../lib/hoc/with-legacy-theme";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import CustomTitle from "../Shared/CustomTitle";

const ViewMode = (props) => {
    const identifierMappings = props.parentState?.data?.identifierMappings || [];
    const cdrs = props.cdrs?.all?.data || [];
    const dataSources = props.dataSources?.all?.data || [];

    return <div>
        {props.showTitle
            ? <CustomTitle identifierMappings={identifierMappings} />
            : null
        }

        <List style={{ marginTop: "16px", marginBottom: "16px" }}>
            {identifierMappings.map((item, index) => {
                const currentCdr = cdrs.find((cdr) => cdr.cdrId === item.cdrSourceId);
                const currentDataSource = dataSources.find((ds) => ds.dataSourceId === item.dataSourceId);
                const currentSource = currentCdr || currentDataSource || {};
                const currentSourceName = currentSource.name || "";

                return <ListItem data-qa={`ds-user-${item.fhirResourceLogicalId}`}
                    key={`${item.fhirResourceLogicalId}-${index}`}
                    disabled
                    style={{ background: index % 2 ? "rgb(235, 235, 235)" : "rgb(245, 245, 245)" }}
                >
                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <div style={{ width: "calc(50% - 100px)", marginRight: "16px" }}>
                            {currentSourceName}
                            <br />
                            <small style={{ color: props.muiTheme.palette.accent3Color }}>
                                {item.dataSourceId || item.cdrSourceId || ""}
                            </small>
                        </div>
                        <div>
                            {`/${props.parentState.data.appUserType !== "PATIENT" ? "Practitioner" : "Patient"}/${item.fhirResourceLogicalId}`}
                        </div>
                    </div>
                </ListItem>
            })}
        </List>
    </div>;
};

ViewMode.propTypes = {
    cdrs: PropTypes.object.isRequired,
    dataSources: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    showTitle: PropTypes.bool.isRequired,
    parentState: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    cdrs: state.cdrs,
    dataSources: state.dataSources,
    ...ownProps,
});
export default connect(mapStateToProps)(withLegacyTheme()(ViewMode));
