import $ from "jquery";

import * as types from "../../actions/types";

import initialState from "./initial-state";

export default function (state = initialState, action: any) {
    switch (action.type) {
        case types.CDRS_ALL_SET: {
            const newState = $.extend(true, {}, state, { all: { status: action.payload.status }});
            newState.all.data = action.payload.data;
            return newState;
        }

        case types.CDRS_SELECTED_SET: {
            const newState = $.extend(true, {}, state, { selected: { status: action.payload.status } });
            newState.selected.data = action.payload.data;
            return newState;
        }

        case types.CDRS_SELECTED_IMPORT: {
            const newState = $.extend(true, {}, state, { selected: { dataManager: { status: action.payload.status } } });
            newState.selected.dataManager.result = action.payload.result;
            return newState;
        }

        default:
            return state;
    }
}
