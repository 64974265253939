import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import {Link} from "react-router-dom";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import getCdsServiceSetsCallUrl from "../../../../lib/getCdsServiceSetsCallUrl";
import {isEnvironmentAdmin} from "../../../../lib/user-environment-permissions-helpers";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import {CardsContainer, CardWrapper, CardBody, CardBodyAddNew, CardMenuBar} from "../../../Widgets/CustomCard/";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {NavbarL2} from "../../../CustomNavbar/";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import Dialogs from "./Dialogs/";
import "./style.less";

const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "waiting" | "w-init" | "error"
    dialog: "", // "" | ":ADD_NEW_WIZARD:" | ":DELETE_CONFIRM:"
    delete: {
        cdsServiceSetId: "",
        name: "",
    },
    data: {},
    wstep: 1,
    gatewayJwkSet: {},
    dataValidation: {},
    isNextWStepEnabled: false,
    // deleteErrorMessage: "",
};

class CdsServiceSetList extends React.Component<any, any> {
    public static propTypes = {
        cdsServiceSets: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        cdsServiceSetsReadAll: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        $(".stage").addClass("stage-bgnd-dark");
        this.initialize();
    }

    public componentWillUnmount() {
        $(".stage").removeClass("stage-bgnd-dark");
    }

    public render() {
        const {env} = getLocationInfo(this.props.location, this.props.config);
        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);

        const data = [...this.props.cdsServiceSets.all.data].sort((a, b) => a.name.localeCompare(b.name));

        return <div className="cds-service-sets">
            <NavbarL2 title={(
                <Link to={`/${env}/cds-service-sets`} style={{color: "#fff", fontSize: 24, textDecoration: "none"}}>
                    CDS Hooks
                </Link>
            )}/>

            <div className="container" style={{paddingTop: 48}}>
                <p style={{margin: "0 0 15px 0", color: this.props.muiTheme.palette.textColor, fontSize: 24, fontWeight: 500, lineHeight: "32px"}}>
                    interopiO® CDS Services Management Console
                </p>
                <p style={{width: 350, margin: "0 0 15px 0", color: this.props.muiTheme.palette.secondaryTextColor, fontSize: 16, fontWeight: 400, lineHeight: "20px"}}>
                    Powerful tools to distribute, activate, monitor, and analyze CDS Hooks™ based services.
                </p>
                <p style={{margin: "0 0 60px 0"}}>
                    <a href="https://support.interopio.com/hc/en-us/categories/360001801431-Getting-Started" target="_blank" style={{margin: "0 15px 0 0"}}>
                        Getting Started
                    </a>
                    <a href="https://support.interopio.com/hc/en-us/categories/360001807451-Using-interopiO-" target="_blank" style={{margin: "0 15px 0 0"}}>
                        Documentation
                    </a>
                    <a href="https://support.interopio.com/hc/en-us" target="_blank" style={{margin: "0 15px 0 0"}}>
                        Support
                    </a>
                </p>

                {["ready", "waiting", "w-init"].includes(this.state.status)
                    ? <CardsContainer>
                        {actAsAdmin
                            ? <CardWrapper data-qa="add-new-cds-card" style={{minHeight: 200}}>
                                <CardBodyAddNew text="Add CDS Service Set" onClick={() => this.setState({dialog: ":ADD_NEW_WIZARD:"})}/>
                            </CardWrapper>
                            : null}

                        {data.map((item, index) => <CardWrapper key={`card-${index}-${item.cdsServiceSetId}`} style={{minHeight: 200}}>
                            <CardMenuBar data-qa={`cds-service-menu-${item.cdsServiceSetId}`} title={item.name} style={{paddingBottom: 0}}
                                onClick={() => this.props.history.push(`/${env}/cds-service-sets/${item.cdsServiceSetId}`)}
                                menuItems={actAsAdmin
                                    ? [
                                        {
                                            leftIcon: <VisibilityIcon/>,
                                            text: "View",
                                            onClick: () => this.props.history.push(`/${env}/cds-service-sets/${item.cdsServiceSetId}`),
                                        },
                                        {
                                            "data-qa": "cds-service-delete-btn",
                                            leftIcon: <DeleteIcon/>,
                                            text: "Delete",
                                            onClick: () => this.setState({
                                                dialog: ":DELETE_CONFIRM:",
                                                delete: {
                                                    cdsServiceSetId: item.cdsServiceSetId,
                                                    name: item.name,
                                                },
                                            }),
                                        },
                                    ]
                                    : []}/>
                            <CardBody text={item.description} style={{minHeight: 160, paddingTop: 0}}
                                onClick={() => this.props.history.push(`/${env}/cds-service-sets/${item.cdsServiceSetId}`)}/>
                        </CardWrapper>
                        )}
                    </CardsContainer>
                    : <CenteredCircularProgress size={63} />}
            </div>
            <br/>

            {["ready", "waiting", "w-init"].includes(this.state.status)
                ? <Dialogs parentState={this.state} initialize={this.initialize} onSetParentState={this.onSetState}/>
                : null}
        </div>;
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private initialize = () => {
        this.setState(
            {status: "init"},
            async () => {
                const {accountId} = getHostnameInfo();
                const {env} = getLocationInfo(this.props.location, this.props.config);
                const url = getCdsServiceSetsCallUrl(accountId, env);
                await this.props.cdsServiceSetsReadAll(this.props.config, url)
                this.setState({
                    ...INIT_STATE,
                    status: "ready",
                    data: INIT_STATE.data,
                });
            },
        );
    };
}

const mapStateToProps = (state, ownProps) => ({
    cdsServiceSets: state.cdsServiceSets,
    config: state.config,
    login: state.login,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsReadAll: actions.cdsServiceSetsReadAll,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(CdsServiceSetList));
