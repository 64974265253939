import * as React from "react";
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as actions from "../../../../redux/actions";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import WStep1 from "./WStep1";
import WStep2 from "./WStep2";
import "./style.less";

const WSTEP_MIN = 1;
const WSTEP_MAX = 2;

const INIT_STATE = {
    data: {
        email: "",
        firstName: "",
        lastName: "",
        agreeToMail: false
    },

    wstep: WSTEP_MIN
};

class AccountsRequestAccessWizard extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public render() {
        return <div className={`${this.state.wstep !== 3 ? "container " : ""} account-create-request`}>
            {this.renderWStep()}
        </div>;
    }

    // Private renders ---------------------------------------------------------
    private renderWStep: Function = () => {
        const propSet = {
            ...this.props,
            state: this.state,
            onSetState: (state: any, cb: any): void => this.setState(state, cb),
            calcNextWStep: this.calcNextWStep
        };

        const wstep = this.state.wstep;
        if (wstep >= WSTEP_MIN || wstep <= WSTEP_MAX) {
            switch (this.state.wstep) {
                case 1:
                    return <WStep1 {...propSet} />;
                case 2:
                    return <WStep2 {...propSet} />;
            }
        }

        return null;
    };

    // Helpers -----------------------------------------------------------------
    private calcNextWStep: Function = () => this.state.wstep < WSTEP_MAX ? this.state.wstep + 1 : WSTEP_MAX;
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(AccountsRequestAccessWizard));
