import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../redux/actions";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import getDataSourcesCallUrl from "../../../../lib/getDataSourcesCallUrl";
import getEnvironmentsCallUrl from "../../../../lib/getEnvironmentsCallUrl";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogActions from "./CustomDialogActions";
import CustomDialogContent from "./CustomDialogContent";
import CustomDialogTitle from "./CustomDialogTitle";

const WSTEP_MIN = 1;
const WSTEP_MAX = 3;

interface IState {
    status: "" | "ready" | "init";
    data: any;
    availableDataAdapters: any[];
    wstep: number;
    isNextWStepEnabled: boolean;
}

export const INIT_STATE: IState = {
    status: "",
    data: {
        __temp__endpoint: "",
        __temp__fhirVersionDetected: "",
        __temp__fhirVersionSelected: ""
    },
    availableDataAdapters: [],
    wstep: WSTEP_MIN,
    isNextWStepEnabled: false,
};

class DSAddNewWizard extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        environments: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        // parentState: PropTypes.object.isRequired,

        dataSourcesGetAvailableDataAdapters: PropTypes.func.isRequired,
        dataSourcesReadAll: PropTypes.func.isRequired,
        environmentsRead: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        // onSetParentState: PropTypes.func.isRequired,
    };

    public constructor(props) {
        super(props);
        this.state = { ...INIT_STATE };
    }

    public componentDidMount() {
        this.initialize();
    }

    public render() {
        return <Dialog
            open
            classes={{paper: "io-modal-wrapper"}}
            style={{ paddingTop: '5px', zIndex: 1250 }}
            disableBackdropClick disableEscapeKeyDown scroll = "body"
            onClose={this.props.onClose}
        >
            <CustomDialogTitle onClose={this.props.onClose} />
            <CustomDialogContent
                wstate={this.state}
                onSetWState={this.onSetState}
            />
            <CustomDialogActions
                wstate={this.state}
                WSTEP_MIN={WSTEP_MIN} WSTEP_MAX={WSTEP_MAX}
                calcNextWStep={this.calcNextWStep}
                calcPrevWStep={this.calcPrevWStep}
                initialize={this.props.initialize}
                onClose={this.props.onClose}
                onSetWState={this.onSetState}
            />
        </Dialog >
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private calcNextWStep = () => this.state.wstep < WSTEP_MAX ? this.state.wstep + 1 : WSTEP_MAX;

    private calcPrevWStep = () => this.state.wstep > WSTEP_MIN ? this.state.wstep - 1 : WSTEP_MIN;

    private initialize = async () => {
        this.setState({ status: "init" });
        const { accountId } = getHostnameInfo();
        const { env } = getLocationInfo(this.props.location, this.props.config);
        const environmentUrl = getEnvironmentsCallUrl(accountId) + "/" + env;
        const dataSourcesUrl = getDataSourcesCallUrl(accountId, env);
        const responses = await Promise.all([
            this.props.environmentsRead(this.props.config, environmentUrl),
            this.props.dataSourcesReadAll(this.props.config, dataSourcesUrl),
            this.props.dataSourcesGetAvailableDataAdapters(accountId)
        ]);
        const [, , { data: availableDataAdapters = INIT_STATE.availableDataAdapters } = {}] = responses;
        const environmentPhi = !!this.props.environments?.selected?.data?.phi;
        this.setState(prevState => ({
            status: "ready",
            data: {
                ...prevState.data,
                accountId,
                environmentId: env,
                phi: environmentPhi ? prevState.data.phi : false,
                configuration: {
                    ...prevState.data.configuration,
                    authenticationType: "NONE",
                    patientStagingDataEnabled: true,
                    secured: true
                }
            },
            availableDataAdapters
        }))
    };
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    environments: state.environments,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    dataSourcesGetAvailableDataAdapters: actions.dataSourcesGetAvailableDataAdapters,
    dataSourcesReadAll: actions.dataSourcesReadAll,
    environmentsRead: actions.environmentsRead,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DSAddNewWizard));
