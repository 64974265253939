import * as PropTypes from "prop-types";
import "./style.less";

const CardsContainer = (props) => {
    return <div className="cards-container" data-qa="cards-container">
        {props.children}
    </div>;
}

CardsContainer.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
};

CardsContainer.defaultProps = {
    className: "",
};

export default CardsContainer;
