import * as PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const StringItem = (props) => {
    const value = props.jsonObj[props.name] || "";

    return <div data-qa="string-item" key={props.name} style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: 16, marginBottom: 6}}>
        <span style={{display: "block", minWidth: 200, marginRight: 6}}>
            {props.name}:
        </span>

        {props.configProps?.[props.name]?.enum ? (
            // use the enum array to create a material ui dropdown
            <TextField
                select
                name={props.name}
                value={value ? value : "null"}
                disabled={!props.canEdit}
                autoComplete="off"
                autoFocus={false}
                fullWidth
                helperText={props.directParentState?.dataValidation?.[props.name]}
                error={!!props.directParentState?.dataValidation?.[props.name]}
                classes={{root: props.canEdit ? "" : "disabled-text-field"}}
                onChange={event => {
                    props.onSetState(prevState => ({
                        isNextWStepEnabled: false,
                        data: {
                            ...prevState.data,
                            configuration: {
                                ...prevState.data.configuration,
                                json: JSON.stringify({
                                    ...props.jsonObj,
                                    [props.name]: event.target.value
                                })
                            }
                        }
                    }));
                }}
            >
                <MenuItem key="default" value={null}>
                    <i>null</i>
                </MenuItem>
                {props.configProps[props.name].enum.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        ) : (
            <TextField name={props.name} value={value} placeholder={props.helperText ? props.helperText : "string"} disabled={!props.canEdit} autoComplete="off" autoFocus={false} fullWidth
                helperText={props.directParentState?.dataValidation?.[props.name]}
                error={!!props.directParentState?.dataValidation?.[props.name]}
                classes={{root: props.canEdit ? "" : "disabled-text-field"}}
                onChange={event => {
                    props.onSetState(prevState => ({
                        isNextWStepEnabled: false,
                        data: {
                            ...prevState.data,
                            configuration: {
                                ...prevState.data.configuration,
                                json: JSON.stringify({
                                    ...props.jsonObj,
                                    [props.name]: event.target.value
                                })
                            }
                        }
                    }));
                }}
            />
        )}
    </div>
};

StringItem.propTypes = {
    canEdit: PropTypes.bool.isRequired,
    configProps: PropTypes.object.isRequired,
    directParentState: PropTypes.object.isRequired,
    helperText: PropTypes.string,
    jsonObj: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default StringItem;
