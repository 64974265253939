import * as PropTypes from "prop-types";
import ActivationAddNewWizard from "./ActivationAddNewWizard/";
import ActivationDeleteConfirm from "./ActivationDeleteConfirm";
import ActivationEdit from "./ActivationEdit";

const Dialogs = (props) => {
    const propSet = {
        parentState: props.parentState,
        initialize: props.initialize,
        onClose: props.initialize,
    };

    switch (props.parentState.dialog) {
        case ":ADD_ACTIVATION:":
            return <ActivationAddNewWizard {...propSet} />;
        case ":DELETE_ACTIVATION_CONFIRM:":
            return <ActivationDeleteConfirm {...propSet} />;
        case ":EDIT_ACTIVATION:":
            return <ActivationEdit {...propSet} />;
        default: return null;
    }
}

Dialogs.propTypes = {
    parentState: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
};

export default Dialogs;
