import axiosWrapper from "./axiosWrapper";
import consoleLogger from "./consoleLogger";
import getImageRepoUrl from "./getImageRepoUrl";

export default function (
    config: any,
    data = {},
) {
    return axiosWrapper(config.envApi, getImageRepoUrl(), "post", data)
        .then((res) => {
            return res;
        })
        .catch((reason) => {
            consoleLogger.log("::: reason:", reason);
        });

}
