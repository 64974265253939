const LOGS = {
    "_embedded": {
        "appsLogsDtoes": [
            {
                "id": "qGAbX3cBp17bjgkpGmBl",
                "timestamp": 1612208347746.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Access token created.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "TOKEN_CREATED"
            },
            {
                "id": "rS0bX3cB3T2a3n8sGREf",
                "timestamp": 1612208347424.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "/token endpoint reached and authenticated, generating access token.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "TOKEN_ENDPOINT_REACHED"
            },
            {
                "id": "3G0bX3cB9ubOb3IkFAeY",
                "timestamp": 1612208346265.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Validated that client ebbe7c9f-14a1-4fcb-8fb4-e0d2f456a13a is activated on gateway https://interopio.com/gateway/fhir/flatiron/uat-env/flatiron-hspc-dstu",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "AUTHORIZE_GATEWAY_ACTIVATION_VALIDATED"
            },
            {
                "id": "1CQbX3cBjewRHocHFJUG",
                "timestamp": 1612208346116.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Reached /authorize endpoint.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "AUTHORIZE_ENDPOINT_REACHED"
            },
            {
                "id": "p2AbX3cBp17bjgkpE2D6",
                "timestamp": 1612208346102.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Successfully authenticated proxy session 2aeb1e88-791e-4576-a955-ec2e6c3f4061 to user proxy|2aeb1e88-791e-4576-a955-ec2e6c3f4061/flatiron/uat-env/flatiron-hspc-dstu/examplePersona@FlatironPOC",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "AUTHORIZE_PROXY_AUTH_SUCCESS"
            },
            {
                "id": "JKcbX3cBWkI85EP8DxWG",
                "timestamp": 1612208344963.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Async method to update proxy session and add launch user completed.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "REDIRECT_ASYNC_UPDATES"
            },
            {
                "id": "0yQbX3cBjewRHocHD5Uu",
                "timestamp": 1612208344879.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Redirecting to downstream app at URL: https://flatiron-poc-uat.interopion.com/api/v1/launch?iss=https://interopio.com/gateway/fhir/flatiron/uat-env/flatiron-hspc-dstu&launch=9d35af56-973c-4591-9dd6-4369f4cf98ef",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "d69ad1362cbb01b4",
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "REDIRECT_ABOUT_TO_REDIRECT"
            },
            {
                "id": "220bX3cB9ubOb3IkDwdK",
                "timestamp": 1612208344879.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Successfully registered launch context for session 2aeb1e88-791e-4576-a955-ec2e6c3f4061 with id: 9d35af56-973c-4591-9dd6-4369f4cf98ef",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "d69ad1362cbb01b4",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "REDIRECT_LAUNCH_CONTEXT_REGISTRATION_SUCCESS"
            },
            {
                "id": "rC0bX3cB3T2a3n8sDxEt",
                "timestamp": 1612208344874.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Launch context created by smart_proxy_admin with launch id 9d35af56-973c-4591-9dd6-4369f4cf98ef",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_REGISTERED"
            },
            {
                "id": "0iQbX3cBjewRHocHDpXo",
                "timestamp": 1612208344805.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Successfully retrieved access token for gateway Flatiron HSPC DSTU2 and proxy session 2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "d69ad1362cbb01b4",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "REDIRECT_ACCESS_TOKEN_FETCHED"
            },
            {
                "id": "I6cbX3cBWkI85EP8CxXL",
                "timestamp": 1612208344012.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Successfully reached redirect endpoint with authorization code: rGjlco. Attempting to exchange for access token at endpoint: https://auth.logicahealth.org/token",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "d69ad1362cbb01b4",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "REDIRECT_ENDPOINT_REACHED"
            },
            {
                "id": "iCsbX3cByZMvOB5aBSZL",
                "timestamp": 1612208342201.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Proxy session updated, preparing to redirect to external authorize endpoint: https://auth.logicahealth.org/authorize",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "cff73dc91a89507f",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_PREPARING_TO_REDIRECT_TO_EXTERNAL_AUTHORIZE"
            },
            {
                "id": "qy0bX3cB3T2a3n8sBBGl",
                "timestamp": 1612208342181.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Successfully retrieved external metatdata for iss https://api.logicahealth.org/FlatironPOC/data",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "cff73dc91a89507f",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_EXTERNAL_METADATA_FETCHED"
            },
            {
                "id": "IqcbX3cBWkI85EP8ARXw",
                "timestamp": 1612208341489.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Launch validated, attempting to fetch external /metadata.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "cff73dc91a89507f",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_VALIDATED"
            },
            {
                "id": "0SQbX3cBjewRHocHAZXy",
                "timestamp": 1612208341487.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "http_error_code": null,
                "body": "Launch endpoint reached, new proxy session created with id: 2aeb1e88-791e-4576-a955-ec2e6c3f4061",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "cff73dc91a89507f",
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Ym0SX3cB9ubOb3IknQf3",
                "timestamp": 1612207791607.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Access token created.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "TOKEN_CREATED"
            },
            {
                "id": "J2ASX3cBp17bjgkpnGBI",
                "timestamp": 1612207791173.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "/token endpoint reached and authenticated, generating access token.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "TOKEN_ENDPOINT_REACHED"
            },
            {
                "id": "DCsSX3cByZMvOB5alyaf",
                "timestamp": 1612207789983.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Validated that client ebbe7c9f-14a1-4fcb-8fb4-e0d2f456a13a is activated on gateway https://interopio.com/gateway/fhir/flatiron/uat-env/flatiron-hspc-dstu",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "AUTHORIZE_GATEWAY_ACTIVATION_VALIDATED"
            },
            {
                "id": "s6cSX3cBWkI85EP8lxRe",
                "timestamp": 1612207789915.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Reached /authorize endpoint.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "AUTHORIZE_ENDPOINT_REACHED"
            },
            {
                "id": "MS0SX3cB3T2a3n8slxFD",
                "timestamp": 1612207789892.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Successfully authenticated proxy session 51946b21-7649-48a5-ad5e-87f51d54c887 to user proxy|51946b21-7649-48a5-ad5e-87f51d54c887/flatiron/uat-env/flatiron-hspc-dstu/examplePersona@FlatironPOC",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "AUTHORIZE_PROXY_AUTH_SUCCESS"
            },
            {
                "id": "sqcSX3cBWkI85EP8khR-",
                "timestamp": 1612207788670.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Async method to update proxy session and add launch user completed.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "REDIRECT_ASYNC_UPDATES"
            },
            {
                "id": "JmASX3cBp17bjgkpkmAx",
                "timestamp": 1612207788594.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Redirecting to downstream app at URL: https://flatiron-poc-uat.interopion.com/api/v1/launch?iss=https://interopio.com/gateway/fhir/flatiron/uat-env/flatiron-hspc-dstu&launch=585fc41b-2bf3-463e-9594-8ed57272386b",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "2d3dbc4afca9e9c3",
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "REDIRECT_ABOUT_TO_REDIRECT"
            },
            {
                "id": "WSQSX3cBjewRHocHkpUh",
                "timestamp": 1612207788578.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Successfully registered launch context for session 51946b21-7649-48a5-ad5e-87f51d54c887 with id: 585fc41b-2bf3-463e-9594-8ed57272386b",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "2d3dbc4afca9e9c3",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "REDIRECT_LAUNCH_CONTEXT_REGISTRATION_SUCCESS"
            },
            {
                "id": "WiQSX3cBjewRHocHkpUl",
                "timestamp": 1612207788577.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Launch context created by smart_proxy_admin with launch id 585fc41b-2bf3-463e-9594-8ed57272386b",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_REGISTERED"
            },
            {
                "id": "CysSX3cByZMvOB5akSaC",
                "timestamp": 1612207788415.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Successfully retrieved access token for gateway Flatiron HSPC DSTU2 and proxy session 51946b21-7649-48a5-ad5e-87f51d54c887",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "2d3dbc4afca9e9c3",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "REDIRECT_ACCESS_TOKEN_FETCHED"
            },
            {
                "id": "YW0SX3cB9ubOb3Ikjgdw",
                "timestamp": 1612207787628.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Successfully reached redirect endpoint with authorization code: 32r9ex. Attempting to exchange for access token at endpoint: https://auth.logicahealth.org/token",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "2d3dbc4afca9e9c3",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "REDIRECT_ENDPOINT_REACHED"
            },
            {
                "id": "YG0SX3cB9ubOb3IkiQeu",
                "timestamp": 1612207786414.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Proxy session updated, preparing to redirect to external authorize endpoint: https://auth.logicahealth.org/authorize",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "d3a5f35360690186",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_PREPARING_TO_REDIRECT_TO_EXTERNAL_AUTHORIZE"
            },
            {
                "id": "WCQSX3cBjewRHocHiZWL",
                "timestamp": 1612207786376.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Successfully retrieved external metatdata for iss https://api.logicahealth.org/FlatironPOC/data",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "d3a5f35360690186",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_EXTERNAL_METADATA_FETCHED"
            },
            {
                "id": "CisSX3cByZMvOB5ahyad",
                "timestamp": 1612207785835.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Launch endpoint reached, new proxy session created with id: 51946b21-7649-48a5-ad5e-87f51d54c887",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "d3a5f35360690186",
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Ly0SX3cB3T2a3n8shxFq",
                "timestamp": 1612207785835.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "51946b21-7649-48a5-ad5e-87f51d54c887",
                "http_error_code": null,
                "body": "Launch validated, attempting to fetch external /metadata.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "d3a5f35360690186",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_VALIDATED"
            },
            {
                "id": "kit-XncByZMvOB5aViLM",
                "timestamp": 1612198074056.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Access token created.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "TOKEN_CREATED"
            },
            {
                "id": "2C1-XncB3T2a3n8sVQ0U",
                "timestamp": 1612198073620.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "/token endpoint reached and authenticated, generating access token.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "TOKEN_ENDPOINT_REACHED"
            },
            {
                "id": "byR-XncBjewRHocHSpFU",
                "timestamp": 1612198070864.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Validated that client 5b04781e-19ea-4290-89c0-d544f79f297b is activated on gateway https://interopio.com/gateway/fhir/flatiron/uat-env/flatiron-hspc-dstu",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "AUTHORIZE_GATEWAY_ACTIVATION_VALIDATED"
            },
            {
                "id": "QKd-XncBWkI85EP8SRHY",
                "timestamp": 1612198070744.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Reached /authorize endpoint.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "AUTHORIZE_ENDPOINT_REACHED"
            },
            {
                "id": "1y1-XncB3T2a3n8sSQ28",
                "timestamp": 1612198070712.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Successfully authenticated proxy session e5c57f2e-d3aa-4596-83be-1b391b7d9287 to user proxy|e5c57f2e-d3aa-4596-83be-1b391b7d9287/flatiron/uat-env/flatiron-hspc-dstu/examplePersona@FlatironPOC",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "AUTHORIZE_PROXY_AUTH_SUCCESS"
            },
            {
                "id": "8W1-XncB9ubOb3IkQAMq",
                "timestamp": 1612198068262.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Async method to update proxy session and add launch user completed.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "REDIRECT_ASYNC_UPDATES"
            },
            {
                "id": "8G1-XncB9ubOb3IkPwPX",
                "timestamp": 1612198068173.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Launch context created by smart_proxy_admin with launch id 7026dbc8-79b0-49df-bfe8-23bbee767a74",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_REGISTERED"
            },
            {
                "id": "qWB-XncBp17bjgkpP1zJ",
                "timestamp": 1612198068169.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Successfully registered launch context for session e5c57f2e-d3aa-4596-83be-1b391b7d9287 with id: 7026dbc8-79b0-49df-bfe8-23bbee767a74",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "c0a25e7f261632c1",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "REDIRECT_LAUNCH_CONTEXT_REGISTRATION_SUCCESS"
            },
            {
                "id": "bSR-XncBjewRHocHP5HF",
                "timestamp": 1612198068160.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Redirecting to downstream app at URL: https://flatironpocapp.interopion.local:19000/api/v1/launch?iss=https://interopio.com/gateway/fhir/flatiron/uat-env/flatiron-hspc-dstu&launch=7026dbc8-79b0-49df-bfe8-23bbee767a74",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "c0a25e7f261632c1",
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "REDIRECT_ABOUT_TO_REDIRECT"
            },
            {
                "id": "1i1-XncB3T2a3n8sPw1t",
                "timestamp": 1612198068077.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Successfully retrieved access token for gateway Flatiron HSPC DSTU2 and proxy session e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "c0a25e7f261632c1",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "REDIRECT_ACCESS_TOKEN_FETCHED"
            },
            {
                "id": "1S1-XncB3T2a3n8sPA2D",
                "timestamp": 1612198067327.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Successfully reached redirect endpoint with authorization code: Em90Ha. Attempting to exchange for access token at endpoint: https://auth.logicahealth.org/token",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "c0a25e7f261632c1",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "REDIRECT_ENDPOINT_REACHED"
            },
            {
                "id": "P6d-XncBWkI85EP8OBF1",
                "timestamp": 1612198066293.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Proxy session updated, preparing to redirect to external authorize endpoint: https://auth.logicahealth.org/authorize",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "570dfe9facd421ae",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_PREPARING_TO_REDIRECT_TO_EXTERNAL_AUTHORIZE"
            },
            {
                "id": "qGB-XncBp17bjgkpOFxi",
                "timestamp": 1612198066270.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Successfully retrieved external metatdata for iss https://api.logicahealth.org/FlatironPOC/data",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "570dfe9facd421ae",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_EXTERNAL_METADATA_FETCHED"
            },
            {
                "id": "bCR-XncBjewRHocHNZGw",
                "timestamp": 1612198065574.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Launch endpoint reached, new proxy session created with id: e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "570dfe9facd421ae",
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "721-XncB9ubOb3IkNQOm",
                "timestamp": 1612198065574.000000000,
                "app_id": "fa-local",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "e5c57f2e-d3aa-4596-83be-1b391b7d9287",
                "http_error_code": null,
                "body": "Launch validated, attempting to fetch external /metadata.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": "570dfe9facd421ae",
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_VALIDATED"
            },
            {
                "id": "xC1lXncB3T2a3n8sEQx5",
                "timestamp": 1612196417913.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "47940229-2e3e-4f08-9770-98da2963dc55",
                "http_error_code": null,
                "body": "Access token created.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "TOKEN_CREATED"
            },
            {
                "id": "QKdlXncBWkI85EP8DxDy",
                "timestamp": 1612196417516.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "47940229-2e3e-4f08-9770-98da2963dc55",
                "http_error_code": null,
                "body": "/token endpoint reached and authenticated, generating access token.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "TOKEN_ENDPOINT_REACHED"
            },
            {
                "id": "wy1lXncB3T2a3n8sDAzQ",
                "timestamp": 1612196416716.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "47940229-2e3e-4f08-9770-98da2963dc55",
                "http_error_code": null,
                "body": "Validated that client ebbe7c9f-14a1-4fcb-8fb4-e0d2f456a13a is activated on gateway https://interopio.com/gateway/fhir/flatiron/uat-env/flatiron-hspc-dstu",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "AUTHORIZE_GATEWAY_ACTIVATION_VALIDATED"
            },
            {
                "id": "3W1lXncB9ubOb3IkDAJD",
                "timestamp": 1612196416579.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "47940229-2e3e-4f08-9770-98da2963dc55",
                "http_error_code": null,
                "body": "Reached /authorize endpoint.",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "INFO",
                "system_log_event": "AUTHORIZE_ENDPOINT_REACHED"
            },
            {
                "id": "nGBlXncBp17bjgkpDFss",
                "timestamp": 1612196416552.000000000,
                "app_id": "poc-app",
                "account_id": "flatiron",
                "data_adapter_id": null,
                "environment_id": "uat-env",
                "gateway_id": "flatiron-hspc-dstu",
                "session_id": "47940229-2e3e-4f08-9770-98da2963dc55",
                "http_error_code": null,
                "body": "Successfully authenticated proxy session 47940229-2e3e-4f08-9770-98da2963dc55 to user proxy|47940229-2e3e-4f08-9770-98da2963dc55/flatiron/uat-env/flatiron-hspc-dstu/examplePersona@FlatironPOC",
                "contains_phi": false,
                "ip_address": null,
                "user_agent": null,
                "metadata": null,
                "labels": null,
                "log_level": "DEBUG",
                "system_log_event": "AUTHORIZE_PROXY_AUTH_SUCCESS"
            }
        ]
    },
    "_links": {
        "first": {
            "href": "https://flatiron.interopio.com/logging/apps/flatiron/uat-env/gateway/flatiron-hspc-dstu/logs?startTime=1604484168522&page=0&size=50"
        },
        "self": {
            "href": "https://flatiron.interopio.com/logging/apps/flatiron/uat-env/gateway/flatiron-hspc-dstu/logs?startTime=1604484168522&page=0&size=50"
        },
        "next": {
            "href": "https://flatiron.interopio.com/logging/apps/flatiron/uat-env/gateway/flatiron-hspc-dstu/logs?startTime=1604484168522&page=1&size=50"
        },
        "last": {
            "href": "https://flatiron.interopio.com/logging/apps/flatiron/uat-env/gateway/flatiron-hspc-dstu/logs?startTime=1604484168522&page=405&size=50"
        }
    },
    "page": {
        "size": 50,
        "totalElements": 20275,
        "totalPages": 406,
        "number": 0
    }
}

export default LOGS
