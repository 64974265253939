import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { createMachine, assign } from 'xstate'
import { useMachine } from '@xstate/react'
import Dialog from '@material-ui/core/Dialog'
import axiosWrapper from '../../../../../../lib/axiosWrapper'
import convertToUrlFriendly from '../../../../../../lib/convertToUrlFriendly'
import getHostnameInfo from '../../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../../lib/getLocationInfo'
import * as validateId from '../../../../../../lib/validate-id'
import CustomDialogActions from './CustomDialogActions'
import CustomDialogContent from './CustomDialogContent'
import CustomDialogTitle from './CustomDialogTitle'

type TProps = {
    config: any,
    history: any,
    location: any,
    onClose: () => void
    onReinit: () => void
}

type TContextUserDirSlim = {
    directoryId: string
    name: string
    description: string
    directoryType: 'INTEROPIO_USERS' | 'DELEGATED_EHR_USERS'
}

export type TContext = {
    data: {
        accountId: string
        environmentId: string
        name: string
        directoryId: string
        description: string
        directoryType: 'INTEROPIO_USERS'
    }
    dataValidation: Partial<{
        name: string
        directoryId: string
    }>
    userDirsSlim: TContextUserDirSlim[]
}

type TEvent = { type: 'SET_NAME'; name: string }
    | { type: 'SET_DIRECTORY_ID'; directoryId: string }
    | { type: 'SET_DESCRIPTION'; description: string }
    | { type: 'SAVE' }

const UserDirSetAddNewWizard = (props: TProps) => {
    const { accountId } = getHostnameInfo()
    const { env } = getLocationInfo(props.location, props.config)

    function isDataValid(context: TContext) {
        return Object.values(context.dataValidation)
            .reduce((acc, value) => acc && !value, true)
    }

    const [state, send] = useMachine(() => createMachine({
        id: 'user-dir-add-new-wizard',
        tsTypes: {} as import('./index.typegen').Typegen0,
        schema: {
            events: {} as TEvent,
            context: {} as TContext,
        },
        context: {
            data: {
                accountId: accountId,
                environmentId: env,
                name: '',
                directoryId: '',
                description: '',
                directoryType: 'INTEROPIO_USERS',
            },
            dataValidation: {
                name: '',
                directoryId: '',
            },
            userDirsSlim: [],
        },
        initial: 'init',
        states: {
            init: {
                invoke: {
                    src: 'init',
                    onDone: {
                        target: 'basic_info',
                        actions: assign({
                            userDirsSlim: (_, event) => event.data?.data || [],
                        }),
                    },
                    onError: 'basic_info',
                },
            },
            basic_info: {
                entry: 'validateDataBasicInfo',
                on: {
                    SET_NAME: {
                        actions: [
                            'setName',
                            'validateDataBasicInfo',
                        ],
                    },
                    SET_DIRECTORY_ID: {
                        actions: [
                            'setDirectoryId',
                            'validateDataBasicInfo',
                        ],
                    },
                    SET_DESCRIPTION: {
                        actions: 'setDescription',
                    },
                    SAVE: {
                        target: 'saving',
                        cond: 'isDataValid',
                    },
                },
            },
            saving: {
                invoke: {
                    src: 'save',
                    onDone: 'basic_info',
                    onError: 'basic_info',
                },
            },
        },
    }, {
        actions: {
            setName: assign({
                data: (context, event) => ({
                    ...context.data,
                    name: event.name,
                    directoryId: convertToUrlFriendly(event.name.substring(0, 18)),
                }),
            }),
            setDirectoryId: assign({
                data: (context, event) => ({
                    ...context.data,
                    directoryId: convertToUrlFriendly(event.directoryId.substring(0, 18)),
                }),
            }),
            setDescription: assign({
                data: (context, event) => ({
                    ...context.data,
                    description: event.description,
                }),
            }),
            validateDataBasicInfo: assign({
                dataValidation: (context) => {
                    const name = context.data.name ? '' : 'Required field'

                    const ids = context.userDirsSlim.map((item) => item.directoryId)
                    let directoryId = ''
                    directoryId = validateId.isUrlFriendly(context.data.directoryId) ? directoryId : 'The ID can only contain: lower case letters of the English alphabet, numbers (0-9), hyphen/minus sign (-), it must start with a letter and end with a letter or number, not a hyphen'
                    directoryId = validateId.isNotTooLong(context.data.directoryId) ? directoryId : 'This ID is too long'
                    directoryId = validateId.isNotTooShort(context.data.directoryId) ? directoryId : 'This ID is too short'
                    directoryId = context.data.directoryId ? directoryId : 'Required field'
                    directoryId = validateId.isUnique(context.data.directoryId, ids) ? directoryId : 'This ID already exists'
                    directoryId = validateId.isNotRestricted(context.data.directoryId, props.config.reservedIds) ? directoryId : 'This ID is reserved'

                    return {
                        name,
                        directoryId,
                    }
                },
            }),
        },
        guards: {
            isDataValid: (context) => isDataValid(context),
        },
        services: {
            init: () => {
                const udsSlimUrl = `api/${accountId}/env/${env}/user-directory-slim`
                return axiosWrapper(props.config.envApi, udsSlimUrl, 'GET')
            },
            save: async (context) => {
                const userDirUrl = `api/${accountId}/env/${env}/user-directory/${context.data.directoryId}`
                const res = await axiosWrapper(props.config.envApi, userDirUrl, 'PUT', context.data)
                if (res?.status < 300 && res?.data) {
                    props.onReinit()
                    props.history.push(`/${env}/auth/${res.data.directoryId}`)
                }
            },
        },
    }))

    return (
        <Dialog
            open
            classes={{paper: "io-modal-wrapper"}}
            style={{ paddingTop: '5px', zIndex: 1250 }}
            disableBackdropClick
            disableEscapeKeyDown
            scroll="body"
            onClose={props.onClose}
        >
            <CustomDialogTitle onClose={props.onClose} />
            <CustomDialogContent
                send={send}
                state={state}
            />
            <CustomDialogActions
                state={state}
                onClose={props.onClose}
                onSave={() => send('SAVE')}
            />
        </Dialog >
    )
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(UserDirSetAddNewWizard))
