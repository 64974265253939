import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {isEnvironmentAdmin} from "../../../../../lib/user-environment-permissions-helpers";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import {Search, Table} from "../../../../Widgets/Table";
import ActivationItem from "../Shared/ActivationItem";

const Activations = (props) => {
    const leftFilters = [
        {
            type: "component",
            value: <Search key={1} setFilterItem={(_, activation) => props.onSetParentState({ filters: { ...props.parentState.filters, activation } })} hint="Search activation" />
        }
    ];

    const rightFilters = [];
    const actAsAdmin = isEnvironmentAdmin(props.login, props.location, props.config);
    if (actAsAdmin) {
        const gateways = props.gateways.all?.data || [];
        const activationsAll = props.cdsServiceSets?.selected?.data?.activations || [];
        const activationsAllGatewayIds = activationsAll.map((activation) => activation.gatewayId);
        const filteredGateways = gateways.filter((gateway) => !activationsAllGatewayIds.includes(gateway.gatewayId));
        const hasAnyFilteredGateways = !!filteredGateways.length;
        rightFilters.push({
            type: "component",
            value: <Button key={1} variant="contained" color="primary"
                disabled={!hasAnyFilteredGateways}
                style={{ top: "5px", marginRight: "16px" }}
                onClick={() => props.onSetParentState({ dialog: ":ADD_ACTIVATION:" })}
            >Add Activation</Button>
        });
    }

    const columns = [
        {
            size: 1,
            name: "name",
            label: "Activation",
            sort: () => props.onSetParentState({ sortBy: { name: !props.parentState.sortBy?.name } }),
            order: props.parentState.sortBy?.name,
        },
        {
            size: 2.7,
            name: "gateway",
            label: "Gateway"
        }
    ];

    const activations = props.parentState.activations || [];

    return <Table
        leftFilters={leftFilters}
        rightFilters={rightFilters}
        columns={columns}
    >
        <List disablePadding>
            {activations.map((activation, index) => (
                <ActivationItem
                    key={`activation-item-${activation.cdsServiceSetId}-${index}`}
                    parentState={props.parentState}
                    activation={activation}
                    clickable showMenu
                    initialize={props.initialize}
                    onSetParentState={props.onSetParentState}
                />
            ))}
        </List>
    </Table>;
}

Activations.propTypes = {
    cdsServiceSets: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    gateways: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    cdsServiceSets: state.cdsServiceSets,
    config: state.config,
    gateways: state.gateways,
    login: state.login,
    ...ownProps,
});
export default withRouter(connect(mapStateToProps)(Activations));
