import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../redux/actions";
import { withRouter } from "react-router";
import getCdsServiceSetsCallUrl from "../../../../../lib/getCdsServiceSetsCallUrl";
import Button from "@material-ui/core/Button";

const ActionButtons = (props) => {
    const canEdit = props.parentState.mode === ":EDIT:";
    const isValid = Object.keys(props.parentState.dataValidation).reduce((acc, key) => !!acc && !props.parentState.dataValidation[key], true);

    return <div style={{
        height: canEdit ? "auto" : 0,
        padding: canEdit ? 24 : "0 24px 0 0",
        textAlign: "right",
        opacity: canEdit ? 1 : 0,
        transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)",
    }}>
        <Button disabled={props.parentState.status !== "ready" || !canEdit} style={{ display: canEdit ? "inline-block" : "none", marginRight: 8 }}
            onClick={() => props.onSetParentState({ mode: ":VIEW:" }, props.initialize)}
        >
            Cancel
        </Button>
        <Button variant="contained" color="primary"
            disabled={props.parentState.status !== "ready" || !canEdit || !isValid || !props.parentState.data.cdsServices || !Array.isArray(props.parentState.data.cdsServices) || !props.parentState.data.cdsServices.length}
            style={{ display: canEdit ? "inline-block" : "none" }}
            onClick={async () => {
                props.onSetParentState({ status: "waiting" });

                const accountId = props.parentState.data.accountId;
                const activations = props.parentState.data.activations;
                const environmentId = props.parentState.data.environmentId;
                const version = props.parentState.data.version;
                const name = props.parentState.data.name;
                const cdsServiceSetId = props.parentState.data.cdsServiceSetId;
                const description = props.parentState.data.description || "";
                const sourceUrl = props.parentState.data.sourceUrl || "";
                const cdsServices = props.parentState.data.cdsServices || [];
                const data = {
                    accountId,
                    activations,
                    environmentId,
                    version,
                    name,
                    cdsServiceSetId,
                    description,
                    sourceUrl,
                    cdsServices
                };

                const cdsServiceSetUrl = getCdsServiceSetsCallUrl(accountId, environmentId) + `/${cdsServiceSetId}`;
                const response = await props.cdsServiceSetsUpdate(props.config, cdsServiceSetUrl, data);
                if (response && response.status < 300 && response.data) {
                    props.onSetParentState({ mode: ":VIEW:" }, props.initialize);
                }
            }}
        >
            Save
        </Button>
    </div>
}

ActionButtons.propTypes = {
    config: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    cdsServiceSetsUpdate: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsUpdate: actions.cdsServiceSetsUpdate,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActionButtons));
