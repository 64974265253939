import * as React from "react";
import * as PropTypes from "prop-types";
import * as permHelpers from "../../../../../../../../lib/user-environment-permissions-helpers";
import {getPath} from "../../../../../../../../lib/utils/";
import {Chip, ListItem} from "@material-ui/core";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    const environmentId = getPath(props, "environment.environmentId") || "--";
    const environmentName = getPath(props, "environment.name") || environmentId;
    const userEnvironmentPermissions = getPath(props, "dialogState.userEnvironmentPermissions")
    const hasAccessNone = permHelpers.hasAccessNone(userEnvironmentPermissions, environmentId);
    const hasAccessAnalytics = permHelpers.hasAccessAnalytics(userEnvironmentPermissions, environmentId);
    const hasAccessAdmin = permHelpers.hasAccessAdmin(userEnvironmentPermissions, environmentId);

    return <ListItem disabled className="environment-ul-item" style={{opacity: props.dialogState.status !== "ready" ? 0.33 : 1}}>
        <div className="content">
            <div className="col-1">
                {environmentName}
                <br/>
                <small style={{color: props.muiTheme.palette.secondaryTextColor}}>
                    {environmentId}
                </small>
            </div>

            <div className="col-2">
                <Chip label={<span style={{padding: "2px 8px", lineHeight: "16px", color: hasAccessNone ? "#fff" : props.muiTheme.palette.accent3Color}}>None</span>}
                    data-qa={`users-permissions-env-permissions-none-chip-${environmentId}`}
                    style={{cursor: "pointer", backgroundColor: hasAccessNone ? props.muiTheme.palette.accent3Color : ""}}
                    onClick={setAccess(props.onSetDialogState, environmentId, null)}/>
                <Chip label={<span style={{padding: "2px 8px", lineHeight: "16px", color: hasAccessAnalytics ? "#fff" : props.muiTheme.palette.accent3Color}}>Analytics</span>}
                    data-qa={`users-permissions-env-permissions-analytics-chip-${environmentId}`}
                    style={{cursor: "pointer", backgroundColor: hasAccessAnalytics ? props.ui.xtheme.palette.colorBlueLight : ""}}
                    onClick={setAccess(props.onSetDialogState, environmentId, "analytics")}/>
                <Chip label={<span style={{padding: "2px 8px", lineHeight: "16px", color: hasAccessAdmin ? "#fff" : props.muiTheme.palette.accent3Color}}>Admin</span>}
                    data-qa={`users-permissions-env-permissions-admin-chip-${environmentId}`}
                    style={{cursor: "pointer", backgroundColor: hasAccessAdmin ? props.ui.xtheme.palette.colorRedLight : ""}}
                    onClick={setAccess(props.onSetDialogState, environmentId, "admin")}/>
            </div>
        </div>
    </ListItem>;
}

// Event handlers --------------------------------------------------------------
function setAccess(onSetDialogState, environmentId, access) {
    return function () {
        onSetDialogState((prevState) => {
            let userEnvironmentPermissions = permHelpers.deletePermissionByEnvironmentId(prevState.userEnvironmentPermissions, environmentId);
            access && (userEnvironmentPermissions = permHelpers.addPermission(userEnvironmentPermissions, environmentId, access));
            return {userEnvironmentPermissions};
        });
    };
}

Component.propTypes = {
    dialogState: PropTypes.object.isRequired,
    environment: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    onSetDialogState: PropTypes.func.isRequired
};

export default Component;
