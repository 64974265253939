import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { createMachine } from 'xstate'
import { useMachine } from '@xstate/react'
import Dialog from '@material-ui/core/Dialog'
import axiosWrapper from '../../../../../../lib/axiosWrapper'
import getHostnameInfo from '../../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../../lib/getLocationInfo'
import type { TContext as TParentContext } from '../..'
import CustomDialogActions from './CustomDialogActions'
import CustomDialogContent from './CustomDialogContent'
import CustomDialogTitle from './CustomDialogTitle'

type TProps = {
    config: any,
    location: any,
    parentContext: TParentContext
    onClose: () => void
    onReinit: () => void
}

export type TContext = {}

type TEvent = { type: 'CONFIRM' }

const UserDirDeleteConfirm = (props: TProps) => {
    const [state, send] = useMachine(() => createMachine({
        id: 'user-dir-delete-confirm',
        tsTypes: {} as import('./index.typegen').Typegen0,
        schema: {
            events: {} as TEvent,
            context: {} as TContext,
        },
        context: {},
        initial: 'idle',
        states: {
            idle: {
                on: {
                    CONFIRM: 'deleting',
                },
            },
            deleting: {
                invoke: {
                    src: 'delete',
                    onDone: {
                        target: 'done',
                        actions: [] as any[],
                    },
                    onError: 'done',
                },
            },
            done: {
                entry: 'entryDone',
            },
        },
    }, {
        actions: {
            entryDone: () => {
                props.onReinit()
            }
        },
        services: {
            delete: () => {
                const { accountId } = getHostnameInfo()
                const { env } = getLocationInfo(props.location, props.config)
                const userDirUrl = `api/${accountId}/env/${env}/user-directory/${props.parentContext.delete.directoryId}`
                return axiosWrapper(props.config.envApi, userDirUrl, 'DELETE')
            },
        },
    }))

    return (
        <Dialog
            open
            disableBackdropClick
            disableEscapeKeyDown
            scroll="body"
            onClose={props.onClose}
        >
            <CustomDialogTitle onClose={props.onClose} />
            <CustomDialogContent
                parentContext={props.parentContext}
                state={state}
            />
            <CustomDialogActions
                state={state}
                onClose={props.onClose}
                onConfirm={() => send('CONFIRM')}
            />
        </Dialog >
    )
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(UserDirDeleteConfirm))
