import * as React from "react";
import * as PropTypes from "prop-types";
import getEnvironmentsCallUrl from "../../../../../../lib/getEnvironmentsCallUrl";
import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import {getPath} from "../../../../../../lib/utils/";
import consoleLogger from "../../../../../../lib/consoleLogger";
import {Dialog, DialogTitle, DialogActions, DialogContent} from "@material-ui/core";
import CenteredCircularProgress from "../../../../../Widgets/CenteredCircularProgress/";
import Actions from "./Actions";
import Content from "./Content";
import Title from "./Title";

const INIT_STATE = {
    status: "", // "" | "recalc" | "wait"
    environments: [],
    userEnvironmentPermissions: [],
    filters: {
        environment: ""
    },
    sortBy: {
        name: true
    }
};

let userEnvironmentPermissionsTimer;

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.initialize();
    }

    public componentDidUpdate(_, prevState) {
        const filters_string = JSON.stringify(this.state.filters);
        const prevFilters_string = JSON.stringify(prevState.filters);
        const sortBy_string = JSON.stringify(this.state.sortBy);
        const prevSortBy_string = JSON.stringify(prevState.sortBy);

        if ((filters_string !== prevFilters_string) || (sortBy_string !== prevSortBy_string)) {
            this.setState(
                {status: "recalc"},
                () => this.getEnvironments()
                    .then((environments) => {
                        this.setState({
                            status: "ready",
                            environments
                        });
                    })
            );
        }
    }

    public render() {
        return <Dialog open style={{paddingTop: "5px", zIndex: 1250}} maxWidth="lg" classes={{paper: "io-modal-wrapper"}}>
            <DialogTitle style={{height: "48px", background: this.props.muiTheme.palette.primary1Color, color: "#fff", lineHeight: "48px", fontSize: "24px", fontWeight: 600}}>
                <Title {...this.props} />
            </DialogTitle>
            <DialogContent style={{width: "100%", background: "rgb(250, 250, 250)", padding: "0 0 24px 0", boxSizing: "border-box"}}>
                {["ready", "recalc"].indexOf(this.state.status) < 0
                    ? <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
                    : <Content{...this.props} dialogState={this.state} onSetDialogState={this.onSetDialogState}/>}
            </DialogContent>
            <DialogActions style={{padding: "0 24px 24px", background: "rgb(250, 250, 250)"}}>
                <Actions {...this.props} dialogState={this.state} onCancel={this.props.onClose} onSetDialogState={this.onSetDialogState}/>
            </DialogActions>
        </Dialog>;
    }

    private onSetDialogState = (state, cb) => this.setState(state, cb);

    private getFilteredEnvironments(environments = []) {
        let filtered = [...environments];

        filtered = filtered.filter((environment) => {
            const environmentName = (environment.name || "").toLowerCase();
            const environmentId = (environment.environmentId || "").toLowerCase();
            const filter = (getPath(this.state, "filters.environment") || "").toLowerCase();
            return environmentName.includes(filter) || environmentId.includes(filter);
        });

        return filtered;
    }

    private getSortedEnvironments(environments = []) {
        const sortBy = this.state.sortBy;
        let sorted = [...environments];

        // Following DRY (Don't-Repeat-Yourself) principle we have to combine the
        // following code into one helper method but more or less
        // this implementation is experimental and its purpose is to
        // demonstrate the basic idea.
        // Later on, the sorting functionality should become much more advanced,
        // i.e. sorting by the Access and so on.

        // Sort by "Name"
        if (sortBy.name !== undefined) {
            if (sortBy.name === true) {
                sorted.sort((a, b) => {
                    const nameA = ("" + a.name).toLowerCase();
                    const nameB = ("" + b.name).toLowerCase();
                    if (nameA < nameB) return -1;
                    else if (nameA > nameB) return 1;
                    else return 0;
                });
            } else if (sortBy.name === false) {
                sorted.sort((a, b) => {
                    const nameA = ("" + a.name).toLowerCase();
                    const nameB = ("" + b.name).toLowerCase();
                    if (nameA < nameB) return 1;
                    else if (nameA > nameB) return -1;
                    else return 0;
                });
            }
        }

        return sorted;
    }

    private _getEnvironments = () => {
        let environments = [...getPath(this.props, "environments.all.data") || []];

        // 1. Filter
        environments = this.getFilteredEnvironments(environments);

        // 2. Sort
        environments = this.getSortedEnvironments(environments);

        // X. Show only the first 20 items -- just to test if that improves performance
        // environments = environments.slice(0, 20);

        return Promise.resolve(environments);
    }

    private getEnvironments = () => {
        clearTimeout(userEnvironmentPermissionsTimer);
        return new Promise((resolve) => {
            userEnvironmentPermissionsTimer = setTimeout(() => resolve(this._getEnvironments()), 550);
        });
    }

    // Initialize --------------------------------------------------------------
    private initialize = () => {
        this.setState(
            {status: "init"},
            () => {
                const {accountId} = getHostnameInfo();
                const environmentsUrl = getEnvironmentsCallUrl(accountId);
                this.props.environmentsReadAll(this.props.config, environmentsUrl)
                    .then(this.getEnvironments)
                    .then((environments) => {
                        const userEnvironmentPermissions = [...getPath(this.props, "state.selectedUser.environments") || []];
                        this.setState({
                            ...INIT_STATE,
                            status: "ready",
                            environments,
                            userEnvironmentPermissions
                        });
                    })
                    .catch((reason) => consoleLogger.error(":::", reason));
            }
        );
    }
}
