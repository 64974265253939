import * as React from "react";
import * as PropTypes from "prop-types";
import Popover from '@material-ui/core/Popover';
import "./style.less";

class CardBody extends React.Component<any, any> {
    public static propTypes = {
        className: PropTypes.string,
        id: PropTypes.string,
        popover: PropTypes.bool,
        style: PropTypes.object,
        text: PropTypes.string,
        textStyles: PropTypes.object,
        title: PropTypes.string,
        titleStyles: PropTypes.object,
        onClick: PropTypes.func,
    };

    public static defaultProps = {
        className: "",
        id: "",
        popover: false,
        style: {},
        text: "",
        textStyles: {},
        title: "",
        titleStyles: {},
        onClick: () => {},
    };

    public constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    public render() {
        return <div data-qa={this.props["data-qa"]} className={`card-body ${this.props.className}`} style={this.props.style} onClick={this.props.onClick}>
            {this.props.title && <div className="body-title" style={this.props.titleStyles}>
                {this.props.title}
            </div>}
            {this.props.text && <div className="body-text" style={this.props.textStyles}
                onMouseOver={this.handleHoverPopover}
                // onMouseLeave={this.handlePopoverClose}
            >
                {this.props.text}
            </div>}
            {this.props.popover &&
                <Popover
                    id={this.props.id || "popover"}
                    open={!!this.state.anchorEl}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{ vertical: "center", horizontal: "center" }}
                    transformOrigin={{ vertical: "center", horizontal: "center" }}
                    PaperProps={{ style: { borderRadius: 8 } }}
                >
                    <div
                        style={{
                            maxWidth: 308,
                            backgroundColor: "#fff",
                            color: "#1A212B",
                            whiteSpace: "normal",
                            padding: 20,
                            borderRadius: 8,
                            boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px"
                        }}
                        onMouseLeave={this.handlePopoverClose}
                    >
                        <p style={{ fontSize: "12px", lineHeight: "24ox", margin: 0 }}>{this.props.text}</p>
                    </div>
                </Popover>
            }
        </div>;
    }

    private handleHoverPopover = (event) => {
        event.preventDefault();
        this.setState({ anchorEl: event.currentTarget });
    };

    private handlePopoverClose = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ anchorEl: null });
    };
}

export default CardBody;
