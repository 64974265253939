import * as PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import JsonConfig from "../../../../Shared/JsonConfig";
import ExternalAuth from '../../../../Shared/ExternalAuth'

const WStep3 = (props) => {
    const availableDataAdapters = props.wstate?.availableDataAdapters || [];

    return <>
        {props.wstate.data.type === "DataSourceEpicDstu2"
            ? <p style={{ marginBottom: 0 }}>
                <strong>FHIR Config</strong>
            </p> : null}

        <TextField
            name="name" value={props.wstate.data.name || ""}
            placeholder="Display name" label="Display Name"
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />

        <TextField
            name="data-source-id" value={props.wstate.data.dataSourceId || ""}
            placeholder="Data adapter ID" label="Data Adapter ID"
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />

        {props.wstate.data.description
            ? <TextField
                name="description" value={props.wstate.data.description || ""}
                placeholder="Description" label="Description"
                disabled fullWidth multiline
                classes={{ root: "disabled-text-field" }}
                style={{ marginTop: 16 }}
            /> : null}

        <TextField
            name="phi" value={props.wstate.data.phi ? "Yes" : "No"}
            placeholder="Will this data adapter be used with PHI" label="Will this data adapter be used with PHI"
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />

        {(() => {
            let value = "";
            switch (props.wstate.data.__temp__fhirVersionSelected) {
                case "1.0.2": value = "FHIR DSTU2 v1.0.2"; break;
                case "3.0.2": value = "FHIR STU3 v3.0.2"; break;
                case "4.0.1": value = "FHIR R4 v4.0.1"; break;
                default: value = "Unknown"; break;
            }
            return <TextField
                name="adapter-version" value={value}
                placeholder="Adapter standard" label="Adapter Standard"
                disabled fullWidth
                classes={{ root: "disabled-text-field" }}
                style={{ marginTop: 16 }}
            />;
        })()}

        {(() => {
            const json = props.wstate?.data?.configuration?.json;
            if (json) {
                const adapter = availableDataAdapters.find((a) => a.type === props.wstate.data.type)
                const configProps = adapter?.configurationSchema?.properties || {};
                return <JsonConfig
                    canEdit={false}
                    configProps={configProps}
                    isJsonEditorShown={false}
                    jsonStr={props.wstate.data.configuration.json || ""}
                />;
            }

            return null;
        })()}

        {(() => {
            const value = (availableDataAdapters.find(({ type }) => type === props.wstate.data.type) || {}).displayName || "";
            return <TextField
                name="type" value={value}
                placeholder="Data source type" label="Data Source Type"
                disabled fullWidth
                classes={{ root: "disabled-text-field" }}
                style={{ marginTop: 16 }}
            />;
        })()}

        {[
            "DataSourceFhirDstu2Proxy",
            "DataSourceAllscriptsDstu2",
            "DataSourceCernerDstu2",
            "DataSourceEpicDstu2",
            "DataSourceFlatironOncoEmrDstu2",
            "DataSourceFhirStu3Proxy",
            "DataSourceFhirR4Proxy",
            "DataSourceCernerR4",
            "DataSourceDstu2ToR4",
        ].includes(props.wstate.data.type)
            ? <TextField
                name="endpoint" value={props.wstate.data.__temp__endpoint || ""}
                placeholder="Endpoint" label="Endpoint"
                disabled fullWidth
                classes={{ root: "disabled-text-field" }}
                style={{ marginTop: 16 }}
            />
            : null}

        {[
            "DataSourceFhirDstu2Proxy",
            "DataSourceFhirStu3Proxy",
            "DataSourceFhirR4Proxy",
            "DataSourceDstu2ToR4",
        ].includes(props.wstate.data.type) ? (() => {
                let value = "";
                switch (props.wstate.data.configuration.authenticationType) {
                    case "NONE": value = "None"; break;
                    case "SMART_ON_FHIR": value = "SMART on FHIR"; break;
                    case "OAUTH2_CLIENT_CREDENTIALS": value = "OAuth2 Client Credentials"; break;
                    default: value = "Unknown"; break;
                }
                return <TextField
                    name="auth-type" value={value}
                    placeholder="Authentication type" label="Authentication Type"
                    disabled fullWidth
                    classes={{ root: "disabled-text-field" }}
                    style={{ marginTop: 16 }}
                />;
            })() : null}

        {props.wstate.data.type === "DataSourceEpicDstu2"
            ? <p style={{ marginTop: 32 }}>
                <strong>Epic Private API</strong>
            </p> : null}

        {props.wstate.data.type === "DataSourceEpicDstu2"
            ? <TextField
                name="epic-patient-staging-data-toggle" value={props.wstate.data.configuration.patientStagingDataEnabled ? "Enabled" : "Disabled"}
                placeholder="Patient staging data" label="Patient Staging Data"
                disabled fullWidth multiline
                classes={{ root: "disabled-text-field" }}
                style={{ marginTop: 16 }}
            /> : null}

        {props.wstate.data.type === "DataSourceEpicDstu2" && props.wstate.data.configuration.patientStagingDataEnabled
            ? <TextField
                name="epic-patient-staging-data-endpoint" value={props.wstate.data.configuration.patientStagingDataRestUrl || ""}
                placeholder="Patient staging data endpoint" label="Patient Staging Data Endpoint"
                disabled fullWidth multiline
                classes={{ root: "disabled-text-field" }}
                style={{ marginTop: 16 }}
            /> : null}

        {props.wstate.data.type === "DataSourceHspc"
            ? <TextField
                name="sandbox-id" value={props.wstate.data.configuration.sandboxId || ""}
                placeholder="Sandbox ID" label="Sandbox ID"
                disabled fullWidth multiline
                classes={{ root: "disabled-text-field" }}
                style={{ marginTop: 16 }}
            /> : null}

        {props.wstate.data.type === "DataSourceHspc"
            ? <TextField
                name="sandbox-secured"
                value={props.wstate.data.configuration.secured ? "Yes" : "No"}
                placeholder="Secured sandbox" label="Secured Sandbox"
                disabled fullWidth multiline
                classes={{ root: "disabled-text-field" }}
                style={{ marginTop: 16 }}
            /> : null}

        <br />
        <ExternalAuth
            availableDataAdapters={props.wstate.availableDataAdapters}
            canEdit={false}
            configJsonStr={props.wstate.data.configuration?.json || ''}
            dataSourceId={props.wstate.data.dataSourceId}
            dataSourceType={props.wstate.data.type}
            authTypeInitValue={props.wstate.data.externalAuth?.authType || ''}
            clientApiEndpointInitValue={props.wstate.data.externalAuth?.clientApiEndpoint || ''}
            clientIdInitValue={props.wstate.data.externalAuth?.clientId || ''}
            clientSecretInitValue={props.wstate.data.externalAuth?.clientSecret || ''}
            epicAppNameInitValue={props.wstate.data.externalAuth?.epicAppName || ''}
            epicPublicKeyInitValue={props.wstate.data.externalAuth?.epicPublicKey || ''}
            externalAuthApiIdInitValue={props.wstate.data.externalAuth?.externalAuthApiId || ''}
            passwordInitValue={props.wstate.data.externalAuth?.password || ''}
            usernameInitValue={props.wstate.data.externalAuth?.username || ''}
            tokenEndpointInitValue={props.wstate.data.externalAuth?.tokenEndpoint || ''}
            credentialsPasswordInitValue={props.wstate.data.externalAuth?.credentials?.[0]?.password || ''}
            credentialsUserInitValue={props.wstate.data.externalAuth?.credentials?.[0]?.user || ''}
        />
    </>;
};

WStep3.propTypes = {
    wstate: PropTypes.object.isRequired,
};

export default WStep3;
