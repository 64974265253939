export default {
    all: {
        status: "",
        data: [],
    },
    selected: {
        status: "",
        data: {},
    }
};
