import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../../redux/actions'
import { Link } from 'react-router-dom'
import { createMachine, assign } from 'xstate'
import { useMachine } from '@xstate/react'
import $ from 'jquery'
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined'
import VisibilityIcon from '@material-ui/icons/Visibility'
import axiosWrapper from '../../../../lib/axiosWrapper'
import withLegacyTheme from '../../../../lib/hoc/with-legacy-theme'
import getHostnameInfo from '../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../lib/getLocationInfo'
import getUserDirsCallUrl from '../../../../lib/getUserDirsCallUrl'
import { isEnvironmentAdmin } from '../../../../lib/user-environment-permissions-helpers'
import { NavbarL2 } from '../../../CustomNavbar/'
import CenteredCircularProgress from '../../../Widgets/CenteredCircularProgress'
import {
    CardsContainer,
    CardWrapper,
    CardBody,
    CardBodyAddNew,
    CardMenuBar,
} from '../../../Widgets/CustomCard'
import Dialogs from './Dialogs'
import './style.less'

type TProps = {
    config: any
    history: any
    location: any
    login: any
    muiTheme: any
    userDirsReadAll: Function
}

type TContextDelete = {
    directoryId: string
    name: string
}

type TContextDialog = '' | ':ADD_NEW_WIZARD:' | ':DELETE_CONFIRM:'

type TContextUserDirSlim = {
    directoryId: string
    name: string
    description: string
    directoryType: 'INTEROPIO_USERS' | 'DELEGATED_EHR_USERS'
}


export type TContext = {
    delete: TContextDelete
    dialog: TContextDialog
    userDirsSlim: TContextUserDirSlim[]
}

type TEvent = { type: 'CLOSE_DIALOG' }
    | { type: 'OPEN_ADD_NEW_USER_DIR_WIZARD' }
    | { type: 'OPEN_CONFIRM_DELETE_USER_DIR_DIALOG', delete?: TContextDelete }
    | { type: 'REINIT' }

const UserDirLIst = (props: TProps) => {
    React.useEffect(() => {
        $('.stage').addClass('stage-bgnd-dark')

        return () => {
            $('.stage').removeClass('stage-bgnd-dark')
        }
    }, [])

    const { accountId } = getHostnameInfo()
    const { env } = getLocationInfo(props.location, props.config)
    const actAsAdmin = isEnvironmentAdmin(props.login, props.location, props.config)

    const [state, send] = useMachine(() => createMachine({
        id: 'user-dirs-list',
        tsTypes: {} as import('./index.typegen').Typegen0,
        schema: {
            context: {} as TContext,
            events: {} as TEvent,
        },
        initial: 'init',
        context: {
            delete: {
                directoryId: '',
                name: '',
            },
            dialog: '',
            userDirsSlim: [],
        },
        states: {
            init: {
                invoke: {
                    src: 'init',
                    onDone: {
                        target: 'ready',
                        actions: assign({
                            userDirsSlim: (_, event) => {
                                const data = event.data?.data || []
                                return [...data].sort((a, b) => a.name.localeCompare(b.name))
                            },
                            dialog: '',
                        }),
                    },
                    onError: 'ready',
                },
            },
            ready: {
                on: {
                    OPEN_ADD_NEW_USER_DIR_WIZARD: {
                        actions: 'openAddNewUserDirWizard',
                        cond: 'isActingAsAdmin',
                    },
                    OPEN_CONFIRM_DELETE_USER_DIR_DIALOG: {
                        actions: 'openConfirmDeleteUserDirDialog',
                        cond: 'isActingAsAdmin',
                    },
                    CLOSE_DIALOG: {
                        actions: 'closeDialog',
                    },
                    REINIT: 'init',
                },
            },
        },
    }, {
        actions: {
            closeDialog: assign({
                dialog: '',
            }),
            openAddNewUserDirWizard: assign({
                dialog: () => ':ADD_NEW_WIZARD:',
            }),
            openConfirmDeleteUserDirDialog: assign({
                dialog: () => ':DELETE_CONFIRM:',
                delete: (_, event) => event.delete,
            }),
        },
        guards: {
            isActingAsAdmin: () => actAsAdmin,
        },
        services: {
            init: () => {
                const userDirsUrl = getUserDirsCallUrl(accountId, env)
                props.userDirsReadAll(props.config, userDirsUrl)

                const udsSlimUrl = `api/${accountId}/env/${env}/user-directory-slim`
                return axiosWrapper(props.config.envApi, udsSlimUrl, 'GET')
            },
        },
    }))

    return (
        <>
            <div className="user-dirs">
                <NavbarL2
                    title={(
                        <Link
                            to={`/${env}/auth`}
                            style={{
                                color: "#fff",
                                fontSize: "24px",
                                textDecoration: "none",
                            }}
                        >
                            Authentication
                        </Link>
                    )}
                />

                <div
                    className="container"
                    style={{ paddingTop: '48px' }}
                >
                    <p style={{
                        margin: '0 0 15px 0',
                        color: props.muiTheme.palette.textColor,
                        fontSize: '24px',
                        fontWeight: 400,
                        lineHeight: '28px',
                    }}>
                        Welcome to interopiO!
                    </p>
                    <p style={{
                        width: 350,
                        margin: '0 0 15px 0',
                        color: props.muiTheme.palette.secondaryTextColor,
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '20px',
                    }}>
                        To get started, create an user directory.
                        Or explore the demo user directory.
                    </p>
                    <p style={{ margin: '0 0 60px 0' }}>
                        <a
                            href="https:support.interopio.com/hc/en-us"
                            target="_blank"
                            style={{ margin: '0 15px 0 0' }}
                        >
                            Support
                        </a>
                    </p>

                    {state.value === 'ready' ? (
                        <CardsContainer>
                            {state.can({ type: 'OPEN_ADD_NEW_USER_DIR_WIZARD' })
                                ? (
                                    <CardWrapper
                                        data-qa="add-new-user-dir"
                                        style={{ minHeight: '200px' }}
                                    >
                                        <CardBodyAddNew
                                            text="Add User Directory"
                                            onClick={() => {
                                                send({ type: 'OPEN_ADD_NEW_USER_DIR_WIZARD' })
                                            }}
                                        />
                                    </CardWrapper>
                                ) : null
                            }

                            {state.context.userDirsSlim.map((it, idx) =>
                                <CardWrapper
                                    key={`card-${idx}-${it.directoryId}`}
                                    style={{
                                        minHeight: '200px',
                                        background: it.directoryType === 'INTEROPIO_USERS' ? '' : props.muiTheme.palette.borderColor,
                                    }}
                                >
                                    <CardMenuBar
                                        data-qa={`user-dir-menu-${it.directoryId}`}
                                        title={it.name}
                                        style={{ paddingBottom: 0 }}
                                        menuItems={(actAsAdmin && it.directoryType === 'INTEROPIO_USERS') ? [
                                            {
                                                leftIcon: <VisibilityIcon/>,
                                                text: 'View',
                                                onClick: () => {
                                                    props.history.push(`/${env}/auth/${it.directoryId}`)
                                                },
                                            },
                                            state.can({ type: 'OPEN_CONFIRM_DELETE_USER_DIR_DIALOG' }) ? {
                                                'data-qa': 'delete-usr-dir-button',
                                                leftIcon: <DeleteIcon/>,
                                                text: 'Delete',
                                                onClick: () => {
                                                    send({
                                                        type: 'OPEN_CONFIRM_DELETE_USER_DIR_DIALOG',
                                                        delete: {
                                                            directoryId: it.directoryId,
                                                            name: it.name,
                                                        }
                                                    })
                                                },
                                            } : null,
                                        ] : []}
                                        onClick={() => props.history.push(`/${env}/auth/${it.directoryId}`)}
                                    />
                                    <CardBody
                                        data-qa={`user-dir-${it.directoryId}`}
                                        text={it.description}
                                        style={{
                                            minHeight: 160,
                                            paddingTop: 0,
                                        }}
                                        onClick={() => {
                                            props.history.push(`/${env}/auth/${it.directoryId}`)
                                        }}
                                    />
                                </CardWrapper>
                            )}
                        </CardsContainer>
                    ) : (
                        <CenteredCircularProgress size={63} />
                    )
                    }
                </div>
                <br/>

            </div>
            <Dialogs
                parentContext={state.context}
                onClose={() => send({ type: 'CLOSE_DIALOG' })}
                onReinit={() => send({ type: 'REINIT' })}
            />
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    login: state.login,
    ...ownProps,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    userDirsReadAll: actions.userDirsReadAll,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(UserDirLIst)))
