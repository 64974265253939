import * as React from "react"

import {SvgIcon} from "@material-ui/core";

export default (props) => (
    <SvgIcon {...props} viewBox="0 0 510 510">
        <g transform="translate(-2.6433e-6 -21.129)" stroke="#454545">
            <path d="m106.02 143.63 299.13-119.51v146.3l-300.26 119.23z"/>
            <path d="m103.45 289.57 86.619 54.662 216.76-86.528-78.877-57.282z"/>
            <path d="m104.11 378.17 0.23159 149.98 301.5-118.93 0.0403-151.51z"/>
        </g>
    </SvgIcon >
);
