import * as React from "react";
import * as PropTypes from "prop-types";
import {getPath} from "../../../../../../../../../lib/utils/";
import SortingIcon from "../../../../../../../../Widgets/icons/SortingIcon";

const Component: React.FunctionComponent<any> = props => {
    const order = getPath(props, `state.sortBy.${props.name}`);

    let color = props.muiTheme.palette.disabledColor;
    if (props.sortable) {
        if (order === true || order === false) {
            color = props.muiTheme.palette.textColor;
        } else {
            color = props.muiTheme.palette.secondaryTextColor;
        }
    }

    const labelColor = props.highlight ? props.muiTheme.palette.primary1Color : "";

    return <div style={{cursor: props.sortable ? "pointer" : "default", color}} onClick={() => props.sortable && props.onSetState({sortBy: {[props.name]: !order}})}>
        <span style={{color: labelColor}}>
            {props.label}
        </span>
        <SortingIcon down={order} />
    </div>;
}

Component.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired,
    highlight: PropTypes.bool,
    label: PropTypes.string,
    sortable: PropTypes.bool
}

Component.defaultProps = {
    highlight: false,
    label: "",
    sortable: true
}

export default Component;
