import * as React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../../../../redux/actions";
import axiosWrapper from "../../../../../../../../../lib/axiosWrapper";
import getHostnameInfo from "../../../../../../../../../lib/getHostnameInfo";
import getGatewaysCallUrl from "../../../../../../../../../lib/getGatewaysCallUrl";
import getLocationInfo from "../../../../../../../../../lib/getLocationInfo";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

interface IProps {
    config: any,
    location: any,
    newSession: any;
    parentStatus: string;
    generalMessageSet: Function;
    onClose: React.MouseEventHandler<HTMLButtonElement>;
    setParentStatus: Function;
};

const CustomDialogActions: React.FC<IProps> = (props: IProps) => {
    return (
        <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
            <Button disabled={props.parentStatus !== "ready"} style={{ marginRight: 8 }}
                onClick={props.onClose}
            >
                Cancel
            </Button>
            <Button disabled={props.parentStatus !== "ready"} variant="contained" color="secondary" style={{ marginRight: 8 }}
                onClick={async (event) => {
                    props.setParentStatus("waiting");
                    props.onClose(event);
                    const { accountId } = getHostnameInfo();
                    const { env, gatewayId } = getLocationInfo(props.location, props.config);
                    const startDateTime = props.newSession.date.toISOString();
                    const endDateTime = new Date(props.newSession.date.getTime() + props.newSession.duration).toISOString();
                    const data = {
                        accountId,
                        environmentId: env,
                        gatewayId,
                        startDateTime,
                        endDateTime,
                    };
                    const path = `${getGatewaysCallUrl(accountId, env)}/${gatewayId}/request-logging-session`;

                    try {
                        await axiosWrapper(props.config.envApi, path, "POST", data);
                    }
                    catch (error) {
                        props.generalMessageSet({
                            type: ":WARNING_SNACKBAR:",
                            title: "There was an error creating a new logging session!",
                            message: error.message,
                        });
                    }
                    props.setParentStatus("init");
                }}
            >
                Create Logging Session
            </Button>
        </DialogActions>
    );
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    generalMessageSet: actions.generalMessageSet,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomDialogActions));
