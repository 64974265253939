import * as React from "react";
import * as PropTypes from "prop-types";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {readAndCompressImage} from "browser-image-resizer";
import {isEnvironmentAdmin} from "../../../../../lib/user-environment-permissions-helpers";
import * as validateData from "../../../../../lib/validate-data-sources-data";
import uploadImage from "../../../../../lib/uploadImage";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import consoleLogger from "../../../../../lib/consoleLogger";
import {Button, Card, FormControl, FormControlLabel, FormHelperText, IconButton, Switch, TextField, Tooltip} from "@material-ui/core";
import {CloudUpload, Edit} from "@material-ui/icons";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress";
import Header from "../../../../Widgets/Editor/Header/";
import JsonConfig from "../../Shared/JsonConfig";
import ActionButtons from "./ActionButtons";
import getHostnameInfo from '../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../lib/getLocationInfo'
import type {
    TDataSourceType,
    TExternalAuth,
    TExternalAuthType,
} from '../../../../../types'
import ExternalAuth from '../../Shared/ExternalAuth';
import ImageLoader from "../../../../Widgets/ImageLoader";

const DEFAULT_ADAPTER_PROPS = [
    "dataAdapterId", "version", "standard"
];

class Details extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.validateData();
    }

    public componentDidUpdate(prevProps) {
        if (!this.props.state.data.configuration.json && JSON.stringify(this.props.state.data.configuration) !== JSON.stringify(prevProps.state.data.configuration)) {
            this.validateData();
        } else if (!!this.props.state.data.configuration.json && this.props.state.data.configuration.json !== prevProps.state.data.configuration.json) {
            this.validateData(false);
        } else if (JSON.stringify(this.props.state.data) !== JSON.stringify(prevProps.state.data)) {
            const validateLogoUrl = this.props.state.data.logoUrl !== prevProps.state.data.logoUrl;
            this.validateData(false, validateLogoUrl);
        }
    }

    public render() {
        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);
        const canEdit = this.props.state.mode === ":EDIT:";

        return <Card style={{marginTop: 48}}>
            <Header {...this.props} style={{height: 56}}
                rightIcon={!canEdit && actAsAdmin
                    ? <IconButton data-qa="da-details-edit-button" style={{paddingTop: 16}} onClick={() => this.props.onSetState({mode: ":EDIT:"})}>
                        <Tooltip title="Edit"><Edit/></Tooltip>
                    </IconButton>
                    : null}/>
            <div style={{padding: "0 24px"}}>
                {this.renderLogo()}
                {this.renderLogoSourceEditor(canEdit)}
                {this.props.state.data.type === "DataSourceEpicDstu2" ? this.renderFhirConfigSectionTitle() : null}
                {this.renderName(canEdit)}
                {this.renderDataSourceId()}
                {this.renderDescription(canEdit)}
                {this.renderPHI()}
                {this.renderSupportedGatewayType()}
                {this.renderType()}
                {[
                    "DataSourceFhirDstu2Proxy",
                    "DataSourceAllscriptsDstu2",
                    "DataSourceCernerDstu2",
                    "DataSourceEpicDstu2",
                    "DataSourceFlatironOncoEmrDstu2",
                    "DataSourceFhirStu3Proxy",
                    "DataSourceFhirR4Proxy",
                    "DataSourceCernerR4"
                ].includes(this.props.state.data.type) ? this.renderEndpoint(canEdit) : null}
                {![
                    "DataSourceFhirDstu2Proxy",
                    "DataSourceAllscriptsDstu2",
                    "DataSourceCernerDstu2",
                    "DataSourceEpicDstu2",
                    "DataSourceFlatironOncoEmrDstu2",
                    "DataSourceFhirStu3Proxy",
                    "DataSourceCernerR4",
                    "DataSourceHspc"
                ].includes(this.props.state.data.type) ? this.renderConfiguration(canEdit) : null}
                {[
                    "DataSourceFhirDstu2Proxy",
                    "DataSourceFhirStu3Proxy",
                    "DataSourceFhirR4Proxy"
                ].includes(this.props.state.data.type) ? this.renderAuthenticationType() : null}
                {this.props.state.data.type === "DataSourceEpicDstu2" ? this.renderEpicPrivateApiSectionTitle() : null}
                {this.props.state.data.type === "DataSourceEpicDstu2" ? this.renderEpicPatientStagingDataToggle(canEdit) : null}
                {this.props.state.data.type === "DataSourceEpicDstu2" && this.props.state.data.configuration.patientStagingDataEnabled ? this.renderEpicPatientStagingDataEndpoint(canEdit) : null}
                {this.props.state.data.type === "DataSourceEpicDstu2" ? this.renderEpicClinicalNoteToggle(canEdit) : null}
                {this.props.state.data.type === "DataSourceEpicDstu2" && this.props.state.data.configuration.fileClinicalNoteEnabled ? this.renderEpicClinicalNoteEndpoint(canEdit) : null}
                {this.props.state.data.type === "DataSourceHspc" ? this.renderSandboxId() : null}
                {this.props.state.data.type === "DataSourceHspc" ? this.renderSandboxSecured() : null}
            </div>

            <div style={{ padding: '0 24px 24px 24px' }}>
                <ExternalAuth
                    availableDataAdapters={this.props.state.availableDataAdapters}
                    canEdit={canEdit}
                    configJsonStr={this.props.state.data.configuration?.json || JSON.stringify({...(this.props.state?.data?.configuration || {})}) || {}}
                    dataSourceId={this.props.state.data.dataSourceId}
                    dataSourceType={this.props.state.data.type}
                    authTypeInitValue={this.props.state.data.externalAuth?.authType || ''}
                    clientApiEndpointInitValue={this.props.state.data.externalAuth?.clientApiEndpoint || ''}
                    clientIdInitValue={this.props.state.data.externalAuth?.clientId || ''}
                    clientSecretInitValue={this.props.state.data.externalAuth?.clientSecret || ''}
                    credentialsPasswordInitValue={this.props.state.data.externalAuth?.credentials?.[0]?.password || ''}
                    credentialsUserInitValue={this.props.state.data.externalAuth?.credentials?.[0]?.user || ''}
                    epicAppNameInitValue={this.props.state.data.externalAuth?.epicAppName || ''}
                    epicPublicKeyInitValue={this.props.state.data.externalAuth?.epicPublicKey || ''}
                    externalAuthApiIdInitValue={this.props.state.data.externalAuth?.externalAuthApiId || ''}
                    passwordInitValue={this.props.state.data.externalAuth?.password || ''}
                    usernameInitValue={this.props.state.data.externalAuth?.username || ''}
                    tokenEndpointInitValue={this.props.state.data.externalAuth?.tokenEndpoint || ''}
                    callback={this.externalAuthCallback}
                />
            </div>

            <ActionButtons state={this.props.state} initialize={this.props.initialize} onSetState={this.props.onSetState}/>
        </Card>;
    }

    protected externalAuthCallback = (data: Partial<{
        authType: TExternalAuthType
        availableExternalDataAdapters: TDataSourceType[]
        systemAuthEnabled: boolean
        clientApiEndpoint: string
        clientId: string
        clientSecret: string
        credentialsPassword: string
        credentialsUser: string
        dataSourceType: TDataSourceType
        epicAppName: string
        epicPublicKey: string
        externalAuthApiId: string,
        isAllscriptsUnityExt: boolean
        isEpicExt: boolean
        isIKnowMedExt: boolean
        isG2Ext: boolean
        isExternal: boolean
        isGenericExt: boolean
        isVarianR4Ext: boolean
        isR4ProxyExt: boolean
        password: string
        username: string
        tokenEndpoint: string
    }>) => {
        this.props.onSetState((prevState) => {
            let externalAuth: TExternalAuth = null
            const { accountId } = getHostnameInfo()
            const { env } = getLocationInfo(this.props.location, this.props.config)
            const gatewayId = ''
            const dataSourceId = prevState.data.dataSourceId
            const appId = ''
            if ((data.isEpicExt || data.isR4ProxyExt) && data.systemAuthEnabled && data.authType === 'BACKEND') {
                externalAuth = {
                    accountId,
                    environmentId: env,
                    gatewayId,
                    dataSourceId,
                    appId,
                    authType: data.authType,
                    clientId: data.clientId,
                    epicAppName: data.epicAppName,
                    epicPublicKey: data.epicPublicKey,
                    externalAuthApiId: data.externalAuthApiId,
                    tokenEndpoint: data.tokenEndpoint,
                }
            }
            else if (data.isEpicExt && data.systemAuthEnabled && data.authType === 'EXTERNAL') {
                externalAuth = {
                    accountId,
                    environmentId: env,
                    gatewayId,
                    dataSourceId,
                    appId,
                    authType: data.authType,
                    clientId: data.clientId,
                    clientSecret: data.clientSecret,
                    tokenEndpoint: data.tokenEndpoint,
                    clientApiEndpoint: data.clientApiEndpoint,
                }
            }
            else if ((data.isGenericExt || data.isIKnowMedExt || data.isAllscriptsUnityExt || data.isVarianR4Ext) && data.authType === 'EXTERNAL') {
                externalAuth = {
                    accountId,
                    environmentId: env,
                    gatewayId: '',
                    dataSourceId,
                    appId: '',
                    authType: data.authType,
                    clientId: data.clientId,
                    clientSecret: data.clientSecret,
                    username: data.username,
                    password: data.password,
                    tokenEndpoint: data.tokenEndpoint,
                }
            }
            else if (data.isG2Ext && data.authType === 'EXTERNAL') {
                externalAuth = {
                    accountId,
                    environmentId: env,
                    gatewayId: '',
                    dataSourceId,
                    appId: '',
                    authType: data.authType,
                    clientId: data.clientId,
                    clientSecret: data.clientSecret,
                }
            }
            return {
                data: {
                    ...prevState.data,
                    externalAuth,
                },
            }
        })
    }

    private renderFhirConfigSectionTitle = () => <p style={{marginBottom: 0, color: this.props.muiTheme.palette.textColor}}>
        <strong>FHIR Config</strong>
    </p>;

    private renderLogo = () => {
        let logo = null;
        if (this.props.state.dataValidation.logoUrl === ":LOADING:") {
            logo = <CenteredCircularProgress size={100}/>;
        } else if (!this.props.state.data.logoUrl || this.props.state.dataValidation.logoUrl) {
            logo = <ImageLoader src={"/console/img/app-logo-hint.png"} imgStyle={{maxHeight: 124}} config={this.props.config}/>
        } else {
            logo = <ImageLoader data-qa="da-details-uploaded-logo" src={this.props.state.data.logoUrl} imgStyle={{maxHeight: 124}} config={this.props.config}/>
        }

        return <div style={{height: 156, padding: 16, textAlign: "center"}}>
            {logo}
        </div>;
    };

    private renderLogoSourceEditor = (canEdit) => {
        let dataValidationLogoUrl = this.props.state.dataValidation.logoUrl;
        if (dataValidationLogoUrl === ":LOADING:") {
            dataValidationLogoUrl = "";
        }

        const errorMessagePadding = dataValidationLogoUrl ? 8 : 0;
        const errorMessageTransform = dataValidationLogoUrl ? "scaleY(1)" : "scaleY(0)";
        const errorMessageBackground = this.props.ui.xtheme.palette.colorRedDark;
        const errorMessage = dataValidationLogoUrl;

        return canEdit
            ? <div>
                <Card style={{padding: 8, background: this.props.ui.xtheme.palette.colorBgndGreyLight}}>
                    <div>
                        <p>
                            Before you upload your images, check out these
                            image recommendations to ensure they’ll look great:
                        </p>
                        <ul>
                            <li>
                                <strong>Format</strong>&nbsp;
                                When you save images, use PNG (.png), JPEG (.jpg/.jpeg),
                                TIFF (.tif/.tiff), GIF (.gif), BMP (.bmp) or SVG (.svg) format.
                            </li>
                            <li>
                                <strong>File extension</strong>&nbsp;
                                The image file extension may not correspond to its format.
                            </li>
                            <li>
                                <strong>Image Resolution</strong>&nbsp;
                                We support images up to 2500x1500 px
                            </li>
                        </ul>
                        <div style={{paddingBottom: "8px", textAlign: "center"}}>
                            <input id="contained-button-file"
                                type="file" accept="image/*" style={{display: "none"}}
                                onChange={async (event) => {
                                    this.props.onSetState({
                                        dataValidation: {
                                            ...this.props.state.dataValidation,
                                            logoUrl: ":LOADING:"
                                        }
                                    });
                                    const IMAGE_WIDTH = 296 * 2;
                                    const IMAGE_HEIGHT = 124 * 2;
                                    const MAX_WIDTH = 2500;
                                    const MAX_HEIGHT = 1500;

                                    const windowURL = window.URL || (window as any).webkitURL;
                                    if (!windowURL) return;

                                    let file: File = event?.target?.files?.[0];
                                    if (!file) {
                                        return;
                                    }

                                    if (!file.type || !file.type.match("image.*")) {
                                        return this.props.onSetState((prevState) => ({
                                            dataValidation: {
                                                ...prevState.dataValidation,
                                                logoUrl: "The file is not an image"
                                            }
                                        }));
                                    }

                                    let img: HTMLImageElement = new Image()
                                    img.src = windowURL.createObjectURL(file);

                                    img.onload = async () => {
                                        const width = img.naturalWidth
                                        const height = img.naturalHeight

                                        windowURL.revokeObjectURL(img.src);

                                        if (!width || !height) {
                                            return;
                                        }

                                        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
                                            return this.props.onSetState((prevState) => ({
                                                dataValidation: {
                                                    ...prevState.dataValidation,
                                                    logoUrl: `The image is too large. The maximum allowed size is ${MAX_WIDTH}x${MAX_HEIGHT} pixels`
                                                }
                                            }));
                                        }

                                        const name = file.name.split(".")[0];

                                        file = await readAndCompressImage(file, {
                                            mimeType: "image/png",
                                            quality: 0.75,
                                            maxWidth: IMAGE_WIDTH,
                                            maxHeight: IMAGE_HEIGHT,
                                            autoRotate: true,
                                            debug: false
                                        });

                                        let bodyFormData = new FormData();
                                        bodyFormData.append("file", file, `${name}.png`);

                                        const response = await uploadImage(this.props.config, bodyFormData);
                                        if (response && response.status < 300 && response.data) {
                                            try {
                                                this.props.onSetState({
                                                    data: {
                                                        ...this.props.state.data,
                                                        logoUrl: response.data.fileUrl,
                                                        logoId: response.data.fileId
                                                    }
                                                });
                                            } catch (reason) {
                                                consoleLogger.log("::: reason:", reason);
                                            }
                                        }
                                    }
                                }}
                            />
                            <label htmlFor="contained-button-file">
                                <Button data-qa="da-details-edit-upload-image-button" variant="contained" color="primary" component="span" startIcon={<CloudUpload/>}>
                                    Upload Image
                                </Button>
                            </label>
                        </div>
                    </div>
                </Card>

                <div style={{
                    marginTop: "16px",
                    padding: errorMessagePadding,
                    transform: errorMessageTransform,
                    transformOrigin: "top",
                    borderRadius: "2px",
                    background: errorMessageBackground,
                    color: "#fff",
                    textAlign: "center",
                    transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
                }}>
                    {errorMessage}
                </div>
            </div>
            : null;
    }

    private renderName = canEdit => <div style={{display: "flex", flexDirection: "row"}}>
        <TextField data-qa="da-details-name-edit" name="name" value={this.props.state.data.name || ""} placeholder="Display name" label="Display Name" fullWidth={true}
            helperText={this.props.state.dataValidation.name} style={{marginTop: 16}}
            error={!!this.props.state.dataValidation.name} disabled={!canEdit} autoComplete="off" autoFocus={false} classes={{root: canEdit ? "" : "disabled-text-field"}}
            onChange={canEdit ? (event) => this.props.onSetState((prevState) => ({data: {...prevState.data, name: event.target.value}})) : undefined}
        />
    </div>;

    private renderDataSourceId = () => <TextField
        name="data-source-id" value={this.props.state.data.dataSourceId || ""}
        placeholder="Data adapter ID" label="Data Adapter ID"
        disabled fullWidth
        classes={{root: "disabled-text-field"}}
        style={{marginTop: 16}}
    />;

    private renderDescription = (canEdit) => <TextField
        name="description" value={this.props.state.data.description || ""}
        placeholder="Description" label="Description" disabled={!canEdit}
        autoComplete="off" autoFocus={false} fullWidth multiline
        classes={{root: canEdit ? "" : "disabled-text-field"}}
        style={{marginTop: 16, marginBottom: 16}}
        onChange={canEdit ? (event) => this.props.onSetState((prevState) => ({data: {...prevState.data, description: event.target.value}})) : undefined}
    />;

    private renderPHI = () => <TextField
        name="phi" value={this.props.state.data.phi ? "Yes" : "No"}
        placeholder="Will this data adapter be used with PHI" label="Will this data adapter be used with PHI"
        disabled fullWidth
        classes={{root: "disabled-text-field"}}
        style={{marginTop: 16}}
    />;

    private renderSupportedGatewayType = () => {
        const supportedGatewayType = this.props.state?.data?.supportedGatewayTypes[0] || '';
        let value = ''
        switch (supportedGatewayType) {
            case 'FHIR_DSTU2__1_0_2': value = 'FHIR DSTU2 v1.0.2'; break
            case 'FHIR_STU3__3_0_2': value = 'FHIR STU3 v3.0.2'; break
            case 'FHIR_R4__4_0_1': value = 'FHIR R4 v4.0.1'; break
            case 'HL7_V2': value = 'HL7 v2.x Listening Endpoint'; break
            default: value = 'Unknown'
        }

        return <TextField
            name="gateway-type" value={value}
            placeholder="Supported Gateway Type" label="Supported Gateway Type"
            disabled fullWidth
            classes={{root: "disabled-text-field"}}
            style={{marginTop: 16}}
        />;
    };

    private renderType = () => {
        const availableDataAdapters = this.props.state?.availableDataAdapters || [];
        const value = (availableDataAdapters.find(({type}) => type === this.props.state.data.type) || {}).displayName || "";
        return <TextField
            name="type" value={value}
            placeholder="Data source type" label="Data Source Type"
            disabled fullWidth
            classes={{root: "disabled-text-field"}}
            style={{marginTop: 16}}
        />;
    };

    private renderConfiguration = canEdit => {
        // remove the default props that should not be editable by the user
        let config = {...(this.props.state?.data?.configuration || {})};
        let json = this.props.state?.data?.configuration.json !== null ? this.props.state?.data?.configuration.json : undefined;
        DEFAULT_ADAPTER_PROPS.map(p => delete config[p]);

        const adapter = (this.props.state?.availableDataAdapters || []).find(i => i.type === this.props.state.data.type);
        let props = adapter?.configurationSchema.properties || {};
        if (this.props.state.data.type === "DataSourceFhirR4Proxy") {
            delete props.fhirR4Endpoint;
            delete props.authenticationType
        }

        if (this.props.state.data.type === "DataSourceG2R4External") {
            const configJSON = JSON.parse(this.props.state?.data?.configuration?.json || JSON.stringify(config) ||  "");
            if (!configJSON.useG2WebService) {
                let {apiEndpoint, ...restProps} = adapter?.configurationSchema?.properties || {};
                props = restProps;
            }
        }

        return <div>
            {canEdit
                ?  <JsonConfig
                    canEdit={true}
                    configProps={props}
                    isJsonEditorShown={false}
                    isStringEditorShown={false}
                    jsonStr={json || JSON.stringify(config) || ""}
                    muiTheme={this.props.muiTheme}
                    onSetState={this.props.onSetState}
                    directParentState={this.props.state}
                />
                : (
                    <JsonConfig
                        canEdit={false}
                        configProps={props}
                        isJsonEditorShown={false}
                        isStringEditorShown={false}
                        jsonStr={json || JSON.stringify(config) || ""}
                        muiTheme={this.props.muiTheme}
                    />
                )
            }

            <TextField
                name="config" value={json == undefined ? JSON.stringify(config) : json}
                placeholder="Data source JSON configuration" label="Data Source JSON Configuration"
                helperText={this.props.state.dataValidation.json}
                error={!!this.props.state.dataValidation.json} disabled={!canEdit}
                autoComplete="off" autoFocus={false} fullWidth
                classes={{root: canEdit ? "" : "disabled-text-field"}}
                style={{marginTop: 16}}
                onChange={(event) => {
                    this.props.onSetState((prevState) => ({
                        data: {
                            ...prevState.data,
                            configuration: {
                                ...prevState.data.configuration,
                                json: event.target.value
                            }
                        }
                    }))
                }}
            />
        </div>
    };

    private renderEndpoint = (canEdit) => {
        const type = this.props.state.data.type || "";
        let endpointProperty = "";
        switch (type) {
            case "DataSourceFhirDstu2Proxy":
                endpointProperty = "fhirDstu2Endpoint";
                break;

            case "DataSourceAllscriptsDstu2":
                endpointProperty = "allscriptsDstu2Endpoint";
                break;

            case "DataSourceCernerDstu2":
                endpointProperty = "cernerDstu2Endpoint";
                break;

            case "DataSourceEpicDstu2":
                endpointProperty = "epicDstu2Endpoint"
                break;

            case "DataSourceFlatironOncoEmrDstu2":
                endpointProperty = "oncoEmrEndpoint";
                break;

            case "DataSourceFhirStu3Proxy":
                endpointProperty = "fhirStu3Endpoint";
                break;

            case "DataSourceFhirR4Proxy":
                endpointProperty = "fhirR4Endpoint";
                break;

            case "DataSourceCernerR4":
                endpointProperty = "cernerR4Endpoint";
                break;
            case "DataSourceDstu2ToR4":
                endpointProperty = "dstu2Endpoint";
                break;
        }

        return <TextField
            name="endpoint" value={this.props.state.data.configuration[endpointProperty] || ""}
            placeholder="Endpoint" label="Endpoint"
            helperText={this.props.state.dataValidation.endpoint}
            error={!!this.props.state.dataValidation.endpoint}
            disabled={!canEdit} fullWidth
            classes={{root: canEdit ? "" : "disabled-text-field"}}
            style={{marginTop: 16}}
            onChange={canEdit ? (event) => this.props.onSetState((prevState) => ({
                data: {
                    ...prevState.data,
                    configuration: {...prevState.data.configuration, [endpointProperty]: event.target.value}
                }
            })) : undefined}
        />
    }

    private renderAuthenticationType = () => {
        let value = "";
        switch (this.props.state.data.configuration.authenticationType) {
            case "NONE":
                value = "None";
                break;
            case "SMART_ON_FHIR":
                value = "SMART on FHIR";
                break;
            case "OAUTH2_CLIENT_CREDENTIALS":
                value = "OAuth2 Client Credentials";
                break;
            default:
                value = "Unknown";
                break;
        }
        return <TextField
            name="auth-type" value={value}
            placeholder="Authentication type" label="Authentication Type"
            disabled fullWidth
            classes={{root: "disabled-text-field"}}
            style={{marginTop: 16}}
        />
    }

    private renderEpicPrivateApiSectionTitle = () => <p style={{marginTop: 32, color: this.props.muiTheme.palette.textColor}}>
        <strong>Epic Private API</strong>
    </p>;

    private renderEpicPatientStagingDataToggle = (canEdit) => {
        return canEdit
            ? <div style={{display: "flex"}}>
                <FormControl
                    error={!!this.props.state.dataValidation.patientStagingDataEnabled}
                    style={{display: "block", marginTop: 16}}
                >
                    <FormControlLabel
                        control={<Switch
                            name="epic-patient-staging-data-toggle"
                            checked={!!this.props.state.data.configuration.patientStagingDataEnabled}
                            color="primary" style={{marginBottom: "8px"}}
                            onChange={(event) => this.props.onSetState((prevState) => ({
                                data: {
                                    ...prevState.data,
                                    configuration: {
                                        ...prevState.data.configuration,
                                        patientStagingDataEnabled: event.target.checked,
                                        patientStagingDataRestUrl: null
                                    }
                                }
                            }))}
                        />}
                        label={`Patient Staging Data (${this.props.state.data.configuration.patientStagingDataEnabled ? "Enabled" : "Disabled"})`}
                    />
                    <FormHelperText>
                        {this.props.state.dataValidation.patientStagingDataEnabled}
                    </FormHelperText>
                </FormControl>
            </div>
            : <TextField
                name="epic-patient-staging-data-toggle" value={this.props.state.data.configuration.patientStagingDataEnabled ? "Enabled" : "Disabled"}
                placeholder="Patient staging data" label="Patient Staging Data"
                disabled fullWidth multiline
                classes={{root: "disabled-text-field"}}
                style={{marginTop: 16}}
            />
    };

    private renderEpicPatientStagingDataEndpoint = (canEdit) => <TextField
        name="epic-patient-staging-data-endpoint" value={this.props.state.data.configuration.patientStagingDataRestUrl || ""}
        placeholder="Patient staging data endpoint" label="Patient Staging Data Endpoint"
        helperText={this.props.state.dataValidation.patientStagingDataRestUrl}
        error={!!this.props.state.dataValidation.patientStagingDataRestUrl}
        autoComplete="off" autoFocus={false} fullWidth
        disabled={!canEdit}
        classes={{root: canEdit ? "" : "disabled-text-field"}}
        onChange={(event) => this.props.onSetState((prevState) => ({
            data: {
                ...prevState.data,
                configuration: {
                    ...prevState.data.configuration,
                    patientStagingDataRestUrl: event.target.value
                }
            }
        }))}
    />;

    private renderEpicClinicalNoteToggle = (canEdit) => {
        return canEdit
            ? <div style={{display: "flex"}}>
                <FormControl style={{display: "block", marginTop: 16}}>
                    <FormControlLabel
                        control={<Switch
                            name="epic-clinical-note-toggle"
                            checked={!!this.props.state.data.configuration.fileClinicalNoteEnabled}
                            color="primary" style={{marginBottom: "8px"}}
                            onChange={(event) => this.props.onSetState((prevState) => ({
                                data: {
                                    ...prevState.data,
                                    configuration: {
                                        ...prevState.data.configuration,
                                        fileClinicalNoteEnabled: event.target.checked,
                                        fileClinicalNoteRestUrl: null
                                    }
                                }
                            }))}
                        />}
                        label={`File Clinical Note (${this.props.state.data.configuration.fileClinicalNoteEnabled ? "Enabled" : "Disabled"})`}
                    />
                </FormControl>
            </div>
            : <TextField
                name="epic-clinical-note-toggle" value={this.props.state.data.configuration.fileClinicalNoteEnabled ? "Enabled" : "Disabled"}
                placeholder="File clinical note" label="File Clinical Note"
                disabled fullWidth multiline
                classes={{root: "disabled-text-field"}}
                style={{marginTop: 16}}
            />
    };

    private renderEpicClinicalNoteEndpoint = (canEdit) => <TextField
        name="epic-clinical-note-endpoint" value={this.props.state.data.configuration.fileClinicalNoteRestUrl || ""}
        placeholder="File clinical note endpoint" label="File Clinical Note Endpoint"
        helperText={this.props.state.dataValidation.fileClinicalNoteRestUrl}
        error={!!this.props.state.dataValidation.fileClinicalNoteRestUrl}
        autoComplete="off" autoFocus={false} fullWidth
        disabled={!canEdit}
        classes={{root: canEdit ? "" : "disabled-text-field"}}
        onChange={(event) => this.props.onSetState((prevState) => ({
            data: {
                ...prevState.data,
                configuration: {...prevState.data.configuration, fileClinicalNoteRestUrl: event.target.value}
            }
        }))}
    />;

    private renderSandboxId = () => <TextField
        name="sandbox-id" value={this.props.state.data.configuration.sandboxId || ""}
        placeholder="Sandbox ID" label="Sandbox ID"
        disabled fullWidth
        classes={{root: "disabled-text-field"}}
        style={{marginTop: 16}}
    />;

    private renderSandboxSecured = () => <TextField
        name="sandbox-secured" value={this.props.state.data.configuration.secured ? "Yes" : "No"}
        placeholder="Secured sandbox" label="Secured Sandbox"
        disabled fullWidth
        classes={{root: "disabled-text-field"}}
        style={{marginTop: 16}}
    />;

    private async validateData(asyncCheck = true, validateLogoUrl = false) {
        this.props.onSetState({dataValidationStatus: "loading"}, async () => {
            const dataValidation: any = await validateData.onEditDetails(asyncCheck, this.props.state, this.props.config);
            if (asyncCheck) {
                this.props.onSetState({
                    ...dataValidation,
                    dataValidationStatus: "finished",
                    logoUrl: ""
                });
            } else {
                this.props.onSetState({
                    dataValidation: {
                        ...dataValidation,
                        name: dataValidation.name,
                        logoUrl: ":LOADING:"
                    },
                    dataValidationStatus: "finished"
                }, () => {
                    if (validateLogoUrl) {
                        validateData.onEditLogoUrlAsync(this.props)
                            .then((logoUrl) => this.props.onSetState((prevState) => ({
                                dataValidation: {
                                    ...prevState.dataValidation,
                                    logoUrl
                                }
                            })));
                    } else {
                        this.props.onSetState((prevState) => ({
                            dataValidation: {
                                ...prevState.dataValidation,
                                logoUrl: ""
                            }
                        }));
                    }
                });
            }
        });
    };
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    login: state.login,
    ui: state.ui,
    ...ownProps
});
export default withRouter(connect(mapStateToProps)(withLegacyTheme()(Details)));
