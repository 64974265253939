let moment = require("moment-timezone");
import * as types from "./types";

export function uiShowNavbar () {
    return { type: types.UI_SHOW_NAVBAR };
}

export function uiHideNavbar () {
    return { type: types.UI_HIDE_NAVBAR };
}

export function uiLeftMenuPrivateExpand () {
    return { type: types.UI_LEFT_MENU_PRIVATE_EXPAND };
}

export function uiLeftMenuPrivateShrink () {
    return { type: types.UI_LEFT_MENU_PRIVATE_SHRINK };
}

export function uiLeftMenuPrivateToggle () {
    return { type: types.UI_LEFT_MENU_PRIVATE_TOGGLE };
}

export function uiTabsSetItems (items: Array<any>) {
    return {
        type: types.UI_TABS_SET_ITEMS,
        payload: items,
    };
}

export function uiTabsSetSelected (selected: number) {
    return {
        type: types.UI_TABS_SET_SELECTED,
        payload: selected,
    };
}

export function uiXThemeSet (xtheme: any) {
    return {
        type: types.UI_XTHEME_SET,
        payload: xtheme,
    };
}

export function uiUpdateTimeZone (timeZone) {
    !timeZone && (timeZone = moment.tz.guess());
    return {
        type: types.UI_UPDATE_TIME_ZONE,
        payload: { timeZone }
    }
}
