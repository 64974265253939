import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../../redux/actions";
import getHostnameInfo from "../../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../../lib/getLocationInfo";
import getCdsServiceSetsCallUrl from "../../../../../../../lib/getCdsServiceSetsCallUrl";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

const CustomDialogActions  = (props) => {
    return <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
        <Button
            color="primary" style={{ marginRight: 8 }}
            disabled={props.parentState.status !== "ready"}
            onClick={props.onClose}
        >
            Cancel
        </Button>
        <Button data-qa="delete-cds-confirm-btn"
            variant="contained" color="secondary" style={{ marginRight: 8 }}
            disabled={props.parentState.status !== "ready"}
            onClick={async () => {
                props.onSetParentState({ status: "waiting" });
                const { env } = getLocationInfo(props.location, props.config);
                const { accountId } = getHostnameInfo();
                const url = getCdsServiceSetsCallUrl(accountId, env) + "/" + props.parentState.delete.cdsServiceSetId;
                const response = await props.cdsServiceSetsDelete(props.config, url);
                if (response && response.status < 300) {
                    props.onClose();
                }
            }}
        >
            Delete CDS Service Set
        </Button>
    </DialogActions>;
}

CustomDialogActions.propTypes = {
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,

    cdsServiceSetsDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsDelete: actions.cdsServiceSetsDelete,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomDialogActions));
