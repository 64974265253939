import axiosWrapper from "../../lib/axiosWrapper";
import { getPath } from "../../lib/utils";
import initialState from "../../redux/reducers/apps/initial-state";
import * as types from "./types";
import consoleLogger from "../../lib/consoleLogger";

export function appsCreate(
    config: any,
    url: string,
    data = {},
) {
    return function (dispatch) {
        dispatch({
            type: types.APPS_SELECTED_SET,
            payload: {
                status: "pending",
                data: initialState.selected.data,
                error: null
            },
        });
        return axiosWrapper(config.envApi, url, "put", data)
            .then((res) => {
                dispatch({
                    type: types.APPS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.APPS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: initialState.selected.data,
                        error: buildErrorObject(reason),
                    },
                });
                consoleLogger.error(":::", reason);
            })
    };
}

function buildErrorObject(errorInfo) {
    let invalidFields = [];
    let message = errorInfo.response?.data?.message ?? null;
    if (typeof message === 'string' && message.includes('smart launch proxy scopes')) {
        invalidFields.push('scopes');
        message = buildScopesErrorMessage(message);
    }
    return {
        message: message,
        status: errorInfo.response.data.status,
        invalidFields: invalidFields,
    };
}

function buildScopesErrorMessage(message) {
    // Right now a 500-level error is returned by the server when a user tries removing scopes from the app
    // that are included in a activation.  The error's message text includes a list of app scopes and a list of activated scopes.
    // This function extracts those lists from the text of the message, and then compares the lists to determine which
    // scopes should not have been removed from the app.
    // It may make sense to change the server code to have this type of error returned with a 400-level HTTP response rather than a 500-level response
    // ACTUAL MESSAGE: "App-Gateway smart launch proxy scopes 'launch patient/*.* openid profile patient/Observation.read patient/Condition.read' no a subset of app's scopes 'launch patient/*.* openid profile'"
    const sanitizedMessage = message.replace("app's", "apps"); // remove apostrophes from the text to make it easier to find the lists
    const scopesLists = sanitizedMessage.match(/'([^']+)'/g); // extract the two lists (they are each contained within sets of single quotes)
    const activatedScopesStr = scopesLists[0].replaceAll('\'', '');
    const appScopesStr = scopesLists[1].replaceAll('\'', '');;
    const activatedScopes = activatedScopesStr.split(' ');
    const appScopes = appScopesStr.split(' ');
    const missingScopes = activatedScopes.filter(f => !appScopes.includes(f));
    return "You may not remove the following scopes because they are included in an activation: " + missingScopes;
}

export function appsDelete(
    config: any,
    url: string,
) {
    return function (dispatch) {
        dispatch({
            type: types.APPS_SELECTED_SET,
            payload: {
                status: "pending",
                data: initialState.selected.data,
            },
        });
        return axiosWrapper(config.envApi, url, "delete")
            .then((res) => {
                dispatch({
                    type: types.APPS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: initialState.selected.data,
                    },
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.APPS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: initialState.selected.data,
                    },
                });
                consoleLogger.error(":::", reason);
            });
    };
}

export function appsRead(
    config: any,
    url: string,
) {
    return function (dispatch) {
        dispatch({
            type: types.APPS_SELECTED_SET,
            payload: {
                status: "pending",
                data: initialState.selected.data,
            },
        });
        return axiosWrapper(config.envApi, url, "get")
            .then((res) => {
                dispatch({
                    type: types.APPS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.APPS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: initialState.selected.data,
                    },
                });
                consoleLogger.error(":::", reason);
            });
    };
}

export function appsReadAll(
    config: any,
    url: string,
) {
    return function (dispatch) {
        dispatch({
            type: types.APPS_ALL_SET,
            payload: {
                status: "pending",
                data: initialState.all.data,
            },
        });
        return axiosWrapper(config.envApi, url, "get")
            .then((res) => {
                dispatch({
                    type: types.APPS_ALL_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.APPS_ALL_SET,
                    payload: {
                        status: "error",
                        data: initialState.all.data,
                    },
                });
                consoleLogger.error(":::", reason);
            });
    };
}

export function appsGenerateLogToken(
    config: any,
    url: string,
) {
    return function (dispatch) {
        dispatch({
            type: types.APPS_GENERATING_LOG_TOKEN
        });
        return axiosWrapper(config.envApi, url, "put")
            .then(res => {
                dispatch({
                    type: types.APPS_SET_LOG_TOKEN,
                    payload: {
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.APPS_SET_LOG_TOKEN_ERROR,
                    payload: {
                        status: "error",
                        data: initialState.all.data,
                    },
                });
                consoleLogger.error(":::", reason);
            });
    };
}

export function appsDeleteLogToken(
    config: any,
    url: string,
) {
    return function (dispatch) {
        dispatch({
            type: types.APPS_DELETING_LOG_TOKEN
        });
        return axiosWrapper(config.envApi, url, "delete")
            .then(res => {
                dispatch({
                    type: types.APPS_DELETE_LOG_TOKEN,
                    payload: {
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.APPS_DELETE_LOG_TOKEN_ERROR,
                    payload: {
                        status: "error",
                        data: initialState.all.data,
                    },
                });
                consoleLogger.error(":::", reason);
            });
    };
}

export const appsUpdate = appsCreate;
