import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import RecentLogins from "./RecentLogins";
// import RecentAccess from "./RecentAccess";
import axiosWrapper from '../../../../lib/axiosWrapper';
import {isAccountOwner} from "../../../../lib/user-roles-helpers";
import Header from "../../../Widgets/Editor/Header";
import {Card, CardContent} from "@material-ui/core";

let moment = require("moment-timezone");

const INIT_STATE = {
    status: "",
    users: [],
    selectedUser: {},
    dataValidation: {},
    recentLogins: [],
    sortBy: {
        role: false
    },
    filters: {
        pageSize: 50,
        page: 1,
        totalElements: 0,
    },
    recentLoginsTimeFilters: {
        timeframe: 0,
        startDate: null,
        endDate: null
    },
    mode: ":VIEW:",
    tab: "LOGINS"
};

class Component extends React.Component<any, any> {
    public static propTypes = {
        accounts: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        accountsAccountUsersRead: PropTypes.func.isRequired,
        ui: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.setState({
            recentLoginsTimeFilters: {
                ...this.state.recentLoginsTimeFilters,
                endDate: new Date(),
                startDate: this.getStartDate(new Date()),
            }
        }, () => {
            this.initialize();
        });
    }

    private getStartDate(endDate) {
        let midnight = moment.tz(endDate - this.state.recentLoginsTimeFilters.timeframe, this.props.ui.timeZone).startOf("day");
        return new Date(midnight.format());
    }

    public componentDidUpdate(_, prevState) {
        if (this.state.recentLoginsTimeFilters.timeframe !== prevState.recentLoginsTimeFilters.timeframe) {
            if (this.state.recentLoginsTimeFilters.timeframe >= 0) {
                this.setState(
                    {
                        recentLoginsTimeFilters: {
                            ...this.state.recentLoginsTimeFilters,
                            endDate: new Date(),
                            startDate: this.getStartDate(new Date()),
                        }
                    },
                    () => {
                        this.initialize();
                    })
            }
        }

        if (
            this.state.recentLoginsTimeFilters.timeframe === -1 &&
            (prevState.recentLoginsTimeFilters.endDate.valueOf() !== this.state.recentLoginsTimeFilters.endDate.valueOf()
            || prevState.recentLoginsTimeFilters.startDate.valueOf() !== this.state.recentLoginsTimeFilters.startDate.valueOf())
        ) {
            this.initialize();
        }

        const filters_string = JSON.stringify(this.state.filters);
        const prevFilters_string = JSON.stringify(prevState.filters);

        if (filters_string !== prevFilters_string) {
            this.initialize();
        }

    }

    public render() {
        const content = this.state.tab === "LOGINS"
            ? <RecentLogins {...this.props} {...this.state} onSetState={this.onSetState} initialize={this.initialize}/> : null
            // : <RecentAccess {...this.props} {...this.state} />;

        return <div style={{width: "100%"}}>
            <div className="container">
                {!isAccountOwner(this.props.login, this.props.config)
                    ? <Card style={{marginTop: "48px"}}>
                        <Header {...this.props} style={{height: "32px", padding: "8px 16px", borderTop: "none"}}/>
                        <CardContent style={{paddingTop: 0}}>
                            <p style={{textAlign: "center"}}>
                                <big>
                                    <strong>
                                        This screen is only available to the account owners
                                    </strong>
                                </big>
                            </p>
                        </CardContent>
                    </Card>
                    :<div>
                        <div className="sub-tabs">
                            <div className={`tab ${this.state.tab === "LOGINS" ? "selected" : ""}`} onClick={() => this.setState({tab: "LOGINS"})}>
                                Recent Logins
                            </div>
                            {/* <div className={`tab ${this.state.tab === "ACCESS" ? "selected" : ""}`} onClick={() => this.setState({tab: "ACCESS"})}>
                                Access Log
                            </div> */}
                        </div>
                        {content}
                    </div>}
            </div>
            <br/>
        </div>;
    }

    // Initialize --------------------------------------------------------------
    public initialize = (page = this.state.filters.page) => {
        isAccountOwner(this.props.login, this.props.config) && this.setState(
            {status: "init"},
            async () => {
                await axiosWrapper(this.props.config.accountApi, `accounts/current/recent-logins`, 'POST', { size: this.state.filters.pageSize, page, startDate: this.state.recentLoginsTimeFilters.startDate, endDate: this.state.recentLoginsTimeFilters.endDate })
                    .then(async (res) => {
                        this.setState({
                            status: "ready",
                            recentLogins: res.data.recentLogins,
                            filters: {
                                ...this.state.filters,
                                page,
                                totalElements: res.data.totalElements
                            }
                        })
                    });
            }
        );
    }

    private onSetState = (state, cb) => this.setState(state, cb);
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Component));
