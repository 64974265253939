export default [
    'AllergyIntolerance',
    'Binary',
    'CarePlan',
    'CareTeam',
    'Condition',
    'Condition(aka Problem)',
    'Device',
    'DiagnosticReport',
    'DocumentReference',
    'Encounter',
    'Goal',
    'Immunization',
    'Location',
    'Medication',
    'MedicationRequest',
    'Observation',
    'Organization',
    'Patient',
    'Practitioner',
    'PractitionerRole',
    'Procedure',
    'Provenance',
    'ValueSet',
]
