import * as React from "react";
import * as PropTypes from "prop-types";
import {DialogContent} from "@material-ui/core";

const Component: React.FunctionComponent<any> = props => {
    return <DialogContent style={{background: "rgb(250, 250, 250)", padding: "0 0 24px 0"}}>
        <img src={"/console/img/collaboration.png"} style={{ width: "100%" }}/>
        <p style={{padding: "0 24px", textAlign: "center"}}>
            {props.state.dataValidation.email}
        </p>
    </DialogContent>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired,
};

export default Component;
