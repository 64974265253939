import * as React from "react";
import * as PropTypes from "prop-types";
import {getFullDateDisplay, getPath} from "../../../../../../lib/utils/";
import {List} from "@material-ui/core";
import TransactionItem from "./AggregatedTransactionItem";
import Table, {FilterBy, FilterByDate} from "../../../../../Widgets/Table";
import getEnvironmentName from "../../../../../../lib/getEnvironmentName";
import axiosWrapper from "../../../../../../lib/axiosWrapper";
import consoleLogger from "../../../../../../lib/consoleLogger";
import CenteredCircularProgress from "../../../../../Widgets/CenteredCircularProgress";
const OPERATIONS_TYPE = [
    "READ",
    "VREAD",
    "SEARCH",
    "CREATE",
    "UPDATE",
    "PATCH",
    "DELETE",
    "HISTORY",
    "CAPABILITIES",
    "BATCH_TX",
    "EXTENDED",
]

const Component: React.FunctionComponent<any> = props => {
    const transactions = getPath(props, "state.aggregatedTransactions.data") || [];
    const rightFilters = getRightFilters(props);
    const content = getContent(props, transactions);
    const columns = getColumns(props);
    const exportList = [
        {
            func: () => exportCsv(props),
            label: "Export CSV"
        }
    ];
    // let pagination = {
    //     goToPage: props.transactions_init,
    //     loading: props.state.status !== "ready",
    //     size: props.state.filters.pageSize,
    //     number: props.state.transactionsPagination.number + 1,
    //     totalElements: props.state.transactionsPagination.totalElements?.value || props.state.transactionsPagination.totalElements,
    //     setPageSize: size => props.setFilterItem("pageSize", size)
    // };
    const total = <span style={{opacity: ".5"}}>Showing {transactions.length} transaction types</span>;

    return <Table includeMargins={false} columns={columns} total={total} export={exportList} rightFilters={rightFilters}>
        {props.state.status !== "recalc"
            ? <List>
                {content}
            </List>
            : <CenteredCircularProgress size={63} style={{padding: "24px"}}/>}
    </Table>;
}

const getRightFilters = props => {
    return [
        {
            type: "component",
            value: <FilterByDate {...props} key="time_filter" setFilterItem={(type, value, timeframe) => updateDate(props, type, value, timeframe)}/>
        },
        {
            type: "component",
            value: <FilterBy {...props} key="operations" label="Operation" allItems={OPERATIONS_TYPE}
                filterKey="operations" setFilterItem={props.setFilterItem}/>
        },
        {
            type: "component",
            value: <FilterBy {...props} key="resources" label="Resource" allItems={props.state.resourceFilterValues}
                filterKey="resources" setFilterItem={props.setFilterItem}/>
        }
    ]
}

const updateDate = (props, type, value, timeframe) => {
    if (type === "period") {
        props.onSetState({startDate: value.startTime, endDate: value.endTime, timeframe: -1});
    } else {
        props.onSetState({timeframe: timeframe});
    }
}

const exportCsv = props => {
    const accountName = props.accounts.selected.data.name;
    const environmentId = props.gateways.selected.data.environmentId;
    const environmentName = getEnvironmentName(environmentId, props.environments);
    const gatewayName = props.gateways.selected.data.name;
    axiosWrapper(props.config.analyticsService, "gtw-export-aggregated-csv", "POST", props.state.aggregatedTransactions.data, {}, "blob")
        .then((response) => {
            let fileName = `${accountName}_${environmentName}_${gatewayName}_gtw-aggregated-transactions-list-${getFullDateDisplay(Date.now(), props.ui.timeZone)}.csv`;
            if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                (window.navigator as any).msSaveOrOpenBlob(new Blob([response.data]), fileName);
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const sanitizedUrl = encodeURI(url);
                const link = document.createElement("a");
                link.href = sanitizedUrl;
                let sanitizedFileName = encodeURI(fileName);
                link.setAttribute("download", sanitizedFileName);
                document.body.appendChild(link);
                link.click();
            }
        })
        .catch((error) => {
            consoleLogger.log(error);
        });
};

const getColumns = props => {
    return [
        {
            size: 1,
            name: "resourceType",
            label: "Resource",
            sort: () => props.onSetState({aggregateSortBy: {["resourceType"]: !getPath(props, `state.aggregateSortBy.resourceType`)}}),
            order: getPath(props, "state.aggregateSortBy.resourceType")
        },
        {
            size: 1,
            name: "operationType",
            label: "Operation",
            sort: () => props.onSetState({aggregateSortBy: {["operationType"]: !getPath(props, `state.aggregateSortBy.operationType`)}}),
            order: getPath(props, "state.aggregateSortBy.operationType")
        },
        {
            size: 1,
            name: "avgTime",
            label: "Average Latency",
            sort: () => props.onSetState({aggregateSortBy: {["avgTime"]: !getPath(props, `state.aggregateSortBy.avgTime`)}}),
            order: getPath(props, "state.aggregateSortBy.avgTime")
        },
        {
            size: 1,
            name: "medianTime",
            label: "Median Latency",
            sort: () => props.onSetState({aggregateSortBy: {["medianTime"]: !getPath(props, `state.aggregateSortBy.medianTime`)}}),
            order: getPath(props, "state.aggregateSortBy.medianTime")
        },
        {
            size: 1,
            name: "count",
            label: "Count",
            sort: () => props.onSetState({aggregateSortBy: {["count"]: !getPath(props, `state.aggregateSortBy.count`)}}),
            order: getPath(props, "state.aggregateSortBy.count")
        }
    ]
}

const getContent = (props, transactions) => {
    if (!props.state.showAll) {
        transactions = transactions.slice(0, 100);
    }
    return transactions.map((transaction, index) => <TransactionItem key={`${transaction.timestamp}-${index}`} {...props} transaction={transaction}/>);
}

Component.propTypes = {
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired,
    transactions_init: PropTypes.func.isRequired,
    setFilterItem: PropTypes.func.isRequired,
};

export default Component;
