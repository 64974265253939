import * as React from "react";
import * as PropTypes from "prop-types";
import {CardActions, Button} from "@material-ui/core";

const Component: React.FunctionComponent<any> = props => {
    return <CardActions>
        <div style={{
            height: props.canEdit ? "36px" : 0,
            marginTop: props.canEdit ? "8px" : 0,
            marginBottom: props.canEdit ? "8px" : 0,
            textAlign: "right",
            width: "99%",
            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
        }}>
            <Button variant="outlined" disabled={!props.canEdit} style={{display: props.canEdit ? "inline-block" : "none", marginRight: "8px"}}
                onClick={() => props.onSetState(
                    {mode: ":VIEW:"},
                    props.initialize
                )}>
                Cancel
            </Button>
            <Button variant="contained" color="primary" disabled={!props.canEdit || !props.state.data.email || props.state.data.email === props.users.selected.data.email || !props.isValid}
                style={{display: props.canEdit ? "inline-block" : "none"}}
                onClick={() => props.onSetState({
                    changeEmailStatus: "",
                    dialog: ":EMAIL_CHANGE_CONFIRM:"
                })}>
                Save
            </Button>
        </div>
    </CardActions>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired,
    canEdit: PropTypes.bool,
    isValid: PropTypes.bool
};

Component.defaultProps = {
    canEdit: true,
    isValid: true
};

export default Component;
