import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../redux/actions";
import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../lib/getLocationInfo";
import getCdsServiceSetsCallUrl from "../../../../../../lib/getCdsServiceSetsCallUrl";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogActions from "./CustomDialogActions";
import CustomDialogContent from "./CustomDialogContent";
import CustomDialogTitle from "./CustomDialogTitle";

const WSTEP_MIN = 1;
const WSTEP_MAX = 3;

class CdsServiceSetAddNew extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        parentState: PropTypes.object.isRequired,
        cdsServiceSetsReadAll: PropTypes.func.isRequired,
        getGatewayJwkSet: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onSetParentState: PropTypes.func.isRequired,
    };

    public componentDidMount() {
        this.initialize();
        this.props.onSetParentState({wstep: WSTEP_MIN});
    }

    public render() {
        return <Dialog
            open
            classes={{paper: "io-modal-wrapper"}}
            style={{ paddingTop: '5px', zIndex: 1250 }}
            disableBackdropClick disableEscapeKeyDown scroll = "body"
            className={this.props.className}
            onClose={this.props.onClose}
        >
            <CustomDialogTitle onClose={this.props.onClose} />
            <CustomDialogContent
                parentState={this.props.parentState}
                onSetParentState={this.props.onSetParentState}
            />
            <CustomDialogActions
                parentState={this.props.parentState}
                WSTEP_MIN={WSTEP_MIN} WSTEP_MAX={WSTEP_MAX}
                calcNextWStep={this.calcNextWStep}
                calcPrevWStep={this.calcPrevWStep}
                initialize={this.props.initialize}
                onSetParentState={this.props.onSetParentState}
            />
        </Dialog >
    }

    private calcNextWStep = () => this.props.parentState.wstep < WSTEP_MAX ? this.props.parentState.wstep + 1 : WSTEP_MAX;

    private calcPrevWStep = () => this.props.parentState.wstep > WSTEP_MIN ? this.props.parentState.wstep - 1 : WSTEP_MIN;

    private initialize = () => {
        this.props.onSetParentState(
            {status: "w-init"},
            async () => {
                const {accountId} = getHostnameInfo();
                const {env} = getLocationInfo(this.props.location, this.props.config);
                const url = getCdsServiceSetsCallUrl(accountId, env);
                const [, getGatewayJwkSetResponse] = await Promise.all([
                    this.props.cdsServiceSetsReadAll(this.props.config, url),
                    this.props.getGatewayJwkSet()
                ]);
                this.props.onSetParentState({
                    status: "ready",
                    gatewayJwkSet: getGatewayJwkSetResponse?.data || {},
                });
            },
        );
    };
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsReadAll: actions.cdsServiceSetsReadAll,
    getGatewayJwkSet: actions.getGatewayJwkSet,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CdsServiceSetAddNew));
