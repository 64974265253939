import * as PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {IconButton, Tooltip} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import "./style.less";

const EhrListingItem = (props) => {
    const ehrName = props.ehrName || "";
    const ehrListingUrl = props.ehrListingUrl || "";

    const deleteItemHtml = (
        <div className="col-remove">
            <Tooltip placement="top" title="Remove">
                <IconButton
                    onClick={() => {
                        let newAppListings = [...props.data.appListings];
                        newAppListings.splice(props.itemIndex, 1);
                        props.onSetState({
                            data: {
                                ...props.data,
                                appListings: newAppListings
                            }
                        });
                    }}>
                    <Delete style={{color: props.muiTheme.palette.accent1Color}}/>
                </IconButton>
            </Tooltip>
        </div>
    );

    const listItemProps = {}
    if (!props.canEdit) {
        listItemProps['disabled'] = true;
    }
    return <ListItem
        {...listItemProps}
        className="ehr-listing-ul-item"
    >
        <div className="content">
            <div  className="col-1">{ehrName}</div>
            <div  className="col-2"><a href={ehrListingUrl.props.href} target="_blank">{ehrListingUrl.props.href}</a></div>
            {props.canEdit && deleteItemHtml}
        </div>
    </ListItem>;
}

EhrListingItem.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object,

    onSetState: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,


    canEdit: PropTypes.bool,
    itemIndex: PropTypes.number,
    ehrName: PropTypes.string,
    ehrListingUrl: PropTypes.string,
    created: PropTypes.string,
    updated: PropTypes.string
}


const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    login: state.login,
    ui: state.ui,
    ...ownProps,
});

export default withRouter(connect(mapStateToProps)(withLegacyTheme()(EhrListingItem)));
