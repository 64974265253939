import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../../redux/actions";
import axiosWrapper from "../../../../../lib/axiosWrapper";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import getMessageFromResponse from "../../../../../lib/getMessageFromResponse";
import {getPath} from "../../../../../lib/utils/";
import {Link} from "react-router-dom";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import {TextField, Button, CircularProgress, Card, CardContent, CardHeader, CardActions} from "@material-ui/core";
import consoleLogger from "../../../../../lib/consoleLogger";

const jwtDecode = require("jwt-decode");

const INIT_STATE = {
    status: "",
    accountData: {},
    data: {
        newEmail: ""
    },
    confirmationStatus: ""
};

class Component extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        loginSet: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        const {accountId} = getHostnameInfo();
        if (!accountId) {
            this.props.history.push("/signin");
        }
        this.initialize();
    }

    public render() {
        if (["ready", "wait"].indexOf(this.state.status) < 0) {
            return <div className="container" style={{textAlign: "center"}}>
                <CircularProgress size={63} style={{padding: "24px"}}/>
            </div>;
        }

        const {protocol, accountId, domain, type, port} = getHostnameInfo();

        return <div className="container">
            <Card style={{width: "480px", marginTop: "48px", marginRight: "auto", marginLeft: "auto", paddingBottom: "16px"}}>
                <div>
                    <CardHeader
                        title={<div>
                            <h2 style={{margin: "32px 0 0 0", textAlign: "center"}}>
                                <span>Confirm email change</span>&nbsp;
                            </h2>
                            <p style={{margin: "8px 0 16px 0", textAlign: "center", fontSize: "14px"}}>
                                {`${accountId}.${domain}.${type}`}
                            </p>
                        </div>}
                        subheader={<p style={{margin: 0, color: this.props.muiTheme.palette.textColor, textAlign: "center", fontSize: "16px"}}>
                            <span>Confirm your</span>&nbsp;
                            <strong><big>new email</big></strong>&nbsp;
                            <span>for</span>&nbsp;
                            <strong><big>{this.state.accountData.name}</big></strong>
                        </p>}/>

                    <CardContent>
                        <TextField name="newEmail" type="email" value={this.state.data.newEmail || ""} label="Your new email" disabled fullWidth classes={{root: "disabled-text-field"}}
                            style={{cursor: "default"}} />

                        <div style={{
                            marginTop: "16px",
                            padding: this.state.confirmationStatus ? "8px" : 0,
                            transform: this.state.confirmationStatus ? "scaleY(1)" : "scaleY(0)",
                            transformOrigin: "top",
                            borderRadius: "2px",
                            background: this.props.muiTheme.palette.accent1Color,
                            color: "#fff",
                            textAlign: "center",
                            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
                        }}>
                            {this.state.confirmationStatus}
                        </div>
                    </CardContent>

                    <CardActions style={{padding: "16px"}}>
                        <Button variant="contained" color="primary" disabled={this.state.status !== "ready" || !!this.state.confirmationStatus || !this.state.data.newEmail} fullWidth
                            onClick={() => {
                                const token = this.getToken();
                                this.setState(
                                    {status: "wait"},
                                    () => {
                                        const data = {token};
                                        axiosWrapper(this.props.config.accountApi, "users/current/change-email-confirm", "POST", data)
                                            .then(response => {
                                                this.props.history.push("/home");
                                                this.props.loginSet({status: "logged-in", data: getPath(response, "data") || {}});
                                            })
                                            .catch(reason => {
                                                this.setState({
                                                    status: "ready",
                                                    confirmationStatus: getMessageFromResponse(reason, true)
                                                });
                                                consoleLogger.error(":::", reason);
                                            });
                                    }
                                );
                            }}>
                            {["init", "wait"].indexOf(this.state.status) > -1
                                ? <CircularProgress size={24} style={{marginTop: "5px"}}/>
                                : "Confirm"}
                        </Button>
                    </CardActions>
                </div>
            </Card>

            <div style={{marginTop: "48px", textAlign: "center"}}>
                <strong>
                    <Link to="/signin" style={{fontSize: "16px", textDecoration: "none"}}>
                        Return to login page
                    </Link>
                </strong>
                &nbsp;·&nbsp;
                <strong>
                    <a href="#!" style={{fontSize: "16px", textDecoration: "none"}}
                        onClick={event => {
                            event.preventDefault();
                            window.location.href = `${protocol}//${domain}.${type}${port ? ":" + port : ""}/console/signin`;
                        }}>
                        Sign into another account?
                    </a>
                </strong>
                <br/><br/>

                Trying to create an account?&nbsp;
                <strong>
                    <a href="#!" style={{fontSize: "16px", textDecoration: "none"}}
                        onClick={event => {
                            event.preventDefault();
                            window.location.href = `${protocol}//${domain}.${type}${port ? ":" + port : ""}/console/accounts/request`;
                        }}>
                        Create a new account
                    </a>
                </strong>
                <br/>
            </div>
        </div>;
    }

    private getToken = () => (getPath(this.props, "location.search") || "").replace("?token=", "");

    private initialize = () => {
        const token = this.getToken();
        this.setState(
            {status: "init"},
            () => {
                const {accountId} = getHostnameInfo();
                axiosWrapper(this.props.config.accountApi, `accounts/public?path=${accountId}`, "GET")
                    .then(accountData => {
                        const newEmail = getPath(jwtDecode(token), "emailChange.newEmail") || "";
                        this.setState({
                            status: "ready",
                            data: {
                                ...this.state.data,
                                newEmail
                            },
                            accountData: accountData && accountData.data || {}
                        })
                    })
                    .catch(reason => {
                        this.setState({
                            status: "ready",
                            confirmationStatus: getMessageFromResponse(reason, true)
                        });
                        consoleLogger.error(":::", reason);
                    });
            }
        );
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Component));
