import * as React from "react";
import withLegacyTheme from "../../../../../../../../../lib/hoc/with-legacy-theme";
import { Theme, withTheme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

interface IProps {
    muiTheme: any;
    theme: Theme;
    onClose: React.MouseEventHandler<HTMLButtonElement>;
};

const CustomDialogTitle: React.FC<IProps> = (props: IProps) => {
    return (
        <DialogTitle disableTypography style={{color: props.theme.palette.primary.contrastText, background: props.muiTheme.palette.primary1Color}}>
            <Typography variant="h6">
                Confirm Before Proceed
            </Typography>
            <IconButton
                style={{ position: "absolute", right: props.theme.spacing(1), top: props.theme.spacing(1), color: props.theme.palette.primary.contrastText }}
                onClick={props.onClose}
            >
                <Tooltip title="Close"><CloseIcon /></Tooltip>
            </IconButton>
        </DialogTitle>
    );
}

export default withTheme(withLegacyTheme()(CustomDialogTitle));
