import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Link} from "react-router-dom";
import * as actions from "../../../../redux/actions";
import Details from "./Details";
import Apps from "./Apps";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import {NavbarL2, NavbarL3} from "../../../CustomNavbar";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress";
import getLocationInfo from "../../../../lib/getLocationInfo";
import {getPath} from "../../../../lib/utils";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getPortalsCallUrl from "../../../../lib/getPortalsCallUrl";
import getGatewaysCallUrl from "../../../../lib/getGatewaysCallUrl";
import PortalAddNewAppLaunchWizard from "./Apps/PortalAddNewAppLaunchWizard";
import WidgetsDeleteConfirm from "../../../Widgets/DeleteConfirm";
import getActiveEnvironment from "../../../../lib/getActiveEnvironment";
import getPortalCallUrl from "../../../../lib/getPortalCallUrl";
import Selector from "../../../CustomNavbar/Selector";
import {Web} from "@material-ui/icons";
import axiosWrapper from "../../../../lib/axiosWrapper";
import getPortalId from "../../../../lib/getPortalId";

let TABS: Array<any> = [
    {title: "DETAILS", "data-qa": "portal-details", error: false},
    {title: "APPS", "data-qa": "portal-apps", error: false}
];
const TAB_SELECTED_BY_DEFAULT = 0;

const activeEnv = getActiveEnvironment();
[":LOCAL:", ":DEVELOPMENT:"].includes(activeEnv) && (TABS = TABS.concat([
    {title: "CDS HOOKS", error: false},
    {title: "SMART WEB MESSAGING", error: false},
    {title: "USAGE", error: false},
    {title: "AUDIT LOG", error: false}
]));

const INIT_STATE = {
    status: "",
    appsPerGateway: [],
    data: {
        context: "provider"
    },
    gateways: [],
    dataValidation: {},
    jwkSetString: "",
    mode: ":VIEW:",
    showAll: false,
    transactions: false
};

class Component extends React.Component<any, any> {
    public static propTypes = {};

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.props.uiTabsSetItems(TABS);
        this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
        this.initialize();
    }

    public componentWillUnmount() {
        const { env } = getLocationInfo(this.props.location, this.props.config)
        const { accountId } = getHostnameInfo()
        const gwUrl: string = getGatewaysCallUrl(accountId, env);
        this.props.gatewaysReadAll(this.props.config, gwUrl)
    }

    public render() {
        const {env, portalId: selectedPortal} = getLocationInfo(this.props.location, this.props.config);
        const fullContent = ((this.props.ui.tabs.selected === 3) || this.props.ui.tabs.selected === 4 || this.props.ui.tabs.selected === 5 || this.props.ui.tabs.selected === 6);
        return <div style={{width: "100%"}}>
            <NavbarL2 title={(
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{height: "56px", marginRight: "24px"}}>
                        <Link to={`/${env}/portals`} style={{color: "#fff", fontSize: "24px", textDecoration: "none"}}>
                            Portals
                        </Link>
                    </div>
                    <Selector data-qa="navbar-portal-selector-name" value={selectedPortal} icon={<Web style={{ color: "rgba(255, 255, 255, 0.87)" }} />}
                        data={this.props.portals.all ? this.props.portals.all.data.map(a => Object.assign({id: getPortalId(a), name: a.name}, a)) : []}
                        onChange={item => {
                            this.props.history.push(`/${env}/portals/${item.id}`);
                            this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
                            this.initialize();
                        }}/>
                </div>
            )}/>

            <NavbarL3 disableAll={["ready", "wait"].indexOf(this.state.status) < -1}/>

            <div className={`container${fullContent ? " full" : ""}`}>
                {this.renderContent()}
            </div>
            <br/>
            {this.renderDialogs()}
        </div>
    }

    private renderDialogs = () => {
        switch (this.state.dialog) {
            case ":ADD_NEW_APP_LAUNCH:":
                return <PortalAddNewAppLaunchWizard onSetState={this.onSetState} {...this.props} state={this.state} onClose={reload => {
                    this.setState({dialog: "", data: {}, update: false});
                    reload && this.initialize();
                }}/>;
            case ":EDIT_APP_LAUNCH:":
                return <PortalAddNewAppLaunchWizard onSetState={this.onSetState} {...this.props} state={this.state} edit onClose={reload => {
                    this.setState({dialog: "", data: {}, update: false});
                    reload && this.initialize();
                }}/>;

            case ":DELETE_CONFIRM:":
                return <WidgetsDeleteConfirm {...this.props} type="portal context" delete={this.state.delete}
                    onClose={() => this.setState({
                        dialog: "",
                        delete: {}
                    })}
                    onDelete={async () => {
                        const {accountId} = getHostnameInfo();
                        const {env} = getLocationInfo(this.props.location, this.props.config);
                        const gatewayId = getPath(this.props, "portals.selected.data.gatewayId")
                        const portalType = getPath(this.props, "portals.selected.data.portalType")
                        const portalUrl = getPortalCallUrl(accountId, env, gatewayId, portalType)
                        const portalId = getPortalId(this.props.portals.selected.data)
                        const portalContext = portalType === 'PROVIDER' ? 'providerPortalContext' : 'patientPortalContext'
                        const portalContextId = this.state.delete.providerPortalContextId || this.state.delete.patientPortalContextId
                        this.props.portalsDelete(this.props.config, `${portalUrl}/${portalId}/${portalContext}/${portalContextId}`)
                            .then(() => {
                                this.setState({dialog: "", delete: undefined});
                                this.initialize(true);
                            });
                    }}
                />;

            default:
                return null;
        }
    };

    private onSetState = (state, cb) => this.setState(state, cb);

    private renderContent() {
        let content = null;
        switch (this.props.ui.tabs.selected) {
            case 0:
                content = <Details onSetState={this.onSetState} {...this.props} state={this.state} initialize={this.initialize}/>;
                break;
            case 1:
                content = <Apps state={this.state} onSetState={this.onSetState} {...this.props} />
                break;
        }

        return ["init", "wait"].indexOf(this.state.status) > -1 ? <CenteredCircularProgress size={63} style={{padding: "24px"}}/> : content;
    }

    // 1. The following attempt to reduce unnecessary API calls does not work properly,
    // causing various problems.
    // The whole process of reducing screen slowness will be part of future tasks.
    // For now, I'm turning off this feature as a quick fix for IO-4416.

    // 2. TODO: The whole initialization method needs to be refactored,
    // there is a lot of room for improvement here.
    // Especially in these chained requests where
    // an unnecessary network waterfall effect is created.

    // private initialize = (reload = false) => {
    //     const {env} = getLocationInfo(this.props.location, this.props.config);
    //     this.setState(
    //         {status: "init"},
    //         async () => {
    //             const {accountId} = getHostnameInfo();

    //             const gwUrl: string = getGatewaysCallUrl(accountId, env);

    //             let portalsUrl = getPortalsCallUrl(accountId, env);
    //             this.props.portalsReadAll(this.props.config, portalsUrl)
    //                 .then(async portals => {
    //                     const responses = await Promise.all([
    //                         this.props.gatewaysReadAll(this.props.config, `${gwUrl}?userStrategy=Interopio`)
    //                     ]);

    //                     const [{data: gateways = []} = {}] = responses;

    //                     let portal = portals.data.find(i => i.providerPortalId === getPath(this.props, "match.params.portalId"));
    //                     let selected = getPath(this.props, "portals.selected.data");
    //                     (shouldSelect || reload) && this.props.portalSelect(this.props.config, accountId, env, portal); <- this causes even more issue. Below, I'm going to apply a quick fix, but this whole method needs to be refactored
    //                     let shouldSelect = Object.keys(selected).length === 0 || portal.providerPortalId !== selected.providerPortalId;

    //                     this.setState({status: "ready", gateways, data: {...portal, context: "provider"}});
    //                 });
    //         }
    //     );
    // }
    // TODO:
    private initialize = (reload = true) => {
        reload // TODO:
        this.setState(
            { status: 'init' },
            async () => {
                const { env } = getLocationInfo(this.props.location, this.props.config)
                const { accountId } = getHostnameInfo()
                let portalsUrl = getPortalsCallUrl(accountId, env)
                const portalsRes = await this.props.portalsReadAll(this.props.config, portalsUrl)
                // TODO: have a call to the BE to get a specific portal and use it here with the artifactsIds info when making an initial load
                const portalsData = portalsRes?.data || []

                const portalData = portalsData.find((it) => getPortalId(it) === this.props.match?.params?.portalId)
                this.props.portalSelect(this.props.config, accountId, env, portalData)

                const gwUrl: string = getGatewaysCallUrl(accountId, env);
                const gatewayRes = await axiosWrapper(this.props.config.envApi, `${gwUrl}/${portalData.gatewayId}`, 'GET')
                const gatewayData = gatewayRes?.data || {}
                const context = (portalData.portalType || '').toLowerCase()
                this.setState({
                    status: 'ready',
                    gateway: gatewayData,
                    data: {
                        ...portalData,
                        context,
                    }
                })
            }
        )
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Component));
