import * as React from "react";
import * as PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {getFullDateDisplay} from "../../../../lib/utils";
import CenteredCircularProgress from "../../CenteredCircularProgress";

class CustomBody extends React.Component<any, any> {
    public static propTypes = {
        logState: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
        ui: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired,
        uiTabsSetSelected: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {active: []};
    }

    public componentDidUpdate(prevProps) {
        prevProps.logState.expandAll !== this.props.logState.expandAll && this.setState({active: []});
    }

    public render() {
        return <div>
            {!this.props.logState.isRefreshing
                ? this.props.logState.data.logs.length
                    ? this.props.logState.data.logs.map((log, index) => {
                        let isActive = this.state.active.indexOf(index) >= 0;
                        this.props.logState.expandAll && (isActive = !isActive);
                        return <div key={`log-item-${log.timestamp}-${index}`} className={`logs-h2-header log-item${isActive ? " active" : ""}`}>
                            <div className="log-time">{getFullDateDisplay(log.timestamp, this.props.ui.timeZone)}</div>
                            <div className="log-gateway-id">{log.gateway_id}</div>
                            <div
                                className={`log-session-id${this.props.type === 'app' ? ' log-item-clickable' : ''}`}
                                onClick={() => {
                                    if (this.props.type === 'app') {
                                        this.props.onSetState({
                                            selectedSessionId: log.session_id,
                                            selectedMetadata: '',
                                        }, () => {
                                            this.props.uiTabsSetSelected(4)
                                        })
                                    }
                                }}
                            >
                                {log.session_id}
                            </div>
                            <div className="log-type">{log.log_level}</div>
                            <div
                                className={`log-metadata${this.props.type === 'app' ? ' log-item-clickable' : ''}`}
                                onClick={() => {
                                    if (this.props.type === 'app') {
                                        this.props.onSetState({
                                            selectedSessionId: '',
                                            selectedMetadata: log.metadata,
                                        }, () => {
                                            this.props.uiTabsSetSelected(4)
                                        })
                                    }
                                }}
                            >
                                {log.metadata}
                            </div>
                            {this.props.type === "app" ? <div className="log-system-event">{log.system_log_event}</div> : null}
                            <div className="log-message">{log.body}</div>
                            <IconButton
                                disableRipple
                                style={{ background: "none" }}
                                onClick={() => this.toggleItem(index)}
                            >
                                {!isActive ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </IconButton>
                        </div>;
                    })
                    : <div className="logs-h2-header"><span className="no-data-returned">No data matches your search criteria.</span></div>
                : <CenteredCircularProgress size={63} style={{padding: "24px"}}/>}
        </div>;
    }

    private toggleItem = (index) => {
        let indexOf = this.state.active.indexOf(index);
        let active = this.state.active.slice();
        indexOf >= 0 ? active.splice(indexOf, 1) : active.push(index);
        this.setState({active});
    };
}

export default CustomBody;
