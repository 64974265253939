import * as PropTypes from "prop-types";
import WStep1 from "./WStep1";

const WSteps = (props) => {
    const propSet = {
        wstate: props.wstate,
        onSetWState: props.onSetWState,
    };
    switch (props.wstate.wstep) {
        case 1: return <WStep1 {...propSet} />;
        default: return null;
    }
}

WSteps.propTypes = {
    wstate: PropTypes.object.isRequired,
    onSetWState: PropTypes.func.isRequired,
};

export default WSteps;
