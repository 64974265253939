import * as React from "react";
import * as PropTypes from "prop-types";
import {Step, Stepper, StepLabel} from "@material-ui/core";
import CenteredCircularProgress from "../../../../../../Widgets/CenteredCircularProgress/";
import WStep1 from "./WStep1";
import WStep2 from "./WStep2";
import WStep3 from "./WStep3";
import WStep4 from "./WStep4";
import WStep5 from "./WStep5";

const Component: React.FunctionComponent<any> = props => {
    return <div>
        <Stepper activeStep={props.state.wstep - 1} style={{backgroundColor: "transparent"}}>
            <Step>
                <StepLabel>Basic Info</StepLabel>
            </Step>
            <Step>
                <StepLabel>Security</StepLabel>
            </Step>
            <Step>
                <StepLabel>Scopes</StepLabel>
            </Step>
            <Step>
                <StepLabel>URIs</StepLabel>
            </Step>
            <Step>
                <StepLabel>Summary</StepLabel>
            </Step>
        </Stepper>

        {props.state.status !== "ready"
            ? <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
            : renderWStep(props)}
    </div>;
}

Component.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired
};

export default Component;

function renderWStep(props) {
    switch (props.state.wstep) {
        case 1:
            return <WStep1 {...props} />;
        case 2:
            return <WStep2 {...props} />;
        case 3:
            return <WStep3 {...props} />;
        case 4:
            return <WStep4 {...props} />;
        case 5:
            return <WStep5 {...props} />;
        default:
            return null;
    }
}
