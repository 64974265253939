import $ from "jquery";
import * as types from "./types";
import initialState from "../../redux/reducers/environments/initial-state";
import { getPath } from "../../lib/utils";
import axiosWrapper from "../../lib/axiosWrapper";
import consoleLogger from "../../lib/consoleLogger";

export function environmentsCreate(
    config: any,
    url: string,
    data = {},
) {
    return function (dispatch) {
        dispatch({
            type: types.ENVIRONMENTS_SELECTED_SET,
            payload: {
                status: "pending",
                data: $.extend(true, {}, initialState.selected.data),
            }
        });
        return axiosWrapper(config.envApi, url, "put", data)
            .then((res) => {
                dispatch({
                    type: types.ENVIRONMENTS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    }
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: types.ENVIRONMENTS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: initialState.all.data,
                    }
                });
                consoleLogger.error(error);
            })
        ;
    };
}

export function environmentsDelete(
    config: any,
    url: string,
) {
    return function (dispatch) {
        dispatch({
            type: types.ENVIRONMENTS_SELECTED_SET,
            payload: {
                status: "pending",
                data: $.extend(true, {}, initialState.selected.data),
            }
        });
        return axiosWrapper(config.envApi, url, "delete")
            .then((res) => {
                dispatch({
                    type: types.ENVIRONMENTS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: $.extend(true, {}, initialState.selected.data),
                    }
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: types.ENVIRONMENTS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, {}, initialState.selected.data),
                    }
                });
                consoleLogger.error(error);
            })
        ;
    };
}

export function environmentsRead(
    config: any,
    url: string,
) {
    return function(dispatch) {
        dispatch({
            type: types.ENVIRONMENTS_SELECTED_SET,
            payload: {
                status: "pending",
                data: $.extend(true, {}, initialState.selected.data),
            }
        });
        return axiosWrapper(config.envApi, url, "get")
            .then((res) => {
                dispatch({
                    type: types.ENVIRONMENTS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    }
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: types.ENVIRONMENTS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, {}, initialState.selected.data),
                    }
                });
                consoleLogger.error(error);
            })
        ;
    };
}

export function environmentsReadAll(
    config: any,
    url: string,
) {
    return function (dispatch) {
        dispatch({
            type: types.ENVIRONMENTS_ALL_SET,
            payload: {
                status: "pending",
                data: $.extend(true, [], initialState.all.data),
            },
        });
        return axiosWrapper(config.envApi, url, "get")
            .then((res) => {
                dispatch({
                    type: types.ENVIRONMENTS_ALL_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.ENVIRONMENTS_ALL_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, [], initialState.all.data),
                    },
                });
                consoleLogger.error(reason);
            })
        ;
    };
}

export const environmentsUpdate = environmentsCreate;
