import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../../redux/actions";
import axiosWrapper from "../../../../../lib/axiosWrapper";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import * as validateData from "../../../../../lib/validate-users-data";
import {Link} from "react-router-dom";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import {TextField, Button, CircularProgress, Card, CardContent, CardHeader, CardActions} from "@material-ui/core";
import consoleLogger from "../../../../../lib/consoleLogger";

const INIT_STATE = {
    status: "ready",
    data: {
        email: ""
    },
    dataValidation: {
        email: ""
    }
};

class UserAccountList extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = INIT_STATE;
    }

    public componentDidMount() {
        const {accountId} = getHostnameInfo();
        if (accountId) {
            this.props.history.push("/home");
        }
    }

    public componentDidUpdate(_, prevState) {
        if (JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.validateData();
        }
    }

    public render() {
        return <div className="container">
            <Card style={{width: "480px", marginTop: "48px", marginRight: "auto", marginLeft: "auto", padding: "28px", borderRadius: "8px"}}>
                {this.state.status === "email-sent"
                    ? this.renderEmailSentMessage()
                    : this.renderEnterEmail()}
            </Card>

            {this.renderAdditionalLinks()}
        </div>;
    }

    private renderEnterEmail = () => {
        return <div>
            <CardHeader title={<div>
                <h2 style={{margin: "4px 0 24px", textAlign: "center"}}>
                    Find your account
                </h2>
                <p style={{margin: "8px 0 16px 0", textAlign: "center", fontSize: "14px", lineHeight: "20px"}}>
                    We will send you an email with list of all interopiO accounts associated with the address you enter below.
                </p>
            </div>}
            subheader={<p style={{margin: 0, color: this.props.muiTheme.palette.textColor, textAlign: "center", fontSize: "16px"}}>
                            Enter your&nbsp;
                <strong><big>email address</big></strong>&nbsp;
            </p>}/>

            <CardContent>
                <TextField name="email" type="email" value={this.state.data.email || ""} label="Email" placeholder="your-email@example.com"
                    error={!!this.state.dataValidation.email} helperText={!!this.state.dataValidation.email} disabled={this.state.status !== "ready"} autoComplete="off" fullWidth
                    onChange={e => this.setState({
                        data: {
                            ...this.state.data,
                            email: e.target.value
                        }
                    })}/>
            </CardContent>

            <CardActions style={{padding: "16px"}}>
                <Button variant="contained" color="primary" fullWidth disabled={!this.state.data.email || this.state.status !== "ready" || !this.isDataValid()}
                    onClick={() => {
                        this.setState(
                            {status: "wait"},
                            () => {
                                axiosWrapper(this.props.config.accountApi, "users/account-list", "POST", this.state.data)
                                    .then(() => this.setState({status: "email-sent"}))
                                    .catch(reason => consoleLogger.error(":::", reason));
                            }
                        );
                    }}>
                    {this.state.status === "wait" ? <CircularProgress size={24} style={{marginTop: "5px"}}/> : "Send accounts list"}
                </Button>
            </CardActions>

            <CardContent style={{padding: "16px", textAlign: "center", fontSize: "16px"}}>
                <strong>
                    <a href="#!" style={{color: this.props.muiTheme.palette.disabledColor, fontSize: "16px", textDecoration: "none"}}>
                        Forgot which email you used?
                    </a>
                </strong>
            </CardContent>
        </div>;
    }

    private renderEmailSentMessage = () => {
        return <CardHeader
            title={<div>
                <h2 style={{margin: "32px 0 0 0", textAlign: "center"}}>
                    Find your account
                </h2>
                <p style={{margin: "8px 0 16px 0", textAlign: "center", fontSize: "14px"}}>
                    &nbsp;
                </p>
            </div>}
            subheader={<p style={{margin: 0, color: this.props.muiTheme.palette.textColor, textAlign: "center", fontSize: "16px"}}>
                <strong><big>We have sent you an email with list of all your interopiO accounts.</big></strong>
            </p>}
        />;
    }

    private renderAdditionalLinks = () => {
        return <div style={{marginTop: "48px", textAlign: "center"}}>
            <strong>
                <Link to="/signin" style={{fontSize: "16px", textDecoration: "none"}}>
                    Return to login page
                </Link>
            </strong>
        </div>;
    }

    private isDataValid = () => Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true);

    private validateData = () => {
        const dataValidation = validateData.onEnterEmail(this.state)
        this.setState({
            status: "ready",
            dataValidation: {
                ...this.state.dataValidation,
                ...dataValidation
            }
        });
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(UserAccountList));
