import * as React from "react";
import * as PropTypes from "prop-types";
import {CircularProgress, List} from "@material-ui/core";
import Header from "../../../../../../Widgets/Editor/Header/";
import UserItem from "../../../UsersAndPermissions/UserItem/";
import EnvironmentItem from "./EnvironmentItem/";
import HeaderContentL1 from "./HeaderContentL1/";
import HeaderContentL2 from "./HeaderContentL2/";
import {Search} from "@material-ui/icons";

const Component: React.FunctionComponent<any> = props => {
    const environments = props.dialogState.environments || [];

    return <div style={{width: "100%"}}>
        <div style={{padding: "0 16px"}}>
            <UserItem {...props} user={props.state.selectedUser} showMenu={false}/>
        </div>

        <Header {...props} leftIcon={props.dialogState.status === "recalc" ? <CircularProgress size={24}/> : <Search/>} style={{borderTop: "none"}}>
            <HeaderContentL1 {...props} />
        </Header>

        <Header {...props} style={{height: "32px", padding: "8px 16px", borderTop: "none"}}>
            <HeaderContentL2 {...props} />
        </Header>

        <List>
            {environments.map((environment, index) => <EnvironmentItem key={`environment-item-${environment.environmentId}-${index}`}{...props} environment={environment}/>)}
        </List>
    </div>
}

Component.propTypes = {
    dialogState: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired
};

export default Component;
