/* eslint-disable react/no-string-refs */
import * as React from "react";
import {readAndCompressImage} from "browser-image-resizer";
import {ChromePicker} from "react-color";

import {Button, Card, IconButton, MenuProps as MenuPropsType, Switch, TextField, Popover, CircularProgress, Select, MenuItem, Tooltip, FormControlLabel, InputLabel, FormControl} from "@material-ui/core";
// import Button from '@material-ui/core/Button'
import {Link, Edit, FileCopy} from "@material-ui/icons";

import ActionButtons from "./ActionButtons";
import Header from "../../../../Widgets/Editor/Header";
import CustomLink from "../../../../Widgets/CustomLink";
import ImageLoader from "../../../../Widgets/ImageLoader";

import getPortalResourceUrl from "../../../../../lib/getPortalResourceUrl";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../lib/getLocationInfo";
import uploadImage from "../../../../../lib/uploadImage";
import getPortalId from "../../../../../lib/getPortalId";
import getPortalConfig from "../../../../../lib/getPortalConfig";
import consoleLogger from "../../../../../lib/consoleLogger";
import * as validateData from '../../../../../lib/validate-portal-edit-data'
import {
    capitalizeFirstLetter,
    getPath,
} from "../../../../../lib/utils";

import "./style.less";

const COLORS = [
    {
        prop: "primary1Color",
        label: "Primary color"
    }
];

class Details extends React.Component<any, any> {

    public SelectMenuProps: Partial<MenuPropsType> = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };

    public constructor(props) {
        super(props)
        this.state = {
            dataValidation: {},
            isSaveEnabled: true,
        }
    }

    public componentDidMount() {
        this.validateData()
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.state.data) !== JSON.stringify(prevProps.state.data)) {
            this.validateData()
        }
    }

    public render() {
        const {env} = getLocationInfo(this.props.location, this.props.config);
        const {accountId} = getHostnameInfo();
        return this.renderTabContent(accountId, env, this.props.state.mode === ":EDIT:");
    };

    private renderTabContent = (accountId, env, canEdit) => {
        const selected = getPath(this.props, "portals.selected.data") || {};
        const gateway = this.props.state.gateway || {};
        const portalId = getPortalId(selected)
        const portalConfig = getPortalConfig(selected)
        const portalResourceUrl = getPortalResourceUrl(accountId, env, gateway.gatewayId, portalId, portalConfig, selected.shortcut || "");
        const isProviderPortal = selected.portalType === 'PROVIDER'

        return <Card style={{marginTop: "48px", borderRadius: "8px", overflow: "hidden"}}>
            <Header {...this.props} leftIcon={<Link style={{margin: "17px 12px"}}/>} style={{height: "60px", paddingTop: "14px"}}
                rightIcon={!canEdit
                    ? <Tooltip title="Edit" placement="bottom-end">
                        <IconButton style={{marginRight: "20px"}} onClick={() => this.props.onSetState({mode: ":EDIT:"})} data-qa="edit-portal-button">
                            <Edit style={{color: this.props.muiTheme.palette.secondaryTextColor}}/>
                        </IconButton>
                    </Tooltip>
                    : null}
                primaryText={<span style={{position: "relative", display: "inline-block"}}>
                    <a href={encodeURI(portalResourceUrl)} target="_blank"
                        style={{display: "inline-block", maxWidth: "530px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", lineHeight: "25px"}}>
                        {portalResourceUrl}
                    </a>
                    <Tooltip title="Copy" placement="bottom">
                        <IconButton onClick={() => this.handleCopy(portalResourceUrl)} style={{padding: 0, height: "24px", width: "24px", left: "16px", top: "-15px"}}>
                            <FileCopy className="portal-icon-copy" style={{color: this.props.muiTheme.palette.secondaryTextColor}}/>
                        </IconButton>
                    </Tooltip>
                </span>}/>
            <div style={{backgroundColor: "white", padding: "24px", verticalAlign: "top"}}>
                <div style={{display: "inline-block", width: "60%"}}>
                    <FormControlLabel label="Activate Portal"
                        control={<Switch disabled={!canEdit} checked={!!this.props.state.data.active || false} data-qa="portal-activation-toggle" color="primary"
                            onChange={e => this.props.onSetState(prevState => ({
                                data: {
                                    ...prevState.data,
                                    active: e.target.checked ? 1 : 0
                                }
                            }))}/>}/>
                    <div
                        data-e2e='portal-details-portal-type'
                        className='portal-type'
                    >
                        <span className='portal-type-descr'>
                            Portal Type:&nbsp;
                        </span>
                        <span data-e2e='portal-details-portal-type-value'>
                            {capitalizeFirstLetter((selected.portalType || '').toLowerCase())}
                        </span>
                    </div>
                    <TextField
                        data-qa="portal-name-input"
                        disabled={!canEdit}
                        error={!!this.state.dataValidation.name}
                        fullWidth
                        helperText={this.state.dataValidation.name}
                        label="Portal Name"
                        name="name"
                        placeholder="Type a name for the portal"
                        style={{marginTop: "24px"}}
                        value={this.props.state.data.name || ""}
                        onChange={e => this.props.onSetState({
                            data: {
                                ...this.props.state.data,
                                name: e.target.value
                            }
                        })}/>
                    <div className="portal-gateway-link-title">Supporting Gateway</div>
                    <CustomLink to={`/${env}/gateways/${gateway.gatewayId}`} style={{marginTop: "12px", marginBottom: "5px", display: "inline-block"}}>
                        {gateway.name}
                    </CustomLink>
                    <TextField
                        data-qa='portal-shortcut-input'
                        disabled={!canEdit}
                        error={!!this.state.dataValidation.shortcut}
                        fullWidth
                        helperText={this.state.dataValidation.shortcut}
                        label='Shortcut'
                        name='shortcut'
                        placeholder='Type a shortcut for the portal'
                        value={this.props.state.data.shortcut || ''}
                        style={{ marginTop: '24px' }}
                        onChange={e => this.props.onSetState({
                            data: {
                                ...this.props.state.data,
                                shortcut: e.target.value
                            }
                        })}/>
                    <FormControl fullWidth style={{ marginTop: '24px' }}>
                        <InputLabel>
                            {isProviderPortal ? 'Default Patient View App' : 'Default Health Records App'}
                        </InputLabel>
                        <Select
                            MenuProps={this.SelectMenuProps}
                            data-qa='portal-default-view-app'
                            disabled={!canEdit || !gateway.appActivations?.length}
                            onChange={(e) => this.props.onSetState({
                                data: {
                                    ...this.props.state.data,
                                    ...(isProviderPortal
                                        ? {
                                            patientViewApp: e.target.value,
                                        }
                                        : {
                                            patientHealthRecordsApp: e.target.value,
                                        }
                                    ),
                                },
                            })}
                            value={(this.props.state.data.patientViewApp || this.props.state.data.patientHealthRecordsApp) || ''}
                        >
                            <MenuItem value={""}/>
                            {gateway.appActivations && gateway.appActivations.map(app => app.appScopes.toLowerCase().indexOf("patient") >= 0 &&
                                <MenuItem key={app.appId} value={app.appId} data-qa={`portal-view-app-${app.appId}`}>{app.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {isProviderPortal && (
                        <>
                            <FormControl fullWidth style={{marginTop: "24px"}}>
                                <InputLabel>Order Entry App (CPOE)</InputLabel>
                                <Select
                                    data-qa='portal-order-app'
                                    disabled={!canEdit || !gateway.appActivations?.length}
                                    fullWidth
                                    MenuProps={this.SelectMenuProps}
                                    value={this.props.state.data.orderEntryApp || ''}
                                    onChange={e => this.props.onSetState({
                                        data: {
                                            ...this.props.state.data,
                                            orderEntryApp: e.target.value
                                        }}
                                    )}
                                >
                                    <MenuItem value={''}/>
                                    {gateway.appActivations && gateway.appActivations.map(app => app.appScopes.toLowerCase().indexOf("patient") >= 0 &&
                                        <MenuItem key={app.appId} value={app.appId} data-qa={`portal-order-app-${app.appId}`}>{app.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <FormControlLabel label="Simulate EHR Menu Items" style={{marginTop: "16px"}}
                                control={<Switch disabled={!canEdit} color="primary" checked={!!this.props.state.data.simulateEHRMenuItems || false}
                                    data-qa="portal-simulate-ehr-toggle"
                                    onChange={e => this.props.onSetState(prevState => ({
                                        data: {
                                            ...prevState.data,
                                            simulateEHRMenuItems: e.target.checked ? 1 : 0
                                        }
                                    }))}/>}/>
                            <FormControlLabel label="Enable Developer Tools" style={{marginTop: "16px", display: "block"}}
                                control={<Switch disabled={!canEdit} color="primary" checked={!!this.props.state.data.enableDeveloperTools || false}
                                    data-qa="portal-enable-dev-tools-toggle"
                                    onChange={e => this.props.onSetState(prevState => ({
                                        data: {
                                            ...prevState.data,
                                            enableDeveloperTools: e.target.checked ? 1 : 0
                                        }
                                    }))}/>}/>
                        </>
                    )}
                </div>
                <div style={{display: "inline-block", width: "40%", textAlign: "center", verticalAlign: "top"}}>
                    {this.renderLogo(canEdit)}
                    <div style={{width: "220px", marginTop: "8px", display: "inline-block", textAlign: "left", fontSize: "12px"}}>
                        The image file should be in PNG or GIF format, up to 640 x 264 pixels and no bigger than 200KB. You must own the rights to the image.
                    </div>
                    {this.renderDeleteLogo(canEdit)}
                    {this.renderColors(canEdit, selected)}
                </div>
            </div>
            <ActionButtons
                parentState={this.state}
                {...this.props}
                isValid={true}
                initialize={this.initialize}
            />
        </Card>
    }

    private renderColors = (canEdit, selected) => {
        return COLORS.map((colorObj, i) => {
            let color = this.props.state.data && this.props.state.data.primary1Color ? this.props.state.data.primary1Color : selected.primary1Color || "#FAFAFA";
            return <div style={{display: "inline-block"}} key={i}>
                <Card style={{display: "inline-block", padding: "5px", cursor: canEdit ? "pointer" : "", margin: "20px 10px 10px", width: "220px"}}
                    onClick={e => canEdit && this.props.onSetState({showColorPicker: true, anchorEl: e.target, colorObj})} key={i}>
                    <Header {...this.props} style={{padding: "16px", textAlign: "center"}}>
                        {colorObj.label}
                    </Header>
                    <div style={{marginTop: "5px", height: "30px", backgroundColor: color}}/>
                </Card>
                <Popover open={this.props.state.showColorPicker || false} anchorEl={this.props.state.anchorEl} anchorOrigin={{horizontal: "left", vertical: "bottom"}}
                    onClose={() => this.props.onSetState({showColorPicker: false})}>
                    <ChromePicker disableAlpha color={color} onChange={color => {
                        this.props.onSetState({
                            data: {
                                ...this.props.state.data,
                                primary1Color: color.hex
                            }
                        })
                    }}/>
                </Popover>
            </div>;
        });
    };

    private renderLogo = (canEdit) => {
        let logoUrl = this.props.state.data?.logoUrl
        const isFullLogoUrl = (logoUrl || '').startsWith('http')

        if (logoUrl && !isFullLogoUrl) {
            let host = this.props.config.envApi.host
            if (host === 'interopio.local') {
                host = 'interopio-dev.com'
            }
            logoUrl = `https://cdn.${host}/${logoUrl}`
        }

        return <div style={{marginTop: "10px"}}>
            <div style={{width: "220px", display: "inline-block", verticalAlign: "top"}}>
                <Card style={{padding: "5px", display: "inline-block", boxSizing: "border-box", width: "220px", position: "relative"}} className="portal-logo-card">
                    <Header {...this.props} style={{padding: "16px", textAlign: "center", marginBottom: "5px"}}>
                        Logo
                    </Header>
                    {this.props.state.validationError
                        ? <div style={{textAlign: "center"}}>
                            {canEdit &&
                            <Tooltip title="Edit">
                                <IconButton style={{paddingTop: "12px", marginRight: "20px", top: "6px"}} className="logo-edit-button"
                                    onClick={() => (this.refs.fileInput as any).click()} data-qa="portal-logo-edit-button">
                                    <Edit style={{color: this.props.muiTheme.palette.secondaryTextColor}}/>
                                </IconButton>
                            </Tooltip>}
                            <span style={{color: this.props.muiTheme.palette.accent1Color}}>{this.props.state.validationError}</span>
                        </div>
                        : <div style={{textAlign: "center"}}>
                            {this.props.state.logoLoading
                                ? <div>
                                    <CircularProgress size={80}/>
                                </div>
                                : <div>
                                    {canEdit &&
                                    <Tooltip title="Edit">
                                        <IconButton style={{paddingTop: "12px", marginRight: "20px", top: "6px"}} className="logo-edit-button"
                                            onClick={() => (this.refs.fileInput as any).click()} data-qa="portal-logo-edit-button">
                                            <Edit style={{color: this.props.muiTheme.palette.secondaryTextColor}}/>
                                        </IconButton>
                                    </Tooltip>}
                                    {logoUrl
                                        ? <ImageLoader src={logoUrl} imgStyle={{maxHeight: "96px", maxWidth: "190px"}} config={this.props.config}/>
                                        : <ImageLoader src={"/console/img/app-logo-hint.png"} imgStyle={{height: "96px"}} config={this.props.config}/>}
                                </div>}

                        </div>}
                    <input type="file" accept="image/*" style={{display: "none"}} ref={"fileInput"}
                        onChange={async (event) => {
                            this.props.onSetState({logoLoading: true})
                            const windowURL = window.URL || (window as any).webkitURL;
                            if (!windowURL) return;

                            let file: File = getPath(event, "target.files.0");
                            if (!file) {
                                return;
                            }

                            if (!file.type || !file.type.match("image.*")) {
                                this.props.onSetState({validationError: "The file is not an image"});
                                return;
                            } else {
                                this.props.onSetState({validationError: undefined});
                            }

                            let img: HTMLImageElement = new Image();
                            img.src = windowURL.createObjectURL(file);

                            this.loadImage(img, windowURL, file);
                        }}
                    />
                </Card>
            </div>
        </div>
    };

    private loadImage = (img, windowURL, file) => {
        const IMAGE_WIDTH = 640;
        const IMAGE_HEIGHT = 264;
        const MAX_WIDTH = 640;
        const MAX_HEIGHT = 264;

        img.onload = async () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            windowURL && windowURL.revokeObjectURL(img.src);

            if (!width || !height) {
                return;
            }

            if (width > MAX_WIDTH || height > MAX_HEIGHT) {
                this.props.onSetState({validationError: `The image is too large. The maximum allowed size is ${MAX_WIDTH}x${MAX_HEIGHT} pixels`});
                return;
            }
            if (file) {
                const name = file.name.split(".")[0];
                file = await readAndCompressImage(file, {
                    mimeType: "image/png",
                    quality: 0.75,
                    maxWidth: IMAGE_WIDTH,
                    maxHeight: IMAGE_HEIGHT,
                    autoRotate: true,
                    debug: false
                });

                let bodyFormData = new FormData();
                bodyFormData.append("file", file, `${name}.png`);

                const response = await uploadImage(this.props.config, bodyFormData);
                if (response && response.status < 300 && response.data) {
                    try {
                        this.props.onSetState({logoLoading: false})
                        this.props.onSetState({
                            data: {
                                ...this.props.state.data,
                                logoUrl: response.data.fileUrl
                            }
                        });
                    } catch (reason) {
                        consoleLogger.log("::: reason:", reason);
                    }
                }

            } else {
                let canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;

                let src = img.getAttribute("src");
                img.setAttribute("crossorigin", "anonymous");
                img.setAttribute("src", "https://cors-anywhere.herokuapp.com/" + src);

                let ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                let dataURL = canvas.toDataURL("image/png");
                this.props.onSetState({
                    data: {
                        ...this.props.state.data,
                        logoUrl: dataURL
                    }
                });
            }
        }
    };

    private renderDeleteLogo = (canEdit) => {
        const logoUrl = this.props.state.data?.logoUrl

        if (!canEdit || !logoUrl) {
            return null
        }

        return (
            <Button
                variant="contained"
                color="secondary"
                style={{
                    display: 'block',
                    width: '220px',
                    margin: '16px auto 0 auto',
                }}
                onClick={() => {
                    this.props.onSetState((prevState) => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            logoUrl: '',
                        },
                    }))
                }}
            >
                Delete Logo
            </Button>
        )
    }

    private handleCopy = url => {
        const sanitizedUrl = encodeURI(url);
        const container = document.createElement("textarea");
        container.innerHTML = sanitizedUrl;

        document.body.appendChild(container);
        container.select();
        document.execCommand("copy");

        document.body.removeChild(container);

        this.props.generalMessageSet({
            type: ":INFO_SNACKBAR:",
            title: "Copied to Clipboard"
        });
    }

    private initialize = (reload = false) => {
        const selected = getPath(this.props, "portals.selected.data") || {};
        this.props.onSetState({validationError: undefined, logoLoading: false, data: {...selected, context: (selected.portalType || '').toLowerCase()}});
        reload && this.props.initialize();
    }

    private validateData = () => {
        const dataValidation = validateData.onEdit(this.props.state)
        this.setState(
            {
                dataValidation,
                isSaveEnabled: !dataValidation.name && !dataValidation.shortcut,
            },
        );
    }
}

export default Details;
