import * as types from "./types";

import initialState from "../../redux/reducers/users/initial-state";

import axiosWrapper from "../../lib/axiosWrapper";
import getMessageFromResponse from "../../lib/getMessageFromResponse";
import { getPath } from "../../lib/utils";

export function usersCurrentRead(config: any) {
    return function (dispatch) {
        dispatch({
            type: types.USERS_SELECTED_SET,
            payload: {
                ...initialState.selected,
                status: "pending",
            },
        });
        return axiosWrapper(config.accountApi, "users/current", "GET")
            .then((res) => {
                dispatch({
                    type: types.USERS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                        message: initialState.selected.message,
                    },
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.USERS_SELECTED_SET,
                    payload: {
                        ...initialState.selected,
                        status: "error",
                        message: getMessageFromResponse(reason, true)
                    },
                });
                // console.error(":::", reason);
            });
    };
}

export function usersCurrentUpdate(
    config: any,
    data = {},
) {
    return function (dispatch) {
        dispatch({
            type: types.USERS_SELECTED_SET,
            payload: {
                ...initialState.selected,
                status: "pending",
            },
        });
        return axiosWrapper(config.accountApi, "users/current", "PUT", data)
            .then((res) => {
                dispatch({
                    type: types.USERS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                        message: initialState.selected.message,
                    },
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.USERS_SELECTED_SET,
                    payload: {
                        ...initialState.selected,
                        status: "error",
                        message: getMessageFromResponse(reason, true)
                    },
                });
                // console.error(":::", reason);
            });
    };
}
