const LOGS = {
    "_embedded": {
        "appsLogsDtoes": [
            {
                "id": "aTpwZ3cBwz9nmLYVJdzY",
                "timestamp": 1612348138966000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "b65wZ3cBYlP5UYonIaen",
                "timestamp": 1612348137891000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "bq5wZ3cBYlP5UYonHadu",
                "timestamp": 1612348136806000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "ba5wZ3cBYlP5UYonGadn",
                "timestamp": 1612348135780000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "aDpwZ3cBwz9nmLYVFdwk",
                "timestamp": 1612348134690000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "bK5wZ3cBYlP5UYonEKfC",
                "timestamp": 1612348133562000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "ZzpwZ3cBwz9nmLYVDNy5",
                "timestamp": 1612348132531000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "a65wZ3cBYlP5UYonCKeT",
                "timestamp": 1612348131472000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "ZjpwZ3cBwz9nmLYVBNwy",
                "timestamp": 1612348130350000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "aq5wZ3cBYlP5UYonAKcB",
                "timestamp": 1612348129277000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "ZTpvZ3cBwz9nmLYV-9zE",
                "timestamp": 1612348128195000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "aa5vZ3cBYlP5UYon96e_",
                "timestamp": 1612348127162000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "ZDpvZ3cBwz9nmLYV89yQ",
                "timestamp": 1612348126086000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "aK5vZ3cBYlP5UYon76e-",
                "timestamp": 1612348125114000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "YzpvZ3cBwz9nmLYV69yy",
                "timestamp": 1612348124080000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Z65vZ3cBYlP5UYon56eO",
                "timestamp": 1612348123018000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "YjpvZ3cBwz9nmLYV49xq",
                "timestamp": 1612348121958000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Zq5vZ3cBYlP5UYonv6e0",
                "timestamp": 1612348112813000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "YTpvZ3cBwz9nmLYVu9wZ",
                "timestamp": 1612348111638000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Za5vZ3cBYlP5UYontqfH",
                "timestamp": 1612348110524000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "YDpvZ3cBwz9nmLYVstwV",
                "timestamp": 1612348109331000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "XzpvZ3cBwz9nmLYVrtwR",
                "timestamp": 1612348108303000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "XjpvZ3cBwz9nmLYVqtwq",
                "timestamp": 1612348107303000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "XTpvZ3cBwz9nmLYVpdzu",
                "timestamp": 1612348106219000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "ZK5vZ3cBYlP5UYonoad3",
                "timestamp": 1612348105075000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "XDpvZ3cBwz9nmLYVndxq",
                "timestamp": 1612348104040000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Y65vZ3cBYlP5UYonmacy",
                "timestamp": 1612348102959000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "WzpvZ3cBwz9nmLYVldwd",
                "timestamp": 1612348101913000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Yq5vZ3cBYlP5UYonkacI",
                "timestamp": 1612348100862000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "WjpvZ3cBwz9nmLYVjNzd",
                "timestamp": 1612348099804000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Ya5vZ3cBYlP5UYoniKef",
                "timestamp": 1612348098702000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "WTpvZ3cBwz9nmLYVhNxc",
                "timestamp": 1612348097613000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "YK5vZ3cBYlP5UYongKdS",
                "timestamp": 1612348096583000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "WDpvZ3cBwz9nmLYVe9zv",
                "timestamp": 1612348095464000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "X65vZ3cBYlP5UYond6ei",
                "timestamp": 1612348094364000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "VzpvZ3cBwz9nmLYVctzz",
                "timestamp": 1612348093168000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Xq5vZ3cBYlP5UYonTqeo",
                "timestamp": 1612348083864000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "VjpvZ3cBwz9nmLYVStwH",
                "timestamp": 1612348082693000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Xa5vZ3cBYlP5UYonRaen",
                "timestamp": 1612348081562000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "VTpvZ3cBwz9nmLYVQdyL",
                "timestamp": 1612348080521000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "XK5vZ3cBYlP5UYonPKfE",
                "timestamp": 1612348079297000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "W65vZ3cBYlP5UYonOKce",
                "timestamp": 1612348078107000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Wq5vZ3cBYlP5UYonM6dx",
                "timestamp": 1612348076909000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Wa5vZ3cBYlP5UYonLqc6",
                "timestamp": 1612348075572000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "VDpvZ3cBwz9nmLYVKdzG",
                "timestamp": 1612348074437000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "WK5vZ3cBYlP5UYonJKfW",
                "timestamp": 1612348073170000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "UzpvZ3cBwz9nmLYVINwZ",
                "timestamp": 1612348071959000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "V65vZ3cBYlP5UYonG6ez",
                "timestamp": 1612348070830000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "UjpvZ3cBwz9nmLYVDNzO",
                "timestamp": 1612348067013000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            },
            {
                "id": "Vq5vZ3cBYlP5UYonCKcB",
                "timestamp": 1612348065790000,
                "app_id": "bilirubin",
                "account_id": "ve11",
                "data_adapter_id": null,
                "environment_id": "env-1",
                "gateway_id": "gw-mysandbox-dstu2",
                "session_id": null,
                "http_error_code": "__httpErrorCode__",
                "body": "__body__",
                "contains_phi": true,
                "ip_address": "__ipAddress__",
                "user_agent": "__userAgent__",
                "metadata": "__metadata__",
                "labels": "__labels__",
                "log_level": "DEBUG",
                "system_log_event": "LAUNCH_ENDPOINT_REACHED"
            }
        ]
    },
    "_links": {
        "first": {
            "href": "http://ve11.interopio-dev.com/logging/apps/ve11/env-1/bilirubin/logs?startTime=1612303200000&page=0&size=50"
        },
        "self": {
            "href": "http://ve11.interopio-dev.com/logging/apps/ve11/env-1/bilirubin/logs?startTime=1612303200000&page=0&size=50"
        },
        "next": {
            "href": "http://ve11.interopio-dev.com/logging/apps/ve11/env-1/bilirubin/logs?startTime=1612303200000&page=1&size=50"
        },
        "last": {
            "href": "http://ve11.interopio-dev.com/logging/apps/ve11/env-1/bilirubin/logs?startTime=1612303200000&page=2&size=50"
        }
    },
    "page": {
        "size": 50,
        "totalElements": 102,
        "totalPages": 3,
        "number": 0
    }
}

export default LOGS;
