import * as React from "react";
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as actions from "../../../../redux/actions";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import WStep1 from "./WStep1";
import WStep2 from "./WStep2";
import WStep3 from "./WStep3";
import WStep4 from "./WStep4";
import WStep5 from "./WStep5";
import "./style.less";

const WSTEP_MIN: number = 1;
const WSTEP_MAX: number = 5;

const INIT_STATE = {
    data: {
        email: "",
        code: [null, null, null, null, null, null],
        userNames: "",
        password: "",
        accountName: "",
        accountId: "",
        accountType: "",
        accountProgram: "",
        latestTosVersion: "",
        firstName: "",
        lastName: "",
        agreeToMail: false
    },

    wstep: WSTEP_MIN
};

class AccountsAddNewWizard extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        const email = localStorage.getItem("iio_confirmationEmail");
        if (email) {
            this.setState({
                data: {...this.state.data, email},
                wstep: this.calcNextWStep(),
            });
        } else {
            const queryEmail = this.getQueryEmail();
            if (queryEmail) {
                this.setState({data: {...this.state.data, email: queryEmail}});
            }
        }
        this.props.tosGetLatest().then(terms => {
            this.setState({data: {...this.state.data, latestTosVersion: terms.data.version}});
        })
    }

    public componentDidUpdate(_prevProps, prevState) {
        if (prevState.wstep !== 5 && this.state.wstep === 5) {
            this.props.uiHideNavbar();
        }
    }

    public componentWillUnmount() {
        this.props.uiShowNavbar();
    }

    public render() {
        return <div className={`${this.state.wstep !== 5 ? "container " : ""} account-create`}>
            {this.renderWStep()}

            <div style={{marginTop: "48px", textAlign: "center"}}>
                {this.state.wstep === 2 && this.state.data.email
                    ? <div>
                        Not your email address?&nbsp;
                        <strong>
                            <a
                                href="#!"
                                style={{fontSize: "16px", textDecoration: "none"}}
                                onClick={(event: any): void => {
                                    event.preventDefault();
                                    localStorage.removeItem("iio_confirmationEmail");
                                    this.setState({
                                        data: {...this.state.data, email: ""},
                                        wstep: WSTEP_MIN
                                    });
                                }}>
                                Try again
                            </a>
                        </strong>
                        <br/><br/>
                    </div>
                    : null}
            </div>
        </div>;
    }

    // Private renders ---------------------------------------------------------
    private renderWStep: Function = () => {
        const propSet = {
            ...this.props,
            state: this.state,
            onSetState: (state: any, cb: any): void => this.setState(state, cb),
            calcNextWStep: this.calcNextWStep
        };

        const wstep = this.state.wstep;
        if (wstep >= WSTEP_MIN || wstep <= WSTEP_MAX) {
            switch (this.state.wstep) {
                case 1:
                    return <WStep1 {...propSet} />;
                case 2:
                    return <WStep2 {...propSet} />;
                case 3:
                    return <WStep3 {...propSet} />;
                case 4:
                    return <WStep4 {...propSet} />;
                case 5:
                    return <WStep5 {...propSet} />;
            }
        }

        return null;
    };

    // Helpers -----------------------------------------------------------------
    private calcNextWStep: Function = () => this.state.wstep < WSTEP_MAX ? this.state.wstep + 1 : WSTEP_MAX;

    private getQueryEmail = () => (this.props.location?.search || '').replace("?email=", '');
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(AccountsAddNewWizard));
