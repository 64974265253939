import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import convertToUrlFriendly from "../../../../lib/convertToUrlFriendly";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import * as validateData from "../../../../lib/validate-signin-data";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const INIT_STATE = {
    status: "",
    data: {
        accountId: ""
    },
    dataValidation: {
        accountId: ""
    }
};

class SelectAccount extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidUpdate(_, prevState) {
        if (JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.validateData();
        }
    }

    public render() {
        const {protocol, domain, type, port} = getHostnameInfo();

        return <Card style={{width: 480, marginTop: 48, marginRight: "auto", marginLeft: "auto", padding: 28, borderRadius: 8}}>
            <CardContent>
                <h2 style={{ margin: "4px 0 24px", textAlign: "center", fontSize: 28 }}>
                    Sign in to your account
                </h2>
                <p style={{ margin: 0, textAlign: "center", fontSize: 16 }}>
                    Enter your account's&nbsp;
                    <strong><big>interopiO URL</big></strong>
                </p>
            </CardContent>

            <CardContent style={{display: "flex", alignItems: "flex-end"}}>
                <TextField autoFocus name="accountId" value={this.state.data.accountId}
                    label={this.state.dataValidation.accountId || "Account ID"} error={!!this.state.dataValidation.accountId}
                    placeholder="your-account-id" autoComplete="off" fullWidth
                    onChange={(event) => this.setState({
                        data: {
                            ...this.state.data,
                            accountId: convertToUrlFriendly(event.target.value.substring(0, 18)),
                        }
                    })}
                    onKeyPress={(event) => {
                        if (event.key === "Enter" && !(!this.state.data.accountId || this.state.status !== "ready" || !this.isDataValid())) {
                            event.preventDefault();
                            window.location.href = `${protocol}//${this.state.data.accountId}.${domain}.${type}${port ? ":" + port : ""}/console/signin`;
                        }
                    }}/>
                <div style={{fontSize: 16.8, fontWeight: 800}}>
                    {"." + domain + "." + type}
                </div>
            </CardContent>

            <CardContent style={{padding: 16}}>
                <Button variant="contained" color="primary" fullWidth
                    disabled={!this.state.data.accountId || this.state.status !== "ready" || !this.isDataValid()}
                    endIcon={this.state.status !== "wait" ? <ArrowForwardIcon/> : null}
                    onClick={event => {
                        event.preventDefault();
                        window.location.href = `${protocol}//${this.state.data.accountId}.${domain}.${type}${port ? ":" + port : ""}/console/signin`;
                    }}
                >
                    {this.state.status === "wait" ? <CircularProgress size={24} /> : "Continue"}
                </Button>
            </CardContent>

            <CardContent style={{padding: 16, textAlign: "center", fontSize: 16}}>
                Don’t know your account URL?&nbsp;
                <strong>
                    <a href="#!" style={{fontSize: 16, textDecoration: "none"}}
                        onClick={event => {
                            event.preventDefault();
                            window.location.href = `${protocol}//${domain}.${type}${port ? ":" + port : ""}/console/users/account-list/enter-email`;
                        }}>
                        Find your account
                    </a>
                </strong>
            </CardContent>
        </Card>;
    }

    private isDataValid = () => Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true);

    private validateData = () => {
        this.setState(
            {status: "wait"},
            () => validateData.onSelectAccountAsync(this.state, this.props.config)
                .then((dataValidation: any) => {
                    this.setState({
                        dataValidation: {
                            ...this.state.dataValidation,
                            ...dataValidation
                        },
                        status: "ready"
                    });
                })
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps
});
export default connect(mapStateToProps)(SelectAccount);
