import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import MoreVert from '@material-ui/icons/MoreVert'
import CustomLink from '../../../../../Widgets/CustomLink'

const PortalAppItem = (props: any) =>  {
    const spanStyle: any = {
        display: 'inline-block',
        overflow: 'hidden',
        textAlign: 'left',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        height: '45px',
        lineHeight: '45px',
        boxSizing: 'border-box',
    }
    const customContexts = (props.data.providerPortalCustomContexts || props.data.patientPortalCustomContexts) || []
    const portalContextId = props.data.providerPortalContextId
        ? { providerPortalContextId: props.data.providerPortalContextId }
        : { patientPortalContextId: props.data.patientPortalContextId }
    const customContext = (customContexts)
        .map((it) => `${it.contextKey}=${it.contextValue}`)
        .join('&')

    return (
        <div
            data-qa={`portal-app-${props.data.displayName}`}
            style={{
                position: 'relative',
                display: 'flex',
                padding: '0 32px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            }}
        >
            <span
                data-e2e={`app-launch-act-display-name-${props.data.displayName}`}
                style={{ ...spanStyle, flex: 2.1, paddingRight: '10px' }}
            >
                {props.data.displayName}
            </span>
            <span style={{ ...spanStyle, flex: 1.6, paddingRight: '10px' }}>
                <CustomLink to={`/${props.state.data.environmentId}/apps/${props.data.appId}`}>
                    <span data-e2e={`portal-app-act-${props.data.appId}`}>
                        {props.data.appName}
                    </span>
                </CustomLink>
            </span>
            <span
                data-e2e={`app-launch-act-user-context-${props.data.displayName}`}
                style={{ ...spanStyle, flex: 1, paddingRight: '10px' }}
            >
                {props.data.userContext}
            </span>
            <span
                data-qa={`app-launch-act-intent-${props.data.displayName}`}
                style={{ ...spanStyle, flex: 1.75, paddingRight: '10px' }}
            >
                {props.data.intent}
            </span>
            <span
                data-e2e={`app-launch-act-custom-context-${props.data.displayName}`}
                style={{ ...spanStyle, flex: 2, paddingRight: '15px' }}
            >
                {customContext}
            </span>
            <IconButton
                data-qa={`portal-app-more-button-${props.data.displayName}`}
                style={{ position: 'absolute', right: '16px', top: '-3px' }}
                onClick={(event) => props.onSetState({
                    showPortalAppItemMenu: true,
                    anchorEl: event.currentTarget,
                    data: props.data,
                    delete: {
                        name: props.data.displayName,
                        ...portalContextId,
                    },
                })}
            >
                <MoreVert/>
            </IconButton>
            <Menu
                open={!!props.state.showPortalAppItemMenu}
                anchorEl={props.state.anchorEl}
                style={{ position: 'absolute', right: '16px' }}
                onClose={() => props.onSetState({ 
                    data: {},
                    showPortalAppItemMenu: false,
                })
                }
            >
                <MenuItem
                    data-qa={`portal-app-edit-button-${props.state.data.displayName}`}
                    onClick={() => props.onSetState({
                        dialog: ':EDIT_APP_LAUNCH:',
                        update: true, showPortalAppItemMenu: false,
                    })}
                >
                    <Edit style={{ opacity: .5, marginRight: '12px' }} />
                    &nbsp;Edit
                </MenuItem>
                <MenuItem
                    data-qa={`portal-app-delete-button-${props.state.data.displayName}`}
                    onClick={() => {
                        props.onSetState({
                            data: {},
                            dialog: ':DELETE_CONFIRM:',
                            showPortalAppItemMenu: false,
                        })
                    }}>
                    <Delete style={{ opacity: .5, marginRight: '12px' }} />
                    &nbsp;Remove
                </MenuItem>
            </Menu>
        </div>
    )
}


export default PortalAppItem
