import * as React from "react";
import * as PropTypes from "prop-types";
import {getPath} from "../../../../../../lib/utils/";

const Component: React.FunctionComponent<any> = props => {
    const oldEmail = getPath(props, "users.selected.data.email") || "";
    const newEmail = getPath(props, "state.data.email") || "";

    return <div>
        <p style={{color: props.muiTheme.palette.textColor, textAlign: "center"}}>
            <big>
                <span>Are you sure you want to change your email from</span>&nbsp;
                <strong style={{color: props.muiTheme.palette.primary1Color}}>{oldEmail}</strong>&nbsp;
                <span>to</span>&nbsp;
                <strong style={{color: props.muiTheme.palette.primary1Color}}>{newEmail}</strong>&nbsp;
                <span>?</span>&nbsp;
            </big>
            <br/><br/>
            <span>Follow the instructions in the email you will receive.</span>&nbsp;
        </p>

        {(() => {
            const padding = props.state.changeEmailStatus ? "8px" : 0;
            const transform = props.state.changeEmailStatus ? "scaleY(1)" : "scaleY(0)";
            let background = props.muiTheme.palette.primary1Color;
            let content = "Confirmation email has been successfully sent";
            if (props.state.changeEmailStatus && props.state.changeEmailStatus !== ":EMAIL_CHANGE_CONFIRMED:") {
                background = props.muiTheme.palette.accent1Color;
                content = props.state.changeEmailStatus;
            }
            return <div style={{
                marginTop: "16px",
                padding,
                transform,
                transformOrigin: "top",
                borderRadius: "2px",
                background,
                color: "#fff",
                textAlign: "center",
                transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
            }}>
                {content}
            </div>;
        })()}
    </div>;
}

Component.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired
};

export default Component;
