import {Button, List, Tooltip} from "@material-ui/core";
import Table, {Search} from "../../../../Widgets/Table";
import * as React from "react";
import * as PropTypes from "prop-types";
import "./style.less";
import {getPath} from "../../../../../lib/utils";
import {Delete, Lock, LockOpen, Visibility} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {useState} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";

const GatewayTableList = props => {
    const [anchorEl, setAnchorEl] = useState(undefined);
    const [clickedGw, setClickedGw] = useState(null);
    const rightFilters = props.actAsAdmin
        ? [
            {
                type: "component",
                value: <Button data-qa="create-new-gateway-card" color="primary" key={1} variant="contained" onClick={() => props.onSetState({dialog: ":ADD_NEW_WIZARD:"})}>
                    Add Gateway
                </Button>
            }
        ]
        : [];
    const leftFilters = [
        {
            type: "component",
            value: <Search key={1} setFilterItem={(_, activation) => props.onSetParentState({ filters: { ...props.parentState.filters, activation } })} hint="Search gateways" />
        }
    ];
    const columns = [
        {
            size: 2,
            name: "name",
            label: "Name",
            sort: () => props.onSetState({sortBy: {["name"]: !getPath(props, `state.sortBy.name`)}}),
            order: true
        },
        {
            size: 1,
            name: "type",
            label: "Type"
        },
        {
            size: 1,
            name: "userStrategy",
            label: "User Strategy"
        },
        {
            size: 1,
            name: "phi",
            label: "PHI"
        },
        {
            size: 3,
            name: "description",
            label: "Description"
        },
        {
            size: .7,
            name: "actions",
            label: "Actions"
        }
    ];

    return <Table rightFilters={rightFilters} columns={columns} leftFilters={leftFilters}>
        <List style={{padding: 0}}>
            {props.data.map((gw, i) => {
                const defaultSource = getPath(gw, "configuration.defaultDataSource") || "";
                const dataSource = (getPath(props, "dataSources.all.data") || []).find((ds) => ds.dataSourceId === defaultSource) || {};
                const dataSourcePhi = dataSource.phi;

                return <div key={i} className="gateway-table-item"
                    onClick={e => (e.target as any).parentElement.classList.contains("gateway-table-item") && props.history.push(`/${props.env}/gateways/${gw.gatewayId}`)}>
                    <div style={{flex: 2, paddingLeft: "32px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                        {gw.name}
                    </div>
                    <div style={{flex: 1}}>
                        {(() => {
                            switch (gw.gatewayType) {
                                case "FHIR_DSTU2__1_0_2":
                                    return "DSTU2";
                                case "FHIR_STU3__3_0_2":
                                    return "STU3";
                                case "FHIR_R4__4_0_1":
                                    return "R4";
                                case "HL7_V2":
                                    return "HL7 v2.x Listening Endpoint";
                                default:
                                    return "--";
                            }
                        })()}
                    </div>
                    <div style={{flex: 1}}>
                        {(() => {
                            switch (gw.userStrategy) {
                                case "INTEROPIO":
                                    return "interopiO";
                                case "DELEGATED":
                                    return "Delegated";
                                default:
                                    return "Not set";
                            }
                        })()}
                    </div>
                    <div style={{flex: 1}}>
                        <Tooltip title={dataSourcePhi ? "PHI" : "NO PHI"}>
                            {dataSourcePhi ? <Lock/> : <LockOpen/>}
                        </Tooltip>
                    </div>
                    <div style={{flex: 3, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                        {gw.description}
                    </div>
                    <div style={{flex: .7}}>
                        <IconButton data-qa={props["data-qa"]} aria-label="more" aria-controls="long-menu" aria-haspopup="true"
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                setAnchorEl(event.currentTarget);
                                setClickedGw(gw.gatewayId);
                            }}>
                            <MoreVertIcon/>
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={clickedGw === gw.gatewayId && !!anchorEl} onClose={() => {
                            setAnchorEl(null);
                            setClickedGw(null);
                        }} PaperProps={{style: {minWidth: 168}}}>
                            <MenuItem onClick={() => props.history.push(`/${props.env}/gateways/${gw.gatewayId}`)}>
                                <ListItemIcon>
                                    <Visibility/>
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    View
                                </Typography>
                            </MenuItem>
                            <MenuItem data-qa="gateway-menu-delete"
                                onClick={() => props.onSetState({
                                    dialog: ":DELETE_CONFIRM:",
                                    delete: {
                                        gatewayId: gw.gatewayId,
                                        name: gw.name
                                    }
                                })}>
                                <ListItemIcon>
                                    <Delete/>
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Delete
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            })}
        </List>
    </Table>
};

GatewayTableList.propTypes = {
    Divider: PropTypes.object,
    actAsAdmin: PropTypes.bool,
    onSetState: PropTypes.func,
    onSetParentState: PropTypes.func,
    parentState: PropTypes.object,
    data: PropTypes.array,
    history: PropTypes.object,
    env: PropTypes.string,
    "data-qa": PropTypes.string
};

export default GatewayTableList;
