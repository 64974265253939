import $ from "jquery";

import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import * as actions from "../../redux/actions/";

import * as features from '../../lib/feature-helpers'
import getAppsCallUrl from '../../lib/getAppsCallUrl'
import getCDRsCallUrl from '../../lib/getCDRsCallUrl'
import getCdsServiceSetsCallUrl from '../../lib/getCdsServiceSetsCallUrl'
import getDataSourcesCallUrl from '../../lib/getDataSourcesCallUrl'
import getEnvironmentsCallUrl from '../../lib/getEnvironmentsCallUrl'
import getGatewaysCallUrl from '../../lib/getGatewaysCallUrl'
import getHostnameInfo from '../../lib/getHostnameInfo'
import getLocationInfo from '../../lib/getLocationInfo'
import getPortalsCallUrl from '../../lib/getPortalsCallUrl'
import getUserDirsCallUrl from '../../lib/getUserDirsCallUrl'
import { isLoggedIn } from '../../lib/users-helpers'

import {ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";

import {NavbarL1Public, NavbarL1Private} from "../CustomNavbar/";
import LeftMenuPrivate from "../LeftMenuPrivate";
import Footer from "../Footer";
import SignIn from "../Pages/SignIn/";
import CenteredCircularProgress from "../Widgets/CenteredCircularProgress/";

import axiosWrapper from '../../lib/axiosWrapper'

import "./style.less";

import consoleLogger from "../../lib/consoleLogger";

type TArtifactsIds = {
    appIds: Array<string>,
    cdsServiceSetIds: Array<string>,
    gatewayIds: Array<string>,
    dataSourceIds: Array<string>,
    cdrIds: Array<string>,
    directoryIds: Array<string>,
    providerPortalIds: Array<string>,
}

type TState = {
    status: '' | 'init' | 'ready'
    artifactsCheckStatus: '' | 'ready' | 'stage-0-process' | 'stage-1' | 'stage-1-process' | 'stage-2' | 'stage-2-process'
    artifactsIds: TArtifactsIds
    latestTosVersion: string
}

const INIT_STATE: TState = {
    status: '',
    artifactsCheckStatus: '',
    artifactsIds: {
        appIds: [],
        cdsServiceSetIds: [],
        gatewayIds: [],
        dataSourceIds: [],
        cdrIds: [],
        directoryIds: [],
        providerPortalIds: [],
    },
    latestTosVersion: ''
}

class App extends React.Component<any, TState> {
    public static propTypes = {
        accounts: PropTypes.object.isRequired,
        apps: PropTypes.object.isRequired,
        cdrs: PropTypes.object.isRequired,
        cdsServiceSets: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        dataSources: PropTypes.object.isRequired,
        environments: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        gateways: PropTypes.object.isRequired,
        generalMessage: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,
        userDirs: PropTypes.object.isRequired,

        accountsCurrentRead: PropTypes.func.isRequired,
        appsReadAll: PropTypes.func.isRequired,
        cdrsReadAll: PropTypes.func.isRequired,
        cdsServiceSetsReadAll: PropTypes.func.isRequired,
        configReadKnowledgeBaseLinks: PropTypes.func.isRequired,
        configUserRolesReadAll: PropTypes.func.isRequired,
        configUserStatusesReadAll: PropTypes.func.isRequired,
        dataSourcesReadAll: PropTypes.func.isRequired,
        environmentsReadAll: PropTypes.func.isRequired,
        featuresReadConfig: PropTypes.func.isRequired,
        gatewaysReadAll: PropTypes.func.isRequired,
        generalMessageReset: PropTypes.func.isRequired,
        loginReset: PropTypes.func.isRequired,
        loginSet: PropTypes.func.isRequired,
        portalsReadAll: PropTypes.func.isRequired,
        reportPhiAccess: PropTypes.func.isRequired,
        uiUpdateTimeZone: PropTypes.func.isRequired,
        uiXThemeSet: PropTypes.func.isRequired,
        userDirsReadAll: PropTypes.func.isRequired,
        usersGetCurrent: PropTypes.func.isRequired
    };

    private unlisten;

    public constructor(props) {
        super(props)
        this.state = INIT_STATE
    }

    public componentDidMount() {
        document.title = "interopiO Console";
        this.unlisten = this.props.history.listen(() => $(".stage").scrollTop(0));
        this.initialize();
    }

    public async componentDidUpdate(prevProps) {
        const artifactsCheckStatus = this.state.artifactsCheckStatus
        const areArtifactsChecked = artifactsCheckStatus === 'ready'

        const isAppReady = this.state.status === 'ready'
        const isLogged = isLoggedIn(this.props.login)

        // Update document title logged in users
        const previousAccountsSelectedStatus = prevProps.accounts?.selected?.status || ''
        const previousAccountName = prevProps.accounts?.selected?.data?.name || ''
        const accountsSelectedStatus = this.props.accounts?.selected?.status || ''
        const accountName = this.props.accounts?.selected?.data.name || ''
        if (
            isLogged
            && (
                previousAccountsSelectedStatus !== accountsSelectedStatus
                || previousAccountName !== accountName
            )
        ) {
            document.title = `${accountName} | interopiO Console`
        }

        const locationInfo = getLocationInfo(this.props.location, this.props.config)
        const prevLocationInfo = getLocationInfo(prevProps.location, prevProps.config)
        const envs = this.props.environments?.all?.data || []
        if (locationInfo.env && locationInfo.env !== prevLocationInfo.env) {
            const selectedEnv = envs.find((it) => it.environmentId === locationInfo.env)
            if (selectedEnv && selectedEnv.phi) {
                this.props.reportPhiAccess(this.props.config, locationInfo.env)
            }
        }

        // - app ready
        // x logged in
        // √ artifacts checked
        // In this case `areArtifactsChecked` flag is set on each log out
        // and that's how a new check will be performed on each log in
        if (!isLogged && areArtifactsChecked) {
            document.title = 'interopiO Console'
            this.setState({ artifactsCheckStatus: '' })
        }

        // √ app ready
        // √ logged in
        // x artifacts checked
        else if (isAppReady && isLogged && !areArtifactsChecked) {
            const { accountId } = getHostnameInfo()
            const {
                env,
                appId,
                cdsServiceSetId,
                gatewayId,
                dataSourceId,
                cdrId,
                directoryId,
            } = locationInfo

            const isCurrentAccountDataReady = this.props.accounts?.selected?.status === 'ready'
            const isEnvironmentsDataReady = this.props.environments?.all?.status === 'ready'

            // x env
            if (!env) {
                this.setState(
                    { artifactsCheckStatus: 'ready' },
                    () => this.props.accountsCurrentRead(this.props.config),
                )
            }

            // √ env
            // √ stage 0
            else if (!artifactsCheckStatus) {
                this.setState(
                    { artifactsCheckStatus: 'stage-0-process'},
                    async () => {
                        const envsUrl = getEnvironmentsCallUrl(accountId)
                        await Promise.all([
                            this.props.accountsCurrentRead(this.props.config),
                            this.props.environmentsReadAll(this.props.config, envsUrl),
                        ])
                        this.setState({ artifactsCheckStatus: 'stage-1' })
                    },
                )
            }

            // √ env
            // √ stage 1
            // √ current account data
            // √ environments data
            else if (
                artifactsCheckStatus === 'stage-1'
                && isCurrentAccountDataReady
                && isEnvironmentsDataReady
            ) {
                this.setState(
                    { artifactsCheckStatus: 'stage-1-process' },
                    async () => {
                        const envsData = this.props.environments?.all?.data || []
                        const isEnvExists = envsData.some((item) => item?.environmentId === env)
                        if (!isEnvExists) {
                            this.setState(
                                { artifactsCheckStatus: 'ready' },
                                () => this.props.history.push('/home'),
                            )
                        }
                        else {
                            const artifactsIdsUrl = `api/${accountId}/env/${env}/ids`
                            const res = await axiosWrapper(
                                this.props.config.envApi,
                                artifactsIdsUrl,
                            )

                            const appsUrl = getAppsCallUrl(accountId, env)
                            this.props.appsReadAll(this.props.config, appsUrl)
                            const cdsServiceSetsUrl = getCdsServiceSetsCallUrl(accountId, env)
                            this.props.cdsServiceSetsReadAll(this.props.config, cdsServiceSetsUrl)
                            const portalsUrl = getPortalsCallUrl(accountId, env)
                            this.props.portalsReadAll(this.props.config, portalsUrl)
                            const gatewaysUrl = getGatewaysCallUrl(accountId, env)
                            this.props.gatewaysReadAll(this.props.config, `${gatewaysUrl}?shallow=true`)
                            const dataSourcesUrl = getDataSourcesCallUrl(accountId, env)
                            this.props.dataSourcesReadAll(this.props.config, dataSourcesUrl)
                            const cdrsUrl = getCDRsCallUrl(accountId, env)
                            this.props.cdrsReadAll(this.props.config, cdrsUrl)
                            const userDirectoriesUrl = getUserDirsCallUrl(accountId, env)
                            this.props.userDirsReadAll(this.props.config, userDirectoriesUrl)

                            this.setState({
                                artifactsCheckStatus: 'stage-2',
                                artifactsIds: res.data,
                            })
                        }
                    }
                )
            }

            // √ env
            // √ stage 2
            // √ artifacts ids
            else if (artifactsCheckStatus === 'stage-2') {
                this.setState(
                    { artifactsCheckStatus: 'stage-2-process' },
                    () => {
                        let isArtifactExists = true
                        if (appId) {
                            const appsData = this.state.artifactsIds.appIds || []
                            isArtifactExists = appsData.some((item) => item === appId)
                        }
                        if (cdsServiceSetId) {
                            const cdsServiceSetsData = this.state.artifactsIds.cdsServiceSetIds || []
                            isArtifactExists = isArtifactExists && cdsServiceSetsData.some((item) => item === cdsServiceSetId)
                        }
                        // TODO: add `portalId` check here
                        // if (portalId) {
                        //     const providerPortalsData = this.state.artifactsIds.data.providerPortalIds || []
                        //     isArtifactExists = isArtifactExists && providerPortalsData.some((item) => item === portalId)
                        // }
                        if (gatewayId) {
                            const gatewaysData = this.state.artifactsIds.gatewayIds || []
                            isArtifactExists = isArtifactExists && gatewaysData.some((item) => item === gatewayId)
                        }
                        if (dataSourceId) {
                            const dataSourcesData = this.state.artifactsIds.dataSourceIds || []
                            isArtifactExists = isArtifactExists && dataSourcesData.some((item) => item === dataSourceId)
                        }
                        if (cdrId) {
                            const cdrsData = this.state.artifactsIds.cdrIds || []
                            isArtifactExists = isArtifactExists && cdrsData.some((item) => item === cdrId)
                        }
                        if (directoryId) {
                            const userDirsData = this.state.artifactsIds.directoryIds || []
                            isArtifactExists = isArtifactExists && userDirsData.some((item) => item === directoryId)
                        }

                        this.setState(
                            { artifactsCheckStatus: 'ready' },
                            () => {
                                if (!isArtifactExists) {
                                    this.props.history.push('/home')
                                }
                            },
                        )
                    }
                )
            }
        }
    }

    public componentWillUnmount() {
        this.unlisten();
    }

    public render() {
        const pathname = this.props.history?.location?.pathname;
        const isUnrestrictedPage = this.props.config.unrestrictedPages.indexOf(pathname) > -1 || pathname.startsWith("/terms/") || pathname.startsWith("/policies/");

        const {accountId} = getHostnameInfo();
        const location = getLocationInfo(this.props.location, this.props.config);

        const isLogged = isLoggedIn(this.props.login);
        const areArtifactsChecked = this.state.artifactsCheckStatus === "ready";
        const showLeftMenuPrivate = isLogged && location.env && areArtifactsChecked;

        const contentStyle: any = {paddingLeft: "0px"};
        if (showLeftMenuPrivate) {
            if (this.props.ui.leftMenuPrivate.isExpanded) {
                contentStyle.paddingLeft = this.props.ui.leftMenuPrivate.width.expanded + "px";
            } else {
                contentStyle.paddingLeft = this.props.ui.leftMenuPrivate.width.shrunk + "px";
            }
        } else {
            contentStyle.transition = "none";
        }

        let navbar = null;
        if (this.props.ui.showNavbar) {
            navbar = isLogged && areArtifactsChecked ? <NavbarL1Private/> : <NavbarL1Public/>;
        }

        return (
            <ThemeProvider theme={createMuiTheme({
                typography: {
                    fontFamily: "Fira Sans, sans-serif"
                },
                palette: {
                    primary: {
                        main: "#00567d"
                    },
                    secondary: {
                        main: "#b51535"
                    },
                    error: {
                        main: "#b51535"
                    }
                }
            })}>
                <div className="app-root">
                    {/* Navbar --------------------------------------------- */}
                    {this.state.status === "ready" ? navbar : null}
                    {/* Left-side menu ------------------------------------- */}
                    {showLeftMenuPrivate ? <LeftMenuPrivate/> : null}
                    {/* App main content ----------------------------------- */}
                    {this.renderMainContent(
                        accountId,
                        pathname,
                        isUnrestrictedPage,
                        isLogged,
                        areArtifactsChecked,
                        contentStyle
                    )}
                </div>
            </ThemeProvider>
        )
    }

    // Private renders ---------------------------------------------------------
    private renderMainContent = (accountId, pathname, isUnrestrictedPage, isLogged, areArtifactsChecked, contentStyle) => {
        let content = null;

        // x status || (√ signed in && x artifacts checked)
        // - account
        // - signed in
        // - tos
        if (this.state.status !== "ready" || (isLogged && !areArtifactsChecked)) {
            content = <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
        }

        // √ status
        // x account
        // - signed in
        // - tos
        else if (!accountId) {
            // √ public
            if (isUnrestrictedPage) content = this.props.children;
            // x public
            else content = <SignIn/>
        }

        // √ status
        // √ account
        // x signed in
        // - tos
        else if (!isLogged) {
            // √ home -> sign-in
            if (pathname === "/home") content = <SignIn/>
            // √ public
            else if (isUnrestrictedPage) content = this.props.children;
            // x public
            else content = <SignIn/>
        }

        // √ status
        // √ account
        // √ signed in
        // √ tos (or one of unrestrictedTosPages)
        else content = this.props.children;

        return <div className="stage" style={contentStyle}>
            {content}
            {this.renderGeneralMessage()}
            <Footer />
        </div>;
    }

    // Private renders ---------------------------------------------------------
    private renderGeneralMessage = () => {
        const {key, type: messageType, title: messageTitle = null, message: messageText = null, autoHideDuration = 24000} = this.props.generalMessage;

        const isOpen = [":INFO_SNACKBAR:", ":WARNING_SNACKBAR:", ":CRITICAL_SNACKBAR:"].includes(messageType);

        let bgndColor = "";
        switch (messageType) {
            case ":INFO_SNACKBAR:":
                bgndColor = this.props.ui?.xtheme?.palette?.colorBlueLight || "";
                break;
            case ":WARNING_SNACKBAR:":
                bgndColor = this.props.ui?.xtheme?.palette?.colorOrangeDark || "";
                break;
            case ":CRITICAL_SNACKBAR:":
                bgndColor = this.props.ui?.xtheme?.palette?.colorRedDark || "";
                break;
        }

        return <Snackbar
            key={key}
            open={isOpen}
            message={<div>
                <h3 style={{margin: "8px 0 0 0", textAlign: "center", lineHeight: "24px"}}>
                    {messageTitle}
                </h3>
                <p style={{margin: "0 0 8px 0", textAlign: "center", lineHeight: "24px"}}>
                    {messageText}
                </p>
            </div>}
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            autoHideDuration={autoHideDuration}
            ContentProps={{style: {minWidth: 160, height: "auto", minHeight: 48, backgroundColor: bgndColor, opacity: 0.85}}}
            onClose={this.props.generalMessageReset}
        />
    }

    // Helpers -----------------------------------------------------------------
    private printAllFeatures = () => {
        consoleLogger.log("::: ::: :::");
        consoleLogger.log("::: %cFeature toggles:", "color: #ddd");
        features.getAll(this.props.features.data)
            .forEach((feature, index: number) => {
                const isApplicable = features.check(feature.id, this.props.features.data);
                consoleLogger.log(
                    "::: "
                    + `%c${feature.isActive ? "√" : "x"} ${isApplicable ? "√" : "x"} ${feature.id || index} ${feature.name}`,
                    `color: ${isApplicable ? "#bada55" : "#da6a00"}`
                );
            });
        consoleLogger.log("::: ::: :::");
    };

    // Initialize --------------------------------------------------------------
    private initialize = async () => {
        this.props.uiXThemeSet({
            palette: {
                colorBlueDark: "#00567d",
                colorBlueLight: "#4fabe0",
                colorGreenDark: "#24a5a4",
                colorGreenLight: "#24dac3",
                colorOrangeDark: "#fd8e00",
                colorOrangeLight: "#fec42e",
                colorRedDark: "#b51535",
                colorRedLight: "#f7767e",
                colorBgndGreyDark: "rgb(207, 216, 220)",
                colorBgndGreyLight: "rgb(236, 239, 241)"
            }
        });

        this.props.uiUpdateTimeZone();

        await Promise.all([
            this.props.featuresReadConfig(this.props.config),
            this.props.configUserRolesReadAll(this.props.config),
            this.props.configUserStatusesReadAll(this.props.config),
            this.props.configReadKnowledgeBaseLinks(this.props.config)
        ]);

        // ---
        // Don't check for current user if we're on SSO auth page (we have to set a token before we check for current user with SSO)
        const pathname: string = this.props.history?.location?.pathname;
        if (!pathname.startsWith("/sso")) {
            const currentUser = await this.props.usersGetCurrent();
            if (currentUser && currentUser.data) {
                this.props.loginSet({
                    status: "logged-in",
                    data: currentUser?.data || {}
                });
            }
        }
        // else {
        //     this.props.loginReset();
        // }

        // ---
        this.printAllFeatures();

        // ---
        this.setState({
            status: "ready",
            artifactsCheckStatus: ""
        });
    };
}

const mapStateToProps = (state, ownProps) => ({
    accounts: state.accounts,
    apps: state.apps,
    cdrs: state.cdrs,
    cdsServiceSets: state.cdsServiceSets,
    config: state.config,
    dataSources: state.dataSources,
    environments: state.environments,
    features: state.features,
    gateways: state.gateways,
    generalMessage: state.generalMessage,
    login: state.login,
    ui: state.ui,
    userDirs: state.userDirs,
    ...ownProps
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    accountsCurrentRead: actions.accountsCurrentRead,
    appsReadAll: actions.appsReadAll,
    cdrsReadAll: actions.cdrsReadAll,
    cdsServiceSetsReadAll: actions.cdsServiceSetsReadAll,
    configReadKnowledgeBaseLinks: actions.configReadKnowledgeBaseLinks,
    configUserRolesReadAll: actions.configUserRolesReadAll,
    configUserStatusesReadAll: actions.configUserStatusesReadAll,
    dataSourcesReadAll: actions.dataSourcesReadAll,
    environmentsReadAll: actions.environmentsReadAll,
    featuresReadConfig: actions.featuresReadConfig,
    gatewaysReadAll: actions.gatewaysReadAll,
    generalMessageReset: actions.generalMessageReset,
    loginReset: actions.loginReset,
    loginSet: actions.loginSet,
    portalsReadAll: actions.portalsReadAll,
    reportPhiAccess: actions.reportPhiAccess,
    uiUpdateTimeZone: actions.uiUpdateTimeZone,
    uiXThemeSet: actions.uiXThemeSet,
    userDirsReadAll: actions.userDirsReadAll,
    usersGetCurrent: actions.usersGetCurrent,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
