import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../redux/actions";
import { withRouter } from "react-router";
import getEnvironmentsCallUrl from "../../../../../lib/getEnvironmentsCallUrl";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../lib/getLocationInfo";
import Button from "@material-ui/core/Button";

const ActionButtons = (props) => {
    const { accountId } = getHostnameInfo();
    const { env } = getLocationInfo(props.location, props.config);
    const canEdit = props.parentState.mode === ":EDIT:";
    const isValid = Object.keys(props.parentState.dataValidation).reduce((acc, key) => acc && !props.parentState.dataValidation[key], true);

    return <div style={{
        height: canEdit ? "auto" : 0,
        padding: canEdit ? 24 : "0 24px 0 0",
        textAlign: "right",
        opacity: canEdit ? 1 : 0,
        transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)",
    }}>
        <Button disabled={!canEdit} style={{ display: canEdit ? "inline-block" : "none", marginRight: 8 }}
            onClick={() => props.onSetParentState({ mode: ":VIEW:" }, props.initialize)}
        >
            Cancel
        </Button>
        <Button data-qa="da-details-edit-save-button" variant="contained" color="primary"
            disabled={props.parentState.status !== "ready" || !canEdit || !isValid}
            style={{ display: canEdit ? "inline-block" : "none" }}
            onClick={async () => {
                props.onSetParentState({ status: "waiting" });
                const url = getEnvironmentsCallUrl(accountId) + "/" + env;
                const res = await props.environmentsUpdate(props.config, url, props.parentState.data)
                if (res && res.status < 300 && res.data) {
                    props.onSetParentState({ mode: ":VIEW:" }, props.initialize);
                }
            }}
        >
            Save
        </Button>
    </div>;
}

ActionButtons.propTypes = {
    parentState: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    environmentsUpdate: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    environmentsUpdate: actions.environmentsUpdate,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActionButtons));
