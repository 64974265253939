import * as React from "react";
import * as PropTypes from "prop-types";
import * as validateData from "../../../../../../lib/validate-users-data";
import {Card, IconButton, TextField, Tooltip} from "@material-ui/core";
import Header from "../../../../../Widgets/Editor/Header/";
import ActionButtons from "./ActionButtons";
import {Edit, MailOutline} from "@material-ui/icons";

export default class extends React.Component<any, any> {
    public static propTypes = {
        muiTheme: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.validateData();
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.state.data.email) !== JSON.stringify(prevProps.state.data.email)) {
            this.validateData();
        }
    }

    public render() {
        const canEdit = this.props.state.mode === ":EDIT_EMAIL:";
        const modeView = this.props.state.mode === ":VIEW:";
        const isValid = Object.keys(this.props.state.dataValidation).reduce((acc, key) => acc && !this.props.state.dataValidation[key], true);

        return <Card style={{marginTop: "48px"}}>
            <Header
                {...this.props}
                leftIcon={<MailOutline/>}
                primaryText="Email"
                rightIcon={!canEdit
                    ? <Tooltip title="Edit">
                        <IconButton disabled={!modeView} onClick={() => this.props.onSetState({mode: ":EDIT_EMAIL:"})}>
                            <Edit style={{color: this.props.muiTheme.palette.secondaryTextColor}}/>
                        </IconButton>
                    </Tooltip>
                    : null}/>

            <div style={{padding: "24px"}}>
                {this.renderEmail(canEdit)}
            </div>

            <ActionButtons {...this.props} canEdit={canEdit} isValid={isValid}/>
        </Card>;
    }

    private renderEmail = canEdit => {
        const isSSO = this.props.state.data.type === "SSO";
        const helperText = isSSO && canEdit ? "Can not edit email on SSO User" : this.props.state.dataValidation.email;
        let props = {
            name: "email",
            value: this.props.state.data.email || "",
            label: "Your email",
            placeholder: "Enter your new email",
            helperText,
            fullWidth: true,
            onChange: canEdit ? e => this.props.onSetState({data: {...this.props.state.data, email: e.target.value}}) : undefined,
            disabled: !canEdit || isSSO,
            classes: {root: !canEdit ? "disabled-text-field" : ""},
            style: !canEdit ? {cursor: "default"} : undefined
        };

        return <TextField {...props}/>;
    }


    private validateData = () => {
        const dataValidation = validateData.onEditEmail(this.props.state);
        this.props.onSetState({dataValidation});
    }
}
