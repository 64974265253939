import * as React from "react";
import * as PropTypes from "prop-types";
import {Switch, TextField, IconButton, FormControlLabel, Tooltip} from "@material-ui/core";
import convertToUrlFriendly from "../../../../../../../lib/convertToUrlFriendly";
import * as validateData from "../../../../../../../lib/validate-apps-data";
import {Info} from "@material-ui/icons";

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.props.onSetState(prevState => ({
            data: {
                ...prevState.data,
                clientId: this.props.state.data.clientId || ""
            },
            dataValidation: {},
            isNextWStepEnabled: false
        }),
        () => this.validateData({validateClientId: true})
        );
    }

    public componentDidUpdate(prevProps) {
        const data_string = JSON.stringify(this.props.state.data);
        const prevData_string = JSON.stringify(prevProps.state.data);
        if (data_string !== prevData_string) {
            this.validateData({validateClientId: this.props.state.data.clientId !== prevProps.state.data.clientId});
        }
    }

    public render() {
        return <div style={{padding: "0 24px"}}>
            <TextField name="name" value={this.props.state.data.name || ""} placeholder="Type a name for the app" label="Display Name"
                helperText={this.props.state.dataValidation.name} error={!!this.props.state.dataValidation.name} fullWidth
                onChange={e => this.props.onSetState({
                    data: {
                        ...this.props.state.data,
                        name: e.target.value,
                        appId: convertToUrlFriendly(e.target.value.substring(0, 18))
                    }
                })}/>

            <TextField name="appId" value={this.props.state.data.appId || ""} placeholder="Type a unique ID" label="App ID" helperText={this.props.state.dataValidation.appId}
                error={!!this.props.state.dataValidation.appId} fullWidth style={{marginTop: "16px"}}
                onChange={e => this.props.onSetState({
                    data: {
                        ...this.props.state.data,
                        appId: convertToUrlFriendly(e.target.value.substring(0, 18))
                    }
                })}/>
            <FormControlLabel label="Enable Application Logging" style={{marginTop: "16px"}}
                control={<Switch color="primary" checked={this.props.state.data.logViewer || false}
                    onChange={e => this.props.onSetState({
                        data: {
                            ...this.props.state.data,
                            logViewer: e.target.checked
                        }
                    })}/>}/>
            <a href="https://support.interopio.com/hc/en-us/articles/360041549832" target="_blank" style={{display: "inline-block"}}>
                <Tooltip title={<span style={{display: "inline-block", padding: "8px 0", lineHeight: "normal", whiteSpace: "normal"}}>Read more</span>}>
                    <IconButton>
                        <Info/>
                    </IconButton>
                </Tooltip>
            </a>

            <TextField name="description" value={this.props.state.data.description || ""} label="Description" fullWidth multiline
                onChange={e => this.props.onSetState({
                    data: {
                        ...this.props.state.data,
                        description: e.target.value
                    }
                })}/>

            <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "16px"}}>
                <TextField name="clientId" value={this.props.state.data.clientId || ""} placeholder="Type a unique Client ID or leave blank to auto generate"
                    label={`Client ID ${!this.props.state.data.clientId ? "(leave blank to auto generate)" : ""}`}
                    helperText={this.props.state.dataValidation.clientId === ":LOADING:" ? "Please wait..." : this.props.state.dataValidation.clientId} fullWidth
                    error={!!this.props.state.dataValidation.clientId && this.props.state.dataValidation.clientId !== ":LOADING:"}
                    onChange={e => this.props.onSetState({
                        data: {
                            ...this.props.state.data,
                            clientId: e.target.value
                        }
                    })}/>
                <Tooltip title={<span style={{display: "block", padding: "8px 0", lineHeight: "normal", whiteSpace: "normal"}}>
                                This Client ID is issued by interopiO.
                                Use this Client ID in the SMART App.
                </span>}>
                    <IconButton>
                        <Info/>
                    </IconButton>
                </Tooltip>
            </div>
        </div>;
    }

    private isNextWStepEnabled = () => {
        const isValid = Object.keys(this.props.state.dataValidation).reduce((acc, key) => acc && !this.props.state.dataValidation[key], true);
        if (isValid !== this.props.state.isNextWStepEnabled) {
            this.props.onSetState({isNextWStepEnabled: isValid});
        }
    }

    private validateData = ({validateClientId}) => {
        const dataValidation = validateData.onWStep1(this.props.state, this.props.state.appsNoActivations, this.props.config)
        this.props.onSetState(
            (prevState) => ({
                dataValidation: {
                    ...prevState.dataValidation,
                    ...dataValidation
                }
            }),
            () => {
                if (validateClientId) {
                    this.props.onSetState(
                        (prevState) => ({
                            dataValidation: {
                                ...prevState.dataValidation,
                                clientId: ":LOADING:"
                            }
                        }),
                        () => {
                            this.isNextWStepEnabled();
                            validateData.onSetClientIdAsync(this.props)
                                .then((clientId) => {
                                    this.props.onSetState(
                                        (prevState) => ({
                                            dataValidation: {
                                                ...prevState.dataValidation,
                                                clientId
                                            }
                                        }),
                                        this.isNextWStepEnabled
                                    );
                                });
                        }
                    );
                }

                this.isNextWStepEnabled();
            }
        );
    }
}
