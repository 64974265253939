import * as React from "react";
import * as PropTypes from "prop-types";
import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../lib/getLocationInfo";
import {Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, Tooltip} from "@material-ui/core";
import CenteredCircularProgress from "../../../../../Widgets/CenteredCircularProgress/";
import {Close} from "@material-ui/icons";
import WStep1 from "./WStep1";
import "./style.less";
import getPortalCallUrl from "../../../../../../lib/getPortalCallUrl";
import getPortalId from "../../../../../../lib/getPortalId";
import {getPath} from "../../../../../../lib/utils";

const INIT_STATE = {
    status: "ready",
    appsPerGateway: [],
    data: {},
    gateways: [],
    isCreateEnabled: false
};
export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        let state = {...INIT_STATE};
        props.state.data && (state.data = JSON.parse(JSON.stringify(props.state.data)));
        props.state.appsPerGateway && (state.appsPerGateway = JSON.parse(JSON.stringify(props.state.appsPerGateway)));
        this.state = state;
    }

    public render() {
        let stepProps = {
            ...this.props,
            state: this.state,
            onSetState: (state, cb) => this.setState(state, cb)
        };

        return <Dialog open className="new-portal-dialog" onClose={() => this.props.onClose()}>
            <DialogTitle className="new-portal-dialog-content" style={{background: this.props.muiTheme.palette.primary1Color}}>
                {this.getTitle()}
            </DialogTitle>
            <DialogContent className="new-portal-dialog-body">
                {this.state.status !== "ready"
                    ? <CenteredCircularProgress size={63} className="portals-loader"/>
                    : <WStep1 {...stepProps}/>}
            </DialogContent>
            <DialogActions style={{background: "rgb(250, 250, 250)", padding: "0 32px 24px 32px"}}>
                {this.getActions()}
            </DialogActions>
        </Dialog>;
    }

    private getActions = () => {
        return (<>
            <Button
                variant="outlined"
                key="cancel"
                onClick={() => this.props.onClose()}
            >
                Cancel
            </Button>
            <Button
                data-qa="add-new-portal-launch-create-btn"
                color="primary"
                disabled={!this.state.isCreateEnabled}
                variant="contained"
                onClick={() => {
                    this.props.onSetState({status: "wait"}, () => {
                        const {accountId} = getHostnameInfo();
                        const {env} = getLocationInfo(this.props.location, this.props.config);
                        const gatewayId = getPath(this.props, "portals.selected.data.gatewayId")
                        const portalType = getPath(this.props, "portals.selected.data.portalType") || ''
                        const portalUrl = getPortalCallUrl(accountId, env, gatewayId, portalType)
                        const portalId = getPortalId(getPath(this.props, "portals.selected.data"))
                        const portalContext = this.props.portals.selected.data.portalType === 'PROVIDER'
                            ? {
                                providerPortalId: portalId,
                                providerPortalContextId: this.state.data.providerPortalContextId,
                                providerPortalCustomContexts: this.state.data.providerPortalCustomContexts,
                            }
                            : {
                                patientPortalId: portalId,
                                patientPortalContextId: this.state.data.patientPortalContextId,
                                patientPortalCustomContexts: this.state.data.patientPortalCustomContexts,
                            }
                        const data = {
                            appId: this.state.data.appId,
                            appName: this.state.data.appName,
                            displayName: this.state.data.displayName,
                            gatewayId: gatewayId,
                            intent: this.state.data.intent,
                            providerPortalCustomContexts: this.state.data.providerPortalCustomContexts,
                            ...portalContext,
                            portalType: this.state.data.portalType,
                            userContext: this.state.data.userContext,
                        };

                        const portalContextId = data.providerPortalContextId || data.patientPortalContextId
                        !this.props.state.update
                            ? this.props.portalsCreate(this.props.config, `${portalUrl}/${portalId}/${portalType.toLowerCase()}PortalContext`, data)
                                .then(() => {
                                    this.props.onClose(true);
                                })
                            : this.props.portalsUpdate(this.props.config, `${portalUrl}/${portalId}/${portalType.toLowerCase()}PortalContext/${portalContextId}`, data)
                                .then(() => {
                                    this.props.onClose(true);
                                });
                    });
                }}>
                {this.props.state.update ? "UPDATE" : "ADD"}
            </Button>
        </>)
    }

    private getTitle = () => {
        return <div className="new-portal-dialog-title">
            <span data-qa="add-new-portal-launch-modal-title">{this.props.edit ? "Edit" : "Add"} App Launch Configuration</span>
            <Tooltip title="Close">
                <IconButton className="new-portal-close-button" onClick={() => this.props.onClose()}>
                    <Close/>
                </IconButton>
            </Tooltip>
        </div>
    }
}
