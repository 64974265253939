import { withRouter } from "react-router"
import { connect } from 'react-redux'
import type { State } from 'xstate'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import getGatewaysResourceUrl from '../../../../../../lib/getGatewaysResourceUrl'
import getHostnameInfo from '../../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../../lib/getLocationInfo'
import CustomLink from '../../../../../Widgets/CustomLink'
import type { TContext, TSource } from '..'

type TProps = {
    config: any
    location: any
    state: State<TContext, any, any, any, any>
    ui: any
    getQueueName: () => string
    getSources: (
        // eslint-disable-next-line no-unused-vars
        context: TContext,
        // eslint-disable-next-line no-unused-vars
        configurationType: TContext['data']['configuration']['type'] | '',
    ) => TSource[]
}

const WStep4 = (props: TProps) => {
    const { accountId } = getHostnameInfo()
    const { env } = getLocationInfo(props.location, props.config)

    const configurationType = props.state.context.data.configuration?.type || ''
    const sources = props.getSources(props.state.context, configurationType)
    const defaultSourceId = props.state.context.data.configuration?.defaultDataSource || ':NO_DEFAULT_SOURCE:'
    const defaultSourceObject: Partial<ReturnType<typeof props.getSources>[number]> = sources.find((src) => src.id === defaultSourceId) || {}
    const defaultSourceName = defaultSourceObject.name || ''
    const defaultSourceUserStrategy = defaultSourceObject.userStrategy || ''
    let defaultUserStrategyLabel = ''
    switch (defaultSourceUserStrategy) {
        case 'INTEROPIO': defaultUserStrategyLabel = 'interopiO Users'; break
        case 'DELEGATED': defaultUserStrategyLabel = 'Delegated Users'; break
    }

    const queueName = props.getQueueName()

    const userDirsSlim = props.state.context.userDirsSlim
    const directoryId = props.state.context.data.userDirectory.directoryId
    const directoryObj = userDirsSlim.find((userDir) => userDir.directoryId === directoryId) || {}
    let directoryName = directoryObj.name || 'NONE'
    if (defaultSourceUserStrategy === 'DELEGATED') {
        directoryName = 'Delegated'
    }

    return (
        <div style={{ padding: '0 24px' }}>
            <TextField
                name="name"
                value={props.state.context.data.name}
                label="Display Name"
                fullWidth
                disabled
                classes={{ root: 'disabled-text-field' }}
            />

            <TextField
                name="gatewayId"
                value={props.state.context.data.gatewayId}
                label="Gateway ID"
                fullWidth
                disabled
                classes={{ root: 'disabled-text-field' }}
                style={{ marginTop: '18px' }}
            />

            <TextField
                name="gatewayUri"
                value={props.state.context.data.gatewayType === 'HL7_V2'
                    ? 'hl7:interopiondemo.smilecdr.com:7000'
                    : getGatewaysResourceUrl(accountId, env, props.state.context.data.gatewayId, props.config.proxyApi)}
                label="Gateway URI"
                fullWidth
                disabled
                classes={{ root: 'disabled-text-field' }}
                style={{ marginTop: '18px' }}
            />

            <TextField
                name="description"
                value={props.state.context.data.description}
                label="Description"
                fullWidth
                multiline
                disabled
                classes={{ root: 'disabled-text-field' }}
                style={{ marginTop: '18px' }}
            />

            <TextField
                name="gateway-cache"
                value={`Caching ${props.state.context.data.configCacheMinutes ? 'Enabled' : 'Disabled'}`}
                label="Cache"
                fullWidth
                disabled
                classes={{ root: 'disabled-text-field' }}
                style={{ marginTop: '18px' }}
            />

            <TextField
                name="gateway-hl7"
                value={`${props.state.context.data.hl7Enabled ? 'Enabled' : 'Disabled'}`}
                label="Enable Incoming HL7 V2 Messages"
                fullWidth
                disabled
                classes={{ root: 'disabled-text-field' }}
                style={{ marginTop: '18px' }}
            />

            {(() => {
                let value = ''
                switch (props.state.context.data.gatewayType) {
                    case 'FHIR_DSTU2__1_0_2': value = 'FHIR DSTU2 v1.0.2'; break
                    case 'FHIR_STU3__3_0_2': value = 'FHIR STU3 v3.0.2'; break
                    case 'FHIR_R4__4_0_1': value = 'FHIR R4 v4.0.1'; break
                    case 'HL7_V2': value = 'HL7 v2.x Listening Endpoint'; break
                    default: value = 'Unknown'; break
                }
                return (
                    <TextField
                        name="gatewayType"
                        value={value}
                        label="Gateway Standard"
                        fullWidth
                        disabled
                        classes={{ root: 'disabled-text-field' }}
                        style={{ marginTop: '18px' }}
                    />
                )
            })()}

            {(() => {
                let value = ''
                if (defaultSourceId && defaultSourceId !== ':NO_DEFAULT_SOURCE:') {
                    value = defaultSourceName
                }
                else value = 'No Default Data Adapter'
                return (
                    <div>
                        <TextField
                            name="description"
                            value={value}
                            label="Default Data Adapter"
                            fullWidth
                            multiline
                            disabled
                            classes={{ root: 'disabled-text-field' }}
                            style={{ marginTop: '18px' }}
                        />
                        {defaultUserStrategyLabel ? (
                            <Chip
                                label={defaultUserStrategyLabel}
                                style={{
                                    backgroundColor: defaultSourceUserStrategy === 'INTEROPIO' ? props.ui.xtheme.palette.colorBlueLight : props.ui.xtheme.palette.colorBlueDark,
                                    height: '20px',
                                    cursor: 'pointer',
                                }}
                            />
                        ) : null}
                    </div>
                )
            })()}

            {props.state.context.data.gatewayType !== 'HL7_V2' ? (() => {
                let value = ''
                switch (props.state.context.data.authenticationType) {
                    case 'NONE': value = 'NONE'; break
                    case 'SMART_ON_FHIR': value = 'SMART on FHIR'; break
                    case 'OKTA_JWK': value = 'OKTA JWK'; break
                    case 'JWK_ASSERTION': value = 'JWK ASSERTION'; break
                    default: value = 'Unknown'; break
                }
                return (
                    <TextField
                        name="authenticationType"
                        value={value}
                        label="Authentication Type"
                        fullWidth
                        disabled
                        classes={{ root: 'disabled-text-field' }}
                        style={{ marginTop: '18px' }}
                    />
                )
            })() : null}

            {props.state.context.data.gatewayType !== 'HL7_V2'
            && (directoryName === 'Delegated' || directoryName === 'NONE') ? (
                    <TextField
                        data-e2e='gw_usr_dir_delegated'
                        name='userDir'
                        value={directoryName}
                        label='User Directory'
                        disabled
                        fullWidth
                        classes={{ root: 'disabled-text-field' }}
                        style={{
                            paddingBottom: '6px',
                            cursor: 'default',
                            marginTop: '16px',
                        }}
                    />
                ) : null}
            {props.state.context.data.gatewayType !== 'HL7_V2'
            && directoryName !== 'Delegated'
            && directoryName !== 'NONE' ? (
                    <div>
                        <div className='portal-gateway-link-title'>
                            User Directory
                        </div>
                        <CustomLink to={`/${env}/auth/${directoryId}`}>
                            <span data-e2e={`gw_usr_dir_${directoryName}`}>
                                {directoryName}
                            </span>
                        </CustomLink>
                    </div>
                ) : null}

            {props.state.context.data.hl7Enabled ? <Divider style={{ marginTop: '18px' }} /> : null}

            {props.state.context.data.hl7Enabled ? (
                <TextField
                    value={props.state.context.data.hl7Properties.messageTypes}
                    label="Message Types"
                    fullWidth
                    disabled
                    classes={{ root: 'disabled-text-field' }}
                    style={{ marginTop: '18px' }}
                />
            ) : null}

            {props.state.context.data.hl7Enabled ? (
                <TextField
                    value={queueName}
                    label="Queue Name"
                    fullWidth
                    disabled
                    classes={{ root: 'disabled-text-field' }}
                    style={{ marginTop: '18px' }}
                />
            ) : null}

            {props.state.context.data.hl7Enabled ? (
                <TextField
                    value={props.state.context.data.hl7Properties.url}
                    label="URL"
                    fullWidth
                    disabled
                    classes={{ root: 'disabled-text-field' }}
                    style={{ marginTop: '18px' }}
                />
            ) : null}

            {props.state.context.data.hl7Enabled ? (() => {
                let value = ''
                switch (props.state.context.data.hl7Properties.urlAuthType) {
                    case 'NONE': value = 'NONE'; break
                    case 'CLIENT_CREDENTIALS': value = 'CLIENT CREDENTIALS'; break
                    default: value = 'Unknown'; break
                }
                return (
                    <TextField
                        value={value}
                        label="Authentication Type"
                        fullWidth
                        disabled
                        classes={{ root: 'disabled-text-field' }}
                        style={{ marginTop: '18px' }}
                    />
                )
            })() : null}

            {
                props.state.context.data.hl7Enabled
                && props.state.context.data.hl7Properties.urlAuthType === 'CLIENT_CREDENTIALS'
                    ? (
                        <TextField
                            value={props.state.context.data.hl7Properties.clientId}
                            label="Client ID"
                            fullWidth
                            disabled
                            classes={{ root: 'disabled-text-field' }}
                            style={{ marginTop: '18px' }}
                        />
                    ) : null
            }

            {
                props.state.context.data.hl7Enabled
                && props.state.context.data.hl7Properties.urlAuthType === 'CLIENT_CREDENTIALS'
                    ? (
                        <TextField
                            value={props.state.context.data.hl7Properties.clientSecret}
                            disabled
                            label="Client Secret"
                            fullWidth
                            type="password"
                            classes={{ root: 'disabled-text-field' }}
                            style={{ marginTop: '18px' }}
                        />
                    ) : null
            }

            {
                props.state.context.data.hl7Enabled
                && props.state.context.data.hl7Properties.urlAuthType === 'CLIENT_CREDENTIALS'
                    ? (
                        <TextField
                            value={props.state.context.data.hl7Properties?.clientAuthUrl}
                            label="Client Auth URL"
                            fullWidth
                            disabled
                            classes={{ root: 'disabled-text-field' }}
                            style={{ marginTop: '18px' }}
                        />
                    ) : null
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ui: state.ui,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(WStep4))
