import * as React from "react";
import * as PropTypes from "prop-types";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import {Button} from "@material-ui/core";

const INIT_STATE = {
    status: "ready" // "ready" | "wait"
};

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public render() {
        const {domain, port, protocol, type} = getHostnameInfo();

        return <div
            style={{backgroundImage: "url(/console/img/mountain-view@2x.png)", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            <div style={{textAlign: "center", color: "#fff", lineHeight: "36px", margin: "auto"}}>
                <h1 style={{fontSize: "28px", fontWeight: 500, margin: 0}}>You did it.</h1>
                <h1 style={{fontSize: "28px", fontWeight: 500, margin: "0 0 48px"}}>Health care is better off with you.</h1>
                <Button variant="contained" style={{width: "320px"}} color="primary"
                    onClick={() => window.location.href = `${protocol}//${this.props.state.data.accountId}.${domain}.${type}${port ? ":" + port : ""}/console/signin`}>
                    {`Sign in to ${this.props.state.data.accountName}`}
                </Button>
            </div>
            <div style={{marginBottom: "48px"}}>
                <a href="https://interopion.com/" target="_blank">
                    <img style={{width: "32px", marginRight: "16px"}} src="/console/img/interopion-icon@2x.png"/>
                </a>
                <a href="https://interopio.com/" target="_blank">
                    <img style={{width: "32px", marginLeft: "16px"}} src="/console/img/interopio-icon-lg@2x--r.png"/>
                </a>
            </div>
        </div>;
    }
}
