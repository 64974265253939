import * as PropTypes from "prop-types";
import "./style.less";

const CardWrapper = (props) => {
    const style = {
        cursor: props.onClick ? "pointer" : "default",
        ...props.style
    };

    return <div data-qa={props["data-qa"]} className={`card-wrapper ${props.className}`} style={style} onClick={props.onClick}>
        {props.children}
    </div>;
}

CardWrapper.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    "data-qa": PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
};

CardWrapper.defaultProps = {
    className: "",
    "data-qa": "",
    style: {},
    onClick: null,
};

export default CardWrapper;
