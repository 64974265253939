import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { createMachine } from 'xstate'
import { useMachine } from '@xstate/react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import axiosWrapper from '../../../../../lib/axiosWrapper'
import { isEnvironmentAdmin } from '../../../../../lib/user-environment-permissions-helpers'
import type {
    TApp,
    TAppActivation,
    TDataSource,
    TGateway,
} from '../../../../../types'
import { Table } from '../../../../Widgets/Table'
import ActivationItem from './ActivationItem'

export type TParentState = {
    status: string
    selectedActivation?: string | TAppActivation
    activations?: TAppActivation[]
}

type TProps = {
    apps: {
        all: {
            status: string,
        },
        selected: {
            status: string,
            data?: TApp,
        },
    }
    config: any
    dataSources: {
        all: {
            status: string,
            data: TDataSource[]
        }
    }
    gateways: {
        all: {
            status: string,
            data?: TGateway[]
        }
    }
    location: any
    login: any
    parentState: TParentState
    initialize: Function
    onSetParentState: Function
}

const Activations: React.FC<TProps> = (props: TProps) => {
    const [state] = useMachine(() => createMachine({
        id: 'activations',
        initial: 'start',
        states: {
            start: {
                after: {
                    550: 'fetching_activations',
                },
            },
            fetching_activations: {
                invoke: {
                    src: 'fetchActivations',
                    onDone: {
                        target: 'activations_fetched_delay',
                        actions: 'setActivations',
                    },
                },
            },
            activations_fetched_delay: {
                after: {
                    755: {
                        target: 'activations_fetched'
                    },
                },
            },
            activations_fetched: {
                type: 'final',
            },
        },
    }, {
        actions: {
            setActivations: (_, event) => {
                const activations = event.data.data
                props.onSetParentState({ activations })
            },
        },
        services: {
            fetchActivations: () => {
                if (props.apps.selected.status === 'ready') {
                    const accountId = props.apps.selected.data.accountId
                    const environmentId = props.apps.selected.data.environmentId
                    const appId = props.apps.selected.data?.appId
                    const path = `api/${accountId}/env/${environmentId}/apps/activations/${appId}`
                    return axiosWrapper(props.config.envApi, path, 'GET')
                }
            }
        },
    }))

    let dataLoadingProgress = 0
    if (props.apps.all.status === 'ready') {
        dataLoadingProgress = 10
        if (props.apps.selected.status === 'ready') {
            dataLoadingProgress = 20
            if (props.dataSources.all.status === 'ready') {
                dataLoadingProgress = 30
                if (props.gateways.all.status === 'ready') {
                    dataLoadingProgress = 75
                    if (
                        state.matches('activations_fetched_delay')
                        || state.matches('activations_fetched')
                    ) {
                        dataLoadingProgress = 100
                    }
                }
                else if (
                    state.matches('activations_fetched_delay')
                    || state.matches('activations_fetched')
                ) {
                    dataLoadingProgress = 55
                }
            }
        }
    }

    const actAsAdmin = isEnvironmentAdmin(props.login, props.location, props.config)
    let rightFilters = []
    if (actAsAdmin) {
        if (dataLoadingProgress !== 100 || !state.matches('activations_fetched')) {
            rightFilters.push({
                type: 'component',
                value: (
                    <Box
                        key="data_loading_progress"
                        position="relative"
                        display="inline-flex"
                    >
                        <CircularProgress/>
                        <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography
                                variant="caption"
                                component="div"
                                color="textSecondary"
                            >
                                {`${dataLoadingProgress}%`}
                            </Typography>
                        </Box>
                    </Box>
                ),
            })
            rightFilters.push({
                type: 'component',
                value: (
                    <Typography
                        key="loading_msg"
                        variant="caption"
                        component="div"
                        color="textSecondary"
                    >
                        Loading...
                    </Typography>
                ),
            })
        }
        else {
            if (props.gateways.all.status === 'ready') {
                const gateways = props.gateways.all.data || []
                if (!gateways.length) {
                    rightFilters.push({
                        type: 'component',
                        value: (
                            <Typography
                                key="no-gateways-in-env-msg"
                                variant="caption"
                                color="textSecondary"
                            >
                                Тhe functionality of this screen is not active because no gateways have been created in this iO Environment
                            </Typography>
                        ),
                    })
                }
                else {
                    const appAllActivationsGatewayIds = props.parentState.activations
                        .map(({ appId, gatewayId }) => appId === props.apps.selected.data.appId && gatewayId)

                    const filteredGateways = gateways
                        .filter((gw) => {
                            if (gw.authenticationType !== 'NONE') {
                                if (!appAllActivationsGatewayIds.includes(gw.gatewayId)) {
                                    return true
                                } else {
                                    const dsId = gw.configuration?.defaultDataSource || ''
                                    const ds = props.dataSources.all.data
                                        .find((ds) => ds.dataSourceId === dsId) || {}
                                    const dsType = ds.type || ''
                                    return ['DataSourceEpicR4', 'DataSourceEpicR4External'].includes(dsType) && appAllActivationsGatewayIds.filter((id) => (id === gw.gatewayId)).length < 2
                                }
                            }
                            return false
                        })
                    rightFilters.push({
                        type: 'component',
                        value: (
                            <Button
                                data-e2e="add_activation_btn"
                                key="add_activation_btn"
                                disabled={!filteredGateways.length}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    props.onSetParentState({ dialog: ':ADD_ACTIVATION:' })
                                }}
                            >
                                Add Activation
                            </Button>
                        ),
                    })
                }
            }
        }
    }

    return (
        <Table
            rightFilters={rightFilters}
            columns={[
                {
                    name: 'name',
                    label: 'Activation',
                    size: 1,
                },
                {
                    name: 'gateway',
                    label: 'Gateway',
                    size: 2.7,
                },
            ]}
        >
            <List>
                {props.parentState.activations.map((activation) => (
                    <ActivationItem
                        key={activation.name}
                        activation={activation}
                        initialize={props.initialize}
                        parentState={props.parentState}
                        onSetParentState={props.onSetParentState}
                    />
                ))}
            </List>
        </Table>
    )
}

const mapStateToProps = (state, ownProps) => ({
    apps: state.apps,
    config: state.config,
    dataSources: state.dataSources,
    gateways: state.gateways,
    login: state.login,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(Activations))
