import * as PropTypes from "prop-types";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../../redux/actions";
import getDataSourcesCallUrl from "../../../../../lib/getDataSourcesCallUrl";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import CheckIcon from "@material-ui/icons/Check";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const CustomDialogActions = (props) => {
    return <DialogActions style={{padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)"}}>
        {props.wstate.wstep > props.WSTEP_MIN
            ? <Button disabled={props.wstate.status !== "ready"} variant="outlined" color="primary" style={{marginRight: 8}} startIcon={<KeyboardArrowLeftIcon/>}
                onClick={() => props.onSetWState({wstep: props.calcPrevWStep()})}>
                Back
            </Button>
            : null}
        {props.wstate.wstep < props.WSTEP_MAX
            ? <Button data-qa="data-adapter-wizard-next" variant="contained" color="primary" style={{marginRight: 8}} endIcon={<KeyboardArrowRightIcon/>}
                disabled={props.wstate.status !== "ready" || !props.wstate.isNextWStepEnabled} onClick={() => props.onSetWState({wstep: props.calcNextWStep()})}>
                Next
            </Button>
            : null}
        {props.wstate.wstep === props.WSTEP_MAX
            ? <Button data-qa="data-adapter-wizard-create" variant="contained" color="primary" style={{marginRight: 8}} startIcon={<CheckIcon/>}
                disabled={props.wstate.status !== "ready" || !props.wstate.isNextWStepEnabled}
                onClick={async () => {
                    props.onSetWState({status: "waiting"});

                    const accountId = props.wstate.data.accountId;
                    const environmentId = props.wstate.data.environmentId;
                    const dataSourceId = props.wstate.data.dataSourceId;

                    const data: any = {
                        accountId,
                        environmentId,
                        name: props.wstate.data.name,
                        dataSourceId,
                        description: props.wstate.data.description,
                        phi: props.wstate.data.phi,
                        type: props.wstate.data.type,
                        configuration: {},
                        externalAuth: props.wstate.data.externalAuth,
                    };

                    switch (props.wstate.data.type) {
                        case "DataSourceFhirDstu2Proxy":
                            data.configuration.authenticationType = props.wstate.data.configuration.authenticationType;
                            data.configuration.fhirDstu2Endpoint = props.wstate.data.__temp__endpoint;
                            break;

                        case "DataSourceAllscriptsDstu2":
                            data.configuration.allscriptsDstu2Endpoint = props.wstate.data.__temp__endpoint;
                            break;

                        case "DataSourceCernerDstu2":
                            data.configuration.cernerDstu2Endpoint = props.wstate.data.__temp__endpoint;
                            break;

                        case "DataSourceEpicDstu2":
                            data.configuration.epicDstu2Endpoint = props.wstate.data.__temp__endpoint;
                            data.configuration.patientStagingDataEnabled = props.wstate.data.configuration.patientStagingDataEnabled;
                            data.configuration.patientStagingDataRestUrl = props.wstate.data.configuration.patientStagingDataEnabled ? props.wstate.data.configuration.patientStagingDataRestUrl : null;
                            data.configuration.fileClinicalNoteEnabled = props.wstate.data.configuration.fileClinicalNoteEnabled;
                            data.configuration.fileClinicalNoteRestUrl = props.wstate.data.configuration.fileClinicalNoteEnabled ? props.wstate.data.configuration.fileClinicalNoteRestUrl : null;
                            break;

                        case "DataSourceFlatironOncoEmrDstu2":
                            data.configuration.oncoEmrEndpoint = props.wstate.data.__temp__endpoint;
                            break;

                        case "DataSourceFhirStu3Proxy":
                            data.configuration.authenticationType = props.wstate.data.configuration.authenticationType;
                            data.configuration.fhirStu3Endpoint = props.wstate.data.__temp__endpoint;
                            break;

                        case "DataSourceFhirR4Proxy": {
                            let config = {};
                            try {
                                config = JSON.parse(props.wstate.data.configuration.json);
                            } catch (reason) {
                                console.warn("::: Warning:", reason)
                            }
                            data.configuration = config;
                            data.configuration.authenticationType = props.wstate.data.configuration.authenticationType;
                            data.configuration.fhirR4Endpoint = props.wstate.data.__temp__endpoint;
                            break;
                        }

                        case "DataSourceCernerR4":
                            data.configuration.cernerR4Endpoint = props.wstate.data.__temp__endpoint;
                            break;

                        case "DataSourceHspc":
                            data.configuration.sandboxId = props.wstate.data.configuration.sandboxId;
                            data.configuration.secured = props.wstate.data.configuration.secured;
                            data.configuration.fhirVersion = (() => {
                                switch (props.wstate.data.__temp__fhirVersionSelected) {
                                    case "1.0.2":
                                        return "FHIR_DSTU2__1_0_2";
                                    case "3.0.2":
                                        return "FHIR_STU3__3_0_2";
                                    case "4.0.1":
                                        return "FHIR_R4__4_0_1";
                                    default:
                                        return "";
                                }
                            })();
                            break;

                        case "DataSourceDstu2ToR4":
                            data.configuration.authenticationType = props.wstate.data.configuration.authenticationType;
                            data.configuration.dstu2Endpoint = props.wstate.data.__temp__endpoint;
                            break;

                        default: {
                            let config = {};
                            try {
                                config = JSON.parse(props.wstate.data.configuration.json);
                            } catch (reason) {
                                console.warn("::: Warning:", reason)
                            }
                            data.configuration = config;
                            break;
                        }
                    }

                    const dataSourceUrl = getDataSourcesCallUrl(accountId, environmentId) + "/" + dataSourceId;
                    const response = await props.dataSourcesCreate(props.config, dataSourceUrl, data);
                    if (response) {
                        props.onClose()
                        props.initialize()
                        props.history.push(`/${environmentId}/datasources/${response.data.dataSourceId}`);
                    }
                    else {
                        props.onSetWState({ status: 'ready' })
                    }
                }}>
                Create Data Adapter
            </Button>
            : null}
    </DialogActions>;
}

CustomDialogActions.propTypes = {
    config: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    wstate: PropTypes.object.isRequired,
    WSTEP_MAX: PropTypes.number.isRequired,
    WSTEP_MIN: PropTypes.number.isRequired,
    calcNextWStep: PropTypes.func.isRequired,
    calcPrevWStep: PropTypes.func.isRequired,
    dataSourcesCreate: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSetWState: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    dataSourcesCreate: actions.dataSourcesCreate
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomDialogActions));
