import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../redux/actions";
import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../lib/getLocationInfo";
import getCdsServiceSetsCallUrl from "../../../../../../lib/getCdsServiceSetsCallUrl";
import getGatewaysCallUrl from "../../../../../../lib/getGatewaysCallUrl";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogActions from "./CustomDialogActions";
import CustomDialogTitle from "./CustomDialogTitle";
import CustomDialogContent from "./CustomDialogContent";

const WSTEP_MIN = 1;
const WSTEP_MAX = 1;

const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "waiting"
    data: {},
    dataValidation: {},
    wstep: WSTEP_MIN,
    isNextWStepEnabled: false,
};
class ActivationAddNewWizard extends React.Component<any, any> {
    public static propTypes = {
        cdsServiceSets: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        cdsServiceSetsRead: PropTypes.func.isRequired,
        gatewaysReadAll: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.initialize();
    }

    public render() {
        return <Dialog
            open disableBackdropClick disableEscapeKeyDown scroll = "body"
            onClose={this.props.onClose}
        >
            <CustomDialogTitle onClose={this.props.onClose} />
            <CustomDialogContent
                wstate={this.state}
                onSetWState={this.onSetState}
            />
            <CustomDialogActions
                wstate={this.state}
                WSTEP_MIN={WSTEP_MIN} WSTEP_MAX={WSTEP_MAX}
                calcNextWStep={this.calcNextWStep}
                calcPrevWStep={this.calcPrevWStep}
                initialize={this.props.initialize}
                onSetWState={this.onSetState}
            />
        </Dialog >
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private calcNextWStep = () => this.state.wstep < WSTEP_MAX ? this.state.wstep + 1 : WSTEP_MAX;

    private calcPrevWStep = () => this.state.wstep > WSTEP_MIN ? this.state.wstep - 1 : WSTEP_MIN;

    private initialize = async() => {
        this.setState({status: "init"});
        const {accountId} = getHostnameInfo();
        const {env, cdsServiceSetId} = getLocationInfo(this.props.location, this.props.config);
        const cdsServiceSetUrl = getCdsServiceSetsCallUrl(accountId, env) + "/" + cdsServiceSetId;
        const gatewaysUrl = getGatewaysCallUrl(accountId, env);
        await Promise.all([
            this.props.cdsServiceSetsRead(this.props.config, cdsServiceSetUrl),
            this.props.gatewaysReadAll(this.props.config, gatewaysUrl)
        ]);
        const cdsServices = this.props.cdsServiceSets?.selected?.data?.cdsServices || [];
        const cdsServiceGatewayActivations = cdsServices.map((service) => ({
            disabled: false,
            id: service.id,
            overrideId: null
        }));
        this.setState({
            ...INIT_STATE,
            status: "ready",
            data: {
                ...INIT_STATE.data,
                cdsServiceGatewayActivations,
            },
        });
    };
};

const mapStateToProps = (state, ownProps) => ({
    cdsServiceSets: state.cdsServiceSets,
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsRead: actions.cdsServiceSetsRead,
    gatewaysReadAll: actions.getGatewayJwkSet,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivationAddNewWizard));
