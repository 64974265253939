import * as validateId from "./validate-id";

export function onWStep1(
    state: any,
    cdrs: any,
    config: any,
) {
    const name = state.data.name ? "" : "Required field";

    const ids = cdrs.all.data.map((item) => item.cdrId);
    let cdrId = "";
    cdrId = validateId.isUrlFriendly(state.data.cdrId) ? cdrId : "The ID can only contain: lower case letters of the English alphabet, numbers (0-9), hyphen/minus sign (-), it must start with a letter and end with a letter or number, not a hyphen";
    cdrId = validateId.isNotTooLong(state.data.cdrId) ? cdrId : "This ID is too long";
    cdrId = validateId.isNotTooShort(state.data.cdrId) ? cdrId : "This ID is too short";
    cdrId = state.data.cdrId ? cdrId : "Required field";
    cdrId = validateId.isUnique(state.data.cdrId, ids) ? cdrId : "This ID already exists";
    cdrId = validateId.isNotRestricted(state.data.cdrId, config.reservedIds) ? cdrId : "This ID is reserved";

    return {
        name,
        cdrId,
    };
}

export function onEditDetails(state: any) {
    const name = state.data.name ? "" : "Required field";
    return { name };
}

export function onEditImportJSON(state: any) {
    let importString: string = state.importString;
    let importMessage: string = "";

    if (importString) {
        try {
            JSON.parse(importString);
        } catch (reason) {
            importMessage = "Invalid JSON format"
        }
        if (importString === "null") {
            importMessage = "Invalid JSON format"
        }
        if (importString.length > 15000) {
            importMessage = "Character limit of 15000 reached. If you want to import bigger Bundles use the Import from File button"
        }
    }

    return {
        import: importMessage,
    };
}
