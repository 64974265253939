import { getPath } from "../../lib/utils/";

import * as types from "./types";

export function loginReset() {
    // sessionStorage.clear();
    sessionStorage.removeItem("token");
    return { type: types.LOGIN_RESET };
}

export function loginSet(payload) {
    const token = getPath(payload, "data.token");
    if (token) {
        sessionStorage.setItem("token", token);
    }

    return {
        type: types.LOGIN_SET,
        payload,
    };
}

