export default (windowLocation = window.location) => {

    // List of the whole domain (subdomains included) without the account id subdomain.
    // This way the admin console won't think the interopio subdomain is an account id
    const BASE_DOMAINS = ["interopio.mckesson.local", "interopio-dev.mckesson.com", "interopio-qa.mckesson.com", "interopio-qaphi.mckesson.com", "interopio.ontada.com", "io.ontada.com", "interopio.mckesson.com", "interopio.local", "interopio.com", "interopio-test.com"]


    function getHostnameInfo() {
        let result = {
            domain : "",
            type : "",
            accountId : "",
            subdomain: "",
            port: windowLocation.port,
            protocol: windowLocation.protocol,
        };

        // This condition is used for unit tests
        const hostname = process.env.NODE_ENV !== "test" ? windowLocation.hostname : "test.interopio.mckesson.local";
        let hostnameParts = hostname.split(".");
        result.type = hostnameParts.pop();
        // Get all domains by taking out the last element, the type
        if (BASE_DOMAINS.indexOf(hostname) === -1) {
            // If not reserved base domain, then the first subdomain will be the account id
            result.accountId = hostnameParts[0];
            // Remove the accountId from the domain
            hostnameParts = hostnameParts.slice(1);
        }
        // Want to include the base domain as well as all subdomains that are a part of the base of interopiO
        result.domain = hostnameParts.join(".")
        return result;
    }

    return getHostnameInfo();
}
