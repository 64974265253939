import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../../../../../redux/actions'
import getAppsCallUrl from '../../../../../../../lib/getAppsCallUrl'
import getHostnameInfo from '../../../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../../../lib/getLocationInfo'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import type { TApp, TAppActivation } from '../../../../../../../types'
import type { TParentState } from '../../../Activations'
import type { TStatus as TDStatus } from '..'

type TProps = {
    apps: {
        selected: {
            data?: TApp
        }
    }
    config: any
    dstatus: TDStatus
    location: any
    parentState: TParentState
    selectedAppActivation?: TAppActivation
    appsUpdate: Function
    onCancel: () => void
    onClose: () => void
    setDStatus: React.Dispatch<React.SetStateAction<TDStatus>>
}

const CustomDialogActions: React.FC<TProps> = (props: TProps) => {
    return (
        <DialogActions style={{ padding: '24px', paddingTop: 0, background: 'rgb(250, 250, 250)' }}>
            <Button
                disabled={props.dstatus !== ':READY:'}
                color="primary"
                style={{ marginRight: '8px' }}
                onClick={props.onCancel}
            >
                Cancel
            </Button>

            <Button
                data-e2e={`act_delete_btn_${props.selectedAppActivation.name}`}
                disabled={props.dstatus !== ':READY:'}
                variant="contained"
                color="secondary"
                onClick={async () => {
                    props.setDStatus(':DELETING:')

                    const activations = props.parentState.activations
                    const selectedActivation = props.selectedAppActivation
                    const updatedActivations = activations
                        .filter((act) => act?.name !== selectedActivation?.name)
                    const data = props.apps.selected?.data || {}
                    const updatedData = {
                        ...data,
                        activations: updatedActivations,
                    }

                    const { accountId } = getHostnameInfo()
                    const { env } = getLocationInfo(props.location, props.config)
                    const appUrl = getAppsCallUrl(accountId, env) + `/${props.apps.selected.data.appId}`
                    await props.appsUpdate(props.config, appUrl, updatedData)

                    props.setDStatus(':READY:')
                    props.onClose()
                }}
            >
                Delete Activation
            </Button>
        </DialogActions>
    )
}

const mapStateToProps = (state, ownProps) => ({
    apps: state.apps,
    config: state.config,
    ...ownProps,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appsUpdate: actions.appsUpdate,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomDialogActions))
