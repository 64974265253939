import * as validateId from "./validate-id";

export function onWStep1(state: any, environments: any, config: any) {
    const name = state.data.name ? "" : "Required field";

    const ids = environments.all.data.map((item) => item.environmentId);
    let environmentId = "";
    environmentId = validateId.isUrlFriendly(state.data.environmentId) ? environmentId : "The ID can only contain: lower case letters of the English alphabet, numbers (0-9), hyphen/minus sign (-), it must start with a letter and end with a letter or number, not a hyphen";
    environmentId = validateId.isNotTooLong(state.data.environmentId) ? environmentId : "This ID is too long";
    environmentId = validateId.isNotTooShort(state.data.environmentId, 1) ? environmentId : "This ID is too short";
    environmentId = validateId.isNotReservedWord(state.data.environmentId) ? environmentId : "This word is not allowed as an ID";
    environmentId = state.data.environmentId ? environmentId : "Required field";
    environmentId = validateId.isUnique(state.data.environmentId, ids) ? environmentId : "This ID already exists";
    environmentId = validateId.isNotRestricted(state.data.environmentId, config.reservedIds) ? environmentId : "This ID is reserved";

    const phi = state.data.phi === true || state.data.phi === false ? "" : "Required field";

    return {
        name,
        environmentId,
        phi,
    };
}

export function onEditDetails(state: any) {
    const name = state.data.name ? "" : "Required field";
    return { name };
}
