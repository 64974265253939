import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import isUrl from 'is-url'
import getHostnameInfo from '../../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../../lib/getLocationInfo'
import { isEnvironmentAdmin } from '../../../../../../lib/user-environment-permissions-helpers'
import type {
    TApp,
    TAppActivation,
    TDataSource,
    TGateway,
} from '../../../../../../types'
import CustomDialogActions from './CustomDialogActions'
import CustomDialogContent from './CustomDialogContent'
import CustomDialogTitle from './CustomDialogTitle'

const STEP_MIN = 1
const STEP_MAX = 1

export type TStatus = ':INIT:' | ':READY:' | ':WAITING:' | ':SAVING:'

export type TAuthMode = ':SMART_1:' | ':SMART_2:'

export type TEhrAuthType = ':PROXY:' | ':SYSTEM:'

export type TDataValidation = Partial<{
    appActivation: Partial<{
        gatewayId: string
        interopioContext: string
        name: string
        allscriptsUnityAppName: string
        clientId: string
        clientSecret: string
        clientAuthType: string
        tenantsPracticeId: string
        tenantsPracticeName: string
        publicPrivateKeyPairGenerated: string
        oktaTokenIssuer: string
        permissionsLocation: string
        permissionName: string
    }>
}>

export type TProxyAuthType = ':PUBLIC:' | ':CONFIDENTIAL:'

export type TSystemAuthType = ':BACKEND:' | ':EXTERNAL:' | ':OTHER:'

type TProps = {
    apps: {
        selected: {
            data?: TApp
        }
    }
    config: any
    dataSources: {
        all: {
            data?: TDataSource[]
        }
    }
    gateways: {
        all: {
            data?: TGateway[]
        }
    }
    location: any
    login: any
    muiTheme: any
    parentState: any
    onClose: () => void

    selectedAppActivation?: TAppActivation
}

const ActivationEditor: React.FC<TProps> = (props: TProps) => {
    const isInitRef = React.useRef(true)

    const [status, setStatus] = React.useState<TStatus>(':INIT:')
    const [step, setStep] = React.useState(STEP_MIN)

    const [canEdit, setEdit] = React.useState(false)

    const [authMode, setAuthMode] = React.useState<TAuthMode>(':SMART_1:')
    const [disableAuthMode, setDisableAuthMode] = React.useState<boolean>(false)
    const [ehrAuthType, setEhrAuthType] = React.useState<TEhrAuthType>(':PROXY:')
    const [proxyAuthType, setProxyAuthType] = React.useState<TProxyAuthType>(':PUBLIC:')
    const [systemAuthType, setSystemAuthType] = React.useState<TSystemAuthType>(':BACKEND:')
    const [isAppLaunchingWithinIKMG2, setIsAppLaunchingWithinIKMG2] = React.useState((props.selectedAppActivation?.tenants?.length === 0 && !props.selectedAppActivation?.allPractices) || false);

    const [appActivation, setAppActivation] = React.useState<TAppActivation>({securityOption: 'NONE'})

    const actAsAdmin = isEnvironmentAdmin(props.login, props.location, props.config)

    const { accountId } = getHostnameInfo()
    const { env } = getLocationInfo(props.location, props.config)

    const selectedApp : any = props.apps.selected.data || {}

    const allDataSources = props.dataSources.all.data || []

    // For Bulk apps - only Gateways with authenticationType JWK_ASSERTION
    let allGateways = props.gateways.all.data || []
    if(selectedApp?.appAuthTypes?.includes('MULTI_PATIENT_ACCESS')) {
        allGateways = allGateways.filter((gw) => {
            return gw.authenticationType === 'JWK_ASSERTION'
        })
    }

    const activations = selectedApp.activations || []

    const alreadySelectedGatewayIds = activations
        .map((act) => act.gatewayId)

    const filteredGateways = allGateways
        .filter((gw) => {
            if (gw.authenticationType !== 'NONE') {
                if (
                    !alreadySelectedGatewayIds.includes(gw.gatewayId)
                    || gw.gatewayId === appActivation.gatewayId
                    || gw.gatewayId === props.selectedAppActivation?.gatewayId
                ) {
                    return true;
                } else {
                    const dsId = gw.configuration?.defaultDataSource || ''
                    const ds = allDataSources
                        .find((ds) => ds.dataSourceId === dsId) || {}
                    const dsType = ds.type || ''
                    return ['DataSourceEpicR4', 'DataSourceEpicR4External', 'DataSourceVarianR4External'].includes(dsType) && alreadySelectedGatewayIds.filter((id) => (id === gw.gatewayId)).length < 2
                }
            };
            return false
        })

    const selectedGatewayId = appActivation.gatewayId || ''
    const selectedGateway = allGateways
        .find((gw) => gw.gatewayId === selectedGatewayId) || {}

    const isExtAuthGtw = Boolean(selectedGateway.appActivations?.find((act) => {
        return act.externalAuth && act.externalAuth?.externalAuthApiId !== props.selectedAppActivation?.externalAuth?.externalAuthApiId
    }))

    const selectedDataSourceId = selectedGateway.configuration?.defaultDataSource || ''
    const selectedDataSource = allDataSources
        .find((ds) => ds.dataSourceId === selectedDataSourceId) || {}
    const selectedDataSourceType = selectedDataSource.type || ''

    const isVarianR4Gateway = ['DataSourceVarianR4External']
        .includes(selectedDataSourceType)

    const isAllscriptsUnityGateway = selectedGateway.requiredProxyType === 'ALLSCRIPTS_UNITY'

    const isCernerR4Gateway = ['DataSourceCernerR4' /* , ... */]
        .includes(selectedDataSourceType)

    const isEpicR4Gateway = ['DataSourceEpicR4', 'DataSourceEpicR4External']
        .includes(selectedDataSourceType)

    const isEpicR4ExternalGateway = (selectedDataSourceType === 'DataSourceEpicR4External')

    const isIKnowMedExtGateway = ['DataSourceIKnowMedR4External']
        .includes(selectedDataSourceType)

    const isLaunchProxyRequired = !!selectedGateway.requiresLaunchProxy

    const daSystemAuthEnabled = Boolean(selectedDataSource.configuration?.systemAuthEnabled)
    const daExternalAuth = Boolean(selectedDataSource.externalAuth)
    let isExtAuthDa = daExternalAuth
    if (isEpicR4Gateway && daExternalAuth) {
        isExtAuthDa = daSystemAuthEnabled
    }
    if (isCernerR4Gateway || isAllscriptsUnityGateway) {
        isExtAuthDa = false
    }

    let interopioContextError = ''
    if (appActivation.interopioContext !== null) {
        try {
            JSON.parse(appActivation.interopioContext)
        }
        catch (reason) {
            interopioContextError = reason.toString()
        }
    }

    let dataValidationName = appActivation.name ? '' : 'Required field'
    if (appActivation.name && !dataValidationName) {
        const appActivationName = appActivation.name.trim()
        const selectedAppActivationName = props.selectedAppActivation?.name
        const allAppActivations = props.apps.selected.data?.activations || []
        const allAppActivationNames = allAppActivations.map(({ name }) => name)
        const filteredAppActivationNames = selectedAppActivationName
            ? allAppActivationNames.filter((name) => name !== selectedAppActivationName)
            : allAppActivationNames
        dataValidationName = filteredAppActivationNames.includes(appActivationName)
            ? 'The activation name must be unique'
            : ''
    }
    const allscriptsUnityAppName = isAllscriptsUnityGateway && !appActivation.allscriptsUnityLaunchProxyInfoApi?.appName
        ? 'Required field' : ''
    const clientId = (
        appActivation.smartLaunchProxyInfo?.clientSecret && !appActivation.smartLaunchProxyInfo?.clientId
        || appActivation.smartLaunchProxyInfo2Api?.clientSecret && !appActivation.smartLaunchProxyInfo2Api?.clientId
    )
        ? 'Client ID is required if there is already a Client Secret' : ''
    const clientSecret = proxyAuthType === ':CONFIDENTIAL:' && appActivation.smartLaunchProxyInfo && !appActivation.smartLaunchProxyInfo.clientSecret
        ? 'Required field' : ''
    const clientAuthType = proxyAuthType === ':CONFIDENTIAL:' &&
        appActivation.smartLaunchProxyInfo2Api &&
        !appActivation.smartLaunchProxyInfo2Api.clientAuthenticationMethod
        ? 'Required field' : ''
    const publicPrivateKeyPairGenerated = appActivation.smartLaunchProxyInfo2Api?.clientAuthenticationMethod === 'ASYMMETRIC' && !appActivation.smartLaunchProxyInfo2Api?.publicKey
        ? 'Generating Public/Private Key Pair is required' : ''


    let tenantsPracticeId = ''
    let tenantsPracticeName = ''

    if (isIKnowMedExtGateway && !appActivation.tenants?.length && !isAppLaunchingWithinIKMG2 && !appActivation.allPractices) {
        tenantsPracticeId = 'At least one practice is required'
        tenantsPracticeName = 'At least one practice is required'
    }

    let oktaTokenIssuer = ''
    let permissionsLocation = ''
    let permissionName = ''

    if (appActivation.securityOption === "OKTA" && appActivation.appActivationOktaPropertiesApi) {
        if (!appActivation.appActivationOktaPropertiesApi.oktaTokenIssuer) {
            oktaTokenIssuer = 'OKTA Token Issuer is required'
        }
        if(!isUrl(appActivation.appActivationOktaPropertiesApi.oktaTokenIssuer)) {
            oktaTokenIssuer = 'OKTA Token Issuer is not a valid URL'
        }
        if (!appActivation.appActivationOktaPropertiesApi.permissionsLocation) {
            permissionsLocation = 'Permissions Location is required'
        }
        if (!appActivation.appActivationOktaPropertiesApi.permissionName) {
            permissionName = 'Permission Name is required'
        }
    }

    const dataValidation: TDataValidation = {
        appActivation: {
            gatewayId: appActivation.gatewayId ? '' : 'Required field',
            interopioContext: interopioContextError,
            name: dataValidationName,
            allscriptsUnityAppName,
            clientId,
            clientSecret,
            clientAuthType,
            tenantsPracticeId,
            tenantsPracticeName,
            publicPrivateKeyPairGenerated,
            oktaTokenIssuer,
            permissionsLocation,
            permissionName,
        },
    }

    const isAppActivationDataValid = Object.values(dataValidation.appActivation || {})
        .reduce((acc, val) => acc && !val, true)

    // init
    React.useEffect(() => {
        if (status === ':INIT:' && isInitRef.current) {
            setEdit(!props.selectedAppActivation)

            setAppActivation((prevState) => ({
                ...prevState,
                ...(props.selectedAppActivation || {
                    allscriptsUnityLaunchProxyInfoApi: null,
                    appId: props.apps.selected?.data?.appId,
                    externalAuth: null,
                    interopioContext: null,
                    smartLaunchProxyInfo: null,
                    smartLaunchProxyInfo2Api: null,
                }),
            }))

            if (props.selectedAppActivation) {
                if (props.selectedAppActivation.smartLaunchProxyInfo2Api
                    && Object.keys(props.selectedAppActivation.smartLaunchProxyInfo2Api).length) {
                    setAuthMode(':SMART_2:')

                    if (props.selectedAppActivation.smartLaunchProxyInfo2Api?.ehrAuthenticationType
                        && props.selectedAppActivation.smartLaunchProxyInfo2Api.ehrAuthenticationType.toUpperCase().includes("PROXY")) {
                        setEhrAuthType(':PROXY:')
                    }
                    else {
                        setEhrAuthType(':SYSTEM:')
                    }

                    if (props.selectedAppActivation.smartLaunchProxyInfo2Api?.ehrAuthenticationMethod
                        && props.selectedAppActivation.smartLaunchProxyInfo2Api?.ehrAuthenticationMethod.toUpperCase().includes("CONFIDENTIAL")) {
                        // There should only be a client authentication type saved for Confidential activations, not public
                        setProxyAuthType(':CONFIDENTIAL:')
                    }
                    else {
                        setProxyAuthType(':PUBLIC:')
                    }
                }
                else {
                    setAuthMode(':SMART_1:')

                    if (props.selectedAppActivation.allscriptsUnityLaunchProxyInfoApi) {
                        setEhrAuthType(':PROXY:')
                        setProxyAuthType(':PUBLIC:')
                        setSystemAuthType(':BACKEND:')
                    }
                    else if (props.selectedAppActivation.externalAuth) {
                        setEhrAuthType(':SYSTEM:')
                        setProxyAuthType(':PUBLIC:')
                        setSystemAuthType(props.selectedAppActivation.externalAuth.authType === 'BACKEND' ? ':BACKEND:' : props.selectedAppActivation.externalAuth.authType === 'EXTERNAL' ? ':EXTERNAL:' : ':OTHER:')
                    }
                    else {
                        setEhrAuthType(':PROXY:')
                        setProxyAuthType(props.selectedAppActivation.smartLaunchProxyInfo?.clientSecret ? ':CONFIDENTIAL:' : ':PUBLIC:')
                        setSystemAuthType(':BACKEND:')
                    }
                }
            }
            else {
                setAuthMode(':SMART_1:')
                setEhrAuthType(':PROXY:')
                setProxyAuthType(':PUBLIC:')
                setSystemAuthType(':BACKEND:')
            }

            setStatus(':READY:')
        }
    }, [
        props.apps.selected?.data?.appId,
        props.selectedAppActivation,
        status,
    ])

    // Update "proxyAuthType"
    // Update "systemAuthType"
    React.useEffect(() => {
        if (!isInitRef.current) {
            setProxyAuthType(':PUBLIC:')
            setSystemAuthType(':BACKEND:')
        }
    }, [ehrAuthType])

    // Update "proxyAuthType"
    // Update "systemAuthType"
    // Update "ehrAuthType"
    React.useEffect(() => {
        if (!isInitRef.current) {
            setEhrAuthType(':PROXY:')
            setProxyAuthType(':PUBLIC:')
            setSystemAuthType(':BACKEND:')
        }
    }, [appActivation.gatewayId])

    // Update "authMode"
    // Update "disableAuthMode"
    React.useEffect(() => {
        if(status === ':READY:') {
            if (selectedGatewayId && (isEpicR4Gateway || isVarianR4Gateway)) {
                const selectedApp = props.apps.selected.data || {}
                const activations = selectedApp.activations || []

                if (props.selectedAppActivation?.gatewayId && selectedGatewayId === props.selectedAppActivation?.gatewayId) {
                    let existingSecondGtwActivation = activations.filter((act) => (act.gatewayId === selectedGatewayId)).length > 1
                    if (existingSecondGtwActivation) {
                        if (props.selectedAppActivation?.smartLaunchProxyInfo2Api
                            && Object.keys(props.selectedAppActivation?.smartLaunchProxyInfo2Api).length) {
                            setAuthMode(':SMART_2:')
                            setDisableAuthMode(true)
                        } else {
                            setAuthMode(':SMART_1:')
                            setDisableAuthMode(true)
                        }
                    }
                } else {
                    let existingGtwActivation = activations.find((act) => (act.gatewayId === selectedGatewayId))
                    if (existingGtwActivation) {
                        if (existingGtwActivation.smartLaunchProxyInfo2Api
                            && Object.keys(existingGtwActivation.smartLaunchProxyInfo2Api).length) {
                            setAuthMode(':SMART_1:')
                            setDisableAuthMode(true)
                        } else {
                            setAuthMode(':SMART_2:')
                            setDisableAuthMode(true)
                        }
                    }
                }
            } else {
                setAuthMode(':SMART_1:')
                setDisableAuthMode(false)
            }
        }
    }, [
        status,
        isEpicR4Gateway,
        isVarianR4Gateway,
        selectedGatewayId,
        props.apps.selected.data,
        props.selectedAppActivation?.gatewayId,
        props.selectedAppActivation?.smartLaunchProxyInfo2Api
    ])

    // Update "appActivation.allscriptsUnityLaunchProxyInfoApi"
    // Update "appActivation.externalAuth"
    // Update "appActivation.smartLaunchProxyInfo"
    // Update "appActivation.smartLaunchProxyInfo2Api"
    React.useEffect(() => {
        if (!isInitRef.current) {
            let allscriptsUnityLaunchProxyInfoApi: TAppActivation['allscriptsUnityLaunchProxyInfoApi'] = null
            let externalAuth: TAppActivation['externalAuth'] = null
            let smartLaunchProxyInfo2Api: TAppActivation['smartLaunchProxyInfo2Api'] = null
            let smartLaunchProxyInfo: TAppActivation['smartLaunchProxyInfo'] = null

            if (authMode === ':SMART_2:') {
                allscriptsUnityLaunchProxyInfoApi = null

                if (props.selectedAppActivation?.smartLaunchProxyInfo2Api) {
                    smartLaunchProxyInfo2Api = props.selectedAppActivation.smartLaunchProxyInfo2Api
                }
                else {
                    smartLaunchProxyInfo2Api = {
                        accountId,
                        environmentId: env,
                        appId: props.apps.selected?.data?.appId,
                        gatewayId: appActivation.gatewayId,
                        dataSourceId: selectedDataSourceId,
                    }
                }

            }
            // SMART 1.0 behavior
            else {
                if (props.selectedAppActivation?.smartLaunchProxyInfo) {
                    smartLaunchProxyInfo = { ...props.selectedAppActivation.smartLaunchProxyInfo }
                }
                else {
                    smartLaunchProxyInfo = {
                        scopes: props.apps.selected?.data?.scopes,
                        clientSecret: null,
                    }
                }
                smartLaunchProxyInfo.appAuthTypeEnum =
                    proxyAuthType === ':CONFIDENTIAL:' ? 'SMART_ON_FHIR_CONFIDENTIAL'
                        : proxyAuthType === ':PUBLIC:' ? 'SMART_ON_FHIR_PUBLIC' : null
                smartLaunchProxyInfo.clientSecret = proxyAuthType === ':CONFIDENTIAL:' ? smartLaunchProxyInfo.clientSecret : null

                if (isAllscriptsUnityGateway) {
                    allscriptsUnityLaunchProxyInfoApi = props.selectedAppActivation?.allscriptsUnityLaunchProxyInfoApi || {}
                    externalAuth = null
                }
                else if (isLaunchProxyRequired
                    && !isAllscriptsUnityGateway
                    && ehrAuthType === ':PROXY:'
                ) {
                    allscriptsUnityLaunchProxyInfoApi = null
                    externalAuth = null
                }
                else if (
                    (isCernerR4Gateway || isEpicR4Gateway)
                    && !isAllscriptsUnityGateway
                    && ehrAuthType === ':SYSTEM:'
                ) {
                    allscriptsUnityLaunchProxyInfoApi = null
                    if (props.selectedAppActivation?.externalAuth) {
                        externalAuth = props.selectedAppActivation.externalAuth
                    }
                    else {
                        externalAuth = {
                            accountId,
                            environmentId: env,
                            appId: props.apps.selected?.data?.appId,
                            gatewayId: appActivation.gatewayId,
                            dataSourceId: selectedDataSourceId,
                        }
                    }
                    externalAuth.authType = systemAuthType === ':BACKEND:' ? 'BACKEND' : systemAuthType === ':EXTERNAL:' ? 'EXTERNAL' : 'OTHER'
                    smartLaunchProxyInfo = null
                }
            }

            setAppActivation((prevState) => ({
                ...prevState,
                allscriptsUnityLaunchProxyInfoApi,
                externalAuth,
                smartLaunchProxyInfo2Api,
                interopioContext: prevState.interopioContext || null,
                smartLaunchProxyInfo,
            }))
        }
    }, [
        accountId,
        appActivation.gatewayId,
        authMode,
        ehrAuthType,
        env,
        isAllscriptsUnityGateway,
        isCernerR4Gateway,
        isEpicR4Gateway,
        isEpicR4ExternalGateway,
        isLaunchProxyRequired,
        isVarianR4Gateway,
        props.apps.selected?.data?.appId,
        props.apps.selected?.data?.scopes,
        props.selectedAppActivation?.allscriptsUnityLaunchProxyInfoApi,
        props.selectedAppActivation?.externalAuth,
        props.selectedAppActivation?.smartLaunchProxyInfo,
        props.selectedAppActivation?.smartLaunchProxyInfo2Api,
        props.selectedAppActivation?.smartLaunchProxyInfo2Api?.clientAuthenticationMethod,
        proxyAuthType,
        selectedDataSourceId,
        systemAuthType,
    ])

    // post init
    React.useEffect(() => {
        if (status === ':READY:' && isInitRef.current) {
            isInitRef.current = false
        }
    }, [status])

    const calcNextStep = () => {
        if (step < STEP_MAX) setStep((prevState) => prevState + 1)
        else setStep(STEP_MAX)
    }

    const calcPrevStep = () => {
        if (step > STEP_MIN) setStep((prevState) => prevState - 1)
        else setStep(STEP_MIN)
    }

    return (
        <Dialog
            open
            disableBackdropClick
            disableEscapeKeyDown
            scroll="body"
            onClose={props.onClose}
        >
            <CustomDialogTitle
                canEdit={canEdit}
                isSelectedAppActivationAvailable={Boolean(props.selectedAppActivation)}
                onClose={props.onClose}
            />
            <CustomDialogContent
                actAsAdmin={actAsAdmin}
                appActivation={appActivation}
                authMode={authMode}
                canEdit={canEdit}
                dataValidation={dataValidation}
                disableAuthMode={disableAuthMode}
                ehrAuthType={ehrAuthType}
                estatus={status}
                estep={step}
                gateways={filteredGateways}
                isAllscriptsUnityGateway={isAllscriptsUnityGateway}
                isAppLaunchingWithinIKMG2={isAppLaunchingWithinIKMG2}
                isCernerR4Gateway={isCernerR4Gateway}
                isEpicR4Gateway={isEpicR4Gateway}
                isEpicR4ExternalGateway={isEpicR4ExternalGateway}
                isExtAuthDa={isExtAuthDa}
                isExtAuthGtw={isExtAuthGtw}
                isIKnowMedExtGateway={isIKnowMedExtGateway}
                isLaunchProxyRequired={isLaunchProxyRequired}
                isVarianR4Gateway={isVarianR4Gateway}
                muiTheme={props.muiTheme}
                proxyAuthType={proxyAuthType}
                selectedGatewayId={selectedGatewayId}
                systemAuthType={systemAuthType}
                setAppActivation={setAppActivation}
                setAuthMode={setAuthMode}
                setEdit={setEdit}
                setEhrAuthType={setEhrAuthType}
                setEStatus={setStatus}
                setIsAppLaunchingWithinIKMG2={setIsAppLaunchingWithinIKMG2}
                setProxyAuthType={setProxyAuthType}
                setSystemAuthType={setSystemAuthType}

            />
            <CustomDialogActions
                appActivation={appActivation}
                authMode={authMode}
                canEdit={canEdit}
                ehrAuthType={ehrAuthType}
                estatus={status}
                estep={step}
                ESTEP_MAX={STEP_MAX}
                ESTEP_MIN={STEP_MIN}
                isAppActivationDataValid={isAppActivationDataValid}
                isAppLaunchingWithinIKMG2={isAppLaunchingWithinIKMG2}
                parentState={props.parentState}
                proxyAuthType={proxyAuthType}
                selectedAppActivation={props.selectedAppActivation}
                calcNextWStep={calcNextStep}
                calcPrevWStep={calcPrevStep}
                onCancel={props.onClose}
                onClose={props.onClose}
                setAuthMode={setAuthMode}
                setEStatus={setStatus}
            />
        </Dialog>
    )
}

const mapStateToProps = (state, ownProps) => ({
    apps: state.apps,
    config: state.config,
    dataSources: state.dataSources,
    gateways: state.gateways,
    login: state.login,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(ActivationEditor))
