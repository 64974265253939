import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import getGatewaysCallUrl from "../../../../../../../lib/getGatewaysCallUrl";
import getHostnameInfo from "../../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../../lib/getLocationInfo";
import {getPath} from "../../../../../../../lib/utils/";
import {Button} from "@material-ui/core";

const Component: React.FunctionComponent<any> = props => {
    const content = [
        <Button variant="outlined" key="cancel" style={{marginRight: "8px"}} onClick={() => props.onSetState({dialog: ""})}>
            Cancel
        </Button>,
        <Button variant="contained" key="confirm" color="primary"
            onClick={() => {
                const {accountId} = getHostnameInfo();
                const {env, gatewayId} = getLocationInfo(props.location, props.config);
                const selectedResources = getPath(props, "state.selectedResources") || {};
                const overrides = (getPath(props.state, "data.configuration.overrides") || []).filter(it => !selectedResources[it.resource]);
                const defaultSourceId = getPath(props.state, "data.configuration.defaultDataSource");

                if (props.selectedSource && props.state.selectedDataSourceType !== "DataSourceIKnowMedR4External") {
                    Object.keys(selectedResources).forEach(resource => {
                        if (selectedResources[resource]) {
                            ["read", "search", "search-type", "search-system", "create", "update", "delete"]
                                .forEach((operation) => {
                                    overrides.push({resource, operation, datasource: props.selectedSource});
                                });
                        }
                    });
                } else if (props.state.selectedDataSourceType === "DataSourceIKnowMedR4External" && props.selectedSources && Object.values(props.selectedSources).some(x => x !== null && x !== '')) {
                    Object.keys(selectedResources).forEach(resource => {
                        if (selectedResources[resource]) {
                            ["read", "search", "search-type", "search-system", "create", "update", "delete"]
                                .forEach((operation) => {
                                    overrides.push({resource, operation, datasource: props.selectedSources[operation] || defaultSourceId});
                                });
                        }
                    });
                }

                const data = $.extend(true, {configuration: {overrides: []}}, props.state.data);
                data.configuration.overrides = overrides;

                props.onSetState(
                    {
                        status: "updating",
                        dialog: "",
                        data
                    },
                    () => {
                        const url = getGatewaysCallUrl(accountId, env) + "/" + gatewayId;
                        props.gatewaysUpdate(props.config, url, data)
                            .then(props.initialize);
                    }
                );
            }}>
            Save
        </Button>
    ];

    return <div>{content}</div>;
}

Component.propTypes = {
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    gatewaysUpdate: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired,
    selectedSource: PropTypes.string.isRequired,
    selectedSources: PropTypes.object.isRequired
};

Component.defaultProps = {
    selectedSources: {}
};

export default Component;
