import * as PropTypes from "prop-types";
import { connect } from "react-redux";

import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import Tooltip from "@material-ui/core/Tooltip";

import CenteredCircularProgress from "../../../../../../Widgets/CenteredCircularProgress/";
import Header from "../../../../../../Widgets/Editor/Header/";

import ETabs from "./ETabs";

const CustomDialogContent = (props) => {
    const canEdit = props.estate.mode === ":EDIT:";
    const appUserId = props.appUsers?.selected?.data?.uid || "";

    return <DialogContent style={{ minWidth: 552, padding: 0, background: "rgb(250, 250, 250)" }}>
        <Header {...props} style={{ height: 56 }}
            leftIcon={<FilterNoneIcon />}
            primaryText={<div><strong>UID: </strong>{appUserId}</div>}
            rightIcon={!canEdit
                ? <IconButton style={{ paddingTop: 16 }} onClick={() => props.onSetEState({ mode: ":EDIT:" })}>
                    <Tooltip title="Edit"><EditIcon /></Tooltip>
                </IconButton>
                : null}
        />
        {["ready", "waiting"].includes(props.estate.status)
            ? <ETabs
                estate={props.estate}
                onSetEState={props.onSetEState}
            />
            : <CenteredCircularProgress size={63} style={{ minWidth: 552, marginTop: 32 }} />}
    </DialogContent>
}

CustomDialogContent.propTypes = {
    appUsers: PropTypes.object.isRequired,
    estate: PropTypes.object.isRequired,
    onSetEState: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    appUsers: state.appUsers,
    ...ownProps,
});
export default connect(mapStateToProps)(CustomDialogContent);
