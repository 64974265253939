import * as PropTypes from "prop-types";
import EditMode from "./EditMode";
import ViewMode from "./ViewMode";

const AppUserToFhirUser = (props) => {
    const propSet = {
        parentState: props.parentState,
        showTitle: props.showTitle,
        onSetParentState: props.onSetParentState,
    }

    return props.canEdit
        ? <EditMode {...propSet} />
        : <ViewMode {...propSet} />;
}

AppUserToFhirUser.propTypes = {
    parentState: PropTypes.object.isRequired,
    onSetParentState: PropTypes.func.isRequired,
    canEdit: PropTypes.bool,
    showTitle: PropTypes.bool,
}

AppUserToFhirUser.defaultProps = {
    canEdit: false,
    showTitle: true,
};

export default AppUserToFhirUser;
