import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {isEnvironmentAdmin} from "../../../../../lib/user-environment-permissions-helpers";
import * as validateData from "../../../../../lib/validate-environments-data";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Header from "../../../../Widgets/Editor/Header/";
import ActionButtons from "./ActionButtons";

class Details extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        parentState: PropTypes.object.isRequired,
        initialize: PropTypes.func.isRequired,
        onSetParentState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.validateData();
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.parentState.data) !== JSON.stringify(prevProps.parentState.data)) {
            this.validateData();
        }
    }

    public render() {
        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);
        const canEdit = this.props.parentState.mode === ":EDIT:";

        return <Card style={{ marginTop: 48 }}>
            <Header {...this.props} style={{ height: 56 }}
                rightIcon={(!canEdit && actAsAdmin)
                    ? <IconButton data-qa="da-details-edit-button" style={{ top: 4 }} onClick={() => this.props.onSetParentState({ mode: ":EDIT:" })}>
                        <Tooltip title="Edit"><EditIcon /></Tooltip>
                    </IconButton>
                    : null}
            />
            <div style={{ padding: "0 24px 24px 24px" }}>
                {this.renderName(canEdit)}
                {this.renderEnvironmentId()}
                {this.renderDescription(canEdit)}
                {this.renderPHI()}
            </div>
            <ActionButtons parentState={this.props.parentState} initialize={this.props.initialize} onSetParentState={this.props.onSetParentState} />
        </Card>;
    }

    private renderName = (canEdit) => {
        return <TextField
            name="name" value={this.props.parentState.data.name || ""}
            placeholder="Display name" label="Display Name" helperText={this.props.parentState.dataValidation.name}
            error={!!this.props.parentState.dataValidation.name} disabled={!canEdit}
            autoComplete="off" autoFocus={false} fullWidth
            classes={{ root: canEdit ? "" : "disabled-text-field" }}
            style={{ marginTop: 16 }}
            onChange={canEdit ? (event) => this.props.onSetParentState((prevParentState) => ({ data: { ...prevParentState.data, name: event.target.value } })) : undefined}
        />
    }

    private renderEnvironmentId = () => {
        return <TextField
            name="environmentId" value={this.props.parentState.data.environmentId || ""}
            placeholder="Environment ID" label="Environment ID" helperText={this.props.parentState.dataValidation.environmentId}
            error={!!this.props.parentState.dataValidation.environmentId}
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />
    }

    private renderDescription = (canEdit) => {
        return <TextField
            name="description" value={this.props.parentState.data.description || ""}
            placeholder="Description" label="Description" disabled={!canEdit}
            autoComplete="off" autoFocus={false} fullWidth multiline
            classes={{ root: canEdit ? "" : "disabled-text-field" }}
            style={{ marginTop: 16 }}
            onChange={canEdit ? (event) => this.props.onSetParentState((prevParentState) => ({ data: { ...prevParentState.data, description: event.target.value } })) : undefined}
        />
    }

    private renderPHI = () => {
        return <TextField
            name="phi" value={this.props.parentState.data.phi ? "Yes" : "No"}
            label="Will this environment be used with PHI?" helperText={this.props.parentState.dataValidation.name}
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />
    }

    private validateData = () => {
        const dataValidation = validateData.onEditDetails(this.props.parentState);
        this.props.onSetParentState({dataValidation});
    }
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    login: state.login,
    ...ownProps,
});
export default withRouter(connect(mapStateToProps)(Details));
