import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../redux/actions";
import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../lib/getLocationInfo";
import getCdsServiceSetsCallUrl from "../../../../../../lib/getCdsServiceSetsCallUrl";
import getGatewaysCallUrl from "../../../../../../lib/getGatewaysCallUrl";
import * as validateData from "../../../../../../lib/validate-cds-service-set-activations-data";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogActions from "./CustomDialogActions";
import CustomDialogContent from "./CustomDialogContent";
import CustomDialogTitle from "./CustomDialogTitle";

const ETAB_DEFAULT = 1;

const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "waiting"
    data: {},
    dataValidation: {},
    etab: null,
    mode: ":VIEW",
};

class ActivationEdit extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        parentState: PropTypes.object.isRequired,
        cdsServiceSetsRead: PropTypes.func.isRequired,
        gatewaysReadAll: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.initialize();
    }

    public componentDidUpdate(_, prevState) {
        const data_string = JSON.stringify(this.state.data);
        const prevData_string = JSON.stringify(prevState.data);
        if (data_string !== prevData_string) {
            this.validateData();
        }
    }

    public render() {
        const isValid = this.isDataValid();

        return <Dialog
            open disableBackdropClick disableEscapeKeyDown scroll = "body"
            onClose={this.props.onClose}
        >
            <CustomDialogTitle onClose={this.props.onClose} />
            <CustomDialogContent
                estate={this.state}
                onSetEState={this.onSetState}
            />
            <CustomDialogActions
                estate={this.state}
                isValid={isValid}
                parentState={this.props.parentState}
                initialize={this.props.initialize}
                onClose={this.props.onClose}
                onSetEState={this.onSetState}
            />
        </Dialog >
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private isDataValid = () => {
        const dataValidation = {
            ...this.state.dataValidation,
            overrideIds: undefined
        };
        const isValid = !Object.keys(dataValidation)
            .map((key) => dataValidation[key])
            .some((value) => !!value);
        const overrideIds = this.state.dataValidation?.overrideIds || {};
        const areOverrideIdsValid = !Object.keys(overrideIds)
            .map((key) => overrideIds[key])
            .some((value) => !!value);
        return isValid && areOverrideIdsValid;
    }

    private validateData = () => {
        const dataValidation = validateData.onEdit(this.state, this.props.config);
        this.setState({dataValidation});
    }

    private initialize = async() => {
        this.setState({status: "init"});
        const { accountId } = getHostnameInfo();
        const { env, cdsServiceSetId } = getLocationInfo(this.props.location, this.props.config);

        const cdsServiceSetUrl = getCdsServiceSetsCallUrl(accountId, env) + "/" + cdsServiceSetId;
        const gatewaysUrl = getGatewaysCallUrl(accountId, env);

        await Promise.all([
            this.props.cdsServiceSetsRead(this.props.config, cdsServiceSetUrl),
            this.props.gatewaysReadAll(this.props.config, gatewaysUrl)
        ]);

        this.setState(prevState => ({
            ...INIT_STATE,
            status: "ready",
            data: this.props.parentState.selectedActivation,
            etab: prevState.etab !== null ? prevState.etab : ETAB_DEFAULT
        }),
        this.validateData,
        );
    };
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsRead: actions.cdsServiceSetsRead,
    gatewaysReadAll: actions.getGatewayJwkSet,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivationEdit));
