const isUrl: Function = require("is-url");
import forge from 'node-forge';
import consoleLogger from './consoleLogger';

export function onEdit (state) {
    let entryPoint = "";
    let issuer = "";
    let cert = "";
    if (state.ssoData.enabled) {
        entryPoint = state.ssoData.entryPoint ? "" : "Required field";
        if (!entryPoint) {
            entryPoint = isUrl(state.ssoData.entryPoint) ? "" : "Entrypoint is not valid URI";
        }
        issuer = state.ssoData.issuer ? "" : "Required field";
        cert = state.ssoData.cert ? "" : "Required field";
        if (!cert) {
            try {
                let pemString = state.ssoData.cert;
                if (!pemString.startsWith('-----BEGIN CERTIFICATE-----')) {
                    pemString = '-----BEGIN CERTIFICATE-----\n' + pemString;
                }
                if (!pemString.endsWith('-----END CERTIFICATE-----')) {
                    pemString = pemString + '\n-----END CERTIFICATE-----';
                }
                const certificate = forge.pki.certificateFromPem(pemString);
                // Check if certificate object has necessary properties
                cert !== null && certificate.publicKey !== null && certificate.subject !== null ? "" : "Invalid certificate format. You can try removing the BEGIN and END lines";
            } catch (error) {
                consoleLogger.log(error)
                cert = "Invalid certificate format. You can try removing the BEGIN and END lines";
            }
        }
    }

    return { entryPoint, issuer, cert };
}
