import * as React from "react";
import * as PropTypes from "prop-types";
import axiosWrapper from "../../../../../../lib/axiosWrapper";
import getMessageFromResponse from "../../../../../../lib/getMessageFromResponse";
import consoleLogger from "../../../../../../lib/consoleLogger";
import {Button} from "@material-ui/core";
import {Check} from "@material-ui/icons";

const Component: React.FunctionComponent<any> = props => {
    const content = [
        props.state.changeEmailStatus !== ":EMAIL_CHANGE_CONFIRMED:"
            ? <Button variant="outlined" key="action-btn-cancel" disabled={props.state.status !== "ready"} style={{marginRight: "8px"}} onClick={props.onCancel}>
                Cancel
            </Button>
            : null,
        props.state.changeEmailStatus !== ":EMAIL_CHANGE_CONFIRMED:"
            ? <Button variant="contained" key="action-btn-change-email-confirm" color="primary" disabled={props.state.status !== "ready" || !!props.state.changeEmailStatus}
                onClick={() => {
                    props.onSetState(
                        {status: "wait"},
                        () => {
                            const data = {email: props.state.data.email}
                            axiosWrapper(props.config.accountApi, "users/current/change-email", "POST", data)
                                .then(props.onClose)
                                .catch(reason => {
                                    props.onSetState({
                                        status: "ready",
                                        changeEmailStatus: getMessageFromResponse(reason, true)
                                    });
                                    consoleLogger.log("::: reason:", reason);
                                });
                        }
                    );
                }}>
                <Check/> Change Email
            </Button>
            : null,
        props.state.changeEmailStatus === ":EMAIL_CHANGE_CONFIRMED:"
            ? <Button variant="contained" key="action-btn-close" color="primary" onClick={props.onClose}>
                Close
            </Button>
            : null];

    return <div>{content}</div>;
}

Component.propTypes = {
    config: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
