export default function (types: Array<string> = []) {
    const res = {
        sof: false,
        pub: false,
        conf: false,
        bes: false,
        bulk: false,
        error: false,
    };


    if (types.includes("SMART_ON_FHIR_PUBLIC")) {
        res.pub = true;
        res.sof = true;
    }
    if (types.includes("SMART_ON_FHIR_CONFIDENTIAL")) {
        res.conf = true;
        res.sof = true;
    }
    if (types.includes("BACKEND_SERVICE")) {
        res.bes = true;
    }
    if (types.includes("MULTI_PATIENT_ACCESS")) {
        res.bulk = true;
    }

    if (res.pub && (res.conf || res.bes)) {
        res.error = true;
    }

    return res;
}
