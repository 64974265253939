import * as PropTypes from "prop-types";
import { connect } from "react-redux";

import withLegacyTheme from "../../../../../../../../lib/hoc/with-legacy-theme";
import consoleLogger from "../../../../../../../../lib/consoleLogger";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import CustomTitle from "../Shared/CustomTitle";

const EditMode = (props) => {
    const identifierMappings = props.parentState?.data?.identifierMappings || [];
    const cdrs = props.cdrs?.all?.data || []
    const filteredCdrs = cdrs.filter((cdr) => !identifierMappings.some((id) => id.cdrSourceId === cdr.cdrId));
    const dataSources = props.dataSources?.all?.data || []
    const filteredDataSources = dataSources.filter((ds) => !identifierMappings.some((id) => id.dataSourceId === ds.dataSourceId));
    const filteredSources = [...filteredCdrs, ...filteredDataSources]
    consoleLogger.log('showTitle', props.showTitle)

    return <>
        {props.showTitle
            ? <CustomTitle identifierMappings={identifierMappings} />
            : null
        }

        {filteredSources.length
            ? <div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <FormControl
                        disabled={props.parentState.status !== "ready"}
                        error={!!props.parentState.dataValidation.__temp__ds}
                        style={{ minWidth: "calc(50% - 100px)", marginRight: "16px" }}
                    >
                        <InputLabel id="user-data-source-select-label">
                            Data Source
                        </InputLabel>
                        <Select data-qa="user-data-source"
                            labelId="user-data-source-select-label"
                            id="user-data-source-select"
                            value={props.parentState.data.__temp__ds || ""}
                            onChange={(event) => props.onSetParentState((prevParentState) => ({
                                data: {
                                    ...prevParentState.data,
                                    __temp__ds: event.target.value,
                                },
                            }))}
                        >
                            {filteredSources.map((src) => (
                                <MenuItem
                                    data-qa={`user-data-source-${src.dataSourceId || "cdr:" + src.cdrId}`}
                                    key={src.dataSourceId || "cdr:" + src.cdrId}
                                    value={src.dataSourceId || "cdr:" + src.cdrId}
                                >
                                    {src.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                            {props.parentState.dataValidation.__temp__ds}
                        </FormHelperText>
                    </FormControl>

                    <div style={{ display: "flex", alignItems: "baseline", width: "100%" }}>
                        <TextField data-qa="user-fhir-id"
                            name="fhirId" value={props.parentState.data.__temp__fhirId || ""}
                            placeholder="FHIR ID" label="FHIR ID"
                            disabled={!props.parentState.data.__temp__ds || props.parentState.status !== "ready"}
                            autoComplete="off" autoFocus={false} fullWidth
                            style={{ marginTop: 16 }}
                            onChange={(event) => props.onSetParentState(prevParentState => ({
                                data: {
                                    ...prevParentState.data,
                                    __temp__fhirId: event.target.value,
                                },
                            }))}
                        />
                        <IconButton data-qa="add-fhir-id-action"
                            disabled={!props.parentState.data.__temp__ds || !props.parentState.data.__temp__fhirId}
                            color="primary"
                            onClick={() => {
                                const identifierMappings = props.parentState.data.identifierMappings || [];
                                const newIdentifierMappings = [
                                    ...identifierMappings,
                                    {
                                        cdrSourceId: props.parentState.data.__temp__ds.startsWith("cdr:") ? props.parentState.data.__temp__ds.slice(4) : "",
                                        dataSourceId: !props.parentState.data.__temp__ds.startsWith("cdr:") ? props.parentState.data.__temp__ds : "",
                                        fhirResourceLogicalId: props.parentState.data.__temp__fhirId
                                    }
                                ];
                                props.onSetParentState((prevParentState) => ({
                                    data: {
                                        ...prevParentState.data,
                                        identifierMappings: newIdentifierMappings,
                                        __temp__ds: "",
                                        __temp__fhirId: ""
                                    }
                                }));
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            : <div style={{ display: "flex", alignItems: "flex-end", marginTop: "10px" }}>
                <div style={{ marginRight: "16px" }}>
                    <small style={{ color: props.muiTheme.palette.accent3Color }}>
                        No available Data Sources to create a link to.
                    </small>
                </div>
            </div>
        }

        <InputLabel style={{ marginTop: "16px" }} id="user-to-fhir-list-label">
            FHIR Links
        </InputLabel>
        <List style={{ marginBottom: "16px" }}>
            {identifierMappings.length ? identifierMappings.map((item, index) => {
                const currentCdr = cdrs.find((cdr) => cdr.cdrId === item.cdrSourceId);
                const currentDataSource = dataSources.find((ds) => ds.dataSourceId === item.dataSourceId);
                const currentSource = currentCdr || currentDataSource || {};
                const currentSourceName = currentSource.name || "";

                return <ListItem data-qa={`ds-user-${item.fhirResourceLogicalId}`}
                    key={`${item.fhirResourceLogicalId}-${index}`}
                    style={{ background: index % 2 ? "rgb(235, 235, 235)" : "rgb(245, 245, 245)" }}
                >
                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <div style={{ width: "calc(50% - 100px)", marginRight: "16px" }}>
                            {currentSourceName}
                            <br />
                            <small style={{ color: props.muiTheme.palette.accent3Color }}>
                                {item.dataSourceId || item.cdrSourceId || ""}
                            </small>
                        </div>
                        <div>
                            {`/${props.parentState.data.appUserType !== "PATIENT" ? "Practitioner" : "Patient"}/${item.fhirResourceLogicalId}`}
                        </div>
                    </div>
                    <IconButton data-qa={`delete-user-${item.fhirResourceLogicalId}`}
                        color="secondary"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            const identifierMappings = props.parentState.data.identifierMappings || [];
                            let newIdentifierMappings = [...identifierMappings];
                            newIdentifierMappings.splice(index, 1);
                            props.onSetParentState((prevParentState) => ({
                                data: {
                                    ...prevParentState.data,
                                    identifierMappings: newIdentifierMappings
                                },
                            }));
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </ListItem>
            }) : <ListItem data-qa="no-user-to-fhir-links"
                style={{ background: "rgb(235, 235, 235)"}}
            >
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ width: "calc(50% - 100px)", marginRight: "16px" }}>
                        <small style={{ color: props.muiTheme.palette.accent3Color }}>
                            No current FHIR Links.
                        </small>
                    </div>
                </div>
            </ListItem>}
        </List>
    </>;
};

EditMode.propTypes = {
    cdrs: PropTypes.object.isRequired,
    dataSources: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    showTitle: PropTypes.bool.isRequired,
    parentState: PropTypes.object.isRequired,

    onSetParentState: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    cdrs: state.cdrs,
    dataSources: state.dataSources,
    ...ownProps,
});
export default connect(mapStateToProps)(withLegacyTheme()(EditMode));
