import * as types from "./types";

import initialState from "../../redux/reducers/accounts/initial-state";

import axiosWrapper from "../../lib/axiosWrapper";
import {getPath} from "../../lib/utils/";
import getHostnameInfo from "../../lib/getHostnameInfo";
import consoleLogger from "../../lib/consoleLogger";

export function accountsAccountUsersRead(config) {
    return function (dispatch) {
        dispatch({
            type: types.ACCOUNTS_ACCOUNT_USERS_SET,
            payload: {
                ...initialState.accountUsers.data,
                status: "pending"
            }
        });
        return axiosWrapper(config.accountApi, "accounts/current/users", "GET")
            .then((res) => {
                dispatch({
                    type: types.ACCOUNTS_ACCOUNT_USERS_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data")
                    }
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.ACCOUNTS_ACCOUNT_USERS_SET,
                    payload: {
                        ...initialState.accountUsers.data,
                        status: "error"
                    }
                });
                consoleLogger.error(":::", reason);
            });
    };
}

export function reportPhiAccess(config, envId) {
    return function () {
        const {accountId} = getHostnameInfo();
        return axiosWrapper(config.envApi, `api/${accountId}/env/${envId}/audit`, "POST");
    };
}

export function accountsCurrentRead(config) {
    return function (dispatch) {
        dispatch({
            type: types.ACCOUNTS_SELECTED_SET,
            payload: {
                ...initialState.selected.data,
                status: "pending"
            }
        });
        return axiosWrapper(config.accountApi, "accounts/current", "GET")
            .then((res) => {
                dispatch({
                    type: types.ACCOUNTS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data")
                    }
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.ACCOUNTS_SELECTED_SET,
                    payload: {
                        ...initialState.selected.data,
                        status: "error"
                    }
                });
                consoleLogger.error(":::", reason);
            });
    };
}

export function accountsDeleteLogs(config, accountId) {
    return function () {
        return axiosWrapper(config.envApi, `api/${accountId}/request-logging-session`, "DELETE")
            .then(res => res)
            .catch(reason => consoleLogger.error(":::", reason));
    };
}

export function accountsCurrentUpdate(config, data = {}) {
    return function (dispatch) {
        dispatch({
            type: types.ACCOUNTS_SELECTED_SET,
            payload: {
                ...initialState.selected.data,
                status: "pending"
            }
        });
        return axiosWrapper(config.accountApi, "accounts/current", "PUT", data)
            .then((res) => {
                dispatch({
                    type: types.ACCOUNTS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data")
                    }
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.ACCOUNTS_SELECTED_SET,
                    payload: {
                        ...initialState.selected.data,
                        status: "error"
                    }
                });
                consoleLogger.error(":::", reason);
            });
    };
}

export function accountsSsoConfigRead(config) {
    return function (dispatch) {
        dispatch({
            type: types.ACCOUNTS_SSO_CONFIG_SET,
            payload: {
                ...initialState.selected.data,
                status: "pending"
            }
        });
        return axiosWrapper(config.accountApi, "accounts/current/sso-config", "GET")
            .then((res) => {
                dispatch({
                    type: types.ACCOUNTS_SSO_CONFIG_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data")
                    }
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.ACCOUNTS_SSO_CONFIG_SET,
                    payload: {
                        ...initialState.selected.data,
                        status: "error"
                    }
                });
                consoleLogger.error(":::", reason);
            });
    };
}

export function accountsSsoConfigUpdate(config, data = {}) {
    return function (dispatch) {
        dispatch({
            type: types.ACCOUNTS_SSO_CONFIG_SET,
            payload: {
                ...initialState.selected.data,
                status: "pending"
            }
        });
        return axiosWrapper(config.accountApi, "accounts/current/sso-config", "POST", data)
            .then((res) => {
                dispatch({
                    type: types.ACCOUNTS_SSO_CONFIG_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data")
                    }
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.ACCOUNTS_SSO_CONFIG_SET,
                    payload: {
                        ...initialState.selected.data,
                        status: "error"
                    }
                });
                consoleLogger.error(":::", reason);
            });
    };
}
