import * as React from "react";
import * as PropTypes from "prop-types";
import {isAccountOwner} from "../../../../../lib/user-roles-helpers";
import {getPath} from "../../../../../lib/utils/";
import {List, Button, Card, CardContent, CircularProgress} from "@material-ui/core";
import Header from "../../../../Widgets/Editor/Header/";
import HeaderContentL1 from "./HeaderContentL1/";
import HeaderContentL2 from "./HeaderContentL2/";
import UserItem from "./UserItem/";
import {Search} from "@material-ui/icons";

export default class extends React.Component<any, any> {
    public static defaultProps = {
        config: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public render() {
        if (!isAccountOwner(this.props.login, this.props.config)) {
            return <Card style={{marginTop: "48px"}}>
                <Header {...this.props}>
                    <span style={{opacity: .5, paddingLeft: "24px"}}>Current User</span>
                </Header>

                <CardContent style={{paddingTop: 0}}>
                    <List>
                        <UserItem {...this.props} user={this.props.login.data} showCurrentUserMenu={true} showMenu={false}/>
                    </List>
                </CardContent>
            </Card>
        }

        const users = getPath(this.props, "state.users") || [];

        return <div>
            {/* Current user ------------------------------------------- */}
            <Card style={{marginTop: "48px"}}>
                <Header {...this.props}>
                    <span style={{opacity: .5, paddingLeft: "24px"}}>Current User</span>
                </Header>

                <CardContent style={{paddingTop: 0}}>
                    <List>
                        <UserItem {...this.props} user={this.props.login.data} showCurrentUserMenu={true} showMenu={false}/>
                    </List>
                </CardContent>
            </Card>

            {/* Users list --------------------------------------------- */}
            <Card style={{marginTop: "48px"}}>
                <Header
                    {...this.props}
                    leftIcon={this.props.state.status === "recalc"
                        ? <CircularProgress style={{ marginRight: 20, position: "relative", top: 4, opacity: 0.5 }} size={24} />
                        : <Search style={{ marginRight: 20, position: "relative", top: 4, opacity: 0.5 }} />}
                    primaryText={<HeaderContentL1 {...this.props} />}
                    rightIcon={<Button data-qa="users-permissions-invite-button" color="primary" style={{ width: 109, marginRight: 16 }}
                        onClick={() => this.props.onSetState({ dialog: ":INVITE_USER:" })} variant="contained">
                        Invite User
                    </Button>}
                />

                <Header {...this.props} style={{height: "32px", padding: "8px 16px", borderTop: "none"}}>
                    <HeaderContentL2 {...this.props} />
                </Header>

                <CardContent style={{paddingTop: 0}}>
                    <List>
                        {users.filter((user) => user.status !== -2).map((user, index) => <UserItem key={index} {...this.props} user={user}/>)}
                    </List>
                </CardContent>
            </Card>
            <br/>
        </div>;
    }
}
