import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../../redux/actions";
import getAppUsersCallUrl from "../../../../../../../lib/getAppUsersCallUrl";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

const CustomDialogActions = (props) => {
    const canEdit = props.estate.mode === ":EDIT:";

    return <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
        <Button data-qa="app-user-btn-cancel"
            disabled={props.estate.status !== "ready"}
            color="primary" style={{ marginRight: 8 }}
            onClick={props.onClose}
        >Cancel</Button>
        {canEdit ? <Button data-qa="app-user-btn-update-app"
            variant="contained" color="primary" style={{ marginRight: 8 }}
            disabled={props.estate.status !== "ready" || !props.isValid}
            onClick={async () => {
                props.onSetEState({ status: "waiting" });
                const accountId = props.estate.data.accountId;
                const environmentId = props.estate.data.environmentId;
                const directoryId = props.estate.data.dirId;
                const appUserId = props.estate.data.uid;
                const appUserUrl = getAppUsersCallUrl(accountId, environmentId, directoryId) + `/${appUserId}`;
                const data = {
                    ...(props.appUsers?.selected?.data || {}),
                    name: props.estate.data.name,
                    email: props.estate.data.email,
                    password: props.estate.data.password ? props.estate.data.password : null,
                    appUserType: props.estate.data.appUserType || "PROVIDER",
                    identifierMappings: props.estate.data.identifierMappings || []
                };
                const response = await props.appUsersUpdate(props.config, appUserUrl, data);
                if (response && response.status < 300) {
                    props.onClose();
                }
            }}
        >Update user</Button> : null}
    </DialogActions>;
}

CustomDialogActions.propTypes = {
    appUsers: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    estate: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,

    appUsersUpdate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSetEState: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    appUsers: state.appUsers,
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appUsersUpdate: actions.appUsersUpdate,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomDialogActions));
