export function isNotRestricted (id: string = "", restricted: Array<string>): boolean {
    return restricted.indexOf(id) === -1;
}

export function isUnique (id: string = "", ids: Array<string>): boolean {
    return ids.indexOf(id) === -1;
}

export function isNotTooShort (id: string = "", len: number = 4): boolean {
    return id.length >= len;
}

export function isNotTooLong (id: string = "", len: number = 18): boolean {
    return id.length <= len;
}

export function isUrlFriendly(id: string = "") {
    const reg = new RegExp("^[a-z]$|^[a-z][a-z0-9/-]*[a-z0-9]$");
    if (reg.test(id)) return true;
    return false;
}

export function isContextKeyFriendly(key: string = "") {
    const reg = new RegExp("^[a-zA-Z]$|^[a-zA-Z][a-zA-Z0-9/-]*[a-zA-Z0-9]$")
    if (reg.test(key)) return true;
    return false;
}

const reservedWords = ["app", "apps", "auth", "cdn", "cdr", "cdrs",
    "cds", "dstu2", "env", "etl", "fhir", "msg", "r4", "status", "stu3", "zipkin"];

export function isNotReservedWord (id: string = ""): boolean {
    return !reservedWords.includes(id);
}
