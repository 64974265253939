import * as React from "react";
import * as PropTypes from "prop-types";
import getMessageFromResponse from "../../../../lib/getMessageFromResponse";
import * as validateData from "../../../../lib/validate-accounts-data";
import {Card, CardContent, CardHeader, CardActions, CircularProgress, Button, TextField} from "@material-ui/core";
import Dialogs from "./Dialogs/";

const INIT_STATE = {
    status: "", // "" | "ready" | "wait"
    dataValidation: {
        email: "",
        firstName: "",
        lastName: ""
    },
    dialog: "" // "" | ":DISPLAY_MESSAGE:"
};

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        calcNextWStep: PropTypes.func.isRequired,
        onSetState: PropTypes.func.isRequired,
        accountsRequestConfirmationCode: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.state.data) !== JSON.stringify(prevProps.state.data)) {
            this.validateData();
        }
    }

    public render() {
        return <div>
            <Card style={{width: "480px", marginTop: "48px", marginRight: "auto", marginLeft: "auto", padding: "28px", borderRadius: "8px"}}>
                <CardHeader title={<div>
                    <h2 style={{fontSize: "28px", textAlign: "center", margin: "4px 0 0"}}>
                        First, confirm your email
                    </h2>
                </div>}/>

                <CardContent>
                    <div style={{display: "flex"}}>
                        <TextField style={{marginRight: "8px"}} name="firstName" value={this.props.state.data.firstName || ""} disabled={this.state.status === "wait"}
                            label="First Name" error={!!this.state.dataValidation.firstName} helperText={this.state.dataValidation.firstName} fullWidth
                            onChange={e => this.props.onSetState({data: {...this.props.state.data, firstName: e.target.value}})}/>
                        <TextField style={{marginLeft: "8px"}} name="lastName" value={this.props.state.data.lastName || ""} disabled={this.state.status === "wait"}
                            label="Last Name" error={!!this.state.dataValidation.lastName} helperText={this.state.dataValidation.lastName} fullWidth
                            onChange={e => this.props.onSetState({data: {...this.props.state.data, lastName: e.target.value}})}/>
                    </div>
                    <TextField style={{marginTop: "8px"}} name="email" type="email" value={this.props.state.data.email || ""} label="Email" placeholder="your-email@example.com"
                        error={!!this.state.dataValidation.email} helperText={this.state.dataValidation.email} disabled={this.state.status === "wait"} fullWidth
                        onChange={e => this.props.onSetState({data: {...this.props.state.data, email: e.target.value}})}/>
                </CardContent>

                <CardActions style={{padding: "16px"}}>
                    <Button color="primary" variant="contained" fullWidth disabled={!this.props.state.data.email || this.state.status !== "ready" || !this.isDataValid()}
                        onClick={() => {
                            this.setState(
                                {status: "wait"},
                                async () => {
                                    let response = null;
                                    try {
                                        const data = {email: this.props.state.data.email};
                                        response = await this.props.accountsRequestConfirmationCode(data, {bypassError: true});
                                    } catch (reason) {
                                        return this.setState({
                                            status: "ready",
                                            dataValidation: {
                                                ...this.state.dataValidation,
                                                email: getMessageFromResponse(reason, true)
                                            },
                                            dialog: ":DISPLAY_MESSAGE:"
                                        });
                                    }

                                    if (response && response.data) {
                                        this.setState(
                                            {status: "ready"},
                                            () => {
                                                localStorage.setItem("iio_confirmationEmail", this.props.state.data.email);
                                                this.props.onSetState({wstep: this.props.calcNextWStep()});
                                            }
                                        );
                                    }
                                }
                            );
                        }}>
                        {this.state.status === "wait"
                            ? <CircularProgress size={24} style={{marginTop: "5px"}}/>
                            : "CONFIRM"}
                    </Button>
                </CardActions>
            </Card>
            {["ready"].indexOf(this.state.status) > -1 &&
            <Dialogs {...this.props} state={this.state}
                onClose={() => this.setState(
                    {...INIT_STATE},
                    () => this.props.onSetState({data: {...this.props.state.data, email: ""}}))}
            />}
            <br/>
        </div>;
    }

    // Helpers -----------------------------------------------------------------
    private isDataValid: Function = (): boolean => Object.keys(this.state.dataValidation).reduce((acc: boolean, key: string): boolean => acc && !this.state.dataValidation[key], true);

    private validateData: Function = () => {
        const dataValidation = validateData.onWStep1(this.props.state)
        this.setState({status: "ready", dataValidation});
    };
}
