import * as React from "react";
import * as PropTypes from "prop-types";
import {Button, Card, CardHeader, CardActions} from "@material-ui/core";

const Component: React.FunctionComponent<any> = props => {
    return <div>
        <Card style={{width: "480px", marginTop: "48px", marginRight: "auto", marginLeft: "auto", padding: "28px", borderRadius: "8px"}}>
            <CardHeader
                title={<div>
                    <h2 style={{fontSize: "28px", textAlign: "center", margin: "4px 0 24px"}}>
                        Request Received
                    </h2>
                    <div style={{fontSize: "14px", textAlign: "center", lineHeight: "24px", margin: "0 10px"}}>
                        <p style={{margin: "0"}}>
                            Thank you for your interest in interopiO®, {props.state.data.firstName}!
                        </p>
                        <p style={{margin: "0"}}>
                            Your request has been received. One of our team members will be reaching out to you at <strong>{props.state.data.email}</strong> with further details.
                        </p>
                    </div>
                </div>}/>

            <CardActions style={{padding: "16px"}}>
                <Button color="primary" fullWidth variant="contained"
                    onClick={() => {
                        const url = "https://www.interopion.com/";
                        window.open(url, "_self");
                    }}>
                    Learn more about Interopion
                </Button>
            </CardActions>
        </Card>
    </div>;
}

Component.propTypes = {
    config: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired

};

export default Component;
