export default function convertToUrlFriendly(string = ""): string {
    if (!(typeof string === "string" || (string as any) instanceof String)) {
        return "";
    }

    return string
        .toLowerCase()
        .replace(/^\s+|\s+$/g, "")
        .replace(/[^a-zA-Z0-9]+/g, "-");
}
