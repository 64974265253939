import * as React from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Typography, FormControlLabel, Switch, Fab, Checkbox, TextField } from '@material-ui/core';
import { Table } from '../../../../../Widgets/Table'
import PracticeListItem from './PracticeListItem'
import type {
    TAppActivation,
    TPracticeInfo
} from '../../../../../../types'
import type { TDataValidation } from '../ActivationEditor'

export type TProps = {
    canEdit?: boolean | false
    isIKnowMedExtGateway: boolean | false
    muiTheme: {
        palette: {
            secondaryTextColor: string
            textColor: string
        }
    }
    data: Partial<TAppActivation>
    dataValidation: Partial<TDataValidation>

    setAppActivation: Function
    isAppLaunchingWithinIKMG2: boolean
    setIsAppLaunchingWithinIKMG2: Function
}

const PracticeList: React.FC<TProps> = (props: TProps) => {
    const canEdit = props.canEdit || false

    const [newPracticeListing, setNewPracticeListing] = React.useState<TPracticeInfo>({})
    const [allPractices, setAllPractices] = React.useState<boolean>(props.data.allPractices || false);

    const practiceListings = props.data.tenants || []
    const columns = [
        {
            size: 1,
            name: "practiceId",
            label: <span style={{ paddingLeft: 20 }}>Practice Id</span>,
            centered: false,
        },
        {
            size: 1,
            name: "practiceName",
            label: <span style={{ paddingLeft: 2 }}>Practice Name</span>,
            centered: false,
        }
    ]

    return (
        <div className="practice-container">
            <Typography variant="body1" gutterBottom>
                Is the app launching within IKM G2?
            </Typography>
            <div>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={!canEdit}
                            checked={props.isAppLaunchingWithinIKMG2}
                            onChange={(event) => props.setIsAppLaunchingWithinIKMG2(event.target.checked)}
                            name="isAppLaunchingWithinIKMG2"
                            color="primary"
                        />
                    }
                    label={props.isAppLaunchingWithinIKMG2 ? "Yes" : "No"}
                />
            </div>
            {!props.isAppLaunchingWithinIKMG2 && (
                <div className="practice-listings">
                    <h4 style={{
                        marginBottom: 0,
                        color: practiceListings.length ? props.muiTheme.palette.textColor : props.muiTheme.palette.secondaryTextColor }}
                    >
                        Practice Listings
                    </h4>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allPractices}
                                disabled={!canEdit}
                                onChange={(event) => {
                                    setAllPractices(event.target.checked)
                                    props.setAppActivation({
                                        ...props.data,
                                        // tenants: [],
                                        allPractices: event.target.checked
                                    });
                                }}
                                name="allPractices"
                                color="primary"
                            />
                        }
                        label="All Practices"
                    />

                    {canEdit && !allPractices ? (
                        <div className="new-practice-listing MuiPaper-rounded MuiPaper-outlined">
                            <div className="new-practice-listing-header">
                                <h5>Add New Practice Listing</h5>
                                <Fab
                                    data-e2e="act_add_new_practice_btn"
                                    size="small"
                                    disabled={(!newPracticeListing.practiceId
                                        || !newPracticeListing.practiceName
                                    )}
                                    onClick={() => {
                                        if (newPracticeListing.practiceId && newPracticeListing.practiceName) {
                                            let practiceToAdd = newPracticeListing
                                            if (props.isIKnowMedExtGateway) {
                                                practiceToAdd.ehr = "G2"
                                            }
                                            const newPracticeListings = [...practiceListings, practiceToAdd]
                                            props.setAppActivation({
                                                ...props.data,
                                                tenants: newPracticeListings
                                            })
                                            setNewPracticeListing({})
                                        }
                                    }}>
                                    <AddIcon />
                                </Fab>
                            </div>

                            <TextField name="addNewPracticeId"
                                data-e2e="act_new_practice_id"
                                label="New Practice Id"
                                value={newPracticeListing.practiceId || ''}
                                error={!!props.dataValidation.appActivation.tenantsPracticeId}
                                helperText={props.dataValidation.appActivation?.tenantsPracticeId ?? ''}
                                fullWidth
                                onChange={(event) => setNewPracticeListing({
                                    ...newPracticeListing,
                                    practiceId: event.target.value,
                                })}
                            />

                            <TextField name="addNewPracticeName"
                                data-e2e="act_new_practice_name"
                                label="New Practice Name"
                                value={newPracticeListing.practiceName || ''}
                                error={!!props.dataValidation.appActivation.tenantsPracticeName}
                                helperText={props.dataValidation.appActivation?.tenantsPracticeName ?? ''}
                                fullWidth
                                style={{ marginTop: 24 }}
                                onChange={(event) => setNewPracticeListing({
                                    ...newPracticeListing,
                                    practiceName: event.target.value,
                                })}
                            />
                        </div>
                    ) : null}

                    {!allPractices && <Table columns={columns}>
                        {practiceListings.map((listing, index) => (
                            <PracticeListItem
                                key={`practice-listing-item-${index}`}
                                practiceId={listing.practiceId}
                                practiceName={listing.practiceName}
                                // created={listing.created}
                                // updated={listing.updated}
                                itemIndex={index}
                                data={props.data}
                                canEdit={canEdit}
                                setAppActivation={props.setAppActivation}
                            />))}
                    </Table>
                    }
                </div>
            )}
        </div>
    )
}

export default PracticeList
