export default function (location: any, config: any) {
    const path: Array<string> = location.pathname.split("/");

    // Part 1 ------------------------------------------------------------------
    let level1: string = "";
    let level2: string = "";
    let level3: string = "";

    // *** Homepage
    if (path[1] === "home") {
        level1 = ":HOME:";
    }

    // *** Environment list (old)
    // else if (path[1] === "environments" && !path[2]) {
    //     level1 = ":ENVIRONMENTS:";
    //     level2 = "";
    // }

    // *** Environment dashboard
    else if (path[1] && config.reservedIds.indexOf(path[1]) === -1 && !path[2]) {
        level1 = ":ENVIRONMENTS:";
        level2 = ":DASHBOARD:";
    }

    // *** Apps section ...
    else if (
        path[1] && config.reservedIds.indexOf(path[1]) === -1
        && path[2] === "apps"
    ) {
        level1 = ":ENVIRONMENTS:";
        level2 = ":APPS:";
    }

    // *** CDS Service Sets section ...
    else if (
        path[1] && config.reservedIds.indexOf(path[1]) === -1
        && path[2] === "cds-service-sets"
    ) {
        level1 = ":ENVIRONMENTS:";
        level2 = ":CDS_SERVICE_SETS:";
    }

    // *** Gateways section (old) ...
    // else if (
    //     path[1] && config.reservedIds.indexOf(path[1]) === -1
    //     && path[2] === "gateways"
    //     && !path[3]
    // ) {
    //     level1 = ":ENVIRONMENTS:";
    //     level2 = ":GATEWAYS:";
    //     level3 = "";
    // }
    // else if (
    //     path[1] && config.reservedIds.indexOf(path[1]) === -1
    //     && path[2] === "gateways"
    //     && path[3] && config.reservedIds.indexOf(path[3]) === -1
    //     && !path[4]
    // ) {
    //     level1 = ":ENVIRONMENTS:";
    //     level2 = ":GATEWAYS:";
    //     level3 = ":DETAILS:";
    // }
    // else if (
    //     path[1] && config.reservedIds.indexOf(path[1]) === -1
    //     && path[2] === "gateways"
    //     && path[3] && config.reservedIds.indexOf(path[3]) === -1
    //     && path[4]
    // ) {
    //     level1 = ":ENVIRONMENTS:";
    //     level2 = ":GATEWAYS:";
    //     level3 = "";
    //     switch (path[4]) {
    //         case "data_mapping": level3 = ":DATA_MAPPING:"; break;
    //     }
    // }

    // *** Portals section ...
    else if (
        path[1] && config.reservedIds.indexOf(path[1]) === -1
        && path[2] === "portals"
    ) {
        level1 = ":ENVIRONMENTS:";
        level2 = ":PORTALS:";
    }

    // *** Gateways section ...
    else if (
        path[1] && config.reservedIds.indexOf(path[1]) === -1
        && path[2] === "gateways"
    ) {
        level1 = ":ENVIRONMENTS:";
        level2 = ":GATEWAYS:";
    }

    // *** Data sources section ...
    else if (
        path[1] && config.reservedIds.indexOf(path[1]) === -1
        && path[2] === "datasources"
    ) {
        level1 = ":ENVIRONMENTS:";
        level2 = ":DATA_SOURCES:";
    }

    // *** CDRs section ...
    else if (
        path[1] && config.reservedIds.indexOf(path[1]) === -1
        && path[2] === "cdrs"
    ) {
        level1 = ":ENVIRONMENTS:";
        level2 = ":CDRS:";
    }

    // *** User directories section ...
    else if (
        path[1] && config.reservedIds.indexOf(path[1]) === -1
        && path[2] === "auth"
    ) {
        level1 = ":ENVIRONMENTS:";
        level2 = ":AUTH:";
    }

    // *** Monitoring section ...
    else if (
        path[1] && config.reservedIds.indexOf(path[1]) === -1
        && path[2] === "monitoring"
    ) {
        level1 = ":ENVIRONMENTS:";
        level2 = ":MONITORING:";
    }

    // *** Analytics section ...
    else if (
        path[1] && config.reservedIds.indexOf(path[1]) === -1
        && path[2] === "analytics"
    ) {
        level1 = ":ENVIRONMENTS:";
        level2 = ":ANALYTICS:";
    }

    // *** Environment settings section ...
    else if (path[1] === "environments" && path[2]) {
        level1 = ":ENVIRONMENTS:";
        level2 = ":SETTINGS:";
    }

    // *** User settings section ...
    else if (path[1] === "users" && path[2] === "current") {
        level1 = ":USERS:";
        level2 = ":CURRENT:";
    }

    // Part 2 ------------------------------------------------------------------
    let appId: string = "";
    let cdrId: string = "";
    let cdsServiceSetId: string = "";
    let dataSourceId: string = "";
    let directoryId: string = "";
    let envId: string = "";
    let environmentId: string = "";
    let gatewayId: string = "";
    let portalId: string = "";

    if (path[1] === "environments" && config.reservedIds.indexOf(path[2]) === -1) {
        environmentId = path[2];
    }

    else if (path[1] && config.reservedIds.indexOf(path[1]) === -1) {
        envId = path[1];

        if (path[2] === "apps" && config.reservedIds.indexOf(path[3]) === -1) {
            appId = path[3];
        }
        else if (path[2] === "cds-service-sets" && config.reservedIds.indexOf(path[3]) === -1) {
            cdsServiceSetId = path[3];
        }
        else if (path[2] === "portals" && config.reservedIds.indexOf(path[3]) === -1) {
            portalId = path[3];
        }
        else if (path[2] === "gateways" && config.reservedIds.indexOf(path[3]) === -1) {
            gatewayId = path[3];
        }
        else if (path[2] === "datasources" && config.reservedIds.indexOf(path[3]) === -1) {
            dataSourceId = path[3];
        }
        else if (path[2] === "cdrs" && config.reservedIds.indexOf(path[3]) === -1) {
            cdrId = path[3];
        }
        else if (path[2] === "auth" && config.reservedIds.indexOf(path[3]) === -1) {
            directoryId = path[3];
        }
    }

    return ({
        level1,
        level2,
        level3,

        appId,
        cdrId,
        cdsServiceSetId,
        dataSourceId,
        directoryId,
        envId,
        environmentId,
        env: envId || environmentId,
        gatewayId,
        portalId
    });
}
