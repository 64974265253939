import * as React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../redux/actions";
import axiosWrapper from "../../../../../../lib/axiosWrapper";
import getGatewaysCallUrl from "../../../../../../lib/getGatewaysCallUrl";
import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../lib/getLocationInfo";
import Card from "@material-ui/core/Card";
import AddNewSession from "./AddNewSession";
import Dialogs from "./Dialogs";
import Sessions from "./Sessions";

interface IProps {
    config: any;
    location: any;
    gatewaysRead: Function;
    generalMessageSet: Function;
}

const ReqResLogging: React.FC<IProps> = (props: IProps) => {
    const [status, setStatus] = React.useState("init" as "init" | "ready" | "waiting");
    const [allSessions, setAllSessions] = React.useState([]);
    const [activeSessions, setActiveSessions] = React.useState([]);
    const [newSession, setNewSession] = React.useState({});
    const [selectedSessions, setSelectedSessions] = React.useState([]);
    const [dialog, setDialog] = React.useState("" as "" | ":CREATE_SESSION:" | ":DELETE_SESSIONS:");

    const { gatewaysRead, generalMessageSet } = props;
    React.useEffect(() => {
        if (status === "init") {
            setStatus("waiting");
            (async () => {
                const promises = [];
                const { accountId } = getHostnameInfo();
                const { env, gatewayId } = getLocationInfo(props.location, props.config);
                const path = `${getGatewaysCallUrl(accountId, env)}/${gatewayId}`;
                promises.push(gatewaysRead(props.config, path));
                promises.push(axiosWrapper(props.config.envApi, path + "/request-logging-session", "GET"));
                promises.push(axiosWrapper(props.config.envApi, path + "/request-logging-session?active=true", "GET"));
                let _allSessions = [];
                let _activeSessions = [];
                try {
                    [,
                        { data: _allSessions = [] } = {},
                        { data: _activeSessions = [] } = {},
                    ] = await Promise.all(promises);
                }
                catch (error) {
                    generalMessageSet({
                        type: ":WARNING_SNACKBAR:",
                        title: "There was an error retrieving the data!",
                        message: error.message,
                    });
                }
                _allSessions.sort((a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime());
                setAllSessions(_allSessions);
                setActiveSessions(_activeSessions);
                setStatus("ready");
            })();
        }
    }, [gatewaysRead, generalMessageSet, props.config, props.location, status]);

    return (
        <>
            <Card className="req-res-logging">
                <AddNewSession activeSessions={activeSessions} parentStatus={status} setDialog={setDialog} setNewSession={setNewSession} setParentStatus={setStatus} />
                <Sessions activeSessions={activeSessions} allSessions={allSessions} parentStatus={status} setDialog={setDialog} setSelectedSessions={setSelectedSessions} />
            </Card>

            <Dialogs dialog={dialog} newSession={newSession} parentStatus={status} selectedSessions={selectedSessions} setDialog={setDialog} setParentStatus={setStatus} setSelectedSessions={setSelectedSessions} />
        </>
    );
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    gatewaysRead: actions.gatewaysRead,
    generalMessageSet: actions.generalMessageSet,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReqResLogging));
