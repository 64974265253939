/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import {Button} from "@material-ui/core";
import {Check} from "@material-ui/icons";
import consoleLogger from "../../../../../../lib/consoleLogger"

const Component: React.FunctionComponent<any> = props => {
    const canEdit = props.state.mode === ":EDIT:";

    const content = [
        <Button variant="outlined" key="action-btn-cancel" disabled={!canEdit} style={{display: canEdit ? "inline-block" : "none", marginRight: "8px"}} onClick={props.onClose}>
            Cancel
        </Button>,

        <Button variant="contained" key="action-btn-update-app" color="primary" style={{display: canEdit ? "inline-block" : "none"}}
            onClick={() => {
                props.onSetState(
                    {status: "wait"},
                    () => {
                        const updatedData = {
                            enabled: props.state.ssoData.enabled,
                            entryPoint: props.state.ssoData.entryPoint,
                            issuer: props.state.ssoData.issuer,
                            spIssuer: props.state.ssoData.spIssuer,
                            cert: props.state.ssoData.cert,
                            buttonLabel: props.state.ssoData.buttonLabel,
                            force: props.state.ssoData.force
                        };
                        props.accountsSsoConfigUpdate(props.config, updatedData)
                            .then((res) => {
                                if (res && res.status < 300 && res.data) {
                                    props.initialize()
                                }
                            })
                            .catch((reason) => {
                                props.onSetState({status: "ready"});
                                consoleLogger.error(":::", reason)
                            });
                    }
                );
            }}>
            <Check/> Edit SSO
        </Button>
    ];

    return (
        <div style={{
            height: canEdit ? "36px" : 0,
            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
        }}>
            {content}
        </div>
    );
}

Component.propTypes = {
    config: PropTypes.object.isRequired
};

export default Component;
