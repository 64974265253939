import * as React from "react";
import * as PropTypes from "prop-types";
import {getPath} from "../../../../../../lib/utils";
import UserItem from "../../UsersAndPermissions/UserItem";

const Component: React.FunctionComponent<any> = props => {
    const userEmail: string = `${getPath(props, "state.selectedUser.email")}`;

    return <div>
        <p style={{color: props.muiTheme.palette.textColor, textAlign: "center"}}>
            <big>
                <span>Are you sure you want to change the user</span>&nbsp;
                <strong style={{color: props.muiTheme.palette.primary1Color}}>{userEmail}</strong>&nbsp;
                <span>to use Google Authenticator as Multifactor Authenticaton Tool. The user will be prompted to scan the generated QR code and enter six digit number to gain access.</span>
            </big>
        </p>
        <UserItem{...props} user={props.state.selectedUser} showMenu={false}/>
    </div>
}

Component.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
