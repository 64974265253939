import * as types from "../../actions/types";

import initialState from "./initial-state";

export default function (state = initialState, action: any) {
    switch (action.type) {
        case types.UI_SHOW_NAVBAR:
            return {
                ...state,
                showNavbar: true,
            };

        case types.UI_HIDE_NAVBAR:
            return {
                ...state,
                showNavbar: false,
            };

        case types.UI_LEFT_MENU_PRIVATE_EXPAND:
            return {
                ...state,
                leftMenuPrivate: {
                    ...state.leftMenuPrivate,
                    isExpanded: true,
                },
            };

        case types.UI_LEFT_MENU_PRIVATE_SHRINK:
            return {
                ...state,
                leftMenuPrivate: {
                    ...state.leftMenuPrivate,
                    isExpanded: false,
                },
            };

        case types.UI_LEFT_MENU_PRIVATE_TOGGLE:
            return {
                ...state,
                leftMenuPrivate: {
                    ...state.leftMenuPrivate,
                    isExpanded: !state.leftMenuPrivate.isExpanded,
                },
            };

        case types.UI_TABS_SET_ITEMS:
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    items: action.payload,
                },
            };

        case types.UI_TABS_SET_SELECTED:
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    selected: action.payload,
                },
            };

        case types.UI_XTHEME_SET:
            return {
                ...state,
                xtheme: {
                    ...state.xtheme,
                    ...action.payload,
                },
            };

        default:
            return state;
    }
}
