import * as React from "react";
import * as PropTypes from "prop-types";
import { bindActionCreators } from 'redux'
import * as actions from '../../redux/actions/'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import $ from "jquery";
import AppBar from "@material-ui/core/AppBar";
import CloudCircleIcon from "@material-ui/icons/CloudCircle";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import * as features from "../../lib/feature-helpers";
import getAppsCallUrl from '../../lib/getAppsCallUrl'
import getCDRsCallUrl from '../../lib/getCDRsCallUrl'
import getCdsServiceSetsCallUrl from '../../lib/getCdsServiceSetsCallUrl'
import getDataSourcesCallUrl from '../../lib/getDataSourcesCallUrl'
import getGatewaysCallUrl from '../../lib/getGatewaysCallUrl'
import getHostnameInfo from '../../lib/getHostnameInfo'
import getLocationInfo from '../../lib/getLocationInfo'
import getUserDirsCallUrl from '../../lib/getUserDirsCallUrl'
import LoggedInMenu from "../CustomNavbar/LoggedInMenu/";
import Selector from "../CustomNavbar/Selector";
import "./style.less";


class NavbarL1Private extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        environments: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,

        appsReadAll: PropTypes.func.isRequired,
        cdrsReadAll: PropTypes.func.isRequired,
        cdsServiceSetsReadAll: PropTypes.func.isRequired,
        dataSourcesReadAll: PropTypes.func.isRequired,
        gatewaysReadAll: PropTypes.func.isRequired,
        userDirsReadAll: PropTypes.func.isRequired,

        stageScrollThreshold: PropTypes.number
    };

    public static defaultProps = {
        stageScrollThreshold: 56
    };

    public constructor(props) {
        super(props);
        this.state = {
            showTitleL2: false
        };
    }

    public componentDidMount() {
        $(".stage").scroll(this.onScroll);
    }

    public render() {
        const location = getLocationInfo(this.props.location, this.props.config);
        const featureSmileCdrCustomization = features.check(":SMILE_CDR_CUSTOMIZATION:", this.props.features.data);

        const titleL1 = !location.env
            ? <div data-qa="nav-home-public" style={{display: "flex", alignItems: "center", height: "48px"}}>
                <Link to={"/"} style={{display: "flex"}}>
                    <img src={"/console/img/interopio-logo-wh@2x--r.png"} style={{width: "auto", height: "32px"}}/>
                </Link>
            </div>
            : (
                <Selector
                    data-qa="selector-environments"
                    data-qa-label="env-name"
                    value={location.env}
                    data={this.props.environments.all
                        ? this.props.environments.all.data
                            .map((environment) => {
                                if (!environment) return null
                                return {
                                    id: environment.environmentId,
                                    name: environment.name,
                                }})
                        : []
                    }
                    icon={<CloudCircleIcon style={{color: "rgba(255, 255, 255, 0.87)"}} />}
                    noBackground
                    onChange={(item) => {
                        this.props.history.push(`/${item.id}`)
                        Promise.resolve()
                            .then(() => {
                                const { accountId } = getHostnameInfo()
                                const { env } = getLocationInfo(this.props.location, this.props.config)

                                const appsUrl = getAppsCallUrl(accountId, env)
                                this.props.appsReadAll(this.props.config, appsUrl)

                                const cdsServiceSetsUrl = getCdsServiceSetsCallUrl(accountId, env)
                                this.props.cdsServiceSetsReadAll(this.props.config, cdsServiceSetsUrl)

                                const cdrsUrl = getCDRsCallUrl(accountId, env)
                                this.props.cdrsReadAll(this.props.config, cdrsUrl)

                                const dataSourcesUrl = getDataSourcesCallUrl(accountId, env)
                                this.props.dataSourcesReadAll(this.props.config, dataSourcesUrl)

                                const gatewaysUrl = getGatewaysCallUrl(accountId, env)
                                this.props.gatewaysReadAll(this.props.config, gatewaysUrl)

                                const userDirectoriesUrl = getUserDirsCallUrl(accountId, env)
                                this.props.userDirsReadAll(this.props.config, userDirectoriesUrl)
                            })
                    }}
                />
            )

        let titleL2 = "";
        if (!location.env) {
            if (location.level1 === ":HOME:") {
                titleL2 = "";
                switch (this.props.ui.tabs.selected) {
                    case 0:
                        titleL2 = "Environments";
                        break;
                    case 1:
                        titleL2 = "Settings";
                        break;
                    case 2:
                        titleL2 = "Users And Permissions";
                        break;
                }
            } else if (location.level1 === ":USERS:" && location.level2 === ":CURRENT:") {
                titleL2 = "User Profile";
            }
        } else if (location.level1 === ":ENVIRONMENTS:" && location.level2 === ":DASHBOARD:") {
            titleL2 = "Environment Dashboard";
        } else if (location.level1 === ":ENVIRONMENTS:" && location.level2 === ":APPS:") {
            titleL2 = "Apps";
        } else if (location.level1 === ":ENVIRONMENTS:" && location.level2 === ":CDS_SERVICE_SETS:") {
            titleL2 = "CDS Hooks";
        } else if (location.level1 === ":ENVIRONMENTS:" && location.level2 === ":PORTALS:") {
            titleL2 = "Portals";
        } else if (location.level1 === ":ENVIRONMENTS:" && location.level2 === ":GATEWAYS:") {
            titleL2 = "Gateways";
        } else if (location.level1 === ":ENVIRONMENTS:" && location.level2 === ":DATA_SOURCES:") {
            titleL2 = "Data Adapters";
        } else if (location.level1 === ":ENVIRONMENTS:" && location.level2 === ":CDRS:") {
            titleL2 = featureSmileCdrCustomization ? "Smile CDR" : "FHIR CDRs";
        } else if (location.level1 === ":ENVIRONMENTS:" && location.level2 === ":AUTH:") {
            titleL2 = "Authentication";
        } else if (location.level1 === ":ENVIRONMENTS:" && location.level2 === ":MONITORING:") {
            titleL2 = "Monitoring";
        } else if (location.level1 === ":ENVIRONMENTS:" && location.level2 === ":SETTINGS:") {
            titleL2 = "Environment Settings";
        } else if (location.level1 === ":ENVIRONMENTS:" && location.level2 === ":ANALYTICS:") {
            titleL2 = "Environment Analytics";
        }

        return <AppBar position="static" className="navbar-l1-private"
            style={{paddingLeft: location.env ? (this.props.ui.leftMenuPrivate.isExpanded ? this.props.ui.leftMenuPrivate.width.expanded : this.props.ui.leftMenuPrivate.width.shrunk) : 0}}>
            <div className="title-wrapper">
                <div className="title-l1">
                    {titleL1}
                </div>
                {titleL2
                    ? <div className={`title-l2 ${!this.state.showTitleL2 ? "title-l2-hidden" : ""}`}>
                        {titleL2}
                    </div>
                    : null}
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <LoggedInMenu/>
                {/* <NotificationsIcon style={{paddingBottom: "4px", paddingLeft: "14px", color: "rgba(255, 255, 255, 0.67)"}}/> */}
            </div>
        </AppBar>;
    }

    // Event handlers ----------------------------------------------------------
    private onScroll = () => {
        const scrollTop = $(".stage").scrollTop();
        if (scrollTop > this.props.stageScrollThreshold && !this.state.showTitleL2) {
            this.setState({showTitleL2: true});
        } else if (scrollTop < this.props.stageScrollThreshold && this.state.showTitleL2) {
            this.setState({showTitleL2: false});
        }
    }
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    environments: state.environments,
    features: state.features,
    ui: state.ui,
    ...ownProps,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appsReadAll: actions.appsReadAll,
    cdrsReadAll: actions.cdrsReadAll,
    cdsServiceSetsReadAll: actions.cdsServiceSetsReadAll,
    dataSourcesReadAll: actions.dataSourcesReadAll,
    gatewaysReadAll: actions.gatewaysReadAll,
    userDirsReadAll: actions.userDirsReadAll,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavbarL1Private))
