import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as actions from "../../../../redux/actions";
import {getPath} from "../../../../lib/utils/";
import axiosWrapper from "../../../../lib/axiosWrapper";
import consoleLogger from "../../../../lib/consoleLogger";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getMessageFromResponse from "../../../../lib/getMessageFromResponse";
import * as validateData from "../../../../lib/validate-signin-data";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Info from "@material-ui/icons/Info";
import { withStyles } from '@material-ui/core/styles';
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";

const INIT_STATE = {
    status: "",
    renderPassword: false,
    renderMFA: false,
    showQR: false,
    data: {
        email: "",
        password: "",
        verificationCode: ""
    },
    dataValidation: {
        email: "",
        signIn: ""
    },
    accountData: {
        name: "",
        path: "",
        uuid: ""
    },
    showPassword: false,
    mfaQrCodeURL: "",
    mfaConfigKey: ""
};

const styles = {
    root: {
        '& .Mui-disabled': {
            color: '#000',
        },
    },
};

class EnterCredentials extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        loginReset: PropTypes.func.isRequired,
        loginSet: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    private emailInput = React.createRef<HTMLInputElement>();

    public componentDidMount() {
        this.initialize();
    }

    public componentDidUpdate(_, prevState) {
        if (JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.validateData();
        }
    }

    public render() {
        const { classes } = this.props;
        if (["ready", "wait"].indexOf(this.state.status) < 0) {
            return <CenteredCircularProgress size={63} style={{padding: 24}}/>
        }

        const {accountId, domain, type} = getHostnameInfo();
        const accountName = this.state.accountData?.name || "your account";

        if (!this.state.renderPassword && !this.state.renderMFA) {
            return <Card style={{width: 480, marginTop: 48, marginRight: "auto", marginLeft: "auto", padding: 28, borderRadius: 8}}>
                <CardContent>
                    <h2 data-qa="logged-out-sign-in-title" style={{ margin: "4px 0 24px", textAlign: "center", fontSize: 28 }}>
                        Sign in to {accountName}
                    </h2>
                    <p style={{ margin: "8px 0 16px 0", textAlign: "center", fontSize: 14 }}>
                        {`${accountId}.${domain}.${type}`}
                    </p>
                    <p style={{ margin: 0, textAlign: "center", fontSize: 16 }}>
                        Enter your&nbsp;
                        <strong><big>email address</big></strong>&nbsp;
                    </p>
                </CardContent>

                <CardContent
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                    }}
                >
                    <TextField
                        autoFocus
                        name='email'
                        type='email'
                        value={this.state.data.email || ''}
                        label={this.state.dataValidation.email || 'Email'}
                        disabled={this.state.status !== 'ready'}
                        error={!!this.state.dataValidation.email}
                        placeholder='Email'
                        autoComplete='email'
                        fullWidth
                        inputRef={this.emailInput}
                        onChange={(event) => this.setState({
                            data: {
                                ...this.state.data,
                                email: event.target.value,
                            },
                            dataValidation: {
                                ...this.state.dataValidation,
                                signIn: '',
                            }
                        })}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter' &&
                                !(!this.state.data.email || this.state.status !== 'ready' || !!this.state.dataValidation.email)) {
                                event.preventDefault()
                                this.submitEmail()
                            }
                        }}
                    />
                </CardContent>

                <CardContent style={{ padding: 16 }}>
                    <Button data-qa="check-email-button" variant="contained" color="primary" fullWidth
                        disabled={!this.state.data.email || this.state.status !== "ready" || !!this.state.dataValidation.email}
                        onClick={this.submitEmail}
                    >
                        {this.state.status === "wait" ? <CircularProgress size={24}/> : "Next"}
                    </Button>
                </CardContent>

                <CardContent style={{ padding: 16, textAlign: "center", fontSize: 12 }}>
                    By continuing, you’re agreeing to our&nbsp;
                    <Link to="/terms/user-terms-of-service" target="_blank" style={{ fontSize: 12, fontWeight: 700, textDecoration: "none" }}>
                        User Terms of Service
                    </Link>,&nbsp;
                    <Link to="/policies/privacy-notice" target="_blank" style={{ fontSize: 12, fontWeight: 700, textDecoration: "none" }}>
                        Privacy Notice</Link>,&nbsp;and&nbsp;
                    <Link to="/policies/cookie-policy" target="_blank" style={{ fontSize: 12, fontWeight: 700, textDecoration: "none" }}>
                        Cookie Policy
                    </Link>.
                </CardContent>

                <CardContent style={{padding: 16, textAlign: "center", fontSize: 16}}>
                    <strong>
                        <a href="#!" style={{color: this.props.muiTheme.palette.disabledColor, fontSize: 16, textDecoration: "none"}}>
                            Forgot which email you used?
                        </a>
                    </strong>
                </CardContent>
            </Card>;
        } else if (this.state.renderPassword) {
            return <Card style={{width: 480, marginTop: 48, marginRight: "auto", marginLeft: "auto", padding: 28, borderRadius: 8}}>
                <CardContent>
                    <div>
                        <h2 data-qa="logged-out-sign-in-title" style={{margin: "4px 0 24px", textAlign: "center", fontSize: 28}}>
                            Sign in to {accountName}
                        </h2>
                        <p style={{margin: "8px 0 16px 0", textAlign: "center", fontSize: 14}}>
                            {`${accountId}.${domain}.${type}`}
                        </p>
                    </div>
                    <p style={{margin: 0, textAlign: "center", fontSize: 16}}>
                        Enter your&nbsp;
                        <strong><big>password</big></strong>&nbsp;
                    </p>
                </CardContent>

                <CardContent>
                    <TextField style={{marginBottom: 10}} name="email" type="email" value={this.state.data.email || ""}
                        disabled={true}
                        fullWidth
                    />
                    <FormControl fullWidth>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input id="password" name="password" autoFocus
                            type={this.state.showPassword ? "text" : "password"}
                            value={this.state.data.password || ""}
                            disabled={this.state.status !== "ready"}
                            placeholder="Password" autoComplete="password" fullWidth
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => this.setState((prevState) => ({showPassword: !prevState.showPassword}))}
                                        onMouseDown={(event) => event.preventDefault()}
                                    >
                                        {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            onChange={(event) => this.setState({
                                data: {
                                    ...this.state.data,
                                    password: event.target.value,
                                },
                                dataValidation: {
                                    ...this.state.dataValidation,
                                    signIn: ""
                                }
                            })}
                            onKeyPress={(event) => {
                                if (event.key === "Enter" && !(!this.state.data.email || !this.state.data.password || this.state.status !== "ready" || !this.isDataValid())) {
                                    event.preventDefault();
                                    this.submitCredentials();
                                }
                            }}
                        />
                    </FormControl>
                    {/* Error message -------------------------------------- */}
                    <div style={{
                        padding: this.state.dataValidation.signIn ? 8 : 0,
                        transform: this.state.dataValidation.signIn ? "scaleY(1)" : "scaleY(0)",
                        transformOrigin: "top",
                        borderRadius: 2,
                        background: this.props.muiTheme.palette.accent1Color,
                        color: "#fff",
                        textAlign: "center",
                        transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)",
                    }}>
                        {this.state.dataValidation.signIn}
                    </div>
                </CardContent>

                <CardContent style={{padding: 16}}>
                    <Button data-qa="sign-in-button" variant="contained" color="primary" fullWidth
                        disabled={!this.state.data.email || !this.state.data.password || this.state.status !== "ready" || !this.isDataValid()}
                        onClick={this.submitCredentials}
                    >
                        {this.state.status === "wait" ? <CircularProgress size={24}/> : "Sign in"}
                    </Button>
                    <Button style={{marginTop: 10, backgroundColor: "#fff", color: this.props.muiTheme.palette.primary1Color}} data-qa="back-to-email-button" variant="contained" fullWidth color="default"
                        onClick={this.backToEmail}
                    >
                        {this.state.status === "wait" ? <CircularProgress size={24}/> : "Back"}
                    </Button>
                </CardContent>

                <CardContent style={{padding: 16, textAlign: "center", fontSize: 16}}>
                    <strong>
                        <Link to="/users/reset-password/enter-email" style={{fontSize: 16, textDecoration: "none"}}>
                            Forgot password?
                        </Link>
                    </strong>
                </CardContent>
            </Card>;
        } else if (this.state.renderMFA) {
            return <Card style={{width: "480px", marginTop: "48px", marginRight: "auto", marginLeft: "auto", padding: "28px", borderRadius: "8px"}}>
                <CardContent>
                    <div>
                        <h2 data-qa="logged-out-sign-in-title" style={{margin: "4px 0 24px", textAlign: "center", fontSize: 28}}>
                            Sign in to {accountName}
                        </h2>
                        <p style={{margin: "8px 0 16px 0", textAlign: "center", fontSize: 14}}>
                            {`${accountId}.${domain}.${type}`}
                        </p>
                    </div>
                    <p style={{margin: 0, textAlign: "center", fontSize: 16}}>
                        Enter your&nbsp;
                        <strong><big>verification code</big></strong>&nbsp;
                    </p>
                </CardContent>
                <CardContent>
                    {this.state.showQR && (
                        <div>
                            <p>Scan this QR code on your authenticator app:</p>
                            <img src={encodeURI(this.state.mfaQrCodeURL)} />
                            <p>Or enter this secret key manually in your authenticator app:</p>
                            <TextField
                                className={classes.root}
                                style={{ wordBreak: "break-all", marginBottom: "10px" }}
                                disabled={true}
                                fullWidth
                                label="Secret Key"
                                variant="outlined"
                                value={this.state.mfaConfigKey}/>
                        </div>
                    )}
                    <TextField autoFocus name="verificationCode" type="text" value={this.state.data.verificationCode || ""}
                        label={"Verification Code"}
                        disabled={this.state.status !== "ready"}
                        fullWidth
                        placeholder="Verification code"
                        onChange={(event) => this.setState({
                            data: {
                                ...this.state.data,
                                verificationCode: event.target.value
                            },
                            dataValidation: {
                                ...this.state.dataValidation,
                                signIn: ""
                            }
                        })}
                        onKeyPress={(e) => {
                            if (e.key === "Enter" && !(!this.state.data.email || !this.state.data.password || this.state.status !== "ready" || !this.isDataValid())) {
                                e.preventDefault();
                                this.submitValidation();
                            }}}
                    />
                    {/* Error message -------------------------------------- */}
                    <div style={{
                        padding: this.state.dataValidation.signIn ? "8px" : 0,
                        transform: this.state.dataValidation.signIn ? "scaleY(1)" : "scaleY(0)",
                        transformOrigin: "top",
                        borderRadius: "2px",
                        background: this.props.muiTheme.palette.accent1Color,
                        color: "#fff",
                        textAlign: "center",
                        transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)",
                    }}>
                        {this.state.dataValidation.signIn}
                    </div>
                </CardContent>

                <CardContent style={{padding: 16}}>
                    <Button data-qa="validation-confirm-button" variant="contained" color="primary" fullWidth
                        disabled={!this.state.data.email || !this.state.data.password || this.state.status !== "ready" || !this.isDataValid()}
                        onClick={this.submitValidation}
                    >
                        {this.state.status === "wait" ? <CircularProgress size={24}/> : "Confirm"}
                    </Button>
                </CardContent>


                <CardContent style={{padding: 16, textAlign: "center",}}>
                    <strong>
                        <div style={{color: this.props.muiTheme.palette.disabledColor, fontSize: 16, textDecoration: "none", display: "flex"}}>
                            <div>If your MFA device is lost, damaged, or not working, please ask an account Owner to reset your MFA.</div>
                            <a href="https://support.interopio.com/hc/en-us/articles/360059664112-Set-Up-Multi-Factor-Authentication-MFA-" target="_blank" style={{display: "inline-block"}}>
                                <Tooltip title={(<span style={{display: "inline-block", width: "80px", padding: "8px 0", lineHeight: "normal", whiteSpace: "normal"}}>Read more</span>)} placement="top-start" classes={{tooltip: "io-tooltip"}}>
                                    <IconButton>
                                        <Info/>
                                    </IconButton>
                                </Tooltip>
                            </a>
                        </div>
                    </strong>
                </CardContent>

            </Card>;
        }
    }

    private submitEmail = () => {
        const { accountId } = getHostnameInfo();
        this.setState(
            { status: "wait" },
            () => {
                axiosWrapper(
                    this.props.config.accountApi,
                    "check-email",
                    "POST",
                    this.state.data,
                )
                    .then((response) => {
                        const accountApi = this.props.config.accountApi;
                        if (response.data.test === "jc") {
                            window.location.href = `${accountApi.protocol}://${accountId}.${accountApi.host}:${accountApi.port}/${accountApi.path}/login-sso`;
                        } else {
                            this.setState({
                                status: "ready",
                                renderPassword: true,
                            })
                        }
                    })
                    .catch((reason) => {
                        const signIn = getMessageFromResponse(reason, true);
                        this.setState(
                            {
                                dataValidation: {
                                    ...this.state.dataValidation,
                                    signIn,
                                },
                            },
                            () => {
                                this.setState(
                                    { status: "ready" },
                                    this.props.loginReset,
                                );
                            },
                        );
                    });
            },
        );
    };

    private submitCredentials = () => {
        this.setState(
            {status: "wait"},
            () => {
                axiosWrapper(
                    this.props.config.accountApi,
                    "login",
                    "POST",
                    this.state.data
                )
                    .then(response => {
                        this.setState(
                            {status: "ready"},
                            async () => {
                                const isFirefox = typeof window["InstallTrigger"] !== "undefined";

                                if (isFirefox) {
                                    $(`<div style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: #00567d;opacity: 1;z-index: 999999;"></div>`)
                                        .insertBefore(".app-wrapper");
                                }
                                if (response.data.mfaEnabled === 0) {
                                    this.props.loginSet({
                                        status: "logged-in",
                                        data: getPath(response, "data") || {}
                                    });
                                    if (this.props.history.location.pathname === "/signin") {
                                        this.props.history.push("/home");
                                    }

                                    if (isFirefox) {
                                        window.location.reload();
                                    }
                                } else {
                                    this.setState({
                                        status: "ready",
                                        renderPassword: false,
                                        renderMFA: true,
                                        showQR: response.data.mfaEnabled === 1
                                    }, () => {
                                        if (this.state.showQR) {
                                            axiosWrapper(
                                                this.props.config.accountApi,
                                                "users/mfa-qr-code",
                                            ). then(res => {
                                                consoleLogger.log("::: res:", res);
                                                this.setState({
                                                    mfaQrCodeURL: res.data.qrCodeDataURL,
                                                    mfaConfigKey: res.data.configKey
                                                })
                                            })
                                        }
                                    })
                                }
                            }
                        );
                    })
                    .catch(reason => {
                        const signIn = getMessageFromResponse(reason, true);
                        this.setState(
                            {
                                dataValidation: {
                                    ...this.state.dataValidation,
                                    signIn
                                }
                            },
                            () => {
                                this.setState(
                                    {status: "ready"},
                                    this.props.loginReset
                                );
                            }
                        );
                    });
            }
        );
    }

    private submitValidation = () => {
        this.setState(
            {status: "wait"},
            () => {
                axiosWrapper(
                    this.props.config.accountApi,
                    "users/verify-otp",
                    "POST",
                    this.state.data
                )
                    .then(response => {
                        this.setState(
                            {status: "ready"},
                            async () => {
                                const isFirefox = typeof window["InstallTrigger"] !== "undefined";

                                if (isFirefox) {
                                    $(`<div style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: #00567d;opacity: 1;z-index: 999999;"></div>`)
                                        .insertBefore(".app-wrapper");
                                }
                                this.props.loginSet({
                                    status: "logged-in",
                                    data: response?.data || {}
                                });
                                if (this.props.history.location.pathname === "/signin") {
                                    this.props.history.push("/home");
                                }

                                if (isFirefox) {
                                    window.location.reload();
                                }
                            }
                        );
                    })
                    .catch(reason => {
                        const signIn = getMessageFromResponse(reason, true);
                        this.setState(
                            {
                                dataValidation: {
                                    ...this.state.dataValidation,
                                    signIn
                                }
                            },
                            () => {
                                this.setState(
                                    {status: "ready"},
                                    this.props.loginReset
                                );
                            }
                        );
                    });
            }
        );
    };

    private backToEmail = () => {
        this.setState({
            renderPassword: false,
            renderMFA: false,
        }, () => {
            const node = this.emailInput.current;
            if (node) {
                node.focus()
            }
        });
    }

    private isDataValid = () => Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true);

    private validateData = () => {
        const dataValidation = validateData.onEnterCredentials(this.state)
        this.setState({
            status: "ready",
            dataValidation: {
                ...this.state.dataValidation,
                ...dataValidation
            }
        });
    }

    private initialize = () => {
        this.setState(
            {status: "init"},
            async () => {
                let response = null;
                const {accountId} = getHostnameInfo();
                try {
                    response = await axiosWrapper(
                        this.props.config.accountApi,
                        `accounts/public?path=${accountId}`,
                        "GET"
                    )
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                    const {
                        protocol,
                        domain,
                        type,
                        port
                    } = getHostnameInfo();
                    window.location.href = `${protocol}//${domain}.${type}${port ? ":" + port : ""}/console`;
                }
                if (response && response.status < 300 && response.data) {
                    this.props.setBannerInfo(response.data.banner)
                    this.setState({
                        status: "ready",
                        accountData: response.data
                    });
                }
            }
        );
    };
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    history: state.history,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    loginReset: actions.loginReset,
    loginSet: actions.loginSet,
}, dispatch);
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(EnterCredentials))));
