const SENSITIVE_KEYWORDS = [
    'password',
    'credentials',
    'token',
    'session',
]

function neutralizeOutput(message) {
    if (typeof message === 'string') {
        SENSITIVE_KEYWORDS.forEach(keyword => {
            if(message.includes(keyword)) {
                return '*** sensitive data ***'
            }
        })
    }
    return message
}

function consoleLog(message?: any, ...optionalParams: any[]) {
    if(process.env.NODE_ENV === "production") {
        return
    }

    console.log(neutralizeOutput(message), ...optionalParams)
}

function consoleError(message?: any, ...optionalParams: any[]) {
    if(process.env.NODE_ENV === "production") {
        return
    }

    console.error(neutralizeOutput(message), ...optionalParams)
}

export default {
    log: consoleLog,
    error: consoleError,
};
