import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../redux/actions";
import { withRouter } from "react-router";
import getDataSourcesCallUrl from "../../../../../lib/getDataSourcesCallUrl";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../lib/getLocationInfo";
import Button from "@material-ui/core/Button";

const ActionButtons = (props) => {
    const canEdit = props.state.mode === ":EDIT:";
    const isValid = Object.keys(props.state.dataValidation).reduce((acc, key) => !!acc && !props.state.dataValidation[key], true);

    return <div style={{
        height: canEdit ? "auto" : 0,
        padding: canEdit ? 24 : "0 24px 0 0",
        textAlign: "right",
        opacity: canEdit ? 1 : 0,
        transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)",
    }}>
        <Button disabled={props.state.status !== "ready" || !canEdit} style={{ display: canEdit ? "inline-block" : "none", marginRight: 8 }}
            onClick={() => props.onSetState({ mode: ":VIEW:" }, props.initialize)}
        >
            Cancel
        </Button>
        <Button data-qa="da-details-edit-save-button"
            variant="contained" color="primary"
            disabled={props.state.status !== "ready" || !canEdit || !isValid}
            style={{ display: canEdit ? "inline-block" : "none" }}
            onClick={async () => {
                props.onSetState({ status: "waiting" });
                const {accountId} = getHostnameInfo();
                const {dataSourceId, env} = getLocationInfo(props.location, props.config);
                let data = { ...props.state.data };
                let config = data.configuration;
                if (config.json) {
                    try {
                        const configProps = JSON.parse(config.json);
                        let configuration = { ...config, ...configProps };
                        delete configuration.json;
                        data.configuration = configuration;
                    } catch (reason) {
                        console.warn("::: Warning:", reason);
                    }
                }
                const url = getDataSourcesCallUrl(accountId, env) + "/" + dataSourceId;
                const response = await props.dataSourcesUpdate(props.config, url, data);
                if (response) {
                    props.onSetState(
                        { mode: ':VIEW:' },
                        props.initialize,
                    )
                }
                else {
                    props.onSetState({ status: 'ready' })
                }
            }}
        >
            Save
        </Button>
    </div>;
}

ActionButtons.propTypes = {
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    dataSourcesUpdate: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    dataSourcesUpdate: actions.dataSourcesUpdate,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActionButtons));
