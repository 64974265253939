import * as React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import SvgIcon from '@material-ui/core/SvgIcon'
import withLegacyTheme from '../../../lib/hoc/with-legacy-theme'
import './style.less'

type TProps = {
    muiTheme?: {
        palette: {
            primary1Color?: string
            disabledColor?: string
            secondaryTextColor?: string
        }
    }
    anchorHref?: string
    anchorPrefix?: typeof SvgIcon
    anchorPreventPropagation?: boolean
    anchorTarget?: string
    anchorText?: string
    buttonLabel?: string
    className?: string
    isPortal?: boolean
    linkText?: string
    linkTo?: string
    style?: object
    onClickButton?: () => {} | null
}

const defaultProps = {
    anchorPreventPropagation: false,
    anchorTarget: '_self',
    onClickButton: null,
}

const CardFooter: React.FC<TProps> = (props: TProps = defaultProps) => {
    const isButtonDisabled = props.onClickButton === null

    return (
        <div
            className={`card-footer ${props.className}`}
            style={props.style}
        >
            {(props.anchorText || props.anchorHref) && (
                <a
                    href={props.anchorHref}
                    target={props.anchorTarget}
                    className='footer-link'
                    onClick={(event) => props.anchorPreventPropagation && event.stopPropagation()}
                    style={{
                        padding: '8px 0',
                        fontSize: props.isPortal ? '12px' : '',
                        lineHeight: props.isPortal ? '15px' : '',
                    }}
                >
                    <div>
                        {props.anchorText || props.anchorHref}
                    </div>
                </a>
            )}
            {(props.linkText || props.linkTo) && (
                <Link
                    to={props.linkTo}
                    className='footer-link'
                    style={{ wordBreak: 'break-all' }}
                    target={props.anchorTarget}
                    onClick={(event) => props.anchorPreventPropagation && event.stopPropagation()}
                >
                    {props.linkText || props.linkTo}
                </Link>
            )}
            {props.buttonLabel && (
                <Button
                    disabled={isButtonDisabled}
                    onClick={!isButtonDisabled && props.onClickButton}
                >
                    <span
                        style={{
                            letterSpacing: 0,
                            fontWeight: 600,
                            fontSize: 14,
                            color: props.onClickButton ? props.muiTheme.palette.primary1Color : props.muiTheme.palette.disabledColor,
                        }}
                    >
                        {props.buttonLabel}
                    </span>
                </Button>
            )}
        </div>
    )
}

export default withLegacyTheme()(CardFooter)
