import * as React from "react";
import * as PropTypes from "prop-types";
import MonitoringToolbar from "../MonitoringToolbar";
import AppList from "./AppList";
import "./style.less";

class MonitoringApps extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {
            filteredApps: []
        }
    }

    public render() {
        let toolbarProps = {
            label: "Apps",
            list: this.props.state.apps.map(i => ({label: i.appName})),
            onPeriodChange: this.props.getApps,
            onFilter: this.filterApps
        };

        return <div className="data-adapters">
            <MonitoringToolbar {...this.props} {...toolbarProps}/>
            {!this.props.state.appsMessage && <AppList {...this.props} filteredApps={this.state.filteredApps}/>}
            <div style={{textAlign: "center", paddingTop: "50px"}}>
                {this.props.state.appsMessage}
            </div>
        </div>;
    }

    protected filterApps = filteredApps => {
        this.setState({filteredApps})
    }
}

export default MonitoringApps;
