import * as React from "react";
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as actions from "../../../../redux/actions";
import {getPath} from "../../../../lib/utils/";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";

const INIT_STATE = {
    status: "", // "" | "init" | "ready" | "wait"
    renderPassword: false,
    data: {
        email: "",
        password: ""
    },

    dataValidation: {
        email: "",
        signIn: ""
    },

    accountData: {
        name: "",
        path: "",
        uuid: ""
    }
};

class SSO extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,

        loginReset: PropTypes.func.isRequired,
        loginSet: PropTypes.func.isRequired,
        usersGetCurrentSSO: PropTypes.func.isRequired
    };

    public constructor(props: any) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount(): void {
        this.initialize();
    }

    public render() {
        return <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
    }

    private initialize = async () => {
        const currentUser = await this.props.usersGetCurrentSSO();
        if (currentUser && currentUser.data) {
            this.props.loginSet({
                status: "logged-in",
                data: getPath(currentUser, "data") || {}
            });
            this.props.history.push("/home");
        } else {
            this.props.history.push("/home");
        }
    }
}

const mapStateToProps = (state: any, ownProps: any) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch: any) => bindActionCreators({...actions}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(SSO)));
