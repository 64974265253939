import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import * as features from "../../../../lib/feature-helpers";
import getCDRsCallUrl from "../../../../lib/getCDRsCallUrl";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import {isEnvironmentAdmin} from "../../../../lib/user-environment-permissions-helpers";
import {Link} from "react-router-dom";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import {CardsContainer, CardWrapper, CardBody, CardBodyAddNew, CardMenuBar} from "../../../Widgets/CustomCard/";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import {NavbarL2} from "../../../CustomNavbar/";
import WidgetsDeleteConfirm from "../../../Widgets/DeleteConfirm/";
import CDRAddNewWizard from "../CDRAddNewWizard/";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./style.less";

export const INIT_STATE = {
    status: "", // "" | "ready" | "init"
    dialog: "", // "" | "ADD_NEW_WIZARD" | "DELETE_CONFIRM"
    delete: {
        cdrId: "",
        name: ""
    },
    deleteError: null,
};

class CDRList extends React.Component<any, any> {
    public static propTypes = {
        cdrs: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        cdrsDelete: PropTypes.func.isRequired,
        cdrsReadAll: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        $(".stage").addClass("stage-bgnd-dark");
        this.initialize();
    }

    public componentWillUnmount() {
        $(".stage").removeClass("stage-bgnd-dark");
    }

    public render() {
        const {env} = getLocationInfo(this.props.location, this.props.config);
        const featureSmileCdrCustomization = features.check(":SMILE_CDR_CUSTOMIZATION:", this.props.features.data);
        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);

        const data = [...this.props.cdrs.all.data].sort((a, b) => a.name.localeCompare(b.name));

        return <div className="cdrs">
            <NavbarL2 title={<Link to={`/${env}/cdrs`} style={{color: "#fff", fontSize: "24px", textDecoration: "none"}}>
                {featureSmileCdrCustomization ? "Smile CDR" : "FHIR CDRs"}
            </Link>}/>

            <div className="container" style={{paddingTop: 48}}>
                {featureSmileCdrCustomization
                    ? <div>
                        <img src={"/console/img/smile-cdr-logo.png"} style={{height: 100}}/>
                        <p style={{margin: "0 0 60px 0", color: this.props.muiTheme.palette.secondaryTextColor, fontSize: 18}}>
                            Smile CDR is a complete, purpose-built clinical data repository designed around the
                            HL7 FHIR standard that is used for storing health records.
                        </p>
                    </div>
                    : <div>
                        <p style={{margin: "0 0 15px 0", color: this.props.muiTheme.palette.textColor, fontSize: 24, fontWeight: 400, lineHeight: "28px"}}>
                            Welcome to interopiO!
                        </p>
                        <p style={{width: 350, margin: "0 0 15px 0", color: this.props.muiTheme.palette.secondaryTextColor, fontSize: 16, fontWeight: 400, lineHeight: "20px"}}>
                            To get started, create a FHIR CDR. Or explore the demo FHIR CDR.
                        </p>
                        <p style={{margin: "0 0 60px 0"}}>
                            <a href="https://support.interopio.com/hc/en-us" target="_blank" style={{margin: "0 15px 0 0"}}>
                                Support
                            </a>
                        </p>
                    </div>}

                {this.state.status !== "ready"
                    ? <CenteredCircularProgress size={63}/>
                    : <CardsContainer>
                        {actAsAdmin
                            ? <CardWrapper style={{minHeight: 200}}>
                                <CardBodyAddNew data-qa="create-new-cdr-action" text={featureSmileCdrCustomization ? "Create new Smile CDR" : "Add FHIR CDR"}
                                    onClick={() => this.setState({dialog: ":ADD_NEW_WIZARD:"})}/>
                            </CardWrapper>
                            : null}

                        {data.map((item: any, index) =>
                            <CardWrapper key={`card-${index}-${item.cdrId}`} style={{minHeight: 200}}>
                                <CardMenuBar data-qa={`${item.name}-cdr-menu`} title={item.name}
                                    menuItems={actAsAdmin
                                        ? [{leftIcon: <VisibilityIcon/>, text: "View", onClick: () => this.props.history.push(`/${env}/cdrs/${item.cdrId}`)},
                                            {
                                                leftIcon: <DeleteIcon/>,
                                                text: "Delete",
                                                "data-qa": `delete-cdr-${item.name}`,
                                                onClick: () => this.setState({
                                                    dialog: ":DELETE_CONFIRM:",
                                                    delete: {
                                                        cdrId: item.cdrId,
                                                        name: item.name
                                                    }
                                                })
                                            }
                                        ] : []}
                                    style={{paddingBottom: 0}}
                                    onClick={() => this.props.history.push(`/${env}/cdrs/${item.cdrId}`)}/>
                                <CardBody text={item.description} style={{minHeight: 160, paddingTop: 0}} onClick={() => this.props.history.push(`/${env}/cdrs/${item.cdrId}`)}/>
                            </CardWrapper>
                        )}
                    </CardsContainer>}
            </div>
            {this.renderDialogs()}
        </div>;
    }

    private renderDialogs = () => {
        const featureSmileCdrCustomization = features.check(":SMILE_CDR_CUSTOMIZATION:", this.props.features.data);

        switch (this.state.dialog) {
            case ":ADD_NEW_WIZARD:":
                return <CDRAddNewWizard initialize={this.initialize} onClose={() => this.setState({dialog: ""})}/>;
            case ":DELETE_CONFIRM:":
                return <WidgetsDeleteConfirm {...this.props} error={this.state.deleteError} type={featureSmileCdrCustomization ? "Smile CDR" : "FHIR CDR"}
                    delete={this.state.delete} onClose={() => this.setState({dialog: "", delete: {}})}
                    onDelete={async () => {
                        const {accountId} = getHostnameInfo();
                        const {env} = getLocationInfo(this.props.location, this.props.config);
                        const url = getCDRsCallUrl(accountId, env) + "/" + this.state.delete.cdrId;
                        const res = await this.props.cdrsDelete(this.props.config, url);
                        if (res.status < 300) {
                            this.setState({dialog: "", delete: {}}, this.initialize);
                        } else if (res.response?.status === 400) {
                            this.setState({deleteError: res.response.data?.message});
                        }
                    }}/>;
            default:
                return null;
        }
    };

    private initialize = () => {
        const {env} = getLocationInfo(this.props.location, this.props.config);
        this.setState(
            {status: "init"},
            async () => {
                const {accountId} = getHostnameInfo();
                const url = getCDRsCallUrl(accountId, env);
                await this.props.cdrsReadAll(this.props.config, url);
                this.setState({status: "ready"});
            }
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    cdrs: state.cdrs,
    config: state.config,
    features: state.features,
    login: state.login,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdrsDelete: actions.cdrsDelete,
    cdrsReadAll: actions.cdrsReadAll,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(CDRList));
