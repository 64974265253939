import * as EmailValidator from "email-validator";

export default function (email = ""): string {
    let message = "";

    if (!EmailValidator.validate(email)) {
        message = "Please provide a valid email";
    }

    if (!email) {
        message = "The format of email addresses should be `local_part@domain`";
    }
    else {
        if (email.indexOf("@") < 0) {
            message = "The format of email addresses should be `local_part@domain`";
        }
        else {
            const addressParts = email.split("@");
            if (!addressParts[0]) {
                message = "`local_part` cannot be empty.";
            }
            else {
                if (!addressParts[1]) {
                    message = "`domain` cannot be empty.";
                }
                else {
                    const domainParts = addressParts[1].split(".");
                    if (domainParts.length < 2 || !domainParts[domainParts.length - 1]) {
                        message = "Invalid `domain`.";
                    }
                }
            }
        }
    }

    return message;
}
