import type { State } from 'xstate'
import DialogContent from '@material-ui/core/DialogContent'
import CenteredCircularProgress from '../../../../../../Widgets/CenteredCircularProgress/'
import type { TContext as TParentContext } from '../../..'
import type { TContext } from '..'

type TProps = {
    parentContext: TParentContext
    state: State<TContext, any, any, any, any>
}

const CustomDialogContent = (props: TProps) => {
    return (
        <DialogContent
            style={{
                minWidth: '552px',
                padding: '24px',
                background: 'rgb(250, 250, 250)',
            }}
        >
            {props.state.matches('idle') ? (
                <>
                    <span>
                        Are you sure you want to remove
                    </span>
                    &nbsp;
                    <strong data-qa="delete-user-dir-name">
                        {props.parentContext.delete.name}
                    </strong>?
                    <br />
                    <span>
                        This action
                        <strong> CANNOT </strong>
                        be undone!
                    </span>
                </>
            ) : <CenteredCircularProgress size={63} />}
        </DialogContent>
    )
}

export default CustomDialogContent
