import * as PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress/";
import WSteps from "./WSteps";

const CustomDialogContent = (props) => {
    return <DialogContent style={{ padding: 24, background: "rgb(250, 250, 250)" }}>
        <Stepper
            activeStep={props.wstate.wstep - 1}
            style={{ minWidth: 552, padding: 0, background: "rgb(250, 250, 250)" }}
        >
            <Step>
                <StepLabel>Basic Info</StepLabel>
            </Step>
            <Step>
                <StepLabel>Configuration</StepLabel>
            </Step>
            <Step>
                <StepLabel>Summary</StepLabel>
            </Step>
        </Stepper>
        {["ready", "waiting"].includes(props.wstate.status)
            ? <WSteps
                wstate={props.wstate}
                onSetWState={props.onSetWState}
            />
            : <CenteredCircularProgress size={63} style={{ minWidth: 552, marginTop: 32 }} />}
    </DialogContent>
}

CustomDialogContent.propTypes = {
    wstate: PropTypes.object.isRequired,
    onSetWState: PropTypes.func.isRequired,
};

export default CustomDialogContent;
