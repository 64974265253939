import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import {NavbarL3} from "../../../CustomNavbar/";
import AccEditGeneral from "../../Accounts/AccEditGeneral/";
import AccEditUsersAndPermissions from "../../Accounts/AccEditUsersAndPermissions/";
import EnvironmentList from "../../Environments/EnvironmentList/";
import Activity from "../../Accounts/AccActivity";

let TABS = [
    {title: "ENVIRONMENTS"},
    {title: "SETTINGS", "data-qa": "account-settings-tab"},
    {title: "USERS & PERMISSIONS", "data-qa": "users-permissions-tab"},
    {title: "ACTIVITY", "data-qa": "account-activity-tab"}
];
const TAB_SELECTED_BY_DEFAULT = 0;

class Component extends React.Component<any, any> {
    public static propTypes = {
        ui: PropTypes.object.isRequired,
        uiTabsSetItems: PropTypes.func.isRequired,
        uiTabsSetSelected: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.props.uiTabsSetItems(TABS);
        if (this.props.location.search === "?settings=true") {
            this.props.uiTabsSetSelected(1);
        } else {
            this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
        }
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search && this.props.location.search === "?settings=true") {
            this.props.uiTabsSetSelected(1);
        }
    }

    public render() {
        return <div style={{width: "100%"}}>
            <NavbarL3/>
            {this.renderTabContent()}
        </div>;
    }

    private renderTabContent = () => {
        switch (this.props.ui.tabs.selected) {
            case 0:
                return <EnvironmentList/>;
            case 1:
                return <AccEditGeneral/>;
            case 2:
                return <AccEditUsersAndPermissions/>
            case 3:
                return <Activity/>;
            default:
                return null;
        }
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Component));
