import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../../redux/actions";
import getCdsServiceSetsCallUrl from "../../../../../../../lib/getCdsServiceSetsCallUrl";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import CheckIcon from "@material-ui/icons/Check";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const CustomDialogActions = (props) => {
    return <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
        {props.wstate.wstep > props.WSTEP_MIN ? <Button
            disabled={props.wstate.status !== "ready" || !props.wstate.isNextWStepEnabled}
            color="primary" style={{ marginRight: 8 }}
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={() => props.onSetWState({ wstep: props.calcPrevWStep() })}
        >Back</Button> : null}
        {props.wstate.wstep < props.WSTEP_MAX ? <Button
            variant="contained" color="primary" style={{ marginRight: 8 }}
            endIcon={<KeyboardArrowRightIcon />}
            disabled={props.wstate.status !== "ready" || !props.wstate.isNextWStepEnabled}
            onClick={() => props.onSetWState({ wstep: props.calcNextWStep() })}
        >Next</Button>: null}
        {props.wstate.wstep === props.WSTEP_MAX ? <Button
            variant="contained" color="primary" style={{ marginRight: 8 }}
            startIcon={<CheckIcon />}
            disabled={props.wstate.status !== "ready" || !props.wstate.isNextWStepEnabled}
            onClick={async () => {
                props.onSetWState({ status: "waiting" });
                const accountId = props.cdsServiceSets.selected.data.accountId;
                const cdsServiceSetId = props.cdsServiceSets.selected.data.cdsServiceSetId;
                const cdsServiceGatewayActivations = props.wstate.data.cdsServiceGatewayActivations;
                const environmentId = props.cdsServiceSets.selected.data.environmentId;
                const gatewayId = props.wstate.data.gatewayId;
                const name = props.wstate.data.name;
                const activations = [
                    { cdsServiceGatewayActivations, cdsServiceSetId, disabled: false, gatewayId, name },
                    ...props.cdsServiceSets.selected.data.activations,
                ];
                const data = {
                    ...props.cdsServiceSets.selected.data,
                    activations,
                };
                const cdsServiceSetUrl = getCdsServiceSetsCallUrl(accountId, environmentId) + `/${cdsServiceSetId}`;
                const response = await props.cdsServiceSetsUpdate(props.config, cdsServiceSetUrl, data);
                if (response && response.status < 300) {
                    props.initialize();
                }
            }}
        >Add CDS Service Set</Button>: null}
    </DialogActions>;
}

CustomDialogActions.propTypes = {
    cdsServiceSets: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    wstate: PropTypes.object.isRequired,
    WSTEP_MAX: PropTypes.number.isRequired,
    WSTEP_MIN: PropTypes.number.isRequired,
    calcNextWStep: PropTypes.func.isRequired,
    calcPrevWStep: PropTypes.func.isRequired,
    cdsServiceSetsUpdate: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetWState: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    cdsServiceSets: state.cdsServiceSets,
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsUpdate: actions.cdsServiceSetsUpdate,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomDialogActions));
