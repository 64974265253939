import * as React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../../redux/actions";
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

interface IProps {
    activeSessions: any[];
    parentStatus: string;
    setDialog: Function;
    setNewSession: Function;
}

const MILLISECONDS_FACTOR = 60 * 1000;

const AddNewSession: React.FC<IProps> = (props: IProps) => {
    const [date, setDate] = React.useState(new Date(Date.now()));
    const [duration, setDuration] = React.useState(10 * MILLISECONDS_FACTOR);

    const setNewSession = props.setNewSession;
    React.useEffect(() => {
        setNewSession({ date, duration });
    }, [date, duration, setNewSession]);

    const isDisabled = props.parentStatus !== "ready" || Boolean(props.activeSessions.length);

    return (
        <div className="add-new-session">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                    label="Start Date/Time"
                    value={date}
                    disabled={isDisabled}
                    disablePast
                    variant="dialog"
                    format="yyyy-MM-dd HH:mm 'GMT'XXX"
                    style={{ minWidth: 220, marginRight: 24 }}
                    onChange={(newDate) => setDate(new Date(newDate))}
                />
            </MuiPickersUtilsProvider>

            <FormControl style={{ minWidth: 220, marginRight: 24 }}>
                <InputLabel>Duration</InputLabel>
                <Select value={duration} disabled={isDisabled}
                    onChange={(event) => setDuration(Number(event.target.value))}
                >
                    {[
                        { val: 10, unit: "min" },
                        { val: 15, unit: "min" },
                        { val: 20, unit: "min" },
                        { val: 25, unit: "min" },
                        { val: 30, unit: "min" }
                    ].map(({ val, unit }, idx) => {
                        let milliseconds = null;
                        if (unit === "min") {
                            milliseconds = val * MILLISECONDS_FACTOR;
                        }
                        return <MenuItem key={idx} value={milliseconds}>{val}{unit}</MenuItem>;
                    })}
                </Select>
            </FormControl>

            <Button variant="contained" color="primary" disabled={isDisabled}
                style={{ display: "block", width: 244, height: "fit-content", marginTop: 12 }}
                onClick={async () => props.setDialog(":CREATE_SESSION:")}
            >
                Create New Logging Session
            </Button>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    generalMessageSet: actions.generalMessageSet,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNewSession));
