import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../../redux/actions";
import getHostnameInfo from "../../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../../lib/getLocationInfo";
import getCdsServiceSetsCallUrl from "../../../../../../../lib/getCdsServiceSetsCallUrl";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import CheckIcon from "@material-ui/icons/Check";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const CustomDialogActions = (props) => {
    return <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
        {props.parentState.wstep > props.WSTEP_MIN ? <Button
            disabled={props.parentState.status !== "ready"}
            variant="outlined"
            color="primary" style={{ marginRight: 8 }}
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={() => props.onSetParentState({ wstep: props.calcPrevWStep() })}
        >Back</Button> : null}
        {props.parentState.wstep < props.WSTEP_MAX ? <Button
            data-qa="add-cds-next-btn"
            variant="contained" color="primary" style={{ marginRight: 8 }}
            endIcon={<KeyboardArrowRightIcon />}
            disabled={props.parentState.status !== "ready" || !props.parentState.isNextWStepEnabled}
            onClick={() => props.onSetParentState({ wstep: props.calcNextWStep() })}
        >Next</Button>: null}
        {props.parentState.wstep === props.WSTEP_MAX ? <Button data-qa="add-cds-finish-btn"
            variant="contained" color="primary" style={{ marginRight: 8 }}
            startIcon={<CheckIcon />}
            disabled={props.parentState.status !== "ready" || !props.parentState.isNextWStepEnabled}
            onClick={async () => {
                props.onSetParentState({ status: "waiting" });
                const { accountId } = getHostnameInfo();
                const { env } = getLocationInfo(props.location, props.config);
                const data = {
                    accountId,
                    environmentId: env,
                    version: "CDS_SERVICES__1_0",
                    name: props.parentState.data.name,
                    cdsServiceSetId: props.parentState.data.cdsServiceSetId,
                    description: props.parentState.data.description,
                    sourceUrl: props.parentState.data.sourceUrl,
                    cdsServices: props.parentState.data.cdsServices
                };
                const url = getCdsServiceSetsCallUrl(accountId, env) + `/${props.parentState.data.cdsServiceSetId}`;
                const res = await props.cdsServiceSetsCreate(props.config, url, data);
                if (res.status < 300 && res.data) {
                    props.initialize();
                    props.history.push(`/${env}/cds-service-sets/${res.data.cdsServiceSetId}`);
                }
            }}
        >Add CDS Service Set</Button>: null}
    </DialogActions>;
}

CustomDialogActions.propTypes = {
    config: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    WSTEP_MAX: PropTypes.number.isRequired,
    WSTEP_MIN: PropTypes.number.isRequired,
    calcNextWStep: PropTypes.func.isRequired,
    calcPrevWStep: PropTypes.func.isRequired,
    cdsServiceSetsCreate: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsCreate: actions.cdsServiceSetsCreate,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomDialogActions));
