import * as React from "react";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import convertToUrlFriendly from "../../../../lib/convertToUrlFriendly";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import * as validateData from "../../../../lib/validate-accounts-data";
import {Button, Card, CardContent, CardHeader, CardActions, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";

const INIT_STATE = {
    status: "ready", // "ready" | "wait"
    dataValidation: {
        accountName: "",
        accountId: "",
        accountType: "",
        accountProgram: ""
    }
};

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.state.data) !== JSON.stringify(prevProps.state.data)) {
            this.validateData();
        }
    }

    public render() {
        const {domain, type} = getHostnameInfo();

        return <Card style={{width: "480px", marginTop: "48px", marginRight: "auto", marginLeft: "auto", padding: "28px", borderRadius: "8px"}}>
            <CardHeader title={<div>
                <h2 style={{fontSize: "28px", textAlign: "center", margin: "4px 0 0"}}>
                    Name your account
                </h2>
            </div>}/>

            <CardContent>
                <TextField name="accountName" type="text" value={this.props.state.data.accountName || ""} label="Account Name" placeholder="Enter account name"
                    error={!!this.state.dataValidation.accountName} helperText={this.state.dataValidation.accountName} autoComplete="off" fullWidth
                    onChange={e => this.props.onSetState({
                        data: {
                            ...this.props.state.data,
                            accountName: e.target.value,
                            accountId: convertToUrlFriendly(e.target.value.substring(0, 18))
                        }
                    })}/>

                <label className="account-id-wrapper">
                    <TextField style={{ marginTop: "8px" }} name="accountId" type="text" value={this.props.state.data.accountId || ""} label="Account ID" placeholder="your-account-id"
                        error={!!this.state.dataValidation.accountId} helperText={this.state.dataValidation.accountId} autoComplete="off" fullWidth className="text-field"
                        onChange={e => this.props.onSetState({data: {...this.props.state.data, accountId: convertToUrlFriendly(e.target.value.substring(0, 18))}})}/>

                    <span className={`suffix ${this.state.dataValidation.accountId ? "error" : ""}`}>
                        {"." + domain + "." + type}
                    </span>
                </label>

                <FormControl fullWidth style={{ marginTop: "10px" }} error={!!this.state.dataValidation.accountType}>
                    <InputLabel>Account Type</InputLabel>
                    <Select
                        value={this.props.state.data.accountType || ""}
                        onChange={e => this.props.onSetState({data: {...this.props.state.data, accountType: e.target.value}})}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Account Type' }}
                    >
                        <MenuItem value={"INTERNAL"}>Internal</MenuItem>
                        <MenuItem value={"EXTERNAL"}>External</MenuItem>
                        <MenuItem value={"COMMERCIAL"}>Commercial</MenuItem>
                    </Select>
                    {this.state.dataValidation.accountType ? (
                        <FormHelperText>
                            {this.state.dataValidation.accountType}
                        </FormHelperText>
                    ) : null}
                </FormControl>

                {this.props.state.data.accountType !== "COMMERCIAL" ? (
                    <FormControl fullWidth style={{ marginTop: "10px" }} disabled={this.props.state.data.accountType === "COMMERCIAL"} error={!!this.state.dataValidation.accountProgram}>
                        <InputLabel>Account Program</InputLabel>
                        <Select
                            value={this.props.state.data.accountProgram || ""}
                            onChange={e => this.props.onSetState({data: {...this.props.state.data, accountProgram: e.target.value}})}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Account Program' }}
                        >
                            <MenuItem value="G_10">G(10)</MenuItem>
                            <MenuItem value={"MARKETPLACE"}>Marketplace</MenuItem>
                        </Select>
                        {this.state.dataValidation.accountProgram ? (
                            <FormHelperText>
                                {this.state.dataValidation.accountProgram}
                            </FormHelperText>
                        ) : null}
                    </FormControl>
                ) : null}
            </CardContent>

            <CardActions style={{padding: "16px"}}>
                <Button color="primary" fullWidth variant="contained" disabled={!this.props.state.data.accountId || this.state.status !== "ready" || !this.isDataValid()}
                    onClick={() => {
                        this.setState(
                            {status: "wait"},
                            async () => {
                                const data = {
                                    user: {
                                        email: this.props.state.data.email,
                                        password: this.props.state.data.password,
                                        first_name: this.props.state.data.firstName,
                                        last_name: this.props.state.data.lastName,
                                        TOSVersion: this.props.state.data.latestTosVersion
                                    },
                                    account: {
                                        path: this.props.state.data.accountId,
                                        name: this.props.state.data.accountName,
                                        type: this.props.state.data.accountType,
                                        program: this.props.state.data.accountType !== "COMMERCIAL" ? this.props.state.data.accountProgram : ""
                                    }
                                };
                                await this.props.usersCreate(data);
                                this.props.onSetState({wstep: this.props.calcNextWStep()});
                            }
                        );
                    }}>
                    {this.state.status === "wait"
                        ? <CircularProgress size={24} style={{marginTop: "5px"}}/>
                        : "Create"}
                </Button>
            </CardActions>
            <div style={{fontSize: "12px", padding: "16px", lineHeight: "24px"}}>
                By continuing, you’re agreeing to our <Link to="/terms/customer-terms-of-service" target="_blank" style={{fontSize: "12px", fontWeight: 700, textDecoration: "none"}}>Customer
                Terms of Service</Link>, <Link to="/policies/privacy-notice" target="_blank" style={{fontSize: "12px", fontWeight: 700, textDecoration: "none"}}>Privacy Notice</Link>,
                and <Link to="/policies/cookie-policy" target="_blank" style={{fontSize: "12px", fontWeight: 700, textDecoration: "none"}}>Cookie Policy</Link>.
            </div>
        </Card>;
    }

    // Helpers -----------------------------------------------------------------
    private isDataValid = () => Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true);

    private validateData = () => {
        this.setState({status: "wait", dataValidation: {}},
            async () => {
                const dataValidation = await validateData.onWStep4Async(this.props.state, this.props.config);
                this.setState({status: "ready", dataValidation});
            });
    };
}
