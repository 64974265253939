// Accounts
export const ACCOUNTS_ACCOUNT_USERS_SET = "accounts/ACCOUNT_USERS_SET";
export const ACCOUNTS_SELECTED_SET = "accounts/SELECTED_SET";
export const ACCOUNTS_SSO_CONFIG_SET = "accounts/SSO_CONFIG_SET";

// Users
export const USERS_SELECTED_SET = "users/SELECTED_SET";

// Apps
export const APPS_ALL_SET = "apps/ALL_SET";
export const APPS_SELECTED_SET = "apps/SELECTED_SET";
export const APPS_GENERATING_LOG_TOKEN = "apps/GENERATING_LOG_TOKEN";
export const APPS_SET_LOG_TOKEN = "apps/SET_LOG_TOKEN";
export const APPS_SET_LOG_TOKEN_ERROR = "apps/SET_LOG_TOKEN_ERROR";
export const APPS_DELETING_LOG_TOKEN = "apps/DELETING_LOG_TOKEN";
export const APPS_DELETE_LOG_TOKEN = "apps/DELETE_LOG_TOKEN";
export const APPS_DELETE_LOG_TOKEN_ERROR = "apps/DELETE_LOG_TOKEN_ERROR";

// App users
export const APP_USERS_ALL_SET = "appUsers/ALL_SET";
export const APP_USERS_SELECTED_SET = "appUsers/SELECTED_SET";

// CDRs
export const CDRS_ALL_SET = "cdrs/ALL_SET";
export const CDRS_SELECTED_SET = "cdrs/SELECTED_SET";
export const CDRS_SELECTED_IMPORT = "cdrs/SELECTED_IMPORT";

// CDS service sets
export const CDS_SERVICE_SETS_ALL_SET = "cdsServiceSets/ALL_SET";
export const CDS_SERVICE_SETS_SELECTED_SET = "cdsServiceSets/SELECTED_SET";

// Config
export const CONFIG_KNOWLEDGE_BASE_LINKS_SET = "config/KNOWLEDGE_BASE_LINKS_SET";
export const CONFIG_USER_ROLES_SET = "config/USER_ROLES_SET";
export const CONFIG_USER_STATUSES_SET = "config/USER_STATUSES_SET";

// Data sources
export const DATA_SOURCES_ALL_SET = "data-sources/ALL_SET";
export const DATA_SOURCES_SELECTED_SET = "data-sources/SELECTED_SET";

// Environments
export const ENVIRONMENTS_ALL_SET = "environments/ALL_SET";
export const ENVIRONMENTS_SELECTED_SET = "environments/SELECTED_SET";

// Features
export const FEATURES_ALL_SET = "features/ALL_SET";

// Gateways
export const GATEWAYS_ALL_SET = "gateways/ALL_SET";
export const GATEWAYS_SELECTED_SET = "gateways/SELECTED_SET";

// Portals
export const PORTALS_ALL_SET = "portals/ALL_SET";
export const PORTALS_SELECTED_SET = "portals/SELECTED_SET";

// General message
export const GENERAL_MESSAGE_RESET = "generalMessage/RESET";
export const GENERAL_MESSAGE_SET = "generalMessage/SET";

// Login
export const LOGIN_RESET = "login/RESET";
export const LOGIN_SET = "login/SET";

// UI
export const UI_SHOW_NAVBAR = "ui/SHOW_NAVBAR";
export const UI_HIDE_NAVBAR = "ui/HIDE_NAVBAR";
export const UI_LEFT_MENU_PRIVATE_EXPAND = "ui/LEFT_MENU_PRIVATE_EXPAND";
export const UI_LEFT_MENU_PRIVATE_SHRINK = "ui/LEFT_MENU_PRIVATE_SHRINK";
export const UI_LEFT_MENU_PRIVATE_TOGGLE = "ui/LEFT_MENU_PRIVATE_TOGGLE";
export const UI_TABS_SET_ITEMS = "ui/TABS_SET_ITEMS";
export const UI_TABS_SET_SELECTED = "ui/TABS_SELECTED_SET";
export const UI_XTHEME_SET = "ui/XTHEME_SET";
export const UI_UPDATE_TIME_ZONE = "ui/UPDATE_TIME_ZONE";

// User dirs
export const USER_DIRS_ALL_SET = "userDirs/ALL_SET";
export const USER_DIRS_SELECTED_SET = "userDirs/SELECTED_SET";
