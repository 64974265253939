import getLocationInfo from "./getLocationInfo"
import { isAccountOwner } from "./user-roles-helpers"
import { getPath } from "./utils/"

const PERMISSIONS = [
    "analytics",
    "admin",
];

function areUserEnvironmentPermissionsValid(
    userEnvironmentPermissions: any,
): boolean {
    if (!Array.isArray(userEnvironmentPermissions)) {
        return false;
    }
    return true;
}

function getPermissionByEnvironmentId(
    userEnvironmentPermissions: Array<string>,
    environmentId: string,
): string {
    return userEnvironmentPermissions.find((permission: string) => getEnvironmentIdFromPermission(permission) === environmentId) || "";
}

function getAccessFromPermission(permission: string): string {
    return ("" + permission).split(":")[1] || "";
}

function getEnvironmentIdFromPermission(permission: string): string{
    return ("" + permission).split(":")[0];
}

export function addPermission(
    userEnvironmentPermissions: Array<string>,
    environmentId: string,
    access: string,
): Array<string> {
    if (!areUserEnvironmentPermissionsValid(userEnvironmentPermissions)) {
        return userEnvironmentPermissions;
    };

    const rule: string = `${environmentId}:${access}`;
    const updatedUserEnvironmentPermissions: Array<string> = [...userEnvironmentPermissions];
    updatedUserEnvironmentPermissions.push(rule);

    return updatedUserEnvironmentPermissions;
}

export function deletePermissionByEnvironmentId(
    userEnvironmentPermissions: Array<string>,
    environmentId: string,
): Array<string> {
    if (!areUserEnvironmentPermissionsValid(userEnvironmentPermissions)) {
        return userEnvironmentPermissions;
    };

    return userEnvironmentPermissions.filter((permission) => getEnvironmentIdFromPermission(permission) !== environmentId);
}

export function hasAccessNone(
    userEnvironmentPermissions: Array<string>,
    environmentId: string,
): boolean {
    if (!areUserEnvironmentPermissionsValid(userEnvironmentPermissions)) {
        return true;
    };

    const permission: string = getPermissionByEnvironmentId(userEnvironmentPermissions, environmentId);
    if (!permission) {
        return true;
    }

    const access: string = getAccessFromPermission(permission)
    if (PERMISSIONS.indexOf(access) < 0) {
        return true;
    }

    return false;
}

export function hasAccessAnalytics(
    userEnvironmentPermissions: Array<string>,
    environmentId: string,
): boolean {
    if (!areUserEnvironmentPermissionsValid(userEnvironmentPermissions)) {
        return false;
    };

    const permission: string = getPermissionByEnvironmentId(userEnvironmentPermissions, environmentId);
    if (!permission) {
        return false;
    }

    const access: string = getAccessFromPermission(permission)
    if (access === "analytics") {
        return true;
    }

    return false;
}

export function hasAccessAdmin(
    userEnvironmentPermissions: Array<string>,
    environmentId: string,
): boolean {
    if (!areUserEnvironmentPermissionsValid(userEnvironmentPermissions)) {
        return false;
    };

    const permission: string = getPermissionByEnvironmentId(userEnvironmentPermissions, environmentId);
    if (!permission) {
        return false;
    }

    const access: string = getAccessFromPermission(permission)
    if (access === "admin") {
        return true;
    }

    return false;
}

export function isEnvironmentAdmin(
    login: any,
    location,
    config: any,
): boolean {
    if (isAccountOwner(login, config)) {
        return true;
    }

    const environments: Array<string> | undefined = getPath(login, "data.environments");
    let environmentId: string = "";
    if (typeof location === "string") {
        environmentId = location;
    }
    else {
        const loc = getLocationInfo(location, config);
        environmentId = loc.env;
    }
    if (hasAccessAdmin(environments, environmentId)) {
        return true;
    }


    return false;
}
