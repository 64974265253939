import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../../redux/actions";
import getAppUsersCallUrl from "../../../../../../../lib/getAppUsersCallUrl";
import getHostnameInfo from "../../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../../lib/getLocationInfo";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import CheckIcon from "@material-ui/icons/Check";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const CustomDialogActions = (props) => {
    return <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
        {props.wstate.wstep > props.WSTEP_MIN ? <Button data-qa="action-btn-back"
            disabled={props.wstate.status !== "ready" || !props.wstate.isNextWStepEnabled}
            color="primary" style={{ marginRight: 8 }}
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={() => props.onSetWState({ wstep: props.calcPrevWStep() })}
        >Back</Button> : null}
        {props.wstate.wstep < props.WSTEP_MAX ? <Button data-qa="action-btn-next"
            variant="contained" color="primary" style={{ marginRight: 8 }}
            endIcon={<KeyboardArrowRightIcon />}
            disabled={props.wstate.status !== "ready" || !props.wstate.isNextWStepEnabled}
            onClick={() => props.onSetWState({ wstep: props.calcNextWStep() })}
        >Next</Button>: null}
        {props.wstate.wstep === props.WSTEP_MAX ? <Button data-qa="action-btn-add-app-user"
            variant="contained" color="primary" style={{ marginRight: 8 }}
            startIcon={<CheckIcon />}
            disabled={props.wstate.status !== "ready" || !props.wstate.isNextWStepEnabled || !!props.wstate.data.__temp__fhirId}
            onClick={async () => {
                props.onSetWState({ status: "waiting" });
                const { accountId } = getHostnameInfo();
                const { directoryId, env } = getLocationInfo(props.location, props.config);
                const data = {
                    accountId,
                    dirId: directoryId,
                    disabled: false,
                    environmentId: env,
                    name: props.wstate.data.name,
                    email: props.wstate.data.email,
                    password: props.wstate.data.password,
                    appUserType: props.wstate.data.appUserType,
                    identifierMappings: props.wstate.data.identifierMappings || []
                };
                const url = getAppUsersCallUrl(accountId, env, directoryId);
                const response = await props.appUsersCreate(props.config, url, data)
                if (response && response.status < 300) {
                    props.initialize();
                }
            }}
        >Add User</Button>: null}
    </DialogActions>;
}

CustomDialogActions.propTypes = {
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    wstate: PropTypes.object.isRequired,
    WSTEP_MAX: PropTypes.number.isRequired,
    WSTEP_MIN: PropTypes.number.isRequired,
    calcNextWStep: PropTypes.func.isRequired,
    calcPrevWStep: PropTypes.func.isRequired,
    appUsersCreate: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetWState: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appUsersCreate: actions.appUsersCreate,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomDialogActions));
