import moment from "moment";
import * as React from "react";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import getAppUsersCallUrl from "../../../../lib/getAppUsersCallUrl";
import getCDRsCallUrl from "../../../../lib/getCDRsCallUrl";
import getDataSourcesCallUrl from "../../../../lib/getDataSourcesCallUrl";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import getUserDirsCallUrl from "../../../../lib/getUserDirsCallUrl";
import Snackbar from '@material-ui/core/Snackbar';
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import {NavbarL2, NavbarL3} from "../../../CustomNavbar/";
import Selector from "../../../CustomNavbar/Selector";
import Details from "./Details/";
import Dialogs from "./Dialogs/";
import Users from "./Users/";

const TABS = [
    {title: "DETAILS", "data-qa": "auth-details-tab"},
    {title: "USERS", "data-qa": "auth-users-tab"}
];

const TAB_SELECTED_BY_DEFAULT = 0;

const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "waiting" | "recalc"
    data: {},
    dataValidation: {},
    appUsers: [],
    selectedAppUser: {},
    filters: {
        appUser: ""
    },
    sortBy: {
        name: true
    },
    mode: ":VIEW:", // ":VIEW:" | ":EDIT:"
    etab: null,
    dialog: "", // "" | ":ADD_APP_USER:" | ":EDIT_APP_USER:" | ":APP_USER_AS_FHIR:" | ":DELETE_APP_USER_CONFIRM:"
    snackbar: {
        open: false,
        message: "",
        autoHideDuration: 4000,
    },
};

let appUserTimer = null;

class UserDirEdit extends React.Component<any, any> {
    public static propTypes = {
        appUsers: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,
        userDirs: PropTypes.object.isRequired,
        appUsersReadAll: PropTypes.func.isRequired,
        cdrsReadAll: PropTypes.func.isRequired,
        dataSourcesReadAll: PropTypes.func.isRequired,
        uiTabsSetItems: PropTypes.func.isRequired,
        uiTabsSetSelected: PropTypes.func.isRequired,
        userDirsRead: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.props.uiTabsSetItems(TABS);
        if (this.props.ui.tabs.selected !== TAB_SELECTED_BY_DEFAULT) {
            this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
        } else {
            this.initialize();
        }
    }

    public async componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.ui.tabs.selected !== TAB_SELECTED_BY_DEFAULT) {
                return this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
            }
            return this.initialize();
        }

        const selectedTab = this.props.ui?.tabs?.selected;
        const prevSelectedTab = prevProps.ui?.tabs?.selected;
        if (selectedTab !== prevSelectedTab) {
            return this.initialize();
        }

        const filters_string = JSON.stringify(this.state.filters);
        const prevFilters_string = JSON.stringify(prevState.filters);
        const areFiltersChanged = filters_string !== prevFilters_string;
        const sortBy_string = JSON.stringify(this.state.sortBy);
        const prevSortBy_string = JSON.stringify(prevState.sortBy);
        const isSortTermChanged = sortBy_string !== prevSortBy_string;
        if (areFiltersChanged || isSortTermChanged) {
            if (this.state.status !== "recalc") {
                this.setState({status: "recalc"});
            }
            const appUsers = await this.getAppUsers();
            this.setState({
                status: "ready",
                appUsers,
            });
        }
    }

    public render() {
        const {env, directoryId: selectedUserDir} = getLocationInfo(this.props.location, this.props.config);

        return <div style={{width: "100%"}}>
            <NavbarL2 title={(
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{height: 56, marginRight: 24}}>
                        <Link to={`/${env}/auth`} style={{color: "#fff", fontSize: 24, textDecoration: "none"}}>
                            Authentication
                        </Link>
                    </div>
                    <Selector data-qa-label="navbar-user-dir-selector-name" data-qa="selected-user-dir"
                        value={selectedUserDir}
                        icon={<SupervisorAccountIcon style={{ color: "rgba(255, 255, 255, 0.87)" }} />}
                        data={this.props.userDirs.all ? this.props.userDirs.all.data.map((item) => ({id: item.directoryId, name: item.name})) : []}
                        onChange={item => this.props.history.push(`/${env}/auth/${item.id}`)}
                    />
                </div>
            )}/>

            <NavbarL3 disableAll={this.state.status !== "ready"}/>

            <div className="container">
                {["ready", "waiting", "recalc"].includes(this.state.status)
                    ? this.renderTabContent()
                    : <CenteredCircularProgress size={63} style={{padding: 24}}/>
                }
            </div>
            <br/>

            {["ready", "waiting", "recalc"].includes(this.state.status)
                ? <Dialogs parentState={this.state} initialize={this.initialize} onSetParentState={this.onSetState} />
                : null
            }

            <Snackbar
                open={this.state.snackbar.open}
                message={this.state.snackbar.message}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={this.state.snackbar.autoHideDuration}
                ContentProps={{ style: { backgroundColor: this.props.ui.xtheme.palette.colorBlueDark, display: "flex", flexDirection: "row", justifyContent: "center" } }}
                onClose={() => this.setState({ snackbar: { open: false, message: "" } })}
            />
        </div>;
    }

    private renderTabContent = () => {
        const contentProps = {
            parentState: this.state,
            initialize: this.initialize,
            onSetParentState: this.onSetState,
        };

        switch (this.props.ui.tabs.selected) {
            case 0:
                return <Details {...contentProps} />;
            case 1:
                return <Users {...contentProps} />;
            default:
                return null;
        }
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private getFilteredAppUsers(appUsers = []) {
        return appUsers.filter((appUser) => {
            const filter = (this.state?.filters?.appUser || "").toLowerCase();
            const appUserName = (appUser.name || "").toLowerCase();
            const isNameMatch = appUserName.includes(filter);
            const appUserEmail = (appUser.email || "").toLowerCase();
            const isEmailMatch = appUserEmail.includes(filter);
            const appUserId = (appUser.uid || "").toLowerCase();
            const isIdMatch = appUserId.includes(filter);
            return isNameMatch || isEmailMatch || isIdMatch;
        });
    };

    private getSortedAppUsers(appUsers = []) {
        const sortBy = this.state.sortBy;
        let sorted = [...appUsers];

        // Following DRY (Don't-Repeat-Yourself) principle we have to combine the
        // following code into one helper method but more or less
        // this implementation is experimental and its purpose is to
        // demonstrate the basic idea.
        // Later on, the sorting functionality should become much more advanced.

        // Sort by "Name"
        if (sortBy.name !== undefined) {
            if (sortBy.name === true) {
                sorted.sort((a, b) => {
                    const nameA = ("" + a.name).toLowerCase();
                    const nameB = ("" + b.name).toLowerCase();
                    if (nameA < nameB) return -1;
                    else if (nameA > nameB) return 1;
                    else return 0;
                });
            } else if (sortBy.name === false) {
                sorted.sort((a, b) => {
                    const nameA = ("" + a.name).toLowerCase();
                    const nameB = ("" + b.name).toLowerCase();
                    if (nameA < nameB) return 1;
                    else if (nameA > nameB) return -1;
                    else return 0;
                });
            }
        }

        // Sort by "Created"
        if (sortBy.createdAt !== undefined) {
            if (sortBy.createdAt === true) {
                sorted.sort((a, b) => {
                    const createdAtA = moment(a.created || null).isValid()
                        ? moment(a.created).unix()
                        : null;
                    const createdAtB = moment(b.created || null).isValid()
                        ? moment(b.created).unix()
                        : null;
                    if (createdAtA < createdAtB) return -1;
                    else if (createdAtA > createdAtB) return 1;
                    else return 0;
                });
            } else if (sortBy.createdAt === false) {
                sorted.sort((a, b) => {
                    const createdAtA = moment(a.created || null).isValid()
                        ? moment(a.created).unix()
                        : null;
                    const createdAtB = moment(b.created || null).isValid()
                        ? moment(b.created).unix()
                        : null;
                    if (createdAtA < createdAtB) return 1;
                    else if (createdAtA > createdAtB) return -1;
                    else return 0;
                });
            }
        }

        // Sort by "Last login"
        if (sortBy.lastLogin !== undefined) {
            if (sortBy.lastLogin === true) {
                sorted.sort((a, b) => {
                    const lastLoginA = moment(a.lastLogin || null).isValid()
                        ? moment(a.lastLogin).unix()
                        : null;
                    const lastLoginB = moment(b.lastLogin || null).isValid()
                        ? moment(b.lastLogin).unix()
                        : null;
                    if (lastLoginA < lastLoginB) return -1;
                    else if (lastLoginA > lastLoginB) return 1;
                    else return 0;
                });
            } else if (sortBy.lastLogin === false) {
                sorted.sort((a, b) => {
                    const lastLoginA = moment(a.lastLogin || null).isValid()
                        ? moment(a.lastLogin).unix()
                        : null;
                    const lastLoginB = moment(b.lastLogin || null).isValid()
                        ? moment(b.lastLogin).unix()
                        : null;
                    if (lastLoginA < lastLoginB) return 1;
                    else if (lastLoginA > lastLoginB) return -1;
                    else return 0;
                });
            }
        }

        return sorted;
    }

    private _getAppUsers = () => {
        let appUsers = [...this.props?.appUsers?.all?.data || []];
        appUsers = this.getFilteredAppUsers(appUsers);
        appUsers = this.getSortedAppUsers(appUsers);
        return appUsers;
    };

    private getAppUsers = () => {
        clearTimeout(appUserTimer);
        return new Promise((resolve) => {
            appUserTimer = setTimeout(() => resolve(this._getAppUsers()), 550);
        });
    };

    private initialize = async () => {
        this.setState({status: "init"});
        const {accountId} = getHostnameInfo();
        const {env, directoryId} = getLocationInfo(this.props.location, this.props.config);
        const userDirUrl = getUserDirsCallUrl(accountId, env) + "/" + directoryId;
        const appUsersUrl = getAppUsersCallUrl(accountId, env, directoryId);
        const cdrsUrl = getCDRsCallUrl(accountId, env);
        const dataSourcesUrl = getDataSourcesCallUrl(accountId, env);
        await Promise.all([
            this.props.userDirsRead(this.props.config, userDirUrl),
            this.props.appUsersReadAll(this.props.config, appUsersUrl),
            this.props.cdrsReadAll(this.props.config, cdrsUrl),
            this.props.dataSourcesReadAll(this.props.config, dataSourcesUrl)
        ])
        const appUsers = await this.getAppUsers();
        this.setState({
            ...INIT_STATE,
            status: "ready",
            data: this.props.userDirs.selected.data,
            appUsers,
        });
    };
}

const mapStateToProps = (state, ownProps) => ({
    appUsers: state.appUsers,
    config: state.config,
    ui: state.ui,
    userDirs: state.userDirs,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appUsersReadAll: actions.appUsersReadAll,
    cdrsReadAll: actions.cdrsReadAll,
    dataSourcesReadAll: actions.dataSourcesReadAll,
    uiTabsSetItems: actions.uiTabsSetItems,
    uiTabsSetSelected: actions.uiTabsSetSelected,
    userDirsRead: actions.userDirsRead,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UserDirEdit);
