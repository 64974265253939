import * as React from "react";
import * as PropTypes from "prop-types";
import axiosWrapper from "../../../../../../lib/axiosWrapper";
import {getPath} from "../../../../../../lib/utils";
import consoleLogger from "../../../../../../lib/consoleLogger";
import {Button} from "@material-ui/core";

const Component: React.FunctionComponent<any> = props => {
    const content = [
        <Button variant="outlined" key="user-environment-permissions-cancel" style={{marginRight: "8px"}} onClick={props.onCancel}>
            Cancel
        </Button>,
        <Button variant="contained" data-qa="users-permissions-env-permissions-save-button" key="user-environment-permissions-save" color="secondary"
            disabled={props.dialogState.status !== "ready"}
            onClick={() => {
                props.onSetDialogState(
                    {status: "wait"},
                    () => {
                        const uuid = getPath(props, "state.selectedUser.uuid");
                        const environments = props.dialogState.userEnvironmentPermissions;
                        axiosWrapper(props.config.accountApi, `users/${uuid}/update-env-permission`, "POST", {environments})
                            .then(props.initialize)
                            .catch(reason => {
                                props.onCancel();
                                consoleLogger.log("::: reason:", reason);
                            });
                    }
                );
            }}>
            Save
        </Button>
    ];

    return <div>{content}</div>;
}

Component.propTypes = {
    config: PropTypes.object.isRequired,
    dialogState: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSetDialogState: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired
};

export default Component;
