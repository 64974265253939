import * as React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import getLocationInfo from "../../../../../../../../../lib/getLocationInfo";
import Alert from "@material-ui/lab/Alert";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

interface IProps {
    config: any;
    environments: any;
    location: any;
    newSession: any;
    parentStatus: string;
}

const CustomDialogContent: React.FC<IProps> = (props: IProps) => {
    const { env } = getLocationInfo(props.location, props.config);
    const isEnvPhiEnabled = Boolean(props.environments.all.data.find(({ environmentId }) => environmentId === env).phi);

    return (
        <DialogContent style={{ minWidth: 552, padding: 24, background: "rgb(250, 250, 250)" }}>
            <Alert severity="warning">
                By enabling this option, we are going to be logging and storing any PHI data being exchanged on every API call.
                {isEnvPhiEnabled ? <strong> You may choose to delete the logged PHI data.</strong> : null}
                &nbsp;The session will expire in 14 days.
            </Alert>
            {props.parentStatus === "ready"
                ? (
                    <Typography gutterBottom style={{ marginTop: 16 }}>
                        <strong>Are you sure you want to create the following logging session?</strong>
                    </Typography>
                ) : null}

            <LinearProgress style={{ opacity: props.parentStatus !== "ready" ? 0.33 : 0, transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)" }} />
            <Table size="small" padding="none" style={{ opacity: props.parentStatus !== "ready" ? 0.33 : 1, transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)" }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Start Date/Time</TableCell>
                        <TableCell align="center">End Date/Time</TableCell>
                        <TableCell align="center">Expires at</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="center">
                            {moment(props.newSession.date).format("YYYY-MM-DD HH:mm Z")}
                        </TableCell>
                        <TableCell align="center">
                            {moment(props.newSession.date)
                                .add(props.newSession.duration, 'milliseconds').format("YYYY-MM-DD HH:mm Z")}
                        </TableCell>
                        <TableCell align="center">
                            {moment(props.newSession.date)
                                .add(props.newSession.duration, 'milliseconds')
                                .add(14, 'days')
                                .format("YYYY-MM-DD HH:mm Z")}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </DialogContent>
    );
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    environments: state.environments,
    ...ownProps,
});
export default withRouter(connect(mapStateToProps)(CustomDialogContent));
