import { useEffect, useState } from 'react';
import * as PropTypes from "prop-types";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../redux/actions";

import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../lib/getLocationInfo";
import getCdsServiceSetsCallUrl from "../../../../../../lib/getCdsServiceSetsCallUrl";

import Dialog from "@material-ui/core/Dialog";
import CustomDialogActions from "./CustomDialogActions";
import CustomDialogContent from "./CustomDialogContent";
import CustomDialogTitle from "./CustomDialogTitle";

interface IState {
    status: "" | "ready" | "init" | "waiting";
    data: any;
}

const INIT_STATE: IState = {
    status: "",
    data: {},
};

const ActivationDeleteConfirmation = (props) => {
    const [state, setState] = useState(INIT_STATE);

    const { cdsServiceSetsRead } = props;
    useEffect(() => {
        (async () => {
            setState((prevState) => ({ ...prevState, status: "init" }));
            const { accountId } = getHostnameInfo();
            const { env, cdsServiceSetId } = getLocationInfo(props.location, props.config);
            const cdsServiceSetUrl = getCdsServiceSetsCallUrl(accountId, env) + "/" + cdsServiceSetId;
            const { data: activationData} = await cdsServiceSetsRead(props.config, cdsServiceSetUrl);
            setState((prevState) => ({
                ...prevState,
                status: "ready",
                data: activationData,
            }));
        })();
    }, [cdsServiceSetsRead, props.config, props.location]);

    return <Dialog
        open disableBackdropClick disableEscapeKeyDown scroll="body"
        onClose={props.onClose}
    >
        <CustomDialogTitle onClose={props.onClose} />
        <CustomDialogContent
            dstate={state}
            parentState={props.parentState}
        />
        <CustomDialogActions
            dstate={state}
            parentState={props.parentState}
            initialize={props.initialize}
            onClose={props.onClose}
            onSetDState={setState}
        />
    </Dialog >
}

ActivationDeleteConfirmation.propTypes = {
    location: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,

    cdsServiceSetsRead: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsRead: actions.cdsServiceSetsRead,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivationDeleteConfirmation));

