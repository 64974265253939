import $ from "jquery";
import * as types from "../../actions/types";
import initialState from "./initial-state";

export default function (state = initialState, action: any) {
    switch (action.type) {
        case types.FEATURES_ALL_SET: {
            return $.extend(true, {}, state, action.payload);
        }

        default:
            return state;
    }
}
