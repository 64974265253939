import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../../../redux/actions'
import isUrl from 'is-url'
import { createMachine, assign } from 'xstate'
import { useMachine } from '@xstate/react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import axiosWrapper from '../../../../../lib/axiosWrapper'
import withLegacyTheme from '../../../../../lib/hoc/with-legacy-theme'
import convertToUrlFriendly from '../../../../../lib/convertToUrlFriendly'
import getGatewaysCallUrl from '../../../../../lib/getGatewaysCallUrl'
import getHostnameInfo from '../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../lib/getLocationInfo'
import * as validateId from '../../../../../lib/validate-id'
import CenteredCircularProgress from '../../../../Widgets/CenteredCircularProgress'
import WStep1 from './WStep1'
import WStep2 from './WStep2'
import WStep3 from './WStep3'
import WStep4 from './WStep4'

export type TSource = Partial<{
    id: string
    name: string
    endpoint: string
    userStrategy: string
    gatewayAuthenticationTypeOptions: string[]
    authenticationType: string
    cdr: boolean
}>

type TProps = {
    accounts: {
        selected?: {
            data?: {
                tier: string,
            },
        },
    }
    cdrs: any
    config: any
    dataSources: any
    history: any
    location: any
    muiTheme: any
    gatewaysReadAll: Function
    onClose: Function
}

export type TContext = {
    step: number
    data: {
        accountId: string
        environmentId: string
        name: string
        gatewayId: string
        description: string
        configCacheMinutes: 0 | 1
        gatewayType: '' | 'FHIR_DSTU2__1_0_2' | 'FHIR_STU3__3_0_2' | 'FHIR_R4__4_0_1' | 'HL7_V2'
        hl7Enabled: boolean
        configuration?: {
            type: 'GatewayConfigurationFhirDstu2' | 'GatewayConfigurationFhirStu3' | 'GatewayConfigurationFhirR4' | 'GatewayConfigurationDefault'
            defaultDataSource: string
            overrides: any[]
        }
        authenticationType: string
        userDirectory: {
            directoryId: string
        }
        hl7Properties?: {
            messageTypes: string
            url: string
            urlAuthType: 'NONE' | 'CLIENT_CREDENTIALS'
            clientId: string
            clientSecret: string
            clientAuthUrl: string
        },
        oktaJwkUrl?: string
    }
    dataValidation: Partial<{
        name: string
        gatewayId: string
        gatewayType: string
        hl7Url: string
        oktaJwkUrl: string
    }>
    gtwyConfig: any
    shallowGateways: any[]
    userDirsSlim: any[]
}

type TEvent = { type: 'SET_NAME'; name: string }
    | { type: 'SET_GATEWAY_ID'; gatewayId: string }
    | { type: 'SET_DESCRIPTION'; description: string }
    | { type: 'SET_CONFIG_CACHE_MINUTES'; configCacheMinutes: boolean }
    | { type: 'SET_HL7_ENABLED'; hl7Enabled: boolean }
    | { type: 'SET_GATEWAY_TYPE'; gatewayType: TContext['data']['gatewayType'] }
    | { type: 'SET_DEFAULT_DATA_SOURCE'; defaultDataSource: string }
    | { type: 'SET_AUTH_TYPE'; authenticationType: string }
    | { type: 'SET_USER_DIR'; directoryId: string }
    | { type: 'SET_HL7_MESSAGE_TYPES'; messageTypes: string }
    | { type: 'SET_HL7_URL'; url: string }
    | { type: 'SET_HL7_URL_AUTH_TYPE'; urlAuthType: 'NONE' | 'CLIENT_CREDENTIALS' }
    | { type: 'SET_HL7_CLIENT_ID'; clientId: string }
    | { type: 'SET_HL7_CLIENT_SECRET'; clientSecret: string }
    | { type: 'SET_HL7_CLIENT_AUTH_URL'; clientAuthUrl: string }
    | { type: 'SET_OKTA_JWK_URL'; oktaJwkUrl: string }
    | { type: 'PREV_STEP' }
    | { type: 'NEXT_STEP' }
    | { type: 'SAVE' }

function getSources(
    context: TContext,
    type: TContext['data']['configuration']['type'] | '',
): TSource[] {
    return (context.gtwyConfig[type] || [])
        .map((it) => ({
            ...it,
            id: it.cdr ? `cdr:${it.id}` : it.id,
        }))
}

const GatewayAddNewWizard = (props: TProps) => {
    const { accountId } = getHostnameInfo()
    const { env } = getLocationInfo(props.location, props.config)

    function isDataValid(context: TContext) {
        return Object.values(context.dataValidation)
            .reduce((acc, value) => acc && !value, true)
    }

    const [state, send] = useMachine(() => createMachine({
        id: 'add-new-gateway-wizard',
        tsTypes: {} as import('./index.typegen').Typegen0,
        schema: {
            context: {} as TContext,
            events: {} as TEvent,
        },
        initial: 'init',
        context: {
            step: 0,
            data: {
                accountId,
                environmentId: env,
                name: '',
                gatewayId: '',
                description: '',
                configCacheMinutes: 0,
                gatewayType: '',
                hl7Enabled: false,
                authenticationType: 'NONE',
                userDirectory: {
                    directoryId: '',
                },
                hl7Properties: {
                    messageTypes: '',
                    url: '',
                    urlAuthType: 'NONE',
                    clientId: '',
                    clientSecret: '',
                    clientAuthUrl: '',
                },
                oktaJwkUrl: ''
            },
            dataValidation: {},
            gtwyConfig: {},
            shallowGateways: [],
            userDirsSlim: [],
        },
        states: {
            init: {
                entry: assign({ step: 0 }),
                invoke: {
                    src: 'init',
                    onDone: {
                        target: 'basic_info',
                        actions: assign({
                            shallowGateways: (_, event) => event.data?.[0]?.data || [],
                            gtwyConfig: (_, event) => event.data?.[1]?.data || {},
                            userDirsSlim: (_, event) => event.data?.[2]?.data || [],
                        }),
                    },
                    onError: 'basic_info',
                },
            },
            basic_info: {
                entry: [
                    assign({ step: 1 }),
                    'validateDataBasicInfo',
                ],
                on: {
                    SET_NAME: {
                        actions: [
                            'setName',
                            'validateDataBasicInfo',
                        ],
                    },
                    SET_GATEWAY_ID: {
                        actions: [
                            'setGatewayId',
                            'validateDataBasicInfo',
                        ],
                    },
                    SET_DESCRIPTION: {
                        actions: 'setDescription',
                    },
                    NEXT_STEP: {
                        target: 'config',
                        cond: 'isDataValid',
                    },
                }
            },
            config: {
                entry: [
                    assign({ step: 2 }),
                    'validateDataConfig',
                ],
                on: {
                    SET_CONFIG_CACHE_MINUTES: {
                        actions: 'setConfigCacheMinutes',
                    },
                    SET_HL7_ENABLED: {
                        actions: 'setHl7Enabled',
                    },
                    SET_GATEWAY_TYPE: {
                        actions: [
                            'setGatewayType',
                            'validateDataConfig',
                        ],
                    },
                    SET_DEFAULT_DATA_SOURCE: {
                        actions: 'setDefaultDataSource',
                    },
                    SET_AUTH_TYPE: {
                        actions: [
                            'setAuthType',
                            'validateOktaJwkUrl',
                        ],
                    },
                    SET_OKTA_JWK_URL: {
                        actions: [
                            'setOktaJwkUrl',
                            'validateOktaJwkUrl',
                        ],
                    },
                    SET_USER_DIR: {
                        actions: 'setUserDir',
                    },
                    PREV_STEP: 'basic_info',
                    NEXT_STEP: [
                        {
                            target: 'hl7Props',
                            cond: 'isDataValidAndHl7Enabled',
                        },
                        {
                            target: 'summary',
                            cond: 'isDataValidAndHl7Disabled',
                        },
                    ],
                }
            },
            hl7Props: {
                entry: [
                    assign({ step: 3 }),
                    'validateHl7Props',
                ],
                on: {
                    SET_HL7_MESSAGE_TYPES: {
                        actions: 'setHl7MessageTypes',
                    },
                    SET_HL7_URL: {
                        actions: [
                            'setHl7Url',
                            'validateHl7Props',
                        ],
                    },
                    SET_HL7_URL_AUTH_TYPE: {
                        actions: 'setHl7UrlAuthType',
                    },
                    SET_HL7_CLIENT_ID: {
                        actions: 'setHl7ClientId',
                    },
                    SET_HL7_CLIENT_SECRET: {
                        actions: 'setHl7ClientSecret',
                    },
                    SET_HL7_CLIENT_AUTH_URL: {
                        actions: 'setHl7ClientAuthUrl',
                    },
                    PREV_STEP: 'config',
                    NEXT_STEP: 'summary',
                }
            },
            summary: {
                entry: assign({ step: 4 }),
                on: {
                    PREV_STEP: [
                        {
                            target: 'hl7Props',
                            cond: 'isDataValidAndHl7Enabled',
                        },
                        {
                            target: 'config',
                            cond: 'isDataValidAndHl7Disabled',
                        },
                    ],
                    SAVE: 'saving'
                },
            },
            saving: {
                entry: assign({ step: 0 }),
                invoke: {
                    src: 'save',
                    onDone: 'summary',
                    onError: 'summary',
                },
            },
        },
    }, {
        actions: {
            setName: assign({
                data: (context, event) => ({
                    ...context.data,
                    name: event.name,
                    gatewayId: convertToUrlFriendly(event.name.substring(0, 18)),
                }),
            }),
            setGatewayId: assign({
                data: (context, event) => ({
                    ...context.data,
                    gatewayId: convertToUrlFriendly(event.gatewayId.substring(0, 18)),
                }),
            }),
            setDescription: assign({
                data: (context, event) => ({
                    ...context.data,
                    description: event.description,
                }),
            }),
            validateDataBasicInfo: assign({
                dataValidation: (context) => {
                    const name = context.data.name ? '' : 'Required field'

                    const ids = context.shallowGateways.map((item) => item.gatewayId)
                    let gatewayId = ''
                    gatewayId = validateId.isUrlFriendly(context.data.gatewayId) ? gatewayId : 'The ID can only contain: lower case letters of the English alphabet, numbers (0-9), hyphen/minus sign (-), it must start with a letter and end with a letter or number, not a hyphen'
                    gatewayId = validateId.isNotTooLong(context.data.gatewayId) ? gatewayId : 'This ID is too long'
                    gatewayId = validateId.isNotTooShort(context.data.gatewayId) ? gatewayId : 'This ID is too short'
                    gatewayId = context.data.gatewayId ? gatewayId : 'Required field'
                    gatewayId = validateId.isUnique(context.data.gatewayId, ids) ? gatewayId : 'This ID already exists'
                    gatewayId = validateId.isNotRestricted(context.data.gatewayId, props.config.reservedIds) ? gatewayId : 'This ID is reserved'

                    return {
                        name,
                        gatewayId,
                    }
                },
            }),
            setConfigCacheMinutes: assign({
                data: (context, event) => ({
                    ...context.data,
                    configCacheMinutes: (event.configCacheMinutes ? 1 : 0) as 0 | 1,
                }),
            }),
            setHl7Enabled: assign({
                data: (context, event) => ({
                    ...context.data,
                    hl7Enabled: event.hl7Enabled,
                }),
            }),
            setGatewayType: assign({
                data: (context, event) => ({
                    ...context.data,
                    gatewayType: event.gatewayType,
                    hl7Enabled: false,
                    configuration: {
                        type: ((): TContext['data']['configuration']['type'] => {
                            switch (event.gatewayType) {
                                case 'FHIR_DSTU2__1_0_2': return 'GatewayConfigurationFhirDstu2'
                                case 'FHIR_STU3__3_0_2': return 'GatewayConfigurationFhirStu3'
                                case 'FHIR_R4__4_0_1': return 'GatewayConfigurationFhirR4'
                                case 'HL7_V2': return 'GatewayConfigurationDefault'
                                default: return 'GatewayConfigurationDefault'
                            }
                        })(),
                        defaultDataSource: '',
                        overrides: [],
                    },
                }),
            }),
            setDefaultDataSource: assign({
                data: (context, event) => {
                    let id = event.defaultDataSource
                    if (id === ':NO_DEFAULT_SOURCE:') {
                        id = ''
                    }
                    const type = context.data.configuration?.type
                    const sources = getSources(context, type)
                    const defaultSourceObject = sources.find((src) => src.id === id) || {}
                    const gatewayAuthenticationTypeOptions = defaultSourceObject.gatewayAuthenticationTypeOptions;
                    let authenticationType = 'NONE';
                    if (gatewayAuthenticationTypeOptions?.includes(context.data.authenticationType)) {
                        // This will catch selected or previously selected items
                        authenticationType = context.data.authenticationType;
                    } else if (gatewayAuthenticationTypeOptions?.length > 0) {
                        // This will catch when a user previously selected a value then switched the default
                        // data adapter type which does not have the previously selected auth type as an option
                        authenticationType = gatewayAuthenticationTypeOptions[0];
                    }

                    return {
                        ...context.data,
                        authenticationType,
                        gatewayAuthenticationTypeOptions,
                        configuration: {
                            ...context.data.configuration,
                            defaultDataSource: id,
                        },
                    }
                },
            }),
            setAuthType: assign({
                data: (context, event) => ({
                    ...context.data,
                    authenticationType: event.authenticationType,
                }),
            }),
            setOktaJwkUrl: assign({
                data: (context, event) => ({
                    ...context.data,
                    oktaJwkUrl: event.oktaJwkUrl,
                }),
            }),
            setUserDir: assign({
                data: (context, event) => ({
                    ...context.data,
                    userDirectory: {
                        directoryId: event.directoryId
                    }
                }),
            }),
            validateOktaJwkUrl: assign({
                dataValidation: (context): TContext['dataValidation'] => {
                    let oktaJwkUrl = ''
                    if (context.data.authenticationType === "OKTA_JWK") {
                        if (!context.data.oktaJwkUrl) {
                            oktaJwkUrl = 'Required field'
                        }
                        else if (!isUrl(context.data.oktaJwkUrl)) {
                            oktaJwkUrl = 'Invalid URL'
                        }
                    }
                    return {
                        oktaJwkUrl,
                    }
                }
            }),
            validateDataConfig: assign({
                dataValidation: (context): TContext['dataValidation'] => {
                    const gatewayType = context.data.gatewayType ? '' : 'Required field'
                    return {
                        gatewayType,
                    }
                }
            }),
            setHl7MessageTypes: assign({
                data: (context, event) => ({
                    ...context.data,
                    hl7Properties: {
                        ...context.data.hl7Properties,
                        messageTypes: event.messageTypes
                    },
                }),
            }),
            setHl7Url: assign({
                data: (context, event) => ({
                    ...context.data,
                    hl7Properties: {
                        ...context.data.hl7Properties,
                        url: event.url
                    }
                }),
            }),
            setHl7UrlAuthType: assign({
                data: (context, event) => ({
                    ...context.data,
                    hl7Properties: {
                        ...context.data.hl7Properties,
                        urlAuthType: event.urlAuthType
                    }
                }),
            }),
            setHl7ClientId: assign({
                data: (context, event) => ({
                    ...context.data,
                    hl7Properties: {
                        ...context.data.hl7Properties,
                        clientId: event.clientId
                    }
                }),
            }),
            setHl7ClientSecret: assign({
                data: (context, event) => ({
                    ...context.data,
                    hl7Properties: {
                        ...context.data.hl7Properties,
                        clientSecret: event.clientSecret
                    }
                }),
            }),
            setHl7ClientAuthUrl: assign({
                data: (context, event) => ({
                    ...context.data,
                    hl7Properties: {
                        ...context.data.hl7Properties,
                        clientAuthUrl: event.clientAuthUrl
                    }
                }),
            }),
            validateHl7Props: assign({
                dataValidation: (context: TContext): TContext['dataValidation'] => {
                    let hl7Url = ''
                    if (context.data.hl7Enabled) {
                        if (!context.data.hl7Properties?.url) {
                            hl7Url = 'Required field'
                        }
                        else if (!isUrl(context.data.hl7Properties.url)) {
                            hl7Url = 'Invalid URL'
                        }
                    }
                    return {
                        hl7Url,
                    }
                }
            }),
        },
        guards: {
            isDataValid: (context) => isDataValid(context),
            isDataValidAndHl7Enabled: (context) => isDataValid(context) && context.data.hl7Enabled,
            isDataValidAndHl7Disabled: (context) => isDataValid(context) && !context.data.hl7Enabled,
        },
        services: {
            init: () => {
                const gwsUrl = getGatewaysCallUrl(accountId, env)
                const gtwyConfigUrl = `api/${accountId}/env/${env}/gtwy-configuration`
                const udsSlimUrl = `api/${accountId}/env/${env}/user-directory-slim`
                return Promise.all([
                    axiosWrapper(props.config.envApi, `${gwsUrl}?shallow=true`, 'GET'),
                    axiosWrapper(props.config.envApi, gtwyConfigUrl, 'GET'),
                    axiosWrapper(props.config.envApi, udsSlimUrl, 'GET'),
                ])
            },
            save: async (context) => {
                const queueName = getQueueName()
                const hl7Enabled = context.data.hl7Enabled
                const hl7Properties = hl7Enabled ? {
                    ...context.data.hl7Properties,
                    accountId,
                    environmentId: env,
                    gatewayId: context.data.gatewayId,
                    queueName,
                } : null
                const userDirectory = (context.data.userDirectory.directoryId && context.data.userDirectory.directoryId !== 'NONE') ? {
                    accountId,
                    environmentId: env,
                    directoryId: context.data.userDirectory.directoryId,
                } : null
                let data = {
                    ...context.data,
                    accountId,
                    environmentId: env,
                    hl7Enabled,
                    hl7Properties,
                    userDirectory,
                }
                if (context.data.gatewayType === 'HL7_V2') {
                    data = {
                        ...data,
                        authenticationType: 'NONE',
                    }
                }
                const gwUrl = getGatewaysCallUrl(accountId, env) + '/' + context.data.gatewayId
                const res = await axiosWrapper(props.config.envApi, gwUrl, 'PUT', data)
                if (res?.status < 300 && res?.data) {
                    const gwsUrl = getGatewaysCallUrl(accountId, env)
                    props.gatewaysReadAll(props.config, gwsUrl)
                    const url = `/${env}/gateways/${res.data.gatewayId}`
                    props.history.push(url)
                }
            },
        },
    }))

    const tier = (props.accounts.selected?.data?.tier || '').toLowerCase()
    const tierNum = Number(tier.split(' ')[1])
    const isTier3Plus = tierNum >= 3

    function getQueueName() {
        return `iosqs_${accountId}_${env}_${state.context.data.gatewayId}`.substring(0, 80)
    }

    return (
        <Dialog
            open
            classes={{ paper: 'io-modal-wrapper' }}
            style={{ paddingTop: '5px', zIndex: 1250 }}
            onClose={() => props.onClose()}
        >
            <DialogTitle
                style={{
                    height: '48px',
                    background: props.muiTheme.palette.primary1Color,
                    color: '#fff',
                    lineHeight: '48px',
                    fontSize: '24px',
                    fontWeight: 600,
                }}
            >
                <div style={{ lineHeight: '48px' }}>
                    <span data-qa="add-new-gateway-modal-title">
                        Add a Gateway
                    </span>
                    <Tooltip
                        title="Close"
                        classes={{
                            popper: 'io-tooltip-root',
                            tooltip: 'io-tooltip',
                        }}
                    >
                        <IconButton
                            style={{
                                position: 'absolute',
                                top: '18px',
                                right: '24px',
                            }}
                            onClick={() => props.onClose()}
                        >
                            <CloseIcon style={{ color: 'rgba(255, 255, 255, 0.85)' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    background: 'rgb(250, 250, 250)',
                    padding: '0 0 24px 0',
                }}
            >
                <Stepper
                    activeStep={state.context.step - 1}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <Step>
                        <StepLabel>Basic Info</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Configuration</StepLabel>
                    </Step>
                    {isTier3Plus &&
                        <Step>
                            <StepLabel>HL7 properties</StepLabel>
                        </Step>
                    }
                    <Step>
                        <StepLabel>Summary</StepLabel>
                    </Step>
                </Stepper>
                {!state.context.step
                    ? <CenteredCircularProgress size={63} style={{ padding: '24px' }} />
                    : (
                        <>
                            {state.matches('basic_info') ? (
                                <WStep1
                                    state={state}
                                    send={send}
                                />
                            ): null}
                            {state.matches('config') ? (
                                <WStep2
                                    state={state}
                                    send={send}
                                    getSources={getSources}
                                />
                            ): null}
                            {state.matches('hl7Props') ? (
                                <WStep3
                                    state={state}
                                    send={send}
                                    getQueueName={getQueueName}
                                />
                            ): null}
                            {state.matches('summary') ? (
                                <WStep4
                                    state={state}
                                    getQueueName={getQueueName}
                                    getSources={getSources}
                                />
                            ): null}
                        </>
                    )
                }
            </DialogContent>
            <DialogActions style={{ backgroundColor: 'rgb(250, 250, 250)' }}>
                {state.can('PREV_STEP') ? (
                    <Button
                        variant="outlined"
                        style={{ marginRight: '8px' }}
                        onClick={() => {
                            send('PREV_STEP')
                        }}
                    >
                        <KeyboardArrowLeftIcon />
                        &nbsp;Back
                    </Button>
                ) : null}
                {state.can('NEXT_STEP') ? (
                    <Button
                        data-qa="add-new-gateway-next-btn"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            send('NEXT_STEP')
                        }}
                    >
                        Next&nbsp;
                        <KeyboardArrowRightIcon />
                    </Button>
                ) : null}
                {state.can('SAVE') ? (
                    <Button
                        data-qa="add-new-gateway-create-btn"
                        key="btn-create-new"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            send('SAVE')
                        }}
                    >
                        <CheckIcon/> Create Gateway
                    </Button>
                ): null}
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state, ownProps) => ({
    accounts: state.accounts,
    cdrs: state.cdrs,
    config: state.config,
    dataSources: state.dataSources,
    ...ownProps,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    gatewaysReadAll: actions.gatewaysReadAll,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(GatewayAddNewWizard)))
