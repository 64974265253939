import * as PropTypes from "prop-types";
import withLegacyTheme from "../../../../../../lib/hoc/with-legacy-theme";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import SearchIcon from "@material-ui/icons/Search";
import AddBoxIcon from "@material-ui/icons/AddBox";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {capitalizeFirstLetter} from "../../../../../../lib/utils";
import Table from "../../../../../Widgets/Table";
import "./style.less";

const Operations = (props) => {
    const total = `${props.state.resources.length} resources`;

    const columns = [
        {
            name: "type",
            size: 2,
            label: "Resource",
            sort: () => props.onSetParentState({sortBy: {["type"]: !props.state.sortBy.type}}),
            order: props.state.sortBy.type,
        },
        {
            name: "read",
            size: 1,
            label: "Read",
            sort: () => props.onSetParentState({sortBy: {["read"]: !props.state.sortBy.read}}),
            order: props.state.sortBy.read,
        },
        {
            name: "search",
            size: 1,
            label: "Search",
            sort: () => props.onSetParentState({sortBy: {["search"]: !props.state.sortBy.search}}),
            order: props.state.sortBy.search,
        },
        {
            name: "create",
            size: 1,
            label: "Create",
            sort: () => props.onSetParentState({sortBy: {["create"]: !props.state.sortBy.create}}),
            order: props.state.sortBy.create,
        },
        {
            name: "update",
            size: 1,
            label: "Update",
            sort: () => props.onSetParentState({sortBy: {["update"]: !props.state.sortBy.update}}),
            order: props.state.sortBy.update,
        },
        {
            name: "delete",
            size: 1,
            label: "Delete",
            sort: () => props.onSetParentState({sortBy: {["delete"]: !props.state.sortBy.delete}}),
            order: props.state.sortBy.delete,
        }
    ];

    const resourcesValues = [
        {
            value: ":ALL:",
            label: "All resources",
            onClick: () => props.onSetParentState({filters: {...props.state.filters, resources: ":ALL:"}})
        },
        {
            value: ":SUPPORTED:",
            label: "Supported resources",
            onClick: () => props.onSetParentState({filters: {...props.state.filters, resources: ":SUPPORTED:"}})
        },
        {
            value: ":UNSUPPORTED:",
            label: "Unsupported resources",
            onClick: () => {
                const operations = {...props.state.filters.operations};
                Object.keys(operations).forEach((key) => operations[key] = false);
                props.onSetParentState({filters: {...props.state.filters, resources: ":UNSUPPORTED:", operations}});
            }
        }
    ];

    const filteredOperations = Object.keys(props.state.filters.operations).filter(key => props.state.filters.operations[key]);
    let labelOperations = "Operations";
    if (filteredOperations.length) {
        labelOperations = capitalizeFirstLetter(filteredOperations[0]);
        if (filteredOperations.length > 1) {
            labelOperations += ` + ${filteredOperations.length - 1}`;
        }
    }

    const checkAll = (set) => {
        const operations = {...props.state.filters.operations};
        Object.keys(operations).forEach((key) => operations[key] = set);
        props.onSetParentState({
            filters: {
                ...props.state.filters,
                operations
            }
        });
    }

    const filters = [
        {
            name: "resources",
            title: "Resources",
            type: "radio",
            checked: props.state.filters.resources,
            isActive: props.state.filters.resources !== resourcesValues[0].value,
            label: resourcesValues.find(i => i.value === props.state.filters.resources).label,
            values: resourcesValues,
            onDelete: () => props.onSetParentState({filters: {...props.state.filters, resources: resourcesValues[0].value}})
        },
        {
            type: "multiple",
            name: "operations",
            title: "Operations",
            checkAllLabel: "Select All Operations",
            label: labelOperations,
            isActive: filteredOperations.length > 0,
            checked: props.state.filters.operations,
            values: Object.keys(props.state.filters.operations),
            allSelected: filteredOperations.length === Object.keys(props.state.filters.operations).length,
            checkAll: val => checkAll(val),
            onDelete: () => checkAll(false),
            onClick: key => props.onSetParentState({filters: {...props.state.filters, operations: {...props.state.filters.operations, [key]: !props.state.filters.operations[key]}}})
        }
    ];

    return <Table columns={columns} total={total} rightFilters={filters} state={props.state}>
        {props.state.resources.map((resource, index) => <div key={index} className="operation-table-row">
            <div>
                <strong>{resource.type}</strong>
            </div>
            <div>
                <InsertDriveFileIcon style={{color: resource.read ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}}/>
            </div>
            <div>
                <SearchIcon style={{color: resource.search ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}}/>
            </div>
            <div>
                <AddBoxIcon style={{color: resource.create ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}}/>
            </div>
            <div>
                <EditIcon style={{color: resource.update ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}}/>
            </div>
            <div>
                <DeleteIcon style={{color: resource.delete ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}}/>
            </div>
        </div>
        )}
    </Table>;
};

Operations.propTypes = {
    state: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    onSetParentState: PropTypes.func.isRequired,
};

export default withLegacyTheme()(Operations);
