const isUrl: Function = require("is-url");

const PATH: string = "/epic/2018/Clinical/Oncology/GetPatientStagingDataEpic/Clinical/Oncology/Staging/GetPatientData/false";

export default function (url: string = ""): string {
    if (
        !url
        || typeof url !== "string"
        || !isUrl(url)
        || !(url.startsWith("http://") || url.startsWith("https://"))
    ) {
        return "";
    }

    let result: string = url.trim().replace(/\/$/, "");

    const index: number = url.indexOf("/FHIR/");
    if (index > -1) {
        return result.substr(0, index) + PATH;
    }

    return result.split("/").slice(0, 4).join("/") + PATH;
}
