import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import CustomDialogActions from './CustomDialogActions'
import CustomDialogContent from './CustomDialogContent'
import CustomDialogTitle from './CustomDialogTitle'
import type {
    TApp,
} from '../../../../../../types'
import { TLaunch } from '../../Launches'
import axiosWrapper from '../../../../../../lib/axiosWrapper'

export type TStatus = ':READY:' | ':PENDING:'
export type TEvent = {
    id: string
    gateway_id: string | null
    session_id: string | null
    maxTime: number | null
    minTime: number | null
    timeToLaunch: number | null
    auth_principal_username: string | null
    launch_context: string | null
}

type TParentState = {
    status: string
    selectedAppLaunch?: TLaunch
}

type TProps = {
    parentState: TParentState
    accounts: any
    apps: {
        selected: {
            data?: TApp
        }
    }
    config: any
    environments: any
    muiTheme: any
    ui: any
    onSetState: Function
    onClose: () => void
}

const AppLaunchInspect: React.FC<TProps> = (props: TProps) => {
    const [status, setStatus] = React.useState<TStatus>(':READY:')
    const [events, setEvents] = React.useState<TEvent[]>([])
    const fetchData = React.useCallback(async () => {
        setStatus(':PENDING:')

        const {
            accountId,
            environmentId,
            clientId,
        } = props.apps.selected.data

        const { session_id } = props.parentState.selectedAppLaunch


        let query = {
            clientId: clientId,
            accountId: accountId,
            environmentId: environmentId,
            sessionId: session_id
        }

        await axiosWrapper(props.config.analyticsService, "launch-get", "POST", query).then(async (res) => {
            let data = []
            res.data.hits.hits.forEach((event) => {
                let source = event._source
                source.id = event._id
                data.push(source)
            });


            setEvents(data)
            setStatus(':READY:')
        });
    }, [
        props
    ])

    // fetch data
    React.useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Dialog
            open
            disableBackdropClick
            disableEscapeKeyDown
            scroll="body"
            onClose={() => props.onSetState({dialog: ""})}
            maxWidth="xl"
        >
            <CustomDialogTitle onClose={() => props.onSetState({dialog: ""})} />
            <CustomDialogContent events={events} {...props}/>
            <CustomDialogActions
                dstatus={status}
                parentState={props.parentState}
                onCancel={() => props.onSetState({dialog: ""})}
                onClose={() => props.onSetState({dialog: ""})}
                setDStatus={setStatus}
            />
        </Dialog>
    )
}

const mapStateToProps = (state, ownProps) => ({
    accounts: state.accounts,
    apps: state.apps,
    config: state.config,
    environments: state.environments,
    ui: state.ui,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(AppLaunchInspect))
