import axiosWrapper from "../../lib/axiosWrapper"

import initialState from "../reducers/config/initial-state";

import * as types from "./types";

export function configReadKnowledgeBaseLinks(config) {
    return async function (dispatch: Function) {
        try {
            const response = await axiosWrapper(config.configApi, "knowledge-base-links.json", "GET", null, { withCredentials: false });
            dispatch({
                type: types.CONFIG_KNOWLEDGE_BASE_LINKS_SET,
                payload: response.data,
            });
            return response;
        }
        catch (reason) {
            dispatch({
                type: types.CONFIG_KNOWLEDGE_BASE_LINKS_SET,
                payload: { ...initialState.knowledgeBaseLinks },
            });
        }
    };
}

export function configUserRolesReadAll(config) {
    return async function (dispatch: Function) {
        try {
            const response = await axiosWrapper(config.accountApi, "user-roles", "GET");
            dispatch({
                type: types.CONFIG_USER_ROLES_SET,
                payload: response.data,
            });
            return response;
        }
        catch (reason) {
            dispatch({
                type: types.CONFIG_USER_ROLES_SET,
                payload: { ...initialState.userRoles },
            });
        }
    };
}

export function configUserStatusesReadAll(config) {
    return async function (dispatch: Function) {
        try {
            const response = await axiosWrapper(config.accountApi, "user-status", "GET");
            dispatch({
                type: types.CONFIG_USER_STATUSES_SET,
                payload: response.data,
            });
            return response;
        }
        catch (reason) {
            dispatch({
                type: types.CONFIG_USER_STATUSES_SET,
                payload: { ...initialState.userStatuses },
            });
        }
    };
}
