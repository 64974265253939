import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../../redux/actions";
import moment from "moment";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";

interface IProps {
    activeSessions: any[];
    allSessions: any[];
    parentStatus: string;
    generalMessageSet: Function;
    setDialog: Function;
    setSelectedSessions: Function;
}

const Sessions: React.FC<IProps> = (props: IProps) => {
    const isDisabled = props.parentStatus !== "ready";

    return (
        <div className="sessions">
            {props.allSessions.length ? (
                <div className="items-wrapper">
                    <LinearProgress style={{ opacity: isDisabled ? 0.33 : 0, transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)" }} />
                    <Table size="small" padding="none" className="items" style={{ opacity: props.parentStatus !== "ready" ? 0.33 : 1 }}>
                        <TableHead>
                            <TableRow className="item item-head">
                                <TableCell align="center" className="id-cell">Session ID</TableCell>
                                <TableCell align="center" className="date-cell">Start</TableCell>
                                <TableCell align="center" className="date-cell">End</TableCell>
                                <TableCell align="center" className="creator-cell">Created by</TableCell>
                                <TableCell align="center" className="date-cell">Expires</TableCell>
                                <TableCell align="center" className="actions-cell">
                                    <Tooltip title="Delete all">
                                        <Button variant="outlined" size="small" color="secondary" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                            onClick={() => {
                                                props.setSelectedSessions(props.allSessions);
                                                props.setDialog(":DELETE_SESSIONS:");
                                            }}
                                        >
                                            Delete All
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.allSessions.map((session) => {
                                const isActiveSession = props.activeSessions.map(({ sessionId }) => sessionId).includes(session.sessionId);
                                return (
                                    <TableRow key={session.sessionId} className="item" style={{ backgroundColor: isActiveSession ? "rgb(100, 181, 246, 0.1)" : null }}>
                                        <TableCell align="center" className="id-cell">
                                            <Tooltip title="Copy to clipboard">
                                                <Button variant="outlined" size="small" color={isActiveSession ? "primary" : "default"}
                                                    style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                                    onClick={() => {
                                                        let container = document.createElement("textarea");
                                                        container.style.display = "hidden";
                                                        container.innerHTML = session.sessionId;
                                                        document.body.appendChild(container);
                                                        container.select();
                                                        document.execCommand("copy");
                                                        document.body.removeChild(container);
                                                        container = null;
                                                        props.generalMessageSet({
                                                            key: session.sessionId,
                                                            type: ":INFO_SNACKBAR:",
                                                            title: "Copied to Clipboard",
                                                            message: session.sessionId,
                                                            autoHideDuration: 2400,
                                                        });
                                                    }}
                                                >
                                                    {session.sessionId.length > 10 ? session.sessionId.substr(0, 10) + "..." : session.sessionId}
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="center">
                                            {moment(session.startDateTime).format("YYYY-MM-DD HH:mm Z")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {moment(session.endDateTime).format("YYYY-MM-DD HH:mm Z")}
                                        </TableCell>
                                        <TableCell align="center" className="creator-cell">
                                            {session.sessionCreator}
                                        </TableCell>
                                        <TableCell align="center">
                                            {moment(session.startDateTime).add(14, 'days').format("YYYY-MM-DD HH:mm Z")}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton disabled={isDisabled} size="small" color="secondary" className="fade"
                                                onClick={() => {
                                                    props.setSelectedSessions([session]);
                                                    props.setDialog(":DELETE_SESSIONS:");
                                                }}
                                            >
                                                <Tooltip title="Delete"><DeleteIcon /></Tooltip>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            ) : (
                <Typography align="center">
                    {!isDisabled ? "There aren't any logging sessions available for this gateway" : null}
                </Typography>
            )}
        </div>
    );
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    generalMessageSet: actions.generalMessageSet,
}, dispatch);
export default connect(null, mapDispatchToProps)(Sessions);
