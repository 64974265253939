import * as React from "react";
import * as PropTypes from "prop-types";
import {CircularProgress, Button} from "@material-ui/core";

const Component: React.FunctionComponent<any> = props => {
    return <div style={{
        height: "auto",
        padding: 24,
        textAlign: "right",
        transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
    }}>
        <Button variant="contained" color="primary" disabled={!props.actAsAdmin || !props.isValid || props.parentState.status !== "ready"}
            style={{display: "inline-block"}}
            onClick={() => {
                props.sendImport(props.parentState.importString)
            }}>
            {props.parentState.status === "wait" ? <CircularProgress size={24}/> : null} Import
        </Button>
    </div>;
}

Component.propTypes = {
    config: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    actAsAdmin: PropTypes.bool.isRequired,
    onSetParentState: PropTypes.func.isRequired,
    sendImport: PropTypes.func.isRequired,
    isValid: PropTypes.bool
};

Component.defaultProps = {
    isValid: true
};

export default Component;
