import * as React from 'react'
import * as PropTypes from 'prop-types'
import * as validateData from '../../../../../../lib/validate-users-data'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import EditIcon from '@material-ui/icons/Edit'
import Header from '../../../../../Widgets/Editor/Header/'
import ActionButtons from './ActionButtons'

// TODO: TProps and TState ...
class BasicInfo extends React.Component<any, any> {
    public static propTypes = {
        muiTheme: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired,
    }

    public componentDidMount() {
        this.validateData()
    }

    public componentDidUpdate(prevProps) {
        if (
            this.props.state.data.first_name !== prevProps.state.data.first_name
            || this.props.state.data.last_name !== prevProps.state.data.last_name
        ) {
            this.validateData()
        }
    }

    public render() {
        const canEdit = this.props.state.mode === ':EDIT_BASIC_INFO:'
        const modeView = this.props.state.mode === ':VIEW:'
        const isValid = Object.keys(this.props.state.dataValidation).reduce((acc, key) => acc && !this.props.state.dataValidation[key], true)

        return (
            <Card style={{ marginTop: '48px' }}>
                <Header
                    {...this.props}
                    leftIcon={<AccountCircleIcon />}
                    primaryText="Basic Info"
                    rightIcon={!canEdit ? (
                        <Tooltip title="Edit">
                            <IconButton
                                data-qa="user-profile-basic-info-edit-button"
                                disabled={!modeView}
                                onClick={() => {
                                    this.props.onSetState({ mode: ':EDIT_BASIC_INFO:' })
                                }}
                            >
                                <EditIcon style={{ color: this.props.muiTheme.palette.secondaryTextColor }} />
                            </IconButton>
                        </Tooltip>
                    ): null}
                />

                <div style={{ padding: '24px' }}>
                    {this.renderName(canEdit)}
                </div>

                <ActionButtons
                    {...this.props}
                    canEdit={canEdit}
                    isValid={isValid}
                />
            </Card>
        )
    }

    private renderName = (canEdit) => {
        return canEdit
            ? (
                <div style={{ display: 'flex' }}>
                    <TextField
                        data-qa="user-profile-basic-info-first-name-input"
                        name="firstName"
                        value={this.props.state.data.first_name || ''}
                        error={!!this.props.state.dataValidation.first_name}
                        helperText={!!this.props.state.dataValidation.first_name}
                        label="Your First Name"
                        placeholder="Enter your first name"
                        fullWidth
                        style={{ marginRight: '8px' }}
                        onChange={(event) => {
                            this.props.onSetState((prevState) => ({
                                ...prevState,
                                data: {
                                    ...prevState.data,
                                    first_name: event.target.value,
                                },
                            }))
                        }}
                    />
                    <TextField
                        data-qa="user-profile-basic-info-last-name-input"
                        name="lastName"
                        value={this.props.state.data.last_name || ''}
                        error={!!this.props.state.dataValidation.last_name}
                        helperText={!!this.props.state.dataValidation.last_name}
                        label="Your Last Name"
                        placeholder="Enter your last name"
                        fullWidth
                        style={{ marginLeft: '8px' }}
                        onChange={(event) => {
                            this.props.onSetState((prevState) => ({
                                ...prevState,
                                data: {
                                    ...prevState.data,
                                    last_name: event.target.value,
                                },
                            }))
                        }}
                    />
                </div>
            ) : (
                <TextField
                    name="name"
                    value={`${this.props.state.data.first_name} ${this.props.state.data.last_name}`}
                    disabled
                    label="Your Name"
                    fullWidth
                    classes={{ root: 'disabled-text-field' }}
                    style={{ cursor: 'default' }}
                />
            )
    }

    private validateData = () => {
        const dataValidation = validateData.onEditBasicInfo(this.props.state)
        this.props.onSetState({ dataValidation })
    }
}

export default BasicInfo
