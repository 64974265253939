import * as React from "react";
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as actions from "../../redux/actions";
import {getMonthFirstFullWrittenDateDisplayNoTimezone, getDateDisplayNoTimezone} from "../../lib/utils";
import {Link} from "react-router-dom";
import SanitizeHtml from "../Widgets/SanitizeHtml/";
import withLegacyTheme from "../../lib/hoc/with-legacy-theme";
import CenteredCircularProgress from "../Widgets/CenteredCircularProgress/";
import "./style.less";

const sanitizeHtml = require("sanitize-html");

const INIT_STATE = {
    status: "init", // "" | "ready" | "init" | "wait",
    title: "",
    date: "",
    document: "",
    all: []
};


class Legal extends React.Component<any, any> {
    public static propTypes = {
        history: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired
    }

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.getAllDocuments();
        this.init();
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            document.querySelector(".container-legal").scrollTop = 0;
            this.init();
        }
    }

    public render() {
        if (["ready"].indexOf(this.state.status) < 0) {
            return <div className="container">
                <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
            </div>;
        }

        return <div className="container-legal">
            <div style={{height: "140px", minHeight: "140px", backgroundColor: "#eceff1", boxShadow: "none", alignItems: "center"}}>
                <div data-qa="legal-title" style={{color: this.props.muiTheme.palette.textColor, fontWeight: 700, fontSize: "36px", textAlign: "center", lineHeight: "140px"}}>
                    {this.state.title}
                </div>
            </div>
            <div style={{maxWidth: "1200px", margin: "0 auto", display: "flex", padding: "48px"}}>
                <div style={{marginRight: "50px", flexGrow: 1}}>
                    <h4 style={{fontSize: "14px", fontWeight: 700, margin: "0 0 16px"}}>
                        Terms
                    </h4>
                    <Link data-qa="legal-nav-cust-tos" style={{
                        display: "block",
                        fontSize: "12px",
                        textDecoration: "none",
                        marginBottom: "16px",
                        color: this.state.document === "CUST_TOS" ? this.props.muiTheme.palette.primary1color : this.props.muiTheme.palette.secondaryTextColor
                    }} to="/terms/customer-terms-of-service">Customer Terms of Service</Link>
                    <Link data-qa="legal-nav-user-tos" style={{
                        display: "block",
                        fontSize: "12px",
                        textDecoration: "none",
                        marginBottom: "16px",
                        color: this.state.document === "USER_TOS" ? this.props.muiTheme.palette.primary1color : this.props.muiTheme.palette.secondaryTextColor
                    }} to="/terms/user-terms-of-service">User Terms of Service</Link>
                    <Link data-qa="legal-nav-supl-tos" style={{
                        display: "block",
                        fontSize: "12px",
                        textDecoration: "none",
                        marginBottom: "16px",
                        color: this.state.document === "SUPL_TOS" ? this.props.muiTheme.palette.primary1color : this.props.muiTheme.palette.secondaryTextColor
                    }} to="/terms/customer-specific-supplement">Customer-Specific Supplement</Link>
                    <Link data-qa="legal-nav-sla-tos" style={{
                        display: "block",
                        fontSize: "12px",
                        textDecoration: "none",
                        marginBottom: "16px",
                        color: this.state.document === "SLA_TOS" ? this.props.muiTheme.palette.primary1color : this.props.muiTheme.palette.secondaryTextColor
                    }} to="/terms/service-level-agreement">Service Level Agreement</Link>
                    <h4 style={{fontSize: "14px", fontWeight: 700, margin: "32px 0 16px"}}>
                        Policies
                    </h4>
                    <Link data-qa="legal-nav-privacy-pol" style={{
                        display: "block",
                        fontSize: "12px",
                        textDecoration: "none",
                        marginBottom: "16px",
                        color: this.state.document === "PRIVACY_POL" ? this.props.muiTheme.palette.primary1color : this.props.muiTheme.palette.secondaryTextColor
                    }} to="/policies/privacy-notice">interopiO® Privacy Notice</Link>
                    <Link data-qa="legal-nav-acceptable-pol" style={{
                        display: "block",
                        fontSize: "12px",
                        textDecoration: "none",
                        marginBottom: "16px",
                        color: this.state.document === "ACCEPTABLE_POL" ? this.props.muiTheme.palette.primary1color : this.props.muiTheme.palette.secondaryTextColor
                    }} to="/policies/acceptable-use-policy">Acceptable Use Policy</Link>
                    <Link data-qa="legal-nav-data-req-pol" style={{
                        display: "block",
                        fontSize: "12px",
                        textDecoration: "none",
                        marginBottom: "16px",
                        color: this.state.document === "DATA_REQ_POL" ? this.props.muiTheme.palette.primary1color : this.props.muiTheme.palette.secondaryTextColor
                    }} to="/policies/data-request-policy">Data Request Policy</Link>
                    <Link data-qa="legal-nav-data-sec-pol" style={{
                        display: "block",
                        fontSize: "12px",
                        textDecoration: "none",
                        marginBottom: "16px",
                        color: this.state.document === "SECURITY_POL" ? this.props.muiTheme.palette.primary1color : this.props.muiTheme.palette.secondaryTextColor
                    }} to="/policies/security-practices">interopiO® Security Practices</Link>
                    <h4 style={{fontSize: "14px", fontWeight: 700, margin: "32px 0 16px"}}>
                        Archives
                    </h4>
                    <Link data-qa="legal-nav-archive" style={{
                        display: "block",
                        fontSize: "12px",
                        textDecoration: "none",
                        marginBottom: "16px",
                        color: this.state.document === "ARCHIVE" ? this.props.muiTheme.palette.primary1color : this.props.muiTheme.palette.secondaryTextColor
                    }} to="/legal/archives">{"Terms & Policies Archives"}</Link>
                </div>
                <div data-qa="legal-content" style={{width: "650px", flexGrow: 3}}>
                    {this.renderContent()}
                </div>
            </div>
        </div>;
    }

    private getAllDocuments: Function = () => {
        let all = [];
        this.props.tosGetAll().then((terms) => {
            all = terms.data
            this.setState({
                status: "ready",
                all
            }, this.init());
        });
    }

    private init: Function = () => {
        let {document, title, date} = this.getDocumentAndTitle(this.props.location.pathname)
        this.setState({
            document,
            title,
            date
        })
    }

    private getDocumentAndTitle: Function = path => {
        let document = "";
        let title = "";
        let date = "";
        let pathname = path
        if (path.split("/").length - 1 > 2) {
            pathname = path.slice(0, path.lastIndexOf("/"));
            date = path.slice(path.lastIndexOf("/") + 1);
        }
        switch (pathname) {
            case "/legal":
                document = "CUST_TOS"
                title = "Customer Terms of Service"
                break;
            case "/policies":
                document = "CUST_POLICIES"
                title = "interopiO Policies"
                break;
            case "/terms/customer-terms-of-service":
                document = "CUST_TOS"
                title = "Customer Terms of Service"
                break;
            case "/terms/user-terms-of-service":
                document = "USER_TOS"
                title = "User Terms of Service"
                break;
            case "/terms/customer-specific-supplement":
                document = "SUPL_TOS"
                title = "Customer-Specific Supplement"
                break;
            case "/terms/service-level-agreement":
                document = "SLA_TOS"
                title = "Service Level Agreement"
                break;
            // case "/policies/privacy-policy":
            //     document = "PRIVACY_POL"
            //     title = "interopiO® Privacy Notice"
            //     break;
            case "/policies/privacy-notice":
                document = "PRIVACY_POL"
                title = "interopiO® Privacy Notice"
                break;
            case "/policies/acceptable-use-policy":
                document = "ACCEPTABLE_POL"
                title = "Acceptable Use Policy"
                break;
            case "/policies/data-request-policy":
                document = "DATA_REQ_POL"
                title = "Data Request Policy"
                break;
            case "/policies/cookie-policy":
                document = "COOKIE_POL"
                title = "Cookie Policy"
                break;
            case "/policies/security-practices":
                document = "SECURITY_POL"
                title = "interopiO® Security Practices"
                break;
            case "/legal/archives":
                document = "ARCHIVE"
                title = "Terms & Policies Archives"
                break;
            default:
                document = "CUST_TOS"
                title = "Customer Terms of Service"
        }

        if (date) {
            title += " (Archived)"
        }

        return {document, title, date}
    }

    private renderContent = () => {
        let content;
        let defaultProps = {
            className: "container",
            style: {padding: 0},
            options: {
                ...sanitizeHtml.defaults,
                allowedTags: false,
                allowedAttributes: false,
                allowedStyles: false,
                allowVulnerableTags: true
            }
        };

        if (this.state.date) {
            const doc = this.state.all[this.state.document.toLowerCase()].find((document) => {
                return getDateDisplayNoTimezone(document.effective) === this.state.date
            });
            if (doc) {
                content = <SanitizeHtml html={doc.content} {...defaultProps}/>
            } else {
                content = <SanitizeHtml html={this.state.all.cust_tos[0].content} {...defaultProps}/>
            }
        } else {
            switch (this.state.document) {
                case "CUST_TOS":
                    content = <SanitizeHtml html={this.state.all.cust_tos[0].content} {...defaultProps}/>
                    break;
                case "USER_TOS":
                    content = <SanitizeHtml html={this.state.all.user_tos[0].content} {...defaultProps}/>
                    break;
                case "SUPL_TOS":
                    content = <SanitizeHtml html={this.state.all.supl_tos[0].content} {...defaultProps}/>
                    break;
                case "SLA_TOS":
                    content = <SanitizeHtml html={this.state.all.sla_tos[0].content} {...defaultProps}/>
                    break;
                case "PRIVACY_POL":
                    content = <SanitizeHtml html={this.state.all.privacy_pol[0].content} {...defaultProps}/>
                    break;
                case "ACCEPTABLE_POL":
                    content = <SanitizeHtml html={this.state.all.acceptable_pol[0].content} {...defaultProps}/>
                    break;
                case "DATA_REQ_POL":
                    content = <SanitizeHtml html={this.state.all.data_req_pol[0].content} {...defaultProps}/>
                    break;
                case "SECURITY_POL":
                    content = <SanitizeHtml html={this.state.all.security_pol[0].content} {...defaultProps}/>
                    break;
                case "CUST_POLICIES":
                    content = <div/>
                    break;
                case "ARCHIVE":
                    content = <div className="legal-content">
                        <div className="archive-heading">Customer Terms of Service</div>
                        <div className="archive-content">
                            {this.renderCustTosArchive()}
                        </div>
                        <div className="archive-heading">User Terms of Service</div>
                        <div className="archive-content">
                            {this.renderUserTosArchive()}
                        </div>
                        <div className="archive-heading">Customer-Specific Supplement</div>
                        <div className="archive-content">
                            {this.renderSuplTosArchive()}
                        </div>
                        <div className="archive-heading">Service Level Agreement</div>
                        <div className="archive-content">
                            {this.renderSlaTosArchive()}
                        </div>
                        <div className="archive-heading">interopiO® Privacy</div>
                        <div className="archive-content">
                            {this.renderPrivacyPolArchive()}
                        </div>
                        <div className="archive-heading">Acceptable Use Policy</div>
                        <div className="archive-content">
                            {this.renderAcceptablePolArchive()}
                        </div>
                        <div className="archive-heading">Data Request Policy</div>
                        <div className="archive-content">
                            {this.renderDataReqPolArchive()}
                        </div>
                        <div className="archive-heading">interopiO® Security Practices</div>
                        <div className="archive-content">
                            {this.renderSecurityPracticesArchive()}
                        </div>
                    </div>
                    break;
                default:
                    content = <SanitizeHtml html={this.state.all.cust_tos[0].content} {...defaultProps}/>
                    break;
            }
        }

        return content
    }

    private renderCustTosArchive = () => {
        return <div>
            <ul>
                {this.state.all.cust_tos.map((item, i) => {
                    if (i === 0) {
                        return (<li key={`cust-tos-list-${i}`}><Link style={{fontWeight: 600}} to="/terms/customer-terms-of-service">Current
                            - {getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                    }
                    return (<li key={`cust-tos-list-${i}`}><Link
                        to={`/terms/customer-terms-of-service/${getDateDisplayNoTimezone(item.effective)}`}>{getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                })}
            </ul>
        </div>
    }

    private renderUserTosArchive = () => {
        return <div>
            <ul>
                {this.state.all.user_tos.map((item, i) => {
                    if (i === 0) {
                        return (<li key={`user-tos-list-${i}`}><Link style={{fontWeight: 600}} to="/terms/user-terms-of-service">Current
                            - {getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                    }
                    return (<li key={`user-tos-list-${i}`}><Link
                        to={`/terms/user-terms-of-service/${getDateDisplayNoTimezone(item.effective)}`}>{getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                })}
            </ul>
        </div>
    }

    private renderSuplTosArchive = () => {
        return <div>
            <ul>
                {this.state.all.supl_tos.map((item, i) => {
                    if (i === 0) {
                        return (<li key={`supl-tos-list-${i}`}><Link style={{fontWeight: 600}} to="/terms/customer-specific-supplement">Current
                            - {getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                    }
                    return (<li key={`supl-tos-list-${i}`}><Link to={`/terms/customer-specific-supplement/${getDateDisplayNoTimezone(item.effective)}`}>{getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                })}
            </ul>
        </div>
    }

    private renderSlaTosArchive = () => {
        return <div>
            <ul>
                {this.state.all.sla_tos.map((item, i) => {
                    if (i === 0) {
                        return (<li key={`sla-tos-list-${i}`}><Link style={{fontWeight: 600}} to="/terms/service-level-agreement">Current
                            - {getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                    }
                    return (<li key={`sla-tos-list-${i}`}><Link to={`/terms/service-level-agreement/${getDateDisplayNoTimezone(item.effective)}`}>{getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                })}
            </ul>
        </div>
    }

    private renderPrivacyPolArchive = () => {
        return <div>
            <ul>
                {this.state.all.privacy_pol.map((item, i) => {
                    if (i === 0) {
                        return (<li key={`privacy-policy-list-${i}`}><Link style={{fontWeight: 600}} to="/policies/privacy-notice">Current
                            - {getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                    }
                    return (<li key={`privacy-policy-list-${i}`}><Link to={`/policies/privacy-notice/${getDateDisplayNoTimezone(item.effective)}`}>{getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                })}
            </ul>
        </div>
    }

    private renderAcceptablePolArchive = () => {
        return <div>
            <ul>
                {this.state.all.acceptable_pol.map((item, i) => {
                    if (i === 0) {
                        return (<li key={`acceptable-policy-list-${i}`}><Link style={{fontWeight: 600}} to="/policies/acceptable-use-policy">Current
                            - {getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                    }
                    return (
                        <li key={`acceptable-policy-list-${i}`}><Link to={`/policies/acceptable-use-policy/${getDateDisplayNoTimezone(item.effective)}`}>{getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                })}
            </ul>
        </div>
    }

    private renderDataReqPolArchive = () => {
        return <div>
            <ul>
                {this.state.all.data_req_pol.map((item, i) => {
                    if (i === 0) {
                        return (<li key={`data-req-policy-list-${i}`}><Link style={{fontWeight: 600}} to="/policies/data-request-policy">Current
                            - {getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                    }
                    return (<li key={`data-req-policy-list-${i}`}><Link to={`/policies/data-request-policy/${getDateDisplayNoTimezone(item.effective)}`}>{getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                })}
            </ul>
        </div>
    }

    private renderSecurityPracticesArchive = () => {
        return <div>
            <ul>
                {this.state.all.security_pol.map((item, i) => {
                    if (i === 0) {
                        return (<li key={`security-practices-list-${i}`}><Link style={{fontWeight: 600}} to="/policies/security-practices">Current
                            - {getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                    }
                    return (<li key={`security-practices-list-${i}`}><Link to={`/policies/security-practices/${getDateDisplayNoTimezone(item.effective)}`}>{getMonthFirstFullWrittenDateDisplayNoTimezone(item.effective)}</Link></li>)
                })}
            </ul>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Legal)));
