import * as React from "react";
import * as PropTypes from "prop-types";
import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import colors from "../../../../../../lib/legacy-color-palette";
import axiosWrapper from "../../../../../../lib/axiosWrapper";
import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import withLegacyTheme from "../../../../../../lib/hoc/with-legacy-theme";
import * as actions from "../../../../../../redux/actions/index";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";
import Refresh from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import RefreshIndicator from "@material-ui/core/RefreshIndicator";
import DateRange from "@material-ui/icons/DateRange";
import AppLaunchesOT from "../../../../Apps/AppEdit/Usage/UsageGraphs/AppLaunchesOT/index"
import TopResourcesHorizontal from "../../../../Apps/AppEdit/Usage/UsageGraphs/TopResourcesHorizontal/index";
import ChipDropDown from "../../../../../Widgets/ChipDropDown/index";
// import {getDateDisplay, getDateWithZoneDisplay} from "../../../../../../lib/utils";
import TotalCount from "../../../../../Widgets/Editor/UsageTab/TotalCount";
import "./style.less";

let moment = require("moment-timezone");

const colorQueue = [
    colors.deepPurple600,
    colors.teal600,
    colors.amber600,
    colors.blue600,
    colors.brown600,
    colors.deepOrange600,
    colors.green600,
    colors.red600,
    colors.yellow600,
    colors.lime600
];

export class ServiceSetAnalytics extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {
            assignedColors: {},
            topResourcesByService: {
                data: null,
                refreshing: false
            },
            topResourcesTotal: {
                data: null,
                refreshing: false
            },
            invocationsOverTimeByService: {
                data: null,
                refreshing: false
            },
            invocationsOverTimeTotal: {
                data: null,
                refreshing: false
            },
            cardsOverTimeByService: {
                data: null,
                refreshing: false
            },
            transactionsOverTimeByService: {
                data: null,
                refreshing: false
            },
            transactionsOverTimeTotal: {
                data: null,
                refreshing: false
            },
            totalTransactions: {
                data: null,
                refreshing: false
            },
            totalInvocations: {
                data: null,
                refreshing: false
            },
            totalCards: {
                data: null,
                refreshing: false
            },
            timeframe: 2678400000,
            timeframeValue: 2678400000,
            startTime: 0,
            endTime: 0,
            timeZone: "US/Mountain",
            startOf: "day",
            interval: "day",
            allServices: [],
            visibleServices: [],
            filteredServices: [],
            snackbar: {
                open: false,
                message: "Downloading CSV...",
                autoHideDuration: null
            },
            selectedServiceId: null
        };

        this.topResources_init = this.topResources_init.bind(this);
        this.topResources_refresh = this.topResources_refresh.bind(this);
        this.invocationsOverTime_init = this.invocationsOverTime_init.bind(this);
        this.invocationsOverTime_refresh = this.invocationsOverTime_refresh.bind(this);
        this.handleTimeSpanChange = this.handleTimeSpanChange.bind(this);
        this.refreshAll = this.refreshAll.bind(this);
        this.handleServicesToggle = this.handleServicesToggle.bind(this);
        this.updateTotalValues = this.updateTotalValues.bind(this);
        this.handleVisibleServicesChange = this.handleVisibleServicesChange.bind(this)
    }

    public componentDidMount() {
        this.setState({
            endTime: new Date().getTime(),
            allServices: this.props.cdsServiceSets.selected.data.cdsServices
        }, () => {
            this.setVisibleServices();
            this.assignChartColors();
            this.getChartStartTimeInMillis();
            this.init();
        });
    }

    public render() {
        const styles = {
            cardChart: {display: "inline-block", margin: "5px"},
            cardChartText: {padding: 0},
            chartMargins: {top: 15, right: 30, bottom: 5, left: 0},
            subTitle: {margin: 0},
            title: {marginBottom: 0}
        };

        return <div style={{width: "100%"}}>
            <div className="container app-analytics">
                {this.renderToolbar()}
                <TotalCount items={{
                    totalInvocations: { ...this.state.totalInvocations, displayName: "CDS Service Invocations" },
                    totalCards: { ...this.state.totalCards, displayName: "CDS Cards Returned" },
                    totalTransactions: { ...this.state.totalTransactions, displayName: "FHIR Transactions" } }} />
                <div style={{margin: "30px 10px 10px"}}>Comparing by Service</div>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.invocationsOverTimeByService_render(styles)}
                    <Divider style={{marginTop: "10px", marginLeft: "50px", marginRight: "50px"}}/>
                    {this.cardsOverTimeByService_render(styles)}
                    <Divider style={{marginTop: "10px", marginLeft: "50px", marginRight: "50px"}}/>
                    {this.topResourcesByService_render(styles)}
                    <Divider style={{marginTop: "10px", marginLeft: "50px", marginRight: "50px"}}/>
                    {this.transactionsOverTimeByService_render(styles)}
                </Card>
                <br/>
                <br/>
                <Divider/>
            </div>
        </div>;
    }

    private init() {
        return Promise.all([this.topResources_init(), this.invocationsOverTime_init(), this.transactionsOverTime_init()])
            .then(([topData, iotData, trotData]) => {
                this.setState({
                    topResourcesByService: {
                        data: this.topResourcesByService_postProcess(topData),
                        refreshing: false
                    },
                    topResourcesTotal: {
                        data: this.topResourcesTotal_postProcess(topData),
                        refreshing: false
                    },
                    invocationsOverTimeByService: {
                        data: this.invocationsOverTimeByService_postProcess(iotData),
                        refreshing: false
                    },
                    invocationsOverTimeTotal: {
                        data: this.invocationsOverTimeTotal_postProcess(iotData),
                        refreshing: false
                    },
                    cardsOverTimeByService: {
                        data: this.cardsOverTimeByService_postProcess(iotData),
                        refreshing: false
                    },
                    totalCards: {
                        data: this.calculateTotalCards(iotData),
                        refreshing: false
                    },
                    totalInvocations: {
                        data: this.calculateTotalInvocations(iotData),
                        refreshing: false
                    },
                    transactionsOverTimeByService: {
                        data: this.transactionsOverTimeByService_postProcess(trotData),
                        refreshing: false
                    },
                    transactionsOverTimeTotal: {
                        data: this.transactionsOverTimeTotal_postProcess(trotData),
                        refreshing: false
                    },
                    totalTransactions: {
                        data: this.calculateTotalTransactions(trotData),
                        refreshing: false
                    }
                }, this.handleVisibleServicesChange.bind(this, this.state.visibleServices));
            });
    }

    private refreshAll() {
        this.topResources_refresh();
        this.invocationsOverTime_refresh();
        this.transactionsOverTime_refresh();
        this.init()
            .then(() => {
                this.handleVisibleServicesChange(this.state.filteredServices);
            });
    }

    private setVisibleServices() {
        let vs = [];
        this.state.allServices.forEach(service => vs.push(service.title));
        this.setState({visibleServices: vs, filteredServices: vs});
    }

    private assignChartColors() {
        let assignedColors = {
            "total": colors.indigo600
        };
        for (let x = 0; x < this.state.allServices.length; x++) {
            let colorIndex = x;
            if (colorIndex > colorQueue.length) {
                colorIndex = colorIndex % colorQueue.length;
            }

            assignedColors[this.state.allServices[x].title] = colorQueue[colorIndex];
        }
        this.setState({assignedColors});
    }

    private getChartStartTimeInMillis() {
        let midnight = moment.tz(this.state.endTime - this.state.timeframe, this.props.ui.timeZone).startOf(this.state.startOf)
        this.setState({startTime: new Date(midnight.format()).getTime()});
        return new Date(midnight.format()).getTime();
    }

    private runAnalyticsQuery(dataType, query) {
        return axiosWrapper(this.props.config.analyticsService, dataType, "POST", query)
    }

    private getTimeZone() {
        return this.props.ui.timeZone
    }

    private cardsOverTimeByService_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        for (let i = 0; i < this.state.allServices.length; i++) {
            let curService = this.state.allServices[i];
            preparedData.series.push(curService.title);
        }

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            for (let key in curBucket["3"]["buckets"]) {
                let gtwKey = key.split(":")[1];
                let serviceKey;
                for (let service of this.state.allServices) {
                    if (gtwKey === service.id) {
                        serviceKey = service.title
                    }
                }
                curData[serviceKey] = curBucket["3"]["buckets"][key]["4"].value;
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    };

    private calculateTotalCards(rawData) {
        let totalCards = 0;
        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];

            for (let key in curBucket["3"]["buckets"]) {
                totalCards += curBucket["3"]["buckets"][key]["4"].value;
            }
        }

        return totalCards;
    };

    private cardsOverTimeByService_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.cardsOverTimeByService.data} styles={styles} launchesOverTime_refresh={this.invocationsOverTime_refresh}
            title="CDS Cards Returned By Service"/>
    }

    private topResources_init() {
        let query = this.topResources_preProcess();
        return this.runAnalyticsQuery("cds-set-top-data", query).then(res => res.data);
    }

    private topResources_preProcess() {
        const {accountId} = getHostnameInfo();
        const environmentId = this.props.cdsServiceSets.selected.data.environmentId;
        const cdsServiceSetId = this.props.cdsServiceSets.selected.data.cdsServiceSetId;
        const clientId = accountId + "/" + environmentId + "/" + cdsServiceSetId + "/";

        let query = {
            clientId,
            endTime: new Date().getTime(),
            startTime: this.getChartStartTimeInMillis(),
            environmentId,
            accountId
        }

        return query;
    }

    private topResourcesByService_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        for (let i = 0; i < this.state.allServices.length; i++) {
            let curService = this.state.allServices[i];
            preparedData.series.push(curService.title);
        }

        const resourceBuckets = rawData.aggregations.group_by_fhir_resource.buckets;

        for (let i = 0; i < resourceBuckets.length; i++) {
            let curBucket = resourceBuckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            const envBuckets = curBucket.group_by_gtw.buckets;

            for (let j = 0; j < envBuckets.length; j++) {
                let curEnvBucket = envBuckets[j];
                let gtwKey = curEnvBucket.key
                let serviceKey;
                for (let service of this.state.allServices) {
                    if (gtwKey === service.id) {
                        serviceKey = service.title
                    }
                }
                curData[serviceKey] = curEnvBucket.doc_count;
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        let resources = ["Observation", "Procedure", "Condition", "Medication", "Patient", "Practitioner", "Organization", "ImmunizationRecommendation"];
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            for (let i = 0; i < resources.length; i++) {
                let datapoint = {}
                datapoint["name"] = resources[i];
                datapoint["demo"] = 0;
                placeholeder.push(datapoint)
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    };

    private topResourcesTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const resourceBuckets = rawData.aggregations.group_by_fhir_resource.buckets;
        for (let i = 0; i < resourceBuckets.length; i++) {
            let curBucket = resourceBuckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }
        let resources = ["Observation", "Procedure", "Condition", "Medication", "Patient", "Practitioner", "Organization", "ImmunizationRecommendation"];
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            for (let i = 0; i < resources.length; i++) {
                let datapoint = {}
                datapoint["name"] = resources[i];
                datapoint["demo"] = 0;
                placeholeder.push(datapoint)
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    };

    private topResources_refresh() {
        this.setState({
            topResourcesByService: {
                data: this.state.topResourcesByService.data,
                refreshing: true
            },
            topResourcesTotal: {
                data: this.state.topResourcesTotal.data,
                refreshing: true
            }
        });
    }

    private topResourcesByService_render(styles) {
        return <TopResourcesHorizontal state={this.state} data={this.state.topResourcesByService.data} styles={styles} topResources_refresh={this.topResources_refresh} legend={true}
            title="Resource Transactions By Service"/>
    };

    private invocationsOverTime_init() {
        let query = this.invocationsOverTime_preProcess();
        return this.runAnalyticsQuery("cds-set-iot-data", query).then(res => res.data);
    }

    private invocationsOverTime_preProcess() {
        let endTime = new Date().getTime()
        if (this.state.timeframe === 86400000) {
            endTime = this.getChartStartTimeInMillis() + 86400000;
        }

        const query = {
            timezone: this.getTimeZone(),
            interval: this.state.interval,
            endTime: endTime,
            startTime: this.getChartStartTimeInMillis(),
            cdsServiceSetId: this.props.cdsServiceSets.selected.data.cdsServiceSetId,
            cdsServices: this.props.cdsServiceSets.selected.data.cdsServices,
            accountId: this.props.cdsServiceSets.selected.data.accountId,
            environmentId: this.props.cdsServiceSets.selected.data.environmentId
        }

        return query;
    }

    private invocationsOverTimeByService_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        for (let i = 0; i < this.state.allServices.length; i++) {
            let curService = this.state.allServices[i];
            preparedData.series.push(curService.title);
        }

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            for (let key in curBucket["3"]["buckets"]) {
                let gtwKey = key.split(":")[1];
                let serviceKey;
                for (let service of this.state.allServices) {
                    if (gtwKey === service.id) {
                        serviceKey = service.title
                    }
                }
                curData[serviceKey] = curBucket["3"]["buckets"][key].doc_count;
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    };

    private invocationsOverTimeTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    };

    private calculateTotalInvocations(rawData) {
        return rawData.hits.total?.value || 0;
    };

    private invocationsOverTime_refresh() {
        this.setState({
            invocationsOverTimeByService: {
                data: this.state.invocationsOverTimeByService.data,
                refreshing: true
            },
            invocationsOverTimeTotal: {
                data: this.state.invocationsOverTimeTotal.data,
                refreshing: true
            },
            totalInvocations: {
                data: this.state.totalInvocations.data,
                refreshing: true
            },
            cardsOverTimeByService: {
                data: this.state.cardsOverTimeByService.data,
                refreshing: true
            },
            totalCards: {
                data: this.state.totalCards.data,
                refreshing: true
            }
        });
    }

    private invocationsOverTimeByService_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.invocationsOverTimeByService.data} styles={styles}
            launchesOverTime_refresh={this.invocationsOverTime_refresh} title="CDS Service Set Invocations By Service"/>
    }

    private transactionsOverTime_init() {
        let query = this.transactionsOverTime_preProcess();
        return this.runAnalyticsQuery("cds-set-trot-data", query).then(res => res.data);
    }

    private transactionsOverTime_preProcess() {
        let endTime = new Date().getTime()
        if (this.state.timeframe === 86400000) {
            endTime = this.getChartStartTimeInMillis() + 86400000;
        }
        const accountId = this.props.cdsServiceSets.selected.data.accountId;
        const environmentId = this.props.cdsServiceSets.selected.data.environmentId;
        const cdsServiceSetId = this.props.cdsServiceSets.selected.data.cdsServiceSetId;
        const clientId = accountId + "/" + environmentId + "/" + cdsServiceSetId + "/";

        const query = {
            timezone: this.getTimeZone(),
            interval: this.state.interval,
            endTime: endTime,
            startTime: this.getChartStartTimeInMillis(),
            clientId,
            cdsServices: this.props.cdsServiceSets.selected.data.cdsServices,
            accountId,
            environmentId
        }

        return query;
    }

    private transactionsOverTimeByService_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        for (let i = 0; i < this.state.allServices.length; i++) {
            let curService = this.state.allServices[i];
            preparedData.series.push(curService.title);
        }

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;

            for (let key in curBucket["3"]["buckets"]) {
                let gtwKey = key.split(":")[1];
                let serviceKey;
                for (let service of this.state.allServices) {
                    if (gtwKey === service.id) {
                        serviceKey = service.title
                    }
                }
                curData[serviceKey] = curBucket["3"]["buckets"][key].doc_count;
            }

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    };

    private transactionsOverTimeTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }

        return preparedData;
    };

    private calculateTotalTransactions(rawData) {
        return rawData.hits.total?.value || 0;
    }

    private transactionsOverTime_refresh() {
        this.setState({
            transactionsOverTimeByService: {
                data: this.state.transactionsOverTimeByService.data,
                refreshing: true
            },
            transactionsOverTimeTotal: {
                data: this.state.transactionsOverTimeTotal.data,
                refreshing: true
            },
            totalTransactions: {
                data: this.state.totalTransactions.data,
                refreshing: true
            }
        });
    }

    private transactionsOverTimeByService_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.transactionsOverTimeByService.data} styles={styles}
            launchesOverTime_refresh={this.transactionsOverTime_refresh} title="Resource Transactions By Service"/>
    }

    private renderToolbar = () => {
        // let timeframe = getDateDisplay(this.state.startTime, this.props.ui.timeZone) + " - " + getDateWithZoneDisplay(this.state.endTime, this.props.ui.timeZone);
        let labelServices = "Services";
        if (this.state.filteredServices.length && this.state.filteredServices.length !== this.state.allServices.length) {
            labelServices = this.state.filteredServices[0];
            if (this.state.filteredServices.length > 1) {
                labelServices += ` + ${this.state.filteredServices.length - 1}`;
            }
        } else if (this.state.filteredServices.length === 0) {
            labelServices = "Services";
        }

        return <Toolbar className={"analytics-toolbar"} style={{height: "76px", display: "flex", justifyContent: "space-between"}}>
            <div style={{alignItems: "none"}}>
                <DateRange style={{fontSize: "24px", paddingRight: "10px", alignSelf: "center"}}/>
                <Select value={this.state.timeframeValue} onChange={this.handleTimeSpanChange} style={{width: "200px", marginRight: "20px"}}>
                    <MenuItem value={0}>Today</MenuItem>
                    <MenuItem value={86400000}>Yesterday</MenuItem>
                    <MenuItem value={604800000}>Last 7 days</MenuItem>
                    <MenuItem value={2678400000}>Last 30 days</MenuItem>
                    <MenuItem value={8035200000}>Last 90 days</MenuItem>
                    <MenuItem value={31536000000}>Last 365 days</MenuItem>
                    <MenuItem value={-12}>Year-to-date</MenuItem>
                </Select>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                <div>
                    <ChipDropDown label={labelServices} inactiveBackground="#fff" inactiveColor={this.props.muiTheme.palette.textColor}
                        activeBackground={this.props.muiTheme.palette.primary1Color} activeColor="#fff" isActive={this.isFilterResourcesActive()}
                        popover={{
                            title: "Services",
                            content: <div style={{margin: "8px 16px"}}>
                                <FormControlLabel
                                    control={<Switch checked={this.state.filteredServices.length === this.state.allServices.length} style={{ marginBottom: "8px" }} onChange={this.handleServicesToggle} />}
                                    label="Select All Services"
                                />
                                <Divider style={{margin: "8px 0px"}}/>
                                {this.state.allServices.sort((a, b) => a.title.localeCompare(b.title)).map(service => {
                                    let index = this.state.filteredServices.indexOf(service.title);
                                    return <FormControlLabel control={<Checkbox name={`service-${service.title}`} checked={index >= 0}
                                        onChange={(event) => {
                                            let vs = $.extend(true, [], this.state.filteredServices);
                                            if (event.target.checked && index < 0) {
                                                vs.push(service.title)
                                            } else if (!event.target.checked && index >= 0) {
                                                vs.splice(index, 1);
                                            }
                                            this.handleVisibleServicesChange(vs);
                                        }}/>}
                                    label={service.title} key={`service-${service.title}`}/>
                                })}
                            </div>
                        }}
                        onRequestDelete={() => {
                            let vs = [];
                            this.state.allServices.forEach(service => vs.push(service.title));
                            this.handleVisibleServicesChange(vs);
                        }}/>
                </div>
                <Tooltip title="Refresh" placement="bottom">
                    <IconButton onClick={this.refreshAll} disabled={this.state.topResourcesByService.refreshing} disableTouchRipple style={{alignSelf: "center", margin: "10px 0 10px 10px"}}>
                        <Refresh/>
                    </IconButton>
                </Tooltip>
            </div>
        </Toolbar>;
    };

    private handleVisibleServicesChange(visibleServices) {
        let vs = $.extend(true, [], visibleServices);
        if (vs.length === 0) {
            this.state.allServices.forEach((service) => {
                vs.push(service.title);
            });
        }
        let iot = $.extend(true, {}, this.state.invocationsOverTimeByService);
        let top = $.extend(true, {}, this.state.topResourcesByService);
        let trot = $.extend(true, {}, this.state.transactionsOverTimeByService);
        iot.data.series = vs;
        top.data.series = vs;
        trot.data.series = vs;
        this.setState({
            filteredServices: visibleServices,
            visibleServices: vs,
            invocationsOverTimeByService: iot,
            topResourcesByService: top,
            transactionsOverTimeByService: trot
        }, this.updateTotalValues);
    }

    private updateTotalValues() {
        let iotTotal = $.extend(true, {}, this.state.invocationsOverTimeTotal);
        let topTotal = $.extend(true, {}, this.state.topResourcesTotal);
        let trotTotal = $.extend(true, {}, this.state.transactionsOverTimeTotal);
        let iotEntries = $.extend(true, [], this.state.invocationsOverTimeByService.data.entries);
        let topEntries = $.extend(true, [], this.state.topResourcesByService.data.entries);
        let trotEntries = $.extend(true, [], this.state.transactionsOverTimeByService.data.entries);
        let cardEntries = $.extend(true, [], this.state.cardsOverTimeByService.data.entries);
        const visibleServices = this.state.visibleServices;
        let iotData = [];
        let topData = [];
        let trotData = [];
        let totalInvocations = 0;
        let totalTransactions = 0;
        let totalCards = 0;
        iotEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            visibleServices.forEach(service => curEntry["total"] += entry[service])
            totalInvocations += curEntry["total"];
            iotData.push(curEntry);
        });
        iotTotal.data.entries = iotData;
        topEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            visibleServices.forEach((service) => {
                if (entry[service]) {
                    curEntry["total"] += entry[service];
                }
            })
            topData.push(curEntry);
        });
        topData.sort((a, b) => (a["total"] > b["total"]) ? -1 : ((b["total"] > a["total"]) ? 1 : 0))
        topTotal.data.entries = topData;
        trotEntries.forEach(entry => {
            let curEntry = {};
            curEntry["name"] = entry.name;
            curEntry["total"] = 0;
            visibleServices.forEach(service => {
                curEntry["total"] += entry[service];
            })
            totalTransactions += curEntry["total"];
            trotData.push(curEntry);
        });
        trotTotal.data.entries = trotData;

        cardEntries.forEach(entry => {
            let sumOfServices = 0
            visibleServices.forEach(service => {
                sumOfServices += entry[service];
            })
            totalCards += sumOfServices;
        });

        this.setState({
            invocationsOverTimeTotal: iotTotal,
            topResourcesTotal: topTotal,
            transactionsOverTimeTotal: trotTotal,
            totalInvocations: {
                data: totalInvocations,
                refreshing: false
            },
            totalTransactions: {
                data: totalTransactions,
                refreshing: false
            },
            totalCards: {
                data: totalCards,
                refreshing: false
            }
        });
    }

    private handleServicesToggle(event) {
        if (event.target.checked) {
            let vs = [];
            this.state.allServices.forEach(service => vs.push(service.title));
            this.handleVisibleServicesChange(vs);
        } else {
            this.handleVisibleServicesChange([]);
        }
    }

    private isFilterResourcesActive = () => this.state.visibleServices.length !== this.state.allServices.length;

    private handleTimeSpanChange = (event) => {
        let startOf;
        let interval;
        let timeframeValue = event.target.value;
        switch (event.target.value) {
            case 0:
                startOf = "day"
                interval = "hour"
                break;
            case 86400000:
                startOf = "day"
                interval = "hour"
                break;
            case 604800000:
                startOf = "day"
                interval = "day"
                break;
            case 2678400000:
                startOf = "day"
                interval = "day"
                break;
            case 8035200000:
                startOf = "week"
                interval = "week"
                break;
            case 31536000000:
                startOf = "month"
                interval = "month"
                break;
            case -12:
                event.target.value = this.calculateTimeframe("year")
                timeframeValue = -12;
                startOf = "day";
                if (event.target.value < 86400000) {
                    interval = "hour"
                } else if (event.target.value < 2678400000) {
                    interval = "day"
                } else if (event.target.value < 10713600000) {
                    interval = "week"
                } else {
                    interval = "month"
                }
                break;
        }
        this.setState({timeframe: event.target.value, timeframeValue, interval, startOf}, this.refreshAll);
    };

    private calculateTimeframe = (startOf) => {
        var startDate = moment(this.state.endTime).tz(this.props.ui.timeZone).startOf(startOf);
        var endDate = moment(this.state.endTime).tz(this.props.ui.timeZone);
        var timeframe = endDate.diff(startDate);
        return timeframe;
    };
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(ServiceSetAnalytics));
