export default [
    "AllergyIntolerance",
    "Appointment",
    "AppointmentResponse",
    "AuditEvent",
    "Basic",
    "Binary",
    "BodySite",
    "Bundle",
    "CarePlan",
    "Claim",
    "ClaimResponse",
    "ClinicalImpression",
    "Communication",
    "CommunicationRequest",
    "Composition",
    "ConceptMap",
    "Condition",
    "Conformance",
    "Contract",
    "DataElement",
    "Device",
    "DeviceComponent",
    "DeviceMetric",
    "DeviceUseRequest",
    "DeviceUseStatement",
    "DiagnosticOrder",
    "DiagnosticReport",
    "DocumentManifest",
    "DocumentReference",
    "Documentation",
    "EligibilityRequest",
    "EligibilityResponse",
    "Encounter",
    "EnrollmentRequest",
    "EnrollmentResponse",
    "EpisodeOfCare",
    "ExplanationOfBenefit",
    "FamilyMemberHistory",
    "Flag",
    "Goal",
    "Group",
    "HealthcareService",
    "ImagingObjectSelection",
    "ImagingStudy",
    "Immunization",
    "ImmunizationRecommendation",
    "ImplementationGuide",
    "List",
    "Location",
    "Media",
    "Medication",
    "MedicationAdministration",
    "MedicationDispense",
    "MedicationOrder",
    "MedicationStatement",
    "MessageHeader",
    "NamingSystem",
    "NutritionOrder",
    "Observation",
    "OperationDefinition",
    "OperationOutcome",
    "Order",
    "OrderResponse",
    "Organization",
    "Parameters",
    "Patient",
    "PaymentNotice",
    "PaymentReconciliation",
    "Person",
    "Practitioner",
    "Procedure",
    "ProcedureRequest",
    "ProcessRequest",
    "ProcessResponse",
    "Provenance",
    "Questionnaire",
    "QuestionnaireResponse",
    "ReferralRequest",
    "RelatedPerson",
    "Remittance",
    "RiskAssessment",
    "Schedule",
    "SearchParameter",
    "Slot",
    "Specimen",
    "StructureDefinition",
    "Subscription",
    "Substance",
    "SupplyDelivery",
    "SupplyRequest",
    "TestScript",
    "ValueSet",
    "VisionPrescription",
];
