import * as React from "react";
import * as PropTypes from "prop-types";
import {IconButton, Tooltip} from "@material-ui/core";
import {Refresh} from "@material-ui/icons";

class RefreshButton extends React.Component<any, any> {
    public static propTypes = {
        refresh: PropTypes.func.isRequired
    };

    public render() {
        return <IconButton style={{ alignSelf: "center", margin: "0 0 0 10px" }} onClick={() => this.props.refresh()}>
            <Tooltip title='Refresh'>
                <Refresh />
            </Tooltip>
        </IconButton>;
    }
}

export default RefreshButton
