import * as PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import withLegacyTheme from "../../../../../../lib/hoc/with-legacy-theme";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {IconButton, Tooltip} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import "./style.less";

const PracticeListingItem = (props) => {
    const practiceId = props.practiceId || "";
    const practiceName = props.practiceName || "";

    const deleteItemHtml = (
        <div className="col-remove">
            <Tooltip placement="top" title="Remove">
                <IconButton
                    onClick={() => {
                        let newPracticeList = [...props.data.tenants];
                        newPracticeList.splice(props.itemIndex, 1);
                        props.setAppActivation({
                            ...props.data,
                            tenants: newPracticeList
                        });
                    }}>
                    <Delete style={{color: props.muiTheme.palette.accent1Color}}/>
                </IconButton>
            </Tooltip>
        </div>
    );

    const listItemProps = {}
    if (!props.canEdit) {
        listItemProps['disabled'] = true;
    }
    return <ListItem
        {...listItemProps}
        className="practice-listing-ul-item"
    >
        <div className="content">
            <div  className="col-1">{practiceId}</div>
            <div  className="col-2">{practiceName}</div>
            {props.canEdit && deleteItemHtml}
        </div>
    </ListItem>;
}

PracticeListingItem.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object,
    setAppActivation: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    canEdit: PropTypes.bool,
    itemIndex: PropTypes.number,
    practiceId: PropTypes.string,
    practiceName: PropTypes.string,
    // created: PropTypes.string,
    // updated: PropTypes.string
}

const mapStateToProps = (state, ownProps) => ({
    ui: state.ui,
    ...ownProps,
});

export default withRouter(connect(mapStateToProps)(withLegacyTheme()(PracticeListingItem)));
