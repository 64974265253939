import * as React from "react";
import * as PropTypes from "prop-types";
import {Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import Actions from "./Actions";
import Content from "./Content";
import Title from "./Title";

import * as validateData from "../../../../../../lib/validate-users-data";

const INIT_STATE = {
    status: "ready",
    dataValidation: {
        invitationEmail: "",
        invitationEmailSentConfirmed: "",
        invitationEmailSentRejected: ""
    },
    data: {
        invitationEmail: ""
    }
};

class Component extends React.Component<any, any> {
    public static propTypes = {
        muiTheme: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {
            ...INIT_STATE
        };
    }

    public componentDidMount() {
        this.validateData();
    }

    public componentDidUpdate(_, prevState) {
        if (JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.validateData();
        }
    }

    public render() {
        return <Dialog open style={{paddingTop: "5px", zIndex: 1250}} maxWidth="lg" classes={{paper: "io-modal-wrapper"}}>
            <DialogTitle style={{height: "48px", background: this.props.muiTheme.palette.primary1Color, color: "#fff", lineHeight: "48px", fontSize: "24px", fontWeight: 600}}>
                <Title {...this.props} onSetDialogState={this.onSetDialogState} state={this.state}/>
            </DialogTitle>
            <DialogContent style={{width: "100%", paddingTop: "24px", boxSizing: "border-box"}}>
                <Content {...this.props} onSetDialogState={this.onSetDialogState} state={this.state}/>
            </DialogContent>
            <DialogActions style={{padding: "0 24px 24px"}}>
                <Actions {...this.props} onSetDialogState={this.onSetDialogState} state={this.state}/>
            </DialogActions>
        </Dialog>;
    }

    // Event handlers ----------------------------------------------------------
    private onSetDialogState = (state, cb) => this.setState(state, cb);

    // Helpers -----------------------------------------------------------------
    private validateData = () => {
        let dataValidation = {};
        dataValidation = {
            ...validateData.onEditInviteUser(this.state),
            invitationEmailSentConfirmed: this.state.dataValidation.invitationEmailSentConfirmed,
            invitationEmailSentRejected: this.state.dataValidation.invitationEmailSentRejected
        };

        this.setState({dataValidation});
    }
}

export default Component;
