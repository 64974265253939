// import feature from "./feature-model"

const init: {
    status: string;
    data: any;
} = {
    status: "",
    data: {
        // ":FEATURE_1:": {
        //     ...feature,
        //     id: ":FEATURE_1:",
        //     name: "Demo Feature #1",
        //     description: "A demo feature just to test the implementation of feature toggles concept",
        //     isActive: true,
        // },
    },
};

export default init;
