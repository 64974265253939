import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import * as features from "../../../../lib/feature-helpers";
import getCDRsCallUrl from "../../../../lib/getCDRsCallUrl";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import getEnvironmentsCallUrl from "../../../../lib/getEnvironmentsCallUrl";
import {Link} from "react-router-dom";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import {NavbarL2, NavbarL3} from "../../../CustomNavbar/";
import Details from "./Details/";
import CDREditSupportedOperations from "./CDREditSupportedOperations";
import DataManager from "./DataManager";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import Selector from "../../../CustomNavbar/Selector";

let TABS = [
    {title: "DETAILS"},
    {title: "SUPPORTED OPERATIONS"},
];

const TAB_SELECTED_BY_DEFAULT = 0;

const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "waiting"
    data: {},
    dataValidation: {},
    mode: ":VIEW:", // ":VIEW:" | ":EDIT:"
    importString: ''
};

class CDREdit extends React.Component<any, any> {
    public static propTypes = {
        cdrs: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        environments: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,
        cdrsRead: PropTypes.func.isRequired,
        environmentsRead: PropTypes.func.isRequired,
        uiTabsSetItems: PropTypes.func.isRequired,
        uiTabsSetSelected: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount = async() => {
        const {accountId} = getHostnameInfo();
        const {env} = getLocationInfo(this.props.location, this.props.config);
        const environmentUrl = getEnvironmentsCallUrl(accountId) + "/" + env;
        await this.props.environmentsRead(this.props.config, environmentUrl);
        let initialTabs = TABS
        // Commenting out the Data Manager tab visibility for now since the BE can't support this due to some nginx issues
        // const environmentPhi = !!this.props.environments?.selected?.data?.phi;
        // if (!environmentPhi) {
        //     initialTabs = initialTabs.concat([
        //         {title: "DATA MANAGER"}
        //     ])
        // }
        this.props.uiTabsSetItems(initialTabs);
        this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
        this.initialize();
    }

    public componentDidUpdate(prevProps) {
        const selectedTab = this.props.ui?.tabs?.selected;
        const prevSelectedTab = prevProps.ui?.tabs?.selected;
        selectedTab !== prevSelectedTab && this.initialize();
    }

    public render() {
        const {env, cdrId: selectedCDR} = getLocationInfo(this.props.location, this.props.config);
        const featureSmileCdrCustomization = features.check(":SMILE_CDR_CUSTOMIZATION:", this.props.features.data);

        return <div style={{width: "100%"}}>
            <NavbarL2 title={(
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{height: 56, marginRight: 24}}>
                        <Link to={`/${env}/cdrs`} style={{color: "#fff", fontSize: 24, textDecoration: "none"}}>
                            {featureSmileCdrCustomization ? "Smile CDR" : "FHIR CDRs"}
                        </Link>
                    </div>
                    <Selector data-qa-label="navbar-cdr-selector-name" value={selectedCDR} icon={<DonutSmallIcon style={{ color: "rgba(255, 255, 255, 0.87)" }} />}
                        data-qa="selector-cdrs" data={this.props.cdrs.all ? this.props.cdrs.all.data.map((cdr) => ({id: cdr.cdrId, name: cdr.name})) : []}
                        onChange={(item) => {
                            this.props.history.push(`/${env}/cdrs/${item.id}`);
                            this.props.uiTabsSetSelected(0);
                            this.initialize();
                        }}/>
                </div>
            )}/>

            <NavbarL3 disableAll={this.state.status !== "ready"}/>

            <div className="container">
                {["ready"].includes(this.state.status)
                    ? this.renderTabContent()
                    : <CenteredCircularProgress size={63} style={{padding: 24}}/>}
            </div>
            <br/>
        </div>;
    }

    private renderTabContent = () => {
        const contentProps = {
            parentState: this.state,
            initialize: this.initialize,
            onSetParentState: this.onSetState
        }

        switch (this.props.ui.tabs.selected) {
            case 0:
                return <Details {...contentProps} />;
            case 1:
                return <CDREditSupportedOperations/>;
            case 2:
                return <DataManager {...contentProps}/>;
            default:
                return null;
        }
    }

    private onSetState: Function = (state, cb) => this.setState(state, cb);

    private initialize = () => {
        this.setState(
            {status: "init"},
            async () => {
                const {accountId} = getHostnameInfo();
                const {env, cdrId} = getLocationInfo(this.props.location, this.props.config);
                if (this.props.ui?.tabs?.selected !== 1) {
                    await this.props.cdrsRead(this.props.config, getCDRsCallUrl(accountId, env) + "/" + cdrId);
                }
                this.setState({
                    ...INIT_STATE,
                    status: "ready",
                    data: this.props.cdrs.selected.data
                });
            }
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    cdrs: state.cdrs,
    config: state.config,
    environments: state.environments,
    features: state.features,
    ui: state.ui,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdrsRead: actions.cdrsRead,
    environmentsRead: actions.environmentsRead,
    uiTabsSetItems: actions.uiTabsSetItems,
    uiTabsSetSelected: actions.uiTabsSetSelected,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CDREdit);
