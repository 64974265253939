import * as React from "react";
import * as PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import ActiveIcon from "@material-ui/icons/CheckCircle";
import InactiveIcon from "@material-ui/icons/AddCircle";
import {Link} from "react-router-dom";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress";
import getLocationInfo from "../../../../../lib/getLocationInfo";

class Adapters extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired
    };

    public render() {
        return <div>
            {!this.props.state.adapters.dataAdapterHealthInfoDtoSet.length && <CenteredCircularProgress size={63} style={{padding: "24px"}}/>}
            {this.props.state.adapters.dataAdapterHealthInfoDtoSet.map((adapter, i) => this.getAdapter(adapter, i))}
        </div>
    }

    private getAdapter(adapter, key) {
        const location = getLocationInfo(this.props.location, this.props.config);
        let events = Object.keys(adapter.healthEvents);
        let lastEvent;
        if (events.length > 0) {
            lastEvent = adapter.healthEvents[events[events.length - 1]];
            lastEvent = lastEvent.toLowerCase();
        }

        return this.props.filteredAdapters.indexOf(adapter.name) === -1
            ? <div key={key} style={{margin: "10px"}}>
                <Card className="data-adapter">
                    <div className="adapter-content">
                        <div className={`status ${lastEvent || "hidden"}`}>
                            {!!lastEvent && lastEvent.localeCompare("healthy") === 0 ? <ActiveIcon/> : <InactiveIcon/>}
                        </div>
                        <div className="section-title">
                            <Link to={`/${location.env}/datasources/${adapter.dataSourceId}`}>
                                {adapter.name}
                            </Link>
                        </div>
                        <div className="totals-sub">
                            <span className="percent">{`${adapter.upTime.toFixed(2)}`}<small className="postfix">%</small></span>
                            <Divider style={{width: "5%", marginTop: "5px", marginBottom: "5px", marginLeft: "auto", marginRight: "auto"}}/>
                            <div style={{color: "gray", fontSize: "11px"}}>Uptime</div>
                        </div>
                        {adapter.latency && <div className="totals-sub">
                            <span className="percent">{`${adapter.latency.averageLatencyMs}`}<small className="postfix"> ms</small></span>
                            <Divider style={{width: "5%", marginTop: "5px", marginBottom: "5px", marginLeft: "auto", marginRight: "auto"}}/>
                            <div style={{color: "gray", fontSize: "11px"}}>Average latency</div>
                        </div>}
                        {adapter.latency && <div className="totals-sub">
                            <span className="percent">{`${adapter.latency.numRequestsUsedToCalculateLatency}`}</span>
                            <Divider style={{width: "5%", marginTop: "5px", marginBottom: "5px", marginLeft: "auto", marginRight: "auto"}}/>
                            <div style={{color: "gray", fontSize: "11px", position: "relative"}}>
                                <Tooltip title="Number of requests used to calculate average latency">
                                    <span>Requests used</span>
                                </Tooltip>
                            </div>
                        </div>}
                    </div>
                </Card>
            </div>
            : null;
    }
}

export default Adapters;
