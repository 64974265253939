import * as PropTypes from "prop-types";
import {Close} from "@material-ui/icons";
import {withTheme, Typography, IconButton, DialogTitle} from "@material-ui/core";

const CustomDialogTitle = (props) => {
    return <DialogTitle disableTypography style={{color: props.theme.palette.primary.contrastText, background: props.theme.palette.primary.main}}>
        <Typography data-qa="new-data-adapter-modal-title" variant="h6">
            Add a Data Adapter
        </Typography>
        <IconButton data-qa="close-data-adapter-modal" onClick={props.onClose}
            style={{position: "absolute", right: props.theme.spacing(1), top: props.theme.spacing(1), color: props.theme.palette.primary.contrastText}}>
            <Close/>
        </IconButton>
    </DialogTitle>
}

CustomDialogTitle.propTypes = {
    theme: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};

export default withTheme(CustomDialogTitle);
