import * as React from "react";
import * as PropTypes from "prop-types";
import MonitoringToolbar from "../MonitoringToolbar";
import Adapters from "./Adapters";
import "./style.less";

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {
            filteredAdapters: []
        }
    }

    public render() {
        let toolbarProps = {
            label: "Adapters",
            onFilter: this.filterAdapters,
            onPeriodChange: this.props.getDataAdapters,
            list: this.props.state.adapters.dataAdapterHealthInfoDtoSet.map(i => {
                return {label: i.name}
            })
        };

        return <div className="data-adapters">
            <MonitoringToolbar {...this.props} {...toolbarProps}/>
            {!this.props.state.adaptersMessage && <Adapters {...this.props} filteredAdapters={this.state.filteredAdapters}/>}
            <div style={{textAlign: "center", paddingTop: "50px"}}>
                {this.props.state.adaptersMessage}
            </div>
        </div>;
    }

    protected filterAdapters = filteredAdapters => {
        this.setState({filteredAdapters})
    }
}
