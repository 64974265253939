import * as React from "react";
import * as PropTypes from "prop-types";
import {ListItem} from "@material-ui/core";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";

const Header: React.FunctionComponent<any> = props => {
    return <ListItem disabled
        style={{
            boxSizing: "border-box",
            height: "48px",
            background: props.muiTheme.palette.accent2Color,
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            opacity: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            ...props.style
        }}>
        {!props.children
            ? <>
                <div style={{width: "50px"}}>{props.leftIcon}</div>
                <div style={{flex: 3}}>{props.primaryText}</div>
                <div style={{flex: .2}}>{props.rightIcon}</div>
            </>
            : <>
                {props.leftIcon ? <div style={{minWidth: "50px", marginRight: "10px"}}>{props.leftIcon}</div> : null}
                <div style={{width: "inherit"}}>{props.children}</div>
            </>}
    </ListItem>;
}

Header.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    children: PropTypes.any,
    leftIcon: PropTypes.element,
    primaryText: PropTypes.any,
    rightIcon: PropTypes.element,
    style: PropTypes.object
};

Header.defaultProps = {
    children: null,
    leftIcon: null,
    primaryText: "",
    rightIcon: null,
    style: {}
};

export default withLegacyTheme()(Header);
