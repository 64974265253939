import { useState } from "react";
import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const Selector = (props: PropTypes.InferProps<typeof Selector.propTypes>) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const displayName = props.data?.find((item) => item !== null && item.id === props.value)?.name || "";

    return (
        <>
            <Button
                data-qa={props["data-qa"]}
                startIcon={props.icon}
                endIcon={<ArrowDropDownIcon />}
                style={{
                    height: 36,
                    padding: "0 8px",
                    borderRadius: 2,
                    background: props.noBackground ? "transparent" : "rgba(0, 0, 0, 0.16)",
                    color: "#fff",
                    textTransform: "none",
                }}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                }}
            >
                {displayName}
            </Button>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {props.data.map((item) => {
                    if(!item) return null
                    return (
                        <MenuItem
                            data-qa={item["data-qa"]}
                            key={item.id}
                            selected={item.id === props.value}
                            onClick={() => {
                                props.onChange(item);
                                setAnchorEl(null);
                            }}
                        >
                            <span data-qa={props["data-qa-label"]}>
                                {item.name}
                            </span>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

Selector.propTypes = {
    data: PropTypes.array,
    "data-qa": PropTypes.string,
    "data-qa-label": PropTypes.string,
    icon: PropTypes.any,
    noBackground: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

Selector.defaultProps = {
    data: [],
    "data-qa": "selector-menu",
    "data-qa-label": "selector-menu-item",
    icon: null,
    noBackground: false,
    value: "",
    onChange: () => {},
}

export default Selector;

