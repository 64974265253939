import * as React from "react";
import * as PropTypes from "prop-types";
import {Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import Actions from "./Actions";
import SelectedResources from "./SelectedResources";
import SourceSelector from "./SourceSelector";
import Title from "./Title";

const INIT_STATE = {
    selectedSource: "",
    selectedSources: {
        read: "",
        search: "",
        "search-type": "",
        "search-system": "",
        create: "",
        update: "",
        delete: ""
    }
};

export default class extends React.Component<any, any> {
    public static propTypes = {
        muiTheme: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE, selectedSource: props.state.source && props.state.source.id || "", selectedSources: props.state.selectedSources || {
            read: "",
            search: "",
            "search-type": "",
            "search-system": "",
            create: "",
            update: "",
            delete: ""
        }};
    }

    public render() {
        return <Dialog open onClose={() => this.props.onSetState({dialog: ""})} maxWidth="sm" fullWidth>
            <DialogTitle style={{height: "48px", background: this.props.muiTheme.palette.primary1Color, color: "#fff"}}>
                <div>
                    <Title {...this.props} />
                </div>
            </DialogTitle>
            <DialogContent style={{width: "100%", paddingTop: "24px", background: "rgb(250, 250, 250)", boxSizing: "border-box"}}>
                <SelectedResources {...this.props} />
                <SourceSelector {...this.props} {...this.state} onChange={selectedSource => this.setState(selectedSource)}/>
            </DialogContent>
            <DialogActions style={{padding: "0 24px 24px", background: "rgb(250, 250, 250)"}}>
                <Actions {...this.props} {...this.state} />
            </DialogActions>
        </Dialog>;
    }
}
