import * as React from "react";
import * as PropTypes from "prop-types";
import {IconButton, Tooltip} from "@material-ui/core";
import {Close} from "@material-ui/icons";

const Component: React.FunctionComponent<any> = props => {
    return <div>
        <span style={{lineHeight: "48px"}} data-qa="users-permissions-env-permissions-modal-title">User Environment Permissions</span>
        <Tooltip title="Close">
            <IconButton style={{position: "absolute", top: "17px", right: "24px"}} onClick={props.onClose}>
                <Close style={{color: "rgba(255, 255, 255, 0.85)"}}/>
            </IconButton>
        </Tooltip>
    </div>;
}

Component.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default Component;
