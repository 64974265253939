import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import convertToUrlFriendly from "../../../../lib/convertToUrlFriendly";
import * as features from "../../../../lib/feature-helpers";
import * as validateData from "../../../../lib/validate-cdrs-data";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
class WStep1 extends React.Component<any, any> {
    public static propTypes = {
        cdrs: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        environments: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        parentState: PropTypes.object.isRequired,
        onSetParentState: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {
            dataValidation: {},
        };
    }

    public componentDidMount() {
        this.props.onSetParentState({isNextWStepEnabled: false});
        this.validateData();
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.parentState.data) !== JSON.stringify(prevProps.parentState.data)) {
            this.validateData();
        }
    }

    public render() {
        const environmentPhi = this.props.environments?.selected?.data?.phi;
        const featureSmileCdrCustomization = features.check(":SMILE_CDR_CUSTOMIZATION:", this.props.features.data);

        return <>
            <TextField
                name="name" value={this.props.parentState.data.name || ""}
                placeholder="Display name" label="Display Name" helperText={this.state.dataValidation.name}
                error={!!this.state.dataValidation.name}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetParentState((prevParentState) => ({
                    data: {
                        ...prevParentState.data,
                        name: event.target.value,
                        cdrId: convertToUrlFriendly(event.target.value.substring(0, 18)),
                    },
                }))}
            />
            <TextField
                name="cdrId" value={this.props.parentState.data.cdrId || ""}
                placeholder={featureSmileCdrCustomization ? "Smile CDR ID" : "FHIR CDR ID"}
                label={featureSmileCdrCustomization ? "Smile CDR ID" : "FHIR CDR ID"}
                helperText={this.state.dataValidation.cdrId}
                error={!!this.state.dataValidation.cdrId}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetParentState((prevParentState) => ({
                    data: {
                        ...prevParentState.data,
                        cdrId: convertToUrlFriendly(event.target.value.substring(0, 18)),
                    },
                }))}
            />
            <TextField
                name="description" value={this.props.parentState.data.description || ""}
                placeholder="Description" label="Description"
                error={!!this.state.dataValidation.description}
                autoComplete="off" autoFocus={false} fullWidth multiline
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetParentState((prevParentState) => ({
                    data: {
                        ...prevParentState.data,
                        description: event.target.value,
                    },
                }))}
            />
            <div>
                <FormControl style={{ width: 280, marginTop: 32 }}>
                    <InputLabel id="cdr-auth-type-label">
                        Authentication Type
                    </InputLabel>
                    <Select
                        data-qa="cdr-auth-type"
                        labelId="cdr-auth-type"
                        id="cdr-auth-type"
                        value={this.props.parentState.data.authenticationType}
                        onChange={(event) => this.props.onSetParentState((prevParentState) => ({
                            data: {
                                ...prevParentState.data,
                                authenticationType: event.target.value,
                            },
                        }))}
                    >
                        <MenuItem data-qa="data-adapter-auth-type-none" value={"NONE"}>
                            None
                        </MenuItem>
                        <MenuItem data-qa="data-adapter-auth-type-smart" value={"SMART_ON_FHIR"}>
                            SMART on FHIR
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl style={{ width: 280, marginTop: 32 }}>
                    <InputLabel id="cdr-fhir-version-label">
                        FHIR Version
                    </InputLabel>
                    <Select
                        disabled
                        data-qa="cdr-fhir-version-select"
                        labelId="cdr-fhir-version-select"
                        id="cdr-fhir-version-select"
                        value={this.props.parentState.data.__temp__fhirVersionSelected}
                        onChange={(event) => this.props.onSetParentState((prevParentState) => ({
                            data: {
                                ...prevParentState.data,
                                __temp__fhirVersionSelected: event.target.value,
                            },
                        }))}
                    >
                        {/* <MenuItem data-qa="cdr-fhir-version-2" value={"1.0.2"}>
                            FHIR DSTU2 v1.0.2
                        </MenuItem>
                        <MenuItem data-qa="cdr-fhir-version-3" value={"3.0.2"}>
                            FHIR STU3 v3.0.2
                        </MenuItem> */}
                        <MenuItem data-qa="cdr-fhir-version-4" value={"4.0.1"}>
                            FHIR R4 v4.0.1
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            {!environmentPhi && this.props.parentState.data.__temp__fhirVersionSelected === "4.0.1" ? <FormControl style={{ width: 280, marginTop: 32 }}>
                <FormControlLabel label="Load sample data" style={{marginTop: "16px"}}
                    control={<Switch color="primary" checked={this.props.parentState.data.preLoadData || false}
                        onChange={(event) => this.props.onSetParentState((prevParentState) => ({
                            data: {
                                ...prevParentState.data,
                                preLoadData: event.target.checked,
                            },
                        }))}/>}/>
            </FormControl> : null}
        </>;
    }

    private validateData = () => {
        const dataValidation = validateData.onWStep1(this.props.parentState, this.props.cdrs, this.props.config);
        this.setState(
            { dataValidation },
            () => {
                const isValid = Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true);
                if (isValid !== this.props.parentState.isNextWStepEnabled) {
                    this.props.onSetParentState({isNextWStepEnabled: isValid});
                }
            },
        );
    };
}

const mapStateToProps = (state, ownProps) => ({
    cdrs: state.cdrs,
    config: state.config,
    environments: state.environments,
    features: state.features,
    ...ownProps,
});
export default connect(mapStateToProps)(WStep1);
