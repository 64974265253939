import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";

import * as validateData from "../../../../../../../../../lib/validate-app-users-data";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import AppUserToFhirUser from "../../../../Shared/AppUserToFhirUser"

class WStep1 extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        wstate: PropTypes.object.isRequired,
        onSetWState: PropTypes.func.isRequired,
    };

    public constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
        };
    }

    public componentDidMount() {
        this.props.onSetWState(
            {
                data: {
                    appUserType: "PROVIDER"
                },
                dataValidation: {},
                isNextWStepEnabled: false,
            },
            this.validateData,
        );
    }

    public componentDidUpdate(prevProps) {
        const data_string = JSON.stringify(this.props.wstate.data);
        const prevData_string = JSON.stringify(prevProps.wstate.data);
        if (data_string !== prevData_string) {
            this.validateData();
        }
    }

    public render() {
        return <>
            <TextField data-qa="new-user-name"
                name="name" value={this.props.wstate.data.name || ""}
                placeholder="Display name" label="Display Name" helperText={this.props.wstate.dataValidation.name}
                error={!!this.props.wstate.dataValidation.name}
                disabled={this.props.wstate.status !== "ready"}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetWState((prevWState) => ({
                    data: {
                        ...prevWState.data,
                        name: event.target.value,
                    },
                }))}
            />

            <TextField data-qa="new-user-email"
                name="email" value={this.props.wstate.data.email || ""}
                placeholder="Email" label="Email" helperText={this.props.wstate.dataValidation.email}
                error={!!this.props.wstate.dataValidation.email}
                disabled={this.props.wstate.status !== "ready"}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetWState((prevWState) => ({
                    data: {
                        ...prevWState.data,
                        email: event.target.value,
                    },
                }))}
            />

            <FormControl
                error={!!this.props.wstate.dataValidation.password}
                fullWidth style={{ marginTop: 16, marginBottom: 16 }}
            >
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input data-qa="new-user-password"
                    id="password" name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.props.wstate.data.password || ""}
                    placeholder="Password" autoComplete="password" fullWidth
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => this.setState((prevState) => ({ showPassword: !prevState.showPassword }))}
                                onMouseDown={(event) => event.preventDefault()}
                            >
                                {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={(event) => this.props.onSetWState((prevWState) => ({
                        data: {
                            ...prevWState.data,
                            password: event.target.value,
                        },
                    }))}
                />
                <FormHelperText>
                    {this.props.wstate.dataValidation.password}
                </FormHelperText>
            </FormControl>

            <FormControl
                disabled={this.props.wstate.status !== "ready"}
                error={!!this.props.wstate.dataValidation.gateway}
                style={{ minWidth: 280, marginTop: 16, marginBottom: 16 }}
            >
                <InputLabel id="new-user-type-select-label">
                    User Type
                </InputLabel>
                <Select data-qa="new-user-type"
                    labelId="new-user-type-select-label"
                    id="new-user-type-select"
                    value={this.props.wstate.data.appUserType}
                    onChange={(event) => this.props.onSetWState((prevWState) => ({
                        data: {
                            ...prevWState.data,
                            appUserType: event.target.value,
                        },
                    }))}
                >
                    <MenuItem data-qa="new-user-type-provider" value={"PROVIDER"}>
                        Provider
                    </MenuItem>
                    <MenuItem data-qa="new-user-type-patient" value={"PATIENT"}>
                        Patient
                    </MenuItem>
                </Select>
            </FormControl>

            <AppUserToFhirUser
                canEdit showTitle={true}
                parentState={this.props.wstate}
                onSetParentState={this.props.onSetWState}
            />
        </>;
    }

    private isDataValid = () => Object.keys(this.props.wstate.dataValidation).reduce((acc, key) => !!acc && !this.props.wstate.dataValidation[key], true);

    private isNextWStepEnabled = () => {
        const isValid = this.isDataValid()
        if (isValid !== this.props.wstate.isNextWStepEnabled) {
            this.props.onSetWState({ isNextWStepEnabled: isValid });
        }
    };

    private validateData = () => {
        const dataValidation = validateData.onWStep1(this.props.wstate);
        this.props.onSetWState(
            { dataValidation },
            this.isNextWStepEnabled,
        );
    };
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
export default connect(mapStateToProps)(WStep1);
