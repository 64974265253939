import * as React from 'react';
import * as PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import CustomDialogActions from "./CustomDialogActions";
import CustomDialogContent from "./CustomDialogContent";
import CustomDialogTitle from "./CustomDialogTitle";

class DeleteConfirm extends React.Component<any, any> {
    public static propTypes = {
        delete: PropTypes.object.isRequired,
        error: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,

        action: PropTypes.string,
        confirmName: PropTypes.bool,
        description: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element
        ]),
        title: PropTypes.string,
        type: PropTypes.string,
    }

    public static defaultProps = {
        action: "delete",
        confirmName: false,
        description: <span>This action <strong>CANNOT</strong> be undone!</span>,
        error: "",
        title: "Confirm Delete Action",
        type: "resource",
    };

    public constructor(props) {
        super(props);
        this.state = {
            confirmName: ""
        };
    }

    public render() {
        let canDelete = true;
        if (this.props.confirmName) {
            if (this.state.confirmName === this.props.delete.name) {
                canDelete = true;
            }
            else {
                canDelete = false;
            }
        }

        return <Dialog
            open disableBackdropClick disableEscapeKeyDown scroll="body"
            onClose={this.props.onClose}
        >
            <CustomDialogTitle
                title={this.props.title}
                onClose={this.props.onClose}
            />
            <CustomDialogContent
                action={this.props.action}
                confirmName={this.props.confirmName}
                delete={this.props.delete}
                description={this.props.description}
                error={this.props.error}
                parentState={this.state}
                type={this.props.type}
                onSetParentState={this.onSetState}
            />
            <CustomDialogActions
                action={this.props.action}
                canDelete={canDelete}
                error={this.props.error}
                type={this.props.type}
                onClose={this.props.onClose}
                onDelete={this.props.onDelete}
            />
        </Dialog >
    }

    private onSetState = (state, cb) => this.setState(state, cb);
}

export default DeleteConfirm;
