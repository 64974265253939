const logEventItemsArr = [
    'APP_ERROR',
    'LAUNCH_ENDPOINT_REACHED',
    'LAUNCH_ENDPOINT_VALIDATED',
    'LAUNCH_EXTERNAL_METADATA_FETCHED',
    'LAUNCH_PREPARING_TO_REDIRECT_TO_EXTERNAL_AUTHORIZE',
    'REDIRECT_ENDPOINT_REACHED',
    'REDIRECT_ACCESS_TOKEN_FETCHED',
    'REDIRECT_LAUNCH_CONTEXT_REGISTRATION_SUCCESS',
    'LAUNCH_REGISTERED',
    'REDIRECT_ABOUT_TO_REDIRECT',
    'AUTHORIZE_PROXY_AUTH_SUCCESS',
    'AUTHORIZE_ENDPOINT_REACHED',
    'AUTHORIZE_GATEWAY_ACTIVATION_VALIDATED',
    'TOKEN_ENDPOINT_REACHED',
    'TOKEN_CREATED',
    'LAUNCH_VALIDATION_FAILED_ERROR',
    'LAUNCH_EXTERNAL_METADATA_FETCH_ERROR',
    'REDIRECT_EXTERNAL_AUTHORIZE_ERROR',
    'REDIRECT_EXTERNAL_TOKEN_ERROR',
    'REDIRECT_LAUNCH_CONTEXT_REGISTRATION_ERROR',
    'REDIRECT_ASYNC_UPDATES',
    'AUTHORIZE_ENDPOINT_ERROR',
    'TOKEN_ENDPOINT_ERROR',
    'INCOMPLETE_LAUNCH_ERROR',
] as const

type TLogEventItem = typeof logEventItemsArr[number]

const logEventItems: Readonly<TLogEventItem[]> = logEventItemsArr

export default logEventItems
