import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../../redux/actions";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress/";
import { isEnvironmentAdmin } from "../../../../../lib/user-environment-permissions-helpers";
import { Button, Card, CardContent, TextField } from "@material-ui/core";
import { Link } from "@material-ui/icons";
import { Alert, AlertTitle } from '@material-ui/lab';
import { styled } from '@material-ui/core/styles';
import Header from "../../../../Widgets/Editor/Header";
import { getPath } from "../../../../../lib/utils";
import * as validateData from "../../../../../lib/validate-cdrs-data";
import consoleLogger from "../../../../../lib/consoleLogger";
import ReactJson from "react-json-view";
import ActionButtons from "./ActionButtons";

const InfoAlert = styled(Alert)({
    '& .MuiAlert-action': {
        alignItems: 'flex-start',
        marginTop: '20px'
    }
});
class DataManager extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        parentState: PropTypes.object.isRequired,
        initialize: PropTypes.func.isRequired,
        onSetParentState: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {
            error: "",
            info: ""
        };
    }

    public componentDidMount() {
        this.validateData();
    }

    public componentDidUpdate(prevProps) {
        if (this.props.parentState.importString !== prevProps.parentState.importString) {
            this.validateData();
        }
        if (this.props.cdrs.selected.dataManager.status !== prevProps.cdrs.selected.dataManager.status) {
            if (this.props.cdrs.selected.dataManager.status === "success") {
                this.setState({info: this.props.cdrs.selected.dataManager.result, error: ""})
            } else if (this.props.cdrs.selected.dataManager.status === "error") {
                this.setState({info: "", error: this.props.cdrs.selected.dataManager.result})
            }
        }
    }

    public render() {
        if(["pending"].includes(this.props.cdrs.selected.dataManager.status)) {
            return <CenteredCircularProgress size={63} style={{padding: 24}}/>
        }

        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);
        if (!actAsAdmin) {
            return <div className="container">
                <Card style={{marginTop: "48px"}}>
                    <Header {...this.props} style={{height: "32px", padding: "8px 16px", borderTop: "none"}}/>
                    <CardContent>
                        <p style={{textAlign: "center"}}>
                            <big>
                                <strong>
                                    This screen is only available to the account owners or environment admins
                                </strong>
                            </big>
                        </p>
                    </CardContent>
                </Card>
            </div>
        }

        const isValid = Object.keys(this.props.parentState.dataValidation).reduce((acc, key) => acc && !this.props.parentState.dataValidation[key], true);

        let importString = getPath(this.props, "parentState.importString") || "";
        let importObject;
        try {
            importObject = JSON.parse(importString);
        } catch (reason) {
            importObject = {};
        }
        if (!importObject) {
            importObject = {};
        }

        return <div style={{marginTop: "48px"}}>
            {this.state.error ? <Alert severity="error" onClose={() => {this.setState({ error: "" })}}>{this.state.error}</Alert> : null}
            {this.state.info ? <InfoAlert severity="info" onClose={() => {this.setState({ info: "" })}}>
                <AlertTitle>Result</AlertTitle>
                <ReactJson src={this.state.info} name="Response" indentWidth={4} collapsed={false} enableClipboard displayObjectSize displayDataTypes sortKeys
                    style={{overflowX: "auto"}}/></InfoAlert> : null}
            <Card style={{marginTop: "48px"}}>
                <Header {...this.props} leftIcon={<Link style={{paddingTop: "4px", marginRight: "24px"}}/>} style={{height: "56px"}}
                    primaryText={<div style={{paddingTop: "4px", opacity: .5}}>
                        Import FHIR JSON
                    </div>}
                    rightIcon={<div>
                        <input type="file" accept="application/json" id="cdr-file-import" disabled={!actAsAdmin} style={{display: "none"}} onChange={(event) => this.importFromFile(event)}/>
                        <label htmlFor="cdr-file-import">
                            <Button variant="contained" disabled={!actAsAdmin} component="span" style={{display: "inline-block", marginRight: "8px", width: "150px"}}>
                                Import From File
                            </Button>
                        </label></div>}/>
                <div style={{padding: "24px 24px 0 24px"}}>
                    <TextField name="jwk-set" value={importString} label="FHIR Bundle JSON" error={!!this.props.parentState.dataValidation.import}
                        helperText={this.props.parentState.dataValidation.import} fullWidth multiline onChange={e => this.props.onSetParentState({importString: e.target.value})}/>
                    <ReactJson src={importObject} name="JSON Preview" indentWidth={4} collapsed={true} enableClipboard displayObjectSize displayDataTypes sortKeys
                        style={{paddingTop: "24px", overflowX: "auto"}}/>
                </div>
                <ActionButtons {...this.props} sendImport={this.sendImport} isValid={isValid} actAsAdmin={actAsAdmin}/>
            </Card>
        </div>;
    }

    private validateData = () => {
        const dataValidation = validateData.onEditImportJSON(this.props.parentState);
        this.props.onSetParentState({dataValidation});
    }

    private sendImport = (inputData) => {
        this.setState({error: ""})
        let parsedData = JSON.parse(inputData);
        if (parsedData?.resourceType) {
            const parentStateData = this.props.parentState.data
            const path = `api/${parentStateData.accountId}/env/${parentStateData.environmentId}/cdr/${parentStateData.cdrId}`
            const config = this.props.config

            this.props.cdrsImport(config, parsedData.resourceType === 'Bundle' ? path : `${path}/${parsedData.resourceType}`, parsedData)

        } else {
            this.setState({info: "", error: "Error importing data. Missing 'resourceType' attribute."})
        }
    }

    private importFromFile = (event) => {
        let fr = new FileReader();
        let file: File = getPath(event, "target.files.0");
        if (file.size > 4194304) {
            this.setState({info: "", error: "Error loading file. Max supported JSON size is 4MB"})
        } else {
            fr.onload = (e) => {
                let inputData;
                let formatted = '';
                try {
                    inputData = JSON.parse(e.target.result as string);
                    formatted = JSON.stringify(inputData, null, 2);
                } catch (err) {
                    consoleLogger.log(err)
                    this.setState({info: "", error: "Error loading file. Make sure you are inputing a valid JSON file."})
                }
                if (formatted) {
                    this.sendImport(formatted)
                }
            };

            fr.readAsText(file);
        }
    }

}

const mapStateToProps = (state, ownProps) => ({
    cdrs: state.cdrs,
    config: state.config,
    features: state.features,
    login: state.login,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdrsImport: actions.cdrsImport,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataManager));
