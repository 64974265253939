import * as PropTypes from "prop-types";
import CircularProgress from '@material-ui/core/CircularProgress';

const CenteredCircularProgress = (props) => {
    return <div className={props.className} style={{width: "100%", ...props.style}}>
        <div style={{width: "fit-content", marginRight: "auto", marginLeft: "auto"}}>
            <CircularProgress size={props.size} />
        </div>
    </div>;
}

CenteredCircularProgress.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.object
};

CenteredCircularProgress.defaultProps = {
    className: "",
    size: 40,
    style: {}
};

export default CenteredCircularProgress;
