import * as React from "react";
import * as PropTypes from "prop-types";
import axiosWrapper from "../../../../../../lib/axiosWrapper";
import getMessageFromResponse from "../../../../../../lib/getMessageFromResponse";
import consoleLogger from "../../../../../../lib/consoleLogger";
import {CircularProgress, Button} from "@material-ui/core";

const Component = props => {
    const content = [
        <Button variant="outlined" key="cancel" style={{marginRight: "8px"}} onClick={() => props.onSetState({dialog: ""})}>
            {props.state.dataValidation.invitationEmailSentConfirmed ? "Close" : "Cancel"}
        </Button>,
        <Button variant="contained" data-qa="users-permissions-invite-new-invite-button" key="Invite" color="primary"
            disabled={props.state.status !== "ready" || !props.state.data.invitationEmail || Object.keys(props.state.dataValidation).some((key) => props.state.dataValidation[key])}
            onClick={() => {
                props.onSetDialogState(
                    {status: "wait"},
                    () => {
                        // eslint-disable-next-line react/prop-types
                        axiosWrapper(props.config.accountApi, "invite-user", "POST", {email: props.state.data.invitationEmail, sentFrom: props.login.data.name})
                            .then(response => {
                                props.onSetDialogState(
                                    {
                                        status: "ready",
                                        data: {
                                            ...props.state.data,
                                            invitationEmail: ""
                                        },
                                        dataValidation: {
                                            ...props.state.dataValidation,
                                            invitationEmailSentConfirmed: getMessageFromResponse(response, false) || "Invitation sent!",
                                            invitationEmailSentRejected: ""
                                        }
                                    },
                                    props.initialize()
                                );
                            })
                            .catch(reason => {
                                props.onSetDialogState({
                                    status: "ready",
                                    dataValidation: {
                                        ...props.state.dataValidation,
                                        invitationEmailSentConfirmed: "",
                                        invitationEmailSentRejected: getMessageFromResponse(reason, true)
                                    }
                                });
                                consoleLogger.log("::: reason:", reason);
                            })
                    }
                );
            }}>
            {props.state.status === "wait" ? <CircularProgress size={24}/> : null} Send Invitation
        </Button>
    ];

    return <div>{content}</div>;
}

Component.propTypes = {
    config: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetDialogState: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
