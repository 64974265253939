// import getActiveEnvironment, { ActiveEnvironment } from "./getActiveEnvironment";
import getHostnameInfo from "./getHostnameInfo";

function checkSmileCdrCustomization(): boolean {
    const { accountId } = getHostnameInfo();
    if (accountId === "smilecdr") return true;

    return false;
}

function checkSkipTos(): boolean {
    // const activeEnv: ActiveEnvironment = getActiveEnvironment();
    // if (activeEnv === ":LOCAL:" || activeEnv === ":DEVELOPMENT:") {
    //     return false;
    // }

    // return true;

    return false;
}

export function check(
    id: string = "",
    features = {},
): boolean {
    const feature = features[id];

    if (!feature) return false;

    if (!feature.isActive) return false;

    // TODO:
    // availableAfter
    // availableBefore

    switch (id) {
        case ":SKIP_TOS:": return checkSkipTos();
        case ":SMILE_CDR_CUSTOMIZATION:": return checkSmileCdrCustomization();
    }

    return true;
}

export function getAll(features = {}): Array<any> {
    const all: Array<any> = [];
    for (const key in features) {
        if (features.hasOwnProperty(key)) {
            const feature = features[key];
            feature.id = feature.id ? feature.id : key;
            all.push(feature);
        }
    }

    return all;
}
