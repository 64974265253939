import * as React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
// import Stepper from '@material-ui/core/Stepper'
// import Step from '@material-ui/core/Step'
// import StepLabel from '@material-ui/core/StepLabel'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import type { TAppActivation, TGateway } from '../../../../../../../types'
import CenteredCircularProgress from '../../../../../../Widgets/CenteredCircularProgress/'
import Header from '../../../../../../Widgets/Editor/Header/'
import type {
    TAuthMode,
    TDataValidation,
    TEhrAuthType,
    TStatus as TEStatus,
    TProxyAuthType,
    TSystemAuthType
} from '..'
import ESteps from './ESteps'
import LaunchDetails from './LaunchDetails'

type TProps = {
    actAsAdmin: boolean
    appActivation: TAppActivation
    authMode: TAuthMode
    canEdit: boolean
    dataValidation: TDataValidation
    ehrAuthType: TEhrAuthType
    estatus: TEStatus
    estep: number
    disableAuthMode: boolean
    gateways: TGateway[]
    isAllscriptsUnityGateway: boolean
    isAppLaunchingWithinIKMG2: boolean
    isCernerR4Gateway: boolean
    isEpicR4Gateway: boolean
    isIKnowMedExtGateway: boolean
    isExtAuthDa: boolean
    isExtAuthGtw: boolean
    isEpicR4ExternalGateway: boolean
    isLaunchProxyRequired: boolean
    isVarianR4Gateway: boolean
    muiTheme: any
    proxyAuthType: TProxyAuthType
    selectedGatewayId: string
    systemAuthType: TSystemAuthType
    setAppActivation: React.Dispatch<React.SetStateAction<TAppActivation>>
    setAuthMode: React.Dispatch<React.SetStateAction<TAuthMode>>
    setEdit: React.Dispatch<React.SetStateAction<boolean>>
    setEhrAuthType: React.Dispatch<React.SetStateAction<TEhrAuthType>>
    setEStatus: React.Dispatch<React.SetStateAction<TEStatus>>
    setIsAppLaunchingWithinIKMG2: React.Dispatch<React.SetStateAction<boolean>>
    setProxyAuthType: React.Dispatch<React.SetStateAction<TProxyAuthType>>
    setSystemAuthType: React.Dispatch<React.SetStateAction<TSystemAuthType>>
}

const CustomDialogContent: React.FC<TProps> = (props: TProps) => (
    <DialogContent style={{ minWidth: '552px', padding: 0, background: 'rgb(250, 250, 250)' }}>
        {/* <Stepper
            activeStep={props.estep - 1}
            style={{ background: 'rgb(250, 250, 250)' }}
        >
            <Step>
                <StepLabel>Basic info</StepLabel>
            </Step>
        </Stepper> */}

        {props.actAsAdmin && !props.canEdit ? (
            <Header rightIcon={(
                <Tooltip title="Edit">
                    <IconButton
                        data-e2e="act_edit_btn"
                        onClick={() => {
                            props.setEdit(true)
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            )} />
        ) : null}

        {[':READY:', ':WAITING:'].includes(props.estatus)
            ? (
                <>
                    <ESteps
                        appActivation={props.appActivation}
                        authMode={props.authMode}
                        canEdit={props.canEdit}
                        dataValidation={props.dataValidation}
                        disableAuthMode={props.disableAuthMode}
                        ehrAuthType={props.ehrAuthType}
                        estatus={props.estatus}
                        estep={props.estep}
                        gateways={props.gateways}
                        isAllscriptsUnityGateway={props.isAllscriptsUnityGateway}
                        isAppLaunchingWithinIKMG2={props.isAppLaunchingWithinIKMG2}
                        isCernerR4Gateway={props.isCernerR4Gateway}
                        isEpicR4Gateway={props.isEpicR4Gateway}
                        isEpicR4ExternalGateway={props.isEpicR4ExternalGateway}
                        isExtAuthDa={props.isExtAuthDa}
                        isExtAuthGtw={props.isExtAuthGtw}
                        isIKnowMedExtGateway={props.isIKnowMedExtGateway}
                        isLaunchProxyRequired={props.isLaunchProxyRequired}
                        isVarianR4Gateway={props.isVarianR4Gateway}
                        muiTheme={props.muiTheme}
                        proxyAuthType={props.proxyAuthType}
                        systemAuthType={props.systemAuthType}
                        setAppActivation={props.setAppActivation}
                        setAuthMode={props.setAuthMode}
                        setEhrAuthType={props.setEhrAuthType}
                        setEStatus={props.setEStatus}
                        setIsAppLaunchingWithinIKMG2={props.setIsAppLaunchingWithinIKMG2}
                        setProxyAuthType={props.setProxyAuthType}
                        setSystemAuthType={props.setSystemAuthType}
                    />
                    {props.isLaunchProxyRequired ? (
                        <LaunchDetails
                            appActivation={props.appActivation}
                            ehrAuthType={props.ehrAuthType}
                            isEpicR4Gateway={props.isEpicR4Gateway}
                            selectedGatewayId={props.selectedGatewayId}
                        />
                    ) : null}
                </>
            )
            : (
                <CenteredCircularProgress
                    size={63}
                    style={{ padding: '24px', paddingLeft: 0 }}
                />
            )}
    </DialogContent>
)

export default CustomDialogContent
