/* eslint-disable react/prop-types */
import * as React from "react";
import BasicInfo from "./BasicInfo";
import Email from "./Email";
import Password from "./Password";

export default props => {
    return <div>
        <BasicInfo {...props} />
        {props.state.data.type !== "SSO" && <Password {...props} />}
        <Email {...props} />
    </div>;
}
