import * as PropTypes from "prop-types";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";

const BooleanItem = (props) => {
    const value = props.jsonObj[props.name];

    return (
        <div data-qa="boolean-item" style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 16,
            marginBottom: 6,
        }}>
            <span
                data-qa="name"
                style={{ display: "block", width: 200, marginRight: 6 }}
            >
                {props.name}:
            </span>
            <div>
                <div data-qa="boolean-item-options">
                    <FormControlLabel
                        label="Yes"
                        control={<Radio
                            name="phi-yes"
                            value
                            checked={value === true}
                            disabled={!props.canEdit}
                            color="primary"
                            onChange={() => props.onSetState((prevState) => ({
                                isNextWStepEnabled: false,
                                data: {
                                    ...prevState.data,
                                    configuration: {
                                        ...prevState.data.configuration,
                                        json: JSON.stringify({
                                            ...props.jsonObj,
                                            [props.name]: true,
                                        }),
                                    },
                                },
                            }))}
                        />}
                        style={{ marginRight: 32 }}
                    />
                    <FormControlLabel
                        label="No"
                        control={<Radio
                            name="phi-no"
                            value={false}
                            checked={value === false}
                            disabled={!props.canEdit}
                            color="primary"
                            onChange={() => props.onSetState((prevState) => ({
                                isNextWStepEnabled: false,
                                data: {
                                    ...prevState.data,
                                    configuration: {
                                        ...prevState.data.configuration,
                                        json: JSON.stringify({
                                            ...props.jsonObj,
                                            [props.name]: false,
                                        }),
                                    },
                                },
                            }))}
                        />}
                    />
                </div>
                {props.helperText ? (<TextField
                    name="da-boolean-item-helper-text" value="" disabled
                    helperText={props.helperText}
                    autoComplete="off" autoFocus={false} fullWidth
                    InputProps={{ style: { display: "none" } }}
                    FormHelperTextProps={{ style: { marginTop: "-5px" } }}
                    classes={{ root: "disabled-text-field" }}
                />) : null}
            </div>
        </div>
    );
}

BooleanItem.propTypes = {
    canEdit: PropTypes.bool.isRequired,
    helperText: PropTypes.string,
    jsonObj: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    onSetState: PropTypes.func.isRequired,
}

export default BooleanItem
