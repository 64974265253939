import getHostnameInfo from "../../lib/getHostnameInfo";

declare const _;

import axiosWrapper from "../../lib/axiosWrapper";
import getEnvironmentsCallUrl from "../../lib/getEnvironmentsCallUrl";
import getGatewaysCallUrl from "../../lib/getGatewaysCallUrl";
import {getPath} from "../../lib/utils/";
import getLocationInfo from "../../lib/getLocationInfo";
import moment from "moment";
import consoleLogger from "../../lib/consoleLogger";

// The following functions are not real action creators.
// They just make API calls and do not produce Redux actions.
// Thanks to the `redux-thunk` middleware we have an access to the
// application state.

// -----------------------------------------------------------------------------
const _accountsGetPublicData = _.throttle(
    async function (_, getState: Function): Promise<any> {
        const state = getState();
        const baseUrl = state.config.accountApi;
        const { accountId } = getHostnameInfo()
        const path: string = `accounts/public?path=${accountId}`;
        try {
            return await axiosWrapper(baseUrl, path, "GET");
        } catch (reason) {
            consoleLogger.error("::: reason:", reason);
        }
    },
    550, { "trailing": false },
);

export function accountsGetPublicData (): Function {
    return _accountsGetPublicData;
}

// -----------------------------------------------------------------------------
const _accountsRequestConfirmationCode = _.throttle(
    function (data: any, { bypassError = false }) {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                const baseUrl = state.config.accountApi;
                const path: string = "confirmations";
                try {
                    return await axiosWrapper(baseUrl, path, "POST", data, undefined, undefined, false);
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                    if (bypassError) {
                        throw reason;
                    }
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function accountsRequestConfirmationCode (data: any, cfg = {}): Function {
    return _accountsRequestConfirmationCode(data, cfg);
}

// -----------------------------------------------------------------------------
const _accountsRequestAccess = _.throttle(
    function (data: any, { bypassError = false }) {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                const baseUrl = state.config.accountApi;
                const path: string = "access-request";
                try {
                    return await axiosWrapper(baseUrl, path, "POST", data);
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                    if (bypassError) {
                        throw reason;
                    }
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function accountsRequestAccess (data: any, cfg = {}): Function {
    return _accountsRequestAccess(data, cfg);
}

// -----------------------------------------------------------------------------
const _accountsVerifyConfirmationCode = _.throttle(
    function (data: any, { bypassError = false }) {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                const baseUrl = state.config.accountApi;
                const path: string = "verify";
                try {
                    return await axiosWrapper(baseUrl, path, "POST", data);
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                    if (bypassError) {
                        throw reason;
                    }
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function accountsVerifyConfirmationCode (data: any, cfg = {}): Function {
    return _accountsVerifyConfirmationCode(data, cfg);
}

// -----------------------------------------------------------------------------
const _cdsServiceSetsCheckDiscovery = _.throttle(
    function (
        endpoint: string = "",
        accountId: string = "",
        environmentId: string = "",
        { bypassError = false } = {},
    ) {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                const baseUrl = state.config.envApi;
                const path: string = `${getEnvironmentsCallUrl(accountId)}/${environmentId}/cds-service-set/check-cds-discovery?endpoint=${endpoint}`;
                const cfg = { bypassError };
                try {
                    return await axiosWrapper(baseUrl, path, "GET", null, cfg);
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                    if (bypassError) {
                        throw reason;
                    }
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function cdsServiceSetsCheckDiscovery (
    endpoint: string = "",
    accountId: string = "",
    environmentId: string = "",
    cfg = {}
): Function {
    return _cdsServiceSetsCheckDiscovery(endpoint, accountId, environmentId, cfg);
}

// -----------------------------------------------------------------------------
const _dataSourcesGetAvailableDataAdapters = _.throttle(
    function (accountId: string = "") {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                const baseUrl = state.config.envApi;
                const path: string = `api/${accountId}/available-data-adapters`;
                try {
                    return await axiosWrapper(baseUrl, path, "GET");
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function dataSourcesGetAvailableDataAdapters (accountId: string = ""): Function {
    return _dataSourcesGetAvailableDataAdapters(accountId);
}

// -----------------------------------------------------------------------------
const _getGatewayJwkSet = _.throttle(
    async function (_, getState: Function): Promise<any> {
        const state = getState();
        const baseUrl = state.config.envApi;
        const path: string = "api/$get-gateway-jwk-set";
        const cfg = { withCredentials: false };
        try {
            return await axiosWrapper(baseUrl, path, "GET", null, cfg);
        } catch (reason) {
            consoleLogger.error("::: reason:", reason);
        }
    },
    550, { "trailing": false },
);

export function getGatewayJwkSet (): Function {
    return _getGatewayJwkSet;
}

// -----------------------------------------------------------------------------
const _gatewayClearCache = _.throttle(
    function (
        accountId: string,
        environmentId: string,
        gatewayId: string,
    ) {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                const baseUrl = state.config.envApi;
                const path: string = `${getGatewaysCallUrl(accountId, environmentId)}/cache/${gatewayId}`;
                try {
                    return await axiosWrapper(baseUrl, path, "DELETE");
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function gatewayClearCache (
    accountId: string = "",
    environmentId: string = "",
    gatewayId: string = "",
): Function {
    return _gatewayClearCache(accountId, environmentId, gatewayId);
}

// -----------------------------------------------------------------------------
const _gatewayPutJwkSet = _.throttle(
    function (
        accountId: string,
        environmentId: string,
        gatewayId: string,
        jwkSet: any,
        { bypassError = false }
    ) {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                const baseUrl = state.config.envApi;
                const path: string = `${getGatewaysCallUrl(accountId, environmentId)}/${gatewayId}/auth-cds`;
                const data = { jwkSet };
                try {
                    return await axiosWrapper(baseUrl, path, "PUT", data);
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                    if (bypassError) {
                        throw reason;
                    }
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function gatewayPutJwkSet (
    accountId: string = "",
    environmentId: string = "",
    gatewayId: string = "",
    jwkSet = "",
    cfg = {}
): Function {
    return _gatewayPutJwkSet(accountId, environmentId, gatewayId, jwkSet, cfg);
}

// -----------------------------------------------------------------------------
const _tosGetLatest = _.throttle(
    async function (_, getState: Function): Promise<any> {
        const state = getState();
        const baseUrl = state.config.accountApi;
        const path: string = "terms/latest";
        const cfg = { withCredentials: false };
        try {
            return await axiosWrapper(baseUrl, path, "GET", null, cfg);
        } catch (reason) {
            consoleLogger.error("::: reason:", reason);
        }
    },
    550, { "trailing": false },
);

export function tosGetLatest (): Function {
    return _tosGetLatest;
}

// -----------------------------------------------------------------------------
const _tosGetAll = _.throttle(
    async function (_, getState: Function): Promise<any> {
        const state = getState();
        const baseUrl = state.config.accountApi;
        const path: string = "terms/all";
        const cfg = { withCredentials: false };
        try {
            return await axiosWrapper(baseUrl, path, "GET", null, cfg);
        } catch (reason) {
            consoleLogger.error("::: reason:", reason);
        }
    },
    550, { "trailing": false },
);

export function tosGetAll (): Function {
    return _tosGetAll;
}

// -----------------------------------------------------------------------------
const _usersAcceptTos = _.memoize(
    function (version: string) {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                const baseUrl = state.config.accountApi;
                const userUuid: string = getPath(state, "login.data.uuid");
                const path: string = `users/${userUuid}/accept-tos`;
                const data = { version };
                try {
                    return await axiosWrapper(baseUrl, path, "POST", data);
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                }
            },
            550, { "trailing": false },
        );
    },
);

export function usersAcceptTos (version: string): Function {
    return _usersAcceptTos(version);
}

// -----------------------------------------------------------------------------
const _usersCompleteInvitation = _.throttle(
    function (data: any, { bypassError = false }) {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                const baseUrl = state.config.accountApi;
                const path: string = "invite-user-complete";
                try {
                    return await axiosWrapper(baseUrl, path, "POST", data);
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                    if (bypassError) {
                        throw reason;
                    }
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function usersCompleteInvitation (data: any, cfg = {}): Function {
    return _usersCompleteInvitation(data, cfg);
}

// -----------------------------------------------------------------------------
const _usersCreate = _.throttle(
    function (data: any) {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                const baseUrl = state.config.accountApi;
                const path: string = "users";
                try {
                    return await axiosWrapper(baseUrl, path, "POST", data);
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function usersCreate (data: any): Function {
    return _usersCreate(data);
}

// -----------------------------------------------------------------------------
const _usersGetCurrent = _.throttle(
    async function (_, getState: Function): Promise<any> {
        const state = getState();
        const baseUrl = state.config.accountApi;
        const path: string = "users/current";
        try {
            return await axiosWrapper(baseUrl, path, "GET");
        } catch (reason) {
            // console.error("::: reason:", reason);
            console.warn("::: User is not logged-in");
        }
    },
    550, { "trailing": false },
);

export function usersGetCurrent (): Function {
    return _usersGetCurrent;
}

// -----------------------------------------------------------------------------
const _usersGetCurrentSSO: any = _.throttle(
    async function (_, getState: Function): Promise<any> {
        const state: any = getState();
        const baseUrl: any = state.config.accountApi;
        const path: string = "users/current-sso";
        try {
            return await axiosWrapper(baseUrl, path, "GET");
        } catch (reason) {
            // console.error("::: reason:", reason);
            console.warn("::: User is not logged-in");
        }
    },
    550, { "trailing": false },
);

export function usersGetCurrentSSO (): Function {
    return _usersGetCurrentSSO;
}

// -----------------------------------------------------------------------------
const _usersResetPasswordRequest = _.throttle(
    function (data: any, { bypassError = false }) {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                const baseUrl = state.config.accountApi;
                const path: string = "reset-password";
                try {
                    return await axiosWrapper(baseUrl, path, "POST", data);
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                    if (bypassError) {
                        throw reason;
                    }
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function usersResetPasswordRequest (data = {}, cfg = {}): Function {
    return _usersResetPasswordRequest(data, cfg);
}

// -----------------------------------------------------------------------------
const _userResetPasswordConfirm = _.throttle(
    function (data: any, { bypassError = false }) {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                const baseUrl = state.config.accountApi;
                const path: string = "reset-password-confirm";
                try {
                    return await axiosWrapper(baseUrl, path, "POST", data);
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                    if (bypassError) {
                        throw reason;
                    }
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function userResetPasswordConfirm (data = {}, cfg = {}): Function {
    return _userResetPasswordConfirm(data, cfg);
}

// -----------------------------------------------------------------------------
const _dataSourcesGetDataAdaptersHealth = _.throttle(
    function (location, timeframe) {
        return _.throttle(
            async function (_, getState: Function): Promise<any> {
                const state = getState();
                let baseUrl = Object.assign({}, state.config.monitoringApi);
                baseUrl.path = "";
                const { accountId } = getHostnameInfo();
                const { env } = getLocationInfo(location, state.config);
                let path: string = `monitoring/data-adapter/health/${accountId}/${env}`;
                const cfg = { withCredentials: false };

                let endTime = new Date().getTime();
                let startTime = endTime - timeframe;
                if (timeframe === 0 || timeframe === 86400000) {
                    [startTime, endTime] = getStartTime(timeframe);
                }
                path += `?startTime=${startTime}&endTime=${endTime}`;

                try {
                    return await axiosWrapper(baseUrl, path, "GET", null, cfg);
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function dataSourcesGetDataAdaptersHealth (location, timeframe) {
    return _dataSourcesGetDataAdaptersHealth(location, timeframe);
}

// -----------------------------------------------------------------------------
const _dataSourcesGetAppHealth = function (location, timeframe, appId, cache) {
    return async function (_, getState: Function): Promise<any> {
        const state = getState();
        let baseUrl = Object.assign({}, state.config.monitoringApi);
        baseUrl.path = "";
        const { accountId } = getHostnameInfo();
        const { env } = getLocationInfo(location, state.config);
        let path: string = `monitoring/apps/health/${accountId}/${env}?appId=${appId}&cache=${cache}`;
        const cfg = { withCredentials: false };

        let endTime = new Date().getTime();
        let startTime = endTime - timeframe;
        if (timeframe === 0 || timeframe === 86400000) {
            [startTime, endTime] = getStartTime(timeframe);
        }

        path += `&startTime=${startTime}&endTime=${endTime}`;
        try {
            return await axiosWrapper(baseUrl, path, "GET", null, cfg);
        } catch (reason) {
            consoleLogger.error("::: reason:", reason);
        }
    }
};

export function dataSourcesGetAppHealth (location, timeframe, appId, cache) {
    return _dataSourcesGetAppHealth(location, timeframe, appId, cache);
}

export function getStartTime (timeframe) {
    let startTime = 0;
    let endTime = Date.now();
    if (timeframe === 0) {
        startTime = moment(endTime).hour(0).minute(0).second(0).millisecond(0).valueOf();
    } else {
        startTime = moment(endTime).hour(0).minute(0).second(0).millisecond(0).subtract(86400000, "ms").valueOf();
        endTime = startTime + 86399999;
    }

    return [startTime, endTime];
}
