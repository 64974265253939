import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import convertToUrlFriendly from "../../../../../../../../../lib/convertToUrlFriendly";
import * as validateData from "../../../../../../../../../lib/validate-cds-service-sets-data";
import TextField from "@material-ui/core/TextField";

class WStep1 extends React.Component<any, any> {
    public static propTypes = {
        cdsServiceSets: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        parentState: PropTypes.object.isRequired,
        onSetParentState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.props.onSetParentState(
            {
                dataValidation: {},
                isNextWStepEnabled: false,
            },
            this.validateData,
        );
    }

    public componentDidUpdate(prevProps) {
        const data_string = JSON.stringify(this.props.parentState.data);
        const prevData_string = JSON.stringify(prevProps.parentState.data);
        if (data_string !== prevData_string) {
            this.validateData();
        }
    }

    public render() {
        return <>
            <TextField
                name="name" value={this.props.parentState.data.name || ""}
                placeholder="Display name" label="Display Name" helperText={this.props.parentState.dataValidation.name}
                error={!!this.props.parentState.dataValidation.name}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetParentState((prevParentState) => ({
                    data: {
                        ...prevParentState.data,
                        name: event.target.value,
                        cdsServiceSetId: convertToUrlFriendly(event.target.value.substring(0, 18)),
                    },
                }))}
            />
            <TextField
                name="cdsServiceSetId" value={this.props.parentState.data.cdsServiceSetId || ""}
                placeholder="CDS service set ID" label="CDS Service Set ID" helperText={this.props.parentState.dataValidation.cdsServiceSetId}
                error={!!this.props.parentState.dataValidation.cdsServiceSetId}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetParentState((prevParentState) => ({
                    data: {
                        ...prevParentState.data,
                        cdsServiceSetId: convertToUrlFriendly(event.target.value.substring(0, 18)),
                    },
                }))}
            />
            <TextField
                name="description" value={this.props.parentState.data.description || ""}
                placeholder="Description" label="Description"
                autoComplete="off" autoFocus={false} fullWidth multiline
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetParentState((prevParentState) => ({
                    data: {
                        ...prevParentState.data,
                        description: event.target.value,
                    },
                }))}
            />
        </>;
    }

    private isDataValid = () => Object.keys(this.props.parentState.dataValidation).reduce((acc, key) => !!acc && !this.props.parentState.dataValidation[key], true);

    private isNextWStepEnabled = () => {
        const isValid = this.isDataValid();
        if (isValid !== this.props.parentState.isNextWStepEnabled) {
            this.props.onSetParentState({isNextWStepEnabled: isValid});
        }
    };

    private validateData = () => {
        const dataValidation = validateData.onWStep1(this.props.parentState, this.props.cdsServiceSets, this.props.config);
        this.props.onSetParentState(
            {dataValidation},
            this.isNextWStepEnabled,
        );
    };
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    cdsServiceSets: state.cdsServiceSets,
    ...ownProps,
});
export default connect(mapStateToProps)(WStep1);
