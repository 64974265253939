import * as React from "react";
import * as PropTypes from "prop-types";
import {getPath} from "../../../../../../../lib/utils/";

const LIST_MAX_LENGTH = 5;

const Component: React.FunctionComponent<any> = props => {
    const selectedResourcesObject = getPath(props, "state.selectedResources") || {};
    const selectedResourcesArray = Object.keys(selectedResourcesObject).filter(key => selectedResourcesObject[key]);

    const title = <strong style={{color: props.muiTheme.palette.primary1Color}}>
        {selectedResourcesArray.length === 1 ? "Selected Resource:" : "Selected Resources:"}
    </strong>;

    const list = selectedResourcesArray
        .slice(0, LIST_MAX_LENGTH + 1)
        .map((resource, index) => <li key={index}>{resource}</li>);

    const more = selectedResourcesArray.length > LIST_MAX_LENGTH
        ? <em>and {selectedResourcesArray.length - LIST_MAX_LENGTH} more...</em>
        : null;

    return <ul>
        {title}
        <br/><br/>
        {list}
        {more}
    </ul>;
}

Component.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired
};

export default Component;
