import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import convertToUrlFriendly from "../../../../lib/convertToUrlFriendly";
import * as validateData from "../../../../lib/validate-environments-data";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";

class WStep1 extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        environments: PropTypes.object.isRequired,
        parentState: PropTypes.object.isRequired,
        onSetParentState: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {
            dataValidation: {},
        };
    }

    public componentDidMount() {
        this.props.onSetParentState({ isNextWStepEnabled: false });
        this.validateData();
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.parentState.data) !== JSON.stringify(prevProps.parentState.data)) {
            this.validateData();
        }
    }

    public render() {
        return <>
            <TextField
                name="name" value={this.props.parentState.data.name || ""}
                placeholder="Display name" label="Display Name" helperText={this.state.dataValidation.name}
                error={!!this.state.dataValidation.name}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetParentState((prevParentState) => ({
                    data: {
                        ...prevParentState.data,
                        name: event.target.value,
                        environmentId: convertToUrlFriendly(event.target.value.substring(0, 18)),
                    },
                }))}
            />
            <TextField
                name="environmentId" value={this.props.parentState.data.environmentId || ""}
                placeholder="Environment ID" label="Environment ID" helperText={this.state.dataValidation.environmentId}
                error={!!this.state.dataValidation.environmentId}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetParentState((prevParentState) => ({
                    data: {
                        ...prevParentState.data,
                        environmentId: convertToUrlFriendly(event.target.value.substring(0, 18)),
                    },
                }))}
            />
            <TextField
                name="description" value={this.props.parentState.data.description || ""}
                placeholder="Description" label="Description" helperText={this.state.dataValidation.description}
                error={!!this.state.dataValidation.description}
                autoComplete="off" autoFocus={false} fullWidth multiline
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetParentState((prevParentState) => ({
                    data: {
                        ...prevParentState.data,
                        description: event.target.value,
                    },
                }))}
            />
            <div>
                <p style={{ marginTop: 24, marginBottom: 0 }}>
                    <strong>Will this environment be used with PHI?</strong>
                </p>
                <FormControlLabel
                    label="Yes"
                    control={<Radio
                        name="phi-yes"
                        value={true}
                        checked={this.props.parentState.data.phi === true}
                        color="primary"
                        onChange={() => this.props.onSetParentState((prevParentState) => ({
                            data: {
                                ...prevParentState.data,
                                phi: true,
                            }
                        }))}
                    />}
                    style={{ marginRight: 48 }}
                />
                <FormControlLabel
                    label="No"
                    control={<Radio
                        name="phi-no"
                        value={false}
                        checked={this.props.parentState.data.phi === false}
                        color="primary"
                        onChange={() => this.props.onSetParentState((prevParentState) => ({
                            data: {
                                ...prevParentState.data,
                                phi: false,
                            }
                        }))}
                    />}
                />
                <TextField
                    name="phi-error" value="" disabled
                    helperText={this.state.dataValidation.phi}
                    error={!!this.state.dataValidation.phi}
                    autoComplete="off" autoFocus={false} fullWidth multiline
                    InputProps={{ style: { display: "none" } }}
                    classes={{ root: "disabled-text-field" }}
                />
            </div>
        </>;
    }

    private validateData = () => {
        const dataValidation = validateData.onWStep1(this.props.parentState, this.props.environments, this.props.config);
        this.setState(
            { dataValidation },
            () => {
                const isValid = Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true);
                if (isValid !== this.props.parentState.isNextWStepEnabled) {
                    this.props.onSetParentState({isNextWStepEnabled: isValid});
                }
            },
        );
    };
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    environments: state.environments,
    ...ownProps,
});
export default connect(mapStateToProps)(WStep1);
