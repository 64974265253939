import * as React from "react";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import * as validateData from "../../../../lib/validate-accounts-data";
import {Button, TextField, IconButton, Card, CardContent, CardHeader, CardActions, Tooltip} from "@material-ui/core";
import {Help, ArrowForward, Visibility, VisibilityOff} from "@material-ui/icons";

const INIT_STATE = {
    dataValidation: {
        firstName: "",
        lastName: "",
        password: ""
    },
    showPassword: false
};

export default class extends React.Component<any, any> {
    public static propTypes = {
        state: PropTypes.object.isRequired,
        calcNextWStep: PropTypes.func.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.state.data) !== JSON.stringify(prevProps.state.data)) {
            this.validateData();
        }
    }

    public render() {
        return <Card style={{width: "480px", marginTop: "48px", marginRight: "auto", marginLeft: "auto", padding: "28px", borderRadius: "8px"}}>
            <CardHeader
                title={
                    <div>
                        <h2 style={{fontSize: "28px", textAlign: "center", margin: "4px 0 24px"}}>
                            Create your profile
                        </h2>
                        <div style={{fontSize: "14px", lineHeight: "24px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <p style={{margin: 0, width: "256px", textAlign: "center"}}>
                                You’ll be the interopiO account admin since you’re creating the account.
                            </p>
                            <Tooltip placement="bottom-end" title={<div style={{backgroundColor: "#fff", color: "#1A212B", whiteSpace: "normal", padding: "20px"}}>
                                <p style={{fontSize: "14px", lineHeight: "24ox", margin: 0, fontWeight: 700}}>What is an admin?</p>
                                <p style={{fontSize: "14px", lineHeight: "24ox", margin: 0}}>An admin is a person who manages your business's interopiO account. Admins can add
                                    and remove
                                    users, reset passwords, pay the bills, and more.</p>
                                <p style={{fontSize: "14px", lineHeight: "24ox", margin: 0}}>You can always assign the admin role to someone else later.</p>
                            </div>} style={{
                                backgroundColor: "#fff",
                                color: "#1A212B",
                                width: "320px",
                                textAlign: "left",
                                padding: 0,
                                borderRadius: "8px",
                            }}>
                                <IconButton style={{width: "24px", height: "24px", padding: 0, cursor: "arrow"}}>
                                    <Help style={{color: "#BBBDC0"}}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>}/>

            <CardContent>
                <div style={{display: "flex"}}>
                    <TextField style={{marginRight: "8px"}} name="firstName" value={this.props.state.data.firstName || ""} disabled={this.state.status === "wait"}
                        label="First Name" error={!!this.state.dataValidation.firstName} helperText={this.state.dataValidation.firstName} fullWidth
                        onChange={e => this.props.onSetState({data: {...this.props.state.data, firstName: e.target.value}})}/>
                    <TextField style={{marginLeft: "8px"}} name="lastName" value={this.props.state.data.lastName || ""} label="Last Name" fullWidth
                        error={!!this.state.dataValidation.lastName} helperText={this.state.dataValidation.lastName} disabled={this.state.status === "wait"}
                        onChange={e => this.props.onSetState({data: {...this.props.state.data, lastName: e.target.value}})}/>
                </div>
                <TextField style={{marginTop: "8px"}} name="email" type="email" value={this.props.state.data.email} label="Email" error={!!this.state.dataValidation.email} disabled fullWidth
                    onChange={e => this.props.onSetState({data: {...this.props.state.data, email: e.target.value}})}/>
                <TextField style={{marginTop: "8px"}} name="password" type={this.state.showPassword ? "text" : "password"} value={this.props.state.data.password} label="Password" fullWidth
                    error={!!this.state.dataValidation.password} helperText={this.state.dataValidation.password}  disabled={this.state.status === "wait"}
                    onChange={e => this.props.onSetState({data: {...this.props.state.data, password: e.target.value}})}
                    InputProps={{
                        endAdornment: <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.setState((prevState) => ({showPassword: !prevState.showPassword}))}
                            onMouseDown={(event) => event.preventDefault()}
                        >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>,
                    }}/>
            </CardContent>

            <CardActions style={{padding: "16px"}}>
                <Button color="primary" disabled={!this.props.state.data.firstName || !this.props.state.data.lastName || !this.props.state.data.password || !this.isDataValid()}
                    fullWidth onClick={() => this.props.onSetState({wstep: this.props.calcNextWStep()})} variant="contained">
                    Next <ArrowForward/>
                </Button>
            </CardActions>
            <div style={{fontSize: "12px", padding: "16px", lineHeight: "24px"}}>
                By continuing, you’re agreeing to our <Link to="/terms/customer-terms-of-service" target="_blank" style={{fontSize: "12px", fontWeight: 700, textDecoration: "none"}}>Customer
                Terms of Service</Link>, <Link to="/policies/privacy-notice" target="_blank" style={{fontSize: "12px", fontWeight: 700, textDecoration: "none"}}>Privacy Notice</Link>,
                and <Link to="/policies/cookie-policy" target="_blank" style={{fontSize: "12px", fontWeight: 700, textDecoration: "none"}}>Cookie Policy</Link>.
            </div>
        </Card>;
    }

    // Helpers -----------------------------------------------------------------
    private isDataValid = () => Object.keys(this.state.dataValidation).reduce((acc, key) => acc && !this.state.dataValidation[key], true);

    private validateData = () => {
        const dataValidation = validateData.onWStep3(this.props.state);
        this.setState({dataValidation});
    };
}
