/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import SortingIcon from "../../../../Widgets/icons/SortingIcon";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    const classes = `table-header-cell${props.sort ? " active" : ""}${props.centered ? " centered" : ""}`;
    const flex = props.size;
    let color = props.muiTheme.palette.disabledColor;
    if (props.sort) {
        if (props.order === true || props.order === false) {
            color = props.muiTheme.palette.textColor;
        } else {
            color = props.muiTheme.palette.secondaryTextColor;
        }
    }

    return <div className={classes} onClick={props.sort} style={{color, flex}}>
        <span>
            {props.label}
        </span>
        <SortingIcon down={props.order}/>
    </div>;
}

Component.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    centered: PropTypes.bool,
    sort: PropTypes.func,
    size: PropTypes.number,
    label: PropTypes.string
}

export default withLegacyTheme()(Component);
