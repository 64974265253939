import * as React from "react";
import * as PropTypes from "prop-types";
import {Button, DialogActions} from "@material-ui/core";

const Component: React.FunctionComponent<any> = props => {
    return <DialogActions style={{textAlign: "center", padding: "0 24px 24px", background: "rgb(250, 250, 250)"}}>
        <Button variant="contained" key="action-btn-close" color="primary" style={{minWidth: "128px"}} onClick={props.onClose}>
            Close
        </Button>
    </DialogActions>;
}

Component.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default Component;
