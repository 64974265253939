import { getPath } from "./utils/";

export default function(
    res: any,
    showDefault: boolean = false,
): string {
    const defaultMessage: string = "Something went wrong. Please try again later or contact support.";

    return getPath(res, "data.message")
        || getPath(res, "response.data.message")
        || getPath(res, "response.statusText")
        || (showDefault ? defaultMessage : "");
}
