import moment from "moment";
import { useState } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../redux/actions";
import getAppUsersCallUrl from "../../../../../../lib/getAppUsersCallUrl";
import { isEnvironmentAdmin } from "../../../../../../lib/user-environment-permissions-helpers";
import { getDateDisplay, getFullDateDisplay } from "../../../../../../lib/utils/";
import withLegacyTheme from "../../../../../../lib/hoc/with-legacy-theme";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CodeIcon from "@material-ui/icons/Code";
import "./style.less";

const AppUserItem = (props) => {
    const actAsAdmin = isEnvironmentAdmin(props.login, props.location, props.config);
    const editableDirectoryType = props.parentState?.data?.directoryType === "INTEROPIO_USERS";

    const isAppUserDisabled = !!props.appUser?.disabled;
    const appUserType = props.appUser?.appUserType;
    const appUserName = props.appUser?.name || "";
    const appUserEmail = props.appUser?.email || "";
    const created = props.appUser?.created;
    const lastLogin = props.appUser?.lastLogin;
    const appUserId = props.appUser?.uid || "--";

    const LeftAvatarIcon = isAppUserDisabled ? PermIdentityIcon : AccountCircleIcon;
    let leftAvatarBgndColor = undefined;
    if (!isAppUserDisabled && appUserType === "PATIENT") {
        leftAvatarBgndColor = props.muiTheme.palette.primary1Color;
    }
    else if (!isAppUserDisabled && appUserType === "PROVIDER") {
        leftAvatarBgndColor = props.muiTheme.palette.accent1Color;
    }
    const leftAvatarStyle = {
        cursor: props.clickable && editableDirectoryType ? "pointer" : "default",
        backgroundColor: leftAvatarBgndColor,
    };

    const appUserCreatedAt = moment(created || null).isValid()
        ? getDateDisplay(created, props.ui.timeZone)
        : "--";

    const appUserLastLogin = moment(lastLogin || null).isValid()
        ? getFullDateDisplay(lastLogin, props.ui.timeZone)
        : "--";

    const [anchorEl, setAnchorEl] = useState(null);
    const rightIcon = (props.showMenu && actAsAdmin && editableDirectoryType) ? <>
        <IconButton
            className="right-icon"
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
            }}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            PaperProps={{ style: { minWidth: 168 } }}
            onClose={() => setAnchorEl(null)}
        >
            <MenuItem onClick={() => props.onSetParentState({
                selectedAppUser: props.appUser,
                dialog: ":EDIT_APP_USER:",
                etab: null,
            })}>
                <ListItemIcon><VisibilityIcon /></ListItemIcon>
                <Typography variant="inherit" noWrap>
                        View user
                </Typography>
            </MenuItem>
            <MenuItem onClick={() => props.onSetParentState({
                selectedAppUser: props.appUser,
                dialog: ":APP_USER_AS_FHIR:",
                etab: null,
            })}>
                <ListItemIcon><CodeIcon /></ListItemIcon>
                <Typography variant="inherit" noWrap>
                        View user as FHIR
                </Typography>
            </MenuItem>
            <MenuItem onClick={async () => {
                props.onSetParentState({
                    status: "waiting",
                    selectedAppUser: props.appUser
                });
                const data = {
                    ...props.appUser,
                    disabled: !props.appUser.disabled
                };
                const accountId = props.appUser.accountId;
                const environmentId = props.appUser.environmentId;
                const directoryId = props.appUser.dirId;
                const appUserId = props.appUser.uid;
                const appUserUrl = getAppUsersCallUrl(accountId, environmentId, directoryId) + `/${appUserId}`;
                const response = await props.appUsersUpdate(props.config, appUserUrl, data);
                if (response && response.status < 300 && response.data) {
                    props.initialize();
                }
            }}>
                <ListItemIcon>
                    {isAppUserDisabled ? <CheckCircleIcon /> : <RemoveCircleIcon />}
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                    {isAppUserDisabled ? "Enable user" : "Disable user"}
                </Typography>
            </MenuItem>
            <MenuItem onClick = {(event) => {
                event.preventDefault();
                event.stopPropagation();
                setAnchorEl(null);
                props.onSetParentState({
                    selectedAppUser: props.appUser,
                    dialog: ":DELETE_APP_USER_CONFIRM:",
                });
            }}>
                <ListItemIcon><DeleteIcon /></ListItemIcon>
                <Typography variant="inherit" noWrap>
                        Delete user
                </Typography>
            </MenuItem>
        </Menu>
    </> : null;

    return <ListItem
        data-qa={`user-mail-${appUserEmail}`}
        disabled
        className="app-user-ul-item"
        style={{opacity: props.parentState.status !== "ready" ? 0.33 : 1}}
    >
        <ListItemAvatar>
            <Avatar
                style={leftAvatarStyle}
                onClick={() => props.clickable && editableDirectoryType && props.onSetParentState({
                    selectedAppUser: props.appUser,
                    dialog: ":EDIT_APP_USER:",
                    etab: null,
                })
                }>
                <LeftAvatarIcon />
            </Avatar>
        </ListItemAvatar>

        <div className="content">
            <div className="col-1" style={{cursor: props.clickable && editableDirectoryType ? "pointer" : "default"}}
                onClick={() => props.clickable && editableDirectoryType && props.onSetParentState({
                    selectedAppUser: props.appUser,
                    dialog: ":EDIT_APP_USER:",
                    etab: null
                })}>
                <span style={{color: isAppUserDisabled ? props.muiTheme.palette.disabledColor : props.muiTheme.palette.textColor}}>
                    {appUserName || appUserEmail || "--"}
                    <br/>
                    {appUserName
                        ? <small style={{color: isAppUserDisabled ? props.muiTheme.palette.disabledColor : props.muiTheme.palette.secondaryTextColor}}>
                            {appUserEmail || "--"}
                        </small>
                        : null}
                </span>
            </div>

            <div className="col-2" style={{cursor: props.clickable && editableDirectoryType ? "pointer" : "default"}}
                onClick={() => props.clickable && editableDirectoryType && props.onSetParentState({
                    selectedAppUser: props.appUser,
                    dialog: ":EDIT_APP_USER:",
                    etab: null,
                })}>
                <small style={{color: isAppUserDisabled ? props.muiTheme.palette.disabledColor : props.muiTheme.palette.textColor}}>
                    {appUserCreatedAt}
                </small>
            </div>

            <div className="col-3" style={{cursor: props.clickable && editableDirectoryType ? "pointer" : "default"}}
                onClick={() => props.clickable && editableDirectoryType && props.onSetParentState({
                    selectedAppUser: props.appUser,
                    dialog: ":EDIT_APP_USER:",
                    etab: null
                })}>
                <small style={{color: isAppUserDisabled ? props.muiTheme.palette.disabledColor : props.muiTheme.palette.textColor}}>
                    {appUserLastLogin}
                </small>
            </div>

            <div className="col-4" style={{cursor: props.clickable && editableDirectoryType ? "pointer" : "default"}}
                onClick={() => props.clickable && editableDirectoryType && props.onSetParentState({
                    selectedAppUser: props.appUser,
                    dialog: ":EDIT_APP_USER:",
                    etab: null
                })}>
                <small style={{color: isAppUserDisabled ? props.muiTheme.palette.disabledColor : props.muiTheme.palette.textColor}}>
                    {appUserId}
                </small>
            </div>
        </div>

        {rightIcon}
    </ListItem>;
}

AppUserItem.propTypes = {
    appUser: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,

    appUsersUpdate: PropTypes.func.isRequired,

    clickable: PropTypes.bool,
    showMenu: PropTypes.bool,

    initialize: PropTypes.func,
    onSetParentState: PropTypes.func,
};

AppUserItem.defaultProps = {
    clickable: true,
    showMenu: true,

    initialize: () => {},
    onSetParentState: () => {},
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    login: state.login,
    ui: state.ui,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appUsersUpdate: actions.appUsersUpdate,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(AppUserItem)));
