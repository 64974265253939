import * as React from "react";
import * as PropTypes from "prop-types";
import {Dialog} from "@material-ui/core";
import Actions from "./Actions";
import Content from "./Content";

export default class extends React.Component<any, any> {
    public static propTypes = {
        muiTheme: PropTypes.object.isRequired,

        onClose: PropTypes.func.isRequired
    };

    public render() {
        return <Dialog open style={{paddingTop: "5px", zIndex: 1250}} onClose={this.props.onClose}>
            <Content {...this.props} />
            <Actions {...this.props} />
        </Dialog>;
    }
}
