import * as React from "react";
import * as PropTypes from "prop-types";
import EditResource from "./EditResource/";

const Component: React.FunctionComponent<any> = props => {
    let content = null;
    switch (props.state.dialog) {
        case ":EDIT:":
            content = <EditResource {...props} />;
            break;
    }

    return content;
}

Component.propTypes = {
    state: PropTypes.object.isRequired
};

export default Component;
