import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../../redux/actions";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import getMessageFromResponse from "../../../../../lib/getMessageFromResponse";
import * as validateData from "../../../../../lib/validate-users-data";
import {getPath} from "../../../../../lib/utils/";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress/";
import {Link} from "react-router-dom";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import {CircularProgress, Button, Card, CardContent, CardHeader, CardActions, TextField} from "@material-ui/core";
import consoleLogger from "../../../../../lib/consoleLogger";

const INIT_STATE = {
    status: "",
    data: {password: ""},
    dataValidation: {password: ""},
    confirmMessage: "",
    tokenMessage: ""
};

class Component extends React.Component<any, any> {
    public static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        loginSet: PropTypes.func.isRequired,
        userResetPasswordConfirm: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        const {accountId} = getHostnameInfo();
        if (!accountId) {
            this.props.history.push("/signin");
        }

        this.initialize();
    }

    public render() {
        return <div className="container">
            <Card style={{width: "480px", marginTop: "48px", marginRight: "auto", marginLeft: "auto", padding: "28px", borderRadius: "8px"}}>
                {this.state.status === "init" ? this.renderInit() : null}
                {["ready", "wait"].includes(this.state.status) ? this.renderConfirmationForm() : null}
            </Card>
            {this.renderAdditionalLinks()}
        </div>;
    }

    private renderAdditionalLinks = () => {
        const {protocol, domain, type, port} = getHostnameInfo();

        return <div style={{marginTop: "48px", textAlign: "center"}}>
            <strong>
                <Link to="/signin" style={{fontSize: "16px", textDecoration: "none"}}>
                    Return to login page
                </Link>
            </strong>
            <br/><br/>

            <strong>
                <a href="#!" style={{fontSize: "16px", textDecoration: "none"}}
                    onClick={event => {
                        event.preventDefault();
                        window.location.href = `${protocol}//${domain}.${type}${port ? ":" + port : ""}/console/signin`;
                    }}>
                    Sign into another account?
                </a>
            </strong>
            <br/>
        </div>;
    }
    private renderConfirmationForm = () => {
        const {accountId, domain, type} = getHostnameInfo();

        const dataValidation = validateData.onResetPasswordConfirm(this.state)
        const isDataValid = Object.keys(dataValidation).reduce((acc, key) => acc && !dataValidation[key], true);

        return <div>
            <CardHeader
                title={<div>
                    <h2 style={{margin: "4px 0 24px", textAlign: "center", fontSize: "28px"}}>
                        Reset your password
                    </h2>
                    <p style={{margin: "8px 0 16px 0", textAlign: "center", fontSize: "14px"}}>
                        {`${accountId}.${domain}.${type}`}
                    </p>
                </div>}
                subheader={<p style={{margin: 0, color: this.props.muiTheme.palette.textColor, textAlign: "center", fontSize: "16px"}}>
                    Enter your&nbsp;
                    <strong><big>new password</big></strong>&nbsp;
                </p>}/>

            <CardContent>
                <TextField name="password" type="password" value={this.state.data.password || ""} label="Password" placeholder="Type in your password"
                    error={!!dataValidation.password} helperText={dataValidation.password} disabled={this.state.status !== "ready" || !!this.state.tokenMessage} fullWidth
                    onChange={e => this.setState(prevState => ({
                        data: {
                            ...prevState.data,
                            password: e.target.value
                        },
                        confirmMessage: ""
                    }))}/>

                <div style={{
                    marginTop: "16px",
                    padding: this.state.tokenMessage ? "8px" : 0,
                    transform: this.state.tokenMessage ? "scaleY(1)" : "scaleY(0)",
                    transformOrigin: "top",
                    borderRadius: "2px",
                    background: this.props.muiTheme.palette.accent1Color,
                    color: "#fff",
                    textAlign: "center",
                    transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
                }}>
                    {this.state.tokenMessage}
                </div>
                <div style={{
                    marginTop: "16px",
                    padding: this.state.confirmMessage ? "8px" : 0,
                    transform: this.state.confirmMessage ? "scaleY(1)" : "scaleY(0)",
                    transformOrigin: "top",
                    borderRadius: "2px",
                    background: this.props.muiTheme.palette.accent1Color,
                    color: "#fff",
                    textAlign: "center",
                    transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
                }}>
                    {this.state.confirmMessage}
                </div>
            </CardContent>

            <CardActions style={{padding: "16px"}}>
                <Button variant="contained" color="primary" fullWidth
                    disabled={!this.state.data.password || this.state.status !== "ready" || !isDataValid || !!this.state.tokenMessage || !!this.state.confirmMessage}
                    onClick={() => {
                        const token = this.getToken();
                        this.setState(
                            {status: "wait"},
                            async () => {
                                let response
                                try {
                                    response = await this.props.userResetPasswordConfirm({newPassword: this.state.data.password, token}, {bypassError: true});
                                    this.props.history.push("/home")
                                    this.props.loginSet({status: "logged-in", data: getPath(response, "data") || {}});
                                } catch (reason) {
                                    consoleLogger.error(":::", reason);
                                    this.setState({
                                        status: "ready",
                                        confirmMessage: getMessageFromResponse(reason, true)
                                    });
                                }
                            }
                        );
                    }}>
                    {this.state.status === "wait" ? <CircularProgress size={24} style={{marginTop: "5px"}}/> : "Reset Password"}
                </Button>
            </CardActions>

            <CardContent style={{padding: "16px", textAlign: "center", fontSize: "16px"}}>
                <strong>
                    <Link to="/users/reset-password/enter-email" style={{fontSize: "16px", textDecoration: "none"}}>
                        Resend confirmation email
                    </Link>
                </strong>
            </CardContent>
        </div>;
    }

    private renderInit = () => {
        return <CenteredCircularProgress size={63} style={{padding: "24px"}}/>;
    }

    private getToken = () => (getPath(this.props, "location.search") || "").replace("?token=", "");

    private initialize = () => {
        const token = this.getToken();
        this.setState(
            {status: "init"},
            async () => {
                try {
                    await this.props.userResetPasswordConfirm({token}, {bypassError: true});
                    this.setState({status: "ready"});
                } catch (reason) {
                    consoleLogger.error(":::", reason);
                    this.setState({
                        status: "ready",
                        tokenMessage: getMessageFromResponse(reason, true)
                    });
                }
            }
        );
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Component));
