import * as React from "react";
import * as PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as actions from "../../redux/actions";

import * as features from "../../lib/feature-helpers";
import getLocationInfo from "../../lib/getLocationInfo";
// SVG icons
import IconHome from "@material-ui/icons/Home";
import IconApps from "@material-ui/icons/Apps";
import IconDashboard from "@material-ui/icons/Dashboard";
import IconKeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import IconKeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import IconLayers from "@material-ui/icons/Layers";
import IconWeb from "@material-ui/icons/Web";
import IconSettings from "@material-ui/icons/Settings";
import IconSupervisorAccount from "@material-ui/icons/SupervisorAccount";
import IconStorage from "@material-ui/icons/Storage";
import IconDataAdapters from "../Widgets/icons/SwapHorizontalCircle";
import IconCdsHooks from "../Widgets/icons/CdsHooks";
import IconSmileCdr from "../Widgets/icons/SmileCdr";
import IconSpeed from "../Widgets/icons/MUISpeed";

import "./style.less";

class LeftMenuPrivate extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,

        uiLeftMenuPrivateExpand: PropTypes.func.isRequired,
        uiLeftMenuPrivateShrink: PropTypes.func.isRequired,
    };

    public render() {
        const location = getLocationInfo(this.props.location, this.props.config);

        const featureSmileCdrCustomization = features.check(":SMILE_CDR_CUSTOMIZATION:", this.props.features.data);

        return <div className={`left-menu-private lmp-${this.props.ui.leftMenuPrivate.isExpanded ? "expanded" : "shrunk"}`}>
            {/* Logo --------------------------------------------------- */}
            <Link to="/" data-qa="nav-home">
                <div className="logo ">
                    <img src={this.props.ui.leftMenuPrivate.isExpanded
                        ? "/console/img/interopio-logo-wh@2x--r.png"
                        : "/console/img/interopio-icon-lg@2x--r.png"}
                    className={`logo-img ${!this.props.ui.leftMenuPrivate.isExpanded ? "logo-icon" : ""}`}/>
                </div>
            </Link>

            {/* Items -------------------------------------------------- */}
            <div className="items">
                {/* Dashboard ------------------------------------------ */}
                {this.renderItemWithRightIcon({
                    icon: IconDashboard,
                    label: "DASHBOARD",
                    level1: ":ENVIRONMENTS:",
                    level2: ":DASHBOARD:",
                    // disabled: true,
                    linkTo: `/${location.env}`,
                    rightIcon: IconSettings,
                    rightLevel1: ":ENVIRONMENTS:",
                    rightLevel2: ":SETTINGS:",
                    rightLinkTo: `/environments/${location.env}`,
                    "right-data-qa": "nav-settings"
                })}

                {/* Apps ----------------------------------------------- */}
                {this.renderItem({
                    icon: IconApps,
                    label: "APPS",
                    level1: ":ENVIRONMENTS:",
                    level2: ":APPS:",
                    linkTo: `/${location.env}/apps`,
                    "data-qa": "nav-apps"
                })}

                {/* CDS Service Sets ----------------------------------- */}
                {this.renderItem({
                    icon: IconCdsHooks,
                    label: "CDS HOOKS",
                    level1: ":ENVIRONMENTS:",
                    level2: ":CDS_SERVICE_SETS:",
                    linkTo: `/${location.env}/cds-service-sets`,
                    "data-qa": "nav-cds"
                })}

                {/* Portals --------------------------------------------- */}
                {this.renderItem({
                    icon: IconWeb,
                    label: "PORTALS",
                    level1: ":ENVIRONMENTS:",
                    level2: ":PORTALS:",
                    linkTo: `/${location.env}/portals`,
                    "data-qa": "nav-portals"
                })}

                {/* Gateways ------------------------------------------- */}
                {this.renderItem({
                    icon: IconLayers,
                    label: "GATEWAYS",
                    level1: ":ENVIRONMENTS:",
                    level2: ":GATEWAYS:",
                    linkTo: `/${location.env}/gateways`,
                    "data-qa": "nav-gateways"
                })}

                {/* Data Sources --------------------------------------- */}
                {this.renderItem({
                    icon: IconDataAdapters,
                    label: "DATA ADAPTERS",
                    level1: ":ENVIRONMENTS:",
                    level2: ":DATA_SOURCES:",
                    linkTo: `/${location.env}/datasources`,
                    "data-qa": "nav-data-adapters"
                })}

                {/* CDRs ----------------------------------------------- */}
                {this.renderItem({
                    icon: featureSmileCdrCustomization ? IconSmileCdr : IconStorage,
                    label: featureSmileCdrCustomization ? "SMILE CDR" : "FHIR CDRS",
                    level1: ":ENVIRONMENTS:",
                    level2: ":CDRS:",
                    linkTo: `/${location.env}/cdrs`,
                    "data-qa": "nav-cdrs"
                })}

                {/* Authentication ------------------------------------- */}
                {this.renderItem({
                    icon: IconSupervisorAccount,
                    label: "AUTHENTICATION",
                    level1: ":ENVIRONMENTS:",
                    level2: ":AUTH:",
                    linkTo: `/${location.env}/auth`,
                    "data-qa": "nav-auth"
                })}

                {/* Monitoring ----------------------------------------- */}
                {this.renderItem({
                    icon: IconSpeed,
                    label: "MONITORING",
                    level1: ":ENVIRONMENTS:",
                    level2: ":MONITORING:",
                    linkTo: `/${location.env}/monitoring`,
                })}

                {/* Account -------------------------------------------- */}
                {!this.props.ui.leftMenuPrivate.isExpanded &&
                this.renderItem({
                    icon: IconHome,
                    label: "ACCOUNT",
                    borderTop: true,
                    linkTo: "/home",
                })}

                {/* Button expand -------------------------------------- */}
                {!this.props.ui.leftMenuPrivate.isExpanded && (
                    <div className="item btn-toggle border-top">
                        <IconKeyboardArrowRight onClick={this.props.uiLeftMenuPrivateExpand}/>
                    </div>
                )}
            </div>

            {/* Account ------------------------------------------------ */}
            {this.props.ui.leftMenuPrivate.isExpanded &&
            this.renderItem({
                icon: IconHome,
                label: "ACCOUNT",
                borderTop: true,
                borderBottom: true,
                linkTo: "/home",
            })}

            {/* Button shrink ------------------------------------------ */}
            {this.props.ui.leftMenuPrivate.isExpanded &&
            <div className="item btn-toggle">
                <IconKeyboardArrowLeft onClick={this.props.uiLeftMenuPrivateShrink}/>
            </div>}
        </div>;
    }

    // Private renders ---------------------------------------------------------
    private renderItem = (cfg) => {
        const location = getLocationInfo(this.props.location, this.props.config);

        const isSelected =
            (location.level1 === cfg.level1 || !cfg.level1)
            && (location.level2 === cfg.level2 || !cfg.level2)
            && (location.level3 === cfg.level3 || !cfg.level3)
            && !(!cfg.level1 && !cfg.level2 && !cfg.level3);
        const wrapperClassName = `item ${!this.props.ui.leftMenuPrivate.isExpanded ? "item-shrunk" : ""} ${cfg.type === ":SUB_ITEM:" ? "sub-item" : ""} ${isSelected ? `item-selected` : ""} ${cfg.disabled ? "item-disabled" : ""} ${cfg.borderTop ? "border-top" : ""} ${cfg.borderBottom ? "border-bottom" : ""} ${cfg.className || ""}`;
        const iconColor = isSelected ? this.props.ui.xtheme.palette.colorOrangeDark : cfg.type === ":SUB_ITEM:" ? "rgba(255, 255, 255, 0.7)" : "rgba(255, 255, 255, 0.9)";

        return <Link to={cfg.linkTo || "#"} data-qa={cfg["data-qa"]} onClick={() => this.props.uiTabsSetSelected(0)}>
            <div className={wrapperClassName}>
                <cfg.icon className="icon-item" style={{ color: iconColor }}/>
                {this.props.ui.leftMenuPrivate.isExpanded && <span>{cfg.label}</span>}
            </div>
        </Link>;
    }

    private renderItemWithRightIcon = cfg => {
        const location = getLocationInfo(this.props.location, this.props.config);

        const containerClassName = `border-top border-bottom ${this.props.ui.leftMenuPrivate.isExpanded ? "item-with-right-icon" : "item-with-right-icon-column"}`

        const isSelected =
            (location.level1 === cfg.level1 || !cfg.level1)
            && (location.level2 === cfg.level2 || !cfg.level2)
            && (location.level3 === cfg.level3 || !cfg.level3)
            && !(!cfg.level1 && !cfg.level2 && !cfg.level3);
        const wrapperClassName = `item item-left ${!this.props.ui.leftMenuPrivate.isExpanded ? "item-shrunk" : ""} ${cfg.type === ":SUB_ITEM:" ? "sub-item" : ""} ${isSelected ? `item-selected` : ""} ${cfg.disabled ? "item-disabled" : ""} ${cfg.borderTop ? "border-top" : ""} ${cfg.borderBottom ? "border-bottom" : ""} ${cfg.className || ""}`;
        const iconColor = isSelected ? this.props.ui.xtheme.palette.colorOrangeDark : cfg.type === ":SUB_ITEM:" ? "rgba(255, 255, 255, 0.7)" : "rgba(255, 255, 255, 0.9)";

        const isRightIconSelected =
            (location.level1 === cfg.rightLevel1 || !cfg.rightLevel1)
            && (location.level2 === cfg.rightLevel2 || !cfg.rightLevel2)
            && (location.level3 === cfg.rightLevel3 || !cfg.rightLevel3)
            && !(!cfg.rightLevel1 && !cfg.rightLevel2 && !cfg.rightLevel3);
        const wrapperRightIconClassName = `item-right-icon ${isRightIconSelected ? `item-selected` : "item-not-selected"}`;
        const iconRightColor = isRightIconSelected ? this.props.ui.xtheme.palette.colorOrangeDark : "rgba(255, 255, 255, 0.9)";

        return <div className={containerClassName}>
            <Link to={cfg.linkTo || "#"} data-qa={cfg["data-qa"]}>
                <div className={wrapperClassName} style={{display: "inline-block"}}>
                    <cfg.icon className="icon-item" style={{ color: iconColor }} />
                    {this.props.ui.leftMenuPrivate.isExpanded && <span>{cfg.label}</span>}
                </div>
            </Link>
            <Link to={cfg.rightLinkTo || "#"} data-qa={cfg["right-data-qa"]}>
                <div className={wrapperRightIconClassName}>
                    <cfg.rightIcon className="icon-item icon-right-svg" style={{ iconRightColor}} />
                </div>
            </Link>
        </div>;
    }
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    features: state.features,
    ui: state.ui,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    uiLeftMenuPrivateExpand: actions.uiLeftMenuPrivateExpand,
    uiLeftMenuPrivateShrink: actions.uiLeftMenuPrivateShrink,
    uiTabsSetSelected: actions.uiTabsSetSelected
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftMenuPrivate));
