import * as React from "react";
import * as PropTypes from "prop-types";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Toolbar from "@material-ui/core/Toolbar"
import DateRangeIcon from "@material-ui/icons/DateRange";
import ChipDropDown from "../../../Widgets/ChipDropDown";
import {getDateDisplay, getDateWithZoneDisplay} from "../../../../lib/utils";

const styles = {
    toolbar: {
        justifyContent: "space-between",
        height: "76px"
    },
    alignNone: {
        alignItems: "none"
    },
    alignCenter: {
        alignSelf: "center"
    },
    dataRange: {
        fontSize: "24px",
        paddingRight: "10px"
    },
    errorStyle: {
        color: "gray"
    },
    popoverMargin: {
        margin: "8px 16px"
    },
    mb8: {
        marginBottom: "8px"
    },
    selectedColor: {
        color: "rgb(3, 155, 229)"
    },
    refreshMargin: {
        margin: "10px 0 10px 10px"
    },
    marginMore: {
        marginRight: "-25px"
    }
};

class MonitoringToolbar extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {
            startTime: 0,
            endTime: 0,
            filteredObjects: [],
            topResourcesByAct: {},
            timeframe: 2592000000
        };
    }

    public render() {
        let timeframe = getDateDisplay(Date.now() - this.state.timeframe, this.props.ui.timeZone) + " - " + getDateWithZoneDisplay(Date.now(), this.props.ui.timeZone);
        let labelActivations = this.props.label;
        if (this.state.filteredObjects.length) {
            labelActivations = this.state.filteredObjects[0];
            if (this.state.filteredObjects.length > 1) {
                labelActivations += ` + ${this.state.filteredObjects.length - 1}`;
            }
        } else if (this.state.filteredObjects.length === 0) {
            labelActivations = this.props.label;
        }

        return <Toolbar className="monitoring-toolbar" style={styles.toolbar}>
            <div style={{...styles.alignNone, display: "flex"}}>
                <DateRangeIcon style={{...styles.alignCenter, ...styles.dataRange}}/>
                <FormControl>
                    <Select value={this.state.timeframe} onChange={this.handleTimeSpanChange}>
                        <MenuItem value={0}>Today</MenuItem>
                        <MenuItem value={86400000}>Yesterday</MenuItem>
                        <MenuItem value={604800000}>Last 7 days</MenuItem>
                        <MenuItem value={2592000000}>Last 30 days</MenuItem>
                        <MenuItem value={7776000000}>Last 90 days</MenuItem>
                        <MenuItem value={31536000000}>Last 365 days</MenuItem>
                    </Select>
                    <FormHelperText>
                        {timeframe}
                    </FormHelperText>
                </FormControl>
            </div>
            <div style={styles.alignNone}>
                <div style={styles.alignCenter}>
                    <ChipDropDown label={labelActivations} inactiveBackground="#fff" inactiveColor={this.props.muiTheme.palette.textColor}
                        activeBackground={this.props.muiTheme.palette.primary1Color}
                        activeColor="#fff" popover={this.getPopover()} isActive={this.isFilterResourcesActive()}
                        onRequestDelete={() => this.setObjects([])}/>
                </div>
            </div>
        </Toolbar>;
    }

    private getPopover() {
        let toggled = !this.state.filteredObjects.length;
        let objects = [];
        let added = [];
        this.props.list.map(item => {
            if (added.indexOf(item.label) === -1) {
                objects.push(<FormControlLabel
                    key={`object-${item.label}`}
                    label={item.label}
                    control={<Checkbox name={`activation-${item.label}`} checked={this.state.filteredObjects.indexOf(item.label) === -1}
                        color="primary" style={{ display: "block" }}
                        onChange={(event) => this.toggleObject(item.label, event.target.checked)}/>}
                />)
                added.push(item.label);
            }
        });

        let content = <div style={styles.popoverMargin}>
            <FormControlLabel
                control={<Switch checked={toggled} color="primary" style={styles.mb8} onChange={this.handleObjectsToggle} />}
                label={`Select All ${this.props.label}`}
            />
            <Divider style={{margin: "8px 0px"}}/>
            {objects}
        </div>;

        return {title: this.props.label, content}
    }

    private isFilterResourcesActive() {
        return this.state.filteredObjects.length > 0;
    }

    private handleTimeSpanChange = (event) => {
        this.setState({timeframe: event.target.value});
        this.props.onPeriodChange(event.target.value);
    };

    private handleObjectsToggle = () => {
        let filteredObjects = [];
        if (this.state.filteredObjects.length === 0) {
            this.props.list.map(object => filteredObjects.push(object.label));
        }

        this.setObjects(filteredObjects);
    };

    private toggleObject = (object, isChecked) => {
        let filteredObjects = this.state.filteredObjects.slice();
        if (isChecked) {
            let index = filteredObjects.indexOf(object);
            filteredObjects.splice(index, 1);
        } else {
            filteredObjects.push(object);
        }
        this.setObjects(filteredObjects);
    };

    private setObjects = filteredObjects => {
        this.setState({filteredObjects});
        this.props.onFilter(filteredObjects);
    };
}

export default withLegacyTheme()(MonitoringToolbar);
