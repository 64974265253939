import * as React from "react";
import CreateSession from "./CreateSession";
import DeleteSessions from "./DeleteSessions";

interface IProps {
    dialog: string;
    newSession: any;
    parentStatus: string;
    selectedSessions: any[];
    setDialog: Function;
    setParentStatus: Function;
    setSelectedSessions: Function;
}

const Dialogs: React.FC<IProps> = (props: IProps) => {
    const { dialog, newSession, selectedSessions, setSelectedSessions, ...propSet } = props;

    switch (dialog) {
        case ":CREATE_SESSION:":
            return <CreateSession {...propSet} newSession={newSession} />;
        case ":DELETE_SESSIONS:":
            return <DeleteSessions {...propSet} selectedSessions={selectedSessions} setSelectedSessions={setSelectedSessions} />;
        default:
            return null;
    }
};

export default Dialogs;
