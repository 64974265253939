import * as React from 'react'
import { withTheme } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

type TProps = {
    canEdit: boolean
    isSelectedAppActivationAvailable: boolean
    theme: Theme
    onClose: React.MouseEventHandler<HTMLButtonElement>
}

const CustomDialogTitle: React.FC<TProps> = (props: TProps) => {
    let title = ''
    if (!props.isSelectedAppActivationAvailable) {
        title = 'Add an Activation'
    }
    else if (props.isSelectedAppActivationAvailable && !props.canEdit) {
        title = 'Preview Activation'
    }
    else if (props.isSelectedAppActivationAvailable && props.canEdit) {
        title = 'Edit Activation'
    }

    return (
        <DialogTitle
            disableTypography
            style={{
                color: props.theme.palette.primary.contrastText,
                background: props.theme.palette.primary.main,
            }}
        >
            <Typography
                data-e2e="act_dialog_title"
                variant="h6"
            >
                {title}
            </Typography>
            <IconButton
                style={{
                    position: "absolute",
                    right: props.theme.spacing(1),
                    top: props.theme.spacing(1),
                    color: props.theme.palette.primary.contrastText,
                }}
                onClick={props.onClose}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    )
}

export default withTheme(CustomDialogTitle)
