import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../redux/actions";
import * as features from "../../../../lib/feature-helpers";
import getCDRsCallUrl from "../../../../lib/getCDRsCallUrl";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import getEnvironmentsCallUrl from "../../../../lib/getEnvironmentsCallUrl";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress";
import withTheme from "@material-ui/core/styles/withTheme";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import WStep1 from "./WStep1";

const WSTEP_MIN = 1;
const WSTEP_MAX = 1;

const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "waiting"
    data: {
        __temp__fhirVersionSelected: "4.0.1",
        accountId: "",
        environmentId: "",
        name: "",
        cdrId: "",
        description: "",
        authenticationType: "NONE", // "NONE" | "SMART_ON_FHIR"
        fhirVersion: "FHIR_R4__4_0_1", // "FHIR_DSTU2__1_0_2" | "FHIR_STU3__3_0_1" | "FHIR_STU3__3_0_2" | "FHIR_R4__4_0_0" | "FHIR_R4__4_0_1"
        preLoadData: false,
    },
    wstep: 1,
    isNextWStepEnabled: false,
};

class CDRAddNewWizard extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        environments: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        cdrsCreate: PropTypes.func.isRequired,
        cdrsReadAll: PropTypes.func.isRequired,
        environmentsRead: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    public static defaultProps = {
        className: ""
    };

    public constructor(props) {
        super(props);
        this.state = { ...INIT_STATE };
    }

    public componentDidMount() {
        this.initialize();
    }

    public render() {
        const {accountId} = getHostnameInfo();
        const {env} = getLocationInfo(this.props.location, this.props.config);
        const featureSmileCdrCustomization = features.check(":SMILE_CDR_CUSTOMIZATION:", this.props.features.data);

        return <Dialog
            open
            classes={{paper: "io-modal-wrapper"}}
            style={{ paddingTop: '5px', zIndex: 1250 }}
            disableBackdropClick disableEscapeKeyDown scroll="body"
            className={this.props.className}
            onClose={this.props.onClose}
        >
            <DialogTitle
                disableTypography
                style={{ color: this.props.theme.palette.primary.contrastText, background: this.props.theme.palette.primary.main }}
            >
                <Typography data-qa="new-cdr-modal-title" variant="h6">
                    {featureSmileCdrCustomization ? "Add Smile CDR" : "Add FHIR CDR"}
                </Typography>
                <IconButton
                    style={{ position: "absolute", right: this.props.theme.spacing(1), top: this.props.theme.spacing(1), color: this.props.theme.palette.primary.contrastText }}
                    onClick={this.props.onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 24, background: "rgb(250, 250, 250)" }}>
                {this.state.status === "ready"
                    ? this.renderWStep()
                    : <CenteredCircularProgress size={63} style={{ minWidth: 552 }} />}
            </DialogContent>
            <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
                {this.state.wstep > WSTEP_MIN ? <Button
                    disabled={!this.state.isNextWStepEnabled}
                    color="primary" style={{ marginRight: 8 }}
                    startIcon={<KeyboardArrowLeftIcon />}
                    onClick={() => this.setState({ wstep: this.calcPrevWStep() })}
                >
                    Back
                </Button>
                    : null}
                {this.state.wstep < WSTEP_MAX ? <Button
                    variant="contained" color="primary" style={{ marginRight: 8 }}
                    endIcon={<KeyboardArrowRightIcon />}
                    disabled={!this.state.isNextWStepEnabled}
                    onClick={() => this.setState({ wstep: this.calcNextWStep() })}
                >
                    Next
                </Button>
                    : null}
                {this.state.wstep === WSTEP_MAX ? <Button data-qa="create-cdr-button"
                    variant="contained" color="primary" style={{ marginRight: 8 }}
                    startIcon={<CheckIcon />}
                    disabled={this.state.status !== "ready" || !this.state.isNextWStepEnabled}
                    onClick={async () => {
                        this.setState({ status: "waiting" });
                        const data = { ...this.state.data };
                        switch (data.__temp__fhirVersionSelected) {
                            case "1.0.2": data.fhirVersion = "FHIR_DSTU2__1_0_2"; break;
                            case "3.0.2": data.fhirVersion = "FHIR_STU3__3_0_2"; break;
                            case "4.0.1": data.fhirVersion = "FHIR_R4__4_0_1"; break;
                        }
                        Object.keys(data).forEach((key) => {
                            if(key.includes("__temp__")) {
                                delete data[key];
                            }
                        });
                        const url = getCDRsCallUrl(accountId, env) + "/" + this.state.data.cdrId;
                        const res = await this.props.cdrsCreate(this.props.config, url, data);
                        if (res.status < 300 && res.data) {
                            this.props.initialize();
                            const url = `/${env}/cdrs/${res.data.cdrId}`;
                            this.props.history.push(url);
                        }
                    }}
                >
                    {featureSmileCdrCustomization ? "Create Smile CDR" : "Create FHIR CDR"}
                </Button>
                    : null}
            </DialogActions>
        </Dialog>
    }

    private renderWStep = () => {
        const propSet = {
            parentState: this.state,
            onSetParentState: (state, cb) => this.setState(state, cb),
        };
        switch (this.state.wstep) {
            case 1:
                return <WStep1 {...propSet} />;
            default:
                return null;
        }
    }

    private initialize = () => {
        const {accountId} = getHostnameInfo();
        const {env} = getLocationInfo(this.props.location, this.props.config);
        this.setState(
            {status: "init"},
            async () => {
                const environmentUrl = getEnvironmentsCallUrl(accountId) + "/" + env;
                const url = getCDRsCallUrl(accountId, env);
                await Promise.all([
                    this.props.environmentsRead(this.props.config, environmentUrl),
                    this.props.cdrsReadAll(this.props.config, url),
                ]);
                this.setState({
                    status: "ready",
                    data: {
                        ...this.state.data,
                        accountId,
                        environmentId: env,
                    },
                });
            },
        );
    };

    private calcNextWStep = () => this.state.wstep < WSTEP_MAX ? this.state.wstep + 1 : WSTEP_MAX;
    private calcPrevWStep = () => this.state.wstep > WSTEP_MIN ? this.state.wstep - 1 : WSTEP_MIN;
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    environments: state.environments,
    features: state.features,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdrsReadAll: actions.cdrsReadAll,
    cdrsCreate: actions.cdrsCreate,
    environmentsRead: actions.environmentsRead,
}, dispatch);
export default withRouter(withTheme(connect(mapStateToProps, mapDispatchToProps)(CDRAddNewWizard)));
