import AppBar from '@material-ui/core/AppBar';

import "./style.less";

const NavbarL1Public = () => {
    return <AppBar position="static">
        <div
            style={{
                width: 147,
                height: 48,
                margin: "26px auto",
                backgroundImage: "url(/console/img/interopio-logo-wh@2x--r.png)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                cursor: "pointer"
            }}
            onClick={() => {
                const host = window.location.host.split(".");
                const protocol = window.location.protocol;
                window.location.href = `${protocol}//${(host.length > 2) ? host.slice(1).join(".") : host.join(".")}/console/`;
            }}
        />
    </AppBar>;
}

export default NavbarL1Public;
