import * as React from "react";
import Cell from "./Cell/";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    return <div className="acc-ul-h2-content">
        <div className="user">
            <Cell {...props} name="user" label="User"/>
        </div>

        <div className="role">
            <Cell {...props} name="role" label="Role"/>
        </div>

        <div className="status">
            <Cell {...props} name="status" label="Status"/>
        </div>

        <div className="type">
            <Cell {...props} name="type" label="Auth Type"/>
        </div>
    </div>;
}

Component.propTypes = {};

export default Component;
