import * as React from "react";
import * as PropTypes from "prop-types";
import AppAddNewWizard from "./AppAddNewWizard";
import AppDeleteConfirm from "./AppDeleteConfirm/";

export default class extends React.Component<any, any> {
    public static propTypes = {
        state: PropTypes.object.isRequired,
        initialize: PropTypes.func.isRequired
    };

    public render() {
        const propSet = {
            ...this.props,
            onClose: this.props.initialize
        };

        let content = null;
        switch (this.props.state.dialog) {
            case ':ADD_NEW_WIZARD:':
                content = (
                    <AppAddNewWizard
                        state={this.props.state}
                        initialize={this.props.initialize}
                        onClose={this.props.initialize}
                        onSetState={this.props.onSetState}
                    />
                )
                break;
            case ":DELETE_CONFIRM:":
                content = <AppDeleteConfirm {...propSet} />;
                break;
        }

        return content;
    }
}



