import * as React from "react";
import * as PropTypes from "prop-types";
import {IconButton, Tooltip} from "@material-ui/core";
import {Close} from "@material-ui/icons";

const Component: React.FunctionComponent<any> = props => {
    const title = "SSO Config";

    return <div>
        <span style={{lineHeight: "48px"}}>{title}</span>
        <Tooltip title="Close">
            <IconButton style={{position: "absolute", top: "17px", right: "24px"}} onClick={props.onClose}>
                <Close style={{color: "rgba(255, 255, 255, 0.85)"}}/>
            </IconButton>
        </Tooltip>
    </div>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};

export default Component;
