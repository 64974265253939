/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import {ListItem} from "@material-ui/core";
import {getFullDateDisplay} from "../../../../../../../lib/utils";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    const subscription = props.subscription;
    const criteria = subscription.resource.criteria;
    const status = subscription.resource.status;
    const channelType = subscription.resource.channel.type;
    const end = getFullDateDisplay(subscription.resource.end, props.ui.timeZone);

    return <ListItem disabled className={`gw-subscriptions-tt-item`} style={{fontSize: "14px", lineHeight: "14px", padding: "16px 0 16px 32px"}}>
        <div className="content">
            <div className="end">
                {end}
            </div>
            <div className="status">
                {status}
            </div>
            <div className="criteria">
                {criteria}
            </div>
            <div className="channelType">
                {channelType}
            </div>
        </div>
    </ListItem>;
};

Component.propTypes = {
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
