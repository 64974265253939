import * as React from "react";
import * as PropTypes from "prop-types";
import {Checkbox, Divider, FormControlLabel, Switch} from "@material-ui/core";
import ChipDropDown from "../../../../../Widgets/ChipDropDown";

declare const _;

class FilterBy extends React.Component<any, any> {
    public static propTypes = {
        allItems: PropTypes.array.isRequired,
        filterKey: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        muiTheme: PropTypes.object.isRequired,
        setFilterItem: PropTypes.func.isRequired,
        containerStyle: PropTypes.object
    };

    public static defaultProps = {
        containerStyle: {}
    };

    private debouncedSetFilterItem = _.debounce((item, filter) => {
        this.props.setFilterItem(item, filter)
    }, 1815, {leading: true})

    public constructor(props) {
        super(props);
        this.state = {filteredItems: this.props.state?.filters?.[this.props.filterKey] || this.props.allItems};
    }

    public render() {
        let label = this.props.label;
        if (this.state.filteredItems.length !== this.props.allItems.length) {
            label = this.state.filteredItems[0];

            if (this.state.filteredItems.length > 1) {
                label += ` + ${this.state.filteredItems.length - 1}`;
            } else if (this.state.filteredItems.length === 0) {
                label = "None";
            }
        }

        return <div style={{...this.props.containerStyle}}>
            <ChipDropDown label={label} inactiveBackground="#fff" inactiveColor={this.props.muiTheme.palette.textColor}
                activeBackground={this.props.muiTheme.palette.primary1Color} activeColor="#fff" popover={this.getPopover()}
                isActive={this.state.filteredItems.length !== this.props.allItems.length} onRequestDelete={() => this.setFilteredItems(this.props.allItems)}/>
        </div>;
    }

    private getPopover = () => {
        const isToggled = this.state.filteredItems.length === this.props.allItems.length;
        const objects = [];
        const added = [];
        this.props.allItems.forEach(item => {
            if (added.indexOf(item) === -1) {
                objects.push(<FormControlLabel key={item} name={`item-${item}`} label={item}
                    control={<Checkbox style={{padding: "2px"}} color="primary" checked={this.state.filteredItems.indexOf(item) !== -1}/>}
                    onChange={(_, isChecked) => {
                        const filteredItems = [...this.state.filteredItems];
                        if (!isChecked) {
                            const index = filteredItems.indexOf(item);
                            filteredItems.splice(index, 1);
                        } else {
                            filteredItems.push(item);
                        }

                        this.setFilteredItems(filteredItems);
                    }}
                />);
                added.push(item);
            }
        });

        const content = <div style={{margin: "8px 16px", display: "flex", flexDirection: "column"}}>
            <FormControlLabel label="Select All" control={<Switch checked={isToggled} style={{marginBottom: "8px"}} color="primary"/>}
                onChange={() => {
                    if (this.state.filteredItems.length < this.props.allItems.length) {
                        this.setFilteredItems(this.props.allItems);
                    } else {
                        this.setFilteredItems([]);
                    }
                }}/>
            <Divider style={{margin: "8px 0px"}}/>
            {objects}
        </div>;

        return {title: this.props.label, content}
    };

    private setFilteredItems = (filteredItems) => {
        this.setState({filteredItems});

        let filter = undefined;
        if (filteredItems.length && filteredItems.length !== this.props.allItems.length) {
            filter = [];
            this.props.allItems.forEach((item) => {
                if (filteredItems.indexOf(item) !== -1) {
                    filter.push(item);
                }
            });
        }
        this.debouncedSetFilterItem(this.props.filterKey, filter);
    }
}

export default FilterBy
