import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../../redux/actions";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import getMessageFromResponse from "../../../../../lib/getMessageFromResponse";
import * as validateData from "../../../../../lib/validate-users-data";
import {Link} from "react-router-dom";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import {CircularProgress, Button, TextField, Card, CardContent, CardHeader, CardActions} from "@material-ui/core";
import consoleLogger from "../../../../../lib/consoleLogger";

const INIT_STATE = {
    status: "ready",
    data: {email: ""},
    emailSentMessage: ""
};

class Class extends React.Component<any, any> {
    public static propTypes = {
        history: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        usersResetPasswordRequest: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        const {accountId} = getHostnameInfo();
        if (!accountId) {
            this.props.history.push("/signin");
        }
    }

    public render() {
        return <div className="container">
            <Card style={{width: "480px", marginTop: "48px", marginRight: "auto", marginLeft: "auto", padding: "28px", borderRadius: "8px"}}>
                {["ready", "wait"].includes(this.state.status) ? this.renderEnterEmail() : null}
                {this.state.status === "email-sent" ? this.renderEmailSentMessage() : null}
            </Card>

            {this.renderAdditionalLinks()}
        </div>;
    }

    private renderEnterEmail = () => {
        const {accountId, domain, type} = getHostnameInfo();

        const dataValidation = validateData.onEnterEmail(this.state)
        const isDataValid: boolean = Object.keys(dataValidation).reduce((acc: boolean, key: string): boolean => acc && !dataValidation[key], true);

        return <div>
            <CardHeader
                title={<div>
                    <h2 style={{margin: "4px 0 24px", textAlign: "center", fontSize: "28px"}}>
                        Reset your password
                    </h2>
                    <p style={{margin: "8px 0 16px 0", textAlign: "center", fontSize: "14px"}}>
                        {`${accountId}.${domain}.${type}`}
                    </p>
                </div>}
                subheader={(
                    <p style={{margin: 0, color: this.props.muiTheme.palette.textColor, textAlign: "center", fontSize: "16px"}}>
                        Enter your&nbsp;
                        <strong><big>email address</big></strong>&nbsp;
                    </p>
                )}/>

            <CardContent>
                <TextField
                    name='email'
                    type='email'
                    value={this.state.data.email || ''}
                    label={dataValidation.email || 'Email'}
                    placeholder='your-email@example.com'
                    fullWidth
                    error={!!dataValidation.email}
                    helperText={!!dataValidation.email}
                    disabled={this.state.status !== 'ready'}
                    onChange={e => this.setState(prevState => ({
                        data: {
                            ...prevState.data,
                            email: e.target.value,
                        }
                    }))}/>
            </CardContent>

            <CardActions style={{padding: "16px"}}>
                <Button variant="contained" color="primary" disabled={!this.state.data.email || !isDataValid || this.state.status !== "ready"} fullWidth
                    onClick={() => this.setState(
                        {status: "wait"},
                        async () => {
                            try {
                                const response = await this.props.usersResetPasswordRequest(this.state.data, {bypassError: true});
                                const emailSentMessage = getMessageFromResponse(response)
                                this.setState({
                                    status: "email-sent",
                                    emailSentMessage
                                });
                            } catch (reason) {
                                consoleLogger.error("::: reason:", reason);
                            }
                        }
                    )}>
                    {this.state.status === "wait" ? <CircularProgress size={24} style={{marginTop: "5px"}}/> : "Send Password Reset Email"}
                </Button>
            </CardActions>
            <CardContent style={{padding: "16px", textAlign: "center", fontSize: "16px"}}>
                <strong>
                    <a href="#!" style={{color: this.props.muiTheme.palette.disabledColor, fontSize: "16px", textDecoration: "none"}}>
                        Forgot which email you used?
                    </a>
                </strong>
            </CardContent>
        </div>;
    }

    private renderEmailSentMessage = () => {
        const {accountId, domain, type} = getHostnameInfo();

        return <CardHeader
            title={<div>
                <h2 style={{margin: "32px 0 0 0", textAlign: "center"}}>
                    Reset your password
                </h2>
                <p style={{margin: "8px 0 16px 0", textAlign: "center", fontSize: "14px"}}>
                    {`${accountId}.${domain}.${type}`}
                </p>
            </div>}
            subheader={<p style={{margin: 0, color: this.props.muiTheme.palette.textColor, textAlign: "center", fontSize: "16px"}}>
                <strong><big>Email sent</big></strong>
                <br/><br/>
                {this.state.emailSentMessage || "Follow the instructions in the email to reset your password"}
            </p>}
        />;
    }

    private renderAdditionalLinks = () => {
        const {protocol, domain, type, port} = getHostnameInfo();

        return <div style={{marginTop: "48px", textAlign: "center"}}>
            <strong>
                <Link to="/signin" style={{fontSize: "16px", textDecoration: "none"}}>
                    Return to login page
                </Link>
            </strong>
            <br/><br/>
            <strong>
                <a href="#!" style={{fontSize: "16px", textDecoration: "none"}}
                    onClick={event => {
                        event.preventDefault();
                        window.location.href = `${protocol}//${domain}.${type}${port ? ":" + port : ""}/console/signin`;
                    }}>
                    Sign into another account?
                </a>
            </strong>
            <br/>
        </div>;
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Class));
