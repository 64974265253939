import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../../redux/actions";
import getAppUsersCallUrl from "../../../../../../../lib/getAppUsersCallUrl";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

const CustomDialogActions  = (props) => {
    return <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
        <Button
            color="primary" style={{ marginRight: 8 }}
            disabled={props.parentState.status !== "ready"}
            onClick={props.onClose}
        >
            Cancel
        </Button>
        <Button
            variant="contained" color="secondary" style={{ marginRight: 8 }}
            disabled={props.parentState.status !== "ready"}
            onClick={async () => {
                props.onSetParentState({ status: "waiting" });
                const selectedAppUser = props.parentState?.selectedAppUser || {};
                const accountId = selectedAppUser.accountId;
                const environmentId = selectedAppUser.environmentId;
                const directoryId = selectedAppUser.dirId;
                const appUserId = selectedAppUser.uid;
                const appUserUrl = getAppUsersCallUrl(accountId, environmentId, directoryId) + `/${appUserId}`;
                const response = await props.appUsersDelete(props.config, appUserUrl)
                if (response && response.status < 300) {
                    props.initialize();
                }
            }}
        >
            Delete user
        </Button>
    </DialogActions>;
}

CustomDialogActions.propTypes = {
    config: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,

    appUsersDelete: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appUsersDelete: actions.appUsersDelete,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CustomDialogActions);
