import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import CopyIcon from '@material-ui/icons/FileCopy'
import getGatewaysResourceUrl from '../../../../../../../../lib/getGatewaysResourceUrl'
import getHostnameInfo from '../../../../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../../../../lib/getLocationInfo'
import type { TAppActivation } from '../../../../../../../../types'
import type { TEhrAuthType } from '../..'

type TProps = {
    appActivation: TAppActivation
    config: any
    ehrAuthType: TEhrAuthType
    isEpicR4Gateway: boolean
    location: any
    selectedGatewayId: string
}

const LaunchDetails: React.FC<TProps> = (props: TProps) => {
    const gatewayUrlRef = React.useRef<any>(null)
    const launchUrlRef = React.useRef<any>(null)
    const redirectUrlRef = React.useRef<any>(null)
    const idRef = React.useRef<any>(null)

    const { accountId } = getHostnameInfo()
    const { env } = getLocationInfo(props.location, props.config)

    const gatewayUrl = getGatewaysResourceUrl(accountId, env, props.selectedGatewayId, props.config.proxyApi)

    const alpLaunchUrl = props.appActivation.allscriptsUnityLaunchProxyInfoApi?.launchUrl || ''
    const slpLaunchUrl = props.appActivation.smartLaunchProxyInfo?.launchUrl || ''
    const smart2LaunchUrl = props.appActivation.smartLaunchProxyInfo2Api?.launchUrl || ''
    const launchUrl = alpLaunchUrl || slpLaunchUrl || smart2LaunchUrl || ''

    const redirectUrl = props.appActivation.smartLaunchProxyInfo?.redirectUrl
        || props.appActivation.smartLaunchProxyInfo2Api?.redirectUrl
        || ''

    const id = props.appActivation.smartLaunchProxyInfo?.smartLaunchProxyId
        || props.appActivation.externalAuth?.externalAuthApiId
        || props.appActivation.smartLaunchProxyInfo2Api?.smartLaunchProxyId
        || ''
    const isIdHidden = !id || (props.isEpicR4Gateway && props.ehrAuthType === ':SYSTEM:')

    return (
        <div style={{
            padding: '24px',
            marginBottom: '24px',
            background: '#f5f5f5',
            borderTop: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        }}>
            {[
                {
                    isDisplayed: true,
                    name: 'ld-gateway-url',
                    value: gatewayUrl,
                    label: 'Gateway URL',
                    ref: gatewayUrlRef,
                },
                {
                    isDisplayed: !!launchUrl,
                    name: 'ld-launch-url',
                    value: launchUrl,
                    label: 'Launch URL',
                    ref: launchUrlRef,
                },
                {
                    isDisplayed: !!redirectUrl,
                    name: 'ld-redirect-url',
                    value: redirectUrl,
                    label: 'Redirect URL',
                    ref: redirectUrlRef,
                },
                {
                    isDisplayed: !isIdHidden,
                    name: 'ld-slp-id',
                    value: id,
                    label: 'ID',
                    ref: idRef,
                },
            ].map((item) => (item.isDisplayed ? (
                <div key={item.name}>
                    <TextField
                        name={item.name}
                        value={item.value}
                        disabled
                        label={item.label}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={() => {
                                            item.ref.current.select()
                                            document.execCommand('copy')
                                        }}
                                    >
                                        <Tooltip title="Copy"><CopyIcon /></Tooltip>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        fullWidth
                        classes={{ root: 'disabled-text-field' }}
                    />
                    <textarea
                        ref={item.ref}
                        value={item.value}
                        readOnly
                        style={{ width: 1, height: 1, margin: 0, padding: 0, border: 'none', opacity: 0 }}
                    />
                </div>
            ) : null))}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(LaunchDetails))
