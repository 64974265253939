import * as React from "react";
import * as PropTypes from "prop-types";
import {Divider, Card, CircularProgress} from "@material-ui/core";
import {getNumberLocaleString} from "../../../../../lib/utils";

export class TotalCount extends React.Component<any, any> {
    public static propTypes = {
        items: PropTypes.object.isRequired,
        title: PropTypes.string
    };

    public static defaultProps = {
        title: "Totals"
    };

    public shouldComponentUpdate(nextProps) {
        // TODO: Object.entries() doesn't work with the project's TypeScript version (2.2.1)
        const isRefreshing = Object.keys(nextProps.items).reduce((acc, key) => acc || nextProps.items[key].refreshing, false);
        return !isRefreshing
    }

    public render() {
        return <div data-qa="total-count">
            <div data-qa="title" style={{margin: "30px 10px 10px"}}>{this.props.title}</div>
            <Card style={{width: "100%", marginTop: 10, marginRight: 5, marginBottom: 10, marginLeft: 5, paddingBottom: 20}}>
                <div data-qa="card-content-container" style={{display: "flex", justifyContent: "space-evenly"}}>
                    {Object.keys(this.props.items).map((key, idx) => {
                        const {data, displayName, prefix, suffix, zeroValue} = this.props.items[key];
                        return <div data-qa={`item-content-container-${idx}`} key={key} style={{width: "50%", textAlign: "center"}}>
                            {data === null
                                ? <CircularProgress style={{marginTop: 20}}/>
                                : <div data-qa={`item-value-${idx}`} style={{margin: "20px 0 auto", width: "100%", fontSize: 42, color: "@color-blue-dark"}}>
                                    {`${prefix || ""}${!data && zeroValue ? zeroValue : getNumberLocaleString(data)}${suffix || ""}`}
                                </div>}
                            <Divider style={{width: "5%", marginTop: 5, marginBottom: 5, marginLeft: "auto", marginRight: "auto"}}/>
                            <div data-qa={`item-display-name-${idx}`} style={{color: "gray"}}>{displayName}</div>
                        </div>
                    })}
                </div>
            </Card>
        </div>;
    }
}

export default TotalCount;
