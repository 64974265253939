import * as React from "react";
import * as PropTypes from "prop-types";
import {Dialog, DialogTitle, DialogActions, DialogContent} from "@material-ui/core";
import Actions from "./Actions";
import Content from "./Content";
import Title from "./Title";

const Component: React.FunctionComponent<any> = props => {
    return <Dialog open style={{paddingTop: "5px", zIndex: 1250}} maxWidth="lg" classes={{paper: "io-modal-wrapper"}}>
        <DialogTitle style={{height: "48px", background: props.muiTheme.palette.accent1Color, color: "#fff", lineHeight: "48px", fontSize: "24px", fontWeight: 600}}>
            <Title {...props} />
        </DialogTitle>
        <DialogContent style={{width: "100%", paddingTop: "24px", boxSizing: "border-box"}}>
            <Content {...props} />
        </DialogContent>
        <DialogActions style={{padding: "0 24px 24px"}}>
            <Actions {...props} />
        </DialogActions>
    </Dialog>;
}

Component.propTypes = {
    muiTheme: PropTypes.object.isRequired
};

export default Component;
