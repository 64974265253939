import * as React from "react";
import * as PropTypes from "prop-types";
import withLegacyTheme from "../../../lib/hoc/with-legacy-theme";
import {Chip, Popover} from "@material-ui/core";
import {ArrowDropDown, Close, Cancel} from "@material-ui/icons";
import "./style.less";

class ChipDropDown extends React.Component<any, any> {
    public static propTypes = {
        activeBackground: PropTypes.string,
        activeColor: PropTypes.string,
        deleteIconStyle: PropTypes.object,
        disabled: PropTypes.bool,
        inactiveBackground: PropTypes.string,
        inactiveColor: PropTypes.string,
        isActive: PropTypes.bool,
        label: PropTypes.string,
        popover: PropTypes.object,
        style: PropTypes.object,
        onRequestDelete: PropTypes.func,
        onClose: PropTypes.func
    };

    public static defaultProps = {
        activeBackground: "",
        activeColor: "",
        deleteIconStyle: {},
        disabled: false,
        inactiveBackground: "",
        inactiveColor: "",
        isActive: false,
        label: "",
        popover: {
            title: "",
            content: null
        },
        style: {},

        onRequestDelete: () => {
        }
    };

    public state = {
        open: false,
        anchorEl: null
    }

    public render() {
        const {activeBackground, activeColor, disabled, inactiveBackground, inactiveColor, isActive} = this.props;

        return <div>
            <Chip onDelete={isActive ? this.props.onRequestDelete : null} onClick={e => !disabled && this.setState({open: true, anchorEl: e.currentTarget})}
                label={<span>{this.props.label} {!disabled && !isActive ? <ArrowDropDown style={{top: 5, position: "absolute"}}/> : ""}</span>}
                deleteIcon={<Cancel style={...this.props.deleteIconStyle} />}
                style={{
                    backgroundColor: isActive ? activeBackground : inactiveBackground,
                    paddingRight: isActive ? 0 : "20px",
                    cursor: disabled ? "default" : "pointer",
                    color: isActive ? activeColor : inactiveColor,
                    ...this.props.style
                }}/>
            <Popover className="IO-chip-popover IO-chip-popover-menu" open={this.state.open} anchorEl={this.state.anchorEl} anchorOrigin={{horizontal: "left", vertical: "bottom"}}
                onClose={this.handleRequestClose} style={{marginTop: "5px"}}>
                <h4 className="title" style={{background: activeBackground, color: activeColor}}>
                    <span className="text" style={{padding: "5px 0 5px 20px"}}>
                        {this.props.popover.title}
                    </span>
                    <Close style={{color: activeColor, padding: "5px 20px 5px 0"}} className="icon" onClick={this.handleRequestClose}/>
                </h4>
                <div style={{padding: 5}}>
                    {this.props.popover.content}
                </div>
            </Popover>
        </div>;
    }

    private handleRequestClose = () => {
        this.setState({open: false});
        !!this.props.onClose && this.props.onClose();
    }
}

export default withLegacyTheme()(ChipDropDown);
