import * as PropTypes from "prop-types";
import withLegacyTheme from "../../../../../../../../../lib/hoc/with-legacy-theme";

const CustomTitle = (props) => {
    return <h4 style={{ marginBottom: 0, color: props.identifierMappings.length ? props.muiTheme.palette.textColor : props.muiTheme.palette.secondaryTextColor }}>
        Mappings
    </h4>
};

CustomTitle.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    identifierMappings: PropTypes.array,
};

CustomTitle.defaultProps = {
    identifierMappings: [],
};

export default withLegacyTheme()(CustomTitle);
