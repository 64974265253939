import checkEmail from "./checkEmail";
import checkPassword from "./checkPassword";

export function onWStep1(
    state: any,
    // userDirs: any,
    // config: any,
) {
    const name: string = state.data.name || "";
    const nameMessage: string = name ? "" : "Required field";

    const email: string = state.data.email || "";
    let emailMessage: string = "";
    if (email) {
        emailMessage = checkEmail(email) ? "Invalid email address" : "";
    }
    else {
        emailMessage = "Required field";
    }

    const password: string = state.data.password || "";
    let passwordMessage: string = "";
    if (password) {
        passwordMessage = checkPassword(password)
    }
    else {
        passwordMessage = "Required field";
    }

    const __temp__ds: string = state.data.__temp__ds || ""
    const __temp__fhirId: string = state.data.__temp__fhirId || ""
    let __temp__dsMessage: string = "";
    if (!__temp__ds && __temp__fhirId) {
        __temp__dsMessage = "Select data adapter"
    }

    return {
        name: nameMessage,
        email: emailMessage,
        password: passwordMessage,
        __temp__ds: __temp__dsMessage,
    };
}

export function onEdit(state: any) {
    const name: string = state.data.name || "";
    const nameMessage: string = name ? "" : "Required field";

    const email: string = state.data.email || "";
    let emailMessage: string = "";
    if (email) {
        emailMessage = checkEmail(email) ? "Invalid email address" : "";
    }
    else {
        emailMessage = "Required field";
    }

    const password: string = state.data.password || "";
    let passwordMessage: string = "";
    if (password) {
        passwordMessage = checkPassword(password)
    }
    else {
        passwordMessage = "";
    }

    const __temp__ds: string = state.data.__temp__ds || ""
    const __temp__fhirId: string = state.data.__temp__fhirId || ""
    let __temp__dsMessage: string = "";
    if (!__temp__ds && __temp__fhirId) {
        __temp__dsMessage = "Select data adapter"
    }

    return {
        name: nameMessage,
        email: emailMessage,
        password: passwordMessage,
        __temp__ds: __temp__dsMessage,
    };
}
