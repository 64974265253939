import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import {Link} from "react-router-dom";
import getEnvironmentsCallUrl from "../../../../lib/getEnvironmentsCallUrl";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import {NavbarL2, NavbarL3} from "../../../CustomNavbar/";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import Details from "./Details/";

const TABS = [
    {title: "DETAILS"}
];

const TAB_SELECTED_BY_DEFAULT = 0;

const INIT_STATE = {
    status: "",
    data: {},
    dataValidation: {},
    mode: ":VIEW:"
};

class EnvironmentEdit extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        environments: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,
        environmentsRead: PropTypes.func.isRequired,
        uiTabsSetItems: PropTypes.func.isRequired,
        uiTabsSetSelected: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.props.uiTabsSetItems(TABS);
        this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
        this.initialize();
    }

    public componentDidUpdate(prevProps) {
        const selectedTab = this.props?.ui?.tabs?.selected;
        const prevSelectedTab = prevProps?.ui?.tabs?.selected;
        if (selectedTab !== prevSelectedTab) {
            this.initialize();
        }
    }

    public render() {
        const {env} = getLocationInfo(this.props.location, this.props.config);

        return <div style={{width: "100%"}}>
            <NavbarL2 title={<div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <div style={{height: 56, marginRight: 24}}>
                    <Link to={`/environments/${env}`} style={{color: "#fff", fontSize: 24, textDecoration: "none"}}>
                        Environment Settings
                    </Link>
                </div>
            </div>}/>

            <NavbarL3 disableAll={this.state.status !== "ready"}/>

            <div className="container">
                {["ready"].includes(this.state.status)
                    ? this.renderTabContent()
                    : <CenteredCircularProgress size={63} style={{padding: 24}}/>}
            </div>
            <br/>
        </div>;
    }

    private renderTabContent = () => {
        const contentProps = {
            parentState: this.state,
            initialize: this.initialize,
            onSetParentState: this.onSetState
        }

        switch (this.props.ui.tabs.selected) {
            case 0:
                return <Details {...contentProps} />;
            default:
                return null;
        }
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private initialize = () => {
        this.setState(
            {status: "init"},
            async () => {
                const {accountId} = getHostnameInfo();
                const {env} = getLocationInfo(this.props.location, this.props.config);
                await this.props.environmentsRead(this.props.config, getEnvironmentsCallUrl(accountId) + "/" + env);
                this.setState({
                    ...INIT_STATE,
                    status: "ready",
                    data: this.props.environments.selected.data
                });
            }
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    environments: state.environments,
    ui: state.ui,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    environmentsRead: actions.environmentsRead,
    uiTabsSetItems: actions.uiTabsSetItems,
    uiTabsSetSelected: actions.uiTabsSetSelected,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentEdit);
