import * as React from "react";
import * as PropTypes from "prop-types";
import {CardActions, Button} from "@material-ui/core";
import consoleLogger from "../../../../../../lib/consoleLogger";

// TODO: TProps ...

const ActionButtons: React.FC<any> = (props) => {
    return (
        <CardActions>
            <div style={{
                height: props.canEdit ? '36px' : 0,
                marginTop: props.canEdit ? '8px' : 0,
                marginBottom: props.canEdit ? '8px' : 0,
                textAlign: 'right',
                width: '99%',
                transition: 'all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)',
            }}>
                <Button
                    data-qa="user-profile-password-cancel-button"
                    disabled={!props.canEdit}
                    variant="outlined"
                    style={{
                        display: props.canEdit ? 'inline-block' : 'none',
                        marginRight: '8px',
                    }}
                    onClick={() => {
                        props.onSetState({ mode: ':VIEW:' }, props.initialize)
                    }}
                >
                    Cancel
                </Button>
                <Button
                    data-qa="user-profile-password-save-button"
                    disabled={!props.canEdit || !props.isValid || !props.state.data.oldPassword || !props.state.data.newPassword || props.state.passwordError}
                    variant='contained'
                    color='primary'
                    style={{ display: props.canEdit ? 'inline-block' : 'none' }}
                    onClick={() => {
                        const data = {
                            newPassword: props.state.data.newPassword,
                            oldPassword: props.state.data.oldPassword,
                        }
                        props.onSetState(
                            { passwordError: false },
                            async () => {
                                const res = await props.usersCurrentUpdate(props.config, data)
                                    .catch((reason) => consoleLogger.error('::: reason:', reason))
                                if (res && res.status < 300 && res.data) {
                                    window.location.reload()
                                }
                                else {
                                    props.onSetState({ passwordError: true })
                                }

                            }
                        )
                    }}
                >
                    Save
                </Button>
            </div>
        </CardActions>
    )
}

ActionButtons.propTypes = {
    canEdit: PropTypes.bool,
    config: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    isValid: PropTypes.bool,
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired,
    usersCurrentUpdate: PropTypes.func.isRequired,
}

ActionButtons.defaultProps = {
    canEdit: true,
    isValid: true,
}

export default ActionButtons
