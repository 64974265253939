import * as types from "../../actions/types";

import initialState from "./initial-state";

export default function (state = initialState, action: any) {
    switch (action.type) {
        case types.ACCOUNTS_ACCOUNT_USERS_SET: {
            return {
                ...state,
                accountUsers: {
                    ...state.accountUsers,
                    ...action.payload,
                },
            };
        }

        case types.ACCOUNTS_SELECTED_SET: {
            return {
                ...state,
                selected: {
                    ...state.selected,
                    ...action.payload,
                },
            };
        }

        case types.ACCOUNTS_SSO_CONFIG_SET: {
            return {
                ...state,
                selectedSsoConfig: {
                    ...state.selected,
                    ...action.payload,
                },
            };
        }

        default:
            return state;
    }
}
