import getActiveEnvironment, { ActiveEnvironment } from "../../../lib/getActiveEnvironment";

import fhirDSTU2Resources from "./fhir-dstu2-resources";
import fhirSTU3Resources from "./fhir-stu3-resources";
import fhirR4Resources from "./fhir-r4-resources";
import fhirUscdiResources from './fhir-uscdi-resources'
import knowledgeBaseLinks from "./knowledge-base-links"
import getHostnameInfo from "../../../lib/getHostnameInfo";
import consoleLogger from "../../../lib/consoleLogger";

const activeEnv: ActiveEnvironment = getActiveEnvironment();
const { domain, type } = getHostnameInfo()
// This condition is used for unit tests
const DOMAIN_NAME = `${domain}.${type}` !== 'interopio.mckesson.local' ? `${domain}.${type}` : ''

// Account API
export function getAccountApi(_activeEnv?: string) {
    consoleLogger.log('DOMAIN_NAME in getAccountApi()', DOMAIN_NAME)
    switch (_activeEnv || activeEnv) {
        case ":LOCAL:":
            return {
                protocol: "http",
                host: DOMAIN_NAME || "interopio.mckesson.local",
                path: "account-api-2",
                port: "10000",
                // port: "10020",
            }
        case ":DEVELOPMENT:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-dev.com",
                path: "account-api-2",
                port: "443",
            }
        case ":TEST:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-test.com",
                path: "account-api-2",
                port: "443",
            }
        default:
            // Production
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio.com",
                path: "account-api-2",
                port: "443"
            }
    }
}

// Analytics Service
export function getAnalyticsService(_activeEnv?: string) {
    switch (_activeEnv || activeEnv) {
        case ":LOCAL:":
            return {
                protocol: "http",
                host: DOMAIN_NAME || "interopio.mckesson.local",
                path: "analytics-service",
                port: "10000",
                // port: "10700",
            }
        case ":DEVELOPMENT:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-dev.com",
                path: "analytics-service",
                port: "443",
            }
        case ":TEST:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-test.com",
                path: "analytics-service",
                port: "443",
            }
        default:
            // Production
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio.com",
                path: "analytics-service",
                port: "443"
            }
    }
}

// features API
export function getConfigApi(_activeEnv?: string) {
    switch (_activeEnv || activeEnv) {
        case ":LOCAL:":
            return {
                protocol: "http",
                host: DOMAIN_NAME || "interopio.mckesson.local",
                path: "console",
                port: "10000"
            }
        case ":DEVELOPMENT:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-dev.com",
                path: "console",
                port: "443"
            }
        case ":TEST:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-test.com",
                path: "console",
                port: "443"
            }
        default:
            // Production
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio.com",
                path: "console",
                port: "443"
            }
    }
}

// Environment API
export function getEnvironmentApi(_activeEnv?: string) {
    switch (_activeEnv || activeEnv) {
        case ":LOCAL:":
            return {
                protocol: "http",
                host: DOMAIN_NAME || "interopio.mckesson.local",
                path: "environment-api",
                port: "10000",
                // port: "10600",
            }
        case ":DEVELOPMENT:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-dev.com",
                path: "environment-api",
                port: "443",
            }
        case ":TEST:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-test.com",
                path: "environment-api",
                port: "443",
            }
        // case ":DEV-AZURE:":
        //     return {
        //         protocol: "https",
        //         host: "interopio-dev.com",
        //         path: "environment-api",
        //         port: "443",
        //     }
        default:
            // Production
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio.com",
                path: "environment-api",
                port: "443",
            }
    }
}

// features API
export function getFeaturesApi(_activeEnv?: string) {
    switch (_activeEnv || activeEnv) {
        case ":LOCAL:":
            return {
                protocol: "http",
                host: DOMAIN_NAME || "interopio.mckesson.local",
                path: "console",
                port: "10000"
            }
        case ":DEVELOPMENT:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-dev.com",
                path: "console",
                port: "443"
            }
        case ":TEST:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-test.com",
                path: "console",
                port: "443"
            }
        default:
            // Production
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio.com",
                path: "console",
                port: "443"
            }
    }
}

// Proxy API
export function getProxyApi(_activeEnv?: string) {
    switch (_activeEnv || activeEnv) {
        case ":LOCAL:":
            return {
                protocol: "http",
                host: "localhost",
                path: "gateway/fhir",
                port: "10000",
                // port: "10300"
            }
        case ":DEVELOPMENT:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-dev.com",
                path: "gateway/fhir",
                port: "443"
            }
        case ":TEST:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-test.com",
                path: "gateway/fhir",
                port: "443"
            }
        default:
            // Production
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio.com",
                path: "gateway/fhir",
                port: "443"
            }
    }
}

// Patient Portal
export function getPatientPortal(_activeEnv?: string) {
    switch (_activeEnv || activeEnv) {
        case ":LOCAL:":
            return {
                protocol: "http",
                host: DOMAIN_NAME || "interopio.mckesson.local",
                path: "patient-portal",
                port: "10000",
                // port: "10810"
            }
        case ":DEVELOPMENT:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-dev.com",
                path: "patient-portal",
                port: "443"
            }
        case ":TEST:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-test.com",
                path: "patient-portal",
                port: "443"
            }
        default:
            // Production
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio.com",
                path: "patient-portal",
                port: "443"
            }
    }
}

// Provider Portal
export function getProviderPortal(_activeEnv?: string) {
    switch (_activeEnv || activeEnv) {
        case ":LOCAL:":
            return {
                protocol: "http",
                host: DOMAIN_NAME || "interopio.mckesson.local",
                path: "provider-portal",
                port: "10000",
                // port: "10810"
            }
        case ":DEVELOPMENT:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-dev.com",
                path: "provider-portal",
                port: "443"
            }
        case ":TEST:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-test.com",
                path: "provider-portal",
                port: "443"
            }
        default:
            // Production
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio.com",
                path: "provider-portal",
                port: "443"
            }
    }
}

// Placeholder Portal - used only to get a placeholder when showing portal URL
export function getPlaceholderPortal(_activeEnv?: string) {
    switch (_activeEnv || activeEnv) {
        case ":LOCAL:":
            return {
                protocol: "http",
                host: DOMAIN_NAME || "interopio.mckesson.local",
                path: "{portal-type}",
                port: "10000",
                // port: "10810"
            }
        case ":DEVELOPMENT:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-dev.com",
                path: "{portal-type}",
                port: "443"
            }
        case ":TEST:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-test.com",
                path: "{portal-type}",
                port: "443"
            }
        default:
            // Production
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio.com",
                path: "{portal-type}",
                port: "443"
            }
    }
}

// Monitoring API
export function getMonitoringApi(_activeEnv?: string) {
    switch (_activeEnv || activeEnv) {
        case ":LOCAL:":
            return {
                protocol: "http",
                host: DOMAIN_NAME || "interopio.mckesson.local",
                path: "monitoring",
                port: "10000"
            }
        case ":DEVELOPMENT:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-dev.com",
                path: "monitoring",
                port: "443"
            }
        case ":TEST:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-test.com",
                path: "monitoring",
                port: "443"
            }
        default:
            // Production
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio.com",
                path: "monitoring",
                port: "443"
            }
    }
}

// Logging API
export function getLoggingApi(_activeEnv?: string) {
    switch (_activeEnv || activeEnv) {
        case ":LOCAL:":
            return {
                protocol: "http",
                host: DOMAIN_NAME || "interopio.mckesson.local",
                path: "logging",
                port: "10000",
            }
        case ":DEVELOPMENT:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-dev.com",
                path: "logging",
                port: "443"
            }
        case ":TEST:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-test.com",
                path: "logging",
                port: "443"
            }
        default:
            // Production
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio.com",
                path: "logging",
                port: "443"
            }
    }
}

// CDR API
export function getCdrApi(_activeEnv?: string) {
    switch (_activeEnv || activeEnv) {
        case ":LOCAL:":
            return {
                protocol: "http",
                host: DOMAIN_NAME || "interopio.mckesson.local",
                path: "cdr",
                port: "10000",
            }
        case ":DEVELOPMENT:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-dev.com",
                path: "cdr",
                port: "443"
            }
        case ":TEST:":
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio-test.com",
                path: "cdr",
                port: "443"
            }
        default:
            // Production
            return {
                protocol: "https",
                host: DOMAIN_NAME || "interopio.com",
                path: "cdr",
                port: "443"
            }
    }
}

export default {
    accountApi: getAccountApi(),
    analyticsService: getAnalyticsService(),
    configApi: getConfigApi(),
    envApi: getEnvironmentApi(),
    featuresApi: getFeaturesApi(),
    proxyApi: getProxyApi(),
    monitoringApi: getMonitoringApi(),
    loggingApi: getLoggingApi(),
    cdrApi: getCdrApi(),

    patientPortal: getPatientPortal(),
    providerPortal: getProviderPortal(),

    // It is not possible to occur collisions between IDs and paths that contains `_`
    reservedIds: [
        "docs",

        // <:RESTRICTED:>.interopio.com
        "status",
        "www",

        // console/<:RESTRICTED:>
        // "about",
        "accounts",
        "console",
        "environments",
        "health",
        "home",
        "privacy-policy",
        "signin",
        "terms-of-service",
        "users",
        "terms",
        "policies",
        "legal",

        // console/**/<:RESTRICTED:>
        "analytics",
        "apps",
        "auth",
        "cds-service-sets",
        "cdrs",
        "datasources",
        "current",
        "gateways",
        "monitoring",

        "customer-terms-of-service",
        "user-terms-of-service",
        "customer-specific-supplement",
        "service-level-agreement",
        "privacy-policy",
        "acceptable-use-policy",
        "data-request-policy",
        "cookie-policy",
        "sso",

        // console/**/**/<:RESTRICTED:>
        // ...
        "activations",

        // console/**/**/**/<:RESTRICTED:>
        // ...
    ],
    unrestrictedPages: [
        "/",
        "/home",

        // "/about",
        "/legal",

        "/signin",
        "/terms-of-service",
        "/terms/customer-terms-of-service",
        "/terms/user-terms-of-service",
        "/terms/customer-specific-supplement",
        "/terms/service-level-agreement",
        "/policies",
        "/policies/privacy-policy",
        "/policies/privacy-notice",
        "/policies/acceptable-use-policy",
        "/policies/data-request-policy",
        "/policies/cookie-policy",
        "/customer-terms-of-service",
        "/user-terms-of-service",
        "/customer-specific-supplement",
        "/service-level-agreement",
        "/privacy-policy",
        "/privacy-notice",
        "/acceptable-use-policy",
        "/data-request-policy",
        "/cookie-policy",
        "/legal/archives",


        "/accounts/new",
        "/accounts/request",

        "/users/account-list/enter-email",
        "/users/change-email/confirm",
        "/users/invitation/confirm",
        "/users/reset-password/confirm",
        "/users/reset-password/enter-email",

        "/sso",
        "/sso/error"

    ],

    unrestrictedTosPages: [
        "/privacy-policy",
        "/privacy-notice",
        "/terms-of-service",
    ],

    fhirDSTU2Resources,
    fhirSTU3Resources,
    fhirR4Resources,
    fhirUscdiResources,

    knowledgeBaseLinks,

    userRoles: [],
    userStatuses: [],
};
