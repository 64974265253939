import * as React from "react";
import * as PropTypes from "prop-types";
import {IconButton, Tooltip} from "@material-ui/core";
import {Close} from "@material-ui/icons";

const Component: React.FunctionComponent<any> = props => {
    return <div onClick={() => props.onSetState({dialog: ""})} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
        <span>Edit</span>
        <Tooltip title="Close">
            <IconButton>
                <Close style={{color: "rgba(255, 255, 255, 0.85)"}}/>
            </IconButton>
        </Tooltip>
    </div>;
}

Component.propTypes = {
    onSetState: PropTypes.func.isRequired
};

export default Component;
