import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import getDataSourcesCallUrl from "../../../../lib/getDataSourcesCallUrl";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import {Link} from "react-router-dom";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import StorageIcon from "@material-ui/icons/Storage";
import {NavbarL2, NavbarL3} from "../../../CustomNavbar/";
import Selector from "../../../CustomNavbar/Selector";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import LoggingTab from "../../../Widgets/LoggingTab";
import Details from "./Details/";
import SupportedOperations from "./SupportedOperations";
import Transactions from "./Transactions/";
import Usage from "./Usage/";

const TABS = [
    {title: "DETAILS"},
    {title: "SUPPORTED OPERATIONS"},
    {title: "USAGE"},
    {title: "TRANSACTIONS"},
    {title: "LOGGING"}
];

const TAB_SELECTED_BY_DEFAULT = 0

const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "waiting"
    data: {},
    dataValidation: {},
    dataValidationStatus: "starting",
    availableDataAdapters: [],
    showMetadata: false,
    mode: ":VIEW:" // ":VIEW:" | ":EDIT:"
};

class DSEdit extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        dataSources: PropTypes.object.isRequired,
        gateways: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,

        dataSourcesRead: PropTypes.func.isRequired,
        dataSourcesGetAvailableDataAdapters: PropTypes.func.isRequired,
        uiTabsSetItems: PropTypes.func.isRequired,
        uiTabsSetSelected: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.props.uiTabsSetItems(TABS);
        if (this.props.ui.tabs.selected !== TAB_SELECTED_BY_DEFAULT) {
            this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
        } else {
            this.initialize();
        }
    }

    public componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.ui.tabs.selected !== TAB_SELECTED_BY_DEFAULT) {
                return this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
            }
            return this.initialize();
        }

        const selectedTab = this.props.ui?.tabs?.selected;
        const prevSelectedTab = prevProps.ui?.tabs?.selected;
        if (selectedTab !== prevSelectedTab) {
            this.initialize();
        }
    }

    public render() {
        const {env, dataSourceId: selectedDataSource} = getLocationInfo(this.props.location, this.props.config);

        return <div style={{width: "100%"}}>
            <NavbarL2 title={(
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{height: 56, marginRight: 24}}>
                        <Link to={`/${env}/datasources`} style={{color: "#fff", fontSize: 24, textDecoration: "none"}}>
                            Data Adapters
                        </Link>
                    </div>
                    <Selector data-qa="navbar-da-selector-name"
                        value={selectedDataSource}
                        icon={<StorageIcon style={{color: "rgba(255, 255, 255, 0.87)"}}/>}
                        data={this.props.dataSources.all ? this.props.dataSources.all.data.map((a) => ({id: a.dataSourceId, name: a.name})) : []}
                        onChange={item => this.props.history.push(`/${env}/datasources/${item.id}`)}
                    />
                </div>
            )}/>

            <NavbarL3 disableAll={this.state.status !== "ready"}/>

            <div className={`container${[2, 3, 4].includes(this.props.ui.tabs.selected) ? " full" : ""}`}>
                {["ready", "waiting"].includes(this.state.status)
                    ? this.renderTabContent()
                    : <CenteredCircularProgress size={63} style={{padding: 24}}/>}
            </div>
            <br/>
        </div>;
    }

    private renderTabContent = () => {
        const contentProps = {
            state: this.state,
            selected: this.props.selected,
            selectedStatus: this.props.selectedStatus,
            initialize: this.initialize,
            onSetState: this.onSetState,
            conformanceRead: this.props.conformanceRead
        }

        switch (this.props.ui.tabs.selected) {
            case 0:
                return <Details {...contentProps} />;
            case 1:
                return <SupportedOperations selected={this.props.selected} selectedStatus={this.props.selectedStatus}/>;
            case 2:
                return <Usage {...contentProps} />;
            case 3:
                return <Transactions {...contentProps} />;
            case 4:
                return <LoggingTab {...contentProps} config={this.props.config} location={this.props.location} login={this.props.login} dataSources={this.props.dataSources} gateways={this.props.gateways} muiTheme={this.props.muiTheme}
                    ui={this.props.ui} type="data-adapter" refresh/>;
            default:
                return null;
        }
    };

    private onSetState = (state, cb) => this.setState(state, cb);

    private initialize = async () => {
        this.setState({status: "init"});
        const {accountId} = getHostnameInfo();
        const {env, dataSourceId} = getLocationInfo(this.props.location, this.props.config);
        const url = getDataSourcesCallUrl(accountId, env) + "/" + dataSourceId;
        const [, {data: availableDataAdapters = []} = {}] = this.props.ui?.tabs?.selected !== 1
            ? await Promise.all([
                this.props.dataSourcesRead(this.props.config, url),
                this.props.dataSourcesGetAvailableDataAdapters(accountId)
            ])
            : await Promise.all([])
        this.setState({
            ...INIT_STATE,
            status: "ready",
            data: this.props.selected,
            availableDataAdapters
        });
    }
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    dataSources: state.dataSources,
    login: state.login,
    selected: state.dataSources.selected.data,
    selectedStatus: state.dataSources.selected.status,
    gateways: state.gateways,
    ui: state.ui,
    ...ownProps
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    dataSourcesGetAvailableDataAdapters: actions.dataSourcesGetAvailableDataAdapters,
    dataSourcesRead: actions.dataSourcesRead,
    conformanceRead: actions.conformanceRead,
    uiTabsSetItems: actions.uiTabsSetItems,
    uiTabsSetSelected: actions.uiTabsSetSelected
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(DSEdit));
