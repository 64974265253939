import * as React from "react";
import * as PropTypes from "prop-types";
import {IconButton, Tooltip} from "@material-ui/core";
import {Close} from "@material-ui/icons";

const Component: React.FunctionComponent<any> = props => {
    return <div style={{lineHeight: "48px"}}>
        <span data-qa="new-app-modal-title">Register an App</span>
        <Tooltip title="Close">
            <IconButton style={{position: "absolute", top: "18px", right: "24px"}} onClick={props.onClose}>
                <Close style={{color: "rgba(255, 255, 255, 0.85)"}}/>
            </IconButton>
        </Tooltip>
    </div>;
}

Component.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default Component;
