let moment = require("moment-timezone");

export default {
    showNavbar: true,
    leftMenuPrivate: {
        isExpanded: true,
        width: {
            expanded: 256,
            shrunk: 64,
        },
    },
    palette: {
        accent1Color: "",
        accent2Color: "",
        accent3Color: "",
        alternateTextColor: "",
        borderColor: "",
        canvasColor: "",
        clockCircleColor: "",
        disabledColor: "",
        pickerHeaderColor: "",
        primary1Color: "",
        primary2Color: "",
        primary3Color: "",
        shadowColor: "",
        textColor: "",
    },
    tabs: {
        selected: null,
        items: [],
    },
    xtheme: {
        palette: {},
    },
    timeZone: moment.tz.guess()
};
