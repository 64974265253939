import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../redux/actions";

import getAppUsersCallUrl from "../../../../../../lib/getAppUsersCallUrl";
import * as validateData from "../../../../../../lib/validate-app-users-data";
import Dialog from "@material-ui/core/Dialog";

import CustomDialogActions from "./CustomDialogActions";
import CustomDialogContent from "./CustomDialogContent";
import CustomDialogTitle from "./CustomDialogTitle";

const ETAB_DEFAULT = 1;

const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "waiting"
    data: {},
    dataValidation: {},
    etab: null,
};

class AppUserEdit extends React.Component<any, any> {
    public static propTypes = {
        appUsers: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,

        appUsersRead: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.initialize();
    }

    public componentDidUpdate(_, prevState) {
        const data_string = JSON.stringify(this.state.data);
        const prevData_string = JSON.stringify(prevState.data);
        if (data_string !== prevData_string) {
            this.validateData();
        }
    }

    public render() {
        const isValid = Object.keys(this.state.dataValidation).reduce((acc, key) => !!acc && !this.state.dataValidation[key], true);

        return <Dialog
            open disableBackdropClick disableEscapeKeyDown scroll = "body"
            onClose={this.props.onClose}
        >
            <CustomDialogTitle onClose={this.props.onClose} />
            <CustomDialogContent
                estate={this.state}
                onSetEState={this.onSetState}
            />
            <CustomDialogActions
                estate={this.state}
                isValid={isValid}
                onClose={this.props.onClose}
                onSetEState={this.onSetState}
            />
        </Dialog >
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private validateData = () => {
        const dataValidation = validateData.onEdit(this.state);
        this.setState({ dataValidation });
    }

    private initialize = async() => {
        this.setState({status: "init"});
        const selectedAppUser = this.props.parentState.selectedAppUser || {};
        const accountId = selectedAppUser.accountId;
        const environmentId = selectedAppUser.environmentId;
        const directoryId = selectedAppUser.dirId;
        const appUserId = selectedAppUser.uid;
        const url = getAppUsersCallUrl(accountId, environmentId, directoryId) + `/${appUserId}`;
        await this.props.appUsersRead(this.props.config, url);
        this.setState((prevState) => ({
            status: "ready",
            data: this.props.appUsers.selected.data,
            dataValidation: {},
            etab: prevState.etab !== null ? prevState.etab : ETAB_DEFAULT
        }), this.validateData);
    };
};

const mapStateToProps = (state, ownProps) => ({
    appUsers: state.appUsers,
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appUsersRead: actions.appUsersRead,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppUserEdit));
