import * as React from "react"
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import withLegacyTheme from "../../lib/hoc/with-legacy-theme";

import "./style.less";

class Footer extends React.Component<any, any>{
    public static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
        style: PropTypes.object.isRequired
    }

    public render() {
        return (
            <footer className="app-footer" style={this.props.style}>
                <div className="footer-container">
                    <span>
                        <div style={{ fontFamily: this.props.muiTheme.fontFamily, fontSize: 12, textAlign: "center" }}>
                            <Link to="/policies/privacy-notice" target="_blank" data-qa="user-menu-privacy-button" style={{ cursor: "pointer", marginRight: "5px" }}>
                                Privacy Notice
                            </Link>
                            &nbsp;·&nbsp;
                            <Link to="/terms/user-terms-of-service" target="_blank" data-qa="user-menu-terms-button" style={{ cursor: "pointer", marginLeft: "5px" }}>
                                Terms of Service
                            </Link>
                        </div>
                    </span>
                </div>
            </footer>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
export default withRouter(connect(mapStateToProps)(withLegacyTheme()(Footer)));