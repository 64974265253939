import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import axiosWrapper from '../../../../../lib/axiosWrapper'
import getHostnameInfo from '../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../lib/getLocationInfo'
import consoleLogger from '../../../../../lib/consoleLogger'
import type {
    TDataSourceType,
    TExternalAuth,
    TExternalAuthType,
} from '../../../../../types'
import {getPath} from "../../../../../lib/utils/";

export type TStatus = ':READY:' | ':WAITING:'

let timer = null

//NOTE: G2 R4 External DA uses the similar configuration as IKM R4 External DA but the field names are mixed up because of the implementation in the Backend

type TAvailableDataAdapter = {
    type: TDataSourceType | ''
    displayName: string
    configurationSchema: Partial<{
        type: "object"
        properties: Partial<{
            [K: string]: Partial<{
                type: "string" | "boolean"
                enum: string[]
                required: boolean
            }>
        }>
    }>
    compatibleFhirVersions:(
        "FHIR_DSTU2__1_0_2"
        | "FHIR_STU3__3_0_1"
        | "FHIR_STU3__3_0_2"
        | "FHIR_R4__4_0_0"
        | "FHIR_R4__4_0_1"
        | "HL7_V2"
    )[]
}

type TProps = {
    authTypeInitValue: TExternalAuthType
    availableDataAdapters: TAvailableDataAdapter[]
    canEdit: boolean
    clientApiEndpointInitValue: string
    clientIdInitValue: string
    clientSecretInitValue: string
    config: any
    configJsonStr: string
    dataSourceId: string
    dataSourceType: TDataSourceType | ''
    epicAppNameInitValue: string
    epicPublicKeyInitValue: string
    externalAuthApiIdInitValue: string
    location: any
    passwordInitValue: string
    usernameInitValue: string
    tokenEndpointInitValue: string
    credentialsPasswordInitValue: string
    credentialsUserInitValue: string

    // eslint-disable-next-line no-unused-vars
    callback?: (...args: any[]) => any
}

const ExternalAuth: React.FC<TProps> = (props: TProps) => {
    const [status, setStatus] = React.useState<TStatus>(':READY:')
    const [authType, setAuthType] = React.useState<TExternalAuthType>('OTHER')
    const [availableExternalDataAdapters, setAvailableExternalDataAdapters] = React.useState<(TDataSourceType | '')[]>([])
    const [clientApiEndpoint, setClientApiEndpoint] = React.useState('')
    const [clientId, setClientId] = React.useState('')
    const [clientSecret, setClientSecret] = React.useState('')
    const [epicAppName, setEpicAppName] = React.useState('')
    const [epicPublicKey, setEpicPublicKey] = React.useState('')
    const [externalAuthApiId, setExternalAuthApiId] = React.useState('')
    const [isExternal, setExternal] = React.useState(false)
    const [isAllscriptsUnityExt, setAllscriptsUnityExt] = React.useState(false)
    const [isEpicExt, setEpicExt] = React.useState(false)
    const [isIKnowMedExt, setIKnowMedExt] = React.useState(false)
    const [isG2Ext, setG2Ext] = React.useState(false)
    const [isGenericExt, setGenericExt] = React.useState(false)
    const [isR4ProxyExt, setR4ProxyExt] = React.useState(false)
    const [isVarianR4Ext, setVarianR4Ext] = React.useState(false)
    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [credentialsUser, setCredentialsUser] = React.useState('')
    const [credentialsPassword, setCredentialsPassword] = React.useState('')
    const [tokenEndpoint, setTokenEndpoint] = React.useState('')

    const [isClientSecretVisible, setClientSecretVisible] = React.useState(false)
    const [isPasswordVisible, setPasswordVisible] = React.useState(false)
    // This may be used in the future
    // const [isCredentialsPasswordVisible, setCredentialsPasswordVisible] = React.useState(false)

    let configJsonObj: Partial<{ [K: string]: boolean | string }>
    try {
        configJsonObj = JSON.parse(props.configJsonStr)
    }
    catch (_) {
        configJsonObj = {}
    }

    // init
    React.useEffect(() => {
        const _availableExternalDataAdapters = props.availableDataAdapters
            .filter((da) => (
                Boolean(da.type)
                && da.compatibleFhirVersions.some((ver) => ver === 'FHIR_R4__4_0_0' || ver === 'FHIR_R4__4_0_1')
                && Object.keys(da.configurationSchema?.properties || {}).includes('useStagingEnvironment')
            ))
            .map(({ type }) => type)
        setAvailableExternalDataAdapters(_availableExternalDataAdapters)
        const _isExternal = _availableExternalDataAdapters.includes(props.dataSourceType)
        setExternal(_isExternal)
        const _isAllscriptsUnityExt = _isExternal && props.dataSourceType === 'DataAdapterAllscriptsUnity'
        setAllscriptsUnityExt(_isAllscriptsUnityExt)
        const _isEpicExt = _isExternal && props.dataSourceType === 'DataSourceEpicR4External'
        setEpicExt(_isEpicExt)
        const _isIKnowMedExt = _isExternal && props.dataSourceType === 'DataSourceIKnowMedR4External'
        setIKnowMedExt(_isIKnowMedExt)
        const _isG2Ext = _isExternal && props.dataSourceType === 'DataSourceG2R4External'
        setG2Ext(_isG2Ext)
        const _isVarianR4Ext = _isExternal && props.dataSourceType === 'DataSourceVarianR4External'
        setVarianR4Ext(_isVarianR4Ext)
        const _isGenericExt = _isExternal && !_isAllscriptsUnityExt && !_isEpicExt && !_isIKnowMedExt && !_isG2Ext && !_isVarianR4Ext
        setGenericExt(_isGenericExt)
        const _isR4ProxyExt = props.dataSourceType === 'DataSourceFhirR4Proxy'
        setR4ProxyExt(_isR4ProxyExt)
        setAuthType(props.authTypeInitValue)
        setClientApiEndpoint(props.clientApiEndpointInitValue)
        setClientId(props.clientIdInitValue)
        setClientSecret(props.clientSecretInitValue)
        setEpicAppName(props.epicAppNameInitValue)
        setEpicPublicKey(props.epicPublicKeyInitValue)
        setExternalAuthApiId(props.externalAuthApiIdInitValue)
        setPassword(props.passwordInitValue)
        setUsername(props.usernameInitValue)
        setTokenEndpoint(props.tokenEndpointInitValue)
        setCredentialsPassword(props.credentialsPasswordInitValue)
        setCredentialsUser(props.credentialsUserInitValue)
    }, [
        props.authTypeInitValue,
        props.availableDataAdapters,
        props.clientApiEndpointInitValue,
        props.clientIdInitValue,
        props.clientSecretInitValue,
        props.dataSourceType,
        props.epicAppNameInitValue,
        props.epicPublicKeyInitValue,
        props.externalAuthApiIdInitValue,
        props.passwordInitValue,
        props.usernameInitValue,
        props.tokenEndpointInitValue,
        props.credentialsPasswordInitValue,
        props.credentialsUserInitValue,
    ])

    const callback = props.callback
    React.useEffect(() => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            if (callback) {
                callback({
                    authType,
                    availableExternalDataAdapters,
                    systemAuthEnabled: configJsonObj.systemAuthEnabled,
                    clientApiEndpoint,
                    clientId,
                    clientSecret,
                    credentialsPassword,
                    credentialsUser,
                    dataSourceType: props.dataSourceType,
                    epicAppName,
                    epicPublicKey,
                    externalAuthApiId,
                    isAllscriptsUnityExt,
                    isEpicExt,
                    isIKnowMedExt,
                    isG2Ext,
                    isExternal,
                    isGenericExt,
                    isVarianR4Ext,
                    isR4ProxyExt,
                    password,
                    username,
                    tokenEndpoint,
                })
            }
        }, 455)
    }, [
        authType,
        availableExternalDataAdapters,
        callback,
        clientApiEndpoint,
        clientId,
        clientSecret,
        configJsonObj.systemAuthEnabled,
        credentialsPassword,
        credentialsUser,
        epicAppName,
        epicPublicKey,
        externalAuthApiId,
        isAllscriptsUnityExt,
        isEpicExt,
        isIKnowMedExt,
        isG2Ext,
        isExternal,
        isGenericExt,
        isVarianR4Ext,
        isR4ProxyExt,
        props.dataSourceType,
        password,
        username,
        tokenEndpoint,
    ])

    return (
        <>
            {isEpicExt && configJsonObj.systemAuthEnabled ? (
                <FormGroup style={{ marginTop: "16px" }}>
                    <FormControlLabel
                        data-e2e="ea_auth_type_radio_backend"
                        label="Backend Auth"
                        control={(
                            <Radio
                                disabled={!props.canEdit || status !== ':READY:'}
                                checked={authType === 'BACKEND'}
                                color="primary"
                                onChange={() => {
                                    setAuthType('BACKEND')
                                }}
                            />
                        )}
                    />
                    <FormControlLabel
                        data-e2e="ea_auth_type_radio_external"
                        label="External Auth"
                        control={(
                            <Radio
                                disabled={!props.canEdit || status !== ':READY:'}
                                checked={authType === 'EXTERNAL'}
                                color="primary"
                                onChange={() => {
                                    setAuthType('EXTERNAL')
                                }}
                            />
                        )}
                    />
                </FormGroup>
            ) : null}

            {isR4ProxyExt && configJsonObj.systemAuthEnabled ? (
                <FormGroup style={{ marginTop: "16px" }}>
                    <FormControlLabel
                        data-e2e="ea_auth_type_radio_backend"
                        label="Backend Auth"
                        control={(
                            <Radio
                                disabled={!props.canEdit || status !== ':READY:'}
                                checked={authType === 'BACKEND'}
                                color="primary"
                                onChange={() => {
                                    setAuthType('BACKEND')
                                }}
                            />
                        )}
                    />
                </FormGroup>
            ) : null}

            {(isGenericExt || isIKnowMedExt || isAllscriptsUnityExt || isG2Ext || isVarianR4Ext) && configJsonObj.systemAuthEnabled ? (
                <FormGroup style={{ marginTop: "16px" }}>
                    <FormControlLabel
                        data-e2e="ea_auth_type_check_external"
                        label="External auth"
                        control={(
                            <Checkbox
                                disabled={!props.canEdit || status !== ':READY:'}
                                checked={authType === 'EXTERNAL'}
                                color="primary"
                                onClick={() => {
                                    setAuthType((prevState) => prevState !== 'EXTERNAL' ? 'EXTERNAL' : 'OTHER')
                                }}
                            />
                        )}
                    />
                </FormGroup>
            ) : null}

            {((isIKnowMedExt) || (isVarianR4Ext && configJsonObj.serviceEndpoint)) && configJsonObj.systemAuthEnabled && authType === 'EXTERNAL' ? (
                <TextField
                    name="ea-system-username"
                    value={username}
                    disabled={!props.canEdit || status !== ':READY:'}
                    label={isVarianR4Ext ? "Username" : "System Username"}
                    fullWidth
                    style={{ marginTop: "16px" }}
                    onChange={(event) => {
                        setUsername(event.target.value)
                    }}
                />
            ) : null}


            {((isIKnowMedExt) || (isVarianR4Ext && configJsonObj.serviceEndpoint)) && configJsonObj.systemAuthEnabled && authType === 'EXTERNAL' ? (
                <FormControl fullWidth style={{ marginTop: "16px" }}>
                    <InputLabel>
                        {isVarianR4Ext ? "Password" : "System Password"}
                    </InputLabel>
                    <Input
                        name="ea-system-password"
                        value={password}
                        disabled={!props.canEdit || status !== ':READY:'}
                        type={isPasswordVisible ? 'text' : 'password'}
                        onClick={props.canEdit ? (e => {
                            let targetValue = (e.target as any).value;
                            if (targetValue?.localeCompare("<hidden>") === 0) {
                                setPassword("");
                            }
                        }) : null}
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                        endAdornment={
                            <InputAdornment position="end" >
                                <IconButton
                                    disabled={!props.canEdit || status !== ':READY:'}
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        if (clientSecret.localeCompare("<hidden>") === 0) {
                                            setPassword("");
                                        }
                                        setPasswordVisible(!isPasswordVisible)
                                    }}
                                    onMouseDown={(event) => event.preventDefault()}
                                >
                                    {isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            ) : null}

            {isG2Ext && configJsonObj.systemAuthEnabled && authType === 'EXTERNAL' ? (
                <TextField
                    name="ea-client-id"
                    value={clientId}
                    disabled={!props.canEdit || status !== ':READY:'}
                    label={"G2 Webservice Username"}
                    fullWidth
                    style={{ marginTop: "16px" }}
                    onChange={(event) => {
                        setClientId(event.target.value)
                    }}
                />
            ) : null}


            {isG2Ext && configJsonObj.systemAuthEnabled && authType === 'EXTERNAL' ? (
                <FormControl fullWidth style={{ marginTop: "16px" }}>
                    <InputLabel>
                        G2 Webservice Password
                    </InputLabel>
                    <Input
                        name="ea-client-secret"
                        value={clientSecret}
                        disabled={!props.canEdit || status !== ':READY:'}
                        type={isClientSecretVisible ? 'text' : 'password'}
                        onClick={props.canEdit ? (e => {
                            let targetValue = (e.target as any).value;
                            if (targetValue?.localeCompare("<hidden>") === 0) {
                                setClientSecret("");
                            }
                        }) : null}
                        onChange={(event) => {
                            setClientSecret(event.target.value)
                        }}
                        endAdornment={
                            <InputAdornment position="end" >
                                <IconButton
                                    disabled={!props.canEdit || status !== ':READY:'}
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        if (clientSecret.localeCompare("<hidden>") === 0) {
                                            setClientSecret("");
                                        }
                                        setClientSecretVisible(!isClientSecretVisible)
                                    }}
                                    onMouseDown={(event) => event.preventDefault()}
                                >
                                    {isClientSecretVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            ) : null}

            {((isEpicExt || isR4ProxyExt) && configJsonObj.systemAuthEnabled) || ((isGenericExt || isIKnowMedExt || isAllscriptsUnityExt || (isVarianR4Ext && configJsonObj.fhirEndpoint)) && configJsonObj.systemAuthEnabled && authType === 'EXTERNAL') ? (
                <TextField
                    name="ea-client-id"
                    value={clientId}
                    disabled={!props.canEdit || status !== ':READY:'}
                    label={isIKnowMedExt ? "empiUser" : "Client ID"}
                    fullWidth
                    style={{ marginTop: "16px" }}
                    onChange={(event) => {
                        setClientId(event.target.value)
                    }}
                />
            ) : null}

            {isEpicExt && configJsonObj.systemAuthEnabled && authType === 'BACKEND' ? (
                <TextField
                    name="ea-epic-app-name"
                    label="Epic App Name"
                    value={epicAppName}
                    disabled={!props.canEdit || status !== ':READY:'}
                    fullWidth
                    style={{ marginTop: "16px" }}
                    onChange={(event) => {
                        setEpicAppName(event.target.value)
                    }}
                />
            ) : null}

            {isR4ProxyExt && configJsonObj.systemAuthEnabled && authType === 'BACKEND' ? (
                <TextField
                    name="ea-epic-app-name"
                    label="App Name"
                    value={epicAppName}
                    disabled={!props.canEdit || status !== ':READY:'}
                    fullWidth
                    style={{ marginTop: "16px" }}
                    onChange={(event) => {
                        setEpicAppName(event.target.value)
                    }}
                />
            ) : null}

            {isEpicExt && configJsonObj.systemAuthEnabled && authType === 'BACKEND' ? (
                <Button
                    data-e2e="ea_generate_key_pair"
                    disabled={(
                        !props.canEdit
                        || status !== ':READY:'
                        || !clientId
                        || !epicAppName
                    )}
                    variant="contained"
                    color="primary"
                    style={{ display: 'block', marginTop: "16px", marginBottom: "16px" }}
                    onClick={async () => {
                        setStatus(':WAITING:')
                        let res: { data: TExternalAuth }
                        try {
                            const { accountId } = getHostnameInfo()
                            const { env } = getLocationInfo(props.location, props.config)
                            const gatewayId = ''
                            const appId = ''
                            const putDataObj = {
                                accountId,
                                environmentId: env,
                                gatewayId,
                                dataSourceId: props.dataSourceId,
                                appId,
                                clientId,
                                epicAppName
                            }
                            const url = `api/encryption`
                            res = await axiosWrapper(props.config.envApi, url, 'PUT', putDataObj)
                        }
                        catch (reason) {
                            consoleLogger.log('::: Error:', reason)
                        }
                        setEpicPublicKey(res.data.epicPublicKey)
                        setExternalAuthApiId(res.data.externalAuthApiId)
                        setStatus(':READY:')
                    }}
                >
                    Generate public/private key pair
                </Button>
            ) : null}

            {isEpicExt && configJsonObj.systemAuthEnabled && authType === 'BACKEND' ? (
                <Button
                    data-e2e="ea_download_pem"
                    disabled={
                        !props.canEdit
                        || status !== ':READY:'
                        || !epicAppName
                        || !epicPublicKey
                        || !externalAuthApiId
                    }
                    variant="contained"
                    color="primary"
                    style={{ display: 'block', marginTop: "16px", marginBottom: "16px" }}
                    onClick={async () => {
                        setStatus(':WAITING:')
                        try {
                            const url = `api/encryption/${externalAuthApiId}/${epicAppName}`
                            const res: { data?: string } = await axiosWrapper(props.config.envApi, url, 'GET', null, {}, 'stream')
                            if (typeof res.data === 'string') {
                                const element = document.createElement('a')
                                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data))
                                element.setAttribute('download', 'pem')
                                element.style.display = 'none'
                                document.body.appendChild(element)
                                element.click()
                                document.body.removeChild(element)
                            }
                            else {
                                throw new Error('Error: Could not find PEM string! "data" field is missing in the response or it is not of type "string"!')
                            }
                        }
                        catch (reason) {
                            consoleLogger.error(reason)
                        }
                        setStatus(':READY:')
                    }}
                >
                    Download PEM file
                </Button>
            ) : null}

            {isEpicExt && configJsonObj.systemAuthEnabled && authType === 'BACKEND' ? (
                <div>
                    <input id="upload_pem_button" type="file" accept='text/plain' style={{display: "none"}}
                        onChange={async event => {
                            setStatus(':WAITING:')
                            let res: { data: TExternalAuth }
                            let file: File = getPath(event, "target.files.0");
                            if (!file) {
                                return;
                            }

                            let bodyFormData = new FormData();
                            const { accountId } = getHostnameInfo()
                            const { env } = getLocationInfo(props.location, props.config)
                            const gatewayId = ''
                            const appId = ''
                            bodyFormData.append("externalAuthApi", new Blob([JSON.stringify({
                                accountId,
                                epicAppName,
                                authType: "BACKEND",
                                appId,
                                clientId: clientId,
                                dataSourceId: props.dataSourceId,
                                environmentId: env,
                                gatewayId,
                            })], {type: "application/json"}));
                            bodyFormData.append("pemFile", file);

                            const url = `api/encryption/upload`

                            try {
                                res = await axiosWrapper(props.config.envApi, url, 'PUT', bodyFormData)
                            }
                            catch(reason) {
                                res = {
                                    data: {
                                        externalAuthApiId: 'ERROR_externalAuthApiId_' + Date.now(),
                                        epicPublicKey: 'ERROR_epicPublicKey_' + reason.toString(),
                                    },
                                }
                            }

                            setEpicPublicKey(res.data.epicPublicKey)
                            setExternalAuthApiId(res.data.externalAuthApiId)
                            setStatus(':READY:')
                        }}
                    />
                    <label htmlFor="upload_pem_button">
                        <Button
                            data-e2e="ea_upload_pem"
                            disabled={(
                                !props.canEdit
                                || status !== ':READY:'
                                || !clientId
                                || !epicAppName
                            )}
                            variant="contained"
                            color="primary"
                            component="span"
                        >
                            Upload PEM file
                        </Button>
                    </label>
                </div>
            ) : null}

            {isR4ProxyExt && configJsonObj.systemAuthEnabled && authType === 'BACKEND' ? (
                <Button
                    data-e2e="ea_generate_key_pair"
                    disabled={(
                        !props.canEdit
                        || status !== ':READY:'
                        || !clientId
                        || !epicAppName
                    )}
                    variant="contained"
                    color="primary"
                    style={{ display: 'block', marginTop: "16px", marginBottom: "16px" }}
                    onClick={async () => {
                        setStatus(':WAITING:')
                        let res: { data: TExternalAuth }
                        try {
                            const { accountId } = getHostnameInfo()
                            const { env } = getLocationInfo(props.location, props.config)
                            const gatewayId = ''
                            const appId = ''
                            const putDataObj = {
                                accountId,
                                environmentId: env,
                                gatewayId,
                                dataSourceId: props.dataSourceId,
                                appId,
                                clientId,
                            }
                            const url = `api/encryption`
                            res = await axiosWrapper(props.config.envApi, url, 'PUT', putDataObj)
                        }
                        catch (reason) {
                            consoleLogger.log('::: Error:', reason)
                        }
                        setEpicPublicKey(res.data.epicPublicKey)
                        setExternalAuthApiId(res.data.externalAuthApiId)
                        setStatus(':READY:')
                    }}
                >
                    Generate public/private key pair
                </Button>
            ) : null}

            {isR4ProxyExt && configJsonObj.systemAuthEnabled && authType === 'BACKEND' ? (
                <Button
                    data-e2e="ea_download_jwt"
                    disabled={
                        !props.canEdit
                        || status !== ':READY:'
                        || !epicAppName
                        || !epicPublicKey
                        || !externalAuthApiId
                    }
                    variant="contained"
                    color="primary"
                    style={{ display: 'block', marginTop: "16px", marginBottom: "16px" }}
                    onClick={async () => {
                        setStatus(':WAITING:')
                        try {
                            const url = `api/encryption/jwk/${externalAuthApiId}`
                            const res: { data?: string } = await axiosWrapper(props.config.envApi, url, 'GET', null, {}, 'stream')
                            if (typeof res === 'object') {
                                consoleLogger.log(res)
                                const element = document.createElement('a')
                                element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(res.data)))
                                element.setAttribute('download', 'JWK')
                                element.style.display = 'none'
                                document.body.appendChild(element)
                                element.click()
                                document.body.removeChild(element)
                            }
                            else {
                                throw new Error('Error: Could not find PEM string! "data" field is missing in the response or it is not of type "string"!')
                            }
                        }
                        catch (reason) {
                            consoleLogger.error(reason)
                        }
                        setStatus(':READY:')
                    }}
                >
                    Download JWT key pair
                </Button>
            ) : null}

            {((isEpicExt  || isGenericExt || isIKnowMedExt || isAllscriptsUnityExt || (isVarianR4Ext && configJsonObj.fhirEndpoint)) && configJsonObj.systemAuthEnabled && authType === 'EXTERNAL') ? (
                <FormControl fullWidth style={{ marginTop: "16px" }}>
                    <InputLabel>
                        {isIKnowMedExt ? 'empiPassword' : 'Client Secret'}
                    </InputLabel>
                    <Input
                        name="ea-client-secret"
                        value={clientSecret}
                        disabled={!props.canEdit || status !== ':READY:'}
                        type={isClientSecretVisible ? 'text' : 'password'}
                        onClick={props.canEdit ? (e => {
                            let targetValue = (e.target as any).value;
                            if (targetValue?.localeCompare("<hidden>") === 0) {
                                setClientSecret("");
                            }
                        }) : null}
                        onChange={(event) => {
                            setClientSecret(event.target.value)
                        }}
                        endAdornment={
                            <InputAdornment position="end" >
                                <IconButton
                                    disabled={!props.canEdit || status !== ':READY:'}
                                    aria-label="toggle secret visibility"
                                    onClick={() => {
                                        if (clientSecret.localeCompare("<hidden>") === 0) {
                                            setClientSecret("");
                                        }
                                        setClientSecretVisible(!isClientSecretVisible)
                                    }}
                                    onMouseDown={(event) => event.preventDefault()}
                                >
                                    {isClientSecretVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            ) : null}

            {((isEpicExt || isR4ProxyExt) && configJsonObj.systemAuthEnabled) || ((isGenericExt || isAllscriptsUnityExt || (isVarianR4Ext && configJsonObj.fhirEndpoint)) && configJsonObj.systemAuthEnabled && authType === 'EXTERNAL') ? (
                <TextField
                    name="ea-token-endpoint"
                    label="Token Endpoint"
                    value={tokenEndpoint}
                    disabled={!props.canEdit || status !== ':READY:'}
                    fullWidth
                    style={{ marginTop: "16px" }}
                    onChange={(event) => {
                        setTokenEndpoint(event.target.value)
                    }}
                />
            ) : null}

            {isEpicExt && configJsonObj.systemAuthEnabled && authType === 'EXTERNAL' ? (
                <TextField
                    name="ea-client-api-endpoint"
                    label="Client API Endpoint"
                    value={clientApiEndpoint}
                    disabled={!props.canEdit || status !== ':READY:'}
                    fullWidth
                    style={{ marginTop: "16px" }}
                    onChange={(event) => {
                        setClientApiEndpoint(event.target.value)
                    }}
                />
            ) : null}

        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(ExternalAuth))
