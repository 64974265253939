import * as React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../../../../redux/actions'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import LaunchIcon from '@material-ui/icons/Launch'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import VisibilityIcon from '@material-ui/icons/Visibility'
import getAppsCallUrl from '../../../../../../lib/getAppsCallUrl'
import getHostnameInfo from '../../../../../../lib/getHostnameInfo'
import getGatewaysResourceUrl from '../../../../../../lib/getGatewaysResourceUrl'
import { isEnvironmentAdmin } from '../../../../../../lib/user-environment-permissions-helpers'
import getLocationInfo from '../../../../../../lib/getLocationInfo'
import withLegacyTheme from '../../../../../../lib/hoc/with-legacy-theme'
import type {
    TApp,
    TAppActivation,
    TGateway,
} from '../../../../../../types'
import CustomLink from '../../../../../Widgets/CustomLink'
import type { TParentState } from '..'
import './style.less'

type TProps = {
    apps: {
        selected: {
            data?: TApp
        }
    }
    activation: TAppActivation
    config: any
    gateways: {
        all: {
            status: string
            data?: TGateway[]
        }
    }
    location: any
    login: any
    muiTheme: {
        palette: {
            disabledColor: string
            textColor: string
        }
    }
    parentState: TParentState
    appsUpdate: Function
    initialize: Function
    onSetParentState: Function
}

const ActivationItem: React.FC<TProps> = (props: TProps) => {
    const [anchorEl, setAnchorEl] = React.useState<Element>(null)

    const { accountId } = getHostnameInfo()
    const { env } = getLocationInfo(props.location, props.config)

    const isActivationDisabled = !!props.activation.disabled

    const isLaunchProxyRequired =
        props.activation.allscriptsUnityLaunchProxyInfoApi
        || props.activation.externalAuth
        || props.activation.smartLaunchProxyInfo
        || props.activation.smartLaunchProxyInfo2Api

    let ehrAuthType = ''
    if (props.activation.allscriptsUnityLaunchProxyInfoApi) {
        ehrAuthType = 'Allscripts Unity'
    }
    else if (
        props.activation.smartLaunchProxyInfo?.clientSecret
        || props.activation.smartLaunchProxyInfo2Api?.clientSecret
        || (
            props.activation.smartLaunchProxyInfo2Api?.clientAuthenticationMethod === 'ASYMMETRIC' &&
            props.activation.smartLaunchProxyInfo2Api.clientId
        )
    ) {
        ehrAuthType = 'Proxy: Confidential client'
    }
    else if (
        props.activation.smartLaunchProxyInfo?.clientId
        || (props.activation.smartLaunchProxyInfo2Api?.clientId
            && props.activation.smartLaunchProxyInfo2Api?.ehrAuthenticationType !== 'SYSTEM')
    ) {
        ehrAuthType = 'Proxy: Public client'
    }
    else if (props.activation.externalAuth?.authType === 'BACKEND' ||
        props.activation.smartLaunchProxyInfo2Api?.ehrAuthenticationType === 'SYSTEM'
        && props.activation.smartLaunchProxyInfo2Api?.clientId) {
        ehrAuthType = 'System: Backend auth'
    }
    else if (props.activation.externalAuth?.authType === 'EXTERNAL') {
        ehrAuthType = 'System: External auth'
    }
    else if (props.activation.externalAuth?.authType === 'OTHER') {
        ehrAuthType = 'System: Other'
    }

    let smartVersion = ''
    if (props.activation.smartLaunchProxyInfo) {
        smartVersion = 'SMART 1.0'
    }
    else if (props.activation.smartLaunchProxyInfo2Api) {
        smartVersion = 'SMART 2.0'
    }

    const activationGatewayId = props.activation.gatewayId
    const activationGatewayName = (props.gateways.all.data || [])
        .find((gw) => gw.gatewayId === activationGatewayId)
        ?.name
    const activationGatewayUrl = getGatewaysResourceUrl(accountId, env, activationGatewayId, props.config.proxyApi)

    const id = props.activation.smartLaunchProxyInfo?.smartLaunchProxyId
        || props.activation.externalAuth?.externalAuthApiId
        || props.activation.smartLaunchProxyInfo2Api?.smartLaunchProxyId;

    const actAsAdmin = isEnvironmentAdmin(props.login, props.location, props.config)
    const isMenuOpen = props.parentState.selectedActivation === props.activation.name
    const iconStyle = {
        margin: '8px 12px',
        fill: 'rgb(117, 117, 117)',
    }
    const rightIcon = actAsAdmin && props.gateways.all.status === 'ready' ? (
        <>
            <IconButton
                data-e2e={`options_menu_btn_${props.activation.name}`}
                onClick={(event) => {
                    props.onSetParentState({ selectedActivation: props.activation.name })
                    setAnchorEl(event.currentTarget)
                }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                className="right-icon"
                open={isMenuOpen}
                anchorEl={() => anchorEl}
                onClose={() => {
                    setAnchorEl(null)
                    props.onSetParentState({ selectedActivation: undefined })
                }}
            >
                <MenuItem
                    data-e2e={`open_activation_option_${props.activation.name}`}
                    onClick={() => {
                        props.onSetParentState({
                            selectedActivation: props.activation,
                            dialog: ':EDIT_ACTIVATION:',
                        })
                    }}
                >
                    <VisibilityIcon style={iconStyle} />
                    <span style={{ padding: '0 16px' }}>
                        View activation
                    </span>
                </MenuItem>
                {(isLaunchProxyRequired
                    && (props.activation.smartLaunchProxyInfo?.launchUrl || props.activation.smartLaunchProxyInfo2Api?.launchUrl)) ? (
                        <MenuItem onClick={() => {
                            const launchUrl = props.activation.smartLaunchProxyInfo?.launchUrl ?
                                props.activation.smartLaunchProxyInfo.launchUrl : props.activation.smartLaunchProxyInfo2Api.launchUrl;
                            let url
                            if (launchUrl.includes('?')) {
                                url = `${launchUrl}&iss=${activationGatewayUrl}&mode=health`
                            }
                            else {
                                url = `${launchUrl}?iss=${activationGatewayUrl}&mode=health`
                            }
                            window.open(url, "_blank")
                        }}>
                            <AssignmentIcon style={iconStyle} />
                            <span style={{ padding: '0 16px' }}>
                            Health Report
                            </span>
                        </MenuItem>
                    ) : null}
                <MenuItem
                    data-e2e={`${isActivationDisabled ? 'enable' : 'disable'}_activation_option_${props.activation.name}`}
                    onClick={async () => {
                        props.onSetParentState({ status: 'wait' })

                        const selectedActivation = props.activation
                        const activations = props.parentState.activations
                            .filter(act => act.appId === selectedActivation.appId)
                        const updatedActivations = activations
                            .map((act) => {
                                if (act.name === selectedActivation.name) {
                                    return ({
                                        ...act,
                                        disabled: !act.disabled
                                    })
                                }
                                return act
                            })
                        const data = props.apps.selected?.data || {}
                        const updatedData = {
                            ...data,
                            activations: updatedActivations,
                        }

                        const appUrl = getAppsCallUrl(accountId, env) + `/${props.apps.selected.data?.appId}`
                        await props.appsUpdate(props.config, appUrl, updatedData)
                        props.initialize()
                    }}
                >
                    {isActivationDisabled
                        ? <CheckCircleIcon style={iconStyle} />
                        : <RemoveCircleIcon style={iconStyle} />
                    }
                    <span style={{ padding: '0 16px' }}>
                        {isActivationDisabled ? 'Enable activation' : 'Disable activation'}
                    </span>
                </MenuItem>
                <MenuItem
                    data-e2e={`delete_activation_option_${props.activation.name}`}
                    onClick={() => {
                        props.onSetParentState({
                            selectedActivation: props.activation,
                            dialog: ":DELETE_ACTIVATION_CONFIRM:",
                        })
                    }}
                >
                    <DeleteIcon style={iconStyle} />
                    <span style={{ padding: '0 16px' }}>
                        Delete activation
                    </span>
                </MenuItem>
            </Menu>
        </>
    ) : null

    return (
        <ListItem
            disabled
            data-e2e={`act_${props.activation.name}`}
            className="activation-ul-item"
            style={{ opacity: props.parentState.status !== 'ready' ? 0.33 : 1 }}
        >
            <div className="content">
                <div
                    className="col-1"
                    onClick={() => {
                        if (props.gateways.all.status === 'ready') {
                            props.onSetParentState({
                                selectedActivation: props.activation,
                                dialog: ':EDIT_ACTIVATION:',
                            })
                        }
                    }}
                >
                    <span
                        data-e2e={`act_display_name_${props.activation.name}`}
                        style={{
                            color: isActivationDisabled ? props.muiTheme.palette.disabledColor : props.muiTheme.palette.textColor,
                            display: 'block',
                        }}
                    >
                        {props.activation.name}
                    </span>
                    {ehrAuthType ? (
                        <Chip
                            data-e2e={`act_ehr_auth_type_chip_${props.activation.name}`}
                            label={ehrAuthType}
                            classes={{
                                root: `ehr-auth-type-chip${isActivationDisabled ? ' disabled' : ''}`,
                                label: 'chip-label',
                            }}
                        />
                    ) : null}
                    {smartVersion ? (
                        <Chip
                            data-e2e={`act_ehr_auth_model_chip_${props.activation.name}`}
                            label={smartVersion}
                            classes={{
                                root: `smart-version-chip${isActivationDisabled ? ' disabled' : ''}`,
                                label: 'chip-label',
                            }}
                        />
                    ) : null}
                    {ehrAuthType || smartVersion ? <br /> : null}
                    {isActivationDisabled ? (
                        <Chip
                            data-e2e={`act_disabled_chip_${props.activation.name}`}
                            label="Disabled activation"
                            classes={{
                                root: 'disable-chip',
                                label: 'chip-label',
                            }}
                        />
                    ) : null}
                    {id ? (
                        <div>
                            <small
                                data-e2e={`act_display_id_${props.activation.name}`}
                                style={{ color: props.muiTheme.palette.disabledColor }}
                            >
                                {id}
                            </small>
                        </div>
                    ) : null}
                </div>

                <div
                    className="col-2"
                    onClick={() => {
                        if (props.gateways.all.status === 'ready') {
                            props.onSetParentState({
                                selectedActivation: props.activation,
                                dialog: ':EDIT_ACTIVATION:',
                            })
                        }
                    }}
                >
                    <span style={{
                        color: isActivationDisabled ? props.muiTheme.palette.disabledColor : props.muiTheme.palette.textColor,
                        display: 'block',
                    }}>
                        <CustomLink to={`/${env}/gateways/${activationGatewayId}`}>
                            <span data-e2e={`act_gw_display_name_${activationGatewayName}`}>
                                {activationGatewayName}
                            </span>
                        </CustomLink>
                    </span>
                    <div style={{ marginTop: '4px' }}>
                        <small
                            data-e2e={`act_gw_url_${activationGatewayId}`}
                            style={{ color: props.muiTheme.palette.disabledColor }}
                        >
                            {activationGatewayUrl}
                        </small>
                    </div>
                </div>

                <div className="col-3">
                    {!isLaunchProxyRequired ? (
                        <Button
                            data-e2e={`act_launch_button_${props.activation.name}`}
                            variant="contained"
                            disabled={isActivationDisabled}
                            onClick={() => {
                                const launchUrl = props.apps.selected.data?.launchUrl || ''
                                let url
                                if (launchUrl.includes('?')) {
                                    url = `${launchUrl}&iss=${activationGatewayUrl}`
                                }
                                else {
                                    url = `${launchUrl}?iss=${activationGatewayUrl}`
                                }
                                window.open(url, '_blank')
                            }}
                        >
                            Launch
                            <LaunchIcon />
                        </Button>
                    ) : null}
                </div>

                <div className="col-4">
                    {rightIcon}
                </div>
            </div>
        </ListItem>
    )
}

const mapStateToProps = (state, ownProps) => ({
    apps: state.apps,
    config: state.config,
    login: state.login,
    gateways: state.gateways,
    ...ownProps,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appsUpdate: actions.appsUpdate,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(ActivationItem)))
