import * as React from "react";
import * as PropTypes from "prop-types";
import {getPath} from "../../../../../../lib/utils/";
import CenteredCircularProgress from "../../../../../Widgets/CenteredCircularProgress/";

const Component: React.FunctionComponent<any> = props => {
    const appName = getPath(props, "state.delete.name") || "";

    return <div>
        {props.state.status !== "ready"
            ? <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
            : <div>
                Are you sure you want to remove registration <strong data-qa="app-to-delete-name">{appName}</strong> ?
                <br/>
                Registration of the app will be removed. To use it again, you will need to re-register your app.
            </div>}
    </div>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired
};

export default Component;
