import consoleLogger from "../../lib/consoleLogger";

declare const _;

import $ from "jquery";
import axiosWrapper from "../../lib/axiosWrapper";
import getGatewaysCallUrl from "../../lib/getGatewaysCallUrl";
import { getPath } from "../../lib/utils";
import initialState from "../../redux/reducers/gateways/initial-state";

import * as types from "./types";

export function gatewaysDelete(
    config: any,
    url: string,
) {
    return function (dispatch) {
        dispatch({
            type: types.GATEWAYS_SELECTED_SET,
            payload: {
                status: "pending",
                data: $.extend(true, {}, initialState.selected.data),
            },
        });
        return axiosWrapper(config.envApi, url, "delete")
            .then((res) => {
                dispatch({
                    type: types.GATEWAYS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: $.extend(true, {}, initialState.selected.data),
                    },
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: types.GATEWAYS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, {}, initialState.selected.data),
                    },
                });
                consoleLogger.error(error);
            });
    };
}

export function gatewaysRead(
    config: any,
    url: string,
) {
    return function (dispatch) {
        dispatch({
            type: types.GATEWAYS_SELECTED_SET,
            payload: {
                status: "pending",
                data: $.extend(true, {}, initialState.selected.data),
            },
        });
        return axiosWrapper(config.envApi, url, "get")
            .then((res) => {
                dispatch({
                    type: types.GATEWAYS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: types.GATEWAYS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, {}, initialState.selected.data),
                    },
                });
                consoleLogger.error(error);
            });
    };
}

export function gatewaysReadAll(
    config: any,
    url: string,
) {
    const ts = Date.now()

    return function (dispatch) {
        dispatch({
            type: types.GATEWAYS_ALL_SET,
            payload: {
                status: 'pending',
                data: initialState.all.data,
                requestedAt: ts,
            },
        })
        return axiosWrapper(config.envApi, url, 'GET')
            .then((res) => {
                dispatch({
                    type: types.GATEWAYS_ALL_SET,
                    payload: {
                        status: 'ready',
                        data: res.data,
                        requestedAt: ts,
                    },
                })
                return res
            })
            .catch((reason) => {
                dispatch({
                    type: types.GATEWAYS_ALL_SET,
                    payload: {
                        status: 'error',
                        data: initialState.all.data,
                        requestedAt: ts,
                    },
                })
                consoleLogger.error(reason)
            })
    }
}

export function gatewaysUpdate(
    config: any,
    url: string,
    data = {},
) {
    return function (dispatch) {
        dispatch({
            type: types.GATEWAYS_SELECTED_SET,
            payload: {
                status: "pending",
                data: $.extend(true, {}, initialState.selected.data),
            },
        });
        return axiosWrapper(config.envApi, url, "put", data)
            .then((res) => {
                dispatch({
                    type: types.GATEWAYS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: types.GATEWAYS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, {}, initialState.selected.data),
                    },
                });
                consoleLogger.error(error);
            });
    };
}

// -----------------------------------------------------------------------------
const _gatewaysUpdatePortal = _.throttle(
    function (data: any, { bypassError = false }) {
        return _.throttle(
            async function (dispatch: Function, getState: Function): Promise<any> {
                const state = getState();

                const selectedData = getPath(state, "gateways.selected.data") || {};
                dispatch({
                    type: types.GATEWAYS_SELECTED_SET,
                    payload: {
                        status: "pending",
                        data: selectedData,
                    },
                });

                const accountId: string = selectedData.accountId;
                const environmentId: string = selectedData.environmentId;
                const gatewayId: string = selectedData.gatewayId;

                const baseUrl: string = state.config.envApi;
                const path: string = getGatewaysCallUrl(accountId, environmentId) + "/" + gatewayId;

                try {
                    return await axiosWrapper(baseUrl, path, "PUT", data);
                } catch (reason) {
                    consoleLogger.error("::: reason:", reason);
                    if (bypassError) {
                        throw reason;
                    }
                }
            },
            550, { "trailing": false },
        );
    },
    550, { "trailing": false },
);

export function gatewaysUpdatePortal(data = {}, cfg = {}) {
    return _gatewaysUpdatePortal(data, cfg);
}
