import axiosWrapper from "./axiosWrapper";
import checkEmail from "./checkEmail";
import * as validateId from "./validate-id";
import { getPath } from "./utils/";

function _onSelectAccount(
    state: any,
    config: any,
) {
    const accountId = getPath(state, "data.accountId") || "";
    if (accountId) {
        let accountIdMessage = "";
        accountIdMessage = validateId.isUrlFriendly(accountId) ? accountIdMessage : "Invalid ID";
        accountIdMessage = validateId.isNotTooLong(accountId) ? accountIdMessage : "This ID is too long";
        accountIdMessage = validateId.isNotTooShort(state.data.accountId) ? accountIdMessage : "This ID is too short";

        if (accountIdMessage) {
            return Promise.resolve({
                accountId: accountIdMessage,
            });
        }
        else {
            return new Promise((resolve) => {
                axiosWrapper(
                    config.accountApi,
                    `accounts/public?path=${accountId}`,
                    "GET",
                )
                    .then(() => {
                        resolve({
                            accountId: "",
                        });
                    })
                    .catch(() => {
                        resolve({
                            accountId: "We couldn’t find your account",
                        });
                    });
            });
        }

    }
    else {
        return {
            accountId: "",
        };
    }
};

let selectAccountTimer;
export function onSelectAccountAsync(
    state: any,
    config: any,
) {
    clearTimeout(selectAccountTimer);
    return new Promise((resolve) => {
        selectAccountTimer = setTimeout(() => resolve(_onSelectAccount(state, config)), 550);
    });
}

export function onEnterCredentials(state: any) {
    const email = getPath(state, "data.email") || "";
    let emailMessage = "";
    if (email) {
        emailMessage = checkEmail(email);
    }

    return {
        email: emailMessage,
    };
}
