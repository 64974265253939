/* eslint-disable react/prop-types */
import {getHourAndMinuteDisplay, getMonthAndDayDisplay, getMonthDisplay, getWeekDayDisplay, getNumberLocaleString} from "../../../../../../../lib/utils";
import * as React from "react";
import * as PropTypes from "prop-types";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import "./style.less";

const Recharts = require("recharts");
const {CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line} = Recharts;

const Component: React.FunctionComponent<any> = props => {
    // const [selectedActivation, setSelectedActivation] = React.useState('')
    const [selectedMetric, setSelectedMetric] = React.useState('avg')
    const [selectedEvent, setSelectedEvent] = React.useState('SMART_launch')

    let data = props.data;
    // if (props.selector && selectedActivation) {
    //     data.series = [selectedActivation]
    // }
    let endTime = props.endTime;
    if (props.state.timeframe === 0 || props.state.timeframe === 86400000) {
        endTime = props.state.startTime + 82800000;
    }

    let eventName = "SMART Launch";
    if (selectedEvent === "app_ready") {
        eventName = "App Ready"
    } else if (selectedEvent === "app_launch_comp") {
        eventName = "App Launch Complete"
    }

    if (!props.showLaunchesOverTime) {
        return null;
    }

    if (props.data === null) {
        return <div>
            <div style={{padding: "20px 20px 10px", color: "gray"}}>{eventName + " " +  props.title}</div>
            <div className="chart-card-text loading" style={props.styles.cardChartText}>
                <CircularProgress/>
            </div>
        </div>;
    }

    // if (props.selector && !selectedActivation) {
    //     return <div style={{ height: "200px" }}>
    //         <div style={{padding: "20px 20px 10px", color: "gray", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
    //             <div>{props.title}</div>
    //         </div>
    //         <div style={{ height: "130px", display: "flex", justifyContent: "center", alignItems: "center"}}>
    //             <FormControl variant="outlined" style={{ minWidth: "220px" }}>
    //                 <InputLabel htmlFor="select-activation">Select an activation</InputLabel>
    //                 <Select
    //                     value={selectedActivation}
    //                     onChange={(e: any) => setSelectedActivation(e.target.value)}
    //                     inputProps={{
    //                         name: 'activation',
    //                         id: 'select-activation',
    //                     }}
    //                 >
    //                     {props.state.allActivations.map(activation => {
    //                         return (<MenuItem key={activation.name} value={activation.name}>{activation.name}</MenuItem>)
    //                     })}
    //                 </Select>
    //             </FormControl>
    //         </div>
    //     </div>
    // }

    return <div>
        <div style={{padding: "20px 20px 10px", color: "gray", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div>{eventName + " " +  props.title}</div>
            {props.selector ? (<div>
                <FormControl style={{ minWidth: "220px", marginRight: "10px"}}>
                    <InputLabel htmlFor="select-event">Select an event time</InputLabel>
                    <Select
                        value={selectedEvent}
                        onChange={(e: any) => setSelectedEvent(e.target.value)}
                        inputProps={{
                            name: 'event',
                            id: 'select-event',
                        }}
                    >
                        <MenuItem key="SMART_launch" value={"SMART_launch"}>SMART Launch</MenuItem>
                        <MenuItem key="app_ready" value={"app_ready"}>App Ready</MenuItem>
                        <MenuItem key="app_launch_comp" value={"app_launch_comp"}>App Launch Complete</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ minWidth: "220px" }}>
                    <InputLabel htmlFor="select-activation">Select a metric</InputLabel>
                    <Select
                        value={selectedMetric}
                        onChange={(e: any) => setSelectedMetric(e.target.value)}
                        inputProps={{
                            name: 'activation',
                            id: 'select-activation',
                        }}
                    >
                        <MenuItem key="avg-value" value={"avg"}>Average</MenuItem>
                        <MenuItem key="median-value" value={"med"}>Median</MenuItem>
                    </Select>
                </FormControl>
            </div>) : null}
        </div>
        <ResponsiveContainer width="99%" height="90%" minHeight={340}>
            <LineChart data={props.data.entries} width={904} height={300} margin={props.styles.chartMargins}>
                <XAxis dataKey="name" scale="time" type="number" domain={[props.state.startTime, endTime]}
                    tickFormatter={unixTime => {
                        if (props.state.timeframe === 604800000) {
                            return getWeekDayDisplay(unixTime, props.ui.timeZone);
                        }
                        if (props.state.timeframe > 10713600000) {
                            return getMonthDisplay(unixTime, props.ui.timeZone);
                        }
                        return props.state.timeframe > 86400000
                            ? getMonthAndDayDisplay(unixTime, props.ui.timeZone)
                            : getHourAndMinuteDisplay(unixTime, props.ui.timeZone);
                    }}/>
                <YAxis allowDecimals={false}
                    tickFormatter={value => {
                        return `${getNumberLocaleString(value)}`
                    }}/>
                <CartesianGrid strokeDasharray="3 3"/>
                <Tooltip labelStyle={{fontSize: "14px"}} itemStyle={{fontSize: "14px"}}
                    formatter={(value, name, entry) => {
                        if (name.indexOf(`_${selectedEvent}`) > -1) {
                            return [`${getNumberLocaleString(value)} / ${entry.payload[`${name.substring(0, name.indexOf(`_${selectedEvent}`))}_numberOfLaunches`]}`, name.substring(0, name.indexOf(`_${selectedEvent}`))]
                        }
                        return `${getNumberLocaleString(value)} / ${entry.payload[`${name}_numberOfLaunches`]}`
                    }}
                    labelFormatter={value => {
                        return props.state.timeframe > 86400000
                            ? <div><span>{getMonthAndDayDisplay(value, props.ui.timeZone)}</span><div style={{ marginLeft: "5px", display: "inline", color: "gray" }}>[activation: time(ms.) / launches(no.)]</div></div>
                            : <div><span>{getHourAndMinuteDisplay(value, props.ui.timeZone)}</span><div style={{ marginLeft: "5px", display: "inline", color: "gray" }}>[activation: time(ms.) / launches(no.)]</div></div>;
                    }}/>
                {props.legend && <Legend
                    formatter={(value) =>{
                        if (value.indexOf(`_${selectedEvent}`) > -1) {
                            return value.substring(0, value.indexOf(`_${selectedEvent}`))
                        }
                        return value
                    }}
                />}
                {data.series.map(item => <Line key={item} dataKey={item + `_${selectedEvent}` + `_${selectedMetric}`} type="monotone" stroke={props.state.assignedColors[item]}/>)}
            </LineChart>
        </ResponsiveContainer>
    </div>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired,
    data: PropTypes.object,
    styles: PropTypes.object.isRequired,
    launchesOverTime_refresh: PropTypes.func.isRequired,
    legend: PropTypes.bool,
    title: PropTypes.string,
    selector: PropTypes.bool
};

Component.defaultProps = {
    legend: true,
    title: "App Launches",
    selector: false
};

export default Component;
