/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import {getPath} from "../../../../../../../lib/utils/";
import {Box, Card, CardContent, Checkbox, CircularProgress, List, Typography} from "@material-ui/core";
import Header from "../../../../../../Widgets/Editor/Header/";
import HeaderContentL1 from "./HeaderContentL1/";
import HeaderContentL2 from "./HeaderContentL2/";
import ResourceItem from "./ResourceItem/";

const Component: React.FunctionComponent<any> = props => {
    const resources = getPath(props, "state.resources") || [];
    const selectedResourcesCount = Object.keys(props.state.selectedResources).reduce((acc, key) => {
        if (props.state.selectedResources[key]) return ++acc; else return acc;
    }, 0);
    const selectedResourcesAllChecked = selectedResourcesCount === resources.length;

    return <Card style={{marginTop: "48px"}}>
        <div style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            height: "100%",
            backgroundColor: props.state.status === "recalc" ? "rgba(255, 255, 255, 0.75)" : "",
            visibility: props.state.status === "recalc" ? "" : "hidden",
            zIndex: 2,
            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
        } as any}/>

        <Header {...props} leftIcon={props.state.status === "recalc"
            ? <div style={{display: "flex"}}>
                <Box
                    key="data_mapping_loading"
                    position="relative"
                    display="inline-flex"
                >
                    <CircularProgress size={24} style={{left: 0}}/>
                </Box>
                <Typography
                    key="loading_msg"
                    variant="caption"
                    component="div"
                    color="textSecondary"
                    style={{marginLeft: 5, lineHeight: "24px"}}
                >
                    Loading...
                </Typography>
            </div>
            : <Checkbox checked={selectedResourcesAllChecked} style={{left: 0, opacity: !selectedResourcesCount ? 0 : 1}} disabled={!selectedResourcesCount}
                onClick={() => {
                    const selectedResources = {};
                    resources.forEach((resource): void => {
                        selectedResources[resource.type] = !selectedResourcesAllChecked;
                    });
                    props.onSetState({selectedResources});
                }}/>}>
            <HeaderContentL1 {...props} selectedResourcesCount={selectedResourcesCount}/>
        </Header>

        <Header {...props} style={{height: "32px", padding: "8px 16px", borderTop: "none"}}>
            <HeaderContentL2 {...props} />
        </Header>

        <CardContent style={{paddingTop: 0}}>
            <List>
                {resources.map((resource, index) => <ResourceItem key={`${resource.type}-${index}`}{...props} resource={resource}/>)}
            </List>
        </CardContent>
    </Card>;
}

Component.defaultProps = {
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
