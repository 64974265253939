/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import {ListItem, TextField, Button, Menu, IconButton, MenuItem} from "@material-ui/core";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import {Search, MoreVert, Unarchive, ExpandMore, ExpandLess} from "@material-ui/icons";
import Cell from "./Cell";
import Filter from "./Filters/Filter";
import "./style.less";
import {Refresh} from "../index";

const Header = props => {
    const style = {background: props.muiTheme.palette.accent2Color};
    const {search, buttons, total, leftFilters, rightFilters} = props;
    const leftIcon = search ? <Search/> : undefined;
    const hasTopContent = !!search || !!buttons || !!total || !!leftFilters || !!rightFilters;
    const exportFunc = !!props.export || !!props.expandAll
        ? <React.Fragment>
            <IconButton onClick={props.toggleMenu}><MoreVert/></IconButton>
            <Menu className="right-icon" open={props.showMenu} anchorEl={props.menuAnchor} onClose={props.toggleMenu}>
                {!!props.export && props.export.map((e, i) => <MenuItem key={`right-menu-export-${e.label}-${i}`} onClick={() => e.func()}>
                    <Unarchive style={{opacity: .5, padding: "5px 24px 5px 5px"}}/> {e.label}
                </MenuItem>)}
                {!!props.expandAll && !props.allExpanded && <MenuItem key="right-menu-expand-all" onClick={() => props.expandAll()}>
                    <ExpandMore style={{opacity: .5, padding: "5px 24px 5px 5px"}}/> Expand all
                </MenuItem>}
                {!!props.expandAll && props.allExpanded && <MenuItem key="right-menu-expand-all" onClick={() => props.expandAll()}>
                    <ExpandLess style={{opacity: .5, padding: "5px 24px 5px 5px"}}/> Collapse all
                </MenuItem>}
            </Menu>
        </React.Fragment>
        : null;

    return <div>
        {hasTopContent && <ListItem className="table-header" style={{...style, ...props.style}}>
            {leftIcon}{props.primaryText}
            <div className="table-header-first-line">
                <div className="content-left-side">
                    {(leftFilters || []).map((f, i) => f.type !== "component" ? <Filter key={i} {...f} /> : f.value)}
                    {!!search && <TextField className="table-header-search" value={search.value} placeholder={search.hint} onChange={search.onChange}/>}
                    {!!total && total}
                </div>
                <div className="content-right-side">
                    {!!props.refresh && <Refresh refresh={props.refresh} />}
                    {(rightFilters || []).map((f, i) => f.type !== "component" ? <Filter key={i} {...f} /> : f.value)}
                    {!!exportFunc && exportFunc}
                    {(buttons || []).map((b, i) => <Button key={i} className="header-button" data-qa={b["data-qa"]} variant="contained" color={b.primary ? "primary" : "secondary"}
                        onClick={b.onClick}>
                        {b.label}
                    </Button>)}
                </div>
            </div>
            {props.rightIcon}
        </ListItem>}
        <ListItem className="table-header-two" style={{...style, ...props.style}}>
            {(props.columns || []).map((c, i) => <Cell key={i} {...c} />)}
        </ListItem>
    </div>;
}

Header.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    search: PropTypes.object,
    buttons: PropTypes.array,
    columns: PropTypes.array,
    total: PropTypes.any,
    expandAll: PropTypes.func,
    rightFilters: PropTypes.array,
    leftFilters: PropTypes.array,
    export: PropTypes.array,
    allExpanded: PropTypes.bool
};

export default withLegacyTheme()(Header);
