const STATUS_CODES = {
    inviteExpired:-3,
    deleted:      -2,
    deactivated:  -1,
    invited:       0,
    active:        1,
    resetPassword: 2,
    changeEmail:   3,
};

export function checkUserStatus(
    user: any,
    status: string = "",
): boolean {
    let userStatus = null;
    if (user.status || user.status === 0) {
        userStatus = user.status;
    }
    else if (user.data && (user.data.status || user.data.status === 0)) {
        userStatus = user.data.status;
    }
    switch (status) {
        case ":INVITE_EXPIRED:": return userStatus === STATUS_CODES.inviteExpired;
        case ":DELETED:":        return userStatus === STATUS_CODES.deleted;
        case ":DEACTIVATED:":    return userStatus === STATUS_CODES.deactivated;
        case ":INVITED:":        return userStatus === STATUS_CODES.invited;
        case ":ACTIVE:":         return userStatus === STATUS_CODES.active;
        case ":RESET_PASSWORD:": return userStatus === STATUS_CODES.resetPassword;
        case ":CHANGE_EMAIL:":   return userStatus === STATUS_CODES.changeEmail;
        default:                 return false;
    }
}
