import checkEmail from "./checkEmail";
import checkPassword from "./checkPassword";
import { getPath } from "./utils/";

export function onEditBasicInfo(state: any) {
    let first_name = "Required field";
    let last_name = "Required field";

    if (state.data.first_name.length === 0 && state.data.last_name.length === 0) {
        // first_name = "";
        // last_name = "";
    } else {
        if (state.data.first_name) {
            first_name = "";
        }
        if (state.data.first_name.length > 60) {
            first_name = "Names cannot be longer than a 60 characters"
        }

        if (state.data.last_name) {
            last_name = "";
        }
        if (state.data.last_name.length > 60) {
            last_name = "Names cannot be longer than a 60 characters"
        }
    }

    return {
        first_name,
        last_name,
    };
}

export function onEditPassword(state: any) {
    let oldPassword = state.data.newPassword && !state.data.oldPassword ? "Enter your old password" : "";
    oldPassword = state.data.oldPassword ? checkPassword(state.data.oldPassword) : oldPassword;

    let newPassword = state.data.oldPassword && !state.data.newPassword ? "Enter your new password" : "";
    newPassword = state.data.newPassword ? checkPassword(state.data.newPassword) : newPassword;

    return {
        oldPassword,
        newPassword,
    };
}

export function onEditEmail(state: any) {
    const email = state.data.email;
    let emailMessage = "";
    if (email) {
        emailMessage = checkEmail(email) ? "Invalid email address" : "";
    }

    return {
        email: emailMessage,
    }
}

export function onEnterEmail(state: any) {
    const email = getPath(state, "data.email") || "";
    let emailMessage = "";
    if (email) {
        emailMessage = checkEmail(email);
    }

    return {
        email: emailMessage,
    };
}

export function onResetPasswordConfirm(state: any) {
    const password = state.data.password ? checkPassword(state.data.password) : "";

    return {
        password,
    };
}

export function onEditInviteUser(state: any) {
    const invitationEmail = state.data.invitationEmail;
    let invitationEmailMessage = "";
    if (invitationEmail) {
        invitationEmailMessage = checkEmail(invitationEmail) ? "Invalid email address" : "";
    }

    return {
        invitationEmail: invitationEmailMessage,
    }
}

export function onInvitationConfirm(state: any) {
    let firstName = "Required field";
    let lastName = "Required field";
    let password = "";

    if (state.data.firstName.length === 0 && state.data.lastName.length === 0 && state.data.password.length === 0) {
        firstName = "";
        lastName = "";
        password = "";
    } else {
        if (state.data.firstName.length > 60) {
            firstName = "Names cannot be longer than a 60 characters"
        }
        if (state.data.firstName) {
            firstName = "";
        }

        if (state.data.lastName.length > 60) {
            lastName = "Names cannot be longer than a 60 characters"
        }
        if (state.data.lastName) {
            lastName = "";
        }

        if (!state.data.password && (state.data.firstName || state.data.lastName)) {
            password = "Required field";
        }
        else {
            password = checkPassword(state.data.password);
        }
    }

    return {
        firstName,
        lastName,
        password,
    };
}
