import * as React from "react";
import * as PropTypes from "prop-types";
import * as validateData from "../../../../../../../lib/validate-apps-data";
import {TextField} from "@material-ui/core";
import EhrListingUrls from "../../../../EhrListingUrls/";
import AppRedirectUrls from "../../../../AppRedirectUrls/";
import type { TProps as TEhrListingUrls } from '../../../../EhrListingUrls'
export default class extends React.Component<any, any> {
    public static propTypes = {
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.props.onSetState(
            {
                isNextWStepEnabled: false,
                dataValidation: {}
            },
            this.validateData
        );
    }

    public componentDidUpdate(prevProps) {
        const data_string = JSON.stringify(this.props.state.data);
        const prevData_string = JSON.stringify(prevProps.state.data);
        if (data_string !== prevData_string) {
            this.validateData();
        }
    }

    public render() {
        return <div style={{padding: "0 24px"}}>
            <TextField name="launchUrl" value={this.props.state.data.launchUrl || ""} placeholder="Type the app launch URI" label="App Launch URI"
                error={!!this.props.state.dataValidation.launchUrl} helperText={this.props.state.dataValidation.launchUrl} fullWidth
                onChange={e => this.props.onSetState({
                    isNextWStepEnabled: false,
                    data: {
                        ...this.props.state.data,
                        launchUrl: e.target.value
                    }
                })}/>

            <TextField name="healthUrl" value={this.props.state.data.healthUrl || ""} placeholder="Type the app health URI" label="App Health URI"
                helperText={this.props.state.dataValidation.healthUrl} error={!!this.props.state.dataValidation.healthUrl} fullWidth style={{marginTop: "16px"}}
                onChange={e => this.props.onSetState({
                    isNextWStepEnabled: false,
                    data: {
                        ...this.props.state.data,
                        healthUrl: e.target.value
                    }
                })}/>

            <AppRedirectUrls {...this.props} canEdit/>
            <EhrListingUrls {...({ ...this.props, canEdit: true } as TEhrListingUrls)} />
        </div>;
    }

    private validateData = () => {
        const dataValidation = validateData.onWStep4(this.props.state);
        this.props.onSetState(
            {
                dataValidation: {
                    ...this.props.state.dataValidation,
                    ...dataValidation
                }
            },
            () => {
                let isValid = Object.keys(this.props.state.dataValidation).reduce((acc, key) => acc && !this.props.state.dataValidation[key], true);
                isValid = isValid && !this.props.state.data.newRedirectUrl;
                if (isValid !== this.props.state.isNextWStepEnabled) {
                    this.props.onSetState({isNextWStepEnabled: isValid});
                }
            }
        );
    }
}