import * as React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import CenteredCircularProgress from "../../../../../../../../Widgets/CenteredCircularProgress/";

interface IProps {
    parentStatus: string;
    selectedSessions: any[];
}

const CustomDialogContent: React.FC<IProps> = (props: IProps) => {
    const isDeleteMultipleMode = props.selectedSessions.length > 1;

    return (
        <DialogContent style={{ minWidth: 552, padding: 24, background: "rgb(250, 250, 250)" }}>
            {props.parentStatus === "ready"
                ? (
                    <>
                        {isDeleteMultipleMode ? (
                            <Typography gutterBottom>
                                Are you sure you want to delete the following sessions:
                                <ul>
                                    {props.selectedSessions.map(({ sessionId }) => (
                                        <li key={sessionId}><strong>{sessionId}</strong></li>
                                    ))}
                                </ul>
                            </Typography>
                        ) : (
                            <Typography gutterBottom>
                                Are you sure you want to remove session&nbsp;
                                <strong>{props.selectedSessions[0].sessionId}</strong>?
                            </Typography>
                        )}
                        <Typography color="secondary" gutterBottom>
                            This action <strong>CANNOT</strong> be undone!
                        </Typography>
                    </>
                ) : <CenteredCircularProgress size={63} />}
        </DialogContent>
    );
}

export default CustomDialogContent;
