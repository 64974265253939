import type { State } from 'xstate'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import type { TContext } from '..'

type TProps = {
    state: State<TContext, any, any, any, any>
    getQueueName: () => string
    send: Function
}

const WStep3 = (props: TProps) => {
    const queueName = props.getQueueName()

    return (
        <div style={{ padding: '0 24px' }}>
            <TextField
                name="messageTypes"
                value={props.state.context.data.hl7Properties?.messageTypes}
                placeholder="Message types"
                label="Message Types"
                fullWidth
                onChange={(event) => {
                    props.send('SET_HL7_MESSAGE_TYPES', { messageTypes: event.target.value })
                }}
            />

            <TextField
                name="queueName"
                value={queueName}
                disabled
                placeholder="Queue name"
                label="Queue Name"
                fullWidth
                classes={{ root: 'disabled-text-field' }}
                style={{ marginTop: '18px' }}
            />

            <TextField
                name="url"
                value={props.state.context.data.hl7Properties?.url}
                placeholder="URL"
                error={!!props.state.context.dataValidation.hl7Url}
                label="URL"
                helperText={props.state.context.dataValidation.hl7Url}
                fullWidth
                style={{ marginTop: '18px' }}
                onChange={(event) => {
                    props.send('SET_HL7_URL', { url: event.target.value })
                }}
            />

            <FormControl
                fullWidth
                style={{ marginTop: '18px' }}
            >
                <InputLabel>
                    Authentication Type
                </InputLabel>
                <Select
                    value={props.state.context.data.hl7Properties?.urlAuthType}
                    autoWidth
                    MenuProps={{ style: { zIndex: 10000 } }}
                    onChange={(event) => {
                        props.send('SET_HL7_URL_AUTH_TYPE', { urlAuthType: event.target.value })
                    }}
                >
                    <MenuItem value="NONE">
                        NONE
                    </MenuItem>
                    <MenuItem value="CLIENT_CREDENTIALS">
                        CLIENT CREDENTIALS
                    </MenuItem>
                </Select>
            </FormControl>

            {props.state.context.data.hl7Properties?.urlAuthType === 'CLIENT_CREDENTIALS' ? (
                <>
                    <TextField
                        value={props.state.context.data.hl7Properties?.clientId}
                        placeholder="Client ID"
                        label="Client ID"
                        fullWidth
                        style={{ marginTop: '18px' }}
                        onChange={(event) => {
                            props.send('SET_HL7_CLIENT_ID', { clientId: event.target.value })
                        }}
                    />

                    <FormControl
                        style={{
                            display: 'block',
                            marginTop: '24px',
                        }}
                    >
                        <InputLabel>
                            Client Secret
                        </InputLabel>
                        <Input
                            value={props.state.context.data.hl7Properties?.clientSecret}
                            placeholder="Client secret"
                            type="password"
                            fullWidth
                            onChange={(event) => {
                                props.send('SET_HL7_CLIENT_SECRET', { clientSecret: event.target.value })
                            }}
                        />
                    </FormControl>

                    <TextField
                        value={props.state.context.data.hl7Properties?.clientAuthUrl}
                        placeholder="Client auth URL"
                        label="Client Auth URL"
                        fullWidth
                        style={{ marginTop: '18px' }}
                        onChange={(event) => {
                            props.send('SET_HL7_CLIENT_AUTH_URL', { clientAuthUrl: event.target.value })
                        }}
                    />
                </>
            ) : null}
        </div>
    )
}

export default WStep3
