const defaultTheme = {
    "muiTheme":{
        "appBar":{
            "color":"#00567d",
            "textColor":"#ffffff",
            "height":64,
            "titleFontWeight":400,
            "padding":24
        },
        "avatar":{
            "color":"#fff",
            "backgroundColor":"rgb(188, 188, 188)"
        },
        "badge":{
            "color":"#ffffff",
            "textColor":"rgba(0, 0, 0, 0.87)",
            "primaryColor":"#00567d",
            "primaryTextColor":"#ffffff",
            "secondaryColor":"#b51535",
            "secondaryTextColor":"#ffffff",
            "fontWeight":500
        },
        "bottomNavigation":{
            "backgroundColor":"#fff",
            "unselectedColor":"rgba(0, 0, 0, 0.54)",
            "selectedColor":"#00567d",
            "height":56,
            "unselectedFontSize":12,
            "selectedFontSize":14
        },
        "button":{
            "height":36,
            "minWidth":88,
            "iconButtonSize":48
        },
        "card":{
            "titleColor":"rgba(0, 0, 0, 0.87)",
            "subtitleColor":"rgba(0, 0, 0, 0.54)",
            "fontWeight":500
        },
        "cardMedia":{
            "color":"rgba(255, 255, 255, 0.87)",
            "overlayContentBackground":"rgba(0, 0, 0, 0.54)",
            "titleColor":"rgba(255, 255, 255, 0.87)",
            "subtitleColor":"rgba(255, 255, 255, 0.54)"
        },
        "cardText":{
            "textColor":"rgba(0, 0, 0, 0.87)"
        },
        "checkbox":{
            "boxColor":"rgba(0, 0, 0, 0.87)",
            "checkedColor":"#00567d",
            "requiredColor":"#00567d",
            "disabledColor":"rgba(0, 0, 0, 0.3)",
            "labelColor":"rgba(0, 0, 0, 0.87)",
            "labelDisabledColor":"rgba(0, 0, 0, 0.3)"
        },
        "chip":{
            "backgroundColor":"rgb(224, 224, 224)",
            "deleteIconColor":"rgba(0, 0, 0, 0.26)",
            "textColor":"rgba(0, 0, 0, 0.87)",
            "fontSize":14,
            "fontWeight":400,
            "shadow":"0 1px 6px rgba(0, 0, 0, 0.12),\n        0 1px 4px rgba(0, 0, 0, 0.12)"
        },
        "datePicker":{
            "color":"#00567d",
            "textColor":"#ffffff",
            "calendarTextColor":"rgba(0, 0, 0, 0.87)",
            "selectColor":"#00567d",
            "selectTextColor":"#ffffff",
            "calendarYearBackgroundColor":"#fff",
            "headerColor":"#00567d"
        },
        "dialog":{
            "titleFontSize":22,
            "bodyFontSize":16,
            "bodyColor":"rgba(0, 0, 0, 0.6)"
        },
        "dropDownMenu":{
            "accentColor":"#e0e0e0"
        },
        "enhancedButton":{
            "tapHighlightColor":"rgba(0, 0, 0, 0)"
        },
        "flatButton":{
            "color":"rgba(0, 0, 0, 0)",
            "buttonFilterColor":"#999999",
            "disabledTextColor":"rgba(0, 0, 0, 0.3)",
            "textColor":"rgba(0, 0, 0, 0.87)",
            "primaryTextColor":"#00567d",
            "secondaryTextColor":"#b51535",
            "fontSize":14,
            "fontWeight":500
        },
        "floatingActionButton":{
            "buttonSize":56,
            "miniSize":40,
            "color":"#00567d",
            "iconColor":"#ffffff",
            "secondaryColor":"#b51535",
            "secondaryIconColor":"#ffffff",
            "disabledTextColor":"rgba(0, 0, 0, 0.3)",
            "disabledColor":"rgb(224, 224, 224)"
        },
        "gridTile":{
            "textColor":"#ffffff"
        },
        "icon":{
            "color":"#fff",
            "backgroundColor":"#00567d"
        },
        "inkBar":{
            "backgroundColor":"#b51535"
        },
        "drawer":{
            "width":256,
            "color":"#fff"
        },
        "listItem":{
            "nestedLevelDepth":18,
            "secondaryTextColor":"rgba(0, 0, 0, 0.54)",
            "leftIconColor":"#757575",
            "rightIconColor":"#757575"
        },
        "menu":{
            "backgroundColor":"#fff",
            "containerBackgroundColor":"#fff"
        },
        "menuItem":{
            "dataHeight":32,
            "height":48,
            "hoverColor":"rgba(0, 0, 0, 0.1)",
            "padding":24,
            "selectedTextColor":"#b51535",
            "rightIconDesktopFill":"#757575"
        },
        "menuSubheader":{
            "padding":24,
            "borderColor":"#e0e0e0",
            "textColor":"#00567d"
        },
        "overlay":{
            "backgroundColor":"rgba(0, 0, 0, 0.54)"
        },
        "paper":{
            "color":"rgba(0, 0, 0, 0.87)",
            "backgroundColor":"#fff",
            "zDepthShadows":[
                "0 1px 6px rgba(0, 0, 0, 0.12),\n         0 1px 4px rgba(0, 0, 0, 0.12)",
                "0 3px 10px rgba(0, 0, 0, 0.16),\n         0 3px 10px rgba(0, 0, 0, 0.23)",
                "0 10px 30px rgba(0, 0, 0, 0.19),\n         0 6px 10px rgba(0, 0, 0, 0.23)",
                "0 14px 45px rgba(0, 0, 0, 0.25),\n         0 10px 18px rgba(0, 0, 0, 0.22)",
                "0 19px 60px rgba(0, 0, 0, 0.3),\n         0 15px 20px rgba(0, 0, 0, 0.22)"
            ]
        },
        "radioButton":{
            "borderColor":"rgba(0, 0, 0, 0.87)",
            "backgroundColor":"#ffffff",
            "checkedColor":"#00567d",
            "requiredColor":"#00567d",
            "disabledColor":"rgba(0, 0, 0, 0.3)",
            "size":24,
            "labelColor":"rgba(0, 0, 0, 0.87)",
            "labelDisabledColor":"rgba(0, 0, 0, 0.3)"
        },
        "raisedButton":{
            "color":"#ffffff",
            "textColor":"rgba(0, 0, 0, 0.87)",
            "primaryColor":"#00567d",
            "primaryTextColor":"#ffffff",
            "secondaryColor":"#b51535",
            "secondaryTextColor":"#ffffff",
            "disabledColor":"rgb(229, 229, 229)",
            "disabledTextColor":"rgba(0, 0, 0, 0.3)",
            "fontSize":14,
            "fontWeight":500
        },
        "refreshIndicator":{
            "strokeColor":"#e0e0e0",
            "loadingStrokeColor":"#00567d"
        },
        "ripple":{
            "color":"rgba(0, 0, 0, 0.87)"
        },
        "slider":{
            "trackSize":2,
            "trackColor":"#bdbdbd",
            "trackColorSelected":"#9e9e9e",
            "handleSize":12,
            "handleSizeDisabled":8,
            "handleSizeActive":18,
            "handleColorZero":"#bdbdbd",
            "handleFillColor":"#ffffff",
            "selectionColor":"#00567d",
            "rippleColor":"#00567d"
        },
        "snackbar":{
            "textColor":"#ffffff",
            "backgroundColor":"rgba(0, 0, 0, 0.87)",
            "actionColor":"#b51535"
        },
        "subheader":{
            "color":"rgba(0, 0, 0, 0.54)",
            "fontWeight":500
        },
        "stepper":{
            "backgroundColor":"transparent",
            "hoverBackgroundColor":"rgba(0, 0, 0, 0.06)",
            "iconColor":"#00567d",
            "hoveredIconColor":"#616161",
            "inactiveIconColor":"#9e9e9e",
            "textColor":"rgba(0, 0, 0, 0.87)",
            "disabledTextColor":"rgba(0, 0, 0, 0.26)",
            "connectorLineColor":"#bdbdbd"
        },
        "svgIcon":{
            "color":"rgba(0, 0, 0, 0.87)"
        },
        "table":{
            "backgroundColor":"#fff"
        },
        "tableFooter":{
            "borderColor":"#e0e0e0",
            "textColor":"#9e9e9e"
        },
        "tableHeader":{
            "borderColor":"#e0e0e0"
        },
        "tableHeaderColumn":{
            "textColor":"#9e9e9e",
            "height":56,
            "spacing":24
        },
        "tableRow":{
            "hoverColor":"#f5f5f5",
            "stripeColor":"rgba(127, 170, 190, 0.4)",
            "selectedColor":"#e0e0e0",
            "textColor":"rgba(0, 0, 0, 0.87)",
            "borderColor":"#e0e0e0",
            "height":48
        },
        "tableRowColumn":{
            "height":48,
            "spacing":24
        },
        "tabs":{
            "backgroundColor":"#00567d",
            "textColor":"rgba(255, 255, 255, 0.7)",
            "selectedTextColor":"#ffffff"
        },
        "textField":{
            "textColor":"rgba(0, 0, 0, 0.87)",
            "hintColor":"rgba(0, 0, 0, 0.3)",
            "floatingLabelColor":"rgba(0, 0, 0, 0.3)",
            "disabledTextColor":"rgba(0, 0, 0, 0.3)",
            "errorColor":"#b51535",
            "focusColor":"#00567d",
            "backgroundColor":"transparent",
            "borderColor":"#e0e0e0"
        },
        "timePicker":{
            "color":"#ffffff",
            "textColor":"#ffffff",
            "accentColor":"#00567d",
            "clockColor":"rgba(0, 0, 0, 0.87)",
            "clockCircleColor":"rgba(0, 0, 0, 0.07)",
            "headerColor":"#00bcd4",
            "selectColor":"#0097a7",
            "selectTextColor":"#ffffff"
        },
        "toggle":{
            "thumbOnColor":"#00567d",
            "thumbOffColor":"#f5f5f5",
            "thumbDisabledColor":"#e0e0e0",
            "thumbRequiredColor":"#00567d",
            "trackOnColor":"rgba(0, 86, 125, 0.5)",
            "trackOffColor":"#bdbdbd",
            "trackDisabledColor":"#bdbdbd",
            "labelColor":"rgba(0, 0, 0, 0.87)",
            "labelDisabledColor":"rgba(0, 0, 0, 0.3)",
            "trackRequiredColor":"rgba(0, 86, 125, 0.5)"
        },
        "toolbar":{
            "color":"rgba(0, 0, 0, 0.54)",
            "hoverColor":"rgba(0, 0, 0, 0.87)",
            "backgroundColor":"rgb(232, 232, 232)",
            "height":56,
            "titleFontSize":20,
            "iconColor":"rgba(0, 0, 0, 0.4)",
            "separatorColor":"rgba(0, 0, 0, 0.175)",
            "menuHoverColor":"rgba(0, 0, 0, 0.1)"
        },
        "tooltip":{
            "color":"#ffffff",
            "rippleBackgroundColor":"#616161"
        },
        "zIndex":{
            "menu":1000,
            "appBar":1100,
            "drawerOverlay":1200,
            "drawer":1300,
            "dialogOverlay":1400,
            "dialog":1500,
            "layer":2000,
            "popover":2100,
            "snackbar":2900,
            "tooltip":3000
        },
        "isRtl":false,
        "spacing":{
            "iconSize":24,
            "desktopGutter":24,
            "desktopGutterMore":32,
            "desktopGutterLess":16,
            "desktopGutterMini":8,
            "desktopKeylineIncrement":64,
            "desktopDropDownMenuItemHeight":32,
            "desktopDropDownMenuFontSize":15,
            "desktopDrawerMenuItemHeight":48,
            "desktopSubheaderHeight":48,
            "desktopToolbarHeight":56
        },
        "fontFamily":"Fira Sans, sans-serif",
        "borderRadius":2,
        "palette":{
            "accent1Color":"#b51535",
            "accent2Color":"#f5f5f5",
            "accent3Color":"#9e9e9e",
            "alternateTextColor":"#ffffff",
            "borderColor":"#e0e0e0",
            "canvasColor":"#fff",
            "clockCircleColor":"rgba(0, 0, 0, 0.07)",
            "disabledColor":"rgba(0, 0, 0, 0.3)",
            "pickerHeaderColor":"#00bcd4",
            "primary1Color":"#00567d",
            "primary2Color":"#0097a7",
            "primary3Color":"#bdbdbd",
            "secondaryTextColor":"rgba(0, 0, 0, 0.54)",
            "shadowColor":"rgba(0, 0, 0, 1)",
            "textColor":"rgba(0, 0, 0, 0.87)"
        },
        "baseTheme":{
            "spacing":{
                "iconSize":24,
                "desktopGutter":24,
                "desktopGutterMore":32,
                "desktopGutterLess":16,
                "desktopGutterMini":8,
                "desktopKeylineIncrement":64,
                "desktopDropDownMenuItemHeight":32,
                "desktopDropDownMenuFontSize":15,
                "desktopDrawerMenuItemHeight":48,
                "desktopSubheaderHeight":48,
                "desktopToolbarHeight":56
            },
            "fontFamily":"Fira Sans, sans-serif",
            "palette":{
                "accent1Color":"#b51535",
                "accent2Color":"#f5f5f5",
                "accent3Color":"#9e9e9e",
                "alternateTextColor":"#ffffff",
                "borderColor":"#e0e0e0",
                "canvasColor":"#fff",
                "clockCircleColor":"rgba(0, 0, 0, 0.07)",
                "disabledColor":"rgba(0, 0, 0, 0.3)",
                "pickerHeaderColor":"#00bcd4",
                "primary1Color":"#00567d",
                "primary2Color":"#0097a7",
                "primary3Color":"#bdbdbd",
                "secondaryTextColor":"rgba(0, 0, 0, 0.54)",
                "shadowColor":"rgba(0, 0, 0, 1)",
                "textColor":"rgba(0, 0, 0, 0.87)"
            }
        },
        "rawTheme":{
            "spacing":{
                "iconSize":24,
                "desktopGutter":24,
                "desktopGutterMore":32,
                "desktopGutterLess":16,
                "desktopGutterMini":8,
                "desktopKeylineIncrement":64,
                "desktopDropDownMenuItemHeight":32,
                "desktopDropDownMenuFontSize":15,
                "desktopDrawerMenuItemHeight":48,
                "desktopSubheaderHeight":48,
                "desktopToolbarHeight":56
            },
            "fontFamily":"Fira Sans, sans-serif",
            "palette":{
                "accent1Color":"#b51535",
                "accent2Color":"#f5f5f5",
                "accent3Color":"#9e9e9e",
                "alternateTextColor":"#ffffff",
                "borderColor":"#e0e0e0",
                "canvasColor":"#fff",
                "clockCircleColor":"rgba(0, 0, 0, 0.07)",
                "disabledColor":"rgba(0, 0, 0, 0.3)",
                "pickerHeaderColor":"#00bcd4",
                "primary1Color":"#00567d",
                "primary2Color":"#0097a7",
                "primary3Color":"#bdbdbd",
                "secondaryTextColor":"rgba(0, 0, 0, 0.54)",
                "shadowColor":"rgba(0, 0, 0, 1)",
                "textColor":"rgba(0, 0, 0, 0.87)"
            }
        }
    }
};

export default defaultTheme;
