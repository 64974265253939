import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const ColoredSwitch = withStyles((theme: Theme) =>
    createStyles({
        switchBase: {
            color: theme.palette.primary.main, // color for 'off' state
            '&$checked': {
                color: theme.palette.secondary.main, // color for 'on' state
            },
            '&$checked + $track': {
                backgroundColor: theme.palette.secondary.main, // background color for 'on' state
            },
        },
        checked: {},
        track: {
            backgroundColor: theme.palette.primary.main, // background color for 'off' state
        },
    }),
)(Switch);

interface LabeledSwitchProps {
  checked: boolean;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelOff: string;
  labelOn: string;
}

const LabeledSwitch: React.FC<LabeledSwitchProps> = ({ checked, onChange, disabled = true, labelOff, labelOn }) => (
    <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>{labelOff}</Grid>
            <Grid item>
                <ColoredSwitch disabled={disabled} checked={checked} onChange={onChange} />
            </Grid>
            <Grid item>{labelOn}</Grid>
        </Grid>
    </Typography>
);

LabeledSwitch.propTypes = {
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    labelOff: PropTypes.string.isRequired,
    labelOn: PropTypes.string.isRequired,
};

export default LabeledSwitch;