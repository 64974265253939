import type { State } from 'xstate'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import CenteredCircularProgress from '../../../../../../Widgets/CenteredCircularProgress/'
import type { TContext } from '..'

type TProps = {
    state: State<TContext, any, any, any, any>
    send: Function
}

const CustomDialogContent = (props: TProps) => {
    return (
        <DialogContent
            style={{
                minWidth: '552px',
                padding: '24px',
                background: 'rgb(250, 250, 250)',
            }}
        >
            {props.state.matches('basic_info') ? (
                <>
                    <TextField
                        name="name"
                        value={props.state.context.data.name}
                        error={!!props.state.context.dataValidation.name}
                        placeholder="Display name"
                        label="Display Name"
                        helperText={props.state.context.dataValidation.name}
                        autoComplete="off"
                        autoFocus={false}
                        fullWidth
                        style={{ marginTop: '18px' }}
                        onChange={(event) => {
                            props.send('SET_NAME', { name: event.target.value })
                        }}
                    />
                    <TextField
                        name="directoryId"
                        value={props.state.context.data.directoryId}
                        error={!!props.state.context.dataValidation.directoryId}
                        placeholder="User directory ID"
                        label="User Directory ID"
                        helperText={props.state.context.dataValidation.directoryId}
                        autoComplete="off"
                        autoFocus={false}
                        fullWidth
                        style={{ marginTop: '18px' }}
                        onChange={(event) => {
                            props.send('SET_DIRECTORY_ID', { directoryId: event.target.value })
                        }}
                    />
                    <TextField
                        name="description"
                        value={props.state.context.data.description}
                        placeholder="Description"
                        label="Description"
                        autoComplete="off"
                        autoFocus={false}
                        fullWidth
                        multiline
                        style={{ marginTop: '18px' }}
                        onChange={(event) => {
                            props.send('SET_DESCRIPTION', { description: event.target.value })
                        }}
                    />
                </>
            ) : <CenteredCircularProgress size={63} />}
        </DialogContent>
    )
}

export default CustomDialogContent
