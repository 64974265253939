import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import axiosWrapper from "../../../../../lib/axiosWrapper";
import consoleLogger from "../../../../../lib/consoleLogger";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import * as actions from "../../../../../redux/actions/";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import Refresh from "@material-ui/icons/Refresh";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AppLaunchesOT from "../../../Apps/AppEdit/Usage/UsageGraphs/AppLaunchesOT"
import TopResourcesHorizontal from "../../../Apps/AppEdit/Usage/UsageGraphs/TopResourcesHorizontal";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress/";
import {getDateDisplay, getDateWithZoneDisplay, getFullDateDisplay} from "../../../../../lib/utils";
import getEnvironmentName from "../../../../../lib//getEnvironmentName";
import TotalCount from "../../../../Widgets/Editor/UsageTab/TotalCount";
import "./style.less";

let moment = require("moment-timezone");

export class Analytics extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {
            status: "init",
            assignedColors: {},
            topResourcesByAct: {
                data: null,
                refreshing: false
            },
            topResourcesTotal: {
                data: null,
                refreshing: false
            },
            topResourcesLatency: {
                data: null,
                refreshing: false
            },
            transactionsOverTimeByAct: {
                data: null,
                refreshing: false
            },
            transactionsOverTimeTotal: {
                data: null,
                refreshing: false
            },
            totalTransactions: {
                data: null,
                refreshing: false
            },
            timeframe: 2678400000,
            timeframeValue: 2678400000,
            startTime: 0,
            endTime: 0,
            timeZone: "US/Mountain",
            startOf: "day",
            interval: "day",
            snackbar: {
                open: false,
                message: "Downloading CSV...",
                autoHideDuration: null
            },
            exclude: []
        };

        this.topResources_init = this.topResources_init.bind(this);
        this.topResources_refresh = this.topResources_refresh.bind(this);
        this.handleTimeSpanChange = this.handleTimeSpanChange.bind(this);
        this.refreshAll = this.refreshAll.bind(this);
        this.handleExludeAllChange = this.handleExludeAllChange.bind(this);
        // this.getUpdatedTotalValues = this.getUpdatedTotalValues.bind(this);
        // this.handleVisibleAppsChange = this.handleVisibleAppsChange.bind(this)
    }

    public componentDidMount() {
        this.setState({
            endTime: new Date().getTime(),
            status: "ready"
        }, () => {
            this.assignChartColors();
            this.init();
        });
    }

    public render() {
        const styles = {
            cardChart: {
                display: "inline-block",
                margin: "5px"
            },
            cardChartText: {padding: 0},
            chartMargins: {top: 15, right: 30, bottom: 5, left: 0},
            subTitle: {margin: 0},
            title: {marginBottom: 0}
        };

        if (this.state.status === "init") {
            return <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
        }

        return <div style={{width: "100%"}}>
            <div className="app-analytics" data-qa-da-usage-container>
                {this.renderToolbar()}
                <TotalCount items={{ totalTransactions: { ...this.state.totalTransactions, displayName: "FHIR Transactions" } }} />
                <div style={{margin: "30px 10px 10px"}}>Total Count</div>
                <Card id="chart-top-resources" className="app-chart-card">
                    {this.topResourcesTotal_render(styles)}
                    <Divider style={{marginTop: "10px", marginLeft: "50px", marginRight: "50px"}}/>
                    {this.transactionsOverTimeTotal_render(styles)}
                    <Divider style={{marginTop: "10px", marginLeft: "50px", marginRight: "50px"}}/>
                    {this.topResourcesLatency_render(styles)}
                </Card>
                <br/>
                <br/>
                <Divider/>
                <Snackbar open={this.state.snackbar.open} message={this.state.snackbar.message} autoHideDuration={this.state.snackbar.autoHideDuration}
                    style={{backgroundColor: this.props.ui.xtheme.palette.colorBlueDark, textAlign: "center"}}/>
            </div>
        </div>;
    }

    private init() {
        Promise.all([this.topResources_init(), this.transactionsOverTime_init()])
            .then(([topData, trotData]) => {
                const topResourcesPostProcessData = this.topResourcesTotal_postProcess(topData);
                this.setState({
                    topResourcesTotal: {
                        data: topResourcesPostProcessData.preparedTotalData,
                        refreshing: false
                    },
                    topResourcesLatency: {
                        data: topResourcesPostProcessData.preparedLatencyData,
                        refreshing: false
                    },
                    transactionsOverTimeTotal: {
                        data: this.transactionsOverTimeTotal_postProcess(trotData),
                        refreshing: false
                    },
                    totalTransactions: {
                        data: this.calculateTotalTransactions(trotData),
                        refreshing: false
                    }
                }
                );
            });
    }

    private refreshAll() {
        this.topResources_refresh();
        this.transactionsOverTime_refresh();
        this.init();
    }

    private assignChartColors() {
        let assignedColors = {
            total: "#3949AB",
            latency: "#43A047",
        };
        this.setState({assignedColors});
    }

    private getChartStartTimeInMillis() {
        let midnight = moment.tz(this.state.endTime - this.state.timeframe, this.props.ui.timeZone).startOf(this.state.startOf)
        this.setState({startTime: new Date(midnight.format()).getTime()});
        return new Date(midnight.format()).getTime();
    }

    private runAnalyticsQuery(dataType, query) {
        return axiosWrapper(this.props.config.analyticsService, dataType, "POST", query)
    }

    private getTimeZone() {
        return this.props.ui.timeZone
    }

    private topResources_init() {
        let query = this.topResources_preProcess();
        return this.runAnalyticsQuery("da-top-data", query).then(res => res.data);
    }

    private topResources_preProcess() {
        const {accountId} = getHostnameInfo();

        let query = {
            daId: this.props.dataSources.selected.data.dataSourceId,
            endTime: new Date().getTime(),
            startTime: this.getChartStartTimeInMillis(),
            environmentId: this.props.dataSources.selected.data.environmentId,
            accountId
        }

        return query;
    }

    private topResourcesTotal_postProcess(rawData) {
        let preparedTotalData = {
            entries: [],
            series: ["total"]
        };

        let preparedLatencyData = {
            entries: [],
            series: ["latency"]
        };

        const resourceBuckets = rawData.aggregations.group_by_fhir_resource.buckets;
        for (let i = 0; i < resourceBuckets.length; i++) {
            let curBucket = resourceBuckets[i];
            let curTotalData = {};
            let curLatencyData = {};
            curTotalData["name"] = curBucket.key;
            curLatencyData["name"] = curBucket.key;
            curTotalData["total"] = curBucket.doc_count;
            curLatencyData["latency"] = curBucket.avg_time ? curBucket.avg_time.value.toFixed(2) : null;

            preparedTotalData.entries = preparedTotalData.entries.concat(curTotalData);
            preparedLatencyData.entries = preparedLatencyData.entries.concat(curLatencyData);
        }
        let resources = ["Observation", "Procedure", "Condition", "Medication", "Patient", "Practitioner", "Organization", "ImmunizationRecommendation"];
        if (preparedTotalData.entries.length === 0) {
            let placeholeder = [];
            let placeholederLatency = [];
            for (let i = 0; i < resources.length; i++) {
                let datapoint = {}
                let datapointLatency = {}
                datapoint["name"] = resources[i];
                datapointLatency["name"] = resources[i];
                datapoint["total"] = 0;
                datapointLatency["latency"] = 0;
                placeholeder.push(datapoint)
                placeholederLatency.push(datapointLatency)
            }
            preparedTotalData.entries = placeholeder;
            preparedLatencyData.entries = placeholederLatency;
        }

        return {preparedTotalData, preparedLatencyData};
    };

    private topResources_refresh() {
        this.setState({
            topResourcesTotal: {
                data: this.state.topResourcesTotal.data,
                refreshing: true
            },
            topResourcesTotalLatency: {
                data: this.state.topResourcesLatency.data,
                refreshing: true
            }
        });
    }

    private topResourcesTotal_render(styles) {
        return <TopResourcesHorizontal state={this.state} data={this.state.topResourcesTotal.data} sortBy="name" styles={styles} topResources_refresh={this.topResources_refresh}
            legend={false} title="Resource Transactions - Total"/>
    };

    private topResourcesLatency_render(styles) {
        return <TopResourcesHorizontal type="latency" state={this.state} data={this.state.topResourcesLatency.data} sortBy="name" styles={styles}
            topResources_refresh={this.topResources_refresh} legend={false} title="Resource Transactions - Average Latency"/>
    };

    private transactionsOverTime_init() {
        let query = this.transactionsOverTime_preProcess();
        return this.runAnalyticsQuery("da-trot-data", query).then(res => res.data);
    }

    private transactionsOverTime_preProcess() {
        let endTime = new Date().getTime()
        if (this.state.timeframe === 86400000) {
            endTime = this.getChartStartTimeInMillis() + 86400000;
        }

        return {
            timezone: this.getTimeZone(),
            interval: this.state.interval,
            endTime: endTime,
            startTime: this.getChartStartTimeInMillis(),
            daId: this.props.dataSources.selected.data.dataSourceId,
            accountId: this.props.dataSources.selected.data.accountId,
            environmentId: this.props.dataSources.selected.data.environmentId
        }
    }

    private transactionsOverTimeTotal_postProcess(rawData) {
        let preparedData = {
            entries: [],
            series: []
        };

        preparedData.series = ["total"]

        const buckets = rawData.aggregations["2"].buckets;
        for (let i = 0; i < buckets.length; i++) {
            let curBucket = buckets[i];
            let curData = {};
            curData["name"] = curBucket.key;
            curData["total"] = curBucket.doc_count;

            preparedData.entries = preparedData.entries.concat(curData);
        }
        if (preparedData.entries.length === 0) {
            let placeholeder = [];
            let intervals;
            let add;
            let datapointName = this.getChartStartTimeInMillis()
            switch (this.state.timeframe) {
                case 0:
                    intervals = Math.floor((new Date().getTime() - datapointName) / 3600000)
                    add = "hour"
                    break;
                case 86400000:
                    intervals = 24
                    add = "hour"
                    break;
                case 604800000:
                    intervals = 7
                    add = "day"
                    break;
                case 2678400000:
                    intervals = 30
                    add = "day"
                    break;
                case 8035200000:
                    intervals = 14
                    add = "week"
                    break;
                case 31536000000:
                    intervals = 12
                    add = "month"
                    break;
            }
            for (let i = 0; i <= intervals; i++) {
                let datapoint = {}
                datapoint["name"] = datapointName;
                datapoint[this.props.apps.selected.data.name] = 0;
                placeholeder.push(datapoint)
                datapointName = new Date(moment.tz(datapointName, this.props.ui.timeZone).add(1, add).format()).getTime();
            }
            preparedData.entries = placeholeder
        }

        return preparedData;
    };

    private calculateTotalTransactions(rawData) {
        return rawData.hits.total?.value || 0;
    }

    private transactionsOverTime_refresh() {
        this.setState({
            transactionsOverTimeTotal: {
                data: this.state.transactionsOverTimeTotal.data,
                refreshing: true
            },
            totalTransactions: {
                data: this.state.totalTransactions.data,
                refreshing: true
            }
        });
    }

    private transactionsOverTimeTotal_render(styles) {
        return <AppLaunchesOT ui={this.props.ui} state={this.state} data={this.state.transactionsOverTimeTotal.data} styles={styles}
            launchesOverTime_refresh={this.transactionsOverTime_refresh} legend={false} title="Total Transactions on Data Adapter"/>
    }

    private renderToolbar = () => {
        let timeframe = getDateDisplay(this.state.startTime, this.props.ui.timeZone) + " - " + getDateWithZoneDisplay(this.state.endTime, this.props.ui.timeZone);

        return <Toolbar className={"analytics-toolbar"} style={{height: "76px", display: "flex", justifyContent: "space-between"}}>
            <div style={{alignItems: "none"}}>
                <DateRangeIcon style={{fontSize: "24px", paddingRight: "10px", alignSelf: "center"}}/>
                <Select value={this.state.timeframeValue} onChange={this.handleTimeSpanChange}>
                    <MenuItem value={0}>
                        Today
                    </MenuItem>
                    <MenuItem value={86400000}>
                        Yesterday
                    </MenuItem>
                    <MenuItem value={604800000}>
                        Last 7 days
                    </MenuItem>
                    <MenuItem value={2678400000}>
                        Last 30 days
                    </MenuItem>
                    <MenuItem value={8035200000}>
                        Last 90 days
                    </MenuItem>
                    <MenuItem value={31536000000}>
                        Last 365 days
                    </MenuItem>
                    <MenuItem value={-12}>
                        Year-to-date
                    </MenuItem>
                </Select>
                <FormHelperText>
                    {timeframe}
                </FormHelperText>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <Tooltip title="Refresh" placement="bottom">
                    <IconButton onClick={this.refreshAll} disabled={this.state.topResourcesLatency.refreshing} disableTouchRipple style={{alignSelf: "center", margin: "10px 0 10px 10px"}}>
                        <Refresh/>
                    </IconButton>
                </Tooltip>
            </div>
        </Toolbar>;
    };

    private handleExludeAllChange(_event, toggled) {
        if (toggled) {
            this.setState({
                exclude: ["metadata"]
            })
        } else {
            this.setState({
                exclude: []
            })
        }
    }

    private handleTimeSpanChange = (event) => {
        let startOf;
        let interval;
        let value = event.target.value;
        let timeframeValue = value;
        switch (value) {
            case 0:
                startOf = "day"
                interval = "hour"
                break;
            case 86400000:
                startOf = "day"
                interval = "hour"
                break;
            case 604800000:
                startOf = "day"
                interval = "day"
                break;
            case 2678400000:
                startOf = "day"
                interval = "day"
                break;
            case 8035200000:
                startOf = "week"
                interval = "week"
                break;
            case 31536000000:
                startOf = "month"
                interval = "month"
                break;
            case -12:
                value = this.calculateTimeframe("year")
                timeframeValue = -12;
                startOf = "day";
                if (value < 86400000) {
                    interval = "hour"
                } else if (value < 2678400000) {
                    interval = "day"
                } else if (value < 10713600000) {
                    interval = "week"
                } else {
                    interval = "month"
                }
                break;
        }
        this.setState({timeframe: value, timeframeValue, interval, startOf}, this.refreshAll);
    };

    private calculateTimeframe = startOf => {
        let startDate = moment(this.state.endTime).tz(this.props.ui.timeZone).startOf(startOf);
        let endDate = moment(this.state.endTime).tz(this.props.ui.timeZone);
        let timeframe = endDate.diff(startDate);
        return timeframe;
    };

    private exportTransactionsCsv = () => {
        this.setState({
            snackbar: {
                open: true,
                message: "Downloading CSV...",
                autoHideDuration: null
            }
        });
        const endTime = new Date().getTime();
        const startTime = this.state.startTime;
        const accountName = this.props.accounts.selected.data.name;
        const environmentId = this.props.dataSources.selected.data.environmentId;
        const environmentName = getEnvironmentName(environmentId, this.props.environments);
        const dataSourceName = this.props.dataSources.selected.data.name;
        const query = {
            startTime,
            endTime,
            daId: this.props.dataSources.selected.data.dataSourceId,
            accountId: this.props.dataSources.selected.data.accountId,
            environmentId,
            timeZone: this.props.ui.timeZone
        }
        axiosWrapper(this.props.config.analyticsService, "da-export-transactions", "POST", query, {}, "blob")
            .then(response => {
                let fileName = `${accountName}_${environmentName}_${dataSourceName}_da-transactions-${getFullDateDisplay(Date.now(), this.props.ui.timeZone)}.csv`;
                if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                    (window.navigator as any).msSaveOrOpenBlob(new Blob([response.data]), fileName);
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const sanitizedUrl = encodeURI(url);
                    const link = document.createElement("a");
                    link.href = sanitizedUrl;
                    let sanitizedFileName = encodeURI(fileName);
                    link.setAttribute("download", sanitizedFileName);
                    document.body.appendChild(link);
                    link.click();
                }
                this.setState({
                    snackbar: {
                        open: true,
                        message: "CSV Downloaded",
                        autoHideDuration: 4000
                    }
                });
            })
            .catch((error) => {
                consoleLogger.log(error);
                this.setState({
                    snackbar: {
                        open: true,
                        message: "Error Downloading CSV",
                        autoHideDuration: 4000
                    }
                });
            });
    };
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Analytics));
