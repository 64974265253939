import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import * as actions from "../../../../../redux/actions";
import getDataSourcesCallUrl from "../../../../../lib/getDataSourcesCallUrl";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";
import consoleLogger from "../../../../../lib/consoleLogger";
import getLocationInfo from "../../../../../lib/getLocationInfo";
import {delay, getPath} from "../../../../../lib/utils/";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress/";
import Operations from "./Operations/";
import Metadata from "./Metadata";

const INIT_STATE = {
    status: "",
    data: {},
    cashedResources: [],
    resources: [],
    filters: {
        resources: ":ALL:",
        operations: {
            read: false,
            search: false,
            create: false,
            update: false,
            delete: false
        }
    },
    sortBy: {
        type: true
    }
};

class SupportedOperations extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        dataSources: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        dataSourcesRead: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.initialize();
    }

    public componentDidUpdate(_, prevState) {
        const filters_string = JSON.stringify(this.state.filters);
        const prevFilters_string = JSON.stringify(prevState.filters);
        const sortBy_string = JSON.stringify(this.state.sortBy);
        const prevSortBy_string = JSON.stringify(prevState.sortBy);

        if ((filters_string !== prevFilters_string) || (sortBy_string !== prevSortBy_string)) {
            this.setState(
                {status: "recalc"},
                () => delay(250).then(() => this.setState(
                    {resources: this.getResources()},
                    () => this.setState({status: "ready"})
                ))
            );
        }
    }

    public render() {
        return <div>
            {this.state.showMetadata && <Metadata {...this.props} onClose={() => this.setState({showMetadata: false})} />}
            {["ready", "recalc"].indexOf(this.state.status) < 0
                ? <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
                : <Operations {...this.props} state={this.state} onSetState={this.onSetState} />
            }
            <br/>
        </div>;
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private getAllResourcesArray = () => {
        const type = getPath(this.props, "dataSources.selected.data.type") || "";
        const fhirVersion = getPath(this.props, "configuration.fhirVersion") || "";
        if (
            ["DataSourceAllscriptsDstu2", "DataSourceCernerDstu2", "DataSourceEpicDstu2", "DataSourceFlatironOncoEmrDstu2", "DataSourceFhirDstu2Proxy"].includes(type)
            || (type === "DataSourceHspc" && fhirVersion === "FHIR_DSTU2__1_0_2")
        ) {
            return this.props.config.fhirDSTU2Resources;
        } else if (["DataSourceFhirStu3Proxy"].includes(type) || (type === "DataSourceHspc" && fhirVersion === "FHIR_STU3__3_0_2")) {
            return this.props.config.fhirSTU3Resources;
        } else if (["DataSourceCernerR4", "DataSourceFhirR4Proxy"].includes(type) || (type === "DataSourceHspc" && fhirVersion === "FHIR_R4__4_0_1")) {
            return this.props.config.fhirR4Resources;
        }

        return [];
    }

    private getFilteredResources(resources) {
        let filtered = resources

        if (this.state.filters.resources === ':SUPPORTED:') {
            filtered = filtered.filter((it) => Object.keys(it)
                .filter((key) => key !== 'type')
                .reduce((acc, key) => acc || it[key], false)
            )
        }
        else if (this.state.filters.resources === ':UNSUPPORTED:') {
            filtered = filtered.filter((it) => !Object.keys(it)
                .filter((key) => key !== 'type')
                .reduce((acc, key) => acc || it[key], false)
            )
        }
        else if (this.state.filters.resources === ':USCDI:') {
            filtered = filtered.filter((it) => this.props.config.fhirUscdiResources.includes(it.type))
        }
        else if (this.state.filters.resources === ':NON-USCDI:') {
            filtered = filtered.filter((it) => !this.props.config.fhirUscdiResources.includes(it.type))
        }

        // *** Operation filter
        const filters = Object.keys(this.state.filters.operations)
            .reduce((acc, it) => {
                if (this.state.filters.operations[it]) {
                    return [...acc, it]
                }
                return acc
            }, [])
        if (filters.length) {
            filtered = filtered
                .filter((it) => Object.keys(it).some((k) => it[k] && filters.indexOf(k) >= 0))
        }

        return filtered
    }

    private getSortedResources(resources) {
        const sortBy = this.state.sortBy;
        let sorted = [...resources];

        if (sortBy.type !== undefined) {
            if (sortBy.type === true) {
                sorted.sort((a, b) => {
                    if (a.type < b.type) return -1;
                    else if (a.type > b.type) return 1;
                    else return 0;
                });
            } else if (sortBy.type === false) {
                sorted.sort((a, b) => {
                    if (a.type < b.type) return 1;
                    else if (a.type > b.type) return -1;
                    else return 0;
                });
            }
        }

        ["read", "search", "create", "update", "delete"]
            .forEach((operation) => this.sortByOperation(sorted, operation));

        return sorted;
    }

    private sortByOperation(sorted, operation) {
        const sortBy = this.state.sortBy;
        if (sortBy[operation] !== undefined) {
            if (sortBy[operation] === true) {
                sorted.sort((a, b) => {
                    if (!!a[operation] < !!b[operation]) return -1;
                    else if (!!a[operation] > !!b[operation]) return 1;
                    else return 0;
                });
            } else if (sortBy[operation] === false) {
                sorted.sort((a, b) => {
                    if (!!a[operation] < !!b[operation]) return 1;
                    else if (!!a[operation] > !!b[operation]) return -1;
                    else return 0;
                });
            }
        }
    }

    private getResources = (cashedResources?) => {
        let resources = [];

        if (cashedResources) {
            resources = [...cashedResources];
        } else {
            resources = [...this.state.cashedResources];
        }

        const allResourcesArray = this.getAllResourcesArray();
        allResourcesArray.forEach((rs) => !resources.find((fr) => fr.type === rs) && resources.push({type: rs}));
        resources = this.getFilteredResources(resources);
        resources = this.getSortedResources(resources);
        return resources;
    }

    private initialize = () => {
        this.setState(
            {status: "init"},
            () => {
                const {accountId} = getHostnameInfo();
                const {env, dataSourceId} = getLocationInfo(this.props.location, this.props.config);
                const metadata = "true"; // ? | "true" | "refresh"
                this.props.dataSourcesRead(this.props.config, getDataSourcesCallUrl(accountId, env) + "/" + dataSourceId + "?metadata=" + metadata)
                    .then(() => {
                        const resources = getPath(this.props, "dataSources.selected.data.metadata.rest.0.resource");
                        const cashedResources = [];
                        if (resources && resources.length) {
                            resources.forEach((re) => {
                                const type = re.type;
                                let read = false;
                                let search = false;
                                let create = false;
                                let update = false;
                                let del = false;
                                re.interaction && re.interaction.forEach(ia => {
                                    switch (ia.code) {
                                        case "read":
                                            read = true;
                                            break;
                                        case "search":
                                        case "search-type":
                                        case "search-system":
                                            search = true;
                                            break;
                                        case "create":
                                            create = true;
                                            break;
                                        case "update":
                                            update = true;
                                            break;
                                        case "delete":
                                            del = true;
                                            break;
                                    }
                                });
                                cashedResources.push({type, read, search, create, update, delete: del});
                            });
                        }
                        this.setState({
                            status: "ready",
                            data: this.props.dataSources.selected.data,
                            cashedResources,
                            resources: this.getResources(cashedResources)
                        });
                    })
                    .catch((reason) => consoleLogger.error(":::", reason));
            }
        );
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(SupportedOperations)));
