import { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../redux/actions";

import getAppUsersCallUrl from "../../../../../../lib/getAppUsersCallUrl";

import Dialog from "@material-ui/core/Dialog";

import CustomDialogActions from "./CustomDialogActions";
import CustomDialogContent from "./CustomDialogContent";
import CustomDialogTitle from "./CustomDialogTitle";

const AppUserAsFhir = (props) => {
    const [fstatus, setFStatus] = useState("");
    const [fhirVersion, setFhirVersion] = useState("");
    const [fhirUser, setFhirUser] = useState(null);

    const { appUsersRead } = props;
    useEffect(() => {
        (async () => {
            setFStatus("init");
            const selectedAppUser = props.parentState.selectedAppUser || {};
            const accountId = selectedAppUser.accountId;
            const environmentId = selectedAppUser.environmentId;
            const directoryId = selectedAppUser.dirId;
            const appUserId = selectedAppUser.uid;
            const url = getAppUsersCallUrl(accountId, environmentId, directoryId) + `/${appUserId}`;
            const response = await appUsersRead(props.config, url);
            if (response && response.status < 300) {
                setFStatus("ready");
            }
        })();
    }, [appUsersRead, props.config, props.parentState.selectedAppUser]);

    return <Dialog
        open disableBackdropClick disableEscapeKeyDown scroll="body"
        onClose={props.onClose}
    >
        <CustomDialogTitle onClose={props.onClose} />
        <CustomDialogContent
            fstatus={fstatus}
            fhirUser={fhirUser}
            fhirVersion={fhirVersion}
            parentState={props.parentState}
            setFhirVersion={setFhirVersion}
            setFhirUser={setFhirUser}
            setFStatus={setFStatus}
            onSetParentState={props.onSetParentState}
        />
        <CustomDialogActions
            fstatus={fstatus}
            parentState={props.parentState}
            onClose={props.onClose}
        />
    </Dialog >
}

AppUserAsFhir.propTypes = {
    config: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,

    appUsersRead: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appUsersRead: actions.appUsersRead,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AppUserAsFhir);

