import * as React from 'react'
import type { TAppActivation, TGateway } from '../../../../../../../../types'
import type {
    TAuthMode,
    TDataValidation,
    TEhrAuthType,
    TStatus as TEStatus,
    TProxyAuthType,
    TSystemAuthType,
} from '../..'
import EStep1 from './EStep1'

type TProps = {
    appActivation: TAppActivation
    authMode: TAuthMode,
    canEdit: boolean
    dataValidation: TDataValidation
    ehrAuthType: TEhrAuthType
    estatus: TEStatus
    estep: number
    disableAuthMode: boolean
    gateways: TGateway[]
    isAllscriptsUnityGateway: boolean
    isAppLaunchingWithinIKMG2: boolean
    isCernerR4Gateway: boolean
    isEpicR4Gateway: boolean
    isEpicR4ExternalGateway: boolean
    isExtAuthDa: boolean
    isExtAuthGtw: boolean
    isIKnowMedExtGateway: boolean
    isLaunchProxyRequired: boolean
    isVarianR4Gateway: boolean
    muiTheme: any
    proxyAuthType: TProxyAuthType
    systemAuthType: TSystemAuthType
    setAppActivation: React.Dispatch<React.SetStateAction<TAppActivation>>
    setAuthMode: React.Dispatch<React.SetStateAction<TAuthMode>>
    setEhrAuthType: React.Dispatch<React.SetStateAction<TEhrAuthType>>
    setEStatus: React.Dispatch<React.SetStateAction<TEStatus>>
    setIsAppLaunchingWithinIKMG2: React.Dispatch<React.SetStateAction<boolean>>
    setProxyAuthType: React.Dispatch<React.SetStateAction<TProxyAuthType>>
    setSystemAuthType: React.Dispatch<React.SetStateAction<TSystemAuthType>>
}

const ESteps: React.FC<TProps> = (props: TProps) => {
    switch (props.estep) {
        case 1: return (
            <EStep1
                appActivation={props.appActivation}
                authMode={props.authMode}
                canEdit={props.canEdit}
                dataValidation={props.dataValidation}
                disableAuthMode={props.disableAuthMode}
                ehrAuthType={props.ehrAuthType}
                estatus={props.estatus}
                gateways={props.gateways}
                isAllscriptsUnityGateway={props.isAllscriptsUnityGateway}
                isAppLaunchingWithinIKMG2={props.isAppLaunchingWithinIKMG2}
                isCernerR4Gateway={props.isCernerR4Gateway}
                isEpicR4Gateway={props.isEpicR4Gateway}
                isEpicR4ExternalGateway={props.isEpicR4ExternalGateway}
                isExtAuthDa={props.isExtAuthDa}
                isExtAuthGtw={props.isExtAuthGtw}
                isIKnowMedExtGateway={props.isIKnowMedExtGateway}
                isLaunchProxyRequired={props.isLaunchProxyRequired}
                isVarianR4Gateway={props.isVarianR4Gateway}
                muiTheme={props.muiTheme}
                proxyAuthType={props.proxyAuthType}
                systemAuthType={props.systemAuthType}
                setAppActivation={props.setAppActivation}
                setAuthMode={props.setAuthMode}
                setEhrAuthType={props.setEhrAuthType}
                setEStatus={props.setEStatus}
                setIsAppLaunchingWithinIKMG2={props.setIsAppLaunchingWithinIKMG2}
                setProxyAuthType={props.setProxyAuthType}
                setSystemAuthType={props.setSystemAuthType}
            />
        )
        default: return null
    }
}

export default ESteps
