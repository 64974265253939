import * as React from "react";
import * as PropTypes from "prop-types";
import axiosWrapper from "../../../../../../lib/axiosWrapper";
import {getPath} from "../../../../../../lib/utils";
import consoleLogger from "../../../../../../lib/consoleLogger";
import {CircularProgress, Button} from "@material-ui/core";

const Component: React.FunctionComponent<any> = props => {
    const content = [
        <Button variant="outlined" key="cancel" style={{marginRight: "8px"}} onClick={() => props.onSetState({dialog: ""})}>
            Cancel
        </Button>,
        <Button variant="contained" data-qa="users-permissions-cahnge-user-type-change-button" key="change" color="secondary" disabled={props.state.status !== "ready"}
            onClick={() => {
                props.onSetState(
                    { status: "wait" },
                    () => {
                        const userUuid = getPath(props, "state.selectedUser.uuid");
                        const data = { mfaEnabled: 1 };
                        axiosWrapper(
                            props.config.accountApi,
                            `users/${userUuid}`,
                            "PUT",
                            data
                        )
                            .then(props.initialize)
                            .catch(reason => {
                                props.onSetState({status: "ready"});
                                consoleLogger.log("::: reason:", reason);
                            });
                    }
                );
            }}>
            {props.state.status === "wait" ? <CircularProgress size={24}/> : null} Reset User MFA
        </Button>
    ];

    return <div>{content}</div>;
}

Component.propTypes = {
    config: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
