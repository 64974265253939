import * as React from "react";
import * as PropTypes from "prop-types";
import getLocationInfo from "../../../../../lib/getLocationInfo";
import checkDAVendor from "../../../../../lib/checkDAVendor";
import {useState} from "react";
import {Button, List} from "@material-ui/core";
import Table, {Search} from "../../../../Widgets/Table";
import {getPath} from "../../../../../lib/utils";
import {Delete, Visibility} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import './style.css';

const DSTableList = props => {
    const [anchorEl, setAnchorEl] = useState(undefined);
    const [clickedDS, setClickedDS] = useState(null);
    const rightFilters = props.actAsAdmin
        ? [
            {
                type: "component",
                value: <Button data-qa="create-data-adapter-card" color="primary" key={1} variant="contained" onClick={() => props.onSetState({dialog: ":ADD_NEW_WIZARD:"})}>
                    Add Data Adapter
                </Button>
            }
        ]
        : [];
    const leftFilters = [
        {
            type: "component",
            value: <Search key={1} setFilterItem={(_, activation) => props.onSetParentState({filters: {...props.parentState.filters, activation}})} hint="Search data adapters"/>
        }
    ];
    const columns = [
        {
            size: 2,
            name: "name",
            label: "Name",
            sort: () => props.onSetState({sortBy: {["name"]: !getPath(props, `state.sortBy.name`)}}),
            order: true
        },
        {
            size: 1,
            name: "type",
            label: "Type"
        },
        {
            size: 1,
            name: "vendor",
            label: "Vendor"
        },
        {
            size: .4,
            name: "actions",
            label: "Actions"
        }
    ];

    return <Table rightFilters={rightFilters} columns={columns} leftFilters={leftFilters}>
        <List style={{padding: 0}}>
            {props.data.map((item, index) => {
                return <div key={index} className="data-adapters-table-item" style={{background: item.dataSourceId ? "" : props.muiTheme.palette.borderColor}}
                    onClick={e => (e.target as any).parentElement.classList.contains("data-adapters-table-item") && onClickListItem(props, item)}>
                    <div style={{flex: 2, paddingLeft: "32px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                        {item.name}
                    </div>
                    <div style={{flex: 1}}>
                        {{
                            "FHIR_DSTU2__1_0_2": "DSTU2",
                            "FHIR_STU3__3_0_1": "STU3",
                            "FHIR_STU3__3_0_2": "STU3",
                            "FHIR_R4__4_0_0": "R4",
                            "FHIR_R4__4_0_1": "R4",
                            "HL7_V2": "HL7 v2.x Listening Endpoint"
                        }?.[item.fhirVersion || item.configuration.fhirVersion || item.supportedGatewayTypes[0]] || "--"}
                    </div>
                    <div style={{flex: 1}}>
                        <img src={getLogoUrl(item.type)} style={{width: "auto", maxWidth: 98, maxHeight: 36, borderRadius: 6}}/>
                    </div>
                    <div style={{flex: .4}}>
                        <IconButton data-qa={props["data-qa"]} aria-label="more" aria-controls="long-menu" aria-haspopup="true"
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                setAnchorEl(event.currentTarget);
                                setClickedDS(item.dataSourceId);
                            }}>
                            <MoreVertIcon/>
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={clickedDS === item.dataSourceId && !!anchorEl} onClose={() => {
                            setAnchorEl(null);
                            setClickedDS(null);
                        }} PaperProps={{style: {minWidth: 168}}}>
                            <MenuItem onClick={() => onClickListItem(props, item)}>
                                <ListItemIcon>
                                    <Visibility/>
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    View
                                </Typography>
                            </MenuItem>
                            <MenuItem data-qa="gateway-menu-delete"
                                onClick={() => props.onSetState({
                                    dialog: ":DELETE_CONFIRM:",
                                    delete: {
                                        dataSourceId: item.dataSourceId,
                                        name: item.name
                                    }
                                })}>
                                <ListItemIcon>
                                    <Delete/>
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Delete
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            })}
        </List>
    </Table>
}

const onClickListItem = (props, item) => {
    const {env} = getLocationInfo(props.location, props.config);
    item.dataSourceId
        ? props.history.push(`/${env}/datasources/${item.dataSourceId}`)
        : props.history.push(`/${env}/cdrs/${item.cdrId}`);
};

const getLogoUrl = type => {
    let logoUrl = undefined;
    const vendor = checkDAVendor(type)
    switch (vendor) {
        case "fhir":
            logoUrl = "/console/img/da-logo-fhir.png";
            break;
        case "logica":
            logoUrl = "/console/img/da-logo-logica.png";
            break;
        case "allscripts":
            logoUrl = "/console/img/da-logo-allscripts.png";
            break;
        case "cerner":
            logoUrl = "/console/img/da-logo-cerner.png";
            break;
        case "epic":
            logoUrl = "/console/img/da-logo-epic.png";
            break;
        case "flatiron":
            logoUrl = "/console/img/da-logo-flatiron.png";
            break;
    }
    return logoUrl
};

DSTableList.propTypes = {
    actAsAdmin: PropTypes.bool,
    onSetState: PropTypes.func,
    data: PropTypes.array,
    muiTheme: PropTypes.object,
    location: PropTypes.object,
    config: PropTypes.object,
    history: PropTypes.object,
    onSetParentState: PropTypes.func,
    parentState: PropTypes.object,
    "data-qa": PropTypes.string
}

export default DSTableList;
