import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../redux/actions";
import getLocationInfo from "../../../lib/getLocationInfo";
import {Link} from "react-router-dom";
import withLegacyTheme from "../../../lib/hoc/with-legacy-theme";
import {NavbarL2, NavbarL3} from "../../CustomNavbar/";
import MonitoringApps from "./MonitoringApps";
import MonitoringDataAdapters from "./MonitoringDataAdapters/";
import axiosWrapper from '../../../lib/axiosWrapper'
import getHostnameInfo from '../../../lib/getHostnameInfo'

const TABS = [
    {title: "APPS"},
    {title: "DATA ADAPTERS"}
];

const TAB_SELECTED_BY_DEFAULT = 0;

const INIT_STATE = {
    timeframe: 0,
    adapters: {dataAdapterHealthInfoDtoSet: []},
    apps: []
};

class Monitoring extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,
        uiTabsSetItems: PropTypes.func.isRequired,
        uiTabsSetSelected: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.props.uiTabsSetItems(TABS);
        if (this.props.ui.tabs.selected !== TAB_SELECTED_BY_DEFAULT) {
            this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
        }
        this.getApps(2592000000);
        this.getDataAdapters(2592000000);
    }

    public render() {
        const {env} = getLocationInfo(this.props.location, this.props.config);

        return <div style={{width: "100%"}}>
            <NavbarL2 title={(
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{height: "56px", marginRight: "24px"}}>
                        <Link to={`/${env}/monitoring`} style={{color: "#fff", fontSize: "24px", textDecoration: "none"}}>
                            Monitoring
                        </Link>
                    </div>
                </div>
            )}/>
            <NavbarL3 />
            <div className="container">
                {this.renderTabContent()}
            </div>
            <br/>
        </div>;
    }

    protected getDataAdapters = timeframe => {
        this.setState({adapters: INIT_STATE.adapters, adaptersMessage: undefined}, () => {
            this.props.dataSourcesGetDataAdaptersHealth(this.props.location, timeframe)
                .then(adapters => {
                    if (!!adapters && adapters.status === 200) {
                        this.setState({adapters: adapters.data, adaptersMessage: undefined});
                    } else {
                        this.setState({adaptersMessage: "No monitoring data available for this time period"});
                    }
                });
        });
    };

    protected getApps = (timeframe) => {
        this.setState({
            apps: INIT_STATE.apps,
            timeframe,
            appsMessage: undefined,
        }, async () => {
            const { accountId } = getHostnameInfo()
            const { env } = getLocationInfo(this.props.location, this.props.config)
            let path = `apps/${accountId}/${env}`
            let endTime = new Date().getTime()
            let startTime = endTime - timeframe
            if (timeframe === 0 || timeframe === 86400000) {
                [startTime, endTime] = actions.getStartTime(timeframe)
            }
            path += `?startTime=${startTime}&endTime=${endTime}`
            const res = await axiosWrapper(
                this.props.config.monitoringApi,
                path, 'GET',
                null,
                { withCredentials: false },
            )
            if (res?.status === 200) {
                if (res.data?.length) {
                    this.setState({
                        apps: res.data,
                        appsMessage: undefined,
                    })
                }
                else {
                    this.setState({
                        appsMessage: 'No monitoring data available for this time period',
                    })
                }
            }
        })
    }

    protected updateApps = apps => {
        this.setState({apps});
    };

    protected getAppHealth = (appId, timeframe, cache = false) => {
        this.props.dataSourcesGetAppHealth(this.props.location, timeframe, appId, cache)
            .then(appHealth => {
                if (!!appHealth.data && !!appHealth.data.healthEvents) {
                    let apps = this.state.apps.slice();
                    let appIndex = apps.findIndex(i => i.appId === appId);
                    let app = Object.assign({}, apps[appIndex]);
                    app.healthEvents = appHealth.data.healthEvents;
                    app.loading = false;
                    app.loaded = true;
                    apps.splice(appIndex, 1, app);

                    this.setState({apps});
                }
            })
            .catch(() => {
                !cache && this.getAppHealth(appId, timeframe, true);
            });
    };

    private renderTabContent = () => {
        switch (this.props.ui.tabs.selected) {
            case 0:
                return <MonitoringApps {...this.props} state={this.state} getApps={this.getApps} getAppHealth={this.getAppHealth} updateApps={this.updateApps}/>;
            case 1:
                return <MonitoringDataAdapters {...this.props} state={this.state} getDataAdapters={this.getDataAdapters}/>;
            default:
                return null;
        }
    };
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Monitoring));
