import * as React from "react";
import * as PropTypes from "prop-types";
import DefaultSource from "./DefaultSource/";
import ResourceList from "./ResourceList/";

const Component: React.FunctionComponent<any> = props => {
    return <div>
        <DefaultSource {...props} />
        {props.state.data.gatewayType === "HL7_V2"
            ? null
            : <ResourceList {...props} />}
    </div>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired
}

export default Component;
