let moment = require("moment-timezone");

export function capitalizeFirstLetter (string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function delay (t: number): Promise<any> {
    return new Promise((resolve) => setTimeout(resolve, t));
}

export function getPath (obj: any, path: string) {
    return path.split(".").reduce((out, key) => out ? out[key] : undefined, obj)
}

export function checkNotAnEmptyArray (array: any): boolean {
    return (Array.isArray(array) && !!array.length);
}

export function getFullDateDisplay (timestamp, timeZone): string {
    return moment.tz(timestamp, timeZone).format("YYYY-MM-DD HH:mm:ss z");
}

export function getFullDateTimeDisplay (timestamp, timeZone): string {
    return moment.tz(timestamp, timeZone).format("YYYY-MM-DD HH:mm");
}

export function getFullDateTimeMsDisplay (timestamp, timeZone): string {
    return moment.tz(timestamp, timeZone).format("YYYY-MM-DD HH:mm:ss.SSS z");
}

export function getDateDisplay (timestamp, timeZone): string {
    return moment.tz(timestamp, timeZone).format("YYYY-MM-DD");
}

export function getDateWithZoneDisplay (timestamp, timeZone): string {
    return moment.tz(timestamp, timeZone).format("YYYY-MM-DD (z)");
}

export function getMonthAndDayDisplay (timestamp, timeZone): string {
    return moment.tz(timestamp, timeZone).format("MM-DD");
}

export function getHourAndMinuteDisplay (timestamp, timeZone): string {
    return moment.tz(timestamp, timeZone).format("HH:mm");
}

export function getMonthDisplay (timestamp, timeZone): string {
    return moment.tz(timestamp, timeZone).format("MMM");
}

export function getWeekDayDisplay (timestamp, timeZone): string {
    return moment.tz(timestamp, timeZone).format("ddd");
}

export function getDateDisplayNoTimezone (timestamp): string {
    return moment.utc(timestamp).format("YYYY-MM-DD");
}

export function getMonthFirstWrittenDateDisplayNoTimezone (timestamp): string {
    return moment.utc(timestamp).format("MMM D, YYYY");
}

export function getMonthFirstFullWrittenDateDisplayNoTimezone (timestamp): string {
    return moment.utc(timestamp).format("MMMM D, YYYY");
}

export function indexOfObjectArray(array, atr, value) {
    return array.map(function(e) { return e[atr]; }).indexOf(value);
}

export function getNumberLocaleString(value) {
    if (isNaN(parseFloat(value))) {
        return "N/A"
    }
    return parseFloat(value).toLocaleString();
}

export function tryParseJSONObject (jsonString){
    try {
        var o = JSON.parse(jsonString);

        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) {
        return {};
    }

    return {};
};
