import * as types from "../../actions/types";

import initialState from "./initial-state";

export default function (state = initialState, action: any) {
    switch (action.type) {
        case types.GATEWAYS_ALL_SET: {
            if (state.all.requestedAt > action.payload.requestedAt) {
                return state
            }

            return {
                ...state,
                all: {
                    status: action.payload.status,
                    data: action.payload.data,
                    requestedAt: action.payload.requestedAt,
                },
            }
        }

        case types.GATEWAYS_SELECTED_SET: {
            return {
                ...state,
                selected: {
                    status: action.payload.status,
                    data: { ...action.payload.data },
                },
            };
        }

        default:
            return state;
    }
}
