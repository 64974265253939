import * as PropTypes from "prop-types";

import withLegacyTheme from "../../../../../../../lib/hoc/with-legacy-theme";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import TextField from "@material-ui/core/TextField";

import convertToUrlFriendly from "../../../../../../../lib/convertToUrlFriendly";

const CdsServiceGatewayActivation = (props) => {
    const cdsServiceGatewayActivations = props.wstate?.data?.cdsServiceGatewayActivations || [];

    return (
        <List disablePadding subheader={
            <ListSubheader component="div" style={{ paddingTop: 32 }}>
                Services:
            </ListSubheader>
        }>
            <ListItem
                disabled
                style={{ boxSizing: "border-box", height: 32, padding: "8px 56px 8px 16px", background: props.muiTheme.palette.accent2Color, borderTop: "1px solid rgba(0, 0, 0, 0.1)", borderBottom: "1px solid rgba(0, 0, 0, 0.1)", color: props.muiTheme.palette.secondaryTextColor, fontSize: 14 }}
            >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: 80, minWidth: 80, maxWidth: 80, padding: "0 4px 0 0", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "left" }}>
                        Enabled
                    </div>
                    <div style={{ width: 200, minWidth: 200, maxWidth: 200, padding: "0 4px 0 0", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "left" }}>
                        ID
                    </div>
                    <div style={{ width: "100%", minWidth: 240, maxWidth: 240, padding: "0 4px 0 0", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "left" }}>
                        ID Override
                    </div>
                </div>
            </ListItem>
            {cdsServiceGatewayActivations.map((item, index) => (
                <div key={`service-item-${item.id}-${index}`}>
                    <ListItem>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ width: 80, minWidth: 80, maxWidth: 80, padding: "0 4px 0 0", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "left" }}>
                                <Checkbox
                                    checked={!item.disabled}
                                    disabled={props.wstate.status !== "ready" || !props.canEdit}
                                    color="primary"
                                    onChange={(event) => {
                                        const newCdsServiceGatewayActivations = [...cdsServiceGatewayActivations];
                                        newCdsServiceGatewayActivations[index] = {
                                            ...newCdsServiceGatewayActivations[index],
                                            disabled: !event.target.checked,
                                        };
                                        props.onSetWState((prevWState) => ({
                                            data: {
                                                ...prevWState.data,
                                                cdsServiceGatewayActivations: newCdsServiceGatewayActivations,
                                            },
                                        }));
                                    }}
                                />
                            </div>
                            <div style={{ width: 200, minWidth: 200, maxWidth: 200, padding: "0 4px 0 0", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "left" }}>
                                {item.id}
                            </div>
                            <div style={{ width: "100%", minWidth: 240, maxWidth: 240, padding: "0 4px 0 0", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "left" }}>
                                <TextField
                                    name="override-id" value={item.overrideId || ""}
                                    placeholder="No override" helperText={props.wstate.dataValidation?.overrideIds?.[item.id] || ""}
                                    error={!!props.wstate.dataValidation?.overrideIds?.[item.id]}
                                    disabled={props.wstate.status !== "ready" || !props.canEdit}
                                    autoComplete="off" autoFocus={false} fullWidth
                                    classes={{ root: props.canEdit ? "" : "disabled-text-field" }}
                                    onChange={(event) => {
                                        const newOverrideIdValue = event.target.value;
                                        const newOverrideId = convertToUrlFriendly(newOverrideIdValue.substring(0, 64));
                                        const newCdsServiceGatewayActivations = [...cdsServiceGatewayActivations];
                                        newCdsServiceGatewayActivations[index] = {
                                            ...newCdsServiceGatewayActivations[index],
                                            overrideId: newOverrideId || null
                                        };
                                        props.onSetWState((prevWState) => ({
                                            data: {
                                                ...prevWState.data,
                                                cdsServiceGatewayActivations: newCdsServiceGatewayActivations,
                                            },
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                    </ListItem>
                    <Divider />
                </div>
            ))}
        </List>
    );


};

CdsServiceGatewayActivation.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    wstate: PropTypes.object.isRequired,
    onSetWState: PropTypes.func.isRequired,
    canEdit: PropTypes.bool
};

CdsServiceGatewayActivation.defaultProps = {
    canEdit: false
};

export default withLegacyTheme()(CdsServiceGatewayActivation);
