import * as PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import CenteredCircularProgress from "../../../../../../Widgets/CenteredCircularProgress/";

const CustomDialogContent = (props) => {
    const activationName = props.parentState.selectedActivation.name || "";

    return <DialogContent style={{ minWidth: 552, padding: 24, background: "rgb(250, 250, 250)" }}>
        {props.dstate.status === "ready"
            ? <>
                <span>Are you sure you want to remove</span>&nbsp;
                <strong>{activationName}</strong>?
                <br />
                <span>This action <strong>CANNOT</strong> be undone!</span>
            </>
            : <CenteredCircularProgress size={63} />}
    </DialogContent>
}

CustomDialogContent.propTypes = {
    dstate: PropTypes.object.isRequired,
    parentState: PropTypes.object.isRequired,
};

export default CustomDialogContent;
