import * as React from "react";
import * as PropTypes from "prop-types";

const Component: React.FunctionComponent<any> = props => {
    return <div>
        <img src={"/console/img/collaboration.png"} style={{width: "100%"}}/>
        <p style={{padding: "0 24px", textAlign: "center"}}>
            {props.state.dataValidation.email}
        </p>
    </div>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired
};

export default Component;
