import * as types from "../../actions/types";
import initialState from "./initial-state";

export default function (state = initialState, action) {
    switch (action.type) {
        case types.LOGIN_RESET:
            return { ...initialState };

        case types.LOGIN_SET:
            return { ...action.payload };

        default:
            return state;
    }
}
