import * as PropTypes from "prop-types";
import CdsServiceSetAddNewWizard from "./CdsServiceSetAddNewWizard";
import CdsServiceSetDeleteConfirm from "./CdsServiceSetDeleteConfirm/";

const Dialogs = (props) => {
    const propSet = {
        parentState: props.parentState,
        initialize: props.initialize,
        onClose: props.initialize,
        onSetParentState: props.onSetParentState,
    };

    switch (props.parentState.dialog) {
        case ":ADD_NEW_WIZARD:":
            return <CdsServiceSetAddNewWizard {...propSet} />;
        case ":DELETE_CONFIRM:":
            return <CdsServiceSetDeleteConfirm {...propSet} />;
        default:
            return null;
    }
};

Dialogs.propTypes = {
    parentState: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetParentState: PropTypes.func.isRequired,
};

export default Dialogs;
