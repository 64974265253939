import * as React from 'react'
import { withTheme } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import type { TAppActivation } from '../../../../../../../types'

type TProps = {
    theme: Theme
    selectedAppActivation?: TAppActivation
}

const CustomDialogContent: React.FC<TProps> = (props: TProps) => {
    return (
        <DialogContent style={{ minWidth: '552px', background: 'rgb(250, 250, 250)' }}>
            <Typography>
                <span>Are you sure you want to remove </span>
                <strong style={{ color: props.theme.palette.secondary.main }}>
                    {props.selectedAppActivation?.name}
                </strong>
                <span> from the app registration?</span>
            </Typography>
        </DialogContent>
    )
}

export default withTheme(CustomDialogContent)
