import * as PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import CenteredCircularProgress from "../../../../../../Widgets/CenteredCircularProgress/";
import Header from "../../../../../../Widgets/Editor/Header/";
import ETabs from "./ETabs";

const CustomDialogContent = (props) => {
    const canEdit = props.estate.mode === ":EDIT:";

    return <DialogContent style={{ minWidth: 552, padding: 0, background: "rgb(250, 250, 250)" }}>
        <Header {...props} style={{ height: 56 }}
            rightIcon={!canEdit
                ? <IconButton style={{ paddingTop: 16 }} onClick={() => props.onSetEState({ mode: ":EDIT:" })}>
                    <Tooltip title="Edit"><EditIcon /></Tooltip>
                </IconButton>
                : null}
        />
        {["ready", "waiting"].includes(props.estate.status)
            ? <ETabs
                estate={props.estate}
                onSetEState={props.onSetEState}
            />
            : <CenteredCircularProgress size={63} style={{ minWidth: 552 }} />}
    </DialogContent>
}

CustomDialogContent.propTypes = {
    estate: PropTypes.object.isRequired,
    onSetEState: PropTypes.func.isRequired,
};

export default CustomDialogContent;
