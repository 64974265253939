export default function (str: string = ""): string {
    if (str.startsWith("http")) {
        return ":URL:";
    }
    if (str.startsWith("data:image")) {
        return ":FILE:"
    }

    return "";
}
