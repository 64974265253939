import * as React from "react";
import * as PropTypes from "prop-types";
import getAccountsResourceUrl from "../../../../../lib/getAccountsResourceUrl";
import * as validateData from "../../../../../lib/validate-accounts-data";
import {getMonthFirstWrittenDateDisplayNoTimezone, getPath, capitalizeFirstLetter} from "../../../../../lib/utils";
import {Card, FormControlLabel, IconButton, Switch, TextField, Tooltip, Typography} from "@material-ui/core";
import Header from "../../../../Widgets/Editor/Header/";
import ActionButtons from "./ActionButtons";
import {Edit, Link, DeleteSweep} from "@material-ui/icons";
import WidgetsDeleteConfirm from "../../../../Widgets/DeleteConfirm";
import getHostnameInfo from "../../../../../lib/getHostnameInfo";

export default class extends React.Component<any, any> {
    public static propTypes = {
        muiTheme: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {};
    }

    public componentDidMount() {
        this.validateData();
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.state.data) !== JSON.stringify(prevProps.state.data)) {
            this.validateData();
        }
    }

    public render() {
        const canEdit = this.props.state.mode === ":EDIT:";
        const isValid = Object.keys(this.props.state.dataValidation).reduce((acc, key) => acc && !this.props.state.dataValidation[key], true);

        return <Card style={{marginTop: "48px"}}>
            <Header {...this.props} leftIcon={<Link style={{paddingTop: "4px", marginRight: "36px", opacity: .5}}/>}
                primaryText={<div style={{opacity: .5}}>{getAccountsResourceUrl()}</div>} style={{height: "56px"}}
                rightIcon={!canEdit
                    ? <div style={{flexDirection: "row", display: "flex"}}>
                        <Tooltip title="Edit">
                            <IconButton data-qa="edit-account-button" style={{paddingTop: "16px"}} onClick={() => this.props.onSetState({mode: ":EDIT:"})}>
                                <Edit style={{color: this.props.muiTheme.palette.secondaryTextColor}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear All API Logs">
                            <IconButton data-qa="clear-account-logs-button" style={{paddingTop: "16px"}} onClick={() => this.setState({dialog: ":DELETE_CONFIRM:"})}>
                                <DeleteSweep style={{color: this.props.muiTheme.palette.secondaryTextColor}}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    : null}/>

            <div style={{padding: "0 24px"}}>
                {this.renderName(canEdit)}
                {this.renderAccountId(canEdit)}
                {this.renderAccountType(canEdit)}
                {this.renderAccountProgram(canEdit)}
                {/* {this.renderCustomerContact(canEdit)}
                {this.renderCustomerContactEmail(canEdit)} */}
                {/* {this.renderCustomerSince(canEdit)}
                {this.renderSubscriptionPlan(canEdit)}
                {this.renderEndDate(canEdit)}
                {this.renderBillingFrequency(canEdit)}
                {this.renderAutoRenew(canEdit)} */}
                {this.renderLoginMessage(canEdit)}
            </div>
            <ActionButtons {...this.props} isValid={isValid}/>
            {this.renderDialogs()}
        </Card>;
    }

    private renderDialogs = () => {
        return this.state.dialog === ":DELETE_CONFIRM:"
            ? <WidgetsDeleteConfirm {...this.props} type="the logs" delete={{name: ""}} onClose={() => this.setState({dialog: ""})}
                onDelete={() => {
                    const {accountId} = getHostnameInfo();
                    this.props.accountsDeleteLogs(this.props.config, accountId);
                    this.setState({dialog: ""})
                }}/>
            : undefined;
    }

    private renderName = canEdit => {
        let props = {
            fullWidth: true,
            "data-qa": "account-name-input",
            name: "name",
            value: this.props.state.data.name || "",
            label: "Account Name",
            error: !!this.props.state.dataValidation.accountName,
            helperText: !!this.props.state.dataValidation.accountName,
            placeholder: canEdit ? "Type a name for the account" : undefined,
            disabled: !canEdit,
            classes: {root: canEdit ? "" : "disabled-text-field"},
            style: {marginTop: "14px", cursor: !canEdit ? "default" : "pointer", color: !canEdit ? this.props.muiTheme.palette.textColor : "inherit"},
            onChange: canEdit ? e => this.props.onSetState({data: {...this.props.state.data, name: e.target.value}}) : undefined
        };

        return <TextField {...props} />;
    }

    private renderAccountId = canEdit => {
        return <TextField name="accountId" data-qa="account-settings-id" value={this.props.state.data.path || ""} label="Account ID" disabled fullWidth
            classes={{root: "disabled-text-field"}} style={{marginTop: "14px", cursor: "default", color: !canEdit ? this.props.muiTheme.palette.textColor : "default"}}/>;
    }

    private renderAccountType = canEdit => {
        return <TextField name="accountType" data-qa="account-settings-type" value={this.props.state.data.type && capitalizeFirstLetter(this.props.state.data.type) || ""} label="Account Type" disabled fullWidth
            classes={{root: "disabled-text-field"}} style={{marginTop: "14px", cursor: "default", color: !canEdit ? this.props.muiTheme.palette.textColor : "default"}}/>;
    }

    private renderAccountProgram = canEdit => {
        return <TextField name="accountProgram" data-qa="account-settings-program" value={this.props.state.data.program && capitalizeFirstLetter(this.props.state.data.program) || ""} label="Account Program" disabled fullWidth
            classes={{root: "disabled-text-field"}} style={{marginTop: "14px", cursor: "default", color: !canEdit ? this.props.muiTheme.palette.textColor : "default"}}/>;
    }

    private renderCustomerContact = canEdit => {
        let props = {
            fullWidth: true,
            name: "customerContact",
            "data-qa": "account-cust-cont-input",
            value: this.props.state.data.customer_contact_name || "",
            label: "Customer Contact",
            onChange: canEdit ? e => this.props.onSetState({data: {...this.props.state.data, customer_contact_name: e.target.value}}) : undefined,
            disabled: !canEdit,
            classes: {root: canEdit ? "" : "disabled-text-field"},
            style: {marginTop: "14px", cursor: !canEdit ? "default" : "pointer", color: !canEdit ? this.props.muiTheme.palette.textColor : "inherit"}
        };

        return <TextField {...props} />;
    }

    private renderCustomerContactEmail = canEdit => {
        let props = {
            fullWidth: true,
            "data-qa": "account-cust-cont-email-input",
            name: "customerContactEmail",
            value: this.props.state.data.customer_contact_email || "",
            label: "Customer Contact Email",
            error: canEdit ? !!this.props.state.dataValidation.customerContactEmail : false,
            helperText: canEdit ? this.props.state.dataValidation.customerContactEmail : undefined,
            onChange: canEdit ? e => this.props.onSetState({data: {...this.props.state.data, customer_contact_email: e.target.value}}) : undefined,
            disabled: !canEdit,
            classes: {root: canEdit ? "" : "disabled-text-field"},
            style: {marginTop: "14px", cursor: !canEdit ? "default" : "pointer", color: !canEdit ? this.props.muiTheme.palette.textColor : "inherit"}
        };

        return <TextField {...props} />;
    }

    private renderCustomerSince = canEdit => {
        let customerSince
        this.props.state.data.customer_since ? customerSince = getMonthFirstWrittenDateDisplayNoTimezone(this.props.state.data.customer_since) : customerSince = "";
        return customerSince !== ""
            ? <TextField disabled fullWidth classes={{root: "disabled-text-field"}} name="customerSince" value={customerSince} label="Customer Since"
                style={{marginTop: "14px", cursor: "default", color: canEdit ? undefined : this.props.muiTheme.palette.textColor}}/>
            : null;
    }

    private renderSubscriptionPlan = canEdit => {
        return this.props.state.data.interopio_subscription
            ? <TextField disabled fullWidth classes={{root: "disabled-text-field"}} name="subscriptionPlan" value={this.props.state.data.interopio_subscription || ""}
                label="Subscription Plan" style={{marginTop: "14px", cursor: "default", color: canEdit ? undefined : this.props.muiTheme.palette.textColor}}/>
            : null;
    }

    private renderEndDate = canEdit => {
        let еndDate
        this.props.state.data.end_date ? еndDate = getMonthFirstWrittenDateDisplayNoTimezone(this.props.state.data.end_date) : еndDate = "";
        return еndDate !== "" && this.props.state.data.interopio_subscription
            ? <TextField disabled fullWidth classes={{root: "disabled-text-field"}} name="еndDate" value={еndDate}
                label="Contract End Date" style={{marginTop: "14px", cursor: "default", color: canEdit ? undefined : this.props.muiTheme.palette.textColor}}/>
            : null;
    }

    private renderBillingFrequency = canEdit => {
        return this.props.state.data.billing_frequency && this.props.state.data.interopio_subscription
            ? <TextField disabled fullWidth classes={{root: "disabled-text-field"}} name="billingFrequency" value={this.props.state.data.billing_frequency || ""}
                label="Billing Frequency" style={{marginTop: "14px", cursor: "default", color: canEdit ? undefined : this.props.muiTheme.palette.textColor}}/>
            : null;
    }

    private renderAutoRenew = canEdit => {
        let autoRenew = this.props.state.data.auto_renew === 1 ? "Yes" : "No";
        return this.props.state.data.interopio_subscription
            ? <TextField disabled fullWidth classes={{root: "disabled-text-field"}} name="autoRenew" value={autoRenew} label="Auto Renew"
                style={{marginTop: "14px", cursor: "default", color: canEdit ? undefined : this.props.muiTheme.palette.textColor}}/>
            : null;
    }

    private renderLoginMessage = canEdit => {
        const enabled = getPath(this.props, "state.data.banner.bannerEnabled") === 1;
        const header = getPath(this.props, "state.data.banner.bannerHeader");
        const text = getPath(this.props, "state.data.banner.bannerContent");
        const message = text.length > 50 ? text.substr(0, 50) + "..." : text;
        return <>
            <Typography variant="caption" color="textSecondary" style={{display: "block", marginTop: "12px"}}>Login Message</Typography>
            <FormControlLabel label={enabled ? "Enabled" : "Disabled"}
                control={<Switch disabled={!canEdit} checked={enabled} color="primary"
                    onChange={canEdit
                        ? e => this.props.onSetState({
                            data: {
                                ...this.props.state.data,
                                banner: {...this.props.state.data.banner, bannerEnabled: e.target.checked ? 1 : 0}
                            }
                        })
                        : undefined}/>}/>
            {!!enabled &&
            <TextField disabled={!canEdit} fullWidth classes={{root: canEdit ? "" : "disabled-text-field"}} name="title" value={header} label="Title"
                style={{marginTop: "14px", cursor: "default", color: canEdit ? undefined : this.props.muiTheme.palette.textColor}}
                onChange={canEdit
                    ? e => this.props.onSetState({
                        data: {
                            ...this.props.state.data,
                            banner: {...this.props.state.data.banner, bannerHeader: e.target.value}
                        }
                    })
                    : undefined}/>}
            {!!enabled &&
            <TextField disabled={!canEdit} fullWidth classes={{root: canEdit ? "" : "disabled-text-field"}} name="message" value={canEdit ? text : message} label="Content"
                style={{marginTop: "14px", cursor: "default", color: canEdit ? undefined : this.props.muiTheme.palette.textColor}} multiline
                onChange={canEdit
                    ? e => this.props.onSetState({
                        data: {
                            ...this.props.state.data,
                            banner: {...this.props.state.data.banner, bannerContent: e.target.value}
                        }
                    })
                    : undefined}/>}
        </>;
    }

    // Helpers -----------------------------------------------------------------
    private validateData = () => {
        const dataValidation = validateData.onEditGeneral(this.props.state);
        this.props.onSetState({dataValidation});
    }
}
