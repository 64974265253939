import * as PropTypes from "prop-types";
import IconAdd from "@material-ui/icons/Add";

const CardBodyAddNew = (props) => {
    return <div data-qa={props["data-qa"]} className={`card-body-add-new ${props.className}`} style={props.style} onClick={props.onClick}>
        <p className="body-icon">
            <IconAdd/>
        </p>
        <p className="body-text">
            {props.text}
        </p>
    </div>;
}

CardBodyAddNew.propTypes = {
    className: PropTypes.string,
    "data-qa": PropTypes.string,
    style: PropTypes.object,
    text: PropTypes.string,
    onClick: PropTypes.func
};

CardBodyAddNew.defaultProps = {
    className: "",
    "data-qa": "",
    style: {},
    text: "",
    onClick: () => { }
};

export default CardBodyAddNew;
