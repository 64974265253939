import axiosWrapper from "../../lib/axiosWrapper";
import { getPath } from "../../lib/utils/";

import initialState from "../reducers/features/initial-state";

import * as types from "./types";
import consoleLogger from "../../lib/consoleLogger";

export function featuresReadConfig(config: any) {
    return function (dispatch: Function): Promise<any> {
        dispatch({
            type: types.FEATURES_ALL_SET,
            payload: {
                status: "pending",
                data: initialState.data,
            },
        });
        return axiosWrapper(
            config.featuresApi,
            "features.json",
            "GET",
            null,
            {
                withCredentials: false,
            },
        )
            .then((res: any) => {
                dispatch({
                    type: types.FEATURES_ALL_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((reason: any): void => {
                dispatch({
                    type: types.FEATURES_ALL_SET,
                    payload: {
                        status: "error",
                        data: initialState.data,
                    },
                });
                process.env.NODE_ENV !== "test" && consoleLogger.error(":::", reason);
            });
    };
}
