import {List} from "@material-ui/core";
import * as React from "react";
import Table, {FilterByDate} from "../../../../Widgets/Table";
import LoginItem from "./LoginItem";
import * as PropTypes from "prop-types";
import {
    delay,
    getFullDateDisplay,
    getFullDateTimeMsDisplay
} from '../../../../../lib/utils'

const RecentLogins = props => {
    const columns = [
        {
            name: "user",
            size: 1,
            label: "User"//,
            // sort: () => props.onSetState({sortBy: {["end"]: !getPath(props, "state.sortBy.end")}}),
            // order: getPath(props, "state.sortBy.end")
        },
        {
            name: "timestamp",
            size: 1,
            label: "Timestamp"//,
            // sort: () => props.onSetState({sortBy: {["status"]: !getPath(props, "state.sortBy.status")}}),
            // order: getPath(props, "state.sortBy.status")
        },
        {
            name: "client",
            size: 1,
            label: "Client"//,
            // sort: () => props.onSetState({sortBy: {["channelType"]: !getPath(props, "state.sortBy.channelType")}}),
            // order: getPath(props, "state.sortBy.channelType")
        }
    ];

    let pagination = {
        goToPage: props.initialize,
        loading: props.status !== "ready",
        size: props.filters.pageSize,
        number: props.filters.page,
        totalElements: props.filters.totalElements,
        setPageSize: size => props.onSetState({ filters: { ...props.filters, pageSize: size }})
    };

    async function exportData(separator) {
        let element = document.createElement('a')
        element.style.display = 'none'

        const data = (props.recentLogins || [])
            .reduce(
                (acc, it) => {
                    return acc + `${it.user}${separator}`
                        + `${it.timestamp}${separator}`
                        + `${getFullDateTimeMsDisplay(it.timestamp, props.ui.timeZone)}${separator}`
                        + `${it.client || "-"}${separator}`
                        + `\r\n`
                },
                `User${separator}`
                    + `Event Time Timestamp${separator}`
                    + `Event Time${separator}`
                    + `Client${separator}`
                    + '\r\n'
            )

        const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })
        const url = URL.createObjectURL(blob)
        element.setAttribute('href', url)

        const extension = (separator === ',' ? 'csv' : 'tsv')
        const accountName = props.accounts.selected.data.name;
        const filename = `${accountName}_Recent_Logins-${getFullDateDisplay(Date.now(), props.ui.timeZone)}.${extension}`
        element.setAttribute('download', filename)

        document.body.appendChild(element)
        await delay(1550)
        element.click()
        await delay(1550)
        document.body.removeChild(element)
        element = null
    }

    async function updateDate(props, type, value, timeframe) {
        if (type === "period") {
            props.onSetState({
                recentLoginsTimeFilters: {...props.recentLoginsTimeFilters, startDate: value.startTime, endDate: value.endTime, timeframe: -1},
                filters: {...props.filters, page: 1}
            });
        } else {
            props.onSetState({
                recentLoginsTimeFilters: {...props.recentLoginsTimeFilters, timeframe: timeframe},
                filters: {...props.filters, page: 1}
            });
        }
    }

    return <Table columns={columns} style={{marginTop: 0, width: "100%"}} pagination={pagination}
        rightFilters={[
            {
                type: "component",
                value: <FilterByDate {...props} key="time_filter" setFilterItem={(type, value, timeframe) => updateDate(props, type, value, timeframe)}/>
            }]}
        total={`Showing ${props.recentLogins?.length} out of ${props.filters.totalElements} total records`}
        export={[
            {func: () => exportData(','), label: "Export CSV"},
            {func: () => exportData('\t'), label: "Export TSV"}]}
    >
        <List>
            {props.recentLogins.map((l, i) => <LoginItem key={i} {...l} timeZone={props.ui.timeZone}/>)}
        </List>
    </Table>;
};

RecentLogins.propTypes = {
    accounts: PropTypes.object,
    filters: PropTypes.object,
    initialize: PropTypes.func,
    onSetState: PropTypes.func,
    recentLogins: PropTypes.array,
    recentLoginsTimeFilters: PropTypes.object,
    status: PropTypes.string,
    ui: PropTypes.object
}

export default RecentLogins;
