import initialState from "../../redux/reducers/data-sources/initial-state";
import { getPath } from "../../lib/utils";
import axiosWrapper from "../../lib/axiosWrapper";
import consoleLogger from "../../lib/consoleLogger";

import * as types from "./types";

export function dataSourcesCreate(
    config: any,
    url: string,
    data = {},
) {
    return function (dispatch: Function): Promise<any> {
        dispatch({
            type: types.DATA_SOURCES_SELECTED_SET,
            payload: {
                status: "pending",
                data: initialState.selected.data,
            },
        });
        return axiosWrapper(config.envApi, url, "put", data)
            .then((res: any) => {
                dispatch({
                    type: types.DATA_SOURCES_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((reason: any): void => {
                dispatch({
                    type: types.DATA_SOURCES_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: initialState.selected.data,
                    },
                });
                consoleLogger.error(reason);
            });
    };
}

export function dataSourcesDelete(
    config: any,
    url: string,
) {
    return function (dispatch: Function): Promise<any> {
        dispatch({
            type: types.DATA_SOURCES_SELECTED_SET,
            payload: {
                status: "pending",
                data: initialState.selected.data,
            },
        });
        return axiosWrapper(config.envApi, url, "delete")
            .then((res: any) => {
                dispatch({
                    type: types.DATA_SOURCES_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: initialState.selected.data,
                    },
                });
                return res;
            })
            .catch((reason: any): void => {
                dispatch({
                    type: types.DATA_SOURCES_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: initialState.selected.data,
                    },
                });
                consoleLogger.error(reason);
                return reason;
            });
    };
}

export function dataSourcesRead(
    config: any,
    url: string,
) {
    return function (dispatch: Function): Promise<any> {
        dispatch({
            type: types.DATA_SOURCES_SELECTED_SET,
            payload: {
                status: "pending",
                data: initialState.selected.data,
            },
        });
        return axiosWrapper(config.envApi, url, "get")
            .then((res: any) => {
                dispatch({
                    type: types.DATA_SOURCES_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((reason: any): void => {
                dispatch({
                    type: types.DATA_SOURCES_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: initialState.selected.data,
                    },
                });
                consoleLogger.error(reason);
            });
    };
}

export function conformanceRead(config, url) {
    return function (dispatch) {
        dispatch({
            type: types.DATA_SOURCES_SELECTED_SET,
            payload: {
                status: "pending"
            }
        });
        return axiosWrapper(config.envApi, url, "get")
            .then(res => {
                let data = getPath(res, "data");
                dispatch({
                    type: types.DATA_SOURCES_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data
                    }
                });
                return res;
            })
            .catch(reason => {
                dispatch({
                    type: types.DATA_SOURCES_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: initialState.selected.data
                    }
                });
                consoleLogger.error(reason);
            });
    };
}

export function dataSourcesReadAll(
    config: any,
    url: string,
) {
    return function (dispatch: Function): Promise<any> {
        dispatch({
            type: types.DATA_SOURCES_ALL_SET,
            payload: {
                status: "pending",
                data: initialState.all.data,
            },
        });
        return axiosWrapper(config.envApi, url, "get")
            .then((res: any) => {
                dispatch({
                    type: types.DATA_SOURCES_ALL_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((reason: any): void => {
                dispatch({
                    type: types.DATA_SOURCES_ALL_SET,
                    payload: {
                        status: "error",
                        data: initialState.all.data,
                    },
                });
                consoleLogger.error(reason);
            });
    };
}

export const dataSourcesUpdate = dataSourcesCreate;
