import { withRouter } from "react-router"
import { connect } from 'react-redux'
import type { State } from 'xstate'
import TextField from '@material-ui/core/TextField'
import * as features from '../../../../../../lib/feature-helpers'
import getGatewaysResourceUrl from '../../../../../../lib/getGatewaysResourceUrl'
import getHostnameInfo from '../../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../../lib/getLocationInfo'
import type { TContext } from '..'

type TProps = {
    config: any
    features: any
    location: any
    state: State<TContext, any, any, any, any>
    send: Function
}

const WStep1 = (props: TProps) => {
    const { accountId } = getHostnameInfo()
    const { env } = getLocationInfo(props.location, props.config)

    const featureSmileCdrCustomization = features.check(':SMILE_CDR_CUSTOMIZATION:', props.features.data)

    return (
        <div style={{ padding: '0 24px' }}>
            <TextField
                name="name"
                value={props.state.context.data.name}
                error={!!props.state.context.dataValidation.name}
                placeholder="Type a name for the gateway"
                label="Display Name"
                helperText={props.state.context.dataValidation.name}
                fullWidth
                onChange={(event) => {
                    props.send('SET_NAME', { name: event.target.value })
                }}
            />

            <TextField
                name="gatewayId"
                value={props.state.context.data.gatewayId}
                error={!!props.state.context.dataValidation.gatewayId}
                placeholder="Type an unique ID"
                label="Gateway ID"
                helperText={props.state.context.dataValidation.gatewayId}
                fullWidth
                style={{ marginTop: '18px' }}
                onChange={(event) => {
                    props.send('SET_GATEWAY_ID', { gatewayId: event.target.value })
                }}
            />

            {featureSmileCdrCustomization ? null : (
                <TextField
                    name="gatewayUri"
                    value={getGatewaysResourceUrl(accountId, env, props.state.context.data.gatewayId, props.config.proxyApi)}
                    label="Gateway URI"
                    fullWidth
                    disabled
                    style={{ marginTop: '18px' }}
                />
            )}

            <TextField
                name="description"
                value={props.state.context.data.description}
                label="Description"
                fullWidth
                multiline
                style={{ marginTop: '18px' }}
                onChange={(event) => {
                    props.send('SET_DESCRIPTION', { description: event.target.value })
                }}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    features: state.features,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(WStep1))
