import * as React from "react";
import * as PropTypes from "prop-types";
import {Button, IconButton, Menu, MenuItem, Select, Typography} from "@material-ui/core";
import {NavigateBefore, NavigateNext} from "@material-ui/icons";
import "./style.less";

const PAGE_SIZES = [10, 50, 100, 500];

class Pagination extends React.Component<any, any> {
    public static propTypes = {
        number: PropTypes.number.isRequired,
        loading: PropTypes.bool.isRequired,
        size: PropTypes.number.isRequired,
        totalElements: PropTypes.number.isRequired,
        goToPage: PropTypes.func.isRequired,
        setPageSize: PropTypes.func.isRequired,
        maxPages: PropTypes.number
    }

    public static defaultProps = {
        maxPages: 7
    };

    public state = {
        paginationOpen: false
    };

    public render() {
        let content = <span/>;
        const {loading, size, number, totalElements} = this.props;
        if (totalElements > 0) {
            const startItem = (number - 1) * size + 1;
            const endItem = Math.min(startItem + size - 1, totalElements);
            const buttonText = loading ? " " : `${startItem} - ${endItem} of ${totalElements}`;
            const totalPages = this.getTotalPages();

            content = <div className="pagination-container">
                <span style={{top: "5px", position: "relative"}}>
                    <IconButton data-rh="Previous Page" disabled={number === 1 || loading} onClick={this.movePage(-1)}>
                        <NavigateBefore/>
                    </IconButton>
                    <Button variant="contained" id="paginaton-button" onClick={this.openPagination} style={{background: "none", boxShadow: "none"}}>
                        {buttonText}
                    </Button>
                    <IconButton data-rh="Next Page" disabled={number >= this.getTotalPages() || loading} onClick={this.movePage(1)}>
                        <NavigateNext/>
                    </IconButton>
                </span>
                <Menu anchorEl={document.getElementById("paginaton-button")} open={this.state.paginationOpen} onClose={this.closePagination}>
                    <div className="pagination-menu-container">
                        <div className="pagination-size">
                            <div className="pagination-menu-page-text">
                                <Typography variant="caption">Page&nbsp;&nbsp;</Typography>
                                <Select className="pagination-select-additional" value={this.props.number} onChange={this.goToSelectedPage}>
                                    {new Array(totalPages).fill(null).map((_item, jdx) => <MenuItem key={`pagination-select-item-${jdx}`} value={jdx + 1}>
                                        {jdx + 1}
                                    </MenuItem>)}
                                </Select>
                                <Typography variant="caption">{` of ${totalPages}`}</Typography>
                            </div>
                            <form className="pagination-menu-page-size-selector">
                                <Typography variant="caption">Records on page&nbsp;&nbsp;</Typography>
                                <Select fullWidth value={this.props.size} onChange={this.setPageSize}>
                                    {PAGE_SIZES.map(size => <MenuItem key={`page-size-${size}`} value={size}>
                                        {size}
                                    </MenuItem>)}
                                </Select>
                            </form>
                        </div>
                        <br/>
                        {this.renderPageSelector()}
                    </div>
                </Menu>
            </div>;
        }

        return content;
    }

    private renderPageSelector = () => {
        const {maxPages, number} = this.props;
        const totalPages = this.getTotalPages() || 0;
        const manyPages = totalPages > maxPages;
        let select = false;
        return <div className="pagination-menu-page-selector-container">
            {
                new Array(totalPages).fill(null).map((_i, idx) => {
                    const num = idx + 1;
                    if (manyPages && idx > 2 && idx && idx < totalPages - 3) {
                        if (!select) {
                            select = true;
                            return <div key={`pagination-dots-${idx}`} className="pagination-dots">...</div>;
                        }
                        return null;
                    }
                    return <Button variant={num === number ? "contained" : "outlined"} classes={{root: "pagination-select-button"}} key={`pagination-button-${idx}`}
                        onClick={this.goToPage(num)} color={num === number ? "primary" : "default"}>
                        {idx + 1}
                    </Button>;
                })
            }
        </div>;
    }

    private openPagination = () => this.setState({paginationOpen: true});

    private closePagination = () => this.setState({paginationOpen: false});

    private getTotalPages = () => {
        const {totalElements, size} = this.props;
        return Math.max(Math.ceil(totalElements / size), 1);
    }

    private movePage = step => () => {
        const minPage = 1;
        const maxPage = this.getTotalPages();
        const newPage = Math.min(Math.max(minPage, this.props.number + step), maxPage);
        this.props.goToPage(newPage);
    }

    private goToPage = num => () => this.setState({paginationOpen: false}, () => this.props.goToPage(num));

    private goToSelectedPage = e => this.setState({paginationOpen: false}, () => this.props.goToPage(e.target.value));

    private setPageSize = e => this.setState({paginationOpen: false}, () => this.props.setPageSize(parseInt(e.target.value)));
}

export default Pagination;
