import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import * as validateData from "../../../../../../../../../lib/validate-cds-service-set-activations-data";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import CdsServiceGatewayActivations from "../../../../Shared/CdsServiceGatewayActivations";

class WStep1 extends React.Component<any, any> {
    public static propTypes = {
        cdsServiceSets: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        gateways: PropTypes.object.isRequired,
        wstate: PropTypes.object.isRequired,
        onSetWState: PropTypes.func.isRequired,
    };

    public componentDidMount() {
        this.props.onSetWState(
            {
                dataValidation: {},
                isNextWStepEnabled: false,
            },
            this.validateData,
        );
    }

    public componentDidUpdate(prevProps) {
        const data_string = JSON.stringify(this.props.wstate.data);
        const prevData_string = JSON.stringify(prevProps.wstate.data);
        if (data_string !== prevData_string) {
            this.validateData();
        }
    }

    public render() {
        const gateways = this.props.gateways?.all?.data || [];
        const activationsAll = this.props.cdsServiceSets?.selected?.data?.activations || [];
        const activationsAllGatewayIds = activationsAll.map((activation) => activation.gatewayId);
        const filteredGateways = gateways.filter((gateway) => !activationsAllGatewayIds.includes(gateway.gatewayId));

        return <>
            <TextField
                name="name" value={this.props.wstate.data.name || ""}
                placeholder="Display name" label="Display Name" helperText={this.props.wstate.dataValidation.name}
                error={!!this.props.wstate.dataValidation.name}
                disabled={this.props.wstate.status !== "ready"}
                autoComplete="off" autoFocus={false} fullWidth
                style={{ marginTop: 16 }}
                onChange={(event) => this.props.onSetWState((prevWState) => ({
                    data: {
                        ...prevWState.data,
                        name: event.target.value,
                    },
                }))}
            />
            <FormControl
                disabled={this.props.wstate.status !== "ready"}
                error={!!this.props.wstate.dataValidation.gateway}
                style={{ minWidth: 280, marginTop: 32 }}
            >
                <InputLabel id="cds-gateway-label">
                    Gateway
                </InputLabel>
                <Select
                    labelId="cds-gateway-select"
                    id="cds-gateway-select"
                    value={this.props.wstate.data.gatewayId || ""}
                    onChange={(event) => this.props.onSetWState((prevWState) => ({
                        data: {
                            ...prevWState.data,
                            gatewayId: event.target.value,
                        },
                    }))}
                >
                    {filteredGateways.map(({ gatewayId, name }, index) => (
                        <MenuItem key={`gateway-item-${gatewayId}-${index}`} value={gatewayId}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                    {this.props.wstate.dataValidation.gateway}
                </FormHelperText>
            </FormControl>
            <CdsServiceGatewayActivations canEdit wstate={this.props.wstate} onSetWState={this.props.onSetWState} />
        </>;
    }

    private isDataValid = () => {
        const dataValidation = {
            ...this.props.wstate.dataValidation,
            overrideIds: undefined,
        };
        const isValid = !Object.keys(dataValidation)
            .map(key => dataValidation[key])
            .some(value => !!value);
        const overrideIds = this.props.wstate.dataValidation?.overrideIds || {};
        const areOverrideIdsValid = !Object.keys(overrideIds)
            .map((key) => overrideIds[key])
            .some((value) => !!value);

        return isValid && areOverrideIdsValid;
    }

    private isNextWStepEnabled = () => {
        const isValid = this.isDataValid();
        if (isValid !== this.props.wstate.isNextWStepEnabled) {
            this.props.onSetWState({isNextWStepEnabled: isValid});
        }
    };

    private validateData = () => {
        const dataValidation = validateData.onWStep1(this.props.wstate, this.props.config);
        this.props.onSetWState(
            {dataValidation},
            this.isNextWStepEnabled,
        );
    };
}

const mapStateToProps = (state, ownProps) => ({
    cdsServiceSets: state.cdsServiceSets,
    config: state.config,
    gateways: state.gateways,
    ...ownProps,
});
export default connect(mapStateToProps)(WStep1);
