import * as React from "react";
import * as PropTypes from "prop-types";
import {ArrowUpward, ArrowDownward} from "@material-ui/icons";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    switch (props.down) {
        case true:
            return <ArrowDownward className="sorting-icon"/>;
        case false:
            return <ArrowUpward className="sorting-icon"/>;
        default:
            return <ArrowDownward className="sorting-icon hidden"/>;
    }
}

Component.propTypes = {
    down: PropTypes.bool
}

export default Component;
