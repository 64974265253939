import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { createMachine } from 'xstate'
import { useMachine } from '@xstate/react'
import getAppsCallUrl from '../../../../../lib/getAppsCallUrl'
import getDataSourcesCallUrl from '../../../../../lib/getDataSourcesCallUrl'
import getGatewaysCallUrl from '../../../../../lib/getGatewaysCallUrl'
import getLogoStringType from '../../../../../lib/getLogoStringType'
import getHostnameInfo from '../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../lib/getLocationInfo'
import * as actions from '../../../../../redux/actions'
import { INIT_STATE as initParentState } from '..'

type TProps = {
    apps: any
    config: any
    dataSources: any
    gateways: any
    location: any
    ui: any
    parentState: {
        status: string
    }
    appsRead: Function
    appsReadAll: Function
    dataSourcesReadAll: Function
    gatewaysReadAll: Function
    onSetParentState: Function
}

const InitMachine = (props: TProps) => {
    const [state, send] = useMachine(() => createMachine({
        id: 'init',
        initial: 'start',
        context: {},
        states: {
            start: {
                on: {
                    SEND_REQUESTS: 'sending_requests',
                },
            },
            sending_requests: {
                on: {
                    PROCESS_DATA: 'wait_redux',
                },
            },
            wait_redux: {
                after: {
                    200: { target: 'processing_data' }
                },
            },
            processing_data: {
                on: {
                    DONE: 'idle',
                },
            },
            idle: {
                on: {
                    RESTART: 'start',
                },
            },
        },
    }))

    const {
        appsRead,
        appsReadAll,
        dataSourcesReadAll,
        gatewaysReadAll,
        onSetParentState,
    } = props

    // start
    React.useEffect(() => {
        if (state.matches('start')) {
            if (props.parentState.status === 'init') {
                send('SEND_REQUESTS')
            }
        }
    }, [props.parentState.status, send, state])

    // sending_requests
    React.useEffect(() => {
        if (state.matches('sending_requests')) {
            const { accountId } = getHostnameInfo()
            const { env, appId } = getLocationInfo(props.location, props.config)

            const appsAllStatus = props.apps.all.status
            if (!['ready', 'pending'].includes(appsAllStatus)) {
                const appsUrl = getAppsCallUrl(accountId, env)
                appsReadAll(props.config, appsUrl)
            }

            const appsSelectedStatus = props.apps.selected.status
            if (![/* 'ready', */ 'pending'].includes(appsSelectedStatus)) {
                const appUrl = `${getAppsCallUrl(accountId, env)}/${appId}`
                appsRead(props.config, appUrl)
            }

            const dataSourcesAllStatus = props.dataSources.all.status
            if (!['ready', 'pending'].includes(dataSourcesAllStatus)) {
                const dsUrl = getDataSourcesCallUrl(accountId, env)
                dataSourcesReadAll(props.config, dsUrl)
            }

            const gatewaysAllStatus = props.gateways.all.status
            if (![/* 'ready', */'pending'].includes(gatewaysAllStatus)) {
                const gatewaysUrl = getGatewaysCallUrl(accountId, env)
                gatewaysReadAll(props.config, `${gatewaysUrl}?shallow=true`)
            }

            send('PROCESS_DATA')
        }
    }, [
        appsRead,
        appsReadAll,
        dataSourcesReadAll,
        gatewaysReadAll,
        props.apps.all.status,
        props.apps.selected.status,
        props.config,
        props.dataSources.all.status,
        props.gateways.all.status,
        props.location,
        send,
        state,
    ])

    // processing_data
    React.useEffect(() => {
        if (state.matches('processing_data')) {
            (async () => {
                const appsAllStatus = props.apps.all.status
                const appsSelectedStatus = props.apps.selected.status
                const dataSourcesAllStatus = props.dataSources.all.status
                const gatewaysAllStatus = props.gateways.all.status
                if (
                    appsAllStatus === 'ready'
                    && appsSelectedStatus === 'ready'
                    && dataSourcesAllStatus === 'ready'
                    && (props.ui.tabs.selected !== 2 || gatewaysAllStatus === 'ready')
                ) {
                    const logoUrl = props.apps.selected.data?.logoUrl || ''
                    const logoStringType = getLogoStringType(logoUrl)
                    onSetParentState((prevState) => ({
                        ...initParentState,
                        status: 'ready',
                        data: {
                            smartLaunchProxyInfo: {},
                            logs: [],
                            logsPagination: {},
                            loadingLogs: true,
                            ...props.apps.selected.data,
                        },
                        logoStringType,
                        selectedSessionId: prevState.selectedSessionId ?? initParentState.selectedSessionId,
                        selectedMetadata: prevState.selectedMetadata ?? initParentState.selectedMetadata,
                    }), () => {
                        send('DONE')
                    })
                }
            })()
        }
    }, [
        onSetParentState,
        props.apps.all.status,
        props.apps.selected.data,
        props.apps.selected.status,
        props.dataSources.all.status,
        props.gateways.all.status,
        props.ui.tabs.selected,
        send,
        state,
    ])

    // idle
    React.useEffect(() => {
        if (state.matches('idle')) {
            if (props.parentState.status === 'init') {
                send('RESTART')
            }
        }
    }, [props.parentState.status, send, state])

    // console.log('::: current state:', state.value)

    return null
}

const mapStateToProps = (state, ownProps) => ({
    apps: state.apps,
    config: state.config,
    dataSources: state.dataSources,
    gateways: state.gateways,
    ui: state.ui,
    ...ownProps,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appsRead: actions.appsRead,
    appsReadAll: actions.appsReadAll,
    dataSourcesReadAll: actions.dataSourcesReadAll,
    gatewaysReadAll: actions.gatewaysReadAll,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InitMachine))
