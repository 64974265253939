import * as React from "react";
import * as PropTypes from "prop-types";

import withLegacyTheme from "../../../../../../../../../lib/hoc/with-legacy-theme";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import AppUserToFhirUser from "../../../../Shared/AppUserToFhirUser"

class ETab1 extends React.Component<any, any> {
    public static propTypes = {
        muiTheme: PropTypes.object.isRequired,
        estate: PropTypes.object.isRequired,
        onSetEState: PropTypes.func.isRequired,
    };

    public constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
        };
    }

    public render() {
        const canEdit = this.props.estate.mode === ":EDIT:";

        return <div style={{ padding: "0 24px 24px 24px" }}>
            {this.renderName(canEdit)}
            {this.renderEmail(/* canEdit */)}
            {this.renderPassword(canEdit)}
            {this.renderPasswordMessage(canEdit)}
            {this.renderAppUserType(canEdit)}
            <AppUserToFhirUser
                canEdit={canEdit} showTitle={true}
                parentState={this.props.estate}
                onSetParentState={this.props.onSetEState}
            />
        </div>;
    }

    private renderName = (canEdit) => {
        return <TextField data-qa="user-name"
            name="name" value={this.props.estate.data.name || ""}
            placeholder="Display name" label="Display Name" helperText={this.props.estate.dataValidation.name}
            error={!!this.props.estate.dataValidation.name} disabled={!canEdit || this.props.estate.status !== "ready"}
            autoComplete="off" autoFocus={false} fullWidth
            classes={{ root: canEdit ? "" : "disabled-text-field" }}
            style={{ marginTop: 16 }}
            onChange={canEdit ? (event) => this.props.onSetEState((prevEState) => ({ data: { ...prevEState.data, name: event.target.value } })) : undefined}
        />;
    };

    // IO-3029: prevent the email from being an editable field
    private renderEmail = (/* canEdit */) => {
        return <TextField data-qa="user-email"
            name="email" value={this.props.estate.data.email || ""}
            placeholder="Email" label="Email" helperText={this.props.estate.dataValidation.email}
            // error={!!this.props.estate.dataValidation.email} disabled={!canEdit || this.props.estate.status !== "ready"}
            autoComplete="off" autoFocus={false} fullWidth
            // classes={{ root: canEdit ? "" : "disabled-text-field" }}
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
            // onChange={canEdit ? (event) => this.props.onSetEState((prevEState) => ({ data: { ...prevEState.data, email: event.target.value } })) : undefined}
        />;
    };

    private renderPassword = (canEdit) => {
        return canEdit
            ? <FormControl
                error={!!this.props.estate.dataValidation.password}
                fullWidth style={{ marginTop: 16, marginBottom: 16 }}
            >
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input data-qa="user-password"
                    id="password" name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.props.estate.data.password || ""}
                    placeholder="Password" autoComplete="password" fullWidth
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => this.setState((prevState) => ({ showPassword: !prevState.showPassword }))}
                                onMouseDown={(event) => event.preventDefault()}
                            >
                                {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={(event) => this.props.onSetEState((prevEState) => ({
                        data: {
                            ...prevEState.data,
                            password: event.target.value,
                        },
                    }))}
                />
                <FormHelperText>
                    {this.props.estate.dataValidation.password}
                </FormHelperText>
            </FormControl>
            : <TextField data-qa="user-password"
                name = "password" value = "●●●●●●●●"
                placeholder="Password" label="Password"
                disabled
                autoComplete="off" autoFocus={false} fullWidth
                classes={{ root: "disabled-text-field" }}
                style={{ marginTop: 16 }}
            />
    }

    private renderPasswordMessage = (canEdit) => {
        const password = this.props.estate.data.password;
        const showMessage = !!canEdit && !!password;

        return <div data-qa="user-password-message" style={{
            height: showMessage ? "auto" : 0,
            marginTop: showMessage ? 16 : 0,
            padding: showMessage ? 8 : 0,
            transform: showMessage ? "scaleY(1)" : "scaleY(0)",
            transformOrigin: "top",
            borderRadius: showMessage ? 2 : 0,
            background: this.props.muiTheme.palette.primary1Color,
            color: "#fff",
            textAlign: "center",
            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
        }}>
            User password will be changed
        </div>;
    }

    private renderAppUserType = (canEdit) => {
        return canEdit
            ? <FormControl
                disabled={this.props.estate.status !== "ready"}
                error={!!this.props.estate.dataValidation.gateway}
                style={{ minWidth: 280, marginTop: 16, marginBottom: 16 }}
            >
                <InputLabel id="new-user-type-select-label">
                    User Type
                </InputLabel>
                <Select data-qa="user-type"
                    labelId="new-user-type-select-label"
                    id="new-user-type-select"
                    value={this.props.estate.data.appUserType || ""}
                    onChange={(event) => this.props.onSetEState((prevEState) => ({
                        data: {
                            ...prevEState.data,
                            appUserType: event.target.value,
                        },
                    }))}
                >
                    <MenuItem data-qa="user-type-provider" value={"PROVIDER"}>
                        Provider
                    </MenuItem>
                    <MenuItem data-qa="user-type-patient" value={"PATIENT"}>
                        Patient
                    </MenuItem>
                </Select>
            </FormControl>
            : (() => {
                let value = "";
                switch (this.props.estate.data.appUserType) {
                    case "PATIENT": value = "Patient"; break;
                    default: value = "Provider";
                }
                return <TextField data-qa="user-type"
                    name="userAppType" value={value}
                    placeholder="User type" label="User Type"
                    disabled
                    autoComplete="off" autoFocus={false} fullWidth
                    classes={{ root: "disabled-text-field" }}
                    style={{ marginTop: 16 }}
                />
            })();
    }
}

export default withLegacyTheme()(ETab1);
