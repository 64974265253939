import {CardBodyAddNew, CardCustomContent, CardImage, CardMenuBar, CardsContainer, CardWrapper} from "../../../../Widgets/CustomCard";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Label from "@material-ui/icons/Label";
import FhirIcon from "../../../../Widgets/icons/Fhir";
import * as React from "react";
import * as PropTypes from "prop-types";
import getLocationInfo from "../../../../../lib/getLocationInfo";
import checkDAVendor from "../../../../../lib/checkDAVendor";

const DSCardsList = props => {
    return <CardsContainer>
        {props.actAsAdmin
            ? <CardWrapper data-qa="create-data-adapter-card" style={{minHeight: 200}}>
                <CardBodyAddNew text="Add Data Adapter" onClick={() => props.onSetState({dialog: ":ADD_NEW_WIZARD:"})}/>
            </CardWrapper>
            : null}

        {props.data.map((item, index) =>
            <CardWrapper data-qa={`data-adapter-card-${item.dataSourceId || ("cdr:" + item.cdrId)}`} key={`card-${index}-${item.dataSourceId || ("cdr:" + item.cdrId)}`}
                style={{minHeight: 200, background: item.dataSourceId ? "" : props.muiTheme.palette.borderColor}}>
                <CardMenuBar data-qa={`data-adapter-menu-${item.dataSourceId}`} title={item.name} style={{paddingBottom: 0}} onClick={() => onClickListItem(props, item)}
                    menuItems={(props.actAsAdmin && item.dataSourceId)
                        ? [
                            {
                                leftIcon: <VisibilityIcon />,
                                text: "View",
                                "data-qa": "data-adapter-view",

                                onClick: () => onClickListItem(props, item),
                            },
                            {
                                leftIcon: <DeleteIcon />,
                                text: "Delete",
                                "data-qa": "data-adapter-delete",
                                onClick: () => props.onSetState({
                                    dialog: ":DELETE_CONFIRM:",
                                    delete: {
                                        dataSourceId: item.dataSourceId,
                                        name: item.name,
                                    },
                                }),
                            }
                        ]
                        : []}/>
                <CardImage src={item.logoUrl} config={props.config} style={{maxHeight: 124, overflow: "hidden", textAlign: "center"}}
                    imgStyle={{width: "auto", maxWidth: 200, maxHeight: 84, borderRadius: 6}} onClick={() => onClickListItem(props, item)}/>
                <CardCustomContent style={{paddingTop: 0, paddingBottom: 10, display: "flex", alignItems: "center", flexWrap: "wrap-reverse"}} onClick={() => onClickListItem(props, item)}>
                    {[
                        <div key="gateway-vendor" style={{display: "inline-block", marginRight: 2, marginBottom: 2, width: 128}}>
                            <div style={{display: "flex"}}>
                                <img src={getLogoUrl(item.type)} style={{width: "auto", maxWidth: 98, maxHeight: 36, borderRadius: 6}}/>
                            </div>
                        </div>,
                        <div key="gateway-type" style={{display: "inline-block", marginRight: 2, marginBottom: 2, width: 128}}>
                            <Chip
                                avatar={<Avatar style={{ width: 32, height: 32, margin: 0 }}><FhirIcon style={{ color: "white" }} /></Avatar>}
                                label={<p>{{
                                    "FHIR_DSTU2__1_0_2": "DSTU2",
                                    "FHIR_STU3__3_0_1": "STU3",
                                    "FHIR_STU3__3_0_2": "STU3",
                                    "FHIR_R4__4_0_0": "R4",
                                    "FHIR_R4__4_0_1": "R4",
                                    "HL7_V2": "HL7 v2.x Listening Endpoint",
                                }?.[item.fhirVersion || item.configuration.fhirVersion || item.supportedGatewayTypes[0]] || "--"}</p>}
                                style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%" }}
                            />
                        </div>,
                        item.type === "DataSourceIKnowMedR4External" ? <div style={{ marginBottom: 8 }}>
                            <Chip
                                avatar={<Avatar style={{ width: 32, height: 32, margin: 0 }}><Label style={{ color: "white" }} /></Avatar>}
                                label={<p>{{
                                    "NONE": "NONE",
                                    "ONTADA_INTERNAL": "Ontada Internal",
                                    "G_10": "G10",
                                    "MARKETPLACE": "Marketplace",
                                }?.[item.programType] || "--"}</p>}
                                style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%" }}
                            />
                        </div> : null,
                    ]}
                </CardCustomContent>
            </CardWrapper>
        )}
    </CardsContainer>
}

const onClickListItem = (props, item) => {
    const {env} = getLocationInfo(props.location, props.config);
    return item.dataSourceId
        ? props.history.push(`/${env}/datasources/${item.dataSourceId}`)
        : props.history.push(`/${env}/cdrs/${item.cdrId}`);
};

const getLogoUrl = type => {
    let logoUrl = undefined;
    const vendor = checkDAVendor(type)
    switch (vendor) {
        case "fhir": logoUrl = "/console/img/da-logo-fhir.png"; break;
        case "logica": logoUrl = "/console/img/da-logo-logica.png"; break;
        case "allscripts": logoUrl = "/console/img/da-logo-allscripts.png"; break;
        case "cerner": logoUrl = "/console/img/da-logo-cerner.png"; break;
        case "epic": logoUrl = "/console/img/da-logo-epic.png"; break;
        case "flatiron": logoUrl = "/console/img/da-logo-flatiron.png"; break;
        case "iknowmed": logoUrl = "/console/img/da-logo-iknowmed.png"; break;
    }
    return logoUrl
};

DSCardsList.propTypes = {
    actAsAdmin: PropTypes.bool,
    onSetState: PropTypes.func,
    data: PropTypes.array,
    muiTheme: PropTypes.object,
    location: PropTypes.object,
    config: PropTypes.object,
    history: PropTypes.object
}

export default DSCardsList;
