import * as React from "react";
import * as PropTypes from "prop-types";
import {IconButton, Tooltip} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import Header from "../../../../../../Widgets/Editor/Header";
import ETab0 from "./ETab0";
import {Edit} from "@material-ui/icons";

const Component: React.FunctionComponent<any> = props => {

    return <div>
        {renderHeader(props)}
        {renderETabContent(props)}
    </div>;
}

function renderHeader(props) {
    const canEdit = props.state.mode === ":EDIT:";

    return <Header {...props}
        rightIcon={!canEdit
            ? <Tooltip title="Edit">
                <IconButton onClick={() => props.onSetState({mode: ":EDIT:"})}>
                    <Edit style={{color: props.muiTheme.palette.secondaryTextColor}} />
                </IconButton>
            </Tooltip>
            : null}/>;
}

function renderETabContent(props) {
    return <SwipeableViews index={props.state.etab} onChangeIndex={(etab) => props.onSetState({etab})}>
        <div><ETab0 {...props} /></div>
        <div/>
    </SwipeableViews>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
