import * as React from "react";
import * as PropTypes from "prop-types";
import {getPath} from "../../../../../../../lib/utils/";
import {CircularProgress} from "@material-ui/core";
import "./style.less";
import colors from "../../../../../../../lib/legacy-color-palette";
import {getNumberLocaleString} from "../../../../../../../lib/utils";

const Recharts = require("recharts");
const {ResponsiveContainer, CartesianGrid, Tooltip, XAxis, YAxis, Bar, BarChart, Legend, Cell} = Recharts;

const Component = props => {
    if (props.data === null) {
        return <div>
            <div style={{padding: "20px 20px 10px", color: "gray"}}>{props.title}</div>
            <div className="chart-card-text loading" style={props.styles.cardChartText}>
                <CircularProgress/>
            </div>
        </div>;
    }

    let entries = getPath(props, "data.entries");

    if (props.sortBy === "name") {
        entries.sort((a, b) => a.name.localeCompare(b.name))
    }

    if (props.sortBy === "count") {
        entries.sort((a, b) => {
            let totalA = 0;
            let totalB = 0;
            props.data.series.forEach(series => {
                if (a[series] !== undefined) {
                    totalA += a[series];
                }
                if (b[series] !== undefined) {
                    totalB += b[series];
                }
            })
            if (totalA < totalB)
                return 1;
            if (totalA > totalB)
                return -1;
            return 0;
        })
    }

    let minHeight = "300px";
    let maxHeight = "400px";
    let length = entries.length;
    if (length > 5 && length < 10) {
        minHeight = "500px";
        maxHeight = "600px";
    } else if (length >= 10 && length < 15) {
        minHeight = "600px";
        maxHeight = "700px";
    } else if (length >= 15 && length < 20) {
        minHeight = "700px";
        maxHeight = "800px";
    } else if (length >= 20 && length < 25) {
        minHeight = "800px";
        maxHeight = "900px";
    } else if (length >= 25 && length < 30) {
        minHeight = "900px";
        maxHeight = "1000px";
    } else if (length >= 30 && length < 35) {
        minHeight = "1000px";
        maxHeight = "1100px";
    } else if (length >= 35 && length < 40) {
        minHeight = "1100px";
        maxHeight = "1200px";
    } else if (length >= 40 && length < 45) {
        minHeight = "1200px";
        maxHeight = "1300px";
    } else if (length >= 45 && length < 50) {
        minHeight = "1300px";
        maxHeight = "1400px";
    } else if (length >= 50) {
        minHeight = "1400px";
        maxHeight = "1500px";
    }

    return <div style={{margin: "0 auto"}}>
        <div style={{padding: "20px 20px 10px", color: "gray"}}>{props.title}</div>
        <ResponsiveContainer width="99%" minHeight={minHeight} maxHeight={maxHeight} height="90%">
            <BarChart width={800} data={entries} maxBarSize={100} barSize={50} barGap={10} layout="vertical" margin={{top: 20, right: 20, bottom: 10, left: 180}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <YAxis dataKey="name" interval={0} type="category" tick={{fontSize: "13px"}}
                    tickFormatter={name => {
                        let tick;
                        const combination = formatLabelValue(name);
                        combination.length > 32 ? tick = combination.substring(0, 32) + "" : tick = combination;
                        return tick;
                    }}/>
                <XAxis allowDecimals={false} type="number" unit={props.type === "latency" ? "ms" : ""}
                    tickFormatter={value => {
                        return `${getNumberLocaleString(value)}`
                    }}/>
                <Tooltip labelStyle={{fontSize: "14px"}} itemStyle={{fontSize: "14px"}}
                    labelFormatter={name => formatLabelValue(name)}
                    formatter={(value) => {
                        if (props.type === "latency") {
                            return `${getNumberLocaleString(value)}ms`
                        }
                        return getNumberLocaleString(value)
                    }}
                />
                {props.legend && <Legend/>}
                {props.data.series.slice(0, 49).map(item => {
                    if (props.type === "latency") {
                        return <Bar key={item} dataKey={item} stackId="const" fill={props.state.assignedColors[item]}>
                            {entries.map((entry, idx) => {
                                let color = props.state.assignedColors[item];
                                if (entry.latency >= 250 && entry.latency < 500) {
                                    color = colors.yellow700;
                                } else if (entry.latency >= 500) {
                                    color = colors.red700;
                                }
                                return <Cell key={idx} fill={color}/>;
                            })}
                        </Bar>
                    }
                    return <Bar key={item} dataKey={item} stackId="const" fill={props.state.assignedColors[item]}/>
                })}
            </BarChart>
        </ResponsiveContainer>
    </div>;
}

function formatLabelValue(name) {
    let combination = "";
    if (name.indexOf(".") > -1) {
        let nameParts = name.split(".");
        nameParts[0] === "null" ? combination += "OTHER" : combination += nameParts[0];
        combination += ".";
        nameParts[1] === "null" ? combination += "OTHER" : combination += nameParts[1];
        if (nameParts[1] === "BATCH_TX") {
            combination = "BATCH_TX";
        }
    } else {
        combination = name;
    }
    return combination;
}

Component.propTypes = {
    state: PropTypes.object.isRequired,
    data: PropTypes.object,
    styles: PropTypes.object.isRequired,
    topResources_refresh: PropTypes.func.isRequired,
    sortBy: PropTypes.string,
    legend: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string
};

Component.defaultProps = {
    legend: true,
    title: "Top Resources",
    sortBy: "count"
};

export default Component;
