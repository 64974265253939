import * as PropTypes from "prop-types";
import * as React from 'react';
import * as validateData from "../../../../../../lib/validate-portal-app-custom-context-data";
import {useState} from "react";
import CustomContextListItem from './CustomContextListItem';
import Table from '../../../../../Widgets/Table';
import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import "./style.less";

interface CustomContext {
    contextKey: string;
    contextValue: string;
    dataValidation: {
        newKey: string,
        newValue: string
    }
}

const CustomContexts = (props) => {
    let customContextsList = []
    const { portalType } = props.portals.selected.data
    if(portalType === 'PROVIDER') {
        customContextsList = props.state.data.providerPortalCustomContexts || []
    }
    else {
        customContextsList = props.state.data.patientPortalCustomContexts || []
    }

    const [customContext, setCustomContext] = useState<CustomContext>({
        contextKey: "",
        contextValue: "",
        dataValidation: {
            newKey: "",
            newValue: ""
        }
    });

    const addNewCustomContext = () => {
        let newCustomContext = {
            contextKey: customContext.contextKey,
            contextValue: customContext.contextValue,
            providerPortalContextId: undefined || '',
            patientPortalContextId: undefined || '',
        }
        props.onSetState({
            data: {
                ...props.state.data,
                ...(portalType === 'PROVIDER' && {
                    providerPortalCustomContexts: [
                        ...props.state.data.providerPortalCustomContexts || [],
                        {
                            ...newCustomContext,
                            providerPortalContextId: props.state.data.providerPortalContextId,
                        },
                    ]
                }),
                ...(portalType === 'PATIENT' && {
                    patientPortalCustomContexts: [
                        ...props.state.data.patientPortalCustomContexts || [],
                        {
                            ...newCustomContext,
                            patientPortalContextId: props.state.data.patientPortalContextId,
                        },
                    ],
                }),
            },
        })
        setCustomContext(prevState => ({
            ...prevState,
            contextKey: "",
            contextValue: "",
            dataValidation: {
                ...prevState.dataValidation,
                newKey: "",
                newValue: ""
            }
        }))
    }

    const columns = [
        {
            size: 1,
            name: "contextKey",
            label: "Key",
            centered: false,
        },
        {
            size: 1,
            name: "contextValue",
            label: "Value",
            centered: false,
        },
        {
            size: 0.3,
        }
    ]

    return (
        <div className="custom-contexts">
            <h4 style={{
                marginBottom: 0,
                color: props.muiTheme.palette.secondaryTextColor
            }}>
                Custom Contexts
            </h4>

            <div className="new-custom-context MuiPaper-rounded MuiPaper-outlined">
                <div className="new-custom-context-header">
                    <h5>Add New</h5>
                    <Fab
                        data-e2e='add-custom-context-btn'
                        size="small"
                        disabled={(!customContext?.contextKey
                            || !customContext?.contextValue
                            || !!customContext?.dataValidation?.newKey
                            || !!customContext?.dataValidation?.newValue
                        )}
                        onClick={() => {
                            addNewCustomContext();
                        }}
                    >
                        <AddIcon />
                    </Fab>
                </div>

                <TextField
                    name="addNewCustomContextName"
                    data-e2e='add-custom-context-key'
                    label="Key"
                    value={customContext?.contextKey || ''}
                    helperText={customContext?.dataValidation?.newKey}
                    error={!!customContext?.dataValidation?.newKey}
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    onChange={(event) => {
                        let newCustomContextKey = event.target.value
                        const portalCustomContexts = portalType === 'PROVIDER'
                            ? props.state.data.providerPortalCustomContexts
                            : props.state.data.patientPortalCustomContexts
                        let validKey = event.target.value ?
                            validateData.onChangeCustomContextKey(portalCustomContexts, newCustomContextKey)
                            : "";
                        setCustomContext(prevState => ({
                            ...prevState,
                            contextKey: newCustomContextKey,
                            dataValidation: {
                                ...prevState.dataValidation,
                                newKey: validKey
                            }
                        }))
                    }}
                />

                <TextField
                    name="addNewCustomContextValue"
                    data-e2e='add-custom-context-value'
                    label="Value"
                    value={customContext?.contextValue || ''}
                    helperText={customContext?.dataValidation?.newValue}
                    error={!!customContext?.dataValidation?.newValue}
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    onChange={(event) => {
                        let newCustomContextValue = event.target.value;
                        let validValue = event.target.value ?
                            validateData.onChangeCustomContextValue(newCustomContextValue)
                            : "";
                        setCustomContext(prevState => ({
                            ...prevState,
                            contextValue: newCustomContextValue,
                            dataValidation: {
                                ...prevState.dataValidation,
                                newValue: validValue
                            }
                        }))
                    }}
                />
            </div>

            <Table columns={columns}>
                {customContextsList.map((context, index) => (
                    <CustomContextListItem
                        key={`custom-context-${index}`}
                        data-e2e={`custom-context-itm-${context.contextKey}`}
                        contextKey={context.contextKey}
                        contextValue={context.contextValue}
                        created={context.created}
                        updated={context.updated}
                        itemIndex={index}
                        state={props.state}
                        onSetState={props.onSetState}
                    />
                ))}
            </Table>
        </div>
    )
}

CustomContexts.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    addNewCustomContext: PropTypes.func.isRequired,
    portals: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired,
}

export default CustomContexts
