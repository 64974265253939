import * as React from "react";
import * as PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import {Search} from "@material-ui/icons";

let timeout;

class SearchField extends React.Component<any, any> {
    public static propTypes = {
        setFilterItem: PropTypes.func.isRequired,
        hint: PropTypes.string
    };

    public constructor(props) {
        super(props);
        this.state = { term: "" };
    }

    public render() {
        return <div>
            <Search style={{ marginRight: "10px", top: "12px", position: "relative" }} />
            <TextField name="logs" value={this.state.term || ""} placeholder={!this.state.term ? this.props.hint : ""} style={{ top: "10px", height: "46px", borderRadius: "2px" }}
                onChange={e => {
                    this.setState({ term: e.target.value }, () => {
                        if (timeout) {
                            clearTimeout(timeout);
                        }
                        if (e.target.value.length > 2) {
                            timeout = setTimeout(() => {
                                this.props.setFilterItem("searchTerms", e.target.value.split(" "));
                            }, 1250);
                        } else if (e.target.value.length === 0) {
                            this.props.setFilterItem("searchTerms", undefined);
                        }
                    })
                }} />
        </div>;
    }
}

export default SearchField
