declare const _;
import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import getCdsServiceSetsCallUrl from "../../../../lib/getCdsServiceSetsCallUrl";
import getGatewaysCallUrl from "../../../../lib/getGatewaysCallUrl";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../lib/getLocationInfo";
import {Link} from "react-router-dom";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import {NavbarL2, NavbarL3} from "../../../CustomNavbar/";
import Selector from "../../../CustomNavbar/Selector";
import Activations from "./Activations/";
import Details from "./Details/";
import Dialogs from "./Dialogs/";
import Usage from "./Usage/";
import IconCdsHooks from "../../../Widgets/icons/CdsHooks";

const TABS = [
    {title: "DETAILS"},
    {title: "ACTIVATIONS"},
    {title: "USAGE"},
];

const TAB_SELECTED_BY_DEFAULT = 0;

const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "waiting" | "recalc"
    data: {},
    dataValidation: {},
    gatewayJwkSet: {},
    activations: [],
    selectedActivation: {},
    filters: {
        name: "",
    },
    sortBy: {
        name: true,
    },
    mode: ":VIEW:", // ":VIEW:" | ":EDIT:"
    dialog: "" // "" | ":ADD_ACTIVATION:" | ":EDIT_ACTIVATION:" | ":DELETE_ACTIVATION_CONFIRM:"
};

class CdsServiceSetEdit extends React.Component<any, any> {
    public static propTypes = {
        cdsServiceSets: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,
        cdsServiceSetsRead: PropTypes.func.isRequired,
        gatewaysReadAll: PropTypes.func.isRequired,
        getGatewayJwkSet: PropTypes.func.isRequired,
        uiTabsSetItems: PropTypes.func.isRequired,
        uiTabsSetSelected: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.props.uiTabsSetItems(TABS);
        if (this.props.ui.tabs.selected !== TAB_SELECTED_BY_DEFAULT) {
            this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
        } else {
            this.initialize();
        }
    }

    public componentDidUpdate(prevProps, prevState) {
        const selectedTab = this.props.ui?.tabs?.selected;
        const prevSelectedTab = prevProps.ui?.tabs?.selected;

        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.ui.tabs.selected !== TAB_SELECTED_BY_DEFAULT) {
                this.props.uiTabsSetSelected(TAB_SELECTED_BY_DEFAULT);
            } else {
                this.initialize();
            }
        }

        if (selectedTab !== prevSelectedTab) {
            this.initialize();
        }

        const filters_string = JSON.stringify(this.state.filters);
        const prevFilters_string = JSON.stringify(prevState.filters);
        const areFiltersChanged = filters_string !== prevFilters_string;
        const sortBy_string = JSON.stringify(this.state.sortBy);
        const prevSortBy_string = JSON.stringify(prevState.sortBy);
        const isSortTermChanged = sortBy_string !== prevSortBy_string;
        if (areFiltersChanged || isSortTermChanged) {
            if (this.state.status !== "recalc") {
                this.setState(
                    {status: "recalc"},
                    this.setActivations
                );
            } else {
                this.setActivations();
            }
        }
    }

    public render() {
        const {env, cdsServiceSetId: selectedCdsServiceSetId} = getLocationInfo(this.props.location, this.props.config);

        return <div style={{width: "100%"}}>
            <NavbarL2 title={(
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{height: 56, marginRight: 24}}>
                        <Link to={`/${env}/cds-service-sets`} style={{color: "#fff", fontSize: 24, textDecoration: "none"}}>
                            CDS Hooks
                        </Link>
                    </div>
                    <Selector data-qa="cds-service-selector"
                        value={selectedCdsServiceSetId}
                        icon={<IconCdsHooks style={{ color: "rgba(255, 255, 255, 0.87)" }} />}
                        data={this.props.cdsServiceSets.all ? this.props.cdsServiceSets.all.data.map(a => ({id: a.cdsServiceSetId, name: a.name})) : []}
                        onChange={item => this.props.history.push(`/${env}/cds-service-sets/${item.id}`)}/>
                </div>
            )}/>

            <NavbarL3 disableAll={this.state.status !== "ready"}/>

            <div className="container">
                {["ready", "waiting", "recalc"].includes(this.state.status)
                    ? this.renderTabContent()
                    : <CenteredCircularProgress size={63} style={{ padding: 24 }} />}
            </div>

            {["ready", "waiting"].indexOf(this.state.status) > -1
                ? <Dialogs parentState={this.state} initialize={this.initialize} />
                : null}
            <br/>
        </div>;
    }

    private renderTabContent = () => {
        const contentProps = {
            parentState: this.state,
            initialize: this.initialize,
            onSetParentState: this.onSetState,
        }

        switch (this.props.ui.tabs.selected) {
            case 0:
                return <Details {...contentProps} />;
            case 1:
                return <Activations {...contentProps} />;
            case 2:
                return <Usage {...{ ...this.props, ...contentProps }} />;
            default:
                return null;
        }
    };

    private onSetState = (state, cb) => this.setState(state, cb);

    private getFilteredActivations(activations) {
        return activations.filter((activation) => {
            const term = (this.state?.filters?.name || "").toLowerCase();
            const name = (activation.name || "").toLowerCase();
            return name.includes(term);
        });
    }

    private getSortedActivations(activations) {
        const sortBy = this.state.sortBy;
        const sorted = [...activations];
        // Sort by "Name"
        if (sortBy.name !== undefined) {
            if (sortBy.name === true) {
                sorted.sort((a, b) => {
                    const nameA = ("" + a.name).toLowerCase();
                    const nameB = ("" + b.name).toLowerCase();
                    if (nameA < nameB) return -1;
                    else if (nameA > nameB) return 1;
                    else return 0;
                });
            } else if (sortBy.name === false) {
                sorted.sort((a, b) => {
                    const nameA = ("" + a.name).toLowerCase();
                    const nameB = ("" + b.name).toLowerCase();
                    if (nameA < nameB) return 1;
                    else if (nameA > nameB) return -1;
                    else return 0;
                });
            }
        }
        return sorted;
    };

    private _setActivations = () => {
        let activations = this.state?.data?.activations || [];
        // 1. Filter
        activations = this.getFilteredActivations(activations);
        // 2. Sort
        activations = this.getSortedActivations(activations);
        // ---
        this.setState({ status: "ready", activations });
    };

    private setActivations = _.debounce(this._setActivations, 550);

    private initialize = async () => {
        this.setState({status: "init"});
        const {accountId} = getHostnameInfo();
        const {env, cdsServiceSetId} = getLocationInfo(this.props.location, this.props.config);
        const cdsServiceSetUrl = getCdsServiceSetsCallUrl(accountId, env) + "/" + cdsServiceSetId;
        const gatewaysUrl = getGatewaysCallUrl(accountId, env);
        const [, , getGatewayJwkSetResponse] = await Promise.all([
            this.props.cdsServiceSetsRead(this.props.config, cdsServiceSetUrl),
            this.props.gatewaysReadAll(this.props.config, gatewaysUrl),
            this.props.getGatewayJwkSet()
        ]);
        this.setState(
            {
                ...INIT_STATE,
                status: "ready",
                data: this.props.cdsServiceSets.selected.data,
                gatewayJwkSet: getGatewayJwkSetResponse?.data || {},
            },
            this.setActivations,
        );
    };
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    cdsServiceSets: state.cdsServiceSets,
    ui: state.ui,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    cdsServiceSetsRead: actions.cdsServiceSetsRead,
    gatewaysReadAll: actions.gatewaysReadAll,
    getGatewayJwkSet: actions.getGatewayJwkSet,
    uiTabsSetItems: actions.uiTabsSetItems,
    uiTabsSetSelected: actions.uiTabsSetSelected,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CdsServiceSetEdit);
