import getHostnameInfo from "./getHostnameInfo";

export function isLoggedIn(login: any): boolean {
    const { accountId } = getHostnameInfo();
    const { status: loginStatus } = login;

    return !!accountId && loginStatus === "logged-in";
}

export function isMe(
    me: any,
    user: any,
): boolean {
    const myself = me.uuid || (me.data && me.data.uuid);
    const yourself = user.uuid || (user.data && user.data.uuid);
    return !!myself && myself === yourself;
}
