import * as PropTypes from "prop-types";
import withTheme from "@material-ui/core/styles/withTheme";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

const CustomDialogTitle = (props) => {
    return <DialogTitle
        disableTypography
        style={{ color: props.theme.palette.primary.contrastText, background: props.theme.palette.primary.main }}
    >
        <Typography variant="h6">
            Edit user
        </Typography>
        <IconButton
            style={{ position: "absolute", right: props.theme.spacing(1), top: props.theme.spacing(1), color: props.theme.palette.primary.contrastText }}
            onClick={props.onClose}
        >
            <CloseIcon />
        </IconButton>
    </DialogTitle>
}

CustomDialogTitle.propTypes = {
    theme: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};

export default withTheme(CustomDialogTitle);
