import * as React from "react";
import * as PropTypes from "prop-types";
import {getFullDateDisplay} from "../../../../../lib/utils";
import consoleLogger from "../../../../../lib/consoleLogger";
import "./styles.less";
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress";

const numberOfDots = 60;

export default class extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {
            snackbar: {
                open: false,
                message: "",
                autoHideDuration: 4000
            },
            anchorEl: null
        }
    }

    public componentDidUpdate() {
        if (this.props.state.apps.length > 0) {
            let newAppList = [];
            let hasChanged = false;
            this.props.state.apps.map(app => {
                if (!app.loading && !app.loaded) {
                    consoleLogger.log(`Calling health endpoint for ${app.appId}`);
                    this.props.getAppHealth(app.appId, this.props.state.timeframe);
                    app.loading = true;
                    hasChanged = true;
                }
                newAppList.push(app);
            });
            hasChanged && this.props.updateApps(newAppList);
        }
    }

    public render() {
        return <div>
            {!this.props.state.apps.length && <CenteredCircularProgress size={63} style={{padding: "24px"}}/>}
            {!!this.props.state.apps.length && this.getApps()}
            <Snackbar
                open={this.state.snackbar.open}
                message={this.state.snackbar.message}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={this.state.snackbar.autoHideDuration}
                ContentProps={{ style: { backgroundColor: this.props.ui.xtheme.palette.colorBlueDark, display: "flex", flexDirection: "row", justifyContent: "center" } }}
                onClose={() => this.setState({ snackbar: { open: false, message: "" } })}
            />
        </div>;
    }

    private getApps = () => {
        let currentStatus = "";
        let dots = Array(numberOfDots).fill(1);
        let eventsPerDot = 1;
        let eventsList = [];
        let healthLine = undefined;
        let rightText = undefined;
        let days = Math.round(this.props.state.timeframe / 1000 / 60 / 60 / 24);

        return this.props.state.apps.map((app, i) => {
            if (this.props.filteredApps.indexOf(app.appName) === -1) {
                if (app.loading) {
                    healthLine = <CenteredCircularProgress size={30}/>;
                } else if (app.healthEvents) {
                    eventsList = Object.keys(app.healthEvents);
                    eventsList = eventsList.reverse();
                    eventsPerDot = eventsList.length / numberOfDots;
                    currentStatus = app.healthEvents[eventsList[eventsList.length - 1]] === "HEALTHY"
                        ? "Available"
                        : "Unavailable";

                    let errorsCount = 0;
                    healthLine = dots.map((_m, i) => {
                        let getFrom = Math.round(i * eventsPerDot);
                        let getTo = Math.round((i + 1) * eventsPerDot) - 1;
                        let hasUnhealthy = false;
                        let errorTime = undefined;
                        while (getTo >= getFrom) {
                            let event = app.healthEvents[eventsList[getTo]];
                            if (!!event && event.localeCompare("HEALTHY") !== 0) {
                                hasUnhealthy = true;
                                errorsCount++;
                                errorTime = eventsList[getTo];
                            }
                            getTo--;
                        }

                        return hasUnhealthy
                            ? <Tooltip className="line" title={getFullDateDisplay(errorTime, this.props.ui.timeZone)}><span /></Tooltip>
                            : <span key={i} className={"dot"}/>
                    });

                    let percent = 100 - ((errorsCount / eventsList.length) * 100);
                    let percentText = percent.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                    rightText = days === 0
                        ? `${percentText}% Today`
                        : days === 1
                            ? `${percentText}% Yesterday`
                            : `${percentText}% Last ${days} days`;
                } else {
                    rightText = days === 0
                        ? "Today"
                        : days === 1
                            ? "Yesterday"
                            : `Last ${days} days`;
                    healthLine = <span className="no-app-data-available">No data available for the selected period</span>
                }

                return <div key={i} className="app-monitoring-wrapper">
                    <div className="first-line-app-monitoring">
                        <span>{app.appName}</span>
                        <span>{currentStatus}</span>
                        <div className="monitoring-duration">
                            {rightText}
                            <div>
                                <IconButton
                                    className="export-button"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        this.setState({ anchorEl: event.currentTarget });
                                    }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={this.state.anchorEl}
                                    open={!!this.state.anchorEl}
                                    onClose={() => this.setState({ anchorEl: null })}
                                >
                                    <MenuItem onClick={() => this.export(app)}>
                                        <ListItemIcon><UnarchiveIcon /></ListItemIcon>
                                        Export as JSON
                                    </MenuItem>
                                    <MenuItem onClick={() => this.exportCsv(app)}>
                                        <ListItemIcon><UnarchiveIcon /></ListItemIcon>
                                        Export as CSV
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div className="app-status-history">
                        {healthLine}
                    </div>
                </div>
            }
        })
    };

    private exportCsv = app => {
        let data = "";
        let input = app.healthEvents;
        Object.keys(input).map(key => {
            data += `${key},${input[key]}\r\n`;
        })

        const link = document.createElement("a");
        let blob = new Blob([data], {type: "text/csv;charset=utf-8;"});
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${app.environmentId}-${app.appName}-${getFullDateDisplay(app.startTime * 1000, this.props.ui.timeZone)}-${getFullDateDisplay(app.endTime * 1000, this.props.ui.timeZone)}.csv`);
        document.body.appendChild(link);
        link.click();
        this.setState({
            anchorEl: null,
            snackbar: {
                open: true,
                message: "CSV Downloaded",
                autoHideDuration: 4000
            }
        });
    };

    private export = app => {
        let data = app.healthEvents;
        const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${app.environmentId}-${app.appName}-${getFullDateDisplay(app.startTime * 1000, this.props.ui.timeZone)}-${getFullDateDisplay(app.endTime * 1000, this.props.ui.timeZone)}.json`);
        document.body.appendChild(link);
        link.click();
        this.setState({
            anchorEl: null,
            snackbar: {
                open: true,
                message: "JSON Downloaded",
                autoHideDuration: 4000
            }
        });
    };
}
