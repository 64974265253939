import * as React from "react";
import Cell from "./Cell/";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    return <div className="user-environment-permissions-h2-content">
        <div className="col-1">
            <Cell {...props} name="name" label="Environment" sortable/>
        </div>

        <div className="col-2">
            <Cell {...props} name="access" label="Access" sortable={false}/>
        </div>
    </div>;
}

Component.propTypes = {};

export default Component;
