const reservedPaths = ['test', 'environment', 'eda', 'terms', 'av2', 'logging', 'monitoring', 'gateway', 'slp', 'oauth', 'auth', 'platform', 'console', 'hl7overhttp', 'cdr', 'analytics', 'smart', 'account',
    'provider-portal', 'patient-portal', 'docs', 'msg', 'docker', 'perf-tests-sv', 'brc', 'sch', 'ics', 'err', 'qwerty', 'etl', 'lambda', 'ipv', 'carequality', 'ehi-export']

export function onEdit(state: any) {
    const name: string = state.data?.name?.trim() ? '' : 'Required field'

    // TODO: update for shortcut to raise error
    // const shortcut: string = !state.shortcut || (state.shortcut.length >= 4 && state.shortcut.indexOf(' ') === -1) ? '' : 'Required field'
    const stateShortcut = state.data.shortcut
    let shortcut = ''
    if (stateShortcut) {
        if (stateShortcut?.length < 4) {
            shortcut = 'Shortcut too short.'
        }
        if (stateShortcut?.length > 18) {
            shortcut = 'Shortcut must be 18 characters at most.'
        }
        const regex = new RegExp('(^[a-z0-9-]+$)')
        if (!regex.test(stateShortcut)) {
            shortcut = 'Shortcut must contain only lowercase letters, numbers or dashes.'
        }
        const startRegex = new RegExp(/[a-z]/i)
        if (!startRegex.test(stateShortcut.charAt(0))) {
            shortcut = 'Shortcut must start with a lowercase letter.'
        }
        const matchingReservedPath = reservedPaths.filter((path) => {
            return stateShortcut.indexOf(path) === 0;
        })[0];
        if (matchingReservedPath) {
            shortcut = `Shortcut must not start with a reserved word: ${matchingReservedPath}.`
        }

    }

    return { name, shortcut }
}
