import * as React from "react";
import * as PropTypes from "prop-types";
import ChangeEmailConfirm from "./ChangeEmailConfirm/";

export default class extends React.Component<any, any> {
    public static propTypes = {
        state: PropTypes.object.isRequired,
        initialize: PropTypes.func.isRequired
    };

    public render() {
        const propSet = {
            ...this.props,
            onClose: this.props.initialize
        };

        let content = null;
        switch (this.props.state.dialog) {
            case ":EMAIL_CHANGE_CONFIRM:":
                content = <ChangeEmailConfirm {...propSet} />;
                break;
        }

        return content;
    }
}
