/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import ChipDropDown from "../../../../../Widgets/ChipDropDown";
import {Switch, Checkbox, Divider, FormControlLabel} from "@material-ui/core";
import {capitalizeFirstLetter} from "../../../../../../lib/utils";
import withLegacyTheme from "../../../../../../lib/hoc/with-legacy-theme";
import {RadioButtonChecked, RadioButtonUnchecked} from "@material-ui/icons";
import {FilterByDate} from "../../../index";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    let label = props.label;
    if (props.type === "timeframe") {
        label = "Today";
        switch (props.value) {
            case 0:
                label = "Today";
                break;
            case 604800000:
                label = "Last 7 days";
                break;
            case 2678400000:
                label = "Last 30 days";
                break;
            default:
                label = "Timeframe";
        }
    }

    const popover = props.type === "timeframe"
        ? {
            title: "Timeframe",
            content: <FilterByDate {...props} key="data_filter" setFilterItem={props.setFilterItem}/>
        }
        : props.type === "radio"
            ? {
                title: props.title,
                content: <div className="table-filter-popup">
                    {props.values.map((v, i) => <FormControlLabel label={v.label} key={i} onChange={v.onClick}
                        control={<Checkbox checkedIcon={<RadioButtonChecked/>} icon={<RadioButtonUnchecked/>}
                            checked={props.checked === v.value}/>}/>)}
                </div>
            }
            : {
                title: props.title,
                content: <div className="table-filter-popup">
                    <FormControlLabel label={props.checkAllLabel}
                        control={<Switch checked={props.allSelected} style={{marginBottom: "8px"}} onChange={() => props.checkAll(!props.allSelected)}/>}/>
                    <Divider style={{margin: "8px 0px"}}/>
                    {props.values.map((key, index) => <FormControlLabel key={`operation-${key}-${index}`} name={`operation-${key}-${index}`} label={capitalizeFirstLetter(key)}
                        onChange={() => props.onClick(key)}
                        control={<Checkbox checked={props.checked[key]}/>}/>)}
                </div>
            };
    const dropProps = {
        label,
        inactiveBackground: "#fff",
        inactiveColor: props.muiTheme.palette.textColor,
        activeBackground: props.muiTheme.palette.primary1Color,
        deleteIconStyle: {fill: "rgba(255, 255, 255, 0.67)"},
        activeColor: "#fff",
        isActive: props.isActive,
        onRequestDelete: props.onDelete,
        popover
    }

    return <ChipDropDown {...dropProps}/>;
}

Component.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    label: PropTypes.string,
    key: PropTypes.string
}

export default withLegacyTheme()(Component);
