import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { createMachine, assign } from 'xstate'
import { useMachine } from '@xstate/react'
import axiosWrapper from '../../../../../lib/axiosWrapper'
import getGatewaysCallUrl from '../../../../../lib/getGatewaysCallUrl'
import getHostnameInfo from '../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../lib/getLocationInfo'
import * as actions from '../../../../../redux/actions'
import { INIT_STATE as initParentState } from '..'

type TProps = {
    config: any
    gateways: any
    location: any
    parentState: {
        status: string
    }
    gatewaysReadAll: Function
    onSetParentState: Function
}

const InitMachine = (props: TProps) => {
    const [state, send] = useMachine(() => createMachine({
        id: 'init',
        initial: 'start',
        context: {
            shallowGateways: [],
        },
        states: {
            start: {
                on: {
                    SEND_REQUESTS: 'sending_requests',
                },
            },
            sending_requests: {
                invoke: {
                    src: 'sendRequests',
                    onDone: {
                        target: 'wait_redux',
                        actions: assign({
                            shallowGateways: (_, event) => event.data?.data || initParentState.shallowGateways,
                        }),
                    },
                    onError: {
                        target: 'processing_data',
                    }
                },
            },
            wait_redux: {
                after: {
                    100: { target: 'processing_data' }
                },
            },
            processing_data: {
                on: {
                    DONE: 'idle',
                },
            },
            idle: {
                on: {
                    RESTART: 'start',
                },
            },
        },
    }), {
        services: {
            sendRequests: () => {
                const { accountId } = getHostnameInfo()
                const { env } = getLocationInfo(props.location, props.config)

                const gatewaysAllStatus = props.gateways.all.status
                const gwsUrl = getGatewaysCallUrl(accountId, env)
                if (![/* 'ready', */ 'pending'].includes(gatewaysAllStatus)) {
                    props.gatewaysReadAll(props.config, gwsUrl)
                }

                return axiosWrapper(props.config.envApi, `${gwsUrl}?shallow=true`, 'GET')
            }
        }
    })

    const onSetParentState = props.onSetParentState

    // start
    React.useEffect(() => {
        if (state.matches('start')) {
            if (props.parentState.status === 'init') {
                send('SEND_REQUESTS')
            }
        }
    }, [props.parentState.status, send, state])

    // processing_data
    React.useEffect(() => {
        if (state.matches('processing_data')) {
            const shallowGateways = state.context.shallowGateways
            onSetParentState({
                ...initParentState,
                status: "ready",
                shallowGateways,
            }, () => {
                send('DONE')
            })
        }
    }, [onSetParentState, send, state])

    // idle
    React.useEffect(() => {
        if (state.matches('idle')) {
            if (props.parentState.status === 'init') {
                send('RESTART')
            }
        }
    }, [props.parentState.status, send, state])

    return null
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    gateways: state.gateways,
    ...ownProps,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    gatewaysReadAll: actions.gatewaysReadAll,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InitMachine))
