import * as PropTypes from "prop-types";

import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import TextField from "@material-ui/core/TextField";

import Items from "./Items";

const JsonConfig = (props) => {
    let jsonObj;
    try {
        jsonObj = JSON.parse(props.jsonStr);
    }
    catch (error) {
        jsonObj = {};
    }

    return (
        <div data-qa="json-config">
            <div
                data-qa="title"
                style={{ fontWeight: "bold", margin: "16px 0" }}
            >
                Properties
            </div>

            {props.areItemsShown ? (
                <Items {...props} jsonObj={jsonObj} />
            ) : null}


            {props.isJsonEditorShown ? (
                <JSONInput
                    id="json-config-editor"
                    locale={locale}
                    placeholder={jsonObj}
                    reset={false}
                    viewOnly={!props.canEdit}
                    confirmGood
                    width="auto"
                    height="auto"
                    theme="light_mitsuketa_tribute"
                    colors={{
                        background: "#f9f9f9",
                    }}
                    style={{
                        outerBox: {
                            marginTop: 16,
                            padding: 4,
                            border: "2px solid rgba(0, 0, 0, 0.2)",
                            borderRadius: 8,
                        },
                    }}
                    onChange={({ json } = { json: ""}) => {
                        let isValidJson
                        try {
                            JSON.parse(json);
                            isValidJson = true;
                        }
                        catch (reason) {
                            isValidJson = false;
                        }
                        if (isValidJson) {
                            props.onSetState(
                                (prevState) => ({
                                    isNextWStepEnabled: false,
                                    data: {
                                        ...prevState.data,
                                        configuration: {
                                            ...prevState.data.configuration,
                                            json,
                                        },
                                    },
                                }),
                                props.forceUpdate,
                            );
                        }
                        else if (!isValidJson) {
                            props.onSetState({ isNextWStepEnabled: false });
                        }
                    }}
                // onBlur={...}
                />
            ) : null}

            {props.isStringEditorShown ? (
                <TextField data-qa="adapter-json-config"
                    name="adapter-json-config" value={props.jsonStr}
                    placeholder={"{\"authEndpointToken\": \"token-endpoint\", \"authEndpointAuthorize\": \"authorize-endpoint\"}"}
                    label="JSON config string"
                    helperText={props.canEdit && props.directParentState.dataValidation && props.directParentState.dataValidation.json}
                    error={!!(props.canEdit && props.directParentState.dataValidation && props.directParentState.dataValidation.json)}
                    disabled={!props.canEdit}
                    autoComplete="off" autoFocus={false} fullWidth
                    classes={{ root: props.canEdit ? "" : "disabled-text-field" }}
                    style={{ top: 8 }}
                    onChange={(event) => {
                        props.onSetState((prevState) => ({
                            isNextWStepEnabled: false,
                            data: {
                                ...prevState.data,
                                configuration: {
                                    ...prevState.data.configuration,
                                    json: event.target.value,
                                },
                            },
                        }))
                    }}
                />
            ) : null}
        </div>
    );
};

JsonConfig.propTypes = {
    configProps: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    jsonStr: PropTypes.string.isRequired,

    areItemsShown: PropTypes.bool,
    canEdit: PropTypes.bool,
    directParentState: PropTypes.object,
    isJsonEditorShown: PropTypes.bool,
    isStringEditorShown: PropTypes.bool,

    forceUpdate: PropTypes.func,
    onSetState: PropTypes.func,
};

JsonConfig.defaultProps = {
    areItemsShown: true,
    canEdit: true,
    directParentState: {},
    isJsonEditorShown: true,
    isStringEditorShown: true,

    forceUpdate: () => { },
    onSetState: () => { },
};

export { JsonConfig };

export default withLegacyTheme()(JsonConfig);
