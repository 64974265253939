import * as React from "react";
import * as PropTypes from "prop-types";
import {isAccountOwner} from "../../../../../lib/user-roles-helpers";
import {TextField, Switch, FormControlLabel, FormControl, Accordion, AccordionSummary, CardHeader, Button, AccordionActions, AccordionDetails, Select, MenuItem, ListSubheader} from "@material-ui/core";
import {Check, ExpandMore} from "@material-ui/icons";
import "./style.less";
import Alert from '@material-ui/lab/Alert';
import * as validateData from "../../../../../lib/validate-sso-data";
import consoleLogger from "../../../../../lib/consoleLogger";

export default class extends React.Component<any, any> {
    public static defaultProps = {
        config: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
    };

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.state.ssoData) !== JSON.stringify(prevProps.state.ssoData)) {
            this.validateData();
        }
    }

    public render() {
        if (!isAccountOwner(this.props.login, this.props.config)) {
            return null;
        }

        return <div>
            <Accordion style={{marginTop: "48px"}}>
                <AccordionSummary style={{
                    padding: "0 24px 0 0",
                    minHeight: "auto",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: this.props.muiTheme.palette.secondaryTextColor,
                    boxSizing: "border-box",
                    height: "48px",
                    background: this.props.muiTheme.palette.accent2Color,
                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    width: "100%"
                }} classes={{content: "sso-expander-content"}} expandIcon={<ExpandMore/>}>
                    <CardHeader style={{padding: "16px 72px"}} title="Authentication Configuration" disableTypography/>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{padding: "24px", width: "100%"}}>
                        {this.renderSelectAuth()}
                        <ListSubheader style={{padding: 0, fontSize: "16px", marginTop: "16px"}}component="div" id="nested-list-subheader">
                            SSO Configuration
                        </ListSubheader>
                        {this.renderEnable()}
                        {this.renderEndpoint()}
                        {this.renderIssuer()}
                        {this.renderCert()}
                    </div>
                </AccordionDetails>
                <AccordionActions style={{textAlign: "right", paddingBottom: "10px"}}>
                    <Button variant="outlined" key="action-btn-cancel" style={{marginRight: "8px"}} onClick={() => this.props.initialize()}>
                        Cancel
                    </Button>
                    <Button variant="contained" key="action-btn-update-app" color="primary" disabled={this.props.state.status !== "ready" || !this.isDataValid()}
                        onClick={() => {
                            this.props.onSetState(
                                {status: "wait"},
                                () => {
                                    const updatedData = {
                                        enabled: this.props.state.ssoData.enabled,
                                        entryPoint: this.props.state.ssoData.entryPoint,
                                        issuer: this.props.state.ssoData.issuer,
                                        cert: this.props.state.ssoData.cert,
                                        buttonLabel: this.props.state.ssoData.buttonLabel,
                                        authForced: this.props.state.ssoData.authForced
                                    };
                                    this.props.accountsSsoConfigUpdate(this.props.config, updatedData)
                                        .then(async (res) => {
                                            if (res && res.status < 300 && res.data) {
                                                this.props.initialize()
                                            }
                                        })
                                        .catch((reason) => {
                                            this.props.onSetState({status: "ready"});
                                            consoleLogger.error(":::", reason)
                                        });
                                }
                            );
                        }}>
                        <Check/> Save Auth Config
                    </Button>
                </AccordionActions>
            </Accordion>
        </div>;
    }

    private validateData = () => {
        const dataValidation = validateData.onEdit(this.props.state)
        this.props.onSetState({
            status: "ready",
            dataValidation: {
                ...this.props.state.dataValidation,
                ...dataValidation
            }
        });
    }

    private isDataValid = () => Object.keys(this.props.state.dataValidation).reduce((acc, key) => acc && !this.props.state.dataValidation[key], true);

    private renderSelectAuth = () => {
        return <div style={{ display: "flex", flexFlow: "wrap-reverse"}}>

            <FormControl style={{ minWidth: 150}}>
                <ListSubheader style={{padding: 0, fontSize: "16px"}}component="div" id="nested-list-subheader">
                    Minimum Required Authentication Level
                </ListSubheader>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.props.state.ssoData.authForced}
                    onChange={e =>  this.props.onSetState({
                        ssoData: {
                            ...this.props.state.ssoData,
                            enabled: e.target.value === "SSO" ? 1 : this.props.state.ssoData.enabled,
                            authForced: e.target.value
                        }
                    })}
                >
                    <MenuItem value="OFF">Off</MenuItem>
                    <MenuItem value="MFA">MFA for non-SSO</MenuItem>
                    <MenuItem value="SSO">SSO for all</MenuItem>
                </Select>
            </FormControl>
            {this.props.state.ssoData.authForced === "OFF"
                ? <Alert severity="info" style={{maxHeight: 45, maxWidth: 600, marginLeft: 45}}>You will be able to define user authentication rules on per-user basis</Alert>
                : null}
            {this.props.state.ssoData.authForced === "MFA"
                ? <Alert severity="info" style={{maxHeight: 45, maxWidth: 600, marginLeft: 45}}>All users will be forced to use MFA with an authenticatior app when logging in</Alert>
                : null}
            {this.props.state.ssoData.authForced === "SSO"
                ? <Alert severity="warning" style={{maxHeight: 65, maxWidth: 600, marginLeft: 45}}>All current users will be converted to SSO Users. Make sure you tested your SSO Configuration first!</Alert>
                : null}
        </div>
    };

    private renderEnable = () => {
        return <div>
            <FormControlLabel label="Enable SSO" control={<Switch color="primary"
                disabled={this.props.accounts.selectedSsoConfig.data?.authForced === "SSO" || this.props.state.ssoData.authForced === "SSO" ? true : false}
                checked={this.props.state.ssoData.authForced === "SSO" || !!this.props.state.ssoData.enabled ? true : false}
                onChange={e => this.props.onSetState({
                    ssoData: {
                        ...this.props.state.ssoData,
                        enabled: e.target.checked ? 1 : 0
                    }
                })}
            />}/>
            {this.props.accounts.selectedSsoConfig.data?.authForced === "SSO" || this.props.state.ssoData.authForced === "SSO"
                ? <TextField name="sso-enable-warning" disabled fullWidth error style={{height: 0, cursor: "default", marginTop: "-40px"}} classes={{root: "disabled-text-field"}}
                    helperText={`You can not disable SSO when "SSO for all" option is active as Minimum Required Authentication Level. Before disabling SSO make sure there is a non-SOS user active.`}/>
                : null}
        </div>
    };
    private renderEndpoint = () => {
        return <TextField   name="name" value={this.props.state.ssoData.entryPoint || ""} placeholder="SAML 2.0 endpoint (HTTP)" label="SAML 2.0 Endpoint (HTTP)" fullWidth
            error={this.props.state.dataValidation.entryPoint ? true : false}
            helperText={this.props.state.dataValidation.entryPoint}
            style={{marginTop: "24px"}}
            onChange={e => {
                this.props.onSetState({
                    ssoData: {
                        ...this.props.state.ssoData,
                        entryPoint: e.target.value
                    }
                });
            }}/>
    };
    private renderIssuer = () => {
        return <TextField   name="name" value={this.props.state.ssoData.issuer || ""} placeholder="Identity provider issuer" label="Identity Provider Issuer" fullWidth
            error={this.props.state.dataValidation.issuer ? true : false}
            helperText={this.props.state.dataValidation.issuer}
            style={{marginTop: "24px"}}
            onChange={e => {
                this.props.onSetState({
                    ssoData: {
                        ...this.props.state.ssoData,
                        issuer: e.target.value
                    }
                });
            }}/>
    };

    private renderCert = () => {
        return <TextField   name="name" value={this.props.state.ssoData.cert || ""} placeholder="Public certificate" label="Public Certificate" fullWidth multiline
            error={this.props.state.dataValidation.cert ? true : false}
            helperText={this.props.state.dataValidation.cert}
            style={{marginTop: "24px"}}
            onChange={e => {
                this.props.onSetState({
                    ssoData: {
                        ...this.props.state.ssoData,
                        cert: e.target.value
                    }
                });
            }}/>
    };
}
