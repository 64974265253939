import * as React from 'react'
import * as PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import * as validateData from '../../../../../../lib/validate-users-data'
import Header from '../../../../../Widgets/Editor/Header/'
import ActionButtons from './ActionButtons'
import EditIcon from '@material-ui/icons/Edit'
import SecurityIcon from '@material-ui/icons/Security'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

// TODO: TProps ...

type TState = {
    showOldPassword: boolean
    showNewPassword: boolean
}

class Password extends React.Component<any, TState> {
    public static propTypes = {
        muiTheme: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        users: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            showOldPassword: false,
            showNewPassword: false,
        }
    }

    public componentDidMount() {
        this.validateData()
    }

    public componentDidUpdate(prevProps) {
        if (
            this.props.state.data.oldPassword !== prevProps.state.data.oldPassword
            || this.props.state.data.newPassword !== prevProps.state.data.newPassword
        ) {
            this.validateData()
        }
    }

    public render() {
        const canEdit = this.props.state.mode === ':EDIT_PASSWORD:'
        const modeView = this.props.state.mode === ':VIEW:'
        const isValid = Object.keys(this.props.state.dataValidation).reduce((acc, key) => acc && !this.props.state.dataValidation[key], true)

        return (
            <Card style={{ marginTop: '48px' }}>
                <Header
                    {...this.props}
                    leftIcon={<SecurityIcon />}
                    primaryText="Password"
                    rightIcon={!canEdit ? (
                        <Tooltip title="Edit">
                            <IconButton
                                data-qa="user-profile-password-edit-button"
                                disabled={!modeView}
                                onClick={() => {
                                    this.props.onSetState({ mode: ":EDIT_PASSWORD:" })
                                }}
                            >
                                <EditIcon style={{ color: this.props.muiTheme.palette.secondaryTextColor }} />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                />

                <div style={{ padding: '24px' }}>
                    {canEdit ? this.renderOldPassword() : null}
                    {canEdit ? this.renderNewPassword() : null}
                    {this.renderErrorPanel()}
                </div>

                <ActionButtons
                    {...this.props}
                    canEdit={canEdit}
                    isValid={isValid}
                />
            </Card>
        )
    }

    private renderOldPassword = () => {
        return (
            <FormControl style={{
                display: 'block',
                marginTop: '24px',
            }}>
                <InputLabel>
                    Current password
                </InputLabel>
                <Input
                    data-qa="user-profile-basic-info-old-password-input"
                    name='old-password'
                    value={this.props.state.data.oldPassword || ''}
                    error={!!this.props.state.dataValidation.oldPassword}
                    placeholder="Type in your current password"
                    endAdornment={this.props.state.data.oldPassword ? (
                        <InputAdornment position="end">
                            <IconButton onClick={() => {
                                this.setState((prevState) => ({ showOldPassword: !prevState.showOldPassword }))
                            }}>
                                {this.state.showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    ) : null}
                    type={this.state.showOldPassword ? 'text' : 'password'}
                    fullWidth
                    onChange={(event) => {
                        this.props.onSetState((prevState) => ({
                            ...prevState,
                            data: {
                                ...prevState.data,
                                oldPassword: event.target.value,
                            },
                        }))
                    }}
                />
            </FormControl>
        )
    }

    private renderNewPassword = () => {
        return (
            <FormControl style={{
                display: 'block',
                marginTop: '24px',
            }}>
                <InputLabel>
                    New Password
                </InputLabel>
                <Input
                    data-qa="user-profile-basic-info-new-password-input"
                    name="new-password"
                    value={this.props.state.data.newPassword || ""}
                    error={!!this.props.state.dataValidation.newPassword}
                    placeholder="Type in a strong new password"
                    endAdornment={this.props.state.data.newPassword ? (
                        <InputAdornment position="end">
                            <IconButton onClick={() => {
                                this.setState((prevState) => ({ showNewPassword: !prevState.showNewPassword }))
                            }}>
                                {this.state.showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    ) : null}
                    type={this.state.showNewPassword ? 'text' : 'password'}
                    fullWidth
                    onChange={(event) => {
                        this.props.onSetState((prevState) => ({
                            ...prevState,
                            data: {
                                ...prevState.data,
                                newPassword: event.target.value,
                            },
                        }))
                    }}
                />
            </FormControl>
        )
    }

    private renderErrorPanel = () => {
        const hasError = Boolean(this.props.state.passwordError)
            || Boolean(this.props.state.dataValidation.oldPassword)
            || Boolean(this.props.state.dataValidation.newPassword)
        const errorMessage = this.props.state.passwordError
            ? this.props.users.selected.message
            : this.props.state.dataValidation.oldPassword || this.props.state.dataValidation.newPassword

        return (
            <div style={{
                margin: '16px',
                padding: hasError ? '8px' : 0,
                borderRadius: '2px',
                background: this.props.muiTheme.palette.accent1Color,
                color: '#fff',
                textAlign: 'center',
                transform: hasError ? 'scaleY(1)' : 'scaleY(0)',
                transformOrigin: 'top',
                transition: 'all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)',
            }}>
                {errorMessage}
            </div>
        )
    }

    private validateData = () => {
        const dataValidation = validateData.onEditPassword(this.props.state)
        this.props.onSetState({ dataValidation })
    }
}

export default Password
