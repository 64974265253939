import getHostnameInfo from "../../../lib/getHostnameInfo";
import EnterCredentials from "./EnterCredentials/";
import SelectAccount from "./SelectAccount/";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import {useState} from "react";

const SignIn = () => {
    const {protocol, accountId, domain, type, port} = getHostnameInfo();
    let [drawer, setDrawer] = useState(false);
    let [banner, setBanner] = useState({} as any);
    const setBannerInfo = info => {
        if (info.bannerEnabled === 1) {
            setTimeout(() => setDrawer(true), 500);
            setBanner(info);
        }
    }

    return <div className="container">
        <Dialog open={drawer} transitionDuration={500} PaperProps={{style: {padding: "24px"}}}>
            <DialogTitle>
                <Typography variant="h5" style={{textAlign: "center"}}>
                    {banner.bannerHeader}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div style={{lineHeight: "26px", maxHeight: "700px", overflowY: "auto"}}>
                    <Typography>
                        {banner.bannerContent}
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions style={{justifyContent: 'center', marginTop: '16px'}}>
                <Button variant="contained" color="primary" style={{width: '100px'}} onClick={() => setDrawer(!drawer)}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
        {accountId ? <EnterCredentials setBannerInfo={setBannerInfo}/> : <SelectAccount/>}

        <div style={{marginTop: "48px", marginBottom: "48px", textAlign: "center"}}>
            {accountId
                ? <div>
                    <strong>
                        <a href="#!" style={{fontSize: "16px", textDecoration: "none"}}
                            onClick={event => {
                                event.preventDefault();
                                window.location.href = `${protocol}//${domain}.${type}${port ? ":" + port : ""}/console/signin`;
                            }}>
                            Sign into another account?
                        </a>
                    </strong>
                    <br/><br/>
                </div>
                : null}

            Trying to create an account?&nbsp;
            <strong>
                <a href="#!"
                    style={{fontSize: "16px", textDecoration: "none"}}
                    onClick={event => {
                        event.preventDefault();
                        window.location.href = `${protocol}//${domain}.${type}${port ? ":" + port : ""}/console/accounts/request`;
                    }}>
                    Create a new account
                </a>
            </strong>
            <br/>
        </div>
    </div>;
}

export default SignIn;
