import * as React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import axiosWrapper from '../../../../../../lib/axiosWrapper'
import getGatewaysCallUrl from '../../../../../../lib/getGatewaysCallUrl'
import type { TGateway } from '../../../../../../types'
import type {
    TState as TGatewayEditState,
    TOnSetState as TOnSetParentState,
} from '../..'

type IProps = {
    isValid: boolean
    parentState: TGatewayEditState
    config: any
    style: any
    getQueueName: () => string
    initialize: () => void
    // eslint-disable-next-line no-unused-vars
    onSetParentState: TOnSetParentState<TGatewayEditState>
}

const ActionButtons: React.FunctionComponent<IProps> = (props: IProps) => {
    return (
        <CardActions style={props.style}>
            <div style={{
                height: props.parentState.mode === ':EDIT:' ? '36px' : 0,
                marginTop: props.parentState.mode === ':EDIT:' ? '8px' : 0,
                marginBottom: props.parentState.mode === ':EDIT:' ? '8px' : 0,
                textAlign: 'right',
                transition: 'all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)',
            }}>
                <Button
                    data-qa="edit-gateway-cancel-btn"
                    disabled={props.parentState.mode !== ':EDIT:'}
                    variant="outlined"
                    style={{
                        display: props.parentState.mode === ':EDIT:' ? 'inline-block' : 'none',
                        marginRight: '8px',
                    }}
                    onClick={() => {
                        props.onSetParentState(
                            (prevState) => ({
                                ...prevState,
                                mode: ":VIEW:",
                            }),
                            props.initialize,
                        )
                    }}
                >
                    Cancel
                </Button>
                <Button
                    data-qa="edit-gateway-save-btn"
                    disabled={
                        props.parentState.mode !== ':EDIT:'
                        || !props.isValid
                        || props.parentState.status !== 'ready'
                    }
                    variant="contained"
                    color="primary"
                    style={{ display: props.parentState.mode === ':EDIT:' ? 'inline-block' : 'none' }}
                    onClick={async () => {
                        props.onSetParentState((prevState) => ({
                            ...prevState,
                            status: 'wait',
                        }))

                        const accountId = props.parentState.data.accountId
                        const environmentId = props.parentState.data.environmentId
                        const gatewayId = props.parentState.data.gatewayId

                        const queueName = props.getQueueName()

                        const hl7Enabled = props.parentState.data.hl7Enabled

                        const hl7Properties: TGateway['hl7Properties'] = hl7Enabled ? {
                            ...props.parentState.data.hl7Properties,
                            accountId,
                            environmentId,
                            gatewayId,
                            queueName,
                        } : null

                        const userDirectory = (props.parentState.data.userDirectory?.directoryId && props.parentState.data.userDirectory?.directoryId !== "NONE") ? {
                            accountId,
                            environmentId,
                            directoryId: props.parentState.data.userDirectory?.directoryId,
                        } : null

                        let data: TGatewayEditState['data'] = {
                            ...props.parentState.data,
                            accountId,
                            environmentId,
                            hl7Enabled,
                            hl7Properties,
                            userDirectory,
                        }

                        if (props.parentState.data.gatewayType === 'HL7_V2') {
                            data = {
                                ...data,
                                authenticationType: 'NONE',
                            }
                        }

                        const gwUrl = getGatewaysCallUrl(accountId, environmentId) + '/' + gatewayId
                        await axiosWrapper(props.config.envApi, gwUrl, 'PUT', data)

                        props.onSetParentState(
                            (prevState) => ({
                                ...prevState,
                                mode: ':VIEW:'
                            }),
                            props.initialize,
                        )
                    }}
                >
                    {props.parentState.status === 'wait' ? (
                        <>
                            <CircularProgress size={17} />
                            &nbsp;
                        </>
                    ) : null}
                    Save
                </Button>
            </div>
        </CardActions>
    )
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
})
export default connect(mapStateToProps)(ActionButtons)
