import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import * as actions from '../../../../../../redux/actions'
import getAppsCallUrl from "../../../../../../lib/getAppsCallUrl";
import getHostnameInfo from "../../../../../../lib/getHostnameInfo";
import getLocationInfo from "../../../../../../lib/getLocationInfo";
import withLegacyTheme from '../../../../../../lib/hoc/with-legacy-theme'
import {Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import Actions from "./Actions";
import Content from "./Content/";
import Title from "./Title";

const WSTEP_MIN: number = 1;
const WSTEP_MAX: number = 5;

class AppAddNewWizard extends React.Component<any, any> {
    public static propTypes = {
        apps: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        appsCreate: PropTypes.func.isRequired,
        appsReadAll: PropTypes.func.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.initialize();
        this.props.onSetState({wstep: WSTEP_MIN});
    }

    public render() {
        return <Dialog
            open
            classes={{paper: "io-modal-wrapper"}}
            style={{ paddingTop: '5px', zIndex: 1250 }}
        >
            <DialogTitle style={{
                height: "48px",
                background: this.props.muiTheme.palette.primary1Color,
                color: "#fff",
                lineHeight: "48px",
                fontSize: "24px",
                fontWeight: 600,
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px"
            }}>
                <Title {...this.props} />
            </DialogTitle>
            <DialogContent style={{background: "rgb(250, 250, 250)", padding: "0 0 24px 0"}}>
                <Content {...this.props} />
            </DialogContent>
            <DialogActions style={{padding: "0 24px 24px", background: "rgb(250, 250, 250)", borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px"}}>
                <Actions{...this.props} WSTEP_MIN={WSTEP_MIN} WSTEP_MAX={WSTEP_MAX} calcNextWStep={this.calcNextWStep} calcPrevWStep={this.calcPrevWStep}/>
            </DialogActions>
        </Dialog>;
    }

    private calcNextWStep = () => this.props.state.wstep < WSTEP_MAX ? this.props.state.wstep + 1 : WSTEP_MAX;

    private calcPrevWStep = () => this.props.state.wstep > WSTEP_MIN ? this.props.state.wstep - 1 : WSTEP_MIN;

    private initialize = () => {
        this.props.onSetState(
            { status: 'w-init' },
            () => {
                const { accountId } = getHostnameInfo()
                const { env } = getLocationInfo(this.props.location, this.props.config)
                const appsAllStatus = this.props.apps.all.status
                const appsUrl = getAppsCallUrl(accountId, env)
                if (![/* 'ready', */ 'pending'].includes(appsAllStatus)) {
                    this.props.appsReadAll(this.props.config, appsUrl)
                }
                this.props.onSetState((prevState) => ({
                    status: 'ready',
                    data: {
                        ...prevState.data,
                        scopes: 'launch patient/*.* openid profile',
                        appAuthTypes: ['SMART_ON_FHIR_PUBLIC'],
                    }
                }))
            }
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    apps: state.apps,
    config: state.config,
    ...ownProps,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appsCreate: actions.appsCreate,
    appsReadAll: actions.appsReadAll,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(AppAddNewWizard)))
