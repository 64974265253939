import axiosWrapper from "../../lib/axiosWrapper";
import { getPath } from "../../lib/utils";
import consoleLogger from "../../lib/consoleLogger";
import initialState from "../../redux/reducers/cds-service-sets/initial-state";

import * as types from "./types";

export function cdsServiceSetsCreate(
    config: any,
    url: string,
    data = {},
) {
    return async function (dispatch: Function): Promise<any> {
        dispatch({
            type: types.CDS_SERVICE_SETS_SELECTED_SET,
            payload: {
                status: "pending",
                data: initialState.selected.data,
            },
        });
        let response: any;
        try {
            response = await axiosWrapper(config.envApi, url, "PUT", data);
            dispatch({
                type: types.CDS_SERVICE_SETS_SELECTED_SET,
                payload: {
                    status: "ready",
                    data: getPath(response, "data"),
                },
            });
            return response;
        } catch (reason) {
            dispatch({
                type: types.CDS_SERVICE_SETS_SELECTED_SET,
                payload: {
                    status: "error",
                    data: initialState.selected.data,
                },
            });
            consoleLogger.error(":::", reason);
        }
    };
}

export function cdsServiceSetsDelete(
    config: any,
    url: string,
) {
    return async function (dispatch: Function): Promise<any> {
        dispatch({
            type: types.CDS_SERVICE_SETS_SELECTED_SET,
            payload: {
                status: "pending",
                data: initialState.selected.data,
            },
        });
        let response: any;
        try {
            response = await axiosWrapper(config.envApi, url, "delete")
            dispatch({
                type: types.CDS_SERVICE_SETS_SELECTED_SET,
                payload: {
                    status: "ready",
                    data: initialState.selected.data,
                },
            });
            return response;
        } catch (reason) {
            dispatch({
                type: types.CDS_SERVICE_SETS_SELECTED_SET,
                payload: {
                    status: "error",
                    data: initialState.selected.data,
                },
            });
            consoleLogger.error(":::", reason);
        }
    };
}

export function cdsServiceSetsRead(
    config: any,
    url: string,
) {
    return async function (dispatch: Function): Promise<any> {
        dispatch({
            type: types.CDS_SERVICE_SETS_SELECTED_SET,
            payload: {
                status: "pending",
                data: initialState.selected.data,
            },
        });
        let response: any;
        try {
            response = await axiosWrapper(config.envApi, url, "GET");
            dispatch({
                type: types.CDS_SERVICE_SETS_SELECTED_SET,
                payload: {
                    status: "ready",
                    data: getPath(response, "data"),
                },
            });
            return response;
        } catch (reason) {
            dispatch({
                type: types.CDS_SERVICE_SETS_SELECTED_SET,
                payload: {
                    status: "error",
                    data: initialState.selected.data,
                },
            });
            consoleLogger.error(":::", reason);
        }
    };
}

export function cdsServiceSetsReadAll(
    config: any,
    url: string,
) {
    return async function (dispatch: Function): Promise<any> {
        dispatch({
            type: types.CDS_SERVICE_SETS_ALL_SET,
            payload: {
                status: "pending",
                data: initialState.all.data,
            },
        });
        let response: any;
        try {
            response = await axiosWrapper(config.envApi, url, "GET");
            dispatch({
                type: types.CDS_SERVICE_SETS_ALL_SET,
                payload: {
                    status: "ready",
                    data: getPath(response, "data"),
                },
            });
            return response;
        } catch (reason) {
            dispatch({
                type: types.CDS_SERVICE_SETS_ALL_SET,
                payload: {
                    status: "error",
                    data: initialState.all.data,
                },
            });
            consoleLogger.error(":::", reason);
        }
    };
}

export const cdsServiceSetsUpdate = cdsServiceSetsCreate;
