import $ from "jquery";
import * as types from "./types";
import initialState from "../../redux/reducers/cdrs/initial-state";
import {getPath} from "../../lib/utils";
import axiosWrapper from "../../lib/axiosWrapper";
import getMessageFromResponse from "../../lib/getMessageFromResponse";
import consoleLogger from "../../lib/consoleLogger";

export function cdrsCreate(
    config: any,
    url: string,
    data = {},
) {
    return function (dispatch) {
        dispatch({
            type: types.CDRS_SELECTED_SET,
            payload: {
                status: "pending",
                data: $.extend(true, {}, initialState.selected.data),
            },
        });
        return axiosWrapper(config.envApi, url, "put", data)
            .then((res) => {
                dispatch({
                    type: types.CDRS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: types.CDRS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, {}, initialState.selected.data),
                    },
                });
                consoleLogger.error(error);
            });
    };
}

export function cdrsDelete(
    config: any,
    url: string,
) {
    return function (dispatch) {
        dispatch({
            type: types.CDRS_SELECTED_SET,
            payload: {
                status: "pending",
                data: $.extend(true, {}, initialState.selected.data),
            },
        });
        return axiosWrapper(config.envApi, url, "delete")
            .then((res) => {
                dispatch({
                    type: types.CDRS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: $.extend(true, {}, initialState.selected.data),
                    },
                });
                return res;
            })
            .catch((error) => {
                let errorData = error.response ? error.response.data : {};
                dispatch({
                    type: types.CDRS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, {error: errorData}, initialState.selected.data),
                    },
                });
                return error;
            });
    };
}

export function cdrsRead(
    config: any,
    url: string,
) {
    return function (dispatch) {
        dispatch({
            type: types.CDRS_SELECTED_SET,
            payload: {
                status: "pending",
                data: $.extend(true, {}, initialState.selected.data),
            },
        });
        return axiosWrapper(config.envApi, url, "get")
            .then((res) => {
                dispatch({
                    type: types.CDRS_SELECTED_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: types.CDRS_SELECTED_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, {}, initialState.selected.data),
                    },
                });
                consoleLogger.error(error);
            });
    };
}

export function cdrsReadAll(
    config: any,
    url: string,
) {
    return function (dispatch) {
        dispatch({
            type: types.CDRS_ALL_SET,
            payload: {
                status: "pending",
                data: $.extend(true, [], initialState.all.data),
            },
        });
        return axiosWrapper(config.envApi, url, "get")
            .then((res) => {
                dispatch({
                    type: types.CDRS_ALL_SET,
                    payload: {
                        status: "ready",
                        data: getPath(res, "data"),
                    },
                });
                return res;
            })
            .catch((reason) => {
                dispatch({
                    type: types.CDRS_ALL_SET,
                    payload: {
                        status: "error",
                        data: $.extend(true, [], initialState.all.data),
                    },
                });
                consoleLogger.error(reason);
            });
    };
}

export const cdrsUpdate = cdrsCreate;


export function cdrsImport(
    config: any,
    url: string,
    data: JSON,
) {
    return function (dispatch) {
        dispatch({
            type: types.CDRS_SELECTED_IMPORT,
            payload: {
                status: "pending",
                result: $.extend(true, {}, initialState.selected.dataManager.result),
            },
        });
        return axiosWrapper(config.envApi, url, "post", data)
            .then((res) => {
                if (res.status < 300) {
                    dispatch({
                        type: types.CDRS_SELECTED_IMPORT,
                        payload: {
                            status: "success",
                            result: getPath(res, "data"),
                        },
                    });
                } else {
                    dispatch({
                        type: types.CDRS_SELECTED_IMPORT,
                        payload: {
                            status: "error",
                            result: getMessageFromResponse(res) || "Error connecting to CDR.",
                        },
                    });
                }

                return res;
            })
            .catch((error) => {
                dispatch({
                    type: types.CDRS_SELECTED_IMPORT,
                    payload: {
                        status: "error",
                        result: getMessageFromResponse(error) || "Error connecting to CDR.",
                    },
                });
                consoleLogger.error(error);
            });
    };
}
