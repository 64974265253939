import * as React from 'react'
import { withTheme } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from "@material-ui/core/TextField"
import IconButton from '@material-ui/core/IconButton'
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import HelpIcon from "@material-ui/icons/Help";
import getEnvironmentName from "../../../../../../../lib//getEnvironmentName";
import {
    delay,
    getFullDateTimeMsDisplay,
    getFullDateDisplay,
    tryParseJSONObject
} from '../../../../../../../lib/utils'
import ReactJson from 'react-json-view'

const CUSTOM_TABLE_CELL_STYLE: React.CSSProperties = {
    padding: '0 4px',
}
const CUSTOM_TABLE_HEADER_CELL_STYLE: React.CSSProperties = {
    ...CUSTOM_TABLE_CELL_STYLE,
    fontSize: '13px',
}
const CUSTOM_TABLE_BODY_CELL_STYLE: React.CSSProperties = {
    ...CUSTOM_TABLE_CELL_STYLE,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'inherit',
}

const CUSTOM_TABLE_ICON_STYLE: React.CSSProperties = {
    height: '16px',
    width: '16px',
    marginLeft: '4px',
    verticalAlign: 'bottom',
    opacity: 0.5
}

type TProps = {
    accounts: any
    apps: any
    environments: any
    parentState: any
    events: any
    muiTheme: any
    ui: any
}

const CustomDialogContent: React.FC<TProps> = (props: TProps) => {
    let hideEvents = ["Record User Information", "Record launch context", "Summary App Complete Time", "Summary Smart Launch Time", "Summary App Ready Time", "Smart Launch", "SMART Launch"]
    let ehrLaunchTimeEvents = ["Obtained Access Token (EHR)", "Authorization Code Request: End", "Authorization Code Request: Start", "Retrieved FHIR Server SMART Configuration", "EHR to interopiO: Launch Sequence Activated"]
    let interopioLaunchTimeEvents = ["Obtained Access Token (interopiO)", "Authorization Code Request Completed", "interopiO to App: Launch Sequence Activated"]
    let events = props.events.filter((e) => {
        if (hideEvents.indexOf(e.event_type) >= 0) {
            return false
        }
        return true
    })

    let ehrHeder = false
    let ioHeader = false
    events = events.map((e, i) => {
        if (i === 0 && ehrLaunchTimeEvents.indexOf(e.event_type) < 0 && interopioLaunchTimeEvents.indexOf(e.event_type) < 0) {
            e.header = "App Launch Events"
        }
        if (ehrLaunchTimeEvents.indexOf(e.event_type) >= 0 && !ehrHeder) {
            e.header = "EHR Launch Time Events"
            ehrHeder = true
        }
        if (interopioLaunchTimeEvents.indexOf(e.event_type) >= 0 && !ioHeader) {
            e.header = "interopiO Launch Time Events"
            ioHeader = true
        }
        return e
    })

    async function exportData(separator) {
        let element = document.createElement('a')
        element.style.display = 'none'

        const data = (events || [])
            .reduce(
                (acc, it) => {
                    return acc + `${it.event_type}${separator}`
                        + `${it.timestamp}${separator}`
                        + `${getFullDateTimeMsDisplay(it.timestamp, props.ui.timeZone)}${separator}`
                        + `${it.duration}${separator}`
                        + `${it.timestamp - props.parentState.selectedAppLaunch.minTime}${separator}`
                        + `${it.gateway_id}${separator}`
                        + `${it.session_id}${separator}`
                        + `${it.auth_principal_username || ""}${separator}`
                        + `${it.launch_context || ""}${separator}`
                        + `\r\n`
                },
                `Event Type${separator}`
                    + `Event Time Timestamp${separator}`
                    + `Event Time${separator}`
                    + `Event Duration(ms)${separator}`
                    + `Accumulated Duration(ms)${separator}`
                    + `Gateway Id${separator}`
                    + `Session Id${separator}`
                    + `User${separator}`
                    + `Launch Context${separator}`
                    + '\r\n'
            )

        const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })
        const url = URL.createObjectURL(blob)
        element.setAttribute('href', url)

        const extension = (separator === ',' ? 'csv' : 'tsv')
        const accountName = props.accounts.selected.data.name;
        const environmentId = props.apps.selected.data.environmentId;
        const environmentName = getEnvironmentName(environmentId, props.environments);
        const clientName = props.apps.selected.data.name;
        const filename = `${accountName}_${environmentName}_${clientName}_${props.parentState.selectedAppLaunch.session_id}-${getFullDateDisplay(Date.now(), props.ui.timeZone)}.${extension}`
        element.setAttribute('download', filename)

        document.body.appendChild(element)
        await delay(1550)
        element.click()
        await delay(1550)
        document.body.removeChild(element)
        element = null
    }

    const CUSTOM_TEXT_FIELD_STYLE: React.CSSProperties = {
        marginTop: "14px",
        color: props.muiTheme.palette.textColor
    }

    const launchCotext = props.events.find((event) => event.launch_context)?.launch_context;
    let user = props.events.find((event) => event.auth_principal_username && event.event_type !== "App Ready" && event.event_type !== "App Launch Complete")?.auth_principal_username || '{}';
    user = tryParseJSONObject(user);
    if (user) {
        Object.keys(user).forEach((k) => user[k] == null && delete user[k]);
    }

    return (
        <DialogContent style={{ minWidth: '552px', background: 'rgb(250, 250, 250)' }}>
            <TextField
                classes={{root: "disabled-text-field"}}
                style={{...CUSTOM_TEXT_FIELD_STYLE}}
                disabled={true}
                fullWidth={true}
                value={getFullDateTimeMsDisplay(props.parentState.selectedAppLaunch.minTime, props.ui.timeZone) || ""}
                label="Start Time"
            />
            <TextField
                classes={{root: "disabled-text-field"}}
                style={{...CUSTOM_TEXT_FIELD_STYLE}}
                disabled={true}
                fullWidth={true}
                value={props.parentState.selectedAppLaunch.session_id || ""}
                label="Session ID"
            />
            <TextField
                classes={{root: "disabled-text-field"}}
                style={{...CUSTOM_TEXT_FIELD_STYLE}}
                disabled={true}
                fullWidth={true}
                value={props.parentState.selectedAppLaunch.gateway_id || ""}
                label="Gateway ID"
            />
            <div
                style={{ display: "flex", alignItems: "end", marginTop: "14px"}}
            >
                <TextField
                    classes={{root: "disabled-text-field"}}
                    style={{ width: "100px" }}
                    disabled={true}
                    // fullWidth={true}
                    // value={props.parentState.selectedAppLaunch.timeToLaunch/1000 + " sec" || ""}
                    label="Launch Times"
                />
                <IconButton
                    style={{height: "36px"}}
                    onClick={() => {
                        const url = "https://support.interopio.com/hc/en-us/articles/12458811312788";
                        window.open(url, "_blank");
                    }}
                >
                    <HelpIcon />
                </IconButton>
            </div>
            <div
                style={{
                    margin: 0,
                    padding: 0,
                    maxWidth: "450px"
                }}
            >
                <div
                    style={{
                        margin: 0,
                        padding: "0 12px",
                        // borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            margin: 0,
                            height: "48px"
                        }}
                    >
                        <div
                            style={{
                                ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                width: '350px',
                            }}
                        >
                            SMART Launch Time
                        </div>
                        <div
                            style={{
                                ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                width: '100px',
                            }}
                        >
                            {props.parentState.selectedAppLaunch.timeToSmartLaunch ? props.parentState.selectedAppLaunch.timeToSmartLaunch/1000 + " sec." : "-"}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        margin: 0,
                        padding: "0 12px",
                        background: props.muiTheme.palette.accent2Color,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            margin: 0,
                            height: "48px"
                        }}
                    >
                        <div
                            style={{
                                ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                width: '350px',
                            }}
                        >
                            App Ready Time
                        </div>
                        <div
                            style={{
                                ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                width: '100px',
                            }}
                        >
                            {props.parentState.selectedAppLaunch.timeToAppReady ? props.parentState.selectedAppLaunch.timeToAppReady/1000 + " sec." : "-"}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        margin: 0,
                        padding: "0 12px",
                        // borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            margin: 0,
                            height: "48px"
                        }}
                    >
                        <div
                            style={{
                                ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                width: '350px',
                            }}
                        >
                            App Launch Complete Time
                        </div>
                        <div
                            style={{
                                ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                width: '100px',
                            }}
                        >
                            {props.parentState.selectedAppLaunch.timeToAppComplete ? props.parentState.selectedAppLaunch.timeToAppComplete/1000 + " sec." : "-"}
                        </div>
                    </div>
                </div>
            </div>
            <TextField
                classes={{root: "disabled-text-field"}}
                style={{...CUSTOM_TEXT_FIELD_STYLE}}
                disabled={true}
                fullWidth={true}
                // value={user || ""}
                label="User"
                // multiline={true}
            />
            <ReactJson src={user}/>
            <TextField
                classes={{root: "disabled-text-field"}}
                style={{...CUSTOM_TEXT_FIELD_STYLE}}
                disabled={true}
                fullWidth={true}
                // value={<ReactJson src={launchCotext}/> || ""}
                label="Launch Context"
            />
            <ReactJson collapseStringsAfterLength={40} style={{maxWidth: "980px", overflow: "auto"}} src={launchCotext}/>
            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <div style={{ margin: "14px 0", color: "rgba(0, 0, 0, 0.38)" }}>
                    Events
                </div>
                <Button
                    disabled={!(props.events?.length > 0)}
                    color="primary"
                    variant="contained"
                    endIcon={<UnarchiveIcon />}
                    style={{ height: '38px' }}
                    onClick={() => {
                        exportData(',')
                    }}
                >
                    Export Events As CSV
                </Button>
            </div>
            {/* Header L2 */}
            <div
                style={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '32px',
                    margin: 0,
                    padding: '0 12px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    background: props.muiTheme.palette.accent2Color,
                }}
            >
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '250px',
                    }}
                >
                    Event Time
                    <ArrowDownward style={{...CUSTOM_TABLE_ICON_STYLE}}/>
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '450px',
                    }}
                >
                    Event
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '100px',
                    }}
                >
                    Event Duration(ms)
                </div>
                <div
                    style={{
                        ...CUSTOM_TABLE_HEADER_CELL_STYLE,
                        width: '100px',
                    }}
                >
                    Accumulated Duration(ms)
                </div>
            </div>

            {/* Body */}
            <div
                style={{
                    margin: 0,
                    padding: 0,
                }}
            >
                {events.map((it) => {
                    return (
                        <div key={it.id}>
                            {it.header ? (
                                <div
                                    style={{
                                        margin: 0,
                                        padding: "0 12px",
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                        fontSize: "14px",
                                        background: props.muiTheme.palette.accent2Color,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            margin: 0,
                                            height: "36px",
                                            justifyContent: "center"
                                        }}
                                    >
                                        {it.header}
                                    </div>
                                </div>
                            ) : null}
                            <div
                                className="session-inspector-log-item-wrapper"
                                style={{
                                    margin: 0,
                                    padding: "0 12px",
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        margin: 0,
                                        height: "48px"
                                    }}
                                >
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            width: '250px',
                                        }}
                                    >
                                        {getFullDateTimeMsDisplay(it.timestamp, props.ui.timeZone)}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            width: '450px',
                                        }}
                                    >
                                        {it.event_type}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            width: '100px',
                                        }}
                                    >
                                        {it.duration}
                                    </div>
                                    <div
                                        style={{
                                            ...CUSTOM_TABLE_BODY_CELL_STYLE,
                                            width: '100px',
                                        }}
                                    >
                                        {it.timestamp - props.parentState.selectedAppLaunch.minTime}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </DialogContent>
    )
}

export default withTheme(CustomDialogContent)
