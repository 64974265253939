import { useState } from "react";
import * as PropTypes from "prop-types";

import Divider from "@material-ui/core/Divider";
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from "@material-ui/icons/MoreVert";

import "./style.less";

const CardMenuBar = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <div
            className={`card-menu-bar ${props.className}`}
            style={props.style}
        >
            <div style={{position: "relative", width: "100%"}}>
                <div
                    className={`bar-title${props.singleLineTitle ? " single-line" : ""}`}
                    onClick={props.onClick}
                >
                    {props.title}
                </div>
            </div>
            {props.menuItems.length ? (
                <div className="bar-menu">
                    <IconButton
                        data-qa={props["data-qa"]}
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        style={{ top: -12, right: -12 }}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={() => setAnchorEl(null)}
                        PaperProps={{ style: { minWidth: 168 }}}
                    >
                        {props.menuItems.map((item, index) => (
                            <div key={`menu-item-${index}`}>
                                {item.dividerTop ? <Divider /> : null}
                                <MenuItem
                                    data-qa={item["data-qa"]}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setAnchorEl(null);
                                        item.onClick && item.onClick();
                                    }}
                                >
                                    {item.leftIcon ? <ListItemIcon>{item.leftIcon}</ListItemIcon> : null}
                                    <Typography variant="inherit" noWrap>
                                        {item.text}
                                    </Typography>

                                </MenuItem>
                                {item.dividerBottom && <Divider />}
                            </div>
                        ))}
                    </Menu>
                </div>
            ) : null}
        </div>
    );
}

CardMenuBar.propTypes = {
    className: PropTypes.string,
    "data-qa": PropTypes.string,
    menuItems: PropTypes.array,
    singleLineTitle: PropTypes.bool,
    style: PropTypes.object,
    title: PropTypes.string,
    onClick: PropTypes.func,
};

CardMenuBar.defaultProps = {
    className: "",
    "data-qa": "",
    menuItems: [],
    singleLineTitle: false,
    style: {},
    title: "",
    onClick: () => {},
};

export default CardMenuBar;
