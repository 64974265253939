import * as React from 'react'
import ReactJson from 'react-json-view'
// import * as PropTypes from 'prop-types'

import withLegacyTheme from '../../../../../../lib/hoc/with-legacy-theme'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import TextField from '@material-ui/core/TextField'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

type TService = {
    description: string
    hook: string
    id: string
    prefetch: {
        observations: string
        patient: string
    }
    title: string
}
type TProps = {
    muiTheme: {
        palette: {
            accent2Color: string
            secondaryTextColor: string
        }
    }
    parentState: {
        data: {
            cdsServices?: Array<TService>
        },
        dataValidation: {
            cdsServices?
        }
    }
}

const ServicesList: React.FC<TProps> = (props: TProps) => {
    const [whichItemIsOpened, setWhichItemIsOpened] = React.useState({})

    if (!props.parentState.data.cdsServices || !!props.parentState.dataValidation.cdsServices) {
        return null
    }

    return (
        <List
            disablePadding
            subheader={
                <ListSubheader component='div'>
                    Services:
                </ListSubheader>
            }
        >
            <ListItem
                disabled
                style={{
                    boxSizing: 'border-box',
                    height: 32,
                    padding: '8px 16px',
                    background: props.muiTheme.palette.accent2Color,
                    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    color: props.muiTheme.palette.secondaryTextColor, fontSize: 14,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                    }}
                >
                    <div style={{ width: '100%' }}>
                        Title
                    </div>
                    <div style={{ width: '100%' }}>
                        ID
                    </div>
                    <div style={{ width: '100%' }}>
                        Hook
                    </div>
                    <div style={{ width: 24, minWidth: 24, maxWidth: 24 }} />
                </div>
            </ListItem>
            {props.parentState.data.cdsServices.map((service, index) => {
                const key = `service-item-${service.id}-${index}`
                const isOpened = Boolean(whichItemIsOpened[key])
                return (
                    <div key={key}>
                        <ListItem
                            button
                            onClick={() => setWhichItemIsOpened((prevState) => ({ ...prevState, [key]: !prevState[key] }))}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        padding: '0 4px 0 0',
                                    }}
                                >
                                    {service.title}
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        padding: '0 4px 0 0',
                                    }}
                                >
                                    {service.id}
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        padding: '0 4px 0 0',
                                    }}
                                >
                                    {service.hook}
                                </div>
                                <div style={{ width: 24, minWidth: 24, maxWidth: 24 }}>
                                    {isOpened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </div>
                            </div>
                        </ListItem>
                        <Collapse
                            in={isOpened}
                            timeout='auto'
                            unmountOnExit
                        >
                            <TextField
                                name={key}
                                value={service.description || ''}
                                placeholder='Description'
                                label='Description'
                                disabled
                                fullWidth
                                multiline
                                classes={{ root: 'disabled-text-field' }}
                            />
                            <ReactJson src={service} />
                            <br />
                        </Collapse>
                        <Divider />
                    </div>
                )
            })}
        </List>
    )
}

// ServicesList.propTypes = {
//     muiTheme: PropTypes.object.isRequired,
//     parentState: PropTypes.object.isRequired,
// }

export default withLegacyTheme()(ServicesList)
