import getHostnameInfo from './getHostnameInfo'

// We are using TypeScript 2.2 and the following code does not work
// export enum ActiveEnvironment {
//     Local = ':LOCAL:',
//     Development = ':DEVELOPMENT:',
//     Test = ':TEST:',
//     Production = ':PRODUCTION:',
// }

export type ActiveEnvironment = ':LOCAL:' | ':DEVELOPMENT:' | ':TEST:' | ':PRODUCTION:' | ':DEV-AZURE:' /* | ':ESCROW:' */

export default function (): ActiveEnvironment {
    const {
        domain,
        type,
    }: {
        domain: string;
        type: string;
    } = getHostnameInfo()

    if (type === 'local') {
        return ':LOCAL:'
    }
    if (domain.endsWith('-dev')) {
        return ':DEVELOPMENT:'
    }
    if (domain.endsWith('-test')) {
        return ':TEST:'
    }
    // if (domain.endsWith('-escrow')) {
    //     return ':ESCROW:'
    // }
    if (domain.endsWith('-dev.mckesson')) {
        return ':DEV-AZURE:'
    }

    return ':PRODUCTION:'
}
