import * as React from "react";
import * as PropTypes from "prop-types";
import * as validateData from "../../../../../../../lib/validate-apps-data";
import {TextField} from "@material-ui/core";

export default class extends React.Component<any, any> {
    public static propTypes = {
        state: PropTypes.object.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.props.onSetState(
            {
                isNextWStepEnabled: false,
                dataValidation: {}
            },
            this.validateData
        );
    }

    public componentDidUpdate(prevProps) {
        const data_string = JSON.stringify(this.props.state.data);
        const prevData_string = JSON.stringify(prevProps.state.data);
        if (data_string !== prevData_string) {
            this.validateData();
        }
    }

    public render() {
        return <div style={{padding: "0 24px"}}>
            <TextField name="scopes" value={this.props.state.data.scopes || ""} placeholder="Space separated list of scopes eg. 'launch patient/*.* openid profile'" label="Scopes"
                error={!!this.props.state.dataValidation.scopes} helperText={this.props.state.dataValidation.scopes} fullWidth multiline
                onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(/[\r\n]+/g, ' ').trim();
                    this.props.onSetState({
                        data: {
                            ...this.props.state.data,
                            scopes: sanitizedValue
                        }
                    })}}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                    }
                }}
            />
        </div>;
    }

    private validateData = () => {
        const dataValidation = validateData.onWStep3(this.props.state);
        this.props.onSetState(
            {
                dataValidation: {
                    ...this.props.state.dataValidation,
                    ...dataValidation
                }
            },
            () => {
                const isValid = Object.keys(this.props.state.dataValidation).reduce((acc, key) => acc && !this.props.state.dataValidation[key], true);
                if (isValid !== this.props.state.isNextWStepEnabled) {
                    this.props.onSetState({isNextWStepEnabled: isValid});
                }
            }
        );
    }
}
