/* eslint-disable react/prop-types */
import {getHourAndMinuteDisplay, getMonthAndDayDisplay, getMonthDisplay, getWeekDayDisplay, getNumberLocaleString} from "../../../../../../../lib/utils";
import * as React from "react";
import * as PropTypes from "prop-types";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import "./style.less";

const Recharts = require("recharts");
const {CartesianGrid, XAxis, YAxis, Line, LineChart, Tooltip, Legend, ResponsiveContainer} = Recharts;

const Component: React.FunctionComponent<any> = props => {
    const [selectedActivation, setSelectedActivation] = React.useState('')

    let data = props.data;
    if (props.selector && selectedActivation) {
        data.series = [selectedActivation]
    }
    let endTime = props.endTime;
    if (props.state.timeframe === 0 || props.state.timeframe === 86400000) {
        endTime = props.state.startTime + 82800000;
    }

    if (props.data === null) {
        return <div>
            <div style={{padding: "20px 20px 10px", color: "gray"}}>{props.title}</div>
            <div className="chart-card-text loading" style={props.styles.cardChartText}>
                <CircularProgress/>
            </div>
        </div>;
    }

    // calculate the width of the y-axis based on the largest entry
    let largestEntry = 0;
    props.data.entries.forEach(entry => {
        for (let key in entry) {
            if (entry[key] > largestEntry && key !== "name") {
                largestEntry = entry[key];
            }
        }
    });
    const yAxisWidth = largestEntry.toString().length * 6 + 60;

    return <div>
        <div style={{padding: "20px 20px 10px", color: "gray", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div>{props.title}</div>
            {props.selector ? (
                <FormControl style={{ minWidth: "220px" }}>
                    <InputLabel htmlFor="select-activation">Select an activation</InputLabel>
                    <Select
                        value={selectedActivation}
                        onChange={(e: any) => setSelectedActivation(e.target.value)}
                        inputProps={{
                            name: 'activation',
                            id: 'select-activation',
                        }}
                    >
                        {props.state.allActivations.map(activation => {
                            return (<MenuItem key={activation.name} value={activation.name}>{activation.name}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            ) : null}
        </div>
        <ResponsiveContainer width="99%" height="90%" minHeight={340}>
            <LineChart data={props.data.entries} width={904} height={300} margin={props.styles.chartMargins}>
                <XAxis dataKey="name" scale="time" type="number" domain={[props.state.startTime, endTime]}
                    tickFormatter={unixTime => {
                        if (props.state.timeframe === 604800000) {
                            return getWeekDayDisplay(unixTime, props.ui.timeZone);
                        }
                        if (props.state.timeframe > 10713600000) {
                            return getMonthDisplay(unixTime, props.ui.timeZone);
                        }
                        return props.state.timeframe > 86400000
                            ? getMonthAndDayDisplay(unixTime, props.ui.timeZone)
                            : getHourAndMinuteDisplay(unixTime, props.ui.timeZone);
                    }}/>
                <YAxis allowDecimals={false}
                    tickFormatter={value => {
                        return `${getNumberLocaleString(value)}`
                    }}
                    width={yAxisWidth} // Increase this value to leave more space for the yAxis label
                />
                <CartesianGrid strokeDasharray="3 3"/>
                <Tooltip labelStyle={{fontSize: "14px"}} itemStyle={{fontSize: "14px"}}
                    formatter={value => {
                        return `${getNumberLocaleString(value)}`
                    }}
                    labelFormatter={value => {
                        return props.state.timeframe > 86400000
                            ? getMonthAndDayDisplay(value, props.ui.timeZone)
                            : getHourAndMinuteDisplay(value, props.ui.timeZone);
                    }}/>
                {props.legend && <Legend/>}
                {data.series.map(item => <Line key={item} dataKey={item} type="monotone" stroke={props.state.assignedColors[item]}/>)}
            </LineChart>
        </ResponsiveContainer>
    </div>;
}

Component.propTypes = {
    state: PropTypes.object.isRequired,
    data: PropTypes.object,
    styles: PropTypes.object.isRequired,
    launchesOverTime_refresh: PropTypes.func.isRequired,
    legend: PropTypes.bool,
    title: PropTypes.string,
    selector: PropTypes.bool
};

Component.defaultProps = {
    legend: true,
    title: "App Launches",
    selector: false
};

export default Component;
