import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { createMachine, assign } from 'xstate'
import { useMachine } from '@xstate/react'
import axiosWrapper from '../../../../../lib/axiosWrapper'
import getAppsCallUrl from '../../../../../lib/getAppsCallUrl'
import getHostnameInfo from '../../../../../lib/getHostnameInfo'
import getLocationInfo from '../../../../../lib/getLocationInfo'
import * as actions from '../../../../../redux/actions'
import { INIT_STATE as initParentState } from '..'

const uuidv4 = require('uuid/v4')

type TProps = {
    apps: any
    config: any
    location: any
    parentState: {
        status: string
    }
    appsReadAll: Function
    onSetParentState: Function
}

const InitMachine = (props: TProps) => {
    const [state, send] = useMachine(() => createMachine({
        id: 'init',
        initial: 'start',
        context: {
            appsNoActivations: [],
        },
        states: {
            start: {
                on: {
                    SEND_REQUESTS: 'sending_requests',
                },
            },
            sending_requests: {
                invoke: {
                    src: 'sendRequests',
                    onDone: {
                        target: 'processing_data',
                        actions:
                            assign({
                                appsNoActivations: (_, event) => event.data?.data || initParentState.appsNoActivations,
                            }),
                    },
                    onError: {
                        target: 'wait_redux',
                    }
                },
            },
            wait_redux: {
                after: {
                    200: { target: 'processing_data' }
                },
            },
            processing_data: {
                on: {
                    DONE: 'idle',
                },
            },
            idle: {
                on: {
                    RESTART: 'start',
                },
            },
        },
    }), {
        services: {
            sendRequests: () => {
                const { accountId } = getHostnameInfo()
                const { env } = getLocationInfo(props.location, props.config)

                const appsAllStatus = props.apps.all.status
                const appsUrl = getAppsCallUrl(accountId, env)
                if (![/* 'ready', */ 'pending'].includes(appsAllStatus)) {
                    props.appsReadAll(props.config, appsUrl)
                }

                return axiosWrapper(props.config.envApi, `${appsUrl}/noActivations`, 'GET')
            }
        }
    })

    const { onSetParentState } = props

    // start
    React.useEffect(() => {
        if (state.matches('start')) {
            if (props.parentState.status === 'init') {
                send('SEND_REQUESTS')
            }
        }
    }, [props.parentState.status, send, state])

    // processing_data
    React.useEffect(() => {
        if (state.matches('processing_data')) {
            onSetParentState({
                ...initParentState,
                status: 'ready',
                data: {
                    ...initParentState.data,
                    clientId: uuidv4(),
                },
                appsNoActivations: state.context.appsNoActivations,
            }, () => {
                send('DONE')
            })
        }
    }, [onSetParentState, props.apps.all.status, send, state])

    // idle
    React.useEffect(() => {
        if (state.matches('idle')) {
            if (props.parentState.status === 'init') {
                send('RESTART')
            }
        }
    }, [props.parentState.status, send, state])

    // console.log('::: current state:', state)

    return null
}

const mapStateToProps = (state, ownProps) => ({
    apps: state.apps,
    config: state.config,
    ...ownProps,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    appsReadAll: actions.appsReadAll,
}, dispatch)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InitMachine))
