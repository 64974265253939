import axiosWrapper from "./axiosWrapper";
import checkEmail from "./checkEmail";
import checkPassword from "./checkPassword";
import * as validateId from "./validate-id";

export function onWStep1(state: any) {
    let email = "";
    let firstName = "Required field";
    let lastName = "Required field";
    if (state.data.email) {
        email = checkEmail(state.data.email) ? "Please provide a valid email" : "";
    }

    if (state.data.firstName) {
        firstName = "";
    }

    if (state.data.lastName) {
        lastName = "";
    }

    return {
        email,
        firstName,
        lastName
    };
}

export function onWStep3(state: any) {
    let firstName = "Required field";
    let lastName = "Required field";
    let password = "";

    if (state.data.firstName.length === 0 && state.data.lastName.length === 0 && state.data.password.length === 0) {
        firstName = "";
        lastName = "";
        password = "";
    } else {
        if (state.data.firstName.length > 60) {
            firstName = "Names cannot be longer than a 60 characters"
        }
        if (state.data.firstName) {
            firstName = "";
        }

        if (state.data.lastName.length > 60) {
            lastName = "Names cannot be longer than a 60 characters"
        }
        if (state.data.lastName) {
            lastName = "";
        }

        if (!state.data.password && (state.data.firstName || state.data.lastName)) {
            password = "Required field";
        }
        else {
            password = checkPassword(state.data.password);
        }
    }

    return {
        firstName,
        lastName,
        password,
    };
}

function _onWStep4Async(
    state: any,
    config: any,
) {
    const accountName = !state.data.accountName && state.data.accountId ? "Required field" : "";

    let accountId = "";
    accountId = validateId.isUrlFriendly(state.data.accountId) ? accountId : "The ID can only contain: lower case letters of the English alphabet, numbers (0-9), hyphen/minus sign (-), it must start with a letter and end with a letter or number, not a hyphen";
    accountId = validateId.isNotTooLong(state.data.accountId) ? accountId : "This ID is too long";
    accountId = validateId.isNotTooShort(state.data.accountId) ? accountId : "This ID is too short";
    accountId = state.data.accountName && !state.data.accountId ? "Required field" : accountId;

    let accountType = "";
    accountType = state.data.accountType ? "" : "Required field";

    let accountProgram = "";
    accountProgram = state.data.accountType === "COMMERCIAL" || state.data.accountProgram ? "" : "Required field";

    if (accountId) {
        return Promise.resolve({
            accountName,
            accountId,
            accountType,
            accountProgram,
        });
    }

    return axiosWrapper(config.accountApi, `accounts/public?path=${state.data.accountId}`, "GET", undefined, undefined, undefined, false)
        .then(() => ({
            accountName,
            accountId: "This account ID already exists",
            accountType,
            accountProgram,
        }))
        .catch((err) => {
            if (err.response.status === 403) {
                return ({
                    accountName,
                    accountId: "This account ID already exists",
                    accountType,
                    accountProgram,
                })
            }
            return ({
                accountName,
                accountType,
                accountProgram,
            })
        });
}

let wstep4Timer;
export function onWStep4Async(
    state: any,
    config: any,
) {
    clearTimeout(wstep4Timer);
    return new Promise((resolve) => {
        wstep4Timer = setTimeout(() => resolve(_onWStep4Async(state, config)), 550);
    });
}

export function onEditGeneral(state: any) {
    const accountName = !state.data.name && state.data.path ? "Required field" : "";
    const customerContactEmail = state.data.customer_contact_email?.length > 0 && checkEmail(state.data.customer_contact_email) ? "Please provide a valid email" : "";


    return {
        accountName,
        customerContactEmail
    };
}

export function onRequestAccessWStep1(state: any) {
    let email = "";
    let firstName = "Required field";
    let lastName = "Required field";
    if (state.data.email) {
        email = checkEmail(state.data.email) ? "Please provide a valid email" : "";
    }

    if (state.data.firstName) {
        firstName = "";
    }

    if (state.data.lastName) {
        lastName = "";
    }

    return {
        email,
        firstName,
        lastName
    };
}
