/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import {getPath} from "../../../../../../lib/utils";
import {List, ListItem} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import SubscriptionItem from "./SubscriptionItem";
import Table from "../../../../../Widgets/Table";

const Component: React.FunctionComponent<any> = props => {
    let subscriptions = getPath(props, "state.subscriptions.data") || [];
    if (!props.state.showAll) {
        subscriptions = subscriptions.slice(0, 100);
    }
    const subscriptionsList = subscriptions.map((subscription, index) => <SubscriptionItem key={`${subscription.timestamp}-${index}`} {...props} subscription={subscription}/>);

    let showAll;
    if (!props.state.showAll && subscriptions.length >= 100) {
        showAll = <ListItem className={`gw-subscriptions-tt-item`} style={{fontSize: "14px", lineHeight: "14px", padding: "16px 0 16px 16px", border: null}}
            onClick={() => props.onSetState({showAll: true})}>
            <div className="" style={{textAlign: "center"}}>
                <ExpandMore style={{verticalAlign: "bottom", height: "16px", width: "16px"}}/>
                Expand up to 1000 Subscriptions
                <ExpandMore style={{verticalAlign: "bottom", height: "16px", width: "16px"}}/>
            </div>
        </ListItem>;
    }

    const columns = [
        {
            name: "end",
            size: 1.11,
            label: "End",
            sort: () => props.onSetState({sortBy: {["end"]: !getPath(props, "state.sortBy.end")}}),
            order: getPath(props, "state.sortBy.end")
        },
        {
            name: "status",
            size: .92,
            label: "Status",
            sort: () => props.onSetState({sortBy: {["status"]: !getPath(props, "state.sortBy.status")}}),
            order: getPath(props, "state.sortBy.status")
        },
        {
            name: "criteria",
            size: 2.45,
            label: "Criteria",
            sort: () => props.onSetState({sortBy: {["criteria"]: !getPath(props, "state.sortBy.criteria")}}),
            order: getPath(props, "state.sortBy.criteria")
        },
        {
            name: "channelType",
            size: 1.11,
            label: "Channel Type",
            sort: () => props.onSetState({sortBy: {["channelType"]: !getPath(props, "state.sortBy.channelType")}}),
            order: getPath(props, "state.sortBy.channelType")
        }
    ];

    return <Table columns={columns} style={{marginTop: 0}}>
        <List>
            {subscriptionsList}
            {showAll}
        </List>
    </Table>;
}

Component.defaultProps = {
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
