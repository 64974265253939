import UserDirAddNewWizard from './UserDirAddNewWizard'
import UserDirDeleteConfirm from './UserDirDeleteConfirm'
import { TContext as TParentContext } from '..'

type TProps = {
    parentContext: TParentContext
    onClose: () => void
    onReinit: () => void
}

const Dialogs = (props: TProps) => {
    switch (props.parentContext.dialog) {
        case ':ADD_NEW_WIZARD:':
            return (
                <UserDirAddNewWizard
                    onClose={props.onClose}
                    onReinit={props.onReinit}
                />
            )
        case ':DELETE_CONFIRM:':
            return (
                <UserDirDeleteConfirm
                    parentContext={props.parentContext}
                    onClose={props.onClose}
                    onReinit={props.onReinit}
                />
            )
        default:
            return null
    }
}

export default Dialogs
