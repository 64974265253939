import * as React from "react";
import * as PropTypes from "prop-types";
import DeleteUserConfirm from "./DeleteUserConfirm/";
import InviteUser from "./InviteUser/";
import UserEnvironmentPermissions from "./UserEnvironmentPermissions";
import SSOEdit from "./SSOEdit";
import ChangeUserTypeConfirm from "./ChangeUserTypeConfirm";
import EnableMFA from "./EnableMFA";
import ResetMFA from "./ResetMFA";

export default class extends React.Component<any, any> {
    public static propTypes = {
        state: PropTypes.object.isRequired,
        initialize: PropTypes.func.isRequired
    };

    public render() {
        const propSet = {...this.props, onClose: this.props.initialize};

        let content = null;
        switch (this.props.state.dialog) {
            case ":INVITE_USER:":
                content = <InviteUser {...propSet} />;
                break;
            case ":DELETE_USER_CONFIRM:":
                content = <DeleteUserConfirm {...propSet} />;
                break;
            case ":USER_ENVIRONMENT_PERMISSIONS:":
                content = <UserEnvironmentPermissions {...propSet} />;
                break;
            case ":EDIT_SSO:":
                content = <SSOEdit {...propSet} />;
                break;
            case ":CHANGE_USER_TYPE:":
                content = <ChangeUserTypeConfirm {...propSet} />;
                break;
            case ":ENABLE_USER_MFA:":
                content = <EnableMFA {...propSet} />;
                break;
            case ":RESET_USER_MFA:":
                content = <ResetMFA {...propSet} />;
                break;
        }

        return content;
    }
}
