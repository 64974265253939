import * as PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";

const CustomDialogContent = (props) => {
    let content = props.error
        ? <div data-qa="delete-confirm-error">
            {props.error}
        </div>
        : <span>
            Are you sure you want to {props.action} {props.type}&nbsp;
            <strong data-qa="item-for-deleting-name">
                {props.delete.name}
            </strong> ?
            <br />
            {props.description}

            {props.confirmName && (
                <TextField
                    name="confirm-name" value={props.parentState.confirmName || ""}
                    placeholder={`Type the name of the ${props.type}`}
                    autoComplete="off" autoFocus={false} fullWidth
                    onChange={(event) => props.onSetParentState((prevParentState) => ({ ...prevParentState, confirmName: event.target.value }))}
                />
            )}
        </span>;

    return <DialogContent style={{ minWidth: 552, padding: 24, background: "rgb(250, 250, 250)" }}>
        {content}
    </DialogContent>
}

CustomDialogContent.propTypes = {
    action: PropTypes.string.isRequired,
    confirmName: PropTypes.bool.isRequired,
    delete: PropTypes.object.isRequired,
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired,
    error: PropTypes.string.isRequired,
    parentState: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,

    onSetParentState: PropTypes.func.isRequired,
};

export default CustomDialogContent;
