import * as React from "react";
import * as PropTypes from "prop-types";
import {getPath} from "../../../../../../lib/utils/";
import UserItem from "../../UsersAndPermissions/UserItem/";

const Component: React.FunctionComponent<any> = props => {
    const userNames: string = `${getPath(props, "state.selectedUser.first_name")} ${getPath(props, "state.selectedUser.last_name")}`;

    return <div>
        <p style={{color: props.muiTheme.palette.textColor, textAlign: "center"}}>
            <big>
                <span>Are you sure you want to delete</span>&nbsp;
                <strong style={{color: props.muiTheme.palette.accent1Color}}>{userNames}</strong>&nbsp;
                <span>from the account?</span>
            </big>
        </p>
        <UserItem {...props} user={props.state.selectedUser} showMenu={false}/>
    </div>
}

Component.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,

    onSetState: PropTypes.func.isRequired
};

export default Component;
