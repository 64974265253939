import * as React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../../../redux/actions";
import {isMe} from "../../../../lib/users-helpers";
import {getRoleCodeByRoleId, isAccountOwner} from "../../../../lib/user-roles-helpers";
import {getPath} from "../../../../lib/utils/";
import consoleLogger from "../../../../lib/consoleLogger";
import withLegacyTheme from "../../../../lib/hoc/with-legacy-theme";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress/";
import Dialogs from "./Dialogs/";
import UsersAndPermissions from "./UsersAndPermissions/";
import SSOConfig from "./SSOConfig/";

const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "wait" | "recalc" | "e-init"
    users: [],
    selectedUser: {},
    filters: {
        user: ""
    },
    ssoData: {
        enabled: 0,
        entryPoint: "",
        issuer: "",
        spIssuer: "",
        cert: "",
        buttonLabel: "",
        authForced: "OFF"
    },
    dataValidation: {
        entryPoint: "",
        issuer: "",
        cert: "",
    },
    sortBy: {
        role: false
    },
    mode: ":VIEW:", // ":VIEW:" | ":EDIT:"
    dialog: "" // "" | ":INVITE_USER:" | ":DELETE_USER_CONFIRM:" | ":USER_ENVIRONMENT_PERMISSIONS:"
};

let usersTimer;

class Component extends React.Component<any, any> {
    public static propTypes = {
        accounts: PropTypes.object.isRequired,
        config: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        accountsAccountUsersRead: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public componentDidMount() {
        this.initialize();
    }

    public componentDidUpdate(_, prevState) {
        const filters_string = JSON.stringify(this.state.filters);
        const prevFilters_string = JSON.stringify(prevState.filters);
        const sortBy_string = JSON.stringify(this.state.sortBy);
        const prevSortBy_string = JSON.stringify(prevState.sortBy);

        if ((filters_string !== prevFilters_string) || (sortBy_string !== prevSortBy_string)) {
            this.setState(
                {status: "recalc"},
                () => this.getUsers()
                    .then((users) => {
                        this.setState({
                            status: "ready",
                            users
                        });
                    })
            );
        }
    }

    public render() {
        return <div style={{width: "100%"}}>
            {/* Main content --------------------------------- */}
            <div className="container">
                {["ready", "recalc", "wait", "e-init"].indexOf(this.state.status) < 0
                    ? <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
                    : <div>
                        <SSOConfig {...this.props} state={this.state} initialize={this.initialize} onSetState={this.onSetState}/>
                        <UsersAndPermissions {...this.props} state={this.state} initialize={this.initialize} onSetState={this.onSetState}/>
                    </div>}
            </div>

            {/* Dialogs ------------------------------------------------ */}
            {["ready", "wait", "e-init"].indexOf(this.state.status) > -1 && <Dialogs {...this.props} state={this.state} initialize={this.initialize} onSetState={this.onSetState}/>}
            <br/>
        </div>;
    }

    // Event handlers ----------------------------------------------------------
    private onSetState = (state, cb) => this.setState(state, cb);

    // Helpers -----------------------------------------------------------------
    private getFilteredUsers(users = []) {
        let filtered = [...users];

        // *** User (name) filter
        filtered = filtered.filter((user) => {
            const firstName = (user.first_name || "").toLowerCase();
            const lastName = (user.last_name || "").toLowerCase();
            const userEmail = (user.email || "").toLowerCase();
            const filter = (getPath(this.state, "filters.user") || "").toLowerCase();
            return firstName.includes(filter) || lastName.includes(filter) || userEmail.includes(filter);
        });

        return filtered;
    }

    private getSortedUsers(users = []) {
        const sortBy = this.state.sortBy;
        let sorted = [...users];

        // Sort by "user"
        if (sortBy.user !== undefined) {
            const sortable = users.filter((user) => user.name);
            const unsortable = users.filter((user) => !user.name);
            if (sortBy.user === true) {
                sortable.sort((a, b) => {
                    const nameA = ("" + a.name).toLowerCase();
                    const nameB = ("" + b.name).toLowerCase();
                    if (nameA < nameB) return -1;
                    else if (nameA > nameB) return 1;
                    else return 0;
                });
            } else if (sortBy.user === false) {
                sortable.sort((a, b) => {
                    const nameA = ("" + a.name).toLowerCase();
                    const nameB = ("" + b.name).toLowerCase();
                    if (nameA < nameB) return 1;
                    else if (nameA > nameB) return -1;
                    else return 0;
                });
            }
            sorted = [...sortable, ...unsortable];
        }

        // Sort by "role"
        if (sortBy.role !== undefined) {
            if (sortBy.role === true) {
                sorted.sort((a, b) => {
                    const roleA = getRoleCodeByRoleId(this.props.config, getPath(a, "userroles.0.role_id"));
                    const roleB = getRoleCodeByRoleId(this.props.config, getPath(b, "userroles.0.role_id"));
                    if (roleA < roleB) return -1;
                    else if (roleA > roleB) return 1;
                    else return 0;
                });
            } else if (sortBy.role === false) {
                sorted.sort((a, b) => {
                    const roleA = getRoleCodeByRoleId(this.props.config, getPath(a, "userroles.0.role_id"));
                    const roleB = getRoleCodeByRoleId(this.props.config, getPath(b, "userroles.0.role_id"));
                    if (roleA < roleB) return 1;
                    else if (roleA > roleB) return -1;
                    else return 0;
                });
            }
        }

        // Sort by "status"
        if (sortBy.status !== undefined) {
            if (sortBy.status === true) {
                sorted.sort((a, b) => {
                    if (a.status < b.status) return -1;
                    else if (a.status > b.status) return 1;
                    else return 0;
                });
            } else if (sortBy.status === false) {
                sorted.sort((a, b) => {
                    if (a.status < b.status) return 1;
                    else if (a.status > b.status) return -1;
                    else return 0;
                });
            }
        }

        return sorted;
    }

    private _getUsers = () => {
        let users: Array<any> = [...getPath(this.props, "accounts.accountUsers.data") || []];

        // 0. Exclude current user
        users = users.filter((user) => !isMe(this.props.login, user));

        // 1. Filter
        users = this.getFilteredUsers(users);

        // 2. Sort
        users = this.getSortedUsers(users);

        // X. Show only the first 20 items -- just to test if that improves performance
        // users = users.slice(0, 20);

        return Promise.resolve(users);
    }

    private getUsers = () => {
        clearTimeout(usersTimer);
        return new Promise((resolve) => {
            usersTimer = setTimeout(() => resolve(this._getUsers()), 550);
        });
    }

    private fetchAccountUsers = () => isAccountOwner(this.props.login, this.props.config) ? this.props.accountsAccountUsersRead(this.props.config) : Promise.resolve();

    // Initialize --------------------------------------------------------------
    private initialize = () => {
        this.setState(
            {status: "init"},
            () => {
                this.fetchAccountUsers()
                    .then(() => this.getUsers())
                    .then((users) => {
                        this.setState({
                            ...INIT_STATE,
                            status: "ready",
                            users
                        }, async () => {
                            const currentUser = await this.props.usersGetCurrent();
                            if (currentUser && currentUser.data) {
                                this.props.loginSet({
                                    status: "logged-in",
                                    data: getPath(currentUser, "data") || {},
                                });
                            }
                            this.props.accountsSsoConfigRead(this.props.config)
                                .then((res) => {
                                    if (res.data) {
                                        this.setState({
                                            status: "ready",
                                            ssoData: res.data
                                        });
                                    } else {
                                        this.setState({
                                            status: "ready"
                                        });
                                    }
                                })
                                .catch((reason) => consoleLogger.error(":::", reason));
                        });
                    }
                    )
                    .catch((reason) => consoleLogger.error(":::", reason));
            }
        );
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Component));
