import {
    checkNotAnEmptyArray,
    getPath,
} from "./utils/"

const DEFAULT_AUTHENTICATION_TYPE: string = "NONE";

const SUPPORTED_FHIR_VERSIONS: Array<string> = [
    "1.0.2",
    "3.0.1",
    "3.0.2",
    "4.0.0",
    "4.0.1",
];

// Check if `conformance.rest[any].security.service[any]`
// has a `system` of `http://hl7.org/fhir/restful-security-service`
// and a `code` of `SMART-on-FHIR`

function getRests(metadata: any): Array<any> {
    const rest = getPath(metadata, "rest");
    return checkNotAnEmptyArray(rest) ? rest : [];
}

function getSecurities(rests: Array<any>): Array<any> {
    const securities: Array<any> = [];
    rests.forEach((rest: any): void => {
        const security = getPath(rest, "security");
        if (security) securities.push(security);
    });
    return securities;
}

function getServices(securities: Array<any>): Array<any> {
    let services: Array<any> = [];
    securities.forEach((security: any): void => {
        const moreServices = getPath(security, "service");
        if (checkNotAnEmptyArray(moreServices)) services = [...services, ...moreServices];
    });
    return services;
}

function getCodings(services: Array<any>): Array<any> {
    let codings: Array<any> = [];
    services.forEach((service: any): void => {
        const moreCodings = getPath(service, "coding");
        if (checkNotAnEmptyArray(moreCodings)) codings = [...codings, ...moreCodings];
    });
    return codings;
}

function hasAnyCode(
    codings: Array<any>,
    system: string,
    code: string,
): boolean {
    return codings.some((coding: any): boolean => {
        const codingSystem: string = getPath(coding, "system") || "";
        const codingCode: string = getPath(coding, "code") || "";
        return codingSystem === system && codingCode === code;
    });
}

export function getAuthenticationType(metadata: any): string {
    // rests = RESTS; // Mock data
    let rests: Array<any> = getRests(metadata);

    const securities: Array<any> = getSecurities(rests);
    const services: Array<any> = getServices(securities);
    const codings: Array<any> = getCodings(services);
    // console.log("::: codings:", codings.length, typeof codings[0]);

    const system = "http://hl7.org/fhir/restful-security-service";
    const code = "SMART-on-FHIR";
    const isSofAuthorizationType: boolean = hasAnyCode(codings, system, code);

    return isSofAuthorizationType ? "SMART_ON_FHIR" : DEFAULT_AUTHENTICATION_TYPE;
}

export function getFhirVersion(metadata: any): string {
    return getPath(metadata, "fhirVersion") || "";
}

export function isFhirVersionSupported(version: string): boolean {
    return SUPPORTED_FHIR_VERSIONS.includes(version);
}

export function getFhirVersionName(version: string): string {
    if (version === "1.0.2") {
        return "DSTU2"
    }
    else if (version === "3.0.2") {
        return "STU3"
    }
    else if (version === "4.0.1" || version === "4.0.0") {
        return "R4"
    }
    else return "";
}

// const RESTS = [
//     {
//         security: {
//             service: [
//                 {
//                     coding: [
//                         {
//                             "system": "http://hl7.org/fhir/restful-security-service",
//                             "code": "SMART-on-FHIR",
//                             "display": "0",
//                         },
//                         {
//                             "system": "http://hl7.org/fhir/restful-security-service",
//                             "code": "SMART-on-FHIR",
//                             "display": "1",
//                         },
//                     ],
//                 },
//                 {
//                     coding: [
//                         {
//                             "system": "http://hl7.org/fhir/restful-security-service",
//                             "code": "SMART-on-FHIR",
//                             "display": "2",
//                         },
//                         {
//                             "system": "http://hl7.org/fhir/restful-security-service",
//                             "code": "SMART-on-FHIR",
//                             "display": "3",
//                         },
//                     ],
//                 },
//             ],
//         },
//     },
//     {
//         security: {
//             service: [
//                 {
//                     coding: [
//                         {
//                             "system": "http://hl7.org/fhir/restful-security-service",
//                             "code": "SMART-on-FHIR",
//                             "display": "4",
//                         },
//                         {
//                             "system": "http://hl7.org/fhir/restful-security-service",
//                             "code": "SMART-on-FHIR",
//                             "display": "5",
//                         },
//                     ],
//                 },
//                 {
//                     coding: [
//                         {
//                             "system": "http://hl7.org/fhir/restful-security-service",
//                             "code": "SMART-on-FHIR",
//                             "display": "6",
//                         },
//                         {
//                             "system": "http://hl7.org/fhir/restful-security-service",
//                             "code": "SMART-on-FHIR",
//                             "display": "7",
//                         },
//                     ],
//                 },
//             ],
//         },
//     },
// ];
