import * as React from "react";
import * as PropTypes from "prop-types";
import {Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import CenteredCircularProgress from "../../../../../Widgets/CenteredCircularProgress";
import Actions from "./Actions";
import Content from "./Content";
import Title from "./Title";

export default class extends React.Component<any, any> {
    public static propTypes = {
        muiTheme: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        onSetState: PropTypes.func.isRequired
    };

    public render() {
        return <Dialog open style={{paddingTop: "5px", zIndex: 1250}} onClose={this.props.onClose}>
            <DialogTitle style={{height: "48px", background: this.props.muiTheme.palette.primary1Color, color: "#fff", lineHeight: "48px", fontSize: "24px", fontWeight: 600}}>
                <div>
                    <Title {...this.props} />
                </div>
            </DialogTitle>
            <DialogContent style={{background: "rgb(250, 250, 250)", paddingTop: "24px"}}>
                {this.props.state.status !== "ready"
                    ? <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
                    : <Content {...this.props} />}
            </DialogContent>
            <DialogActions style={{padding: "0 24px 24px", background: "rgb(250, 250, 250)"}}>
                <Actions onCancel={this.props.onClose}{...this.props} />
            </DialogActions>
        </Dialog>;
    }
}
