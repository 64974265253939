import { withRouter } from "react-router"
import { connect } from 'react-redux'
import type { State } from 'xstate'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import InputLabel from '@material-ui/core/InputLabel'
import * as features from '../../../../../../lib/feature-helpers'
import type { TContext, TSource } from '..'

type TProps = {
    accounts: any
    features: any
    state: State<TContext, any, any, any, any>
    ui: any
    getSources: (
        // eslint-disable-next-line no-unused-vars
        context: TContext,
        // eslint-disable-next-line no-unused-vars
        configurationType: TContext['data']['configuration']['type'] | '',
    ) => TSource[]
    send: Function
}

const WStep2 = (props: TProps) => {
    const featureSmileCdrCustomization = features.check(':SMILE_CDR_CUSTOMIZATION:', props.features.data)

    const isGatewayTypeR4 = props.state.context.data.gatewayType === 'FHIR_R4__4_0_1'
    // const subscription = (props.accounts.selected?.data?.interopio_subscription || '').toLowerCase()
    const tier = (props.accounts.selected?.data?.tier || '').toLowerCase()
    const tierNum = Number(tier.split(' ')[1])
    const isTier3Plus = tierNum >= 3
    // const isHl7MessagesEnabled = isGatewayTypeR4 && (isTier3Plus || subscription.includes('team developer'))
    const isHl7MessagesEnabled = isGatewayTypeR4 && isTier3Plus
    const configurationType = props.state.context.data.configuration?.type || ''
    const sources = props.getSources(props.state.context, configurationType)
    const defaultSourceId = props.state.context.data.configuration?.defaultDataSource || ':NO_DEFAULT_SOURCE:'
    const defaultSourceObject: Partial<ReturnType<typeof props.getSources>[number]> = sources.find((src) => src.id === defaultSourceId) || {}
    const defaultSourceUserStrategy = defaultSourceObject.userStrategy || ''
    const defaultSourceGatewayAuthenticationTypeOptions = defaultSourceObject.gatewayAuthenticationTypeOptions || ['NONE']

    const selectedDirectoryId = props.state.context.data.userDirectory.directoryId || 'NONE'
    const userDirsIo = props.state.context.userDirsSlim
        .filter((userDir) => userDir.directoryType === 'INTEROPIO_USERS')

    return (
        <div style={{ padding: '0 24px' }}>
            <FormControlLabel
                label={`Caching ${props.state.context.data.configCacheMinutes ? 'Enabled' : 'Disabled'}`}
                control={(
                    <Switch
                        checked={!!props.state.context.data.configCacheMinutes}
                        color="primary"
                        onChange={(event) => {
                            props.send('SET_CONFIG_CACHE_MINUTES', { configCacheMinutes: event.target.checked })
                        }}
                    />
                )}
            />
            <br />

            <FormControlLabel
                label="Enable Incoming HL7 V2 Messages"
                disabled={!isHl7MessagesEnabled}
                control={
                    <Switch
                        checked={props.state.context.data.hl7Enabled}
                        color="primary"
                        onChange={(event) => {
                            props.send('SET_HL7_ENABLED', { hl7Enabled: event.target.checked })
                        }}
                    />
                }
            />
            <Tooltip
                title={(
                    <>
                        <span>
                            This feature is available to only interopiO Tier 3 and above customers. Contact
                        </span>
                        &nbsp;
                        <a
                            href="mailto:support@interopion.com"
                            style={{ color: "white" }}
                        >
                            support@interopion.com
                        </a>
                        &nbsp;
                        <span>
                            for more details.
                        </span>
                    </>
                )}
                placement="top-start"
                classes={{ popper: 'io-tooltip-root' }}
                style={{ top: '-3px' }}
            >
                <IconButton>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <br />

            <FormControl
                error={!!props.state.context.dataValidation.gatewayType}
                fullWidth
                style={{ marginTop: '16px' }}
            >
                <InputLabel>
                    Gateway Standard
                </InputLabel>
                <Select
                    data-qa="new-gateway-standard-select"
                    value={props.state.context.data.gatewayType}
                    MenuProps={{ style: { zIndex: 10000 }}}
                    onChange={(event) => {
                        props.send('SET_GATEWAY_TYPE', { gatewayType: event.target.value })
                    }}
                >
                    <MenuItem
                        data-qa="new-gw-standard-2"
                        value="FHIR_DSTU2__1_0_2"
                    >
                        FHIR DSTU2 v1.0.2
                    </MenuItem>
                    <MenuItem
                        data-qa="new-gw-standard-3"
                        value="FHIR_STU3__3_0_2"
                    >
                        FHIR STU3 v3.0.2
                    </MenuItem>
                    <MenuItem
                        data-qa="new-gw-standard-4"
                        value="FHIR_R4__4_0_1"
                    >
                        FHIR R4 v4.0.1
                    </MenuItem>
                    {featureSmileCdrCustomization ? (
                        <MenuItem value="HL7_V2">
                            HL7 v2.x Listening Endpoint
                        </MenuItem>
                    ) : null}
                </Select>
                {props.state.context.dataValidation.gatewayType ? (
                    <FormHelperText>
                        {props.state.context.dataValidation.gatewayType}
                    </FormHelperText>
                ) : null}
            </FormControl>
            <br/>

            <FormControl
                fullWidth
                style={{ marginTop: '16px' }}
            >
                <InputLabel>
                    Default Data Adapter
                </InputLabel>
                <Select
                    data-qa="new-gateway-data-adapter-select"
                    value={defaultSourceId}
                    disabled={!configurationType || configurationType === 'GatewayConfigurationDefault'}
                    fullWidth
                    MenuProps={{ style: { zIndex: 10000 } }}
                    onChange={(event) => {
                        props.send('SET_DEFAULT_DATA_SOURCE', { defaultDataSource: event.target.value })
                    }}
                >
                    <MenuItem
                        data-qa="add-gateway-adapter-none"
                        value=":NO_DEFAULT_SOURCE:"
                    >
                        No Default Data Adapter
                    </MenuItem>
                    {sources.map((src) => {
                        const userStrategy = src.userStrategy || ''
                        let userStrategyLabel = ''
                        switch (userStrategy) {
                            case 'INTEROPIO': userStrategyLabel = 'interopiO Users'; break
                            case 'DELEGATED': userStrategyLabel = 'Delegated Users'; break
                        }
                        return (
                            <MenuItem
                                data-qa={`add-gateway-adapter-${src.id}`}
                                key={src.id}
                                value={src.id}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'baseline',
                                    }}
                                >
                                    <div>
                                        {src.name}&nbsp;
                                    </div>
                                    {userStrategy ? (
                                        <Chip
                                            label={userStrategyLabel}
                                            style={{
                                                height: '20px',
                                                cursor: 'pointer',
                                                backgroundColor: userStrategy === 'INTEROPIO'
                                                    ? props.ui.xtheme.palette.colorBlueLight
                                                    : props.ui.xtheme.palette.colorBgndGreyLight,
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
            <br />

            {props.state.context.data.gatewayType !== 'HL7_V2' ? (
                <FormControl
                    fullWidth
                    style={{ marginTop: '16px' }}
                >
                    <InputLabel>
                        Authentication Type
                    </InputLabel>
                    <Select
                        data-qa="new-gateway-auth-type-select"
                        value={props.state.context.data.authenticationType}
                        disabled={!configurationType || configurationType === 'GatewayConfigurationDefault' || defaultSourceGatewayAuthenticationTypeOptions.length == 1}
                        autoWidth
                        MenuProps={{ style: { zIndex: 10000 } }}
                        onChange={(event) => {
                            props.send('SET_AUTH_TYPE', { authenticationType: event.target.value })
                        }}
                    >
                        {defaultSourceGatewayAuthenticationTypeOptions.map((authType) => {
                            return (
                                <MenuItem
                                    data-qa={`new-gateway-auth-${authType}`}
                                    key={authType}
                                    value={authType}
                                >
                                    {authType.replace(/_/g, " ")}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            ) : null}
            <br/>

            {props.state.context.data.authenticationType === 'OKTA_JWK' ? (
                <TextField
                    style={{ marginTop: "10px" }}
                    name="oktaJwkUrl"
                    value={props.state.context.data.oktaJwkUrl}
                    error={!!props.state.context.dataValidation.oktaJwkUrl}
                    placeholder="Enter Okta JWK URL"
                    label="Okta JWK URL"
                    helperText={props.state.context.dataValidation.oktaJwkUrl}
                    fullWidth
                    onChange={(event) => {
                        props.send('SET_OKTA_JWK_URL', { oktaJwkUrl: event.target.value })
                    }}
                />
            ) : null}
            <br/>

            {props.state.context.data.gatewayType !== 'HL7_V2'
                && defaultSourceUserStrategy === 'DELEGATED' ? (
                    <FormControl
                        fullWidth
                        style={{ marginTop: '16px' }}
                    >
                        <InputLabel>
                            User Directory
                        </InputLabel>
                        <Select
                            data-qa='new-gateway-user-dir-select'
                            value='DELEGATED'
                            label='User Directory'
                            disabled
                            autoWidth
                            style={{ marginTop: '16px' }}
                        >
                            <MenuItem
                                data-qa='new-gateway-user-dir-delegated'
                                value='DELEGATED'
                            >
                                Delegated
                            </MenuItem>
                        </Select>
                    </FormControl>
                ) : null}

            {props.state.context.data.gatewayType !== 'HL7_V2'
                && defaultSourceUserStrategy !== 'DELEGATED' ? (
                    <FormControl
                        fullWidth
                        style={{ marginTop: '16px' }}
                    >
                        <InputLabel>
                            User Directory
                        </InputLabel>
                        <Select
                            data-qa='new-gateway-user-dir-select'
                            value={selectedDirectoryId}
                            label='User Directory'
                            disabled={!configurationType || configurationType === 'GatewayConfigurationDefault'}
                            autoWidth
                            MenuProps={{ style: { zIndex: 10000 } }}
                            style={{ marginTop: '16px' }}
                            onChange={(event) => {
                                props.send('SET_USER_DIR', { directoryId: event.target.value })
                            }}
                        >
                            <MenuItem
                                data-qa='new-gateway-user-dir-none'
                                value="NONE"
                            >
                                NONE
                            </MenuItem>
                            {userDirsIo.map((it) => {
                                const directoryId = it.directoryId || ''
                                const directoryName = it.name || ''
                                return (
                                    <MenuItem
                                        data-qa={`new-gateway-user-dir-${it.directoryId}`}
                                        key={directoryId}
                                        value={directoryId}
                                    >
                                        {directoryName}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                ): null}

            {props.state.context.data.gatewayType === 'HL7_V2' ? (
                <TextField
                    disabled
                    name="port"
                    type="number"
                    label="Port"
                    fullWidth
                />
            ) : null}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    accounts: state.accounts,
    features: state.features,
    ui: state.ui,
    ...ownProps,
})
export default withRouter(connect(mapStateToProps)(WStep2))
