import * as React from "react";
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as actions from "../../../redux/actions";
import {Link} from "react-router-dom";
import withLegacyTheme from "../../../lib/hoc/with-legacy-theme";
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";
import "./style.less";

class GetStarted extends React.Component<any, any> {
    public static propTypes = {
        history: PropTypes.object.isRequired,
        muiTheme: PropTypes.object.isRequired,
        ui: PropTypes.object.isRequired,

        uiHideNavbar: PropTypes.func.isRequired,
        uiShowNavbar: PropTypes.func.isRequired,
    }

    public componentDidMount() {
        if (this.props.ui.showNavbar) {
            this.props.uiHideNavbar()
        }
    }

    public componentDidUpdate() {
        if (this.props.ui.showNavbar) {
            this.props.uiHideNavbar()
        }
    }

    public componentWillUnmount() {
        if (!this.props.ui.showNavbar) {
            this.props.uiShowNavbar();
        }
    }

    public render() {
        return <div className="container-get-started">
            <div style={{width: "100%", textAlign: "center"}}>
                <div
                    style={{
                        width: 147,
                        height: 48,
                        margin: "48px auto",
                        backgroundImage: "url(/console/img/interopio-logo@2x--r.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        const host = window.location.host.split(".");
                        const protocol = window.location.protocol;
                        window.location.href = `${protocol}//${(host.length > 2) ? host.slice(1).join(".") : host.join(".")}/console/`;
                    }}
                />
            </div>
            <div style={{width: 800, margin: "0 auto", borderRadius: 6, display: "flex"}}>
                <div style={{width: 400, backgroundColor: "#ECEFF1", padding: "64px 48px", borderRadius: "6px 0 0 6px", border: "1px solid #D7D7D7"}}>
                    <h2 style={{margin: 0, fontWeight: 500, width: 250}}>
                        Start innovating today with interopiO
                    </h2>
                    <p>
                        Create a brand-new account for you and your team.
                    </p>
                    <Button
                        startIcon={<IconAdd />}
                        style={{
                            height: 40,
                            width: "100%",
                            lineHeight: "40px",
                            marginTop: 32,
                            backgroundColor: "#fff",
                            color: this.props.muiTheme.palette.primary1Color,
                            border: "1px solid #bbbdc0",
                            borderRadius: 4,
                            paddingLeft: 2, fontSize: 16, textTransform: "none"
                        }}
                        onClick={() => this.props.history.push("/accounts/request")}
                    >
                        Create an interopiO Account
                    </Button>
                </div>
                <div style={{width: "400px", padding: "64px 48px", borderRadius: "0 6px 6px 0", border: "1px solid #D7D7D7", borderLeft: "none"}}>
                    <h2 style={{margin: 0, fontWeight: 500, width: 250}}>
                        Is your team already using interopiO?
                    </h2>
                    <p>
                        Find and sign in to your team’s existing account.
                    </p>
                    <Button
                        style={{
                            height: 40,
                            width: "100%",
                            lineHeight: "40px",
                            marginTop: 32,
                            backgroundColor: "#fff",
                            color: this.props.muiTheme.palette.primary1Color,
                            border: "1px solid #bbbdc0",
                            borderRadius: 4,
                            paddingLeft: 2, fontSize: 16, textTransform: "none"
                        }}
                        onClick={() => this.props.history.push("/signin")}
                    >
                        Sign in to interopiO
                    </Button>
                </div>
            </div>
            <div style={{width: 765, margin: "36px auto 64px", display: "flex", justifyContent: "space-between"}}>
                <div style={{width: 125}}>
                    <img src="/console/img/cloud-connect-graphic.svg"/>
                </div>
                <div style={{width: 426, fontSize: 14, lineHeight: "24px", textAlign: "center"}}>
                    <h4 style={{margin: 0, fontWeight: 700}}>
                        What’s an interopiO account?
                    </h4>
                    <p style={{margin: 0}}>
                        Each company or team that uses interopiO has an account of their own. It’s where their apps, CDS services, and gateways to clinical data sources are managed and
                        can only be joined by invitation.
                    </p>
                </div>
                <div style={{width: 125}}>
                    <img src="/console/img/config-graphic.svg"/>
                </div>
            </div>
            <div style={{
                width: 236,
                margin: "0 auto",
                display: "flex",
                justifyContent: "space-between",
                fontSize: 14,
                color: this.props.muiTheme.palette.secondaryTextColor
            }}>
                <Link to="/terms/customer-terms-of-service" target="_blank" style={{textDecoration: "none"}}>{`Privacy & Terms`}</Link>
                <a href="https://interopion.com/" target="_blank" style={{textDecoration: "none"}}>About Interopion</a>
            </div>
        </div>;
    }
}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    uiHideNavbar: actions.uiHideNavbar,
    uiShowNavbar: actions.uiShowNavbar,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(GetStarted)));
