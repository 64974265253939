import * as PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";

const CustomDialogContent = (props) => {
    return <DialogContent style={{ minWidth: 552, padding: 24, background: "rgb(250, 250, 250)" }}>
        {props.parentState?.delete?.rejectingMessage || ""}
    </DialogContent>
}

CustomDialogContent.propTypes = {
    parentState: PropTypes.object.isRequired,
};

export default CustomDialogContent;
