import * as React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../../../../../../redux/actions";
import axiosWrapper from "../../../../../../../../../lib/axiosWrapper";
import getHostnameInfo from "../../../../../../../../../lib/getHostnameInfo";
import getGatewaysCallUrl from "../../../../../../../../../lib/getGatewaysCallUrl";
import getLocationInfo from "../../../../../../../../../lib/getLocationInfo";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

interface IProps {
    config: any,
    location: any,
    parentStatus: string;
    selectedSessions: any[];
    generalMessageSet: Function;
    onClose: React.MouseEventHandler<HTMLButtonElement>;
    setParentStatus: Function;
};

const CustomDialogActions: React.FC<IProps> = (props: IProps) => {
    const isDeleteMultipleMode = props.selectedSessions.length > 1;

    function deleteOne(session) {
        const { accountId } = getHostnameInfo();
        const { env, gatewayId } = getLocationInfo(props.location, props.config);
        const selectedSessionId: string = session.sessionId;
        const path = `${getGatewaysCallUrl(accountId, env)}/${gatewayId}/request-logging-session/${selectedSessionId}`;
        return axiosWrapper(props.config.envApi, path, "DELETE");
    }

    return (
        <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
            <Button disabled={props.parentStatus !== "ready"} style={{ marginRight: 8 }}
                onClick={props.onClose}
            >
                Cancel
            </Button>
            <Button disabled={props.parentStatus !== "ready"} variant="contained" color="secondary" style={{ marginRight: 8 }}
                onClick={async (event) => {
                    props.setParentStatus("waiting");
                    props.onClose(event);
                    try {
                        isDeleteMultipleMode
                            ? await Promise.all(props.selectedSessions.map((s) => deleteOne(s)))
                            : await deleteOne(props.selectedSessions[0]);
                    }
                    catch (error) {
                        props.generalMessageSet({
                            type: ":WARNING_SNACKBAR:",
                            title: isDeleteMultipleMode
                                ? "There was an error deleting some of the sessions!"
                                : `There was an error deleting session ${props.selectedSessions[0].sessionId}!`,
                            message: error.message,
                        });
                    }
                    props.setParentStatus("init");
                }}
            >
                {`Delete Session${isDeleteMultipleMode ? "s" : ""}`}
            </Button>
        </DialogActions>
    );
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    generalMessageSet: actions.generalMessageSet,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomDialogActions));
