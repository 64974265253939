import {CardBody, CardBodyAddNew, CardCustomContent, CardMenuBar, CardsContainer, CardWrapper} from "../../../../Widgets/CustomCard";
import {getPath} from "../../../../../lib/utils";
import {Delete, Lock, LockOpen, Storage, SupervisorAccount, Visibility} from "@material-ui/icons";
import {Avatar, Chip} from "@material-ui/core";
import * as PropTypes from "prop-types";
import IconFhir from "../../../../Widgets/icons/Fhir";
import IconSmileCdr from "../../../../Widgets/icons/SmileCdr";

const GatewayCardsList = props => {
    return <CardsContainer>
        {props.actAsAdmin
            ? <CardWrapper style={{minHeight: "200px"}} data-qa="create-new-gateway-card">
                <CardBodyAddNew text="Add Gateway" onClick={() => props.onSetState({dialog: ":ADD_NEW_WIZARD:"})}/>
            </CardWrapper>
            : null}

        {props.data.map((item, index) => {
            const defaultSource = getPath(item, "configuration.defaultDataSource") || "";
            return <CardWrapper key={`card-${index}-${item.gatewayId}`} style={{minHeight: "200px"}}>
                <CardMenuBar data-qa={`gateway-menu-btn-${item.gatewayId}`} title={item.name}
                    menuItems={props.actAsAdmin
                        ? [
                            {
                                leftIcon: <Visibility/>,
                                text: "View",
                                onClick: () => props.history.push(`/${props.env}/gateways/${item.gatewayId}`)
                            },
                            {
                                "data-qa": "gateway-menu-delete",
                                leftIcon: <Delete/>,
                                text: "Delete",
                                onClick: () => props.onSetState({
                                    dialog: ":DELETE_CONFIRM:",
                                    delete: {
                                        gatewayId: item.gatewayId,
                                        name: item.name
                                    }
                                })
                            }
                        ]
                        : []}
                    style={{paddingBottom: 0}}
                    onClick={() => props.history.push(`/${props.env}/gateways/${item.gatewayId}`)}/>
                <CardBody data-qa={`gateway-card-${item.gatewayId}`} text={item.description} style={{paddingTop: 0, minHeight: 0}}
                    onClick={() => props.history.push(`/${props.env}/gateways/${item.gatewayId}`)}/>
                <CardCustomContent
                    style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                    }}
                    onClick={() => props.history.push(`/${props.env}/gateways/${item.gatewayId}`)}
                >
                    <div
                        style={{
                            display: "inline-block",
                            marginRight: 2,
                            marginBottom: 2,
                            width: 128,
                        }}
                    >
                        <Chip
                            label={(
                                <>
                                    <Avatar
                                        style={{
                                            display: 'inline-block',
                                            width: '32px',
                                            height: '32px',
                                            left: '-11px',
                                        }}
                                    >
                                        <IconFhir
                                            style={{
                                                position: 'relative',
                                                top: '3px',
                                                left: '4px',
                                                color: 'white',
                                            }}
                                        />
                                    </Avatar>
                                    {(() => {
                                        switch (item.gatewayType) {
                                            case 'FHIR_DSTU2__1_0_2': return 'DSTU2'
                                            case 'FHIR_STU3__3_0_2': return 'STU3'
                                            case 'FHIR_R4__4_0_1': return 'R4'
                                            case 'HL7_V2': return 'HL7 v2.x Listening Endpoint'
                                            default: return '--'
                                        }
                                    })()}
                                </>
                            )}
                            classes={{ label: 'full-width-chip-label' }}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'inline-block',
                            marginBottom: 10,
                            marginLeft: 2,
                            width: 128,
                        }}
                    >
                        <Chip
                            label={(
                                <>
                                    <Avatar
                                        style={{
                                            display: 'inline-block',
                                            width: '32px',
                                            height: '32px',
                                            left: '-11px',
                                        }}
                                    >
                                        <SupervisorAccount
                                            style={{
                                                position: 'relative',
                                                top: '3px',
                                                left: '4px',
                                                color: 'white',
                                            }}
                                        />
                                    </Avatar>
                                    {(() => {
                                        switch (item.userStrategy) {
                                            case 'INTEROPIO': return 'interopiO'
                                            case 'DELEGATED': return 'Delegated'
                                            default: return 'Not set'
                                        }
                                    })()}
                                </>
                            )}
                            classes={{ label: 'full-width-chip-label' }}
                            style={{ width: '100%' }}
                        />
                    </div>
                    {!defaultSource ? (
                        <div
                            style={{
                                display: 'inline-block',
                                marginRight: 2,
                                marginBottom: 2,
                                width: 128,
                            }}
                        >
                            <Chip
                                label={(
                                    <>
                                        <Avatar
                                            style={{
                                                display: 'inline-block',
                                                width: '32px',
                                                height: '32px',
                                                left: '-11px',
                                            }}
                                        >
                                            <Storage
                                                style={{
                                                    position: 'relative',
                                                    top: '3px',
                                                    left: '4px',
                                                    color: 'white',
                                                }}
                                            />
                                        </Avatar>
                                        Not set
                                    </>
                                )}
                                classes={{ label: 'full-width-chip-label' }}
                                style={{ width: '100%' }}
                            />
                        </div>
                    ) : null}
                    {defaultSource && defaultSource.startsWith('cdr:') ? (
                        <div
                            style={{
                                display: 'inline-block',
                                marginRight: 2,
                                marginBottom: 10,
                                width: 128,
                            }}
                        >
                            <Chip
                                label={(
                                    <>
                                        <Avatar
                                            style={{
                                                display: 'inline-block',
                                                width: '32px',
                                                height: '32px',
                                                left: '-11px',
                                            }}
                                        >
                                            {props.featureSmileCdrCustomization ? (
                                                <IconSmileCdr
                                                    style={{
                                                        position: 'relative',
                                                        top: '3px',
                                                        left: '4px',
                                                        color: 'white',
                                                    }}
                                                />
                                            ) : (
                                                <Storage
                                                    style={{
                                                        position: 'relative',
                                                        top: '3px',
                                                        left: '4px',
                                                        color: 'white',
                                                    }}
                                                />
                                            )}
                                        </Avatar>
                                        <span>
                                            {props.featureSmileCdrCustomization ? 'SMILE CDR' : 'FHIR CDR'}
                                        </span>
                                    </>
                                )}
                                classes={{ label: 'full-width-chip-label' }}
                                style={{ width: '100%' }}
                            />
                        </div>
                    ) : null}
                    {defaultSource && !defaultSource.startsWith('cdr:') ? (
                        <div
                            style={{
                                display: 'inline-block',
                                marginRight: 2,
                                marginBottom: 10,
                                width: 128,
                            }}
                        >
                            <Chip
                                label={(() => {
                                    if (!item.configuration?.defaultDataSourcePhi) {
                                        return (
                                            <>
                                                <Avatar
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '32px',
                                                        height: '32px',
                                                        left: '-11px',
                                                    }}
                                                >
                                                    <Lock
                                                        style={{
                                                            position: 'relative',
                                                            top: '3px',
                                                            left: '4px',
                                                            color: 'white',
                                                        }}
                                                    />
                                                </Avatar>
                                                <span>
                                                    PHI enabled
                                                </span>
                                            </>
                                        )
                                    }
                                    return (
                                        <>
                                            <Avatar
                                                style={{
                                                    display: 'inline-block',
                                                    width: '32px',
                                                    height: '32px',
                                                    left: '-11px',
                                                }}
                                            >
                                                <LockOpen
                                                    style={{
                                                        position: 'relative',
                                                        top: '3px',
                                                        left: '4px',
                                                        color: 'white',
                                                    }}
                                                />
                                            </Avatar>
                                            <span>
                                                PHI disabled
                                            </span>
                                        </>
                                    )
                                })()}
                                classes={{ label: 'full-width-chip-label' }}
                                style={{ width: '100%' }}
                            />
                        </div>
                    ) : null}
                </CardCustomContent>
                <div style={{"height": "35px", "width": "100%"}}/>
            </CardWrapper>;
        })}
    </CardsContainer>;
}

GatewayCardsList.propTypes = {
    actAsAdmin: PropTypes.bool,
    data: PropTypes.array,
    env: PropTypes.string,
    featureSmileCdrCustomization: PropTypes.bool,
    history: PropTypes.object,
    onSetState: PropTypes.func,
};

export default GatewayCardsList;
