import * as React from "react";
import * as PropTypes from "prop-types";
import {TextField} from "@material-ui/core";

const Component: React.FunctionComponent<any> = props => {
    return <div>
        <p style={{color: props.muiTheme.palette.textColor, textAlign: "center"}}>
            <big>Enter the <strong>email address</strong></big>
        </p>

        <TextField data-qa="users-permissions-invite-new-email-input" name="invitationEmail" type="email" value={props.state.data.invitationEmail || ""}
            label="Email" placeholder="user-email@example.com" error={!!props.state.dataValidation.invitationEmail} helperText={props.state.dataValidation.invitationEmail}
            disabled={props.state.status !== "ready"} autoComplete="off" fullWidth
            // eslint-disable-next-line react/prop-types
            onChange={e => props.onSetDialogState({
                data: {
                    ...props.state.data,
                    invitationEmail: e.target.value
                },
                dataValidation: {
                    ...props.state.dataValidation,
                    invitationEmailSentConfirmed: "",
                    invitationEmailSentRejected: ""
                }
            })}
        />
        <div style={{
            marginTop: "16px",
            padding: props.state.dataValidation.invitationEmailSentConfirmed ? "8px" : 0,
            transform: props.state.dataValidation.invitationEmailSentConfirmed ? "scaleY(1)" : "scaleY(0)",
            transformOrigin: "top",
            borderRadius: "2px",
            background: props.muiTheme.palette.primary1Color,
            color: "#fff",
            textAlign: "center",
            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
        }}>
            {props.state.dataValidation.invitationEmailSentConfirmed}
        </div>
        <div style={{
            marginTop: "16px",
            padding: props.state.dataValidation.invitationEmailSentRejected ? "8px" : 0,
            transform: props.state.dataValidation.invitationEmailSentRejected ? "scaleY(1)" : "scaleY(0)",
            transformOrigin: "top",
            borderRadius: "2px",
            background: props.muiTheme.palette.accent1Color,
            color: "#fff",
            textAlign: "center",
            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
        }}>
            {props.state.dataValidation.invitationEmailSentRejected}
        </div>
    </div>
}

Component.propTypes = {
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
