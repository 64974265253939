import * as PropTypes from "prop-types";
import "./style.less";

const CardCustomContent = (props) => {
    return <div className={`card-custom-content ${props.className}`} style={props.style} onClick={props.onClick}>
        {props.children}
    </div>;
}

CardCustomContent.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
}

CardCustomContent.defaultProps = {
    className: "",
    style: {},
    onClick: () => {}
}

export default CardCustomContent;
