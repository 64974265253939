import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogActions from "./CustomDialogActions";
import CustomDialogContent from "./CustomDialogContent";
import CustomDialogTitle from "./CustomDialogTitle";

interface IProps {
    newSession: any;
    parentStatus: string;
    setParentStatus: Function;
    setDialog: Function;
}

const DeleteSessions: React.FC<IProps> = (props: IProps) => {
    function onClose() {
        props.setDialog("");
    }

    return <Dialog open disableBackdropClick disableEscapeKeyDown scroll="body">
        <CustomDialogTitle onClose={onClose} />
        <CustomDialogContent newSession={props.newSession} parentStatus={props.parentStatus} />
        <CustomDialogActions newSession={props.newSession} parentStatus={props.parentStatus} onClose={onClose} setParentStatus={props.setParentStatus} />
    </Dialog >
}

export default DeleteSessions;
