import * as PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import CheckCdsServices from "../../../../../../Shared/CheckCdsServices";

const WStep3 = (props) => {
    return <>
        <TextField
            name="name" value={props.parentState.data.name || ""}
            placeholder="Display name" label="Display Name"
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />
        <TextField
            name="cdsServiceSetId" value={props.parentState.data.cdsServiceSetId || ""}
            placeholder="CDS service set ID" label="CDS Service Set ID"
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />
        <TextField
            name="description" value={props.parentState.data.description || ""}
            placeholder="Description" label="Description"
            disabled fullWidth multiline
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />
        <TextField
            name="jku" value={props.parentState.gatewayJwkSet.jku || ""}
            placeholder="JKU endpoint" label="JKU Endpoint"
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />
        <CheckCdsServices
            canEdit
            parentState={props.parentState}
            onSetParentState={props.onSetParentState}
        />
    </>;
};

WStep3.propTypes = {
    parentState: PropTypes.object.isRequired,
    onSetParentState: PropTypes.func.isRequired,
};

export default WStep3;
