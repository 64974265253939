import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../redux/actions";
import getEnvironmentsCallUrl from "../../../../lib/getEnvironmentsCallUrl";
import getHostnameInfo from "../../../../lib/getHostnameInfo";
import CenteredCircularProgress from "../../../Widgets/CenteredCircularProgress";
import withTheme from "@material-ui/core/styles/withTheme";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import WStep1 from "./WStep1";

const WSTEP_MIN = 1;
const WSTEP_MAX = 1;

const INIT_STATE = {
    status: "", // "" | "ready" | "init" | "pending"
    data: {
        accountId: "",
        name: "",
        environmentId: "",
        description: "",
        phi: null,
    },
    wstep: 1,
    isNextWStepEnabled: false,
};
class EnvironmentAddNewWizard extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,
        environmentsCreate: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    public static defaultProps = {
        className: ""
    };

    public constructor(props) {
        super(props);
        this.state = { ...INIT_STATE };
    }

    public componentDidMount() {
        this.initialize();
    }

    public render() {
        const { accountId } = getHostnameInfo();

        return <Dialog
            open disableBackdropClick disableEscapeKeyDown scroll="body"
            className={this.props.className}
            onClose={this.props.onClose}
        >
            <DialogTitle
                disableTypography
                style={{ color: this.props.theme.palette.primary.contrastText, background: this.props.theme.palette.primary.main }}
            >
                <Typography data-qa="new-env-modal-title" variant="h6">
                    Add a new Environment
                </Typography>
                <IconButton
                    style={{ position: "absolute", right: this.props.theme.spacing(1), top: this.props.theme.spacing(1), color: this.props.theme.palette.primary.contrastText }}
                    onClick={this.props.onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 24, background: "rgb(250, 250, 250)" }}>
                {this.state.status === "ready"
                    ? this.renderWStep()
                    : <CenteredCircularProgress size={63} style={{ minWidth: 552 }} />}
            </DialogContent>
            <DialogActions style={{ padding: 24, paddingTop: 0, background: "rgb(250, 250, 250)" }}>
                {this.state.wstep > WSTEP_MIN ? <Button
                    disabled={!this.state.isNextWStepEnabled}
                    color="primary" style={{ marginRight: 8 }}
                    startIcon={<KeyboardArrowLeftIcon />}
                    onClick={() => this.setState({ wstep: this.calcPrevWStep() })}
                >
                    Back
                </Button>
                    : null}
                {this.state.wstep < WSTEP_MAX ? <Button
                    variant="contained" color="primary" style={{ marginRight: 8 }}
                    endIcon={<KeyboardArrowRightIcon />}
                    disabled={!this.state.isNextWStepEnabled}
                    onClick={() => this.setState({ wstep: this.calcNextWStep() })}
                >
                    Next
                </Button>
                    : null}
                {this.state.wstep === WSTEP_MAX ? <Button data-qa="create-env-button"
                    variant="contained" color="primary" style={{ marginRight: 8 }}
                    startIcon={<CheckIcon />}
                    disabled={this.state.status !== "ready" || !this.state.isNextWStepEnabled}
                    onClick={async () => {
                        this.setState({ status: "pending" });
                        const url = getEnvironmentsCallUrl(accountId) + "/" + this.state.data.environmentId;
                        const res = await this.props.environmentsCreate(this.props.config, url, this.state.data)
                        if (res.status < 300 && res.data) {
                            this.props.initialize();
                            const url = `/${res.data.environmentId}`;
                            this.props.history.push(url);
                        }
                    }}
                >
                    Create Environment
                </Button>
                    : null}
            </DialogActions>
        </Dialog>
    }

    private renderWStep = () => {
        const propSet = {
            parentState: this.state,
            onSetParentState: (state, cb) => this.setState(state, cb),
        };
        switch (this.state.wstep) {
            case 1:
                return <WStep1 {...propSet} />;
            default:
                return null;
        }
    }

    private initialize = () => {
        const { accountId } = getHostnameInfo();
        this.setState({
            status: "ready",
            data: {
                ...this.state.data,
                accountId,
            },
        });
    };

    private calcNextWStep = () => this.state.wstep < WSTEP_MAX ? this.state.wstep + 1 : WSTEP_MAX;
    private calcPrevWStep = () => this.state.wstep > WSTEP_MIN ? this.state.wstep - 1 : WSTEP_MIN;
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    ...ownProps,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    environmentsCreate: actions.environmentsCreate,
}, dispatch);
export default withRouter(withTheme(connect(mapStateToProps, mapDispatchToProps)(EnvironmentAddNewWizard)));
