import * as React from "react";
import * as PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogActions from "./CustomDialogActions";
import CustomDialogTitle from "./CustomDialogTitle";
import CustomDialogContent from "./CustomDialogContent";

const WSTEP_MIN = 1;
const WSTEP_MAX = 1;

const INIT_STATE = {
    status: "ready", // "ready" | "waiting"
    data: {
        appUserType: "PROVIDER"
    },
    dataValidation: {},
    wstep: WSTEP_MIN,
    isNextWStepEnabled: false
};

class AppUserAddNewWizard extends React.Component<any, any> {
    public static propTypes = {
        onClose: PropTypes.func.isRequired
    };

    public constructor(props) {
        super(props);
        this.state = {...INIT_STATE};
    }

    public render() {
        return <Dialog open disableBackdropClick disableEscapeKeyDown scroll="body" onClose={this.props.onClose}>
            <CustomDialogTitle onClose={this.props.onClose}/>
            <CustomDialogContent wstate={this.state} onSetWState={this.onSetState}/>
            <CustomDialogActions wstate={this.state} WSTEP_MIN={WSTEP_MIN} WSTEP_MAX={WSTEP_MAX} calcNextWStep={this.calcNextWStep} calcPrevWStep={this.calcPrevWStep}
                initialize={this.props.onClose} onSetWState={this.onSetState}/>
        </Dialog>
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private calcNextWStep = () => this.state.wstep < WSTEP_MAX ? this.state.wstep + 1 : WSTEP_MAX;

    private calcPrevWStep = () => this.state.wstep > WSTEP_MIN ? this.state.wstep - 1 : WSTEP_MIN;
};

export default AppUserAddNewWizard;
