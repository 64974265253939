import * as React from 'react'
import ActivationDeleteConfirm from './ActivationDeleteConfirm'
import ActivationEditor from './ActivationEditor/'
import AppLaunchInspect from './AppLaunchInspect/'

type TProps = {
    state: any
    initialize: () => void
    muiTheme: any,
    onSetState: Function
}

const Dialogs: React.FC<TProps> = (props: TProps) => {
    switch (props.state.dialog) {
        case ':ADD_ACTIVATION:':
            return (
                <ActivationEditor
                    muiTheme={props.muiTheme}
                    parentState={props.state}
                    onClose={props.initialize}
                />
            )
        case ':EDIT_ACTIVATION:':
            return (
                <ActivationEditor
                    muiTheme={props.muiTheme}
                    onClose={props.initialize}
                    parentState={props.state}
                    selectedAppActivation={props.state.selectedActivation}
                />
            )
        case ':DELETE_ACTIVATION_CONFIRM:':
            return (
                <ActivationDeleteConfirm
                    onClose={props.initialize}
                    parentState={props.state}
                    selectedAppActivation={props.state.selectedActivation}
                />
            )
        case ':APP_LAUNCH_INSPECT:':
            return (
                <AppLaunchInspect
                    muiTheme={props.muiTheme}
                    onClose={props.initialize}
                    parentState={props.state}
                    onSetState={props.onSetState}
                />
            )
    }

    return null
}

export default Dialogs
