/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import axiosWrapper from "../../../../../../lib/axiosWrapper";
import consoleLogger from "../../../../../../lib/consoleLogger";
import {getRoleOwnerId, getRoleUserId, isAccountOwner as checkAccountOwner, isAccountUser as checkAccountUser} from "../../../../../../lib/user-roles-helpers";
import {checkUserStatus} from "../../../../../../lib/user-statuses-helpers";
import {getPath} from "../../../../../../lib/utils/";
import {Avatar, Chip, Divider, IconButton, Menu, ListItem, MenuItem} from "@material-ui/core";
import {AccountBox, AccountCircle, Delete, MoreVert, PermIdentity, Send, CloudCircle, Cached, ScreenLockPortrait} from "@material-ui/icons";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    const userUuid = getPath(props, "user.uuid");
    const userNames = (getPath(props, "user.first_name") || getPath(props, "user.last_name")) ? `${getPath(props, "user.first_name")} ${getPath(props, "user.last_name")}` : getPath(props, "user.email") || "";
    const userEmail = (getPath(props, "user.first_name") || getPath(props, "user.last_name")) ? getPath(props, "user.email") : "";
    const userRoleId = getPath(props, "user.userroles.0.role_id") || null;
    const userRoleObj = props.config.userRoles.find((role) => getPath(role, "id") === userRoleId);
    const userRoleName = getPath(userRoleObj, "name") || userRoleId;
    const userStatusCode = getPath(props, "user.status") || getPath(props, "user.status") === 0 ? getPath(props, "user.status") : null;
    const userStatusObj = props.config.userStatuses.find((status) => getPath(status, "code") === userStatusCode);
    const userStatusName = getPath(userStatusObj, "name") || userStatusCode;
    const userTypeName = getPath(props, "user.type") === "SSO" ? "SSO" : getPath(props, "user.mfaEnabled") === 0 ? "Basic" : "MFA" || "Basic";
    const userMFAStatus = getPath(props, "user.mfaEnabled") || 0;
    const isSsoEnabled = getPath(props, "accounts.selectedSsoConfig.data.enabled") || 0;
    const isAccountOwner = checkAccountOwner(props.user, props.config);
    const isAccountUser = checkAccountUser(props.user, props.config);
    const hasStatusActive = checkUserStatus(props.user, ":ACTIVE:");
    const hasStatusChangeEmail = checkUserStatus(props.user, ":CHANGE_EMAIL:");
    const hasStatusDeactivated = checkUserStatus(props.user, ":DEACTIVATED:");
    const hasStatusInvited = checkUserStatus(props.user, ":INVITED:");
    const hasStatusInviteExpired = checkUserStatus(props.user, ":INVITE_EXPIRED:");
    const hasStatusResetPassword = checkUserStatus(props.user, ":RESET_PASSWORD:");

    // Left avatar
    let leftAvatarIcon = <AccountCircle/>;
    if (hasStatusActive || hasStatusResetPassword || hasStatusChangeEmail) {
        leftAvatarIcon = <PermIdentity/>;
    }

    let leftAvatarColor = "";

    let leftAvatarBgndColor = "";
    if (isAccountOwner && !hasStatusInvited && !hasStatusDeactivated) {
        leftAvatarBgndColor = props.muiTheme.palette.accent1Color;
    } else if (isAccountUser && !hasStatusInvited && !hasStatusDeactivated) {
        leftAvatarBgndColor = props.muiTheme.palette.primary1Color;
    }

    const leftAvatarStyle = {left: "-16px"};

    const leftAvatarProps = {
        icon: leftAvatarIcon,
        color: leftAvatarColor,
        style: {...leftAvatarStyle, backgroundColor: leftAvatarBgndColor}
    }

    let leftAvatar = <Avatar {...leftAvatarProps} />;

    // Role chip
    const roleChipStyle = {};

    let roleChipBgndColor = "";
    if (isAccountOwner && !hasStatusInvited && !hasStatusDeactivated) {
        roleChipBgndColor = props.ui.xtheme.palette.colorRedLight;
    } else if (isAccountUser && !hasStatusInvited && !hasStatusDeactivated) {
        roleChipBgndColor = props.ui.xtheme.palette.colorBlueLight;
    }

    // Status chip
    const statusChipStyle = {};

    let statusChipBgndColor = "";
    if (hasStatusActive || hasStatusResetPassword || hasStatusChangeEmail || hasStatusInvited) {
        statusChipBgndColor = props.ui.xtheme.palette.colorBlueLight;
    }

    // Type chip
    const typeChipStyle = {};

    // Right icon
    const rightIconMenuItems = [];

    if (isAccountUser && (hasStatusActive || hasStatusResetPassword || hasStatusChangeEmail || hasStatusInvited)) {
        rightIconMenuItems.push(
            <MenuItem data-qa="users-permissions-user-item-env-button" key="right-menu-manage"
                onClick={() => {
                    props.onSetState({
                        selectedUser: props.user,
                        dialog: ":USER_ENVIRONMENT_PERMISSIONS:",
                        menuOpen: '',
                    });
                }}>
                <CloudCircle style={{opacity: .5, marginRight: "16px"}}/> Manage Environment Permissions
            </MenuItem>
        )
    }

    if (isAccountUser && (hasStatusActive || hasStatusResetPassword || hasStatusChangeEmail || hasStatusInvited)) {
        rightIconMenuItems.push(
            <MenuItem data-qa="users-permissions-user-item-owner-button" key="right-menu-assign-owner"
                onClick={() => {
                    props.onSetState(
                        { status: "wait", menuOpen: '' },
                        () => {
                            const roleOwnerId = getRoleOwnerId(props.config);
                            const data = {roles: [roleOwnerId]};
                            axiosWrapper(props.config.accountApi, `users/${userUuid}`, "PUT", data)
                                .then(() => props.initialize())
                                .catch((reason) => consoleLogger.error(":::", reason))
                        }
                    );
                }}>
                <AccountCircle style={{opacity: .5, marginRight: "16px"}}/> Assign an Owner role
            </MenuItem>
        )
    }

    if (isAccountOwner && (hasStatusActive || hasStatusResetPassword || hasStatusChangeEmail || hasStatusInvited)) {
        rightIconMenuItems.push(
            <MenuItem data-qa="users-permissions-user-item-user-button" key="right-menu-assign-user"
                onClick={() => {
                    props.onSetState(
                        { status: "wait", menuOpen: '' },
                        () => {
                            const roleUserId = getRoleUserId(props.config);
                            const data = {roles: [roleUserId]};
                            axiosWrapper(props.config.accountApi, `users/${userUuid}`, "PUT", data)
                                .then(() => props.initialize())
                                .catch((reason) => consoleLogger.error(":::", reason))
                        }
                    );
                }}>
                <AccountCircle style={{opacity: .5, marginRight: "16px"}}/> Assign an User role
            </MenuItem>
        )
    }

    if (hasStatusActive || hasStatusResetPassword || hasStatusChangeEmail) {
        rightIconMenuItems.push(
            <MenuItem key="right-menu-deactivate"
                onClick={() => {
                    props.onSetState(
                        { status: "wait", menuOpen: '' },
                        () => {
                            axiosWrapper(props.config.accountApi, `users/${userUuid}/toggle-active`, "POST")
                                .then(() => props.initialize())
                                .catch((reason) => consoleLogger.error(":::", reason))
                        }
                    );
                }}>
                <PermIdentity style={{opacity: .5, marginRight: "16px"}}/> Deactivate user
            </MenuItem>
        )
    }

    if (hasStatusDeactivated) {
        rightIconMenuItems.push(
            <MenuItem key="right-menu-activate"
                onClick={() => {
                    props.onSetState(
                        { status: "wait", menuOpen: '' },
                        () => {
                            axiosWrapper(props.config.accountApi, `users/${userUuid}/toggle-active`, "POST")
                                .then(() => props.initialize())
                                .catch((reason) => consoleLogger.error(":::", reason))
                        }
                    );
                }}>
                <AccountBox style={{opacity: .5, marginRight: "16px"}}/> Activate user
            </MenuItem>
        )
    }

    if (hasStatusInvited || hasStatusInviteExpired) {
        rightIconMenuItems.push(
            <MenuItem data-qa="users-permissions-user-item-resent-button" key="right-menu-resend"
                disabled={props.state.status !== "ready"}
                onClick={() => {
                    props.onSetState(
                        { status: "reinvite", menuOpen: '' },
                        () => {
                            axiosWrapper(props.config.accountApi, `users/${userUuid}/resend-invitation`, "POST", {sentFrom: props.login.data.name})
                                .then(() => props.initialize())
                                .catch((reason) => {
                                    consoleLogger.error(":::", reason);
                                })
                        }
                    );
                }}>
                <Send style={{opacity: .5, marginRight: "16px"}}/> Resend invitation
            </MenuItem>
        )
    }

    if (hasStatusActive && isSsoEnabled && userTypeName !== "SSO") {
        rightIconMenuItems.push(
            <MenuItem data-qa="users-permissions-user-item-sso-button" key="right-menu-sso"
                disabled={props.state.status !== "ready"}
                onClick={() => {
                    props.onSetState({
                        selectedUser: props.user,
                        dialog: ":CHANGE_USER_TYPE:",
                        menuOpen: '',
                    });
                }}>
                <Cached style={{opacity: .5, marginRight: "16px"}}/> Change to SSO User
            </MenuItem>
        )
    }

    if (hasStatusActive && userTypeName === "Basic" && userMFAStatus === 0) {
        rightIconMenuItems.push(
            <MenuItem data-qa="users-permissions-user-item-mfa-button" key="right-menu-mfa"
                disabled={props.state.status !== "ready"}
                onClick={() => {
                    props.onSetState({
                        selectedUser: props.user,
                        dialog: ":ENABLE_USER_MFA:",
                        menuOpen: '',
                    });
                }}>
                <ScreenLockPortrait style={{opacity: .5, marginRight: "16px"}}/> Enable MFA
            </MenuItem>
        )
    }

    if (hasStatusActive && userTypeName === "MFA" && userMFAStatus === 2) {
        rightIconMenuItems.push(
            <MenuItem data-qa="users-permissions-user-item-mfa-reset-button" key="right-menu-mfa-reset"
                disabled={props.state.status !== "ready"}
                onClick={() => {
                    props.onSetState({
                        selectedUser: props.user,
                        dialog: ":RESET_USER_MFA:",
                        menuOpen: '',
                    });
                }}>
                <ScreenLockPortrait style={{opacity: .5, marginRight: "16px"}}/> Reset User MFA
            </MenuItem>
        )
    }

    let rightIcon = null;
    if (props.showMenu) {
        rightIcon = <React.Fragment>
            <IconButton data-qa={`${userNames}-options-button`} onClick={e => props.onSetState({menuOpen: userUuid, menuAnchor: e.currentTarget})}>
                <MoreVert/>
            </IconButton>
            <Menu open={props.state.menuOpen === userUuid} className="right-icon" onClose={() => props.onSetState({menuOpen: undefined})} anchorEl={props.state.menuAnchor}>
                {rightIconMenuItems}
                <Divider/>
                <MenuItem data-qa="users-permissions-user-item-delete-button" key="right-menu-delete"
                    onClick={() => {
                        props.onSetState({
                            selectedUser: props.user,
                            dialog: ":DELETE_USER_CONFIRM:",
                            menuOpen: '',
                        });
                    }}>
                    <Delete style={{opacity: .5, marginRight: "16px"}}/> Delete
                </MenuItem>
            </Menu>
        </React.Fragment>;
    } else if (props.showCurrentUserMenu && userTypeName !== "SSO" && (isSsoEnabled || userMFAStatus === 0)) {
        rightIcon = <React.Fragment>
            <IconButton data-qa={`current-user-options-button`} onClick={e => props.onSetState({menuOpen: userUuid, menuAnchor: e.currentTarget})}>
                <MoreVert/>
            </IconButton>
            <Menu open={props.state.menuOpen === userUuid} className="right-icon" onClose={() => props.onSetState({menuOpen: undefined})} anchorEl={props.state.menuAnchor}>
                {isSsoEnabled === 1 && isAccountOwner && <MenuItem data-qa="users-permissions-user-item-sso-button" key="right-menu-resend"
                    disabled={props.state.status !== "ready"}
                    onClick={() => {
                        props.onSetState({
                            selectedUser: props.user,
                            dialog: ":CHANGE_USER_TYPE:",
                            menuOpen: '',
                        });
                    }}>
                    <Cached style={{opacity: .5, marginRight: "16px"}}/> Change to SSO User
                </MenuItem>}
                {userMFAStatus === 0 && <MenuItem data-qa="users-permissions-user-item-mfa-button" key="right-menu-mfa"
                    disabled={props.state.status !== "ready"}
                    onClick={() => {
                        props.onSetState({
                            selectedUser: props.user,
                            dialog: ":ENABLE_USER_MFA:",
                            menuOpen: '',
                        });
                    }}>
                    <ScreenLockPortrait style={{opacity: .5, marginRight: "16px"}}/> Enable MFA
                </MenuItem>}
            </Menu>
        </React.Fragment>;
    }

    return <ListItem data-qa={userNames} disabled className="acc-ul-item" style={{opacity: props.state.status !== "ready" ? 0.33 : 1}}>
        {leftAvatar}
        <div className="content">
            <div className="user">
                {userNames}
                <br/>
                <small style={{color: props.muiTheme.palette.secondaryTextColor}}>
                    {userEmail}
                </small>
            </div>
            <div className="role">
                <Chip data-qa={`users-permissions-user-item-role-chip-${userNames}`} style={{...roleChipStyle, backgroundColor: roleChipBgndColor}} label={userRoleName}/>
            </div>
            <div className="status">
                <Chip data-qa={`users-permissions-user-item-status-chip-${userNames}`} style={{...statusChipStyle, backgroundColor: statusChipBgndColor}} label={userStatusName}/>
            </div>
            <div className="type">
                <Chip data-qa={`users-permissions-user-item-type-chip-${userNames}`} style={{...typeChipStyle, backgroundColor: statusChipBgndColor}} label={userTypeName}/>
            </div>
        </div>
        {rightIcon}
    </ListItem>;
}

Component.propTypes = {
    config: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired,
    showMenu: PropTypes.bool,
    showCurrentUserMenu: PropTypes.bool
};

Component.defaultProps = {
    showMenu: true,
    showCurrentUserMenu: false
}

export default Component;
