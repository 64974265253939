import * as React from "react";
import * as PropTypes from "prop-types";
import {CardActions, CircularProgress, Button} from "@material-ui/core";

const Component: React.FunctionComponent<any> = props => {
    return <CardActions>
        <div style={{
            width: "99%",
            height: props.state.mode === ":EDIT:" ? "36px" : 0,
            marginTop: props.state.mode === ":EDIT:" ? "8px" : 0,
            marginBottom: props.state.mode === ":EDIT:" ? "8px" : 0,
            textAlign: "right",
            transition: "all 900ms cubic-bezier(0.25, 0.8, 0.25, 1)"
        }}>
            <Button disabled={props.state.mode !== ":EDIT:"} style={{display: props.state.mode === ":EDIT:" ? "inline-block" : "none", marginRight: "8px"}}
                onClick={() => props.onSetState({mode: ":VIEW:"}, props.initialize)} variant="outlined">
                Cancel
            </Button>
            <Button data-qa="account-settings-edit-save-button" color="primary" disabled={props.state.status !== "ready" || props.state.mode !== ":EDIT:" || !props.isValid}
                style={{display: props.state.mode === ":EDIT:" ? "inline-block" : "none"}} variant="contained"
                onClick={() => {
                    props.onSetState(
                        {status: "update"},
                        () => {
                            const data = {
                                name: props.state.data.name,
                                customer_contact_name: props.state.data.customer_contact_name,
                                customer_contact_email: props.state.data.customer_contact_email,
                                banner: props.state.data.banner
                            };
                            props.accountsCurrentUpdate(props.config, data)
                                .then(res => {
                                    if (res && res.status < 300 && res.data) {
                                        props.onSetState({mode: ":VIEW:"}, props.initialize);
                                    }
                                });
                        }
                    );
                }}>
                {props.state.status === "update" ? <CircularProgress size={24}/> : null} Save
            </Button>
        </div>
    </CardActions>;
}

Component.propTypes = {
    config: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    accountsCurrentUpdate: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired,
    isValid: PropTypes.bool
};

Component.defaultProps = {
    isValid: true
};

export default Component;
