import withTheme from '@material-ui/core/styles/withTheme'
import type { Theme } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

type TProps = {
    theme: Theme
    onClose: () => void
}

const CustomDialogTitle = (props: TProps) => {
    return (
        <DialogTitle
            disableTypography
            style={{
                background: props.theme.palette.primary.main,
                color: props.theme.palette.primary.contrastText,
            }}
        >
            <Typography
                data-qa="new-user-dir-modal-title"
                variant="h6"
            >
                Add a User Directory
            </Typography>
            <IconButton
                style={{
                    position: 'absolute',
                    right: props.theme.spacing(1),
                    top: props.theme.spacing(1),
                    color: props.theme.palette.primary.contrastText,
                }}
                onClick={props.onClose}
            >
                <CloseIcon/>
            </IconButton>
        </DialogTitle>
    )
}

export default withTheme(CustomDialogTitle)
