import * as PropTypes from "prop-types";

const sanitizeHtml = require("sanitize-html");
const uuidv4 = require("uuid/v4");

const Component = (props) => {
    return <div id={props.id} dangerouslySetInnerHTML={{__html: sanitizeHtml(props.html, props.options)}} className={props.className} style={props.style}/>;
};

Component.propTypes = {
    html: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    options: PropTypes.object,
    style: PropTypes.object
}

Component.defaultProps = {
    className: "",
    id: uuidv4(),
    options: {},
    style: {}
}

export default Component;
